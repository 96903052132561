// configuration file for auth input fields rules

import { t } from "i18next";
import { RegisterOptions } from "react-hook-form";

interface IAuthInput {
  [key: string]: {
    required: boolean;
    type?: "text" | "password" | "element" | "email" | "hidden";
    placeholder?: string;
    validator?: "string" | "email" | "url" | "password";
    doubleCheck?: boolean;
    checkForce?: boolean;
    readonly?: boolean;
    autocomplete?: string;
    label?: string;
  };
}

export const authInputSignIn: IAuthInput = {
  email: {
    required: true,
    placeholder: "",
    type: "email",
    label: "Your email",
    validator: "email",
  },
  password: { required: true, type: "password", label: "Password" },
};

export const authInputRetrievePasswordStep0: IAuthInput = {
  email: {
    required: true,
    placeholder: "",
    type: "email",
    label: "Your email",
    validator: "email",
  },
};

export const authInputRetrievePasswordStep1: IAuthInput = {
  code: {
    required: true,
    placeholder: "",
    type: "text",
    label: "Verification code",
    validator: "string",
  },
  password: { required: true, type: "password", label: "New password", doubleCheck: true },
};

// step 1
export const authInputUserSigninInfo: IAuthInput = {
  email: {
    required: true,
    readonly: true,
    placeholder: "",
    type: "email",
    label: "Your email",
    validator: "email",
  },
  tempPassword: { required: true, type: "password", label: "Enter your temporary password", placeholder: "Enter code" },
};

// step 2
export const authInputUserInvitation: IAuthInput = {
  given_name: {
    required: true,
    label: "Email",
    validator: "email",
  },
  password: {
    required: true,
    type: "password",
    label: "Set new Password",
    checkForce: true,
    doubleCheck: true,
  },
};

//new authform useform with react hook form

interface ReactHookFormItem {
  label: string;
  props: {
    name: string;
    type:
      | "text"
      | "password"
      | "email"
      | "number"
      | "select"
      | "checkbox"
      | "radio"
      | "date"
      | "time"
      | "url"
      | "tel"
      | "file"
      | "range"
      | "search"
      | "hidden"
      | "image"
      | "submit"
      | "reset"
      | "button";
    placeholder?: string;
    autocomplete?: "on" | "off";
    value?: string | number | boolean;
  };
  options: RegisterOptions;
}

export const emailField: ReactHookFormItem = {
  label: "Your email",
  props: {
    name: "email",
    type: "email",
  },
  options: {
    disabled: true,
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t("auth.signIn.emailError"),
    },
  },
};

export const firstnameField: ReactHookFormItem = {
  label: "First Name",
  props: {
    name: "firstname",
    type: "text",
    placeholder: "First Name",
  },
  options: {
    required: true,
    pattern: {
      value: /^[a-zA-Z ]*$/,
      message: "Only lower/upper case letters are allowed ",
    },
  },
};
export const lastnameField: ReactHookFormItem = {
  label: "Last Name",
  props: {
    name: "lastname",
    type: "text",
    placeholder: "Last Name",
  },
  options: {
    required: true,
    pattern: {
      value: /^[a-zA-Z ]*$/,
      message: "Only lower/upper case letters are allowed ",
    },
  },
};

export const tempPasswordField: ReactHookFormItem = {
  label: authInputUserSigninInfo.tempPassword.label!,
  props: {
    name: "tempPassword",
    type: "password",
    placeholder: "Enter the code received by email",
  },
  options: {
    required: true,
    minLength: {
      value: 8,
      message: "Please enter the temporary password received in your email",
    },
  },
};
