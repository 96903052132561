import {Box, Button, Grid, Skeleton, Typography} from "@mui/material";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {useFirstPartyData} from "components/hooks/useFirstPartyData";
import {useNavigate} from "react-router";
import {ListTitle} from ".";
import {FirstPartyItem} from "./firstPartyItem";
import {useEffect, useState} from "react";
import {useAppToast} from "../../components/live/AppToast";

type FirstPartyProps = {
    accountId: string;
};

export const FirstParty = (props: FirstPartyProps) => {
    const {accountId} = props;
    const navigate = useNavigate();
    const appToast = useAppToast();

    const [autoRefresh, setAutoRefresh] = useState(false);

    const {data, isLoading, remove} = useFirstPartyData(+accountId, autoRefresh);


    useEffect(() => {
        if (data) {
            const isPending = data.filter(fp => fp.uploadStatus === "PENDING").length > 0;

            if (isPending && !autoRefresh) {
                setAutoRefresh(true);
            } else if (!isPending && autoRefresh) {
                setAutoRefresh(false);
                appToast.open({
                    toastId: "globalToast",
                    severity: "success",
                    message: "Your First Party Data has been processed",
                });
            }
        }

    }, [appToast, autoRefresh, data]);


    return (
        <>
            <ListTitle title="First Party Data">
                <Button variant="contained"
                        onClick={() => navigate(pageUtils.getPagePathById(PageId.uploadFirstParty, {accountId: accountId}))}>
                    Upload First Party Data
                </Button>
            </ListTitle>

            <Box sx={{my: 3}}>
                <Box mb={3}
                     sx={{background: "#fcfcfc", padding: "1rem", borderRadius: "7px", border: "1px solid #ececec"}}>
                    <Typography variant="body1">
                        Below are the First Party Data lists you have previously uploaded. To upload another list, click
                        on the “Upload First Party Data” button and
                        follow the steps
                        <br/> Only lists where the status is “Success” can be used when building audiences
                        <br/> For privacy, you can only use a list you have directly uploaded <br/>
                        If you upload a non-hashed email list, this list will be automatically hashed by Evorra for
                        privacy
                    </Typography>
                </Box>
            </Box>
            {isLoading ? (
                <Box>
                    <Grid container spacing={5} direction="row" alignItems="center">
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton height="356px"/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton height="356px"/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton height="356px"/>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box>
                    {!data?.length && (
                        <Box mt={8}>
                            <Typography sx={{textAlign: "center"}}>
                                You haven't upload a First Party yet. Use the "Upload First Party Data" to start using
                                your First Party Data
                            </Typography>
                        </Box>
                    )}
                    <Grid container spacing={5} direction="row" alignItems="center" mb={6}>
                        {data?.map((file) => {
                            return (
                                <Grid key={file.id} item xs={12} md={6} lg={4}>
                                    <FirstPartyItem file={file} accountId={+accountId}/>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
        </>
    );
};
