import {Button, styled, Theme} from '@mui/material';
import {PageId} from 'application/pages/pages.config';
import {FooterBlock, FormBlock,} from 'components/templates/InfoBlock';
import {MainFrame} from 'components/templates/MainFrame';
import {FormProvider, useForm} from 'react-hook-form';
import React from 'react';
import {BrandForm} from './shared/BrandForm';
import {objectApi} from '../../application/entities/dataApi';
import {useNavigate, useParams} from 'react-router-dom';
import {pageUtils} from '../../application/pages/pages.utils';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import {AccountHeaderContent, AccountHeaderProperties} from '../Agencies/AccountHeader';
import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {CompanyNavigation} from '../Companies/shared/CompanyNavigation';
import {useAppToast} from '../../components/live/AppToast';
import {useAccountTracker} from '../Agencies/useAccountTracker';
import {queryClientRefetcher} from '../../application/utils/useQuery.utils';

interface CreateBrandProps {
  companyId?: number;
}

export const CreateBrand = (props: CreateBrandProps) => {
  
  const appToast = useAppToast();
  
  const methods    = useForm({mode: 'all'});
  const accountApi = new objectApi.accounts();
  const navigate   = useNavigate();
  // receive accountId and brandId in uri.params or props
  const uriParams  = useParams<{ companyId: string; }>() as { companyId: string };
  const companyId  = props?.companyId ?? +uriParams?.companyId;
  
  const accountQuery           = useAccountQuery(companyId);
  const accountData: IAccounts = accountQuery?.data ?? {};
  
  // account tracker and mode setter
  useAccountTracker(companyId);
  
  const {
          handleSubmit,
          formState: {isValid},
        } = methods;
  
  
  function onSubmit(dta: any) {
    const account = {...dta, companyUrlList: [dta.companyUrlList]};
    if (account.industries?.[0]?.id) account.industryIds = [account.industries?.[0]?.id];
    // delete account.industries;
    accountApi.createBrandAccountAssociations(companyId, {...account, accountType: 'BRAND'}).then((res) => {
      
      const account: IAccounts = res.data;
      appToast.success({message: 'The Brand was created successfully'});
      // reload accounts lists
      queryClientRefetcher(['account', 'user']);
      //
      navigate(pageUtils.getPagePathById(PageId.brandUpdate, {companyId, brandId: account.id}));
      
    }).catch((e) => {
      appToast.error({message: e?.message ?? 'An error occurred'});
    });
  }
  
  return (
    <MainFrame frameId={PageId.brandCreate}
               checkPageAccess={true}
               grantWith={{accountId: accountData.id}}
               headerProps={{
                 headerTitle      : 'New Brand - ' + accountData?.companyName,
                 hideHeaderDefault: true,
                 headerProperties : <AccountHeaderProperties account={accountData}/>,
                 headerContent    : <AccountHeaderContent account={accountData}/>,
                 headerButtons    : <CompanyNavigation account={accountData}/>
               }}>
      <FormProvider {...methods}>
        <Form name={'form'} onSubmit={handleSubmit(onSubmit)} className="flex-v" style={{height: '100%'}}>
          <div className="flex-main flex-h gap-small pad-ii-2">
            <FormBlock title={'New Brand Account Settings'}>
              <BrandForm/>
              <div className="flex-h pad flex-align-right">
                <Button type="submit" disabled={!isValid} variant={'contained'}>Save</Button>
              </div>
            </FormBlock>
          </div>
        </Form>
      </FormProvider>
    </MainFrame>
  );
};

const Form = styled('form')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
}));

