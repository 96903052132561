import {FormControlLabel, FormHelperText, MenuItem, Select, Theme, Typography,} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {StyledCheckbox} from 'components/form/CheckBox';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {CheckBoxGroupBlock, DetailsBlockGrid, InputFieldLabel, TextFieldBlock} from '../../../components/templates/InfoBlock';


interface IClientTypeProps {
  account: Partial<IAccounts>;
  accountFormData?: any; // FormikConfig<IAccounts>
  creationMode?: boolean;
}


const useStylesCheckBoxGroup = makeStyles((theme: Theme) =>
  createStyles({
      root         : {
        '&.MuiFormGroup-root': {
          paddingLeft: '30px',
        },
      },
      formGroup    : {
        paddingLeft: '30px',
      },
      checkBoxLabel: {
        fontSize  : 12,
        color     : '#3B4559',
        fontWeight: 'normal',
      },
    },
  ),
);

// ViewAccountType should be a fallback
export const EditAccountClientType: React.FC<IClientTypeProps> = (props) => {
  
  const {account, accountFormData, creationMode} = props;
  const {t}              = useTranslation();
  const classes                    = useStylesCheckBoxGroup();
  const awsRegions=[
    {name:'Africa (Cape Town)',code:'af-south-1'},
    {name:'Asia Pacific (Hong Kong)',code:'ap-east-1'},
    {name:'Asia Pacific (Mumbai)',code:'ap-south-1'},
    {name:'Asia Pacific (Osaka-Local)',code:'ap-northeast-3'},
    {name:'Asia Pacific (Seoul)',code:'ap-northeast-2'},
    {name:'Asia Pacific (Singapore)',code:'ap-southeast-1'},
    {name:'Asia Pacific (Sydney)',code:'ap-southeast-2'},
    {name:'Asia Pacific (Tokyo)',code:'ap-northeast-1'},
    {name:'Canada (Central)',code:'ca-central-1'},
    {name:'Europe (Frankfurt)',code:'eu-central-1'},
    {name:'Europe (Ireland)',code:'eu-west-1'},
    {name:'Europe (London)',code:'eu-west-2'},
    {name:'Europe (Paris)',code:'eu-west-3'},
    {name:'Europe (Stockholm)',code:'eu-north-1'},
    {name:'South America (São Paulo)',code:'sa-east-1'},
    {name:'US East (N. Virginia)',code:'us-east-1'},
    {name:'US East (Ohio)',code:'us-east-2'},
    {name:'US West (N. California)',code:'us-west-1'},
    {name:'US West (Oregon)',code:'us-west-2'},
  ]

  React.useEffect(()=>{
    if(accountFormData.values?.dataProvider){
      accountFormData.setFieldValue('awsRegion', 'eu-west-1')
    }else{
      accountFormData.setFieldValue('awsRegion', '')
    }
  }, [accountFormData.values?.dataProvider])

  return (
    <>
      <DetailsBlockGrid>
        <div className={classes.root}>
          <div>
            <InputFieldLabel required={true} htmlFor="form-group">
              {t('account.accountClientType.clientType')}
            </InputFieldLabel>
            <div
            >
              <CheckBoxGroupBlock id="form-group">
                <FormControlLabel
                  control={<StyledCheckbox
                    checked={Boolean(accountFormData?.values?.dataProvider)}
                    onChange={(e)=>{
                      if(e.target.checked ==true){
                      accountFormData?.setFieldValue('dataClient', false)
                      }
                      accountFormData?.handleChange(e)
                    }
                  }
                    name="dataProvider"
                    data-cy={'client-type-provider-checkbox'}
                    value={Boolean(accountFormData?.values?.dataProvider)}
                  />}
                  label={
                    <>
                      <Typography component="span" className={classes.checkBoxLabel}>
                        {t('account.accountClientType.dataProvider')}
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  control={<StyledCheckbox
                    checked={Boolean(accountFormData?.values?.dataClient)}
                    onChange={(e)=>{
                      if(e.target.checked ==true){
                      accountFormData?.setFieldValue('dataProvider', false)
                      }
                      accountFormData?.handleChange(e)
                    }
                  }
                    name="dataClient"
                    data-cy={'client-type-client-checkbox'}
                    value={Boolean(accountFormData?.values?.dataClient)}
                  />}
                  label={
                    <>
                      <Typography component="span" className={classes.checkBoxLabel}>
                        {t('account.accountClientType.dataClient')}
                      </Typography>
                    </>
                  }
                />
              </CheckBoxGroupBlock>
              {accountFormData.errors.dataClientProvider &&
               <FormHelperText
                   style={{color: 'red', fontSize: 10}}
               >
                 {accountFormData.errors.dataClientProvider}
               </FormHelperText>}
            
            </div>
            {(accountFormData.values?.dataProvider && creationMode) &&
              <div style={{marginTop:'2rem'}}>
                <InputFieldLabel required={true} htmlFor="bootstrap-input">
                  AWS region
                </InputFieldLabel>
                <TextFieldBlock>
                  <Select
                    labelId="awsRegion"
                    id="awsRegion"
                    name="awsRegion"
                    variant={'standard'}
                    onChange={accountFormData.handleChange}
                    fullWidth={true}
                    value={accountFormData.values.awsRegion}
                  >
                    {awsRegions.map(region => <MenuItem key={region.code} value={region.code}>{region.code} - {region.name}</MenuItem>)}
                  </Select>
                </TextFieldBlock>
              </div>
            }
          </div>
        </div>
        <div>
          <InputFieldLabel>
            {t('account.accountClientType.clientTypesDescription')}
          </InputFieldLabel>
          <Typography sx={{color: '#9DAABF'}}>
            {t('account.textAndWarnings.warnDetailSpecificTotype')}
          </Typography>
        </div>
      </DetailsBlockGrid>
    </>
  );
};