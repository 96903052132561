import { IAudiences } from "application/entities/dataTypes/audiences";
import { DspCampaignInfo, DspItems } from "application/entities/dataTypes/dspCampaignInfo";

export const ConvertEnabledAudienceCheckoutParamsToDspItems = (audience: IAudiences): DspItems[] => {
  if (!audience?.dspCampaignInfo) return [];
  const dsps = Array.from(audience?.dspCampaignInfo);
  let dspList: DspItems[] = [];
  dsps.forEach((dsp: DspCampaignInfo) => {
    dsp.dspItems.forEach((item: DspItems) => {
      dspList.push(item);
    });
  });

  const audienceRecapWithCheckoutParams = audience.audienceActivationRecap
    ?.map((recap) => {
      if (recap.checkoutParameters && recap.checkoutParameters !== "{}") {
        const formattedCheckoutParams = JSON.parse(recap.checkoutParameters);
        const checkoutParams = {
          dspCode: recap.dspCode,
          dspName: recap.dspName,
          checkoutParameters: formattedCheckoutParams,
        };
        return checkoutParams;
      }
      return null;
    })
    .filter((el) => el !== null);

  const activatedDspCode = audience?.audienceActivationRecap?.map((recap) => recap.dspCode);

  const activatedDspList = dspList
    .filter((dsp: DspItems) => activatedDspCode?.includes(dsp.code))
    .filter((dsp: DspItems) => dsp.dspCheckoutParameterGroups.length > 0);

  activatedDspList?.map((dsp: DspItems) => {
    const activeParams = audienceRecapWithCheckoutParams?.find((recap) => recap?.dspCode === dsp.code)?.checkoutParameters;
    dsp.dspName = audienceRecapWithCheckoutParams?.find((recap) => recap?.dspCode === dsp.code)?.dspName;
    if (activeParams) {
      dsp.dspCheckoutParameterGroups?.map((group) => {
        return group.dspCheckoutParameters?.map((param) => {
          param.selected = activeParams[param.code];
          return param;
        });
      });
    }
    return dsp;
  });

  return activatedDspList;
};
