import {Box, Button, styled, Theme} from '@mui/material';
import {PageId} from 'application/pages/pages.config';
import {MainFrame} from 'components/templates/MainFrame';
import {useForm, FormProvider} from 'react-hook-form';
import {FooterBlock, FormBlock, ShadowBox} from '../../components/templates/InfoBlock';
import {CompanyForm} from './shared/CompanyForm';
import React from 'react';
import {useUiStore} from '../../redux/ui.store';

export const CreateCompany = () => {
  // account tracker and mode setter
  useUiStore.setState({accountMode: 'COMPANY'});
  
  // form to check input data
  const methods = useForm({mode: 'all'});
  const {
          handleSubmit,
          reset,
          formState: {isValid},
        }       = methods;
  
  function onSubmit(dta: any) {
    console.log(dta);
  }
  
  return <MainFrame checkPageAccess={true} frameId={PageId.companyCreate}>
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={'flex-h gap-medium pad-2'}>
          <FormBlock title={'Company Settings'}>
            <CompanyForm  />
          </FormBlock>
        </div>
        <FooterBlock className="flex-h">
          <div className="flex-main"></div>
          <Button data-cy="save-btn" type="submit" disabled={!isValid} variant={'contained'}>
            Save
          </Button>
        </FooterBlock>
      </Form>
    </FormProvider>
  </MainFrame>;
};

const Form = styled('form')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
}));