import {Box, Divider, Skeleton, styled, Theme, Typography} from "@mui/material";

import {Columns} from "components/ui/AppElements";
import _ from "lodash";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {AudienceCardMain} from "pages/AudienceBuilder/components/CardsZone/AudienceCardMain";
import {AudienceFeatureNode} from "pages/AudienceBuilder/components/CardsZone/AudienceFeatureNode";
import {IElementsData} from "pages/AudienceBuilder/types/audienceBuilderTypes";
import React from "react";

export const AudienceViewAttributes = (props: { loading?: boolean; previewMode?: boolean }) => {
    const {loading, previewMode} = props;
    const audience = useAudienceBuilderStore((st) => st.audience);
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues);
    const columns = useAudienceBuilderStore(
        (st) => {
            const template = st.audienceTemplate;
            let columns: any = [];
            //prepare columns. There is a condition, cause template is made to build menu, we have to convert it to columns
            if (template.length === 1) {
                // for template with one tab in menu, we set 1 column by feature
                template[0].features.map((templateFeature) => {
                    let cards: any = [];
                    if (Array.isArray(templateFeature.subFeatures) && templateFeature.subFeatures.length > 0) {
                        templateFeature.subFeatures.map((subFeature) => {
                            cards.push(subFeature.code);
                        });
                    } else {
                        cards.push(templateFeature.code);
                    }
                    columns.push({
                        name: templateFeature.name,
                        cards: cards,
                    });
                });
            } else {
                // for template with multiple tabs in menu, we set 1 column by tab
                template.map((templateItem) => {
                    let cards: any = [];
                    templateItem.features.map((templateFeature) => {

                        if (Array.isArray(templateFeature.subFeatures) && templateFeature.subFeatures.length > 0) {
                            templateFeature.subFeatures.map((subFeature) => {
                                cards.push(subFeature.code);
                            });
                        } else {
                            cards.push(templateFeature.code);
                        }
                    });
                    columns.push({
                        name: templateItem.featureGroupName,
                        cards: cards,
                    });
                });
            }

            return columns;
        },
        (old, ne) => _.isEqual(old, ne)
    );

    const isColumnEmpty = (column: any) => {
        let isEmpty = true;
        column.cards.map((card: any) => {
            if (filtersValues.find((filterValue) => filterValue.code === card && filterValue.selected.length > 0)) {
                isEmpty = false;
            }
        });
        return isEmpty;
    };

    return (
        <>
            {!previewMode && (
                <>
                    <Typography variant={"h5"} style={{paddingBottom: "10px"}}>
                        Audience attributes
                    </Typography>
                    <Divider style={{marginBottom: "30px"}}/>
                </>
            )}
            {loading ? (
                <Skeleton variant="rectangular" height={200} style={{borderRadius: "10px"}}/>
            ) : (
                <>
                    <Columns style={{width: "100%", height: "auto"}}>
                        <Box sx={{pb: 2, display: "flex", flex: 1}}>
                            <div style={{flex: 1}}>
                                <Box sx={{pt: 0}}>
                                    <AudienceCardMain id={audience.id} data={{} as IElementsData} viewMode={true}/>
                                </Box>
                            </div>
                        </Box>
                    </Columns>

                    <Columns>
                        <AudienceColumnsContainer>
                            {columns?.map((col: any, index: number) => {
                                return (
                                    !isColumnEmpty(col) && (
                                        <div key={col.name}>
                                            <Label sx={{pb: 2}}>{col.name} </Label>
                                            <Box sx={{display: "grid", gap: "16px"}}>
                                                {col.cards.map((card: any) => {
                                                    const filteredValues = [...filtersValues];
                                                    const result = filteredValues.filter(
                                                        (filterValue) => card === filterValue?.code && Array.isArray(filterValue?.selected) && filterValue?.selected.length > 0
                                                    );
                                                    return result.map((item) => {
                                                        return (
                                                            <AudienceFeatureNode code={card} viewMode={true}>
                                                                {item.code}
                                                            </AudienceFeatureNode>
                                                        );
                                                    });
                                                })}
                                            </Box>
                                        </div>
                                    )
                                );
                            })}
                        </AudienceColumnsContainer>
                    </Columns>
                </>
            )}
        </>
    );
};

const AudienceColumnsContainer = styled(Box)((props: { theme: Theme }) => ({
    display: "flex",
    gap: "32px",
}));

const Label = styled(Box)((props) => ({
    color: "#7587A3",
    fontWeight: "bold",
}));
