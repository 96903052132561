import {Typography} from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {DetailBlock} from '../../../components/templates/InfoBlock';
import {InputLabel} from '../../../components/form/Input';


interface ICompanyDetailsProps {
  account: IAccounts | Partial<IAccounts>;
  accountIndustry?: any;
}

export const ViewAccountDetails: React.FC<ICompanyDetailsProps> = ({
                                                                     account,
                                                                     accountIndustry,
                                                                   }) => {
  // @ts-ignore
  const companyUrlList = account?.companyUrlList?.join('\n') ?? '';
  const {t}         = useTranslation();
  return (
    <>
      <CompanyDetailsBlock>
        <div>
          <InputLabel>
            {t('account.accountDetailsLabels.companyName')}
          </InputLabel>
          <TextFieldBlock>
            <Typography>{account.companyName}</Typography>
          </TextFieldBlock>
        </div>
        <div>
          <InputLabel>
            {t('account.accountDetailsLabels.industry')}
          </InputLabel>
          <TextFieldBlock>
            {Boolean(account.industries) && account.industries.map((res: any) => res.name)}
          </TextFieldBlock>
        </div>
        <div>
          <div>
            <InputLabel>
              {t('account.accountDetailsLabels.companySize')}
            </InputLabel>
            <TextFieldBlock>
              <Typography>{account.companySize}</Typography>
            </TextFieldBlock>
          </div>
        </div>
        <div>
          <InputLabel>
            {t('account.accountDetailsLabels.companyUrl')}
          </InputLabel>
          <TextFieldBlock>
            <Typography>{companyUrlList}</Typography>
          </TextFieldBlock>
        </div>
      </CompanyDetailsBlock>
    </>
  );
};

const CompanyDetailsBlock = styled(DetailBlock)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;


const TextFieldBlock = styled.div`
`;