import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import {granter} from "../../application/utils/granter.utils";
import {AUTHUser} from "../../application/utils/AuthUser";
import {getFlatPaths, PageId} from "../../application/pages/pages.config";
import {pageComponents} from "../../application/pages/pages.components";
import {pageUtils} from "../../application/pages/pages.utils";

export const AppRoutes = () => {
    const routes = getFlatPaths();

    const SignOut = () => {
        return <></>;
    };

    let redirect: any;
    //redirecting depending on account type
    const accountType = AUTHUser.accountType ?? "DATAPROVIDER";

    if (AUTHUser.isEvorraAdmin()) {
        const homeEvorraDashboard = pageUtils.getPagePathById(PageId.EvorraDashboard, {
            companyId: AUTHUser.getUserAccount().id,
        });
        redirect = <Route path="*" element={<Navigate replace to={homeEvorraDashboard}/>}/>;
    } else if (accountType === "DATAPROVIDER") {
        redirect = <Route path="*"
                          element={<Navigate replace to={pageUtils.getPagePathById(PageId.dashboardDataProvider)}/>}/>;
    } else {
        redirect = <Route path="*" element={<Navigate replace to={"companies/" + AUTHUser.accountId}/>}/>;
    }

    return (
        <Routes>
            {Object.keys(routes).map((page: any) => {
                let test: boolean = true;

                const pageData = routes[page];
                const Component = pageComponents[pageData.id as PageId]; //  pageData.component as any;
                //
                if (pageData._accesses) test = granter.grantAccesses(pageData._accesses).with(AUTHUser.granterUser).toUser(AUTHUser.granterUser);

                if (Component) return <Route key={page} path={pageData.path} element={<Component/>}/>;
            })}
            {redirect}
            <Route path="/auth/user_invitation/:email" element={<SignOut/>}/>
        </Routes>
    );
};
