import { Typography, Fade, Grid, styled, Box, Button, Divider } from "@mui/material";
import { IDspChannels } from "application/entities/dataTypes/dspChannels";
import { providerIconList } from "assets/providerIcons";
import { AppIcon } from "components/ui/AppIcon";
import { useChannelsSetupStore } from "../ChannelsSetupStore";
import { pageUtils } from "application/pages/pages.utils";
import { PageId } from "application/pages/pages.config";
import { useNavigate } from "react-router";
import { useUiStore } from "redux/ui.store";

export const ChannelSelection = (props: { unselectedDsps: IDspChannels[] }) => {
  const { unselectedDsps } = props;

  const channelsSetupStore = useChannelsSetupStore();
  const navigate = useNavigate();

  const currentBrand = useUiStore()?.currentBrand;
  const handleSelectDsp = (dspCode: string) => {
    const dspSelected: IDspChannels | undefined = unselectedDsps.find((dsp) => dsp.code === dspCode);
    channelsSetupStore.setDspSelected(dspSelected);
    channelsSetupStore.setCurrentStep(2);
  };
  return (
    <>
      <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Typography variant="h2">Channel Selection</Typography>
        </Box>
        <Button variant="outlined" onClick={() => navigate(pageUtils.getPagePathById(PageId.channelsSetup, { accountId: +currentBrand!.id }))}>
          Back
        </Button>
      </Box>

      <Divider />

      <Typography variant="body1" mb={4} mt={2}>
        Select a channel to configure in order to use it in the audience enablement process
      </Typography>
      <Fade in={true} timeout={1000} unmountOnExit>
        <Grid container spacing={5} direction="row" alignItems="center">
          {unselectedDsps.map((dsp) => {
            return (
              <Grid item key={dsp.id} xs={6} md={3} lg={2} sx={{ textAlign: "center" }}>
                <DspButton data-cy={`icon-${dsp.code}`} onClick={() => handleSelectDsp(dsp.code)}>
                  <AppIcon className="dsp-logo" fontSize={"medium"} icon={providerIconList[dsp.code]} />
                  <Typography variant="body1" textAlign={"center"} mt={1} color={"GrayText"}>
                    {dsp.name}
                  </Typography>
                </DspButton>
              </Grid>
            );
          })}
        </Grid>
      </Fade>
    </>
  );
};

const DspButton = styled("div")(() => ({
  cursor: "pointer",
  "&:hover .dsp-logo": {
    scale: "1.08",
    transition: "all 0.2s ease-in-out",
  },
}));
