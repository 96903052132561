import {apiService} from '../api/apiService';
import {ICountry} from '../../../types';
import {dataUtils} from '../../utils/dataState.utils';
import {IIndustry} from '../dataTypes/industries';
import {AbstractBaseApi} from './AbstractBaseApi';
import {IRules} from '../dataTypes/rules';

export class Rules extends AbstractBaseApi<IRules> {

  private apiCountries
  private apiIndustries

  constructor() {
    super('rules');
    this.apiCountries  = apiService.entity(['rules', 'countries']);
    this.apiIndustries = apiService.entity(['rules', 'industries']);
  }

  private static prepare(data: Partial<IRules>): Partial<IRules> {
    const p: Partial<IRules> = {...data}

    delete p.features;
    delete p.industryIds;

    if (p?.id === 0) delete p.id;

    if (p.locations && p.locations.length) {
      p.locations = dataUtils.collectObjectField(
        p.locations,
        'code',
      );
    }

    if (p.industries && p.industries.length) {
      const industriesIds: number[] = dataUtils.collectObjectField(
        p.industries,
        'id',
      );
      p.industries = industriesIds.map((item: number) => `/industries/${item}`);
    }

    return p;
  }


  create(data: Partial<IRules>) {
    return super.create(Rules.prepare(data))
  }

  update(id: number, data: Partial<IRules>) {
    return super.update(id, Rules.prepare(data))
  }
  
}