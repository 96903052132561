import {FormControl, FormControlLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import styledOld from "styled-components/macro";
import {
  AudiencePlanIcon,
  ChevronDownBlue,
  EnterprisePlanIcon,
  EnterprisePlusPlanIcon,
  NoSubscriptionPlan,
  TeamPlanIcon,
} from "../../../assets/icons";
import {StyledCheckbox} from "../../../components/form/CheckBox";
import {NumberInput} from "../../../components/form/InputAnna";
import {useStylesSelectNumber} from "../../../components/form/Select/select.styled";
import {ISubscriptionPlan, Plan} from "../../../types";
import {ISubscriptions} from "../../../application/entities/dataTypes/subscriptions";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {EUserGrade} from "../../../application/utils/granter.utils";
import {useTranslation} from "react-i18next";

interface ISubscriptionPlanProps {
    subscription: Partial<ISubscriptions>;
    setSubscription: (arg0: Partial<ISubscriptions>) => void;
    subscriptionPlansList: ISubscriptionPlan[];
}

export const EditAccountSubscriptionPlan: React.FC<ISubscriptionPlanProps> = ({
                                                                                  subscription,
                                                                                  setSubscription,
                                                                                  subscriptionPlansList
                                                                              }) => {
    const classes = useStylesSelectNumber();
    const [checkedPlan, setCheckedPlan] = React.useState<any>(subscription.subscriptionPlanId);
    const [subscriptionItems, setSubscriptionItems] = React.useState<Record<string, any>>({});
    const {t} = useTranslation();

    const getPlanIcon = (plan: string) => {
        switch (plan) {
            case Plan.NoSubscription:
                return <NoSubscriptionPlan/>;
            case Plan.Audience:
                return <AudiencePlanIcon/>;
            case Plan.Enterprise:
                return <EnterprisePlanIcon/>;
            case Plan.EnterprisePlus:
                return <EnterprisePlusPlanIcon/>;
            case Plan.Teams:
                return <TeamPlanIcon/>;
            default:
                return;
        }
    };

    React.useEffect(() => {
        subscription && setCheckedPlan(subscription.subscriptionPlanId);
        subscription && setSubscriptionItems({[`${subscription.subscriptionPlanId}`]: subscription.maxUsers});
    }, [subscription.subscriptionPlanId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const plan = +event.target.id;
        setCheckedPlan(plan);
        setSubscription({...subscription, subscriptionPlanId: plan});
    };

    const handleChangeMaxNbUsers = (event: any) => {
        const number = event.target.value as number;
        setSubscriptionItems(() => ({
            ...subscriptionItems,
            [checkedPlan]: number,
        }));
        setSubscription({...subscription, maxUsers: number});
    };

    return (
        <>
            {subscriptionPlansList.map((item) => {
                const listNumbers = Array.from({length: 30}, (v, k) => k + 1);
                return (
                    <Container key={item.id} data-cy={"subscription-plan-item"}>
                        <FormControlLabel
                            control={
                                <StyledCheckbox
                                    checked={item.id === checkedPlan}
                                    onChange={handleChange}
                                    name={item.plan}
                                    disabled={AUTHUser.getUser().authorities?.includes(EUserGrade.CONTRIBUTOR)}
                                    id={item.id as string}
                                />
                            }
                            label=""
                        />
                        {getPlanIcon(item.plan)}
                        <TitleBlock>
                            <PlanTitle>{item.plan}</PlanTitle>

                            <ShortDescription>{item.shortDescription}</ShortDescription>
                        </TitleBlock>

                        <MaxNumberBlock>
                            <SelectUsers>
                                <span> {t("account.generalTitles.selectUsers")}</span>
                            </SelectUsers>
                            {item.id === checkedPlan ? (
                                <SelectBlock>
                                    <FormControl size="small">
                                        <Select
                                            className={classes.root}
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={subscription?.maxUsers ?? subscriptionItems[item.id] ?? item.maxNbUsers} // CMVP-1698
                                            onChange={handleChangeMaxNbUsers}
                                            IconComponent={ChevronDownBlue}
                                            input={<NumberInput/>}
                                            disabled={!AUTHUser.isEvorraAdmin()}
                                        >
                                            {listNumbers.map((number) => (
                                                <MenuItem key={"key_" + number} value={number}>
                                                    {number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </SelectBlock>
                            ) : (
                                <MaxNumberUsers>{subscriptionItems[item.id] ?? item.maxNbUsers}</MaxNumberUsers>
                            )}
                        </MaxNumberBlock>

                        <Description>{item.description}</Description>
                    </Container>
                );
            })}
        </>
    );
};

const Container = styledOld.div`
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0 5px 11px rgba(196, 211, 241, 0.6);
  border-radius: 10px;
  margin-top: 12px;
`;

const TitleBlock = styledOld.div`
  margin-left: 10px;
  width: 145px;
`;

const PlanTitle = styledOld.div`

  color: #3B4559;
  font-size: 14px;
  font-weight: bolder;
`;

const ShortDescription = styledOld.div`
  font-size: 12px;
  font-weight: normal;
  color: #9DAABF;
`;

const MaxNumberBlock = styledOld.div`
  background: #F7F8FB;
  height: 100%;
  width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectUsers = styledOld.div`
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #7587A3;
`;

const SelectBlock = styledOld.div`
  border: 1px solid transparent;
  margin: 5px 0;
  //width: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3B4559;
`;

const MaxNumberUsers = styledOld.div`
  border: 2px solid transparent;
  margin: 5px;
  width: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3B4559;
`;

const Description = styledOld.div`
  width: 210px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #9DAABF;
  padding-left: 15px;
`;
