import { Checkbox, CheckboxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';


const useStylesCheckbox = makeStyles({
  root: {
  },
  icon: {
    borderRadius: 3,
    width: 14,
    height: 14,
    boxShadow: '0px 0px 6px rgba(196, 211, 241, 0.85)',
    border: '1px solid #9DAABF',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(157, 170, 191, 1)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'transparent',
      boxShadow: '0px 0px 6px rgba(196, 211, 241, 0.85)',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
      border: '1px solid transparent',
    },
  },
  checkedIcon: {
    backgroundColor: '#00B5E2',
    border: '1px solid transparent',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 12,
      height: 12,
      backgroundImage:
        'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
        ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
        '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#00B5E2',
      boxShadow: '0px 0px 6px rgba(196, 211, 241, 0.85)',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }
});


export const StyledCheckbox = (props: CheckboxProps) => {
  const classes = useStylesCheckbox();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
};
