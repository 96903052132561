import { objectApi } from "application/entities/dataApi";
import { IDspChannels } from "application/entities/dataTypes/dspChannels";
import { IDspChannelsSelections } from "application/entities/dataTypes/dspUserSelections";
import { useQuery } from "react-query";



export const useSelectedDspChannels = (accountId: number) => {  
    const accountsApi = new objectApi.accounts();
    const {isLoading, error, data, refetch, remove} = useQuery<IDspChannelsSelections[]>(['account', +accountId, 'dspChannelsSelections','dspChannels'], () =>
    {
        return accountsApi.getDspChannelsSelections(+accountId)
        .then(res => res?.data?._embedded?.dspChannelsSelections);
    },{
        enabled:Boolean(accountId),
        cacheTime: 30000
    }); 
    return {isLoading, error, data, refetch, remove};
}

export const useAllDspChannels = (accountId: number) => {
    const accountsApi = new objectApi.accounts();
    const {isLoading, error, data, refetch, remove } = useQuery<IDspChannels[]>(['dspChannelsParameters', +accountId,'dspChannels'], () => 
    {
        return accountsApi.GetDspChannels(+accountId)
        .then(res => res?.data?._embedded?.dspChannels)
    },{
        enabled:Boolean(accountId),
        cacheTime: 1000 * 60 * 60
    });
    return {isLoading, error, data, refetch, remove};
}