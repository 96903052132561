import {Box, Typography} from "@mui/material";
import React from "react";
import {Warning} from "@mui/icons-material";

export const CPMInfos = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "7px",
                padding: "1rem",
                marginTop: "1rem",
                display: 'flex',
                alignItems: 'center',
                gap: '1rem'
            }}
        >
            <Warning sx={{color: "#7587a3"}}/>
            <Box>
                <Typography variant="body1" color="#7587a3">
                    Any changes to the CPM are not applied retrospectively. They are applied to audiences created post
                    the
                    CPM change and to any audiences which
                    have not been enabled
                </Typography>
                <Typography variant="body1" color="#7587a3" pt={1}>
                    If you wish to apply the new CPM to a previously enabled audience, you will need to duplicate that
                    audience and re-enable the audience to your
                    platform of choice.
                </Typography>

            </Box>
        </Box>
    );
};