import {IFiltersValues} from "../../../types/audienceBuilderTypes";
import {FeatureLabel} from "../audienceFeature";
import {Box} from "@mui/material";

type AgeOfChildrenFeatureProps = {
    filterValue: IFiltersValues
}
export const AgeOfChildrenFeature = (props: AgeOfChildrenFeatureProps) => {

    return <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "4px",
        padding: "4px"
    }}>
        {props.filterValue.selected.sort((a, b) => a.min - b.min).map((item, index) => {
            const value = `${item.min} - ${item.max}`
            return <FeatureLabel data-cy={"arrayLabel"} data-cy-value={value} key={item.min}>
                {value}
            </FeatureLabel>
        })}


    </Box>
}