import React from "react";
import {MenuItem} from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";


export const SegmentProviderFilter = () => {

    const providers = useSegmentBuilderStore(st => st.providersList)

    const providerCode = useSegmentBuilderStore(st => st.providerCode)


    const handleChange = (event: SelectChangeEvent<typeof providerCode>) => {
        const {
            target: {value},
        } = event;
        console.log('event', value)
        if (Array.isArray(value)) {

            useSegmentBuilderStore.setState({providerCode: value.includes('') ? [] : value})
        }

    }

    return <>
        <Select value={providerCode}
                renderValue={(value) => {
                    if (value?.length === 0) return <>All Providers</>
                    return <>{value.length} selected</>
                }}
                multiple={true}
                displayEmpty
                disabled={providers.length === 1}
                onChange={handleChange}
                sx={{width: '200px', height: '40px'}}>
            {providers.map(({value, label}) => <MenuItem key={label}
                                                         value={value}>{label}</MenuItem>)}
        </Select>
    </>
}