import {IExternalSegment} from "../../application/entities/dataTypes/externalSegment";
import {moneyFormatter} from "../../application/utils/formatters.utils";


export const DEFAULT_DISTANCE = 0.24

export const getTotalIds = (segment: IExternalSegment) => {
    return (segment?.numAndroidids ?? 0) + (segment?.numHems ?? 0) + (segment?.numId5s || 0) + (segment?.numIosids || 0) + (segment?.numIps || 0) + (segment?.numPanoids || 0) + (segment?.numPubmaticCookies || 0) + (segment?.numRampids || 0) + (segment?.numTtdids || 0) + (segment?.numUid20s || 0)
}

export const getTotalIdsSegmentsList = (segments: IExternalSegment[]) => {
    return segments.reduce((acc, segment) => {
        return acc + getTotalIds(segment)
    }, 0)
}


type IdCodeType = keyof Pick<IExternalSegment,
    'numAndroidids' | 'numHems' | 'numId5s' | 'numIosids' |
    'numIps' | 'numPanoids' | 'numPubmaticCookies' |
    'numRampids' | 'numTtdids' | 'numUid20s'
>;

export type IdCount = {
    idName: IdCodeType;
    total: number;
}

export const getTotalIdsForSegmentsList = (segments: IExternalSegment[] | undefined): IdCount[] => {
    if (!segments) return []
    const idKeys: IdCodeType[] = [
        'numAndroidids',
        'numHems',
        'numId5s',
        'numIosids',
        'numIps',
        'numPanoids',
        'numPubmaticCookies',
        'numRampids',
        'numTtdids',
        'numUid20s'
    ];

    return idKeys.map(key => ({
        idName: key,
        total: segments.reduce((sum, segment) =>
            sum + (segment[key] || 0), 0
        )
    })).filter((idKey) => idKey.total > 0);
}


export const getTotalFromIdsCountList = (idsCount: IdCount[]) => {
    return idsCount.reduce((sum, idCount) => sum + idCount.total, 0)
}

export const getMaxFromIdsCountList = (idsCount: IdCount[]) => {
    return idsCount.reduce((max, idCount) => Math.max(max, idCount.total), 0)
}


export const getIdName = (idCode: IdCodeType) => {
    switch (idCode) {
        case 'numAndroidids':
            return 'Android Ids'
        case 'numHems':
            return 'Hashed Emails'
        case 'numId5s':
            return 'Id5'
        case 'numIosids':
            return 'Ios Ids'
        case 'numIps':
            return 'Ips'
        case 'numPanoids':
            return 'PanoID'
        case 'numPubmaticCookies':
            return 'Pubmatic Cookies'
        case 'numRampids':
            return 'Ramp Ids'
        case 'numTtdids':
            return 'The Trade Desk Ids'
        case 'numUid20s':
            return 'Uid2.0'
        default:
            return ''
    }

}

export const getCPMFromSegmentsList = (segments: IExternalSegment[] | undefined) => {
    if (!segments) return "Not set"
    const segmentCpmMin = segments.reduce((acc, segment) => {
        if (segment.segmentCpm) {
            if (acc === 0) {
                return segment.segmentCpm
            }
            if (segment.segmentCpm < acc) return segment.segmentCpm
        }
        return acc
    }, 0)

    const segmentCpmMax = segments.reduce((acc, segment) => {
        if (segment.segmentCpm) {
            if (segment.segmentCpm > acc) return segment.segmentCpm
        }
        return acc
    }, 0)

    const getCPMToDisplay = () => {
        if (segmentCpmMax + segmentCpmMin === 0) {
            return "Not set"
        }
        if (segmentCpmMin === segmentCpmMax) {
            return `${moneyFormatter(segmentCpmMin ?? 0, {currency: "USD"})}`
        }
        return `${moneyFormatter(segmentCpmMin ?? 0, {currency: "USD"})} - ${moneyFormatter(segmentCpmMax ?? 0, {currency: "USD"})}`
    }

    return getCPMToDisplay()
}