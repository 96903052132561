import { Box, styled, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useUserQuery } from "../../../components/hooks/data/useUserQuery";
import { ICompanyUsers } from "../../../application/entities/dataTypes/companyUser";

interface IUserAssociationsProps {
  userId: any;
}

export const UserBrandAssociationTable: FunctionComponent<IUserAssociationsProps> = (props: IUserAssociationsProps) => {
  const uriParams = useParams<{ userId: string }>() as { userId: string };
  const userId = props?.userId ?? +uriParams?.userId;

  const userQuery = useUserQuery({ userId: userId });
  const userData: ICompanyUsers = userQuery?.data ?? {};

  const brandAssocList = userData.agentBrandAccountAssociations ?? [];

  return (
    <table data-cy="accountAssociations" className={"accountAssociations"} style={{ width: "100%" }}>
      <thead>
        <tr data-cy={"table-headers"} style={{ display: "block" }}>
          <td style={{ minWidth: "150px", width: "150px", maxWidth: "150px" }}>Brand</td>
          <td style={{ minWidth: "120px" }}>Role</td>
          <td style={{ minWidth: "110px" }}>Active since</td>
          <td style={{ width: "80px", textAlign: "center" }}>Approval required</td>
        </tr>
      </thead>
      <tbody style={{ maxHeight: "calc(100vh - 620px)", overflowY: "auto", display: "block" }}>
        {brandAssocList.map((brandAssoc) => {
          const newDate = moment(brandAssoc.creationTime).format("D-MMM-YYYY");
          return (
            <tr style={{ display: "block" }} key={brandAssoc.account.id} data-cy={"row-brand-permission"}>
              <td style={{ minWidth: "150px", width: "150px", maxWidth: "150px" }}>
                <Typography data-cy={"brand-name"} title={brandAssoc.account.companyName} noWrap>
                  {brandAssoc.account.companyName}
                </Typography>
              </td>
              <td style={{ minWidth: "120px", textTransform: "capitalize" }}>
                <Typography data-cy={"brand-role"} noWrap>
                  {brandAssoc.role.toLowerCase() === "contributor"
                    ? "Viewer"
                    : brandAssoc.role.toLowerCase() === "administrator"
                    ? "Administrator"
                    : brandAssoc.role.toLowerCase() === "creator"
                    ? "creator"
                    : ""}
                </Typography>
              </td>
              <td style={{ minWidth: "110px" }} data-cy={"brand-activation"}>
                {newDate}
              </td>
              <td style={{ width: "80px", textAlign: "center" }}>
                {(brandAssoc.approvalRequired && brandAssoc.role !== "CONTRIBUTOR") === true
                  ? brandAssoc?.approvalPriceLimit
                    ? `From $${brandAssoc.approvalPriceLimit}`
                    : "Always"
                  : brandAssoc?.role === "CREATOR"
                  ? "Not required"
                  : "Not applicable"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
