import * as React from 'react';
import {TIcon} from '../../../assets/icons';
import {Elem} from '../AppElements';
import styled from 'styled-components';
import {AppIcon} from '../AppIcon';
import {Box} from '@mui/material';

export interface IMenuLabelProps {
  data?: any,
  text: string,
  index?: number,
  icon?: TIcon,
  helperText?: string
  onRemove?: Function
  onClick?: Function
  dense?: boolean
};

export const MenuLabel = (props: IMenuLabelProps) => {
  
  const {data, index, text, onRemove, dense, onClick} = props;
  
  const padding           = dense ? 0 : 0.5;
  const handleClickRemove = (data: any, index?: number) => {
    onRemove && onRemove(data, index);
  };
  
  return (
    <MenuLabelContainer onClick={() => {if (onClick) onClick();}} data-cy={'MenuLabel'}>
      <Box sx={{py: padding, px: 1, flex: 1}}>{text}</Box>
      {onRemove && <TagCloser  data-cy={'MenuLabelCloser'}>
          <AppIcon color={'#ccc'} onClick={() => {
            handleClickRemove(data, index);
          }} fontSize={'small'} display={'block'} icon={'Close'}/>
      </TagCloser>}
    </MenuLabelContainer>
  );
};

const TagCloser = styled(Box)({
  // color : '#7587A3',
  cursor: 'pointer',
  //right : '0.25rem',
});

const MenuLabelContainer = styled(Elem)({
  display     : 'inline-flex!important',
  alignItems  : 'center',
  borderRadius: '80px',
  marginLeft  : 0,
  position    : 'relative',
  textOverflow: 'ellipsis',
  overflow    : 'hidden',
  whiteSpace  : 'nowrap',
  [TagCloser] : {
    opacity: '0.8',
  },
  '&:hover'   : {
    [TagCloser]: {
      opacity: '1',
    }
  }
});
