import * as React from 'react';
import {Autocomplete, Button, ClickAwayListener, Popper, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import { useAudienceBuilderStore } from '../Store/AudienceBuilderStore';
import { objectApi } from 'application/entities/dataApi';
import { ITags } from 'application/entities/dataTypes/tags';
import { getObjectItemById, removeFragmentItemById } from 'application/utils/dataState.utils';
import { TagList } from 'components/organisms/TagList';
import { Elem, Columns, GridItemFull, GridItem } from 'components/ui/AppElements';

interface IAudienceBuilderTagProps {
}

export const AudienceTagsHeader = (props: IAudienceBuilderTagProps) => {
  
  const [dataTags, setDataTags]     = React.useState<ITags[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  
  const audienceActions = useAudienceBuilderStore.getState().actions;
  const audienceTags    = useAudienceBuilderStore((st) => st.audience.tags);
  
  const audienceData = useAudienceBuilderStore((st) => st.audience);
  
  const tagsApi     = new objectApi.tags();
  const accountsApi = new objectApi.accounts();
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open                    = Boolean(anchorEl);
  const {t}                     = useTranslation();
  
  React.useEffect(() => {
    if (audienceData?.account?.id) {
      initTags().then((tagList) => {
        if (tagList) setDataTags(tagList);
      }).catch(e => {});
    }
  }, [audienceData?.account?.id]);
  
  React.useEffect(() => {
    if (audienceTags) {
      let out: any[];
      out = filterTags(audienceTags?.length ? audienceTags : []);
      if (out?.length) setDataTags((currentState: ITags[]) => [...out]);
    }
  }, [audienceTags]);
  
  const initTags = async () => {
    
    return accountsApi.GetTags(audienceData?.account?.id, {page: {pageNumber: 0, pageSize: 150}, output: {part: 'EMBEDDED'}}).then((data) => {
      return data?.data?._embedded?.tags ?? [];
    }).catch(e => {
    });
  };
  
  // only display selected tags
  const filterTags = (data: any[]) => {
    return data.filter((tag: ITags) => {
      return !Boolean(getObjectItemById(audienceTags, tag.id).length);
    });
  };
  
  const removeTag = (id: number, index?: number) => {
    const newTags = removeFragmentItemById(audienceTags, id);
    audienceActions.updateAudienceTags(newTags);
    setInputValue('');
  };
  
  const addTag = (tag: ITags) => {
    const newData = audienceTags ? [...audienceTags] : [];
    
    newData.push(tag);
    
    audienceActions.updateAudienceTags(newData);
    
    setInputValue('');
    let out: any;
    out = filterTags(newData);
    // if (out) setTags(() => [...out]);
  };
  
  const addNewTag = async (value: string) => {
    if (value && value.length) {
      const newtag = await tagsApi.create({account: 'accounts/'+audienceData?.account?.id,name: value});
      addTag(newtag as unknown as ITags);
    }
  };
  
  const handleEllipsisClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const form = <Elem>
    <Columns>
      <GridItemFull style={{position: 'relative'}} padding={[0, 0.5, 0, 0]} width={12}>
        <Autocomplete
          id="combo-box"
          options={dataTags}
          autoHighlight
          disableClearable={true}
          fullWidth={true}
          getOptionLabel={(option) => option.name}
          onChange={(event: any, newValue: any) => {
            if (newValue) {
              addTag(newValue);
            }
          }}
          onInputChange={(event: any, newValue: any, reason: string) => {
            if (reason === 'input') {
              setInputValue(newValue);
            }
          }}
          renderOption={(props, option, {selected}) => <li {...props} key={option.name + option.id}>{option.name}</li>}
          renderInput={(params) => <TextField
            {...params}
            variant="standard"
            data-cy={'autoCompleteInputTag'}
            inputProps={{
              ...params.inputProps,
              value       : inputValue,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
          />}
        />
      </GridItemFull>
      <GridItem>
        <Button data-cy={'buttonAddNewTag'} disabled={!(inputValue && inputValue.length)} onClick={() => {addNewTag(inputValue);}} fullWidth={true}
                variant={'contained'}>{t('labels.add')}</Button>
      </GridItem>
    </Columns>
    {/*<Divider variant={'middle'}/>*/}
    <Elem padding={[1, 0, 0, 0]}>
      <TagList fullSize={true} onRemoveTag={removeTag} tags={audienceTags}/>
    </Elem>
  </Elem>;
  
  return <>
    <Button data-cy={'buttonAddTag'} size={'small'} onClick={handleEllipsisClick} style={{width: '90px', margin: '0 1rem'}} variant={'outlined'}>{t('labels.addTag')}</Button>
    <PopperContainer
      anchorEl={anchorEl}
      open={open}
      placement="bottom-start"
    ><ClickAwayListener onClickAway={handleCloseMenu}>
      <InnerPopper>
        {form}
      </InnerPopper>
    </ClickAwayListener>
    </PopperContainer>
  </>;
};

const PopperContainer = styled(Popper)((props: { theme: Theme }) => ({
  padding        : '0.5rem',
  maxHeight      : 'calc(100% - 2rem)',
  overflow       : 'hidden',
  zIndex         : 150,
  border         : '1px solid ' + props.theme.skin.borderColor,
  backgroundColor: props.theme.palette.background.paper,
  boxShadow      : props.theme.skin.boxShadow,
  borderRadius   : 20,
}));

const InnerPopper = styled(Elem)((props: { theme: Theme }) => ({
  zIndex   : 1000,
  maxHeight: '100%',
  overflow : 'auto',
  maxWidth : '350px'
}));