import * as yup from 'yup';


export const passwordValidationSchema = yup.object({
  newPassword: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});