import {Button, styled, TextField, Theme} from '@mui/material';
import {PageId} from 'application/pages/pages.config';
import {pageUtils} from 'application/pages/pages.utils';
import {MainFrame} from 'components/templates/MainFrame';
import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {FormProvider, useForm} from 'react-hook-form';
import {FieldZone, FormBlock, ShadowBox} from '../../components/templates/InfoBlock';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import React from 'react';
import {ViewSubscription} from '../Accounts/View/ViewSubscription';
import {useCountryQuery} from '../../components/hooks/data/useCountryQuery';
import {useTranslation} from 'react-i18next';
import {CompanyForm} from './shared/CompanyForm';
import {AccountHeader} from '../Agencies/AccountHeader';
import {useNavigate, useParams} from 'react-router-dom';
import {objectApi} from '../../application/entities/dataApi';
import {useAccountTracker} from '../Agencies/useAccountTracker';
import {useAppToast} from '../../components/live/AppToast';
import {BackendError} from '../../types/backendError';
import {useLoggedUser} from '../../components/hooks/useLoggedInUser';
import { queryClient } from 'application/utils/useQuery.utils';

interface UpdateCompanyProps {
  companyId?: number;
}

export const UpdateCompany = (props: UpdateCompanyProps) => {
  
  const uriParams = useParams<{ companyId: string; }>() as { companyId: string };
  const companyId = props?.companyId ?? +uriParams?.companyId;
  
  const {t}                    = useTranslation();
  const toast                  = useAppToast();
  const accountApi             = new objectApi.accounts();
  const accountQuery           = useAccountQuery(companyId);
  const accountData: IAccounts = accountQuery?.data ?? {};
  const countryQy              = useCountryQuery();
  // form to check input data
  const methods                = useForm({mode: 'all'});
  const navigate               = useNavigate();
  
  
  // used to reload credential informations
  const loggedInUser = useLoggedUser()

  // account tracker and mode setter
  useAccountTracker(accountData.id);
  
  const {
          handleSubmit,
          reset,
          register,
          formState: {isValid, errors},
        } = methods;
  
  
  const billingVatNumber = register('billingVatNumber', {minLength: 2});
  const billingEmail     = register('billingEmail', {minLength: 2, pattern: /^\S+@\S+$/i});
  
  
  React.useEffect(() => {
    if (Object.keys(accountData).length && countryQy?.data) {
      reset({
        ...accountData,
      });
    }
  }, [accountData, countryQy?.data]);
  
  async function onSubmit(account: any & Partial<IAccounts>) {
    
    const dtaAcc = {...account};
    
    delete dtaAcc.billingUser;
    
    delete dtaAcc.industryIds;
    delete dtaAcc.industries;
    delete dtaAcc.companyUsers;
    delete dtaAcc.subscription;
    delete dtaAcc.notes;
    delete dtaAcc.affinities;
    delete dtaAcc.vault;
    
    await accountApi.update(companyId, {...dtaAcc})
                    .then((rs) => {
                        toast.success({
                          message: 'The company was successfully updated'
                        });
                      queryClient.invalidateQueries('account')
                      loggedInUser.refetch();
                        navigate(pageUtils.getPagePathById(PageId.companyView, {companyId: companyId}));

                      }
                    )
                    .catch((e: BackendError) => {
                      toast.error({
                        message: e?.message ?? 'An error occurred'
                      });
                    });
    // update industries
    if (account.industries?.[0]?.id) await accountApi.updateIndustries(companyId, [account.industries?.[0]?.id]);
    
    await accountQuery.refetch();
    
  }
  const MAX_WIDTH = '1150px';
  return <MainFrame
    checkPageAccess={true}
    grantWith={{accountId: accountData.id}}
    frameId={PageId.companyUpdate}
    breadCrumbProps={{crumbTitle:'Company update', noParent: true}}
    mainFrameHeaderContent={<AccountHeader
      accountId={companyId}
      frameId={PageId.companyUpdate}/>}>
    <FormProvider {...methods}>
      <Form  onSubmit={handleSubmit(onSubmit)}>
        <div style={{padding:'2em',overflow: 'auto',maxWidth:MAX_WIDTH, display: 'grid', gridTemplateColumns:'3fr 2fr', gap:'40px'}}>
          
            <FormBlock title={'Company Settings'}>
              {Boolean(Object.keys(accountData).length) && <CompanyForm/>}        
            </FormBlock>
            <div>
              <FormBlock style={{height: 'auto'}} noBorder title={'Subscription Details'}>
                {Boolean(accountData.subscription) && <ViewSubscription
                    accountId={companyId} // CMVP-2098
                    subscription={{...accountData.subscription, subscriptionPlanId: accountData.subscriptionPlan.id}}
                />}
              </FormBlock>
                <FormBlock className={'shadow-box pad-2'} style={{height: 'auto'}} noTitlePadding={true} noBorder title={'Billing Information'}>
                  <FieldZone code={'billingEmail'} title={'Billing Contact Email'}>
                    <TextField {...billingEmail} error={Boolean(errors.billingEmail)} sx={{width: '250px'}}/>
                  </FieldZone>
                  <FieldZone code={'billingVatNumber'} title={'VAT / TAX number'}>
                    <TextField {...billingVatNumber} error={Boolean(errors.billingVatNumber)} sx={{width: '250px'}}/>
                  </FieldZone>
                </FormBlock>
            </div>
        </div>
        <div style={{paddingInline:'2em', maxWidth:MAX_WIDTH, display:'flex', justifyContent:'space-between'}}>
          <Button data-cy="save-cancel" onClick={() => {navigate(pageUtils.getPagePathById(PageId.companyView, {companyId: companyId}));}} variant={'outlined'} color={'error'}>
            Cancel
          </Button>
          <Button data-cy="save-btn" type="submit" disabled={!isValid} variant={'contained'}>
            Save
          </Button>
        </div>
      </Form>
    </FormProvider>
  </MainFrame>;
};


const Form = styled('form')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
}));


