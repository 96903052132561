import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Divider, InputLabel, TextField } from "@mui/material"
import { RegisterOptions, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { AUTHUser } from '../../../application/utils/AuthUser';
import { useAppToast } from "components/live/AppToast";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { IJiraIssue } from "application/entities/dataTypes/jiraIssue";
import { JiraIssue } from "application/entities/dataApi/JiraIssue";
import { objectApi } from "application/entities/dataApi";

export const JiraCollector = () => {
    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const appToast = useAppToast();
    const windowDialog = useOpenDialogWindow();
    const jiraIssueApi = new objectApi.jiraIssue();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        outline: 0
    };

    interface ReactHookFormItem {
        name: string;
        label: string;
        type: 'text' | 'password' | 'email' | 'number' | 'select' | 'checkbox' | 'radio' | 'date' | 'time' | 'url' | 'tel' | 'file' | 'range' | 'search' | 'hidden' | 'image' | 'submit' | 'reset' | 'button';
        placeholder?: string;
        options: RegisterOptions
    }

    const titleField: ReactHookFormItem = {
        name: 'title',
        label: 'Feedback Topic',
        type: 'text',
        options: {
            required: {
                value: true,
                message: 'Please enter a feedback topic'
            }
        }
    }

    const messageField: ReactHookFormItem = {
        name: 'message',
        label: 'Share feedback details below',
        type: 'text',
        options: {
            required: {
                value: true,
                message: 'Please enter details'
            }
        }
    }

    const {
        handleSubmit,
        control,
        register,
        getValues,
        formState: { isValid, errors },
    } = useForm({ mode: 'all' });

    const onSubmit = (values: any) => {
        setLoadingState(true);
        const user = AUTHUser.getUser();
        //use https://developer.atlassian.com/cloud/jira/platform/apis/document/playground/ to generate the description item
        // api doc: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-rest-api-3-issue-bulk-post
        const datas: IJiraIssue = {
            fields: {
                summary: values.title,
                project: {
                    key: "CMVP"
                },
                issuetype: {
                    id: "10004"
                },
                priority: {
                    id: "3"
                },
                description: {
                    type: "doc",
                    version: 1,
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "This ticket was created by feedback form in Evorra’s app",
                                    marks: [
                                        {
                                            type: "em"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: (user.firstName ? user.firstName : '') + ' ' + (user.lastName ? user.lastName : '') + ' wrote:',
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "blockquote",
                            content: [
                                {
                                    type: "paragraph",
                                    content: [
                                        {
                                            type: "text",
                                            text: values.message
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "rule"
                        },
                        {
                            type: "heading",
                            "attrs": {
                                "level": 2
                            },
                            content: [
                                {
                                    type: "text",
                                    text: "Account informations"
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "Email: ",
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                },
                                {
                                    type: "text",
                                    text: user.email
                                },
                                {
                                    type: "hardBreak"
                                },
                                {
                                    type: "text",
                                    text: "Company: ",
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                },
                                {
                                    type: "text",
                                    text: user.account.companyName
                                }
                            ]
                        },
                        {
                            type: "heading",
                            "attrs": {
                                "level": 2
                            },
                            content: [
                                {
                                    type: "text",
                                    text: "Tech informations"
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "UserAgent: ",
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                },
                                {
                                    type: "text",
                                    text: window?.navigator?.userAgent
                                },
                                {
                                    type: "hardBreak"
                                },
                                {
                                    type: "text",
                                    text: "Platform: ",
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                },
                                {
                                    type: "text",
                                    text: window?.navigator?.platform
                                },
                                {
                                    type: "hardBreak"
                                },
                                {
                                    type: "text",
                                    text: "Current URL: ",
                                    marks: [
                                        {
                                            type: "strong"
                                        }
                                    ]
                                },
                                {
                                    type: "text",
                                    text: window?.location?.href
                                }
                            ]
                        }
                    ]
                }
            }
        }

        // api call
        jiraIssueApi.postJiraIssue(datas).then(res => {
            setLoadingState(false);
            appToast.success({ message: 'Thank you for your feedback!' });
            windowDialog.close('jira-collector');
        }).catch(err => {
            console.error(err)
            setLoadingState(false);
            appToast.error({ message: 'Please try again' });
        })
    }


    return (
        <>
        <InnerContainer style={{width:'353px', padding: '0 41px'}}>
                    <Box style={{ fontSize: '12px', color: '#9DAABF', textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                        We’d love to hear from you. Use this form to share any ideas you have for improving the platform. Please highlight any issues you’d like us to address.
                    </Box>
                    </InnerContainer>
            <FormContainer onSubmit={handleSubmit(onSubmit)} style={{backgroundColor:'#fff'}}>
                    <InnerContainer >
                

                    <InputLabel required={true} style={{
                        color: '#9DAABF',
                        marginBottom: '0.25rem',
                        fontSize: 13,
                        fontWeight:'500'
                    }}>{titleField.label}</InputLabel>
                    <TextField
                        {...register(titleField.name, titleField.options)}
                        type={titleField.type}
                        name={titleField.name}
                        error={errors[titleField.name]}
                        style={{ width: '100%', background: 'transparent', marginBottom: '15px' }}
                        helperText={errors?.title?.message} />

                    <InputLabel required={true} style={{
                        color: '#9DAABF',
                        marginBottom: '0.25rem',
                        fontSize: 13,
                        fontWeight:'500'
                    }}>{messageField.label}</InputLabel>
                    <TextField
                        {...register(messageField.name, messageField.options)}
                        type={messageField.type}
                        name={messageField.name}
                        error={errors[messageField.name]}
                        helperText={errors?.message?.message}
                        style={{ width: '100%', background: 'transparent' }}
                        multiline
                        rows={4} />
                    <div style={{ padding: '0.5rem', display: 'flex', justifyContent: 'space-between', paddingRight: 0, textAlign: 'right', marginBlock: '1rem' }}>
                        <Button
                            id={'cancel'}
                            data-cy="login-button"
                            variant="outlined"
                            onClick={() => windowDialog.close('jira-collector')}
                        >
                            Cancel
                        </Button>
                        <Button
                            id={'cyLoginButton'}
                            data-cy="login-button"
                            variant="contained"
                            type={'submit'}
                            startIcon={loadingState ? <CircularProgress style={{ color: 'white' }} size={24} /> : undefined}
                            disabled={!isValid}
                        >
                            {loadingState ? 'Sending...' : 'Send'}
                        </Button>
                    </div>                    
                </InnerContainer>
            </FormContainer>
        </>
    )
}

const FormContainer = styled.form`
  overflow:hidden;
  width:353px;
  padding: 0 41px;
  
`;

const InnerContainer = styled.div`
  overflow: hidden;
  width:100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top:1rem;
`;
