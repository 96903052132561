import { Box, Checkbox, Typography } from "@mui/material";

type OptionBooleanProps = {
  label: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
};

export const OptionBoolean = (props: OptionBooleanProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked);
  };
  return (
    <Box sx={{ display: "flex", gap: "0.5rem" }}>
      <Checkbox size={"small"} name={props.label} checked={props.value} onChange={handleChange} />
      <Typography variant="body1">{props.label}</Typography>
    </Box>
  );
};
