import { Box, Button, ButtonGroup, Fade, Paper, Tooltip, Typography, styled } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { objectApi } from "application/entities/dataApi";
import { listFilesColumns } from "application/entities/dataColumns/listFiles.columns";
import { IUploadedList } from "application/entities/dataTypes/listFiles";
import { PageId } from "application/pages/pages.config";
import { dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { useDomainLists, useExclusionLists } from "components/hooks/data/useListsQuery";
import { GridTable } from "components/organisms/GridTable";
import { MainFrame } from "components/templates/MainFrame";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { useAccountTracker } from "pages/Agencies/useAccountTracker";

import { ActionButtons } from "components/controls/ActionButtons";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { AppIcon } from "components/ui/AppIcon";
import { useTranslation } from "react-i18next";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { actions } from "../../application/actions/actions";
import { UploadList } from "./UploadList";

export const Lists = () => {
  const { accountId } = useParams<{ accountId: string }>() as { accountId: string };
  useAccountTracker(+accountId);
  const dialogWindow = useOpenDialogWindow();
  const { t } = useTranslation();

  const accountsApi = new objectApi.accounts();

  const exclusionListsQuery = useExclusionLists(+accountId);

  const domainListsQuery = useDomainLists(+accountId);

  const { isLoading: exclusionLoading, data: exclusionData, error: exclusionError, refetch: exclusionRefetch } = exclusionListsQuery;

  const { isLoading: domainLoading, data: domainData, error: domainError, refetch: domainRefetch } = domainListsQuery;

  const parentAccountId = useQuery(
    ["parentAccountId", +accountId],
    () => {
      return accountsApi.byId(+accountId);
    },
    { enabled: Boolean(accountId) }
  );

  const [tabActive, setTabActive] = useState<number>(1);

  const uploadExclusionListDialog: IOpenDialogAction = {
    dialogId: "UploadListModal",
    component: UploadList,
    title: t("uploadLists.uploadExclusionList"),
    icon: "Upload",
    description: t("uploadLists.uploadExclusionListDescription"),
    componentProps: {
      accountId: accountId,
      listType: "exclusion",
    },
  };

  const handleOpenExclusionLists = () => {
    return null;
    dialogWindow.open(uploadExclusionListDialog).then();
  };

  const uploadDomainListDialog: IOpenDialogAction = {
    dialogId: "UploadListModal",
    component: UploadList,
    title: t("uploadLists.uploadDomainList"),
    icon: "Upload",
    description: t("uploadLists.uploadDomainListDescription"),
    componentProps: {
      accountId: accountId,
      listType: "domain",
    },
  };

  const handleOpenDomainLists = () => {
    dialogWindow.open(uploadDomainListDialog).then();
  };

  const getFileName = (key: string) => key?.split("/").pop()?.replaceAll("_", " ");

  const prepareToDataGrid = (arr: IUploadedList[]): object[] => {
    const list = arr.map((item, index) => {
      const fileName = getFileName(item.key);
      return { ...item, id: index, accountId: accountId, fileName: fileName };
    });
    return list;
  };

  const deleteList = (key: string) => {
    accountsApi.deleteUploadedList(+accountId, key).then(() => {
      exclusionRefetch();
      domainRefetch();
    });
  };
  const handleDelete = (key: string) => {
    dialogWindow.open({
      dialogId: "ListDelete",
      buttonActions: { acceptButton: true, cancelButton: true },
      title: "Delete this list?",
      description: "You will not be able to use it",
      icon: "Trash",
      onAccept: () => deleteList(key),
    });
  };

  actions.mylistsCard.deleteList.action = handleDelete;
  actions.mylistsCard.deleteList.code = "delete-list";
  actions.mylistsCard.deleteList.variant = "outlined";
  const columns: GridColDef[] = dataColumnModelUtils.renderModel(listFilesColumns, "show");
  columns[5].renderCell = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
        <ActionButtons actions={actions.mylistsCard} vars={params.row.key} />
      </div>
    );
  };
  const exclusionRows = exclusionData?.objects ? prepareToDataGrid(exclusionData.objects) : [];
  const exclusionDomains = domainData?.objects ? prepareToDataGrid(domainData.objects) : [];

  return (
    <>
      <MainFrame
        frameId={PageId.lists}
        scrollable={true}
        loading={exclusionLoading || domainLoading}
        breadCrumbProps={{ params: { companyId: parentAccountId?.data?.parentCompanyId, brandId: accountId } }}
      >
        <Box textAlign="center" pt={4}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group" disableElevation>
            <Tooltip title="This feature is temporary unavailable" arrow placement="bottom">
              <Button
                disableRipple
                onClick={() => {}}
                variant={tabActive === 0 ? "contained" : "outlined"}
                sx={{ color: "#7587A380" }}
              >{`Audience Lists (${exclusionData?.objects.length || "0"})`}</Button>
            </Tooltip>
            <Button onClick={() => setTabActive(1)} variant={tabActive === 1 ? "contained" : "outlined"}>{`Domain Lists (${
              domainData?.objects.length || "0"
            })`}</Button>
          </ButtonGroup>
        </Box>
        <PageWrap data-cy="lists-page">
          <Paper elevation={4} sx={{ maxWidth: "1200px", margin: "auto", backgroundColor: "white", borderRadius: "7px", padding: "2rem" }}>
            <Fade in={tabActive === 0} timeout={750} unmountOnExit>
              <Box hidden={tabActive !== 0}>
                <ListTitle title="Audience Lists">
                  <Button disableRipple onClick={handleOpenExclusionLists} variant="contained">
                    Upload Audience List
                  </Button>
                </ListTitle>
                {exclusionData?.objects.length === 0 ? (
                  <EmptyLists />
                ) : (
                  <GridTable
                    autoHeight={true}
                    dataCy={exclusionLoading ? "my-list-table-loading" : "my-list-table"}
                    data={{ columns: columns as any, rows: exclusionRows }}
                    noRowsText={exclusionLoading ? "Loading" : "no audiences lists"}
                    gridProps={{
                      localText: {
                        noRowsLabel: "no label rows",
                      },
                    }}
                  />
                )}
              </Box>
            </Fade>
            <Fade in={tabActive === 1} timeout={750} unmountOnExit>
              <Box hidden={tabActive !== 1}>
                <ListTitle title="Domain Lists">
                  <Button disableRipple onClick={handleOpenDomainLists} variant="contained">
                    Upload Domain List
                  </Button>
                </ListTitle>
                {domainData?.objects.length === 0 ? (
                  <EmptyLists />
                ) : (
                  <GridTable
                    autoHeight={true}
                    dataCy={domainLoading ? "my-list-table-loading" : "my-list-table"}
                    data={{ columns: columns as any, rows: exclusionDomains }}
                    noRowsText={domainLoading ? "Loading" : "no domain lists"}
                    gridProps={{
                      localText: {
                        noRowsLabel: "no label rows",
                      },
                    }}
                  />
                )}
              </Box>
            </Fade>

            {/* <TableContainer component={Box}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>List Name</TableCell>
                    <TableCell align="right">Description</TableCell>
                    <TableCell align="right">Upload Date & Time</TableCell>
                    <TableCell align="right">Uploaded By</TableCell>
                    <TableCell align="right">Records Uploaded</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exclusionData?.objects.map((row) => (
                    <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{getFileName(row.key)}</TableCell>
                      <TableCell align="right">{row.description}</TableCell>
                      <TableCell align="right">{moment(row.uploadedAt as string).format("D MMMM YYYY")}</TableCell>
                      <TableCell align="right">{row.uploadedBy}</TableCell>
                      <TableCell align="right">{row.rowsCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Paper>
        </PageWrap>
      </MainFrame>
    </>
  );
};

const PageWrap = styled(Box)(({ theme }) => ({
  height: "100%",
  overflowY: "auto",
  padding: "2rem",
}));

const ListTitle = (props: { title: string; children: JSX.Element }) => {
  const { title, children } = props;
  return (
    <Box mb={4} sx={{ display: "grid", gridTemplateColumns: "1fr 300px", justifyContent: "space-between", alignItems: "center" }}>
      <Typography variant="h2" sx={{ fontSize: "18px" }}>
        {title}
      </Typography>
      <Box textAlign="right">{children}</Box>
    </Box>
  );
};

const EmptyLists = () => {
  return (
    <Box textAlign="center" mt={4}>
      <AppIcon icon="TransparencyCard" color="#7587A3" fontSize="medium" />
      <Typography variant="h4" pt={2} textAlign="center" fontSize="16px" className="color-tech-grey">
        You don't have any list uploaded.
      </Typography>
    </Box>
  );
};
