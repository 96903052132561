import React, {FunctionComponent} from 'react';
import {FormInputContainer, InputFieldLabel, LabelZone, TextFieldBlock} from '../../../components/templates/InfoBlock';
import {useAccountQuery} from '../../../components/hooks/data/useAccountQuery';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';

interface IBrandFormProps {
  accountId: number;
}


export const BrandFormView: FunctionComponent<IBrandFormProps> = (props: IBrandFormProps) => {
  
  const {accountId} = props
  
  const accountQuery = useAccountQuery(accountId);
  const account: IAccounts = accountQuery?.data ?? {};
  
  const companyUrlList = account?.companyUrlList?.join('\n') ?? '';
  
  return <div className={'flex-h gap-small'}>
    <FormInputContainer>
      <LabelZone
       title={'Brand Name'}
       content={account.companyName}
       code={'companyName'}
      />
      <LabelZone
        title={'Company URL'}
        content={companyUrlList}
        code={'companyUrlList'}
      />
    </FormInputContainer>
    <FormInputContainer>
      <LabelZone
        title={'Industry'}
        content={Boolean(account.industries) && account.industries.map((res: any) => res.name)}
        code={'industries'}
      />
      <LabelZone
        title={'Country'}
        content={account.country}
        code={'country'}
      />
    </FormInputContainer>
  </div>;
};
