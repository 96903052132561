import {MenuItem, MenuList, Typography} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useBrandAccountAssociation} from '../../../components/hooks/data/useBrandAccountAssociation';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';

interface IBrandMenuProps {
  companyId: number;
  onClick: (param: IAccounts) => void;
  isOpen: boolean;
  activeBrand: IAccounts; // CMVP-2101
};


export const BrandMenu: FunctionComponent<IBrandMenuProps> = (props: IBrandMenuProps) => {
  const {companyId, onClick,  activeBrand, isOpen} = props;
  
  const brandListQuery = useBrandAccountAssociation(companyId);
  const brandListData  = brandListQuery?.data ?? [];
  
  return <div style={{display: isOpen ? '' : 'none', width: '187px', minWidth: '187px', overflow: 'auto', height: '100%', backgroundColor: '#f0f2f6', position: 'relative'}}>
    <div style={{zIndex: 10, backgroundColor: '#f0f2f6'}} className={' pad-t-2 pad flex-h flex-align-middle gap-small w-full  pos-sticky'}>
      <div style={{marginLeft: '1.1rem'}}>
        <Typography sx={{fontWeight: 'bold', color: '#7587a3!important', fontSize: '14px'}} noWrap>Select Brand</Typography>
      </div>
    </div>
    <div style={{zIndex: 0}}><MenuList data-cy={'brandlist'} data-cy-count={brandListData?.length} dense={false}>
      {brandListData.map((brand: any) => {
        return (
          <MenuItem
            onClick={() => onClick(brand)}
            key={brand.companyName}
            data-cy={brand.companyName}
            data-cy-value={'brand_'+ brand.id}
            selected={brand.id === activeBrand?.id}
          >
            <Typography noWrap>{brand.companyName}</Typography>
          </MenuItem>
        );
      })}
    </MenuList>
    
    
    </div>
  </div>;
};


