import {Theme} from "@mui/material";
import {styled} from "@mui/styles";
import {IFeaturesDto} from "application/entities/dataTypes/features";
import {ISubFeaturesDto} from "application/entities/dataTypes/subFeatures";
import TreeViewLocationWithStatesDmas
    from "components/form/EntityTreeView/LocationWithStates/TreeViewLocationWithStatesDmas";
import {CountriesWithStatesDataItem} from "components/form/EntityTreeView/LocationWithStates/types";
import {TreeViewInterest} from "components/form/EntityTreeView/TreeViewInterest";
import _ from "lodash";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {jsonDecodeArray, jsonifyArray} from "pages/AudienceBuilder/audienceBuilderUtils";
import {useFeatureFlag} from "stores/featureFlags.store";
import {TreeViewSelect} from "../../../../components/form/EntityTreeView/TreeViewSelect";
import {featureTypesPresets} from "../../configuration";

export interface FeatureTypeLinkedIdProps {
    mode?: "float" | "block";
    featureCode: string;
    subFeatureCode?: string;
    treeInterest?: boolean;
}

export const InputLinkedId = (props: FeatureTypeLinkedIdProps, ref: any) => {
    const {mode = "float", featureCode, subFeatureCode, treeInterest} = props;
    const featureFlags = useFeatureFlag((st: any) => st.featureFlags);

    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        if (subFeatureCode) {
            return el.code === subFeatureCode;
        } else {
            return el.code === featureCode;
        }
    })[0];

    const evFeature = useAudienceBuilderStore((st) => {
        if (subFeatureCode) {
            return st.EvFeatures.find((feature) => feature.code === featureCode)?.subFeatures.find((subFeature) => subFeature.code === subFeatureCode);
        } else {
            return st.EvFeatures.find((feature) => feature.code === featureCode);
        }
    });
    const audienceActions = useAudienceBuilderStore.getState().actions;
    const audienceAccountId = useAudienceBuilderStore(
        (st) => st.audience?.account?.id,
        (old, ne) => _.isEqual(old, ne)
    );

    const featureConf = evFeature?.featureValues ? jsonDecodeArray(evFeature?.featureValues)[0] : null;

    const handleChange = (item: any) => {
        const newValues = item
            .map((item: any) => {
                return {
                    id: item?.id ?? item[featureConf.idField],
                    [featureConf.defaultField]: item[featureConf.defaultField],
                    [featureConf.hierarchyField]: item[featureConf.hierarchyField],
                };
            })
            .flat(1);
        if (subFeatureCode) {
            audienceActions.insertSubFeatureValues(evFeature! as ISubFeaturesDto, jsonifyArray(newValues));
        } else {
            audienceActions.insertFeatureValues(evFeature! as IFeaturesDto, jsonifyArray(newValues));
        }
    };

    const onToggleExpand = (path: string[]) => {
        if (subFeatureCode) {
            audienceActions.toggleTreeItem(subFeatureCode, path);
        } else {
            audienceActions.toggleTreeItem(featureCode, path);
        }
    };


    const inputLabel = featureTypesPresets[featureCode]?.inputLabel;
    return Boolean(featureConf?.entity && filtersValues.selectable.length > 0) ? (
        <>
            {subFeatureCode && <TextFieldLabel>{evFeature?.name}</TextFieldLabel>}

            {treeInterest ? (
                <TreeViewInterest
                    entityName={featureConf.entity.replace("{accountId}", `${audienceAccountId}`)}
                    entityList={filtersValues.selectable}
                    entityTextName={inputLabel || (featureConf?.label ?? featureConf.entity)}
                    defaultField={featureConf.defaultField ?? "name"}
                    hierarchyField={featureConf.hierarchyField}
                    onChange={handleChange}
                    onToggleExpand={onToggleExpand}
                    mode={mode}
                    selectedEntityItems={filtersValues.selected}
                    singleSelect={false}
                />
            ) : featureCode === "LOCATION" ? (
                    <TreeViewLocationWithStatesDmas
                        entityName="Geography"
                        entityList={filtersValues.selectable as CountriesWithStatesDataItem[]}
                        onChange={handleChange}
                        onToggleExpand={onToggleExpand}
                        selectedEntityItems={filtersValues.selected}
                    />
                ) :


                (
                    <TreeViewSelect
                        entityName={featureConf.entity.replace("{accountId}", `${audienceAccountId}`)}
                        entityList={filtersValues.selectable}
                        entityTextName={inputLabel || (featureConf?.label ?? featureConf.entity)}
                        defaultField={featureConf.defaultField ?? "name"}
                        hierarchyField={featureConf.hierarchyField}
                        onChange={handleChange}
                        onToggleExpand={onToggleExpand}
                        mode={mode}
                        selectedEntityItems={filtersValues.selected}
                        singleSelect={false}
                    />
                )}
        </>
    ) : null;
};

export const TextFieldLabel = styled("div")((props: { theme: Theme; active?: boolean }) => ({
    fontFamily: props.theme.typography.fontFamily,
    fontWeight: "bold",
    color: "#9DAABF",
    padding: "0",
    fontSize: 13,
}));
