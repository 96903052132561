import React from "react";
import zxcvbn from "zxcvbn";
import { AuthPassStr } from "../../features/Authentication/authentication.styled";
import { Grid, Typography, useTheme } from "@mui/material";
import { GridItem, GridItemFull } from "../../ui/AppElements";

interface Props {
  password: string;
  getForceCallback?: Function;
}

export const AuthStrength = (props: Props) => {
  const theme = useTheme();

  const [passStr, setPassStr] = React.useState<number>(0);
  const [progressWidth, setProgressWidth] = React.useState<number>(0);

  const requireLowerCase = /(?=.*[a-z])/;
  const requireUpperCase = /(?=.*[A-Z])/;
  const requireNumbers = /(?=.*[0-9])/;
  const requireSpecialCharacter =
    /(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])/;
  const forbiddenWhiteSpaces = /^\S+$/;
  const requireMinimumCharacterCount = /^.{8,99}$/;

  React.useEffect(() => {
    let force = zxcvbn(props.password).score;
    setPassStr(force);

    let awsForce = calculateAwsStrength(props.password);

    if (props.getForceCallback) props.getForceCallback(awsForce);
    setProgressWidth(awsForce);
  }, [props.password]);

  const calculateAwsStrength = (password: string) => {
    const result = {
      requireLowerCase: props.password.search(requireLowerCase),
      requireUpperCase: props.password.search(requireUpperCase),
      requireNumbers: props.password.search(requireNumbers),
      requireSpecialCharacter: props.password.search(requireSpecialCharacter),
      requireMinimumCharacterCount: props.password.search(
        requireMinimumCharacterCount
      ),
      forbiddenWhiteSpaces: props.password.search(forbiddenWhiteSpaces),
      // finalRegex: props.password.search(finalRegex),
    };

    const tot = Object.values(result).reduce(
      (a: number, b: number) => a + b,
      0
    );

    return Math.round(((tot + 6) / 6) * 100);
  };

  let strColor;
  let forcetext;

  switch (passStr) {
    case 0:
    case 1:
      strColor = theme.palette.error.main;
      forcetext = "Weak";
      break;
    case 2:
    case 3:
      strColor = "orange";
      forcetext = "Average";
      break;
    case 4:
      strColor = "#5cff47";
      forcetext = "Strong";
      break;
    case 5:
      strColor = theme.palette.success.main;
      forcetext = "Powerfull";
      break;
    default:
  }

  const style = {
    backgroundColor: strColor,
    height: "3px",
    width: `${progressWidth}%`,
    transition: "all 300ms ease-in-out",
  };

  const textStyle = {
    // color: strColor,
    transition: "all 300ms ease-in-out",
  };

  return (
    <AuthPassStr>
      <div style={{ margin: "0.5rem 0.5rem", textAlign: "left" }}>
        <div style={{ border: "0.5px solid #E4EAF0", borderRadius: "12px" }}>
          <div style={style} />
        </div>
        <Grid container>
          <GridItemFull>
            <Typography style={{ color: theme.palette.text.disabled }}>
              Password strength
            </Typography>
          </GridItemFull>
          <GridItem style={textStyle}>{forcetext}</GridItem>
        </Grid>
      </div>
    </AuthPassStr>
  );
};

export default AuthStrength;
