import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
// import { useStylesGrid } from 'components/Grid/grid.styledOld';
import * as React from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import {useTranslation} from 'react-i18next';


interface IGridCustomToolbarProps {
  downloadAllRef?: React.Ref<any>;
  exportReference: React.Ref<HTMLButtonElement>;
  setExportReference: (arg0: React.Ref<HTMLButtonElement>) => void;
}
// ghost toolbar with ghost download button
export const GridCustomToolbar = forwardRef<HTMLButtonElement, IGridCustomToolbarProps>(
  ({ setExportReference }, ref) => {

    const exportRef = useRef<HTMLButtonElement>(null);
    const {t}         = useTranslation();

    useEffect(() => {
      setExportReference && setExportReference(exportRef);
    }, [setExportReference, exportRef]);

    // const classes = useStylesGrid();

    return (
      <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
        {/*<div className={classes.search}>*/}
        {/*  <div className={classes.searchIcon}>*/}
        {/*    <MagnifyGlassBlue />*/}
        {/*  </div>*/}
        {/*  <InputBase*/}
        {/*    placeholder="Search…"*/}
        {/*    classes={{*/}
        {/*      root: classes.inputRoot,*/}
        {/*      input: classes.inputInput,*/}
        {/*    }}*/}
        {/*    inputProps={{ 'aria-label': 'search' }}*/}
        {/*  />*/}
        {/*</div>*/}

        <div>
          <GridToolbarExport
            style={{ display: 'none' }}
            ref={exportRef}
            title={t('labels.downloadAll')}
            csvOptions={{delimiter:';'}}
          />
          {/*<GridToolbarFilterButton />*/}
        </div>
      </GridToolbarContainer>
    );
  });