import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "redux/slices/auth.slices";
import dialogSlice from "redux/slices/dialog.slices";
import dialogSliceBis from "redux/slices/dialog.slices.bis";
import toastSlice from "redux/slices/toast.slices";
import { History } from "history";
import crudOpSlice from "../slices/crudmsg.slices";

export interface RootState {
  dialogSlice: ReturnType<typeof dialogSlice>;
  dialogSliceBis: ReturnType<typeof dialogSliceBis>;
  toastSlice: ReturnType<typeof toastSlice>;
  authSlice: ReturnType<typeof authSlice>;
  crudOpSlice: ReturnType<typeof crudOpSlice>;
}

export const rootReducer = (history: History) =>
  combineReducers({
    // router: connectRouter(history),
    dialogSlice,
    dialogSliceBis,
    toastSlice,
    authSlice,
    crudOpSlice,
  });
