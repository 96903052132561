export const magniteAdSizes = [
    {
        value: "1000x1000",
        label: "1000x1000 - Full Page",
    },
    {
        value: "1000x250",
        label: "1000x250 - German Masthead",
    },
    {
        value: "1000x300",
        label: "1000x300 - Large Leaderboard",
    },
    {
        value: "1000x40",
        label: "1000x40 - Leaderboard 4",
    },
    {
        value: "1000x90",
        label: "1000x90 - Leaderboard 2",
    },
    {
        value: "1004x768",
        label: "1004x768 - Tablet Full Page (Status Bar)",
    },
    {
        value: "1024x768",
        label: "1024x768 - Tablet Interstitial Landscape",
    },
    {
        value: "1024x90",
        label: "1024x90 - Tablet Landscape Banner",
    },
    {
        value: "120x240",
        label: "120x240 - Vertical Banner",
    },
    {
        value: "120x60",
        label: "120x60 - Button 2",
    },
    {
        value: "120x600",
        label: "120x600 - Skyscraper",
    },
    {
        value: "120x90",
        label: "120x90 - Button 1",
    },
    {
        value: "1250x240",
        label: "1250x240 - Nordic Stortavla",
    },
    {
        value: "1250x360",
        label: "1250x360 - Nordic Panorama XXL",
    },
    {
        value: "1250x480",
        label: "1250x480 - Swedish Big Panorama",
    },
    {
        value: "125x125",
        label: "125x125 - Button",
    },
    {
        value: "1280x800",
        label: "1280x800 - Tablet Interstitial Landscape",
    },
    {
        value: "140x350",
        label: "140x350 - Nordic Billboard",
    },
    {
        value: "160x600",
        label: "160x600 - Wide Skyscraper",
    },
    {
        value: "160x600",
        label: "160x600 - Wallpaper 160x600",
    },
    {
        value: "160x90",
        label: "160x90 - Vertical Links - 160",
    },
    {
        value: "1800x1000",
        label: "1800x1000 - Wallpaper/Skin",
    },
    {
        value: "1800x200",
        label: "1800x200 - Custom Horizontal (1800x200)",
    },
    {
        value: "180x150",
        label: "180x150 - Rectangle",
    },
    {
        value: "180x500",
        label: "180x500 - Norwegian Skyscraper",
    },
    {
        value: "180x90",
        label: "180x90 - Vertical Links - 180",
    },
    {
        value: "1920x1080",
        label: "1920x1080 - OOH 16:9 Screen (Landscape)",
    },
    {
        value: "1920x1200",
        label: "1920x1200 - Widescreen Wallpaper (1920x1200)",
    },
    {
        value: "1x1",
        label: "1x1 - 1x1 Floating",
    },
    {
        value: "2000x1400",
        label: "2000x1400 - Czech Branding",
    },
    {
        value: "200x200",
        label: "200x200 - Small Square",
    },
    {
        value: "200x600",
        label: "200x600 - Super Wide Sky",
    },
    {
        value: "200x90",
        label: "200x90 - Vertical Links - 200",
    },
    {
        value: "234x60",
        label: "234x60 - Half Banner",
    },
    {
        value: "240x200",
        label: "240x200 -  Square 240 (240x200)",
    },
    {
        value: "240x37",
        label: "240x37 - Mobile Banner 5",
    },
    {
        value: "240x400",
        label: "240x400 - Vertical Rectangle",
    },
    {
        value: "250x250",
        label: "250x250 - Square",
    },
    {
        value: "250x350",
        label: "250x350 - Custom Vertical",
    },
    {
        value: "250x360",
        label: "250x360 - Swedish Stortavla",
    },
    {
        value: "250x600",
        label: "250x600 - Big Skyscraper (250x600)",
    },
    {
        value: "250x800",
        label: "250x800 - Nordic Outsider XXL",
    },
    {
        value: "260x500",
        label: "260x500 - Medium Portrait (260x500)",
    },
    {
        value: "2x2",
        label: "2x2 - Catch All",
    },
    {
        value: "2x4",
        label: "2x4 - Skin",
    },
    {
        value: "300x100",
        label: "300x100 - 3:1 Rectangle",
    },
    {
        value: "300x1050",
        label: "300x1050 - Portrait",
    },
    {
        value: "300x120",
        label: "300x120 - Mini Rectangle",
    },
    {
        value: "300x1200",
        label: "300x1200 - Large Portrait",
    },
    {
        value: "300x150",
        label: "300x150 - Custom Horizontal",
    },
    {
        value: "300x250",
        label: "300x250 - Medium Rectangle",
    },
    {
        value: "300x250",
        label: "300x250 - SideKick",
    },
    {
        value: "300x250",
        label: "300x250 - Wallpaper 300x250",
    },
    {
        value: "300x250",
        label: "300x250 - Slider 300x250",
    },
    {
        value: "300x250",
        label: "300x250 - Curtain 300x250",
    },
    {
        value: "300x300",
        label: "300x300 - Square 300",
    },
    {
        value: "300x50",
        label: "300x50 - Mobile Banner 2",
    },
    {
        value: "300x600",
        label: "300x600 - Half Page Ad",
    },
    {
        value: "300x600",
        label: "300x600 - FilmStrip",
    },
    {
        value: "300x75",
        label: "300x75 - Large Mobile Banner",
    },
    {
        value: "300x850",
        label: "300x850 - Large Half Page Ad",
    },
    {
        value: "320x100",
        label: "320x100 - Mobile Double Size Banner",
    },
    {
        value: "320x150",
        label: "320x150 - Mobile Banner 7",
    },
    {
        value: "320x160",
        label: "320x160 - Swedish Half Takeover",
    },
    {
        value: "320x180",
        label: "320x180 - Mobile Billboard",
    },
    {
        value: "320x200",
        label: "320x200 - Custom Horizontal",
    },
    {
        value: "320x240",
        label: "320x240 - Mobile Half Page Ad",
    },
    {
        value: "320x250",
        label: "320x250 - Mobile IMU",
    },
    {
        value: "320x270",
        label: "320x270 - Mobile Interstitial (320x270)",
    },
    {
        value: "320x320",
        label: "320x320 - Swedish Takeover",
    },
    {
        value: "320x400",
        label: "320x400 - Norwegian Portrait",
    },
    {
        value: "320x480",
        label: "320x480 - Mobile Interstitial Portrait",
    },
    {
        value: "320x50",
        label: "320x50 - Mobile Banner 1",
    },
    {
        value: "320x500",
        label: "320x500 - Custom Vertical (320x500)",
    },
    {
        value: "320x64",
        label: "320x64 - Swiss Mobile Banner (320x64)",
    },
    {
        value: "320x80",
        label: "320x80 - Mobile Banner 6",
    },
    {
        value: "336x280",
        label: "336x280 - Large Rectangle",
    },
    {
        value: "336x280",
        label: "336x280 - Square 336 (deprecated)",
    },
    {
        value: "360x50",
        label: "360x50 - Mobile Banner 9 (360x50)",
    },
    {
        value: "360x56",
        label: "360x56 - Custom Horizontal",
    },
    {
        value: "364x97",
        label: "364x97 - Android App",
    },
    {
        value: "3840x1080",
        label: "3840x1080 - OOH Airport Panoramic Video Wall Horizontal",
    },
    {
        value: "468x120",
        label: "468x120 - Double Banner",
    },
    {
        value: "468x15",
        label: "468x15 - Horizontal Links - 468",
    },
    {
        value: "468x400",
        label: "468x400 - Nordic Giant Rectangle",
    },
    {
        value: "468x60",
        label: "468x60 - Banner",
    },
    {
        value: "480x280",
        label: "480x280 - Nordic Helsida",
    },
    {
        value: "480x320",
        label: "480x320 - Mobile Interstitial Landscape",
    },
    {
        value: "480x60",
        label: "480x60 - Mobile Banner 4",
    },
    {
        value: "480x75",
        label: "480x75 - Mobile Banner 3",
    },
    {
        value: "480x820",
        label: "480x820 - Czech Interscroller",
    },
    {
        value: "500x200",
        label: "500x200 - Czech mobile sticky",
    },
    {
        value: "513x137",
        label: "513x137 - Android App",
    },
    {
        value: "578x154",
        label: "578x154 - Android App",
    },
    {
        value: "580x400",
        label: "580x400 - Nordic Netboard",
    },
    {
        value: "580x500",
        label: "580x500 - Norwegian Netboard",
    },
    {
        value: "600x300",
        label: "600x300 - Megabox (600x300)",
    },
    {
        value: "600x500",
        label: "600x500 - Retina asset size (600x500)",
    },
    {
        value: "620x400",
        label: "620x400 - Danish Article Banner (620x400)",
    },
    {
        value: "640x100",
        label: "640x100 - Retina Asset Size 2 (640x100)",
    },
    {
        value: "640x150",
        label: "640x150 - Mobile Leaderboard XXL",
    },
    {
        value: "640x160",
        label: "640x160 - Tablet Wide Banner (640x160)",
    },
    {
        value: "640x200",
        label: "640x200 - Aurea strip ad 2 (640x200)",
    },
    {
        value: "640x320",
        label: "640x320 - Mobile Banner 8",
    },
    {
        value: "640x360",
        label: "640x360 - Hungarian Roadblock (640x360)",
    },
    {
        value: "640x380",
        label: "640x380 - Larger Double Size Banner (640x380)",
    },
    {
        value: "640x480",
        label: "640x480 - Monster",
    },
    {
        value: "640x832",
        label: "640x832 - Mobile Web Interstitial (640x832)",
    },
    {
        value: "654x175",
        label: "654x175 - Android App",
    },
    {
        value: "694x250",
        label: "694x250 - Smart billboard",
    },
    {
        value: "6x6",
        label: "6x6 - Rubicon Custom (Native via Display)",
    },
    {
        value: "728x15",
        label: "728x15 - Horizontal Links - 728",
    },
    {
        value: "728x40",
        label: "728x40 - Leaderboard (728x40)",
    },
    {
        value: "728x90",
        label: "728x90 - Leaderboard",
    },
    {
        value: "728x90",
        label: "728x90 - Wallpaper 728x90",
    },
    {
        value: "750x100",
        label: "750x100 - Polish Billboard",
    },
    {
        value: "750x200",
        label: "750x200 - Polish Double Billboard",
    },
    {
        value: "750x300",
        label: "750x300 - Polish Triple Billboard",
    },
    {
        value: "768x1024",
        label: "768x1024 - Tablet Interstitial Portrait",
    },
    {
        value: "768x90",
        label: "768x90 - Tablet Portrait Banner",
    },
    {
        value: "800x1280",
        label: "800x1280 - Tablet Interstitial Portrait",
    },
    {
        value: "800x250",
        label: "800x250 - German Billboard",
    },
    {
        value: "800x600",
        label: "800x600 - Banner Interstitial",
    },
    {
        value: "840x250",
        label: "840x250 - Big Leaderboard",
    },
    {
        value: "850x250",
        label: "850x250 - Bavarian Billboard",
    },
    {
        value: "88x31",
        label: "88x31 - Micro Bar",
    },
    {
        value: "900x250",
        label: "900x250 - Super Leaderboard",
    },
    {
        value: "930x180",
        label: "930x180 - Danish Megaboard",
    },
    {
        value: "930x600",
        label: "930x600 - Danish Monsterboard",
    },
    {
        value: "940x200",
        label: "940x200 - Billboard GR (940x200)",
    },
    {
        value: "950x200",
        label: "950x200 - Polish Billboard 2 (950x200)",
    },
    {
        value: "950x250",
        label: "950x250 - Billboard",
    },
    {
        value: "950x90",
        label: "950x90 - Slider",
    },
    {
        value: "970x100",
        label: "970x100 - Small Czech Leaderboard",
    },
    {
        value: "970x1000",
        label: "970x1000 - Skin NL",
    },
    {
        value: "970x250",
        label: "970x250 - IAB Billboard",
    },
    {
        value: "970x500",
        label: "970x500 - Rich Media Masthead (970x500)",
    },
    {
        value: "970x90",
        label: "970x90 - Pushdown",
    },
    {
        value: "980x120",
        label: "980x120 - Nordic Panorama",
    },
    {
        value: "980x150",
        label: "980x150 - Norwegian Top Banner",
    },
    {
        value: "980x240",
        label: "980x240 - Double Panorama",
    },
    {
        value: "980x250",
        label: "980x250 - Billboard",
    },
    {
        value: "980x300",
        label: "980x300 - Monster Panorama",
    },
    {
        value: "980x400",
        label: "980x400 - Nordic Parade",
    },
    {
        value: "980x50",
        label: "980x50 - Leaderboard 3",
    },
    {
        value: "980x600",
        label: "980x600 - Nordic Blackboard",
    },
    {
        value: "990x720",
        label: "990x720 - Nordic Interstitial (990x720)",
    },
    {
        value: "990x90",
        label: "990x90 - adMax Leaderboard",
    },
    {
        value: "994x250",
        label: "994x250 - Wideboard",
    },
    {
        value: "998x200",
        label: "998x200 - XL Czech Leaderboard",
    },
];

export const pubmaticAdSizes = [
    {
        label: "0x0 - Video",
        value: "0x0",
    },
    {
        label: "1024x728 - Mobile",
        value: "1024x728",
    },
    {
        label: "1024x90 - Mobile",
        value: "1024x90",
    },
    {
        label: "120x20 - Mobile",
        value: "120x20",
    },
    {
        label: "120x240 - Vertical Banner",
        value: "120x240",
    },
    {
        label: "120x30 - Mobile",
        value: "120x30",
    },
    {
        label: "120x600 - Skyscraper",
        value: "120x600",
    },
    {
        label: "1250x480 - web",
        value: "1250x480",
    },
    {
        label: "125x125 - Button",
        value: "125x125",
    },
    {
        label: "1280x800 - Mobile",
        value: "1280x800",
    },
    {
        label: "1536x256 - Mobile",
        value: "1536x256",
    },
    {
        label: "160x600 - Wide Skyscraper",
        value: "160x600",
    },
    {
        label: "168x28 - Mobile",
        value: "168x28",
    },
    {
        label: "168x42 - Mobile",
        value: "168x42",
    },
    {
        label: "1800x1000 - Arch Ad Format",
        value: "1800x1000",
    },
    {
        label: "180x150 - Small Rectangle",
        value: "180x150",
    },
    {
        label: "1x1 - Native",
        value: "1x1",
    },
    {
        label: "200x200 - Small Square",
        value: "200x200",
    },
    // {
    //   label: "210x990 - Double Leaderboard",
    //   value: "210x990",
    // },
    {
        label: "216x54 - Mobile",
        value: "216x54",
    },
    {
        label: "234x60 - Half Banner",
        value: "234x60",
    },
    {
        label: "240x320 - Mobile",
        value: "240x320",
    },
    {
        label: "250x250 - Square",
        value: "250x250",
    },
    {
        label: "2560x256 - Mobile",
        value: "2560x256",
    },
    {
        label: "290x456 - Double Page Spread",
        value: "290x456",
    },
    {
        label: "300x1050 - Portrait",
        value: "300x1050",
    },
    {
        label: "300x250 - Sidekick",
        value: "300x250",
    },
    {
        label: "300x300 - Banner",
        value: "300x300",
    },
    {
        label: "300x48 - Mobile",
        value: "300x48",
    },
    {
        label: "300x600 - Filmstrip",
        value: "300x600",
    },
    {
        label: "300x50",
        value: "300x50",
    },
    {
        label: "300x75 - Mobile",
        value: "300x75",
    },
    // {
    //   label: "310x970 - Megaboard",
    //   value: "310x970",
    // },
    {
        label: "320x100 - Mobile",
        value: "320x100",
    },
    {
        label: "320x160 - Mobile",
        value: "320x160",
    },
    {
        label: "320x240 - Mobile",
        value: "320x240",
    },
    {
        label: "320x250 - Mobile",
        value: "320x250",
    },
    {
        label: "320x320 - Mobile",
        value: "320x320",
    },
    {
        label: "320x351 - Mobile",
        value: "320x351",
    },
    {
        label: "320x460 - Mobile",
        value: "320x460",
    },
    {
        label: "320x48 - Mobile",
        value: "320x48",
    },
    {
        label: "320x480 - Mobile",
        value: "320x480",
    },
    {
        label: "320x50 - Mobile",
        value: "320x50",
    },
    {
        label: "320x53 - Mobile",
        value: "320x53",
    },
    {
        label: "320x60 - Mobile",
        value: "320x60",
    },
    {
        label: "320x80 - Mobile",
        value: "320x80",
    },
    {
        label: "336x280 - Large Rectangle",
        value: "336x280",
    },
    // {
    //   label: "456x290 - Double Page Spread",
    //   value: "456x290",
    // },
    {
        label: "468x360 - Web",
        value: "468x360",
    },
    {
        label: "468x60 - Banner",
        value: "468x60",
    },
    {
        label: "480x300 - Wallpaper",
        value: "480x300",
    },
    {
        label: "480x32 - Mobile",
        value: "480x32",
    },
    {
        label: "480x270",
        value: "480x270",
    },
    {
        label: "480x320 - Mobile",
        value: "480x320",
    },
    {
        label: "480x50 - Mobile",
        value: "480x50",
    },
    {
        label: "480x60 - Mobile",
        value: "480x60",
    },
    {
        label: "480x80 - Mobile",
        value: "480x80",
    },
    {
        label: "400x300",
        value: "400x300",
    },
    {
        label: "480x360",
        value: "480x360",
    },
    {
        label: "480x800 - Mobile",
        value: "480x800",
    },
    {
        label: "480x854 - Mobile",
        value: "480x854",
    },
    {
        label: "576X576 - DOOH",
        value: "576x576",
    },
    {
        label: "600x832 - Mobile",
        value: "600x832",
    },
    {
        label: "60x425 - overlay/interstitial",
        value: "60x425",
    },
    {
        label: "640x107 - Mobile",
        value: "640x107",
    },
    {
        label: "640x360",
        value: "640x360",
    },
    {
        label: "640x640 - Mobile",
        value: "640x640",
    },
    {
        label: "640x480",
        value: "640x480",
    },
    {
        label: "640x90 - Mobile",
        value: "640x90",
    },
    {
        label: "720x540",
        value: "720x540",
    },
    {
        label: "728x1024 - Mobile",
        value: "728x1024",
    },
    {
        label: "728x90 - Leaderboard",
        value: "728x90",
    },
    {
        label: "756x260 - Pre-Roll Video",
        value: "756x260",
    },
    {
        label: "768x1024 - Mobile",
        value: "768x1024",
    },
    {
        label: "768x90 - Mobile",
        value: "768x90",
    },
    // {
    //   label: "7x7 - Test",
    //   value: "7x7",
    // },
    {
        label: "800x1280 - Mobile",
        value: "800x1280",
    },
    {
        label: "800x250 - Billboard",
        value: "800x250",
    },
    {
        label: "800x480 - Pre-Roll Video",
        value: "800x480",
    },
    {
        label: "80x12 - Mobile",
        value: "80x12",
    },
    {
        label: "80x15 - Mobile",
        value: "80x15",
    },
    {
        label: "88x31 - Micro Bar",
        value: "88x31",
    },
    {
        label: "900x300 - Billboard",
        value: "900x300",
    },
    {
        label: "950x200-Header Banner",
        value: "950x200",
    },
    {
        label: "970x250 - Billboard",
        value: "970x250",
    },
    {
        label: "970x30 - Sliding Billboard",
        value: "970x30",
    },
    {
        label: "970x310 - Megaboard",
        value: "970x310",
    },
    {
        label: "970x90 - Pushdown",
        value: "970x90",
    },
    {
        label: "980x150 - Super Leaderboard",
        value: "980x150",
    },
    {
        label: "980x300 - Web",
        value: "980x300",
    },
    {
        label: "980x60 - Banner",
        value: "980x60",
    },
    {
        label: "990x100 - Leaderboard",
        value: "990x100",
    },
    {
        label: "1920x1080",
        value: "1920x1080",
    }
    // {
    //   label: "990x210 - Double Leaderboard",
    //   value: "990x210",
    // },
];

export const pubmaticAdSizesFull = [
    {
        label: "0x0 - Video",
        value: "0x0",
    },
    {
        label: "1000x1",
        value: "1x1000",
    },
    {
        label: "1000x100",
        value: "1000x100",
    },
    {
        label: "1000x1000",
        value: "1000x1000",
    },
    {
        label: "1000x200",
        value: "1000x200",
    },
    {
        label: "1000x250",
        value: "1000x250",
    },
    {
        label: "1000x300",
        value: "1000x300",
    },
    {
        label: "1000x400",
        value: "1000x400",
    },
    {
        label: "1000x50",
        value: "1000x50",
    },
    {
        label: "1000x560",
        value: "1000x560",
    },
    {
        label: "1000x562",
        value: "1000x562",
    },
    {
        label: "1000x60",
        value: "1000x60",
    },
    {
        label: "1000x600",
        value: "1000x600",
    },
    {
        label: "1000x70",
        value: "1000x70",
    },
    {
        label: "1000x90",
        value: "1000x90",
    },
    {
        label: "100x100",
        value: "100x100",
    },
    {
        label: "100x300",
        value: "100x300",
    },
    {
        label: "1020x250",
        value: "1020x250",
    },
    {
        label: "1020x90",
        value: "1020x90",
    },
    {
        label: "1020x95",
        value: "1020x95",
    },
    {
        label: "1024 x 768",
        value: "1024x768",
    },
    {
        label: "1024x1024",
        value: "1024x1024",
    },
    {
        label: "1024x50",
        value: "1024x50",
    },
    {
        label: "1024x576",
        value: "1024x576",
    },
    {
        label: "1024x60",
        value: "1024x60",
    },
    {
        label: "1024x600",
        value: "1024x600",
    },
    {
        label: "1024x66",
        value: "1024x66",
    },
    {
        label: "1024x728 - Mobile",
        value: "1024x728",
    },
    {
        label: "1024x800",
        value: "1024x800",
    },
    {
        label: "1024x90 - Mobile",
        value: "1024x90",
    },
    {
        label: "1030x590",
        value: "1030x590",
    },
    {
        label: "1050x177",
        value: "1050x177",
    },
    {
        label: "1050x670",
        value: "1050x670",
    },
    {
        label: "1056x288",
        value: "1056x288",
    },
    {
        label: "1056x576",
        value: "1056x576",
    },
    {
        label: "1060x610",
        value: "1060x610",
    },
    {
        label: "1070x250",
        value: "1070x250",
    },
    {
        label: "1080x1080",
        value: "1080x1080",
    },
    {
        label: "1080x1320",
        value: "1080x1320",
    },
    {
        label: "1080x1440",
        value: "1080x1440",
    },
    {
        label: "1080x1920",
        value: "1080x1920",
    },
    {
        label: "1080x2094",
        value: "1080x2094",
    },
    {
        label: "1080x2160",
        value: "1080x2160",
    },
    {
        label: "1080x2592",
        value: "1080x2592",
    },
    {
        label: "1080x786",
        value: "1080x786",
    },
    {
        label: "10x1",
        value: "10x1",
    },
    {
        label: "1100x210",
        value: "1100x210",
    },
    {
        label: "1100x250",
        value: "1100x250",
    },
    {
        label: "1100x400",
        value: "1100x400",
    },
    {
        label: "1100x50",
        value: "1100x50",
    },
    {
        label: "1100x600",
        value: "1100x600",
    },
    {
        label: "110x360",
        value: "110x360",
    },
    {
        label: "1134x250",
        value: "1134x250",
    },
    {
        label: "1140x250",
        value: "1140x250",
    },
    {
        label: "1150x647",
        value: "1150x647",
    },
    {
        label: "1152x288",
        value: "1152x288",
    },
    {
        label: "1152x560",
        value: "1152x560",
    },
    {
        label: "1152x576",
        value: "1152x576",
    },
    {
        label: "1184x400",
        value: "1184x400",
    },
    {
        label: "1188x288",
        value: "1188x288",
    },
    {
        label: "1200x1920",
        value: "1200x1920",
    },
    {
        label: "1200x250",
        value: "1200x250",
    },
    {
        label: "1200x300",
        value: "1200x300",
    },
    {
        label: "1200x600",
        value: "1200x600",
    },
    {
        label: "1200x627",
        value: "1200x627",
    },
    {
        label: "1200x720",
        value: "1200x720",
    },
    {
        label: "1200x90",
        value: "1200x90",
    },
    {
        label: "120x120",
        value: "120x120",
    },
    {
        label: "120x160",
        value: "120x160",
    },
    {
        label: "120x20 - Mobile",
        value: "120x20",
    },
    {
        label: "120x240 - Vertical Banner",
        value: "120x240",
    },
    {
        label: "120x30 - Mobile",
        value: "120x30",
    },
    {
        label: "120x45",
        value: "120x45",
    },
    {
        label: "120x60",
        value: "120x60",
    },
    {
        label: "120x600 - Skyscraper",
        value: "120x600",
    },
    {
        label: "120x607",
        value: "120x607",
    },
    {
        label: "120x609",
        value: "120x609",
    },
    {
        label: "120x70",
        value: "120x70",
    },
    {
        label: "120x90",
        value: "120x90",
    },
    {
        label: "1216x416",
        value: "1216x416",
    },
    {
        label: "1220x300",
        value: "1220x300",
    },
    {
        label: "1220x600",
        value: "1220x600",
    },
    {
        label: "1236x200",
        value: "1236x200",
    },
    {
        label: "1250x240",
        value: "1250x240",
    },
    {
        label: "1250x300",
        value: "1250x300",
    },
    {
        label: "1250x480 - web",
        value: "1250x480",
    },
    {
        label: "1250x600",
        value: "1250x600",
    },
    {
        label: "125x100",
        value: "125x100",
    },
    {
        label: "125x125 - Button",
        value: "125x125",
    },
    {
        label: "125x600",
        value: "125x600",
    },
    {
        label: "1260x160",
        value: "1260x160",
    },
    {
        label: "1260x450",
        value: "1260x450",
    },
    {
        label: "1263x250",
        value: "1263x250",
    },
    {
        label: "1280x100",
        value: "1280x100",
    },
    {
        label: "1280x256",
        value: "1280x256",
    },
    {
        label: "1280x50",
        value: "1280x50",
    },
    {
        label: "1280x512",
        value: "1280x512",
    },
    {
        label: "1280x600",
        value: "1280x600",
    },
    {
        label: "1280x720",
        value: "1280x720",
    },
    {
        label: "1280x768",
        value: "1280x768",
    },
    {
        label: "1280x800 - Mobile",
        value: "1280x800",
    },
    {
        label: "1280x832",
        value: "1280x832",
    },
    {
        label: "1280x90",
        value: "1280x90",
    },
    {
        label: "1312x528",
        value: "1312x528",
    },
    {
        label: "1360x768",
        value: "1360x768",
    },
    {
        label: "1365x770",
        value: "1365x770",
    },
    {
        label: "1366x768",
        value: "1366x768",
    },
    {
        label: "1368x324",
        value: "1368x324",
    },
    {
        label: "1400x1900",
        value: "1400x1900",
    },
    {
        label: "1400x400",
        value: "1400x400",
    },
    {
        label: "1400x900",
        value: "1400x900",
    },
    {
        label: "140x350",
        value: "140x350",
    },
    {
        label: "140x500",
        value: "140x500",
    },
    {
        label: "140x600",
        value: "140x600",
    },
    {
        label: "1420X720",
        value: "1420x720",
    },
    {
        label: "1440x250",
        value: "1440x250",
    },
    {
        label: "1440x2560",
        value: "1440x2560",
    },
    {
        label: "1440x2880",
        value: "1440x2880",
    },
    {
        label: "1440x2960",
        value: "1440x2960",
    },
    {
        label: "1440x384",
        value: "1440x384",
    },
    {
        label: "1440x528",
        value: "1440x528",
    },
    {
        label: "1440x700",
        value: "1440x700",
    },
    {
        label: "144x256",
        value: "144x256",
    },
    {
        label: "1480x320",
        value: "1480x320",
    },
    {
        label: "1500x1800",
        value: "1500x1800",
    },
    {
        label: "150x100",
        value: "150x100",
    },
    {
        label: "150x150",
        value: "150x150",
    },
    {
        label: "1536x2048",
        value: "1536x2048",
    },
    {
        label: "1536x256 - Mobile",
        value: "1536x256",
    },
    {
        label: "1536X320",
        value: "1536x320",
    },
    {
        label: "1536x380",
        value: "1536x380",
    },
    {
        label: "1536x432",
        value: "1536x432",
    },
    {
        label: "1540x1024",
        value: "1540x1024",
    },
    {
        label: "155x900",
        value: "155x900",
    },
    {
        label: "158x598",
        value: "158x598",
    },
    {
        label: "1600x1024",
        value: "1600x1024",
    },
    {
        label: "1600x2560",
        value: "1600x2560",
    },
    {
        label: "1600x500",
        value: "1600x500",
    },
    {
        label: "1600x810",
        value: "1600x810",
    },
    {
        label: "160x150",
        value: "160x150",
    },
    {
        label: "160x160",
        value: "160x160",
    },
    {
        label: "160x300",
        value: "160x300",
    },
    {
        label: "160x600 - Wide Skyscraper",
        value: "160x600",
    },
    {
        label: "160x601",
        value: "160x601",
    },
    {
        label: "160x607",
        value: "160x607",
    },
    {
        label: "160x609",
        value: "160x609",
    },
    {
        label: "160x800",
        value: "160x800",
    },
    {
        label: "160x90",
        value: "160x90",
    },
    {
        label: "160x91",
        value: "160x91",
    },
    {
        label: "161x600",
        value: "161x600",
    },
    {
        label: "168x28 - Mobile",
        value: "168x28",
    },
    {
        label: "168x42 - Mobile",
        value: "168x42",
    },
    {
        label: "16x9",
        value: "16x9",
    },
    {
        label: "170x1085",
        value: "170x1085",
    },
    {
        label: "170x1086",
        value: "170x1086",
    },
    {
        label: "170x1087",
        value: "170x1087",
    },
    {
        label: "1764x468",
        value: "1764x468",
    },
    {
        label: "176x144",
        value: "176x144",
    },
    {
        label: "1800x1000 - Arch Ad Format",
        value: "1800x1000",
    },
    {
        label: "1800x1050",
        value: "1800x1050",
    },
    {
        label: "1800x1080",
        value: "1800x1080",
    },
    {
        label: "1800x1800",
        value: "1800x1800",
    },
    {
        label: "1800x200",
        value: "1800x200",
    },
    {
        label: "180x100",
        value: "180x100",
    },
    {
        label: "180x150 - Small Rectangle",
        value: "180x150",
    },
    {
        label: "180x180",
        value: "180x180",
    },
    {
        label: "180x35",
        value: "180x35",
    },
    {
        label: "180x500",
        value: "180x500",
    },
    {
        label: "180x800",
        value: "180x800",
    },
    {
        label: "1856X640",
        value: "1856x640",
    },
    {
        label: "1903x250",
        value: "1903x250",
    },
    {
        label: "190x200",
        value: "190x200",
    },
    {
        label: "1920x1080",
        value: "1920x1080",
    },
    {
        label: "1920x1200",
        value: "1920x1200",
    },
    {
        label: "1920x1920",
        value: "1920x1920",
    },
    {
        label: "1920x2160",
        value: "1920x2160",
    },
    {
        label: "1920x240",
        value: "1920x240",
    },
    {
        label: "1920x256",
        value: "1920x256",
    },
    {
        label: "1920x380",
        value: "1920x380",
    },
    {
        label: "1920x384",
        value: "1920x384",
    },
    {
        label: "1920x432",
        value: "1920x432",
    },
    {
        label: "1920x450",
        value: "1920x450",
    },
    {
        label: "1920x480",
        value: "1920x480",
    },
    {
        label: "1920x500",
        value: "1920x500",
    },
    {
        label: "1920x672",
        value: "1920x672",
    },
    {
        label: "1920x864",
        value: "1920x864",
    },
    {
        label: "1920x900",
        value: "1920x900",
    },
    {
        label: "1920x960",
        value: "1920x960",
    },
    {
        label: "192x256",
        value: "192x256",
    },
    {
        label: "1x1 - Native",
        value: "1x1",
    },
    {
        label: "1x2",
        value: "1x2",
    },
    {
        label: "2000x1400",
        value: "2000x1400",
    },
    {
        label: "200x200 - Small Square",
        value: "200x200",
    },
    {
        label: "200x250",
        value: "200x250",
    },
    {
        label: "200x300",
        value: "200x300",
    },
    {
        label: "200x446",
        value: "200x446",
    },
    {
        label: "200x600",
        value: "200x600",
    },
    {
        label: "200x90",
        value: "200x90",
    },
    {
        label: "2048x768",
        value: "2048x768",
    },
    {
        label: "210x210",
        value: "210x210",
    },
    {
        label: "210x990 - Double Leaderboard",
        value: "210x990",
    },
    {
        label: "2112x384",
        value: "2112x384",
    },
    {
        label: "215x215",
        value: "215x215",
    },
    {
        label: "2160x1920",
        value: "2160x1920",
    },
    {
        label: "2160x3840",
        value: "2160x3840",
    },
    {
        label: "216x36",
        value: "216x36",
    },
    {
        label: "216x54 - Mobile",
        value: "216x54",
    },
    {
        label: "220x120",
        value: "220x120",
    },
    {
        label: "220x260",
        value: "220x260",
    },
    {
        label: "220x600",
        value: "220x600",
    },
    {
        label: "220x90",
        value: "220x90",
    },
    {
        label: "221x90",
        value: "221x90",
    },
    {
        label: "2240x560",
        value: "2240x560",
    },
    {
        label: "226x128",
        value: "226x128",
    },
    {
        label: "2280x264",
        value: "2280x264",
    },
    {
        label: "229x206",
        value: "229x206",
    },
    {
        label: "2304x960",
        value: "2304x960",
    },
    {
        label: "230x214",
        value: "230x214",
    },
    {
        label: "230x600",
        value: "230x600",
    },
    {
        label: "234x60 - Half Banner",
        value: "234x60",
    },
    {
        label: "235x240",
        value: "235x240",
    },
    {
        label: "235x90",
        value: "235x90",
    },
    {
        label: "2400x384",
        value: "2400x384",
    },
    {
        label: "240x100",
        value: "240x100",
    },
    {
        label: "240x133",
        value: "240x133",
    },
    {
        label: "240X295",
        value: "240x295",
    },
    {
        label: "240x320 - Mobile",
        value: "240x320",
    },
    {
        label: "240x400",
        value: "240x400",
    },
    {
        label: "240x600",
        value: "240x600",
    },
    {
        label: "245x210",
        value: "245x210",
    },
    {
        label: "245x260",
        value: "245x260",
    },
    {
        label: "245x342",
        value: "245x342",
    },
    {
        label: "245x770",
        value: "245x770",
    },
    {
        label: "2496x384",
        value: "2496x384",
    },
    {
        label: "250x120",
        value: "250x120",
    },
    {
        label: "250x200",
        value: "250x200",
    },
    {
        label: "250x240",
        value: "250x240",
    },
    {
        label: "250x250 - Square",
        value: "250x250",
    },
    {
        label: "250x280",
        value: "250x280",
    },
    {
        label: "250x300",
        value: "250x300",
    },
    {
        label: "250x360",
        value: "250x360",
    },
    {
        label: "250x460",
        value: "250x460",
    },
    {
        label: "250x480",
        value: "250x480",
    },
    {
        label: "250x580",
        value: "250x580",
    },
    {
        label: "250x600",
        value: "250x600",
    },
    {
        label: "250x720",
        value: "250x720",
    },
    {
        label: "250X800",
        value: "250x800",
    },
    {
        label: "2560x1024",
        value: "2560x1024",
    },
    {
        label: "2560x1140",
        value: "2560x1140",
    },
    {
        label: "2560x1440",
        value: "2560x1440",
    },
    {
        label: "2560x256 - Mobile",
        value: "2560x256",
    },
    {
        label: "2560x720",
        value: "2560x720",
    },
    {
        label: "256x144",
        value: "256x144",
    },
    {
        label: "256x192",
        value: "256x192",
    },
    {
        label: "256x256",
        value: "256x256",
    },
    {
        label: "260x600",
        value: "260x600",
    },
    {
        label: "2640x675",
        value: "2640x675",
    },
    {
        label: "2700X2278",
        value: "2700x2278",
    },
    {
        label: "270x90",
        value: "270x90",
    },
    {
        label: "288x126",
        value: "288x126",
    },
    {
        label: "288x200",
        value: "288x200",
    },
    {
        label: "288x384",
        value: "288x384",
    },
    {
        label: "290x456 - Double Page Spread",
        value: "290x456",
    },
    {
        label: "294x80 ",
        value: "294x80",
    },
    {
        label: "298x171",
        value: "298x171",
    },
    {
        label: "299x100",
        value: "100x299",
    },
    {
        label: "299x249",
        value: "299x249",
    },
    {
        label: "2x2",
        value: "2x2",
    },
    {
        label: "300x100",
        value: "300x100",
    },
    {
        label: "300x1050 - Portrait",
        value: "300x1050",
    },
    {
        label: "300x120",
        value: "300x120",
    },
    {
        label: "300x125",
        value: "300x125",
    },
    {
        label: "300x140",
        value: "300x140",
    },
    {
        label: "300x150",
        value: "300x150",
    },
    {
        label: "300x170",
        value: "300x170",
    },
    {
        label: "300x180",
        value: "300x180",
    },
    {
        label: "300x189",
        value: "300x189",
    },
    {
        label: "300x195",
        value: "300x195",
    },
    {
        label: "300x200",
        value: "300x200",
    },
    {
        label: "300x215",
        value: "300x215",
    },
    {
        label: "300x225",
        value: "300x225",
    },
    {
        label: "300x240",
        value: "300x240",
    },
    {
        label: "300x250 - Sidekick",
        value: "300x250",
    },
    {
        label: "300x251",
        value: "300x251",
    },
    {
        label: "300x252",
        value: "300x252",
    },
    {
        label: "300x257",
        value: "300x257",
    },
    {
        label: "300x260",
        value: "300x260",
    },
    {
        label: "300x265",
        value: "300x265",
    },
    {
        label: "300x274",
        value: "300x274",
    },
    {
        label: "300x287",
        value: "300x287",
    },
    {
        label: "300x292",
        value: "300x292",
    },
    {
        label: "300x300 - Banner",
        value: "300x300",
    },
    {
        label: "300x31",
        value: "300x31",
    },
    {
        label: "300x333",
        value: "300x333",
    },
    {
        label: "300x337",
        value: "300x337",
    },
    {
        label: "300x360",
        value: "300x360",
    },
    {
        label: "300x400",
        value: "300x400",
    },
    {
        label: "300x407",
        value: "300x407",
    },
    {
        label: "300x430",
        value: "300x430",
    },
    {
        label: "300x431",
        value: "300x431",
    },
    {
        label: "300x433",
        value: "300x433",
    },
    {
        label: "300x45",
        value: "300x45",
    },
    {
        label: "300x450",
        value: "300x450",
    },
    {
        label: "300x46",
        value: "300x46",
    },
    {
        label: "300x47",
        value: "300x47",
    },
    {
        label: "300x48 - Mobile",
        value: "300x48",
    },
    {
        label: "300x480",
        value: "300x480",
    },
    {
        label: "300x49",
        value: "300x49",
    },
    {
        label: "300x50",
        value: "300x50",
    },
    {
        label: "300x500",
        value: "300x500",
    },
    {
        label: "300x51",
        value: "300x51",
    },
    {
        label: "300x52",
        value: "300x52",
    },
    {
        label: "300x520",
        value: "300x520",
    },
    {
        label: "300x53",
        value: "300x53",
    },
    {
        label: "300x54",
        value: "300x54",
    },
    {
        label: "300x55",
        value: "300x55",
    },
    {
        label: "300x550",
        value: "300x550",
    },
    {
        label: "300x56",
        value: "300x56",
    },
    {
        label: "300x60",
        value: "300x60",
    },
    {
        label: "300x600 - Filmstrip",
        value: "300x600",
    },
    {
        label: "300x601",
        value: "300x601",
    },
    {
        label: "300x607",
        value: "300x607",
    },
    {
        label: "300x65",
        value: "300x65",
    },
    {
        label: "300x75 - Mobile",
        value: "300x75",
    },
    {
        label: "300x750",
        value: "300x750",
    },
    {
        label: "300x80",
        value: "300x80",
    },
    {
        label: "300x850",
        value: "300x850",
    },
    {
        label: "301x250",
        value: "301x250",
    },
    {
        label: "301x50",
        value: "301x50",
    },
    {
        label: "301x51",
        value: "301x51",
    },
    {
        label: "301x60",
        value: "301x60",
    },
    {
        label: "301x601",
        value: "301x601",
    },
    {
        label: "302x250",
        value: "302x250",
    },
    {
        label: "303x250",
        value: "303x250",
    },
    {
        label: "305x250",
        value: "305x250",
    },
    {
        label: "3072x384",
        value: "3072x384",
    },
    {
        label: "30x106",
        value: "30x106",
    },
    {
        label: "310x400",
        value: "310x400",
    },
    {
        label: "310x730",
        value: "310x730",
    },
    {
        label: "310x970 - Megaboard",
        value: "310x970",
    },
    {
        label: "319x49",
        value: "319x49",
    },
    {
        label: "320x1",
        value: "320x1",
    },
    {
        label: "320x100 - Mobile",
        value: "320x100",
    },
    {
        label: "320x1100",
        value: "320x1100",
    },
    {
        label: "320x120",
        value: "320x120",
    },
    {
        label: "320x125",
        value: "320x125",
    },
    {
        label: "320x140",
        value: "320x140",
    },
    {
        label: "320x150",
        value: "320x150",
    },
    {
        label: "320x160 - Mobile",
        value: "320x160",
    },
    {
        label: "320x180",
        value: "320x180",
    },
    {
        label: "320x200",
        value: "320x200",
    },
    {
        label: "320x240 - Mobile",
        value: "320x240",
    },
    {
        label: "320x250 - Mobile",
        value: "320x250",
    },
    {
        label: "320x266",
        value: "320x266",
    },
    {
        label: "320x270",
        value: "320x270",
    },
    {
        label: "320x288",
        value: "320x288",
    },
    {
        label: "320x300",
        value: "320x300",
    },
    {
        label: "320x320 - Mobile",
        value: "320x320",
    },
    {
        label: "320x351 - Mobile",
        value: "320x351",
    },
    {
        label: "320x360",
        value: "320x360",
    },
    {
        label: "320x40",
        value: "320x40",
    },
    {
        label: "320x400",
        value: "320x400",
    },
    {
        label: "320x416",
        value: "320x416",
    },
    {
        label: "320x45",
        value: "320x45",
    },
    {
        label: "320x46",
        value: "320x46",
    },
    {
        label: "320x460 - Mobile",
        value: "320x460",
    },
    {
        label: "320x47",
        value: "320x47",
    },
    {
        label: "320x48 - Mobile",
        value: "320x48",
    },
    {
        label: "320x480 - Mobile",
        value: "320x480",
    },
    {
        label: "320x49",
        value: "320x49",
    },
    {
        label: "320x50 - Mobile",
        value: "320x50",
    },
    {
        label: "320x500",
        value: "320x500",
    },
    {
        label: "320x51",
        value: "320x51",
    },
    {
        label: "320x52",
        value: "320x52",
    },
    {
        label: "320x53 - Mobile",
        value: "320x53",
    },
    {
        label: "320x54",
        value: "320x54",
    },
    {
        label: "320x540",
        value: "320x540",
    },
    {
        label: "320x55",
        value: "320x55",
    },
    {
        label: "320x568",
        value: "320x568",
    },
    {
        label: "320x592",
        value: "320x592",
    },
    {
        label: "320x60 - Mobile",
        value: "320x60",
    },
    {
        label: "320x600",
        value: "320x600",
    },
    {
        label: "320x610",
        value: "320x610",
    },
    {
        label: "320x620",
        value: "320x620",
    },
    {
        label: "320x64",
        value: "320x64",
    },
    {
        label: "320x75",
        value: "320x75",
    },
    {
        label: "320x80 - Mobile",
        value: "320x80",
    },
    {
        label: "320x85",
        value: "320x85",
    },
    {
        label: "320x90",
        value: "320x90",
    },
    {
        label: "321x50",
        value: "321x50",
    },
    {
        label: "321x51",
        value: "321x51",
    },
    {
        label: "322x300",
        value: "322x300",
    },
    {
        label: "324x250",
        value: "324x250",
    },
    {
        label: "325x203",
        value: "325x203",
    },
    {
        label: "325x204",
        value: "325x204",
    },
    {
        label: "325x236",
        value: "325x236",
    },
    {
        label: "325x508",
        value: "325x508",
    },
    {
        label: "330x275",
        value: "330x275",
    },
    {
        label: "330x306",
        value: "330x306",
    },
    {
        label: "330x309",
        value: "330x309",
    },
    {
        label: "330x330",
        value: "330x330",
    },
    {
        label: "330x370",
        value: "330x370",
    },
    {
        label: "331x320",
        value: "331x320",
    },
    {
        label: "331x371",
        value: "331x371",
    },
    {
        label: "331x552",
        value: "331x552",
    },
    {
        label: "336x270",
        value: "336x270",
    },
    {
        label: "336x280 - Large Rectangle",
        value: "336x280",
    },
    {
        label: "336x300",
        value: "336x300",
    },
    {
        label: "336x306",
        value: "336x306",
    },
    {
        label: "336x314",
        value: "336x314",
    },
    {
        label: "336x318",
        value: "336x318",
    },
    {
        label: "336x320",
        value: "336x320",
    },
    {
        label: "336x327",
        value: "336x327",
    },
    {
        label: "336x328",
        value: "336x328",
    },
    {
        label: "336x330",
        value: "336x330",
    },
    {
        label: "336x400",
        value: "336x400",
    },
    {
        label: "336x600",
        value: "336x600",
    },
    {
        label: "336x850",
        value: "336x850",
    },
    {
        label: "338x194",
        value: "338x194",
    },
    {
        label: "339x320",
        value: "339x320",
    },
    {
        label: "340x250",
        value: "340x250",
    },
    {
        label: "340x280",
        value: "340x280",
    },
    {
        label: "340x480",
        value: "340x480",
    },
    {
        label: "340x600",
        value: "340x600",
    },
    {
        label: "345x287",
        value: "345x287",
    },
    {
        label: "345x345",
        value: "345x345",
    },
    {
        label: "345x387",
        value: "345x387",
    },
    {
        label: "346x60",
        value: "346x60",
    },
    {
        label: "349x392",
        value: "349x392",
    },
    {
        label: "350x197",
        value: "350x197",
    },
    {
        label: "350x380",
        value: "350x380",
    },
    {
        label: "350x860",
        value: "350x860",
    },
    {
        label: "353x202",
        value: "353x202",
    },
    {
        label: "354x295",
        value: "354x295",
    },
    {
        label: "354x320",
        value: "354x320",
    },
    {
        label: "355x320",
        value: "355x320",
    },
    {
        label: "358x250",
        value: "358x250",
    },
    {
        label: "360x100",
        value: "360x100",
    },
    {
        label: "360x150",
        value: "360x150",
    },
    {
        label: "360x180",
        value: "360x180",
    },
    {
        label: "360x200",
        value: "360x200",
    },
    {
        label: "360x250",
        value: "360x250",
    },
    {
        label: "360x300",
        value: "360x300",
    },
    {
        label: "360x306",
        value: "360x306",
    },
    {
        label: "360x320",
        value: "360x320",
    },
    {
        label: "360x323",
        value: "360x323",
    },
    {
        label: "360x328",
        value: "360x328",
    },
    {
        label: "360x332",
        value: "360x332",
    },
    {
        label: "360x50",
        value: "360x50",
    },
    {
        label: "360x540",
        value: "360x540",
    },
    {
        label: "360x60",
        value: "360x60",
    },
    {
        label: "360x600",
        value: "360x600",
    },
    {
        label: "360x640",
        value: "360x640",
    },
    {
        label: "360x692",
        value: "360x692",
    },
    {
        label: "360x710",
        value: "360x710",
    },
    {
        label: "360x720",
        value: "360x720",
    },
    {
        label: "363x302",
        value: "363x302",
    },
    {
        label: "363x356",
        value: "363x356",
    },
    {
        label: "363x359",
        value: "363x359",
    },
    {
        label: "363x360",
        value: "363x360",
    },
    {
        label: "366X204",
        value: "366x204",
    },
    {
        label: "368x300",
        value: "368x300",
    },
    {
        label: "370x370",
        value: "370x370",
    },
    {
        label: "375x1",
        value: "375x1",
    },
    {
        label: "375x100",
        value: "375x100",
    },
    {
        label: "375x110",
        value: "375x110",
    },
    {
        label: "375x150",
        value: "375x150",
    },
    {
        label: "375x250",
        value: "375x250",
    },
    {
        label: "375x312",
        value: "375x312",
    },
    {
        label: "375x320",
        value: "375x320",
    },
    {
        label: "375x355",
        value: "375x355",
    },
    {
        label: "375x358",
        value: "375x358",
    },
    {
        label: "375x375",
        value: "375x375",
    },
    {
        label: "375x50",
        value: "375x50",
    },
    {
        label: "375x510",
        value: "375x510",
    },
    {
        label: "375x627",
        value: "375x627",
    },
    {
        label: "375x667",
        value: "375x667",
    },
    {
        label: "375x734",
        value: "375x734",
    },
    {
        label: "375x812",
        value: "375x812",
    },
    {
        label: "375x90",
        value: "375x90",
    },
    {
        label: "377x314",
        value: "377x314",
    },
    {
        label: "379x425",
        value: "379x425",
    },
    {
        label: "380x250",
        value: "380x250",
    },
    {
        label: "382x318",
        value: "382x318",
    },
    {
        label: "382x349",
        value: "382x349",
    },
    {
        label: "382x358",
        value: "382x358",
    },
    {
        label: "382x359",
        value: "382x359",
    },
    {
        label: "382x361",
        value: "382x361",
    },
    {
        label: "382x371",
        value: "382x371",
    },
    {
        label: "382x375",
        value: "382x375",
    },
    {
        label: "382x378",
        value: "382x378",
    },
    {
        label: "382x379",
        value: "382x379",
    },
    {
        label: "383x50",
        value: "383x50",
    },
    {
        label: "3840x4320",
        value: "3840x4320",
    },
    {
        label: "384x256",
        value: "384x256",
    },
    {
        label: "384x320",
        value: "384x320",
    },
    {
        label: "384x352",
        value: "384x352",
    },
    {
        label: "385x120",
        value: "385x120",
    },
    {
        label: "385x190",
        value: "385x190",
    },
    {
        label: "385x385",
        value: "385x385",
    },
    {
        label: "386x280",
        value: "386x280",
    },
    {
        label: "386x410",
        value: "386x410",
    },
    {
        label: "387x434",
        value: "387x434",
    },
    {
        label: "3890x690",
        value: "3890x690",
    },
    {
        label: "390x320",
        value: "390x320",
    },
    {
        label: "390x325",
        value: "390x325",
    },
    {
        label: "390x764",
        value: "390x764",
    },
    {
        label: "391x320",
        value: "391x320",
    },
    {
        label: "392x72",
        value: "392x72",
    },
    {
        label: "393x320",
        value: "393x320",
    },
    {
        label: "393x50",
        value: "393x50",
    },
    {
        label: "396x468",
        value: "396x468",
    },
    {
        label: "396x576",
        value: "396x576",
    },
    {
        label: "397x275",
        value: "397x275",
    },
    {
        label: "397x316",
        value: "397x316",
    },
    {
        label: "3x1",
        value: "3x1",
    },
    {
        label: "3x3",
        value: "3x3",
    },
    {
        label: "400x140",
        value: "400x140",
    },
    {
        label: "400x225",
        value: "400x225",
    },
    {
        label: "400x300",
        value: "400x300",
    },
    {
        label: "400x410",
        value: "400x410",
    },
    {
        label: "400x600",
        value: "400x600",
    },
    {
        label: "400x800",
        value: "400x800",
    },
    {
        label: "404x210",
        value: "404x210",
    },
    {
        label: "412x320",
        value: "412x320",
    },
    {
        label: "412x343",
        value: "412x343",
    },
    {
        label: "412x346",
        value: "412x346",
    },
    {
        label: "412x366",
        value: "412x366",
    },
    {
        label: "412x383",
        value: "412x383",
    },
    {
        label: "412x386",
        value: "412x386",
    },
    {
        label: "412x391",
        value: "412x391",
    },
    {
        label: "412x412",
        value: "412x412",
    },
    {
        label: "412x50",
        value: "412x50",
    },
    {
        label: "412x775",
        value: "412x775",
    },
    {
        label: "412x798",
        value: "412x798",
    },
    {
        label: "414x320",
        value: "414x320",
    },
    {
        label: "414x345",
        value: "414x345",
    },
    {
        label: "414x394",
        value: "414x394",
    },
    {
        label: "414x396",
        value: "414x396",
    },
    {
        label: "414x398",
        value: "414x398",
    },
    {
        label: "414x50",
        value: "414x50",
    },
    {
        label: "414x736",
        value: "414x736",
    },
    {
        label: "414x816",
        value: "414x816",
    },
    {
        label: "418x418",
        value: "418x418",
    },
    {
        label: "420x105",
        value: "420x105",
    },
    {
        label: "420x140",
        value: "420x140",
    },
    {
        label: "420x380",
        value: "420x380",
    },
    {
        label: "420x640",
        value: "420x640",
    },
    {
        label: "4224x1760",
        value: "4224x1760",
    },
    {
        label: "424x50",
        value: "424x50",
    },
    {
        label: "425x354",
        value: "425x354",
    },
    {
        label: "425x600",
        value: "425x600",
    },
    {
        label: "428x120",
        value: "428x120",
    },
    {
        label: "430x135",
        value: "430x135",
    },
    {
        label: "430x270",
        value: "430x270",
    },
    {
        label: "4312X1824",
        value: "4312x1824",
    },
    {
        label: "4320x3840",
        value: "4320x3840",
    },
    {
        label: "432x372",
        value: "432x372",
    },
    {
        label: "440x220",
        value: "440x220",
    },
    {
        label: "440x248",
        value: "440x248",
    },
    {
        label: "440x250",
        value: "440x250",
    },
    {
        label: "450x150",
        value: "450x150",
    },
    {
        label: "450x250",
        value: "450x250",
    },
    {
        label: "450x450",
        value: "450x450",
    },
    {
        label: "450x50",
        value: "450x50",
    },
    {
        label: "450x600",
        value: "450x600",
    },
    {
        label: "456x290 - Double Page Spread",
        value: "456x290",
    },
    {
        label: "460x150",
        value: "460x150",
    },
    {
        label: "460x200",
        value: "460x200",
    },
    {
        label: "460X360",
        value: "460x360",
    },
    {
        label: "460x60",
        value: "460x60",
    },
    {
        label: "467x120",
        value: "467x120",
    },
    {
        label: "468x120",
        value: "468x120",
    },
    {
        label: "468x200",
        value: "468x200",
    },
    {
        label: "468x220",
        value: "468x220",
    },
    {
        label: "468x240",
        value: "468x240",
    },
    {
        label: "468x250",
        value: "468x250",
    },
    {
        label: "468x264",
        value: "468x264",
    },
    {
        label: "468x280",
        value: "468x280",
    },
    {
        label: "468x300",
        value: "468x300",
    },
    {
        label: "468x360 - Web",
        value: "468x360",
    },
    {
        label: "468x400",
        value: "468x400",
    },
    {
        label: "468x480",
        value: "468x480",
    },
    {
        label: "468x60 - Banner",
        value: "468x60",
    },
    {
        label: "468x600",
        value: "468x600",
    },
    {
        label: "468x672",
        value: "468x672",
    },
    {
        label: "468x756",
        value: "468x756",
    },
    {
        label: "468x80",
        value: "468x80",
    },
    {
        label: "468X90",
        value: "468x90",
    },
    {
        label: "477x600",
        value: "477x600",
    },
    {
        label: "480x100",
        value: "480x100",
    },
    {
        label: "480x180",
        value: "480x180",
    },
    {
        label: "480x200",
        value: "480x200",
    },
    {
        label: "480x240",
        value: "480x240",
    },
    {
        label: "480x250",
        value: "480x250",
    },
    {
        label: "480x270",
        value: "480x270",
    },
    {
        label: "480x280",
        value: "480x280",
    },
    {
        label: "480x288",
        value: "480x288",
    },
    {
        label: "480x300 - Wallpaper",
        value: "480x300",
    },
    {
        label: "480x32 - Mobile",
        value: "480x32",
    },
    {
        label: "480x320 - Mobile",
        value: "480x320",
    },
    {
        label: "480x360",
        value: "480x360",
    },
    {
        label: "480x400",
        value: "480x400",
    },
    {
        label: "480x480",
        value: "480x480",
    },
    {
        label: "480x50 - Mobile",
        value: "480x50",
    },
    {
        label: "480x504",
        value: "480x504",
    },
    {
        label: "480x540",
        value: "480x540",
    },
    {
        label: "480x60 - Mobile",
        value: "480x60",
    },
    {
        label: "480x600",
        value: "480x600",
    },
    {
        label: "480x640",
        value: "480x640",
    },
    {
        label: "480x75",
        value: "480x75",
    },
    {
        label: "480x80 - Mobile",
        value: "480x80",
    },
    {
        label: "480x800 - Mobile",
        value: "480x800",
    },
    {
        label: "480x820",
        value: "480x820",
    },
    {
        label: "480x854 - Mobile",
        value: "480x854",
    },
    {
        label: "480X960",
        value: "480x960",
    },
    {
        label: "487x297",
        value: "487x297",
    },
    {
        label: "491x555",
        value: "491x555",
    },
    {
        label: "4x1",
        value: "4x1",
    },
    {
        label: "4x4",
        value: "4x4",
    },
    {
        label: "500x1000",
        value: "500x1000",
    },
    {
        label: "500x200",
        value: "500x200",
    },
    {
        label: "500x240",
        value: "500x240",
    },
    {
        label: "500x360",
        value: "500x360",
    },
    {
        label: "500x500",
        value: "500x500",
    },
    {
        label: "504x864",
        value: "504x864",
    },
    {
        label: "510x140",
        value: "510x140",
    },
    {
        label: "510x90",
        value: "510x90",
    },
    {
        label: "512x320",
        value: "512x320",
    },
    {
        label: "520x290",
        value: "520x290",
    },
    {
        label: "520x360",
        value: "520x360",
    },
    {
        label: "525x320",
        value: "525x320",
    },
    {
        label: "530x140",
        value: "530x140",
    },
    {
        label: "530x150",
        value: "530x150",
    },
    {
        label: "536x165",
        value: "536x165",
    },
    {
        label: "536x302",
        value: "536x302",
    },
    {
        label: "540x185",
        value: "540x185",
    },
    {
        label: "540x280",
        value: "540x280",
    },
    {
        label: "540x340",
        value: "540x340",
    },
    {
        label: "540x960",
        value: "540x960",
    },
    {
        label: "550x100",
        value: "550x100",
    },
    {
        label: "550x140",
        value: "550x140",
    },
    {
        label: "550x150",
        value: "550x150",
    },
    {
        label: "550x480",
        value: "550x480",
    },
    {
        label: "550x50",
        value: "550x50",
    },
    {
        label: "556x280",
        value: "556x280",
    },
    {
        label: "560x120",
        value: "560x120",
    },
    {
        label: "560x270",
        value: "560x270",
    },
    {
        label: "560x300",
        value: "560x300",
    },
    {
        label: "560x315",
        value: "560x315",
    },
    {
        label: "560x400",
        value: "560x400",
    },
    {
        label: "568x320",
        value: "568x320",
    },
    {
        label: "570x140",
        value: "570x140",
    },
    {
        label: "570x200",
        value: "570x200",
    },
    {
        label: "570x600",
        value: "570x600",
    },
    {
        label: "576x1152",
        value: "576x1152",
    },
    {
        label: "576x192",
        value: "576x192",
    },
    {
        label: "576x240",
        value: "576x240",
    },
    {
        label: "576x324",
        value: "576x324",
    },
    {
        label: "576x396",
        value: "576x396",
    },
    {
        label: "576X576 - DOOH",
        value: "576x576",
    },
    {
        label: "576x864",
        value: "576x864",
    },
    {
        label: "576x960",
        value: "576x960",
    },
    {
        label: "578x648",
        value: "578x648",
    },
    {
        label: "580x110",
        value: "580x110",
    },
    {
        label: "580x120",
        value: "580x120",
    },
    {
        label: "580x150",
        value: "580x150",
    },
    {
        label: "580x300",
        value: "580x300",
    },
    {
        label: "580x400",
        value: "580x400",
    },
    {
        label: "580x500",
        value: "580x500",
    },
    {
        label: "591x295",
        value: "591x295",
    },
    {
        label: "5x5",
        value: "5x5",
    },
    {
        label: "600x100",
        value: "600x100",
    },
    {
        label: "600x1024",
        value: "600x1024",
    },
    {
        label: "600x1080",
        value: "600x1080",
    },
    {
        label: "600X120",
        value: "600x120",
    },
    {
        label: "600x1200",
        value: "600x1200",
    },
    {
        label: "600x180",
        value: "600x180",
    },
    {
        label: "600x190",
        value: "600x190",
    },
    {
        label: "600x250",
        value: "600x250",
    },
    {
        label: "600x300",
        value: "600x300",
    },
    {
        label: "600x338",
        value: "600x338",
    },
    {
        label: "600x350",
        value: "600x350",
    },
    {
        label: "600x400",
        value: "600x400",
    },
    {
        label: "600x50",
        value: "600x50",
    },
    {
        label: "600x500",
        value: "600x500",
    },
    {
        label: "600x600",
        value: "600x600",
    },
    {
        label: "600x80",
        value: "600x80",
    },
    {
        label: "600x800",
        value: "600x800",
    },
    {
        label: "600x832 - Mobile",
        value: "600x832",
    },
    {
        label: "600x90",
        value: "600x90",
    },
    {
        label: "600x900",
        value: "600x900",
    },
    {
        label: "600x976",
        value: "600x976",
    },
    {
        label: "605x149",
        value: "605x149",
    },
    {
        label: "605x150",
        value: "605x150",
    },
    {
        label: "605x165",
        value: "605x165",
    },
    {
        label: "605x340",
        value: "605x340",
    },
    {
        label: "60x40",
        value: "60x40",
    },
    {
        label: "60x425 - overlay/interstitial",
        value: "60x425",
    },
    {
        label: "610x100",
        value: "610x100",
    },
    {
        label: "610x138",
        value: "610x138",
    },
    {
        label: "610x57",
        value: "610x57",
    },
    {
        label: "616x70",
        value: "616x70",
    },
    {
        label: "620x185",
        value: "620x185",
    },
    {
        label: "620x200",
        value: "620x200",
    },
    {
        label: "620x249",
        value: "620x249",
    },
    {
        label: "620x250",
        value: "620x250",
    },
    {
        label: "620x253",
        value: "620x253",
    },
    {
        label: "620x300",
        value: "620x300",
    },
    {
        label: "620x345",
        value: "620x345",
    },
    {
        label: "620x360",
        value: "620x360",
    },
    {
        label: "620x366",
        value: "620x366",
    },
    {
        label: "620x371",
        value: "620x371",
    },
    {
        label: "620x400",
        value: "620x400",
    },
    {
        label: "620x415",
        value: "620x415",
    },
    {
        label: "620x500",
        value: "620x500",
    },
    {
        label: "620x620",
        value: "620x620",
    },
    {
        label: "620x891",
        value: "620x891",
    },
    {
        label: "620x90",
        value: "620x90",
    },
    {
        label: "621x320",
        value: "621x320",
    },
    {
        label: "626X85",
        value: "626x85",
    },
    {
        label: "627x627",
        value: "627x627",
    },
    {
        label: "628x185",
        value: "628x185",
    },
    {
        label: "630x172",
        value: "630x172",
    },
    {
        label: "630x200",
        value: "630x200",
    },
    {
        label: "630x400",
        value: "630x400",
    },
    {
        label: "630x90",
        value: "630x90",
    },
    {
        label: "637x359",
        value: "637x359",
    },
    {
        label: "638x175",
        value: "638x175",
    },
    {
        label: "638x200",
        value: "638x200",
    },
    {
        label: "638x250",
        value: "638x250",
    },
    {
        label: "638x315",
        value: "638x315",
    },
    {
        label: "638x360",
        value: "638x360",
    },
    {
        label: "640 x 480",
        value: "640x480",
    },
    {
        label: "640 x 960",
        value: "640x960",
    },
    {
        label: "640x100",
        value: "640x100",
    },
    {
        label: "640x102",
        value: "640x102",
    },
    {
        label: "640x103",
        value: "640x103",
    },
    {
        label: "640x107 - Mobile",
        value: "640x107",
    },
    {
        label: "640x1136",
        value: "640x1136",
    },
    {
        label: "640x120",
        value: "640x120",
    },
    {
        label: "640x1280",
        value: "640x1280",
    },
    {
        label: "640x1386",
        value: "640x1386",
    },
    {
        label: "640x200",
        value: "640x200",
    },
    {
        label: "640x24",
        value: "640x24",
    },
    {
        label: "640x240",
        value: "640x240",
    },
    {
        label: "640x247",
        value: "640x247",
    },
    {
        label: "640x300",
        value: "640x300",
    },
    {
        label: "640x320",
        value: "640x320",
    },
    {
        label: "640x360",
        value: "640x360",
    },
    {
        label: "640x390",
        value: "640x390",
    },
    {
        label: "640x400",
        value: "640x400",
    },
    {
        label: "640x460",
        value: "640x460",
    },
    {
        label: "640x500",
        value: "640x500",
    },
    {
        label: "640x600",
        value: "640x600",
    },
    {
        label: "640x640 - Mobile",
        value: "640x640",
    },
    {
        label: "640x645",
        value: "640x645",
    },
    {
        label: "640x649",
        value: "640x649",
    },
    {
        label: "640x655",
        value: "640x655",
    },
    {
        label: "640x720",
        value: "640x720",
    },
    {
        label: "640x832",
        value: "640x832",
    },
    {
        label: "640x90 - Mobile",
        value: "640x90",
    },
    {
        label: "643x185",
        value: "643x185",
    },
    {
        label: "648x250",
        value: "648x250",
    },
    {
        label: "650x170",
        value: "650x170",
    },
    {
        label: "650x60",
        value: "650x60",
    },
    {
        label: "655x70",
        value: "655x70",
    },
    {
        label: "660x200",
        value: "660x200",
    },
    {
        label: "660x440",
        value: "660x440",
    },
    {
        label: "660x90",
        value: "660x90",
    },
    {
        label: "664x250",
        value: "664x250",
    },
    {
        label: "666x374",
        value: "666x374",
    },
    {
        label: "667x375",
        value: "667x375",
    },
    {
        label: "670x240",
        value: "670x240",
    },
    {
        label: "672x280",
        value: "672x280",
    },
    {
        label: "672x288",
        value: "672x288",
    },
    {
        label: "675x450",
        value: "675x450",
    },
    {
        label: "680x200",
        value: "680x200",
    },
    {
        label: "680x90",
        value: "680x90",
    },
    {
        label: "687x387",
        value: "687x387",
    },
    {
        label: "690x160",
        value: "690x160",
    },
    {
        label: "690x320",
        value: "690x320",
    },
    {
        label: "690x390",
        value: "690x390",
    },
    {
        label: "690x480",
        value: "690x480",
    },
    {
        label: "690x80",
        value: "690x80",
    },
    {
        label: "698x170",
        value: "698x170",
    },
    {
        label: "700x150",
        value: "700x150",
    },
    {
        label: "717x89",
        value: "717x89",
    },
    {
        label: "720x100",
        value: "720x100",
    },
    {
        label: "720x1232",
        value: "720x1232",
    },
    {
        label: "720x1280",
        value: "720x1280",
    },
    {
        label: "720x1440",
        value: "720x1440",
    },
    {
        label: "720x240",
        value: "720x240",
    },
    {
        label: "720x300",
        value: "720x300",
    },
    {
        label: "720x400",
        value: "720x400",
    },
    {
        label: "720x406",
        value: "720x406",
    },
    {
        label: "720x480",
        value: "720x480",
    },
    {
        label: "720x576",
        value: "720x576",
    },
    {
        label: "720x90",
        value: "720x90",
    },
    {
        label: "728x100",
        value: "728x100",
    },
    {
        label: "728x1024 - Mobile",
        value: "728x1024",
    },
    {
        label: "728x130",
        value: "728x130",
    },
    {
        label: "728x180",
        value: "728x180",
    },
    {
        label: "728x185",
        value: "728x185",
    },
    {
        label: "728x200",
        value: "728x200",
    },
    {
        label: "728x250",
        value: "728x250",
    },
    {
        label: "728x300",
        value: "728x300",
    },
    {
        label: "728x315",
        value: "728x315",
    },
    {
        label: "728x335",
        value: "728x335",
    },
    {
        label: "728x50",
        value: "728x50",
    },
    {
        label: "728x60",
        value: "728x60",
    },
    {
        label: "728x600",
        value: "728x600",
    },
    {
        label: "728x89",
        value: "728x89",
    },
    {
        label: "728x90 - Leaderboard",
        value: "728x90",
    },
    {
        label: "728x91",
        value: "728x91",
    },
    {
        label: "728x92",
        value: "728x92",
    },
    {
        label: "728x93",
        value: "728x93",
    },
    {
        label: "728x94",
        value: "728x94",
    },
    {
        label: "730x90",
        value: "730x90",
    },
    {
        label: "732x250",
        value: "732x250",
    },
    {
        label: "732x412",
        value: "732x412",
    },
    {
        label: "736x736",
        value: "736x736",
    },
    {
        label: "740x160",
        value: "740x160",
    },
    {
        label: "740x360",
        value: "740x360",
    },
    {
        label: "740x416",
        value: "740x416",
    },
    {
        label: "745x100",
        value: "745x100",
    },
    {
        label: "750x100",
        value: "750x100",
    },
    {
        label: "750x1334",
        value: "750x1334",
    },
    {
        label: "750x200",
        value: "750x200",
    },
    {
        label: "750x300",
        value: "750x300",
    },
    {
        label: "756x260 - Pre-Roll Video",
        value: "756x260",
    },
    {
        label: "760x120",
        value: "760x120",
    },
    {
        label: "768x1024 - Mobile",
        value: "768x1024",
    },
    {
        label: "768x1280",
        value: "768x1280",
    },
    {
        label: "768x288",
        value: "768x288",
    },
    {
        label: "768x384",
        value: "768x384",
    },
    {
        label: "768x432",
        value: "768x432",
    },
    {
        label: "768x500",
        value: "768x500",
    },
    {
        label: "768x90 - Mobile",
        value: "768x90",
    },
    {
        label: "770x250",
        value: "770x250",
    },
    {
        label: "770x251",
        value: "770x251",
    },
    {
        label: "770x252",
        value: "770x252",
    },
    {
        label: "770x90",
        value: "770x90",
    },
    {
        label: "788x100",
        value: "788x100",
    },
    {
        label: "793x410",
        value: "793x410",
    },
    {
        label: "794x396",
        value: "794x396",
    },
    {
        label: "799x250",
        value: "250x799",
    },
    {
        label: "7x7 - Test",
        value: "7x7",
    },
    {
        label: "800x1100",
        value: "800x1100",
    },
    {
        label: "800x1200",
        value: "800x1200",
    },
    {
        label: "800x1216",
        value: "800x1216",
    },
    {
        label: "800x1280 - Mobile",
        value: "800x1280",
    },
    {
        label: "800x250 - Billboard",
        value: "800x250",
    },
    {
        label: "800x410",
        value: "800x410",
    },
    {
        label: "800x450",
        value: "800x450",
    },
    {
        label: "800x480 - Pre-Roll Video",
        value: "800x480",
    },
    {
        label: "800x600",
        value: "800x600",
    },
    {
        label: "800x90",
        value: "800x90",
    },
    {
        label: "80x100",
        value: "80x100",
    },
    {
        label: "80x12 - Mobile",
        value: "80x12",
    },
    {
        label: "80x15 - Mobile",
        value: "80x15",
    },
    {
        label: "810x1440",
        value: "810x1440",
    },
    {
        label: "820x100",
        value: "820x100",
    },
    {
        label: "820x460",
        value: "820x460",
    },
    {
        label: "828x100",
        value: "828x100",
    },
    {
        label: "830x430",
        value: "830x430",
    },
    {
        label: "840x150",
        value: "840x150",
    },
    {
        label: "840x250",
        value: "840x250",
    },
    {
        label: "841x250",
        value: "841x250",
    },
    {
        label: "844x90",
        value: "844x90",
    },
    {
        label: "846x412",
        value: "846x412",
    },
    {
        label: "850x250",
        value: "850x250",
    },
    {
        label: "854x480",
        value: "854x480",
    },
    {
        label: "856x482",
        value: "856x482",
    },
    {
        label: "864x576",
        value: "864x576",
    },
    {
        label: "880x250",
        value: "880x250",
    },
    {
        label: "880x650",
        value: "880x650",
    },
    {
        label: "88x31 - Micro Bar",
        value: "88x31",
    },
    {
        label: "890x100",
        value: "890x100",
    },
    {
        label: "890x200",
        value: "890x200",
    },
    {
        label: "890x250",
        value: "890x250",
    },
    {
        label: "896x500",
        value: "896x500",
    },
    {
        label: "896x800",
        value: "896x800",
    },
    {
        label: "8x1",
        value: "8x1",
    },
    {
        label: "8x8",
        value: "8x8",
    },
    {
        label: "900x250",
        value: "900x250",
    },
    {
        label: "900x288",
        value: "900x288",
    },
    {
        label: "900x300 - Billboard",
        value: "900x300",
    },
    {
        label: "900x550",
        value: "900x550",
    },
    {
        label: "900x600",
        value: "900x600",
    },
    {
        label: "900x720",
        value: "900x720",
    },
    {
        label: "903x508",
        value: "903x508",
    },
    {
        label: "90x100",
        value: "90x100",
    },
    {
        label: "910x150",
        value: "910x150",
    },
    {
        label: "920x250",
        value: "920x250",
    },
    {
        label: "930x180",
        value: "930x180",
    },
    {
        label: "930x600",
        value: "930x600",
    },
    {
        label: "930x90",
        value: "930x90",
    },
    {
        label: "940x100",
        value: "940x100",
    },
    {
        label: "940x110",
        value: "940x110",
    },
    {
        label: "940x180",
        value: "940x180",
    },
    {
        label: "940x200",
        value: "940x200",
    },
    {
        label: "940x250",
        value: "940x250",
    },
    {
        label: "940x300",
        value: "940x300",
    },
    {
        label: "940x40",
        value: "940x40",
    },
    {
        label: "940x45",
        value: "940x45",
    },
    {
        label: "940x500",
        value: "940x500",
    },
    {
        label: "940x60",
        value: "940x60",
    },
    {
        label: "950x200-Header Banner",
        value: "950x200",
    },
    {
        label: "950x300",
        value: "950x300",
    },
    {
        label: "950x90",
        value: "950x90",
    },
    {
        label: "960x1",
        value: "960x1",
    },
    {
        label: "960x100",
        value: "960x100",
    },
    {
        label: "960x1024",
        value: "960x1024",
    },
    {
        label: "960x110",
        value: "960x110",
    },
    {
        label: "960x150",
        value: "960x150",
    },
    {
        label: "960x1708",
        value: "960x1708",
    },
    {
        label: "960x180",
        value: "960x180",
    },
    {
        label: "960x200",
        value: "960x200",
    },
    {
        label: "960x210",
        value: "960x210",
    },
    {
        label: "960x250",
        value: "960x250",
    },
    {
        label: "960x300",
        value: "960x300",
    },
    {
        label: "960x310",
        value: "960x310",
    },
    {
        label: "960x540",
        value: "960x540",
    },
    {
        label: "960x600",
        value: "960x600",
    },
    {
        label: "960X640",
        value: "960x640",
    },
    {
        label: "960x864",
        value: "960x864",
    },
    {
        label: "960x90",
        value: "960x90",
    },
    {
        label: "962x50",
        value: "962x50",
    },
    {
        label: "967x544",
        value: "967x544",
    },
    {
        label: "969x694",
        value: "969x694",
    },
    {
        label: "970x100",
        value: "970x100",
    },
    {
        label: "970x1000",
        value: "970x1000",
    },
    {
        label: "970x120",
        value: "970x120",
    },
    {
        label: "970x125",
        value: "970x125",
    },
    {
        label: "970x150",
        value: "970x150",
    },
    {
        label: "970x180",
        value: "970x180",
    },
    {
        label: "970x200",
        value: "970x200",
    },
    {
        label: "970x210",
        value: "970x210",
    },
    {
        label: "970x250 - Billboard",
        value: "970x250",
    },
    {
        label: "970x251",
        value: "970x251",
    },
    {
        label: "970x255",
        value: "970x255",
    },
    {
        label: "970x275",
        value: "970x275",
    },
    {
        label: "970x280",
        value: "970x280",
    },
    {
        label: "970x30 - Sliding Billboard",
        value: "970x30",
    },
    {
        label: "970x300",
        value: "970x300",
    },
    {
        label: "970x310 - Megaboard",
        value: "970x310",
    },
    {
        label: "970x360",
        value: "970x360",
    },
    {
        label: "970x40",
        value: "970x40",
    },
    {
        label: "970x400",
        value: "970x400",
    },
    {
        label: "970x50",
        value: "970x50",
    },
    {
        label: "970x500",
        value: "970x500",
    },
    {
        label: "970x550",
        value: "970x550",
    },
    {
        label: "970x66",
        value: "970x66",
    },
    {
        label: "970x90 - Pushdown",
        value: "970x90",
    },
    {
        label: "970x91",
        value: "970x91",
    },
    {
        label: "972x1885",
        value: "972x1885",
    },
    {
        label: "972x250",
        value: "972x250",
    },
    {
        label: "972x50",
        value: "972x50",
    },
    {
        label: "972x90",
        value: "972x90",
    },
    {
        label: "975x100",
        value: "975x100",
    },
    {
        label: "975x250",
        value: "975x250",
    },
    {
        label: "975x300",
        value: "975x300",
    },
    {
        label: "975x550",
        value: "975x550",
    },
    {
        label: "980x120",
        value: "980x120",
    },
    {
        label: "980x150 - Super Leaderboard",
        value: "980x150",
    },
    {
        label: "980x20",
        value: "980x20",
    },
    {
        label: "980x200",
        value: "980x200",
    },
    {
        label: "980x240",
        value: "980x240",
    },
    {
        label: "980x250",
        value: "980x250",
    },
    {
        label: "980x30",
        value: "980x30",
    },
    {
        label: "980x300 - Web",
        value: "980x300",
    },
    {
        label: "980x360",
        value: "980x360",
    },
    {
        label: "980x400",
        value: "980x400",
    },
    {
        label: "980x450",
        value: "980x450",
    },
    {
        label: "980x480",
        value: "980x480",
    },
    {
        label: "980x50",
        value: "980x50",
    },
    {
        label: "980x500",
        value: "980x500",
    },
    {
        label: "980x552",
        value: "980x552",
    },
    {
        label: "980x60 - Banner",
        value: "980x60",
    },
    {
        label: "980x600",
        value: "980x600",
    },
    {
        label: "980x75",
        value: "980x75",
    },
    {
        label: "980x800",
        value: "980x800",
    },
    {
        label: "980x90",
        value: "980x90",
    },
    {
        label: "984x430",
        value: "984x430",
    },
    {
        label: "985x400",
        value: "985x400",
    },
    {
        label: "985x555",
        value: "985x555",
    },
    {
        label: "985x600",
        value: "985x600",
    },
    {
        label: "988x320",
        value: "988x320",
    },
    {
        label: "990x100 - Leaderboard",
        value: "990x100",
    },
    {
        label: "990x180",
        value: "990x180",
    },
    {
        label: "990x210 - Double Leaderboard",
        value: "990x210",
    },
    {
        label: "990x250",
        value: "990x250",
    },
    {
        label: "990x30",
        value: "990x30",
    },
    {
        label: "990x300",
        value: "990x300",
    },
    {
        label: "990x50",
        value: "990x50",
    },
    {
        label: "990x505",
        value: "990x505",
    },
    {
        label: "990x90",
        value: "990x90",
    },
    {
        label: "992x115",
        value: "992x115",
    },
    {
        label: "994x118",
        value: "994x118",
    },
    {
        label: "994x250",
        value: "994x250",
    },
    {
        label: "994x500",
        value: "994x500",
    },
    {
        label: "994x560",
        value: "994x560",
    },
    {
        label: "995x123",
        value: "995x123",
    },
    {
        label: "996x250",
        value: "996x250",
    },
    {
        label: "997x123",
        value: "997x123",
    },
    {
        label: "998x200",
        value: "998x200",
    },
    {
        label: "998x210",
        value: "998x210",
    },
    {
        label: "998x250",
        value: "998x250",
    },
    {
        label: "9x9",
        value: "9x9",
    },
];
