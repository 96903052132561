interface IFragment {
    id?: number
    name?: string
    code?: string

    [index: string]: any
}



export function removeFragmentItemAtIndex(arr: any[], index: number) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeFragmentItemById<T = Record<string,any>>(arr: T[], id: number|string,idname?:string): T[] {
    //@ts-ignore
    return arr.filter((item: T) => item[idname ?? 'id'] !== id);
}

export function getObjectIndex(arr: IFragment[], id: number | undefined, idname?: string): number | undefined {
    const idcode = idname ?? 'id'
    return arr.findIndex( element => {
        if (element?.[idcode] === id) {
            return true;
        }
    });
}

/** sorting  */
function sortBy<T extends Record<string, any> = Record<string, any>>(
  arr: T[],
  by: string,
  sortDir: string = "asc"
) {
    const sortD = sortDir === "asc" ? 1 : -1;
    return [...arr].sort((a: T, b: T) => {
        return resolveDotPath(a, by) > resolveDotPath(b, by)
               ? sortD
               : resolveDotPath(b, by) > resolveDotPath(a, by)
                 ? -sortD
                 : 0;
    });
}

export function getObjectItemById<T  extends Record<string, any> = Record<string, any>>(arr: T[], id: number | string, idname?: string): T[] {
    const idcode = idname ?? 'id'
    
    return arr.filter((item) => {
        
        return resolveDotPath(item,idcode)  === id
    });
}

export function getObjectByFieldValue<T=any>(arr: T[]  , field: keyof T, fieldValue: any): T[]  {
    return arr?.filter((item) => item[field] === fieldValue) ?? [];
}

export function collectObjectField(th:Record<string,any>,fieldName: string){
   return th.map((t: any) => {
       if (t?.[fieldName]) return t?.[fieldName]
    })
}

function resolveDotPath   (object:Record<string,any>, path: string, defaultValue?: any):any   {
    return path
    .split('.')
    .reduce((r, s) => r ? r[s] : defaultValue, object)
}

export const dataUtils = {
    collectObjectField,
    getObjectByFieldValue,
    getObjectItemById,
    removeFragmentItemById,
    sortBy
}