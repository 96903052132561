import {Box, Button, styled, Typography} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {MainFrame} from '../../components/templates/MainFrame';
import {DspAccountChannelSelectionTable} from './DspAccountChannelSelectionTable';
import {Link, useParams} from 'react-router-dom';
import {DspAccountParameterWizard} from './DspAccountParameterWizard';
import {useQuery} from 'react-query';
import {objectApi} from '../../application/entities/dataApi';
import {Theme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {PageId} from '../../application/pages/pages.config';
import { useAccountTracker } from 'pages/Agencies/useAccountTracker';
import { useAllDspChannels, useSelectedDspChannels } from 'components/hooks/data/useDspChannelsQuery';
import { getUnselectedDsps } from 'application/utils/dsp.utils';
import { pageUtils } from 'application/pages/pages.utils';

interface IDspChannelParametersProps {
  accountId?: any
};


export const DspAccountChannelParameters: FunctionComponent<IDspChannelParametersProps> = (props: IDspChannelParametersProps) => {
  
  let {accountId}             =  useParams<{ accountId: string }>() as { accountId: string };
  accountId = props.accountId ?? accountId;
  useAccountTracker(+accountId);

  const [editMode, setEditMode] = React.useState<boolean>(false);
  const {t}         = useTranslation();
  const accountsApi              = new objectApi.accounts();

  
  const parentAccountId          = useQuery(['parentAccountId', +accountId], () => {
    return accountsApi.byId(+accountId);
  }, {enabled:Boolean(accountId)});

  
  const allDsps = useAllDspChannels(+accountId);
  const dspChannelsSelected = useSelectedDspChannels(+accountId);
  const unselectedDsps = getUnselectedDsps(allDsps.data , dspChannelsSelected.data)
  const loading = parentAccountId.isLoading || allDsps.isLoading || dspChannelsSelected.isLoading;
  
  
  return <>
  <MainFrame frameId={PageId.DspAccountChannelParameters} 
             checkPageAccess={true} 
             grantWith={{accountId:parentAccountId?.data?.parentCompanyId }}
             loading={loading}
             breadCrumbProps={{noParent:true}}
             >
    <DspChannelParametersContainer sx={{p: 2, px: 4}}>
      <div style={{position:'absolute', inset:'0 0 0 0', background:'rgba(255,255,255,0.9)', zIndex:'999',display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{textAlign:'center'}}>
          <Typography mb={2} variant={'h3'}>This page doesn't exist anymore</Typography>
          <Link to={pageUtils.getPagePathById(PageId.channelsSetup, {accountId: +accountId})}><Button variant={'contained'}>Go to the new version</Button></Link>
          
        </div>
      </div>
      {Boolean(editMode) && <DspAccountParameterWizard
        accountId={+accountId}
        isOpen={editMode}
        onValidate={() => {setEditMode(false);}}
      />}
      <br/>
      <br/>
      <BIB>
        <div className={'flex-h flex-align-middle gap-small pad-l-3 pad-r-3'} style={{justifyContent:'space-between'}}>
          <Typography variant={'h4'} sx={{my: 3}} data-cy="enablement-channels" data-test-value-selected={dspChannelsSelected?.data?.length} data-test-value-available={unselectedDsps.length}>Enablement Channels</Typography>
          <Button variant={'contained'}
                  disabled={(unselectedDsps.length === 0)}
                  data-cy={'new-channel-btn'}
                  onClick={() => {setEditMode(!editMode);}}>{ (unselectedDsps.length === 0) ? t`dspChannelParameters.noMoreChannels`: t`dspChannelParameters.addChannel`}</Button>
        </div>
        <DspAccountChannelSelectionTable
          accountId={+accountId}/>
      </BIB>
      <br/>
      <br/>
    </DspChannelParametersContainer>
  </MainFrame>

  </>
}


const BIB = styled(Box)((props: { theme: Theme }) => ({
  borderRadius: '6px',
  border      : '1px solid #F0F2F7',
  background  : props.theme.palette.background.default,
  maxWidth    : '1024px'
}));

const DspChannelParametersContainer = styled(Box)((props) => ({
  height  : '100%',
  overflow: 'auto',
  position:'relative'
}));