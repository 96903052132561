/**
 created by Mydde
 */

import * as React from 'react';
import {ClickAwayListener, TextField, Theme} from '@mui/material';
import Popper from '@mui/material/Popper';
import {StyledCheckbox} from '../CheckBox';
import {AppIcon} from '../../ui/AppIcon';
import {apiService} from '../../../application/entities/api/apiService';
import {TreeItem, TreeView} from '@mui/lab';
import {getObjectByFieldValue} from '../../../application/utils/dataState.utils';
import styledOld from 'styled-components/macro';
import {ColumnsMiddle, Elem, GridItemFull, Node} from '../../ui/AppElements';
import {TEntityName} from '../../../application/entities/dataTypes';
import _ from 'lodash';
import {styled} from '@mui/styles';
import {BackendError} from '../../../types/backendError';

interface IEntityTreeViewProps<T = any> {
  entityName: TEntityName;
  defaultField: string; // field used to display data
  hierarchyField: string; // field containing the path
  entityVars?: string[];
  selectedEntityItems?: Record<string, any>[];
  onChange?: (item: Record<string, T>) => void;
  dataWatcher?: any;
  singleSelect?: boolean; // default true - allow multiple / single selection
  textOnEmpty?: string;
  entityTextName?: string;
  mode?: 'float' | 'block';
  error?:boolean;
  registerFormField?: Record<string,any> // react-hook-form
}

interface ITreeItemData {
  name: string;
  parent?: string;
  path?: string;
  entityData?: any;
}

interface ITreeListState extends ITreeItemData {
  name: string;
  child?: ITreeListState[];
}

interface ITreeListItemState {
  name: string;
  item: ITreeListState;
}

/** @deprecated */
type ISelectedItemsState = Map<string, ISelectedListItemState>
type ISelectedItemsObjectState = Record<string, ISelectedListItemState>

interface ISelectedListItemState {
  name: string;
  item: ITreeItemData; // not needed ?
  entityData: Record<string, any>;
}

export const EntityTreeView = (props: IEntityTreeViewProps) => {
  
  const {registerFormField={}, entityName, entityVars,error, onChange, selectedEntityItems, defaultField, hierarchyField, singleSelect, entityTextName, mode = 'float'} = props;
  
  const [entityList, setEntityList]                 = React.useState<Record<string, any>[]>([]);
  const [filteredEntityList, setFilteredEntityList] = React.useState<Record<string, any>[]>([]);
  
  const [treeList, setTreeList] = React.useState<ITreeListState[]>([]);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
  
  const [selectedItems, setSelectedItems]              = React.useState<ISelectedItemsState>(new Map([]) as ISelectedItemsState);
  const [selectedItemsObject, setSelectedItemsObjects] = React.useState<ISelectedItemsObjectState>({} as ISelectedItemsObjectState);
  
  const selectedItemsRef = React.useRef<ISelectedItemsState>(new Map([]) as ISelectedItemsState);
  
  const inputSearchField      = React.useRef(null);
  const timerSearchField: any = React.useRef();
  
  const timerChange: any = React.useRef(null);
  
  const open = Boolean(anchorEl);
  const id   = `tree_${entityName}`;
  
  React.useEffect(() => {
    init(entityName);
  }, [entityName]);
  
  React.useEffect(() => {
    if (!_.isEqual(selectedItemsRef.current, selectedItems)) {
      selectedItemsRef.current = selectedItems;
    }
  }, [selectedItems]);
  
  React.useEffect(() => {
    setTreeList(buildTreeList(filteredEntityList));
  }, [filteredEntityList]);
  
  React.useEffect(() => {
    
    if (selectedEntityItems && Array.isArray(selectedEntityItems)) {
      const cd = preCheckItems([...selectedEntityItems]);
      //if (!_.isEqual(selectedItemsRef.current, selectedItems)) {//
      setSelectedItems(cd.selected);
      setSelectedItemsObjects(cd.selectedObj);
      //}
    }
  }, [entityName, selectedEntityItems]);
  
  const init = (entity: TEntityName) => {
    // run apiService on startup
    apiService.entity(entityVars ?? entityName).page({pageSize: 800, pageNumber: 0})
              .fetch({part: 'EMBEDDED'})
              .then((res) => {
                if (res && Array.isArray(res)) {
                  setEntityList(res);
                  setFilteredEntityList(res);
                  //
        
                }
              }).catch((e: BackendError) => {});
  };
  
  /**
   * return Map of selected items
   * @param itemPreSelection
   * @returns {ISelectedItemsState}
   */
  const preCheckItems = (itemPreSelection: any) => {
    const selectedObj: ISelectedItemsObjectState = {} as ISelectedItemsObjectState;
    const selected: ISelectedItemsState          = new Map([]) as ISelectedItemsState;
    // remove from selectedItems if not in Preselected
    itemPreSelection.forEach((itemData: any) => {
      if (itemData?.[defaultField]) {
        // because sometimes name and path are the same
        let realName = itemData[defaultField].split('/').pop();
        selected.set(realName, {
          name      : realName,
          item      : {
            name      : itemData[defaultField],
            entityData: itemData
          },
          entityData: itemData
        });
        
        selectedObj[realName] = {
          name      : realName,
          item      : {
            name      : itemData[defaultField],
            entityData: itemData
          },
          entityData: itemData
        };
      }
    });
    
    return {selected, selectedObj};
  };
  
  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const buildTreeList = (entityList: any[]) => {
    
    const parentality = [] as ITreeItemData[];
    
    entityList.forEach((entityData: any) => {
      if (entityData[defaultField]) {
        const spl = (entityData[hierarchyField]).replace(/^\/+/g, '').split('/');
        
        let lastPiece: string;
        
        spl.forEach((piece: string) => {
          const test = getObjectByFieldValue<any>(parentality, 'name', piece).length;
          if (!test) {
            parentality.push({
              name  : piece,
              parent: lastPiece,
              path  : entityData[hierarchyField].replace(/^\/+/g, ''),
              entityData
            });
          }
          
          lastPiece = piece;
        });
      }
    });
    
    return nestTree(parentality);
    
    function nestTree(directories: any, parent?: string) {
      let node: any = [];
      directories
      .filter(function (d: any) {
        return d.parent === parent;
      })
      .forEach(function (d: any) {
        const cd = d;
        cd.child = nestTree(directories, d.name);
        return node.push(cd);
      });
      return node;
    }
  };
  
  const onCheckBoxChange = (item: ITreeListState, checked: boolean) => {
    if (!item.name) return;
    const selection = singleSelect ? onSelectSingle(item, checked) : onSelectMultiple(item, checked);
    
    setSelectedItems(() => selection);
    
    if (onChange) {
      if (timerChange.current) clearTimeout(timerChange.current);
      timerChange.current = setTimeout(() => { onChange(exportValues(selection));}, 100);
    }
  };
  
  // return data as array of items for output
  const exportValues = (itemsSelected: ISelectedItemsState) => {
    return Array.from(itemsSelected.values()).map((item: ITreeListItemState) => {
      return item.item.entityData;
    }).filter(x => x);
  };
  

  
  const onSelectSingle = (item: ITreeListState, checkState: boolean) => {
    // if false, remove from Map
    const selected = new Map();
    if (checkState) {
      selected.set(item.name, {
        name   : item.name,
        item   : item,
        checked: checkState
      });
    } else {
      selected.delete(item.name);
    }
    
    return selected;
  };
  
  // multiple selection
  const onSelectMultiple = (item: ITreeListState, checkState: boolean) => {
    
    let tmpSelected = new Map([...selectedItems]) as ISelectedItemsState;
    
    if (checkState) {
      tmpSelected.set(item.name, {
        name      : item.name,
        item,
        entityData: item.entityData
      });
    } else {
      tmpSelected.delete(item.name);
    }
    
    return ifChild(tmpSelected, item, checkState);
    
    // Set checked state for item's children, recursing
    function ifChild(rootSelection: ISelectedItemsState, item: ITreeListState, checked: boolean) {
      let tmpSelected = new Map([...rootSelection]) as ISelectedItemsState;
      if (item && item?.child?.length) {
        item.child.forEach((newItem: ITreeListState) => {
          if (checked) {
            // add each child to selected
            tmpSelected.set(newItem.name, {
              name      : newItem.name,
              item      : newItem,
              entityData: newItem
            });
          } else {
            // or remove it
            tmpSelected.delete(newItem.name);
          }
          if (newItem?.child?.length) {
            // and recurse
            tmpSelected = ifChild(tmpSelected, newItem, checked);
          }
        });
      }
      
      return tmpSelected;
    }
  };
 
  
  const getCheckState = (item: ITreeListState) => {
    if (!item) return;
    // if children and all children are checked, then check
    if (item.child?.length) {
      return item?.child.every((it: ITreeListState) => {
        return Boolean(selectedItems.has(it.name));
      });
    }
    
    return Boolean(selectedItems.get(item.name)); //
  };
  
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;
 
    if (searchString && searchString.length !== 0) {
      if (timerSearchField.current) {
        window.clearTimeout(timerSearchField.current);
      }
      timerSearchField.current = window.setTimeout(() => {preformSearch(searchString);}, 800);
    } else {
      setFilteredEntityList(entityList);
    }
  };
  
  
  const preformSearch = (searchString: string) => {
    const regex = new RegExp(`.*${searchString}.*`, 'gi');
 
    const res = entityList.filter((item) => {
      return item[defaultField].search(regex) === 0;
    });
    
    setFilteredEntityList(res);
  };
  
  const getIndeterminate = (item: ITreeListState | undefined) => {
    if (item?.child?.length) {
      // take all children, verify they are checked all / some
      if (item?.child.every((it: ITreeListState) => {
        return selectedItems.has(it.name);
      })) {
        return false;
      } else {
        return item?.child.some((it: ITreeListState) => {
          return selectedItems.has(it.name);
        });
      }
    }
    return false;
  };
  
  // forge root input value
  const buildInputValue = (selectedItems: ISelectedItemsState, single: boolean | undefined) => {
    if (single) {
      return Array.from(selectedItems)?.[0]?.[1]?.name ?? '';
    } else {
      // entityTextName
      return `${selectedItems.size === 0 ? 'Select' : selectedItems.size} ${entityTextName ?? entityName}`;
    }
  };
  
  const BuildTree = (props: { list: ITreeListState[] }): any => {
    
    const {list} = props;
    
    return list.map((treeListItem: ITreeListState, index: number) => {
      
      const label = buildTreeLabel({item: treeListItem, parentRoot: (index === 0)});
      
      if (treeListItem?.child?.length) {
        return <TreeItem key={`${treeListItem.name}_${index}`}
                         nodeId={`${treeListItem.name}_${index}`}
                         label={label}>
          <BuildTree list={treeListItem?.child}/>
        </TreeItem>;
      } else {
        return <TreeItem key={`${treeListItem.name}_${index}`}
                         nodeId={`${treeListItem.name}_${index}`}
                         label={label}/>;
      }
    });
  };
  
  // set label and listeners on tree item
  const buildTreeLabel = (props: { item: ITreeListState, parentRoot: boolean }): any => {
    const {item} = props;
    
    const chkState = getCheckState(props.item);
    
    return <div className={'flex-h flex-align-middle'} title={item.path}>
      <Node width={1.5}>
        {!(singleSelect && props.item.child?.length) && <StyledCheckbox
            disableRipple
            color={'primary'}
            size={'small'}
            indeterminate={getIndeterminate(props.item)}
            checked={chkState}
            inputProps={{
              style: {
                padding: 0
              }
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {event.stopPropagation();}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              onCheckBoxChange(props.item, event.target.checked);
            }}/>}
      </Node>
      <GridItemFull
        style={{
          overflow    : 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '8px'
        }}>{props.item.name}</GridItemFull>
    </div>;
  };
  
  return mode === 'float' ? <React.Fragment>
    <div style={{maxWidth: '280px',}}>
      <TextField
        onClick={handleInputClick}
        name={'name'}
        fullWidth={true}
        value={buildInputValue(selectedItems, singleSelect)}
        // style={{width: '100%', maxWidth: '200px'}}
        {...registerFormField}
        placeholder={`Pick ${entityName}`}
        InputProps={{
          endAdornment: <div style={{marginRight: '0.5rem'}}><AppIcon display={'block'} color={'#00B5E2'} fontSize={'tiny'} rotate={open ? 180 : 0} icon={'ChevronDownIcon'}/></div>
        }} />
    </div>
    <PopperContainer
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      data-cy={'entity-tree'}
      modifiers={[{
        name   : 'preventOverflow',
        enabled: true,
        options: {
          altAxis     : true,
          altBoundary : true,
          tether      : true,
          rootBoundary: 'document',
          boundariesElement: 'window',
          padding     : 8,
        },
      }]} >
      <ClickAwayListener onClickAway={handleClose}>
        <InnerPopper>
          <div style={{height: '100%', overflow: 'auto'}}>
            <Node padding={[1, 0]}>
              <TextField
                inputRef={inputSearchField}
                autoFocus
                fullWidth={true}
                type={'search'}
                placeholder={`Search ${entityName}`}
                onChange={onInputChange}
              />
            </Node>
            <GridItemFull>
              <div style={{overflow: 'auto', maxHeight: '340px'}}>
                <TreeView
                  defaultExpanded={['1']}
                  defaultCollapseIcon={<AppIcon fontSize={'small'} icon={'Minus'}/>}
                  defaultExpandIcon={<AppIcon fontSize={'small'} icon={'Plus'}/>}
                >
                  <BuildTree list={treeList}/>
                </TreeView>
              </div>
            </GridItemFull>
          </div>
        </InnerPopper>
      </ClickAwayListener>
    </PopperContainer>
  </React.Fragment> : <BlockPopper>
           <div style={{height: '100%', overflow: 'hidden'}}>
             <Node padding={[1, 0]}>
               <TextField
                 inputRef={inputSearchField}
                 autoFocus
                 fullWidth={true}
                 type={'search'}
                 placeholder={`Search ${entityName}`}
                 onChange={onInputChange}
                 error={error}
                 /*startAdornment={<InputAdornment position="start"><AppIcon icon={'SearchIcon'}
                  fontSize={'small'}/></InputAdornment>}*/
               />
             </Node>
             <GridItemFull>
               <div style={{overflow: 'auto'}}>
                 <TreeView
                   defaultExpanded={['1']}
                   defaultCollapseIcon={<AppIcon fontSize={'small'} icon={'Minus'}/>}
                   defaultExpandIcon={<AppIcon fontSize={'small'} icon={'Plus'}/>}
                   /// selected={selected}
                 >
                   <BuildTree list={treeList}/>
                 </TreeView>
               </div>
             </GridItemFull>
           </div>
         </BlockPopper>;
};

const PopperContainer = styledOld(Popper)({
  margin   : '1rem',
  maxHeight: 'calc(100% - 2rem)',
  zIndex   : 1000,
  overflow : 'hidden',
});

const InnerPopper = styledOld(Elem).attrs({padding: [1, 1.5]})({
  border         : '1px solid rgba(27,31,35,.15)',
  boxShadow      : '0px 0px 6px rgba(196, 211, 241, 0.85)',
  borderRadius   : 20,
  backgroundColor: 'white',
  maxHeight      : '100%',
  overflow       : 'auto',
});

const BlockPopper = styled('div')((props: { theme: Theme }) => ({
  padding        : '1rem',
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  overflow       : 'hidden',
}));