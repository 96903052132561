export type BackendErrorType = {
  response: {
    data: {
      error: {
        debugTrace: string,
        level: 'error' | string,
        message: string,
        messageId: 'genericError' | string
        parameters: string,
        reason: 'INTERNAL_ERROR' | string,
        url: string,
      }
    }
  }
}

export class BackendError {
  
  private readonly _error: BackendErrorType['response']['data']['error'];
  private readonly _message: string;
  
  constructor(backendError: BackendErrorType) {
    this._error   = backendError.response?.data?.error ?? 'Backend error';
    this._message = backendError.response?.data?.error?.message ?? 'Backend error message';
  }
  
  get error(): BackendErrorType['response']['data']['error'] {
    return this._error;
  }
  
  get message(): string {
    return this._message;
  }
}