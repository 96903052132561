
type GridProps = {
    children: React.ReactNode;
    templateRows?: string;
    templateColumns?: string;
    templateAreas?: string;
    gap?: number;
    rowGap?: number;
    columnGap?: number;
    justifyItems?: 'start' | 'end' | 'center' | 'stretch';
    alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
    justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
    alignContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
    style?: React.CSSProperties;

}

const Grid = (props: GridProps, ...otherProps:any) => {
const {children, templateRows, templateColumns, templateAreas, gap, rowGap, columnGap, justifyItems, alignItems, justifyContent, alignContent, style} = props; 
return <div style={{
        display: 'grid',
        gridTemplateRows: templateRows,
        gridTemplateColumns: templateColumns,
        gridTemplateAreas: templateAreas,
        gap: gap + 'px',
        rowGap: rowGap + 'px',
        columnGap: columnGap +'px',
        justifyItems: justifyItems,
        alignItems: alignItems,
        justifyContent: justifyContent,
        alignContent: alignContent,
        ...style
    }}
    {...otherProps}
>
    {children}
</div>

}
export default Grid;