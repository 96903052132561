type TMoney = {
  currency?: string;
  maxDigits?: number;
  locale?: string;
  hideCurrency?: boolean;
};

export const moneyFormatter = (value: number, options: TMoney) => {
  const { currency, maxDigits, locale, hideCurrency } = options;
  const currentLocale = locale ?? "en-US";
  const moneyFormatConfig = {
    minimumFractionDigits: maxDigits ?? 2,
    maximumFractionDigits: maxDigits ?? 2,
    ...(!hideCurrency && { style: "currency", currency: currency ?? "USD" }),
  };

  const moneyFormatter = new Intl.NumberFormat(currentLocale, moneyFormatConfig);
  return moneyFormatter.format(value);
};

export const getCurrencySymbol = (currency: string) => {
  if (currency === "AUD") return "A$";
  if (currency === "CAD") return "C$";
  if (currency === "EUR") return "€";
  if (currency === "GBP") return "£";
  return "$";
};
