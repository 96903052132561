import {AUTHUser, IUserPermissions} from "application/utils/AuthUser";

export enum EPermission {
    EVADMIN = "EVADMIN",
    EVUSER = "EVUSER",
    COMPANYADMIN = "COMPANYADMIN",
    COMPANYUSER = "COMPANYUSER",
    BRANDADMIN = "BRANDADMIN",
    BRANDCREATOR = "BRANDCREATOR",
    BRANDVIEWER = "BRANDVIEWER",
    COMPANYSUPERADMIN = "ACCOUNT_SUPERADMIN",
}

export type TPermission =
    | EPermission.EVADMIN
    | EPermission.EVUSER
    | EPermission.COMPANYADMIN
    | EPermission.COMPANYUSER
    | EPermission.BRANDADMIN
    | EPermission.BRANDCREATOR
    | EPermission.BRANDVIEWER | EPermission.COMPANYSUPERADMIN;
;

/** @deprecated use EPermission instead */
export enum EUserGod {
    SUPERADMIN = "SUPERADMIN", // is Evorra ...
    EVORRAUSER = "EVORRAUSER", // is Evorra ...
}

export enum EUserSelf {
    accountId = "accountId",
    userId = "userId",
}

export enum EUserGrade {
    ADMINISTRATOR = "ADMINISTRATOR",
    COMPANYSUPERADMIN = "ACCOUNT_SUPERADMIN",
    CREATOR = "CREATOR",
    CONTRIBUTOR = "CONTRIBUTOR", // renders to viewer
    // from Authorities Enum // seems not to be to use // do not use
    VIEWER = "VIEWER",
}

// roles to words, should use i18n ?
export const roleName: Record<EUserGrade, string> = {
    ADMINISTRATOR: "Administrator",
    ACCOUNT_SUPERADMIN: "Super Admin",
    CREATOR: "Creator",
    CONTRIBUTOR: "Viewer",
    VIEWER: "", // deprecated
};

// Use for displaying
export const accountTypeName: Record<EUserGrade, string> = {
    ADMINISTRATOR: "Administrator",
    ACCOUNT_SUPERADMIN: "Super Admin",
    CREATOR: "", //not used
    CONTRIBUTOR: "User", // not used
    VIEWER: "", // not used
};

export enum EUserRole {
    DATA_CLIENT = "DATA_CLIENT",
    DATA_PROVIDER = "DATA_PROVIDER",
}

export enum EUserAccountType {
    COMPANY = "COMPANY",
    BRAND = "BRAND",
}

export type GradeAndGod = typeof EUserGrade | typeof EUserGod;

export type IUserGradeOptions = EPermission | "*" | undefined;
export type IUserRoleOptions = EUserRole | EUserRole[] | "*";

export type IAccessOption = {
    grade?: IUserGradeOptions | IUserGradeOptions[];
    role?: IUserRoleOptions;
    self?: EUserSelf;
    userAccountType?: EUserAccountType.COMPANY | EUserAccountType.BRAND;
    companyGrade?: IUserGradeOptions;
    brandGrade?: IUserGradeOptions;
    brandLevel?: boolean;
};

export type IAccessOptions = IAccessOption[];

export type TAccessObjMatch = {
    accountId?: number;
    userId?: number;
    userAccountType?: EUserAccountType.COMPANY | EUserAccountType.BRAND | "*" | undefined;
};

// for components
export interface IGranterProps {
    grade?: EUserGrade;
    role: EUserRole;
}

class granter {
    // granters : old several granters, essentially migration and legacy purpose
    public granters!: IAccessOptions;
    private user!: IUserPermissions;
    private objMatch!: TAccessObjMatch;
    private hasDebug!: boolean;
    private debugData: {
        user: IUserPermissions;
        self: any;
        grade: any;
        role: any;
        userAccountType: any;
        success: boolean
    } = {} as any;

    constructor(args: IAccessOptions, objMatch?: TAccessObjMatch) {
        if (objMatch) this.objMatch = objMatch;

        if (!Array.isArray(args)) {
        } else {
            this.granters = args;
        }
    }

    /** accepts only array of object declarations
     * objMatch contains sourceAccount | sourceUser | ... */
    static grantAccesses(args: IAccessOptions, objMatch?: TAccessObjMatch) {
        return new granter(args, objMatch);
    }

    // check if the user array contains
    // at least one of the values provided
    // the function works on grades and roles
    containsSome(userGrantKey: any | any[], granterKey: any | any[]) {
        let result: boolean = true; // the test is positive if the list of expected values is empty
        if (granterKey) {
            const actual_ = Array.isArray(userGrantKey) ? userGrantKey : [userGrantKey];
            const expected_ = Array.isArray(granterKey) ? granterKey : [granterKey];
            result = expected_.some((v) => v === "*" || actual_.includes(v));
        }

        return result;
    }

    // step tu register user
    toUser(user: IUserPermissions) {
        this.user = user;
        return this.performGrant();
    }

    public debug(dbg: boolean) {
        this.hasDebug = dbg;
        return this;
    }

    public with(objMatch: TAccessObjMatch) {
        this.objMatch = objMatch;
        return this;
    }

    // check the user privilege
    // verify that at least one permission is matching
    // meaning the user has one grade and one role matching
    // if the permission restricts the scope to the user account or id
    // then check if the context is matching the user account or id
    private performGrant() {
        const granters_ = Array.isArray(this.granters) ? this.granters : [this.granters];
        return granters_.some((grant) => {
            const passes: boolean[] = [];
            let grade;
            if (grant.brandLevel) {
                if (this.user.accountGrade === EPermission.COMPANYADMIN) {
                    grade = EPermission.BRANDADMIN;
                } else {
                    grade = this.user.brandGrades.find((brandGrade) => brandGrade.brandId === AUTHUser.currentBrandId)?.brandPermission;
                }
                if (this.hasDebug) {

                    console.log(grade, grant);
                }
                passes.push(this.containsSome(grade, grant?.grade));
            } else {
                passes.push(this.containsSome(this.user?.accountGrade, grant?.grade));
            }
            passes.push(this.containsSome(this.user?.accountRole, grant?.role));
            passes.push(this.containsSome(this.user?.userAccountType, grant?.userAccountType));
            passes.push(this.containsSome(this.user?.accountGrade, grant?.companyGrade));

            if (grant?.self) {
                //if grant.self is account id, try to find the id in account brand grade
                // if this.objMatch declaration in source or target
                passes.push(this.objMatch?.[grant.self] === this.user?.[grant.self]);
                passes.push(this.objMatch?.[grant.self] !== undefined);
                /* do : SUPERADMIN take precedence over self  */
                if (this.hasDebug) {
                    console.log("objMatch", this.objMatch);
                    console.log('grant self', grant.self)
                    console.log("this.user", this.user);
                    this.debugData.self = this.objMatch?.[grant.self] === this.user?.[grant.self];
                }
            }

            if (this.hasDebug) {
                this.debugData.user = this.user;
                this.debugData.grade = grade ? this.containsSome(grade, grant?.grade) : this.containsSome(this.user?.accountGrade, grant?.grade);
                this.debugData.role = this.containsSome(this.user?.accountRole, grant?.role);
                this.debugData.userAccountType = this.containsSome(this.user?.userAccountType, grant?.userAccountType);

                this.debugData.success = passes.every((passe) => passe);
                console.log("------------------------");
                console.log(AUTHUser);
                console.log("BRAND ID", AUTHUser.currentBrandId);
                console.log(granters_);
                console.log(this.debugData);
                console.log(grade ? grade : this.user?.accountGrade);
                console.log("**********************");
            }

            return passes.every((passe) => passe);
        });
    }
}

//
export {granter};
