import * as yup from 'yup';


export const dataPremiumValidationSchema = yup.object({
  dataPremium: yup
    .number()
    .integer("The value should be an integer")
    .min(-100, 'Minimum value is -100')
    .max(1000, 'Maximum value is 1000')
    .required('The value is required'),
});