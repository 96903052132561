import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import styled from 'styled-components/macro';


export const useStylesDetails = makeStyles(
  (theme: Theme) => {
    return {
      root: {},
      logoBlock: {
        background: 'linear-gradient(180deg, #4262FF 19.27%, #37DEF9 72.92%)',
        padding: '1px',
        borderRadius: '50%',
        margin: '10px',
        width: 'fit-content',
        height: 'fit-content',
      },
      logo: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 3px rgba(222, 222, 222, 0.85)',
        color: '#3B4559',
        fontSize: '13px',
        borderTop: '1px transparent solid',
        width: '30px',
        height: '30px',
      },
    };
  });

/** @deprecated  use templates/InfoBlockOld */
export const InfoBlockOld            = styled.div`
  background: #F1F3F8;
  border-radius: 20px;
  width: 100%;
  //min-height: 210px;
  padding: 30px;
  margin-bottom: 20px;
  display: grid;
`;


