import { accessList } from "application/access/access";
import apiRequest from "application/entities/api/interceptors";
import { PageId } from "application/pages/pages.config";
import { ActionButtons } from "components/controls/ActionButtons";
import { MainFrame } from "components/templates/MainFrame";
import i18n from "i18n.config";
import { url } from "inspector";
import React from "react";
import { ContentBlock } from "styles/styles";
import { devApi } from "_configuration/api.config";
import {actions} from '../../application/actions/actions';
import { useAdminPage } from "components/hooks/useAdminPage";

export const BusinessCockpit = <T, >(initData: T) => {
  const businessCockpit = `${devApi}/businessCockpit`;
  const [url, setUrl] = React.useState();
  useAdminPage()
  React.useEffect(() => {
    apiRequest.get(businessCockpit).then(r => setUrl(r.data));
  }, []);
  
    actions.report.changeCockpit= {
        title: i18n.t("globalCockpit.reportsLinks.globalCockpit"),
        variant: "contained",
        pageId: PageId.globalCockpit,
        accesses: accessList.cockpit.report,
      }
    const headerButtons = <ActionButtons actions={actions.report} vars={{}}/>;
  
    return (
      <MainFrame frameId={'business-cockpit'} headerProps={{headerButtons}}>
        <ContentBlock>
          <div style={{margin:'0px', height:'calc(100%)'}}>
            
              <iframe
                width="100%"
                height="98%"
                frameBorder="0"
                src={url}>
              </iframe>
            
          </div>
        </ContentBlock>
      </MainFrame>
    );
  };