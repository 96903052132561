import {Box, styled} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {SlideBox, SlideBoxListType} from '../../controls/SlideBox';

interface ITourProps {
  component?: any;
  arg1?: any;
  arg2?: any;
  arg3?: any;
};

type TourActionsProps = {
  open: () => void,
  close: () => void
}

export const Tour = React.forwardRef((props: ITourProps, ref: React.Ref<{ actions: TourActionsProps }>) => {
  
  const {component} = props;
  
  React.useImperativeHandle(ref, () => ({actions}));
  
  const actions = {
    open : () => {},
    close: () => {}
  };
  
  const list: SlideBoxListType[] = [
    {content: 'any'},
    {content: 'other'},
  ];
  
  return <TourContainer>
    <SlideBox list={list}>
      <Box sx={{display: 'flex', alignItems: 'center',}}>
        <SlideBox.ButtonPrevious/>
        <SlideBox.Content/>
        <SlideBox.ButtonNext/>
      </Box>
    </SlideBox>
  </TourContainer>;
});


interface ITourContainerProps {
};
const TourContainer = styled(Box)<ITourContainerProps>((props) => ({
  display:'none',
  position: 'fixed',
  width:'100%',
  height:'100%',
  top: '0'
}));