import React, { FunctionComponent } from "react";
import { dataColumnModelUtils } from "../../application/utils/dataColumnModel.utils";
import { brandAgentColumns } from "../../application/entities/dataColumns/brandAgent.columns";
import { GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { ICompanyUsers } from "../../application/entities/dataTypes/companyUser";
import { GridTable } from "../../components/organisms/GridTable";
import { useCompanyUsersQuery } from "../../components/hooks/data/useCompanyUsersQuery";
import { MenuItem, Select } from "@mui/material";

import _ from "lodash";
import { accountTypeName } from "application/utils/granter.utils";

interface IBrandUserListTableProps {
  brandId: number;
  onRowClick: (params: ICompanyUsers) => void;
  editable?: boolean;
}

export const BrandUserListTable: FunctionComponent<IBrandUserListTableProps> = (props: IBrandUserListTableProps) => {
  const accountUsersQuery = useCompanyUsersQuery(props.brandId);
  const userData: ICompanyUsers[] = accountUsersQuery?.data?.data?._embedded?.companyUsers ?? [];
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<any>(brandAgentColumns);

  brandAgentColumns.role.headerName = "Brand Role";
  let editBrandAgentColumns = _.cloneDeep(brandAgentColumns);

  editBrandAgentColumns.role.valueGetter = (params: GridValueGetterParams<ICompanyUsers>) => {
    return (
      <Select defaultValue={params.row.role.toUpperCase()} sx={{ width: "150px" }} data-cy={"user-role-selector"}>
        <MenuItem data-cy={"administrator-role"} value={"ADMINISTRATOR"}>
          {accountTypeName["ADMINISTRATOR"]}
        </MenuItem>
        {/*{accountType === 'BRAND' ? <MenuItem data-cy={'creator-role'} value={'CREATOR'}>Creator</MenuItem> : null}*/}
        <MenuItem data-cy={"viewer-role"} value={"CONTRIBUTOR"}>
          {accountTypeName["CONTRIBUTOR"]}
        </MenuItem>
      </Select>
    );
  };

  React.useEffect(() => {
    setColumns(editMode ? editBrandAgentColumns : brandAgentColumns);
  }, [editMode]);

  React.useEffect(() => {
    setEditMode(Boolean(props?.editable));
  }, [Boolean(props?.editable)]);

  function changeRoleName(params: any) {
    params.map((element: any) => {
      if (element.role === "ADMINISTRATOR") {
        element.role = "Administrator";
      } else if (element.role === "CONTRIBUTOR") {
        element.role = "Viewer";
      } else if (element.role === "CREATOR") {
        element.role = "Creator";
      }
    });

    return params;
  }

  const cols = dataColumnModelUtils.renderModel(columns, "show") as any;

  return props.brandId ? (
    <GridTable
      data={{
        columns: cols,
        rows: changeRoleName(userData),
      }}
      gridProps={{ onRowClick: (param: GridRowParams<ICompanyUsers>) => props.onRowClick(param.row) }}
    />
  ) : null;
};
