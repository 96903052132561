import { Paper, Box, Typography } from "@mui/material";
import { AppIcon } from "components/ui/AppIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type CreditCardProps = {
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
  selected?: boolean;
  onClick?: () => void;
};
export const CreditCard = (props: CreditCardProps) => {
  const { brand, last4, expMonth, expYear, selected } = props;
  const icon = brand === "visa" ? "Visa" : brand === "mastercard" ? "Mastercard" : brand === "amex" ? "Amex" : undefined;
  return (
    <Box
      sx={{
        border: selected ? "2px solid #00B5E2" : "none",
        borderRadius: "4px",
      }}
    >
      <Paper
        onClick={props.onClick}
        elevation={selected ? 0 : 8}
        sx={{
          width: "190px",
          height: "110px",
          padding: 1,
          background: "linear-gradient(#888, #333)",
          position: "relative",
          cursor: "pointer",
          border: selected ? "2px solid #fff" : "none",
        }}
      >
        {selected && (
          <Box
            sx={{
              position: "absolute",
              right: "8px",
              bottom: "0px",
            }}
          >
            <CheckCircleIcon sx={{ color: "primary.main" }} fontSize="medium" />
          </Box>
        )}
        <Box sx={{ position: "absolute", right: "12px", top: "4px" }}>{icon && <AppIcon icon={icon} fontSize="default" />}</Box>
        <Box sx={{ position: "absolute", left: "8px", top: "15px" }}>
          <AppIcon icon="CaretDown" color="#ececec" fontSize="small" rotate={90} />
        </Box>

        <Box sx={{ position: "absolute", top: "50px", left: "4px" }}>
          <Typography mb={2} pl={2} color="#ececec" variant="body2" fontSize={12} letterSpacing="1px">
            XXXX XXXX XXXX {last4}
          </Typography>
        </Box>
        <Box sx={{ position: "absolute", bottom: "8px", left: "16px" }}>
          <Typography color="#e1e1e1" variant="body2" textTransform="uppercase" fontSize={10}>
            Exp. {expMonth}/{expYear}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
