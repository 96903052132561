import * as React from 'react';
import {GridContent} from '../../components/organisms/Grid';
import {Container, ContentBlock} from 'styles/styles';
import {MainFrame} from '../../components/templates/MainFrame';
import { devApi } from '_configuration/api.config';
import apiRequest from 'application/entities/api/interceptors';
import {useTranslation} from 'react-i18next';
import {useAppToast} from '../../components/live/AppToast';
import {ActionButtons} from '../../components/controls/ActionButtons';
import {actions} from '../../application/actions/actions';
import i18n from 'i18n.config';
import { PageId } from 'application/pages/pages.config';
import { accessList } from 'application/access/access';
import { useAdminPage } from 'components/hooks/useAdminPage';

export const GlobalCockpit = <T, >(initData: T) => {
  const globalCockpit = `${devApi}/globalCockpit`;
  const [url, setUrl] = React.useState();
  useAdminPage()

  React.useEffect(() => {
    apiRequest.get(globalCockpit).then(r => setUrl(r.data));
  }, []);
  
  // the dashboard is displayed if the application is accessed over https and is running on an authorised domain
  // to run locally
  //    install a simple http-server
  //    install a certificate 
  //      brew install mkcert
  //      brew install nss
  //      mkcert -install
  //      mkcert marc.freddy.net localhost 127.0.0.1 ::1
  //      ln -s marc.freddy.net+3.pem cert.pem 
  //      ln -s marc.freddy.net+3-key.pem key.pem
  //    launch the server with ssl activated and redirecting hits to the local react server: http-server --ssl --proxy http://localhost:3000

  actions.report.changeCockpit= {
    title: "Business Cockpit",
    variant: "contained",
    pageId: PageId.businessCockpit,
    accesses: accessList.cockpit.report,
  }

  const headerButtons = <ActionButtons actions={actions.report} vars={{}}/>;

  return (
    <MainFrame frameId={'data-cockpit'} headerProps={{headerButtons}}>
      <ContentBlock>
        <div style={{margin:'0px', height:'calc(100%)'}}>
          
            <iframe
              width="100%"
              height="98%"
              frameBorder="0"
              src={url}>
            </iframe>
          
        </div>
      </ContentBlock>
    </MainFrame>
  );
};
