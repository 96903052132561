import React from 'react';
import {TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useTranslation} from 'react-i18next';
import {IconSet} from '../../../assets/icons';
import { ICountry} from '../../../types';
import {getCountries} from '../zOld/requests';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {DetailsBlockGrid, TextFieldBlock, InputFieldLabel} from '../../../components/templates/InfoBlock';


interface ICompanyAddressProps {
  accountDetailsFormik: any;
  account: Partial<IAccounts> & Partial<IAccounts>;
  setAccount: (arg: any) => void;
}

export const EditAccountAddress: React.FC<ICompanyAddressProps> = ({
                                                                     accountDetailsFormik,
                                                                     account,
                                                                     setAccount,
                                                                   }) => {
  
  
  const [countryList, setCountryList]   = React.useState<ICountry[]>([]);
  const {t}              = useTranslation();
  const [localCountry, setLocalCountry] = React.useState<ICountry>({
    name         : '',
    code         : '',
    continentCode: '',
    continentName: '',
    path         : '',
  });
  
  
  React.useEffect(() => {
    getCountries()
    .then((res) => {
      const {countries} = res.data._embedded;
      countries && setCountryList(countries);
    })
    .catch((err) => {
    });
  }, []);
  
  React.useEffect(() => {
    if (account.country && countryList) {
      const predefinedCountry = countryList.find(item => item.code === account.country);
      if (predefinedCountry) {
        setLocalCountry(predefinedCountry);
      }
    }
  }, [account.country, countryList]);
  
  return <>
    <DetailsBlockGrid data-cy={'user-detail-address'}>
      <div style={{gridColumnStart:'1', gridColumnEnd:'3'}}>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">
            {t('account.accountAddressLabels.companyStreetAddress')}
          </InputFieldLabel>
          <TextFieldBlock>
            <TextField
              sx={{height: '80px'}}
              variant="standard"
              multiline
              fullWidth
              id="streetAddress"
              name="streetAddress"
              value={accountDetailsFormik.values.streetAddress}
              onBlur={accountDetailsFormik.handleBlur}
              onChange={accountDetailsFormik.handleChange}
              error={accountDetailsFormik.touched.streetAddress && Boolean(accountDetailsFormik.errors.streetAddress)}
              helperText={accountDetailsFormik.touched.streetAddress && accountDetailsFormik.errors.streetAddress}
              inputProps={{'data-cy': 'edit-street-address'}}
            />
          </TextFieldBlock>
        </div>
      </div>

      <div>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">
            {t('account.accountAddressLabels.postalCode')}
          </InputFieldLabel>
          <TextFieldBlock>
            <TextField
              fullWidth
              id="postalCode"
              name="postalCode"
              value={accountDetailsFormik.values.postalCode}
              onBlur={accountDetailsFormik.handleBlur}
              onChange={accountDetailsFormik.handleChange}
              error={accountDetailsFormik.touched.postalCode && Boolean(accountDetailsFormik.errors.postalCode)}
              helperText={accountDetailsFormik.touched.postalCode && accountDetailsFormik.errors.postalCode}
              inputProps={{'data-cy': 'edit-post-code'}}
            />
          </TextFieldBlock>
        </div>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">
            {t('account.accountAddressLabels.state')}
          </InputFieldLabel>
          <TextFieldBlock>
            <TextField
              fullWidth
              id="state"
              name="state"
              value={accountDetailsFormik.values.state}
              onBlur={accountDetailsFormik.handleBlur}
              onChange={accountDetailsFormik.handleChange}
              error={accountDetailsFormik.touched.state && Boolean(accountDetailsFormik.errors.state)}
              helperText={accountDetailsFormik.touched.state && accountDetailsFormik.errors.state}
              inputProps={{'data-cy': 'edit-state'}}
            />
          </TextFieldBlock>
        </div>
      </div>

      <div>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">
            {t('account.accountAddressLabels.city')}
          </InputFieldLabel>
          <TextFieldBlock>
            <TextField
              fullWidth
              id="city"
              name="city"
              value={accountDetailsFormik.values.city}
              onBlur={accountDetailsFormik.handleBlur}
              onChange={accountDetailsFormik.handleChange}
              error={accountDetailsFormik.touched.city && Boolean(accountDetailsFormik.errors.city)}
              helperText={accountDetailsFormik.touched.city && accountDetailsFormik.errors.city}
              inputProps={{'data-cy': 'edit-city'}}
            />
          </TextFieldBlock>
        </div>
        <div>
            <InputFieldLabel htmlFor="bootstrap-input">
              {t('account.accountAddressLabels.country')}*
            </InputFieldLabel>
            <TextFieldBlock>
              <Autocomplete
                data-cy={'edit-country'}
                id="country"
                popupIcon={<IconSet.ChevronDownBlue/>}
                clearIcon={<IconSet.Close/>}
                options={countryList}
                getOptionLabel={(option: ICountry) => option.name}
                value={localCountry}
                clearOnBlur={false}
                onChange={(event, newValue) => {
                  setAccount({...account, country: newValue?.code});
                  accountDetailsFormik.setFieldValue('country', newValue?.code);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{...params.InputProps, autoComplete: 'new-password'}}
                    error={accountDetailsFormik.touched.country && Boolean(accountDetailsFormik.errors.country)}
                    helperText={accountDetailsFormik.touched.country && accountDetailsFormik.errors.country}
                  />)}
              />
            </TextFieldBlock>
        </div>
      </div>
     
    </DetailsBlockGrid>
  </>;
};