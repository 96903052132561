import React from "react";
import styledOld from "styled-components/macro";

import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { TIcon } from "assets/icons";
import { IActionButtons } from "components/controls/ActionButtons";
import { ActionMenu } from "components/controls/ActionMenu";
import { ColorPicker } from "components/form/ColorPicker";
import { Columns, Elem, GridItemFullAuto } from "components/ui/AppElements";
import { AppIcon } from "components/ui/AppIcon";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";

// import  Color from "Color"
export interface IAudienceBuilderThumbNewProps {
  id?: any; // TODO mandatory
  icon?: TIcon;
  hideIconGutter?: boolean;
  title?: string;
  subTitle?: string;
  showBadge?: boolean;
  actions?: TAppThumbOptions;
  children?: any;
  gutterColor?: string;
  className?: any;
  draggable?: boolean;
  linkTo?: string;
  refParent?: any;
  isSelected?: boolean;
  linkedTo?: string;
  actionMenuStyle?: "ellipsis" | "fixed";
  onClick?: (id: number) => void;
  onDblClick?: (id: number) => void;
  group?: "BASICS" | "DEMOGRAPHICS" | "FILTERS" | "STRATEGY" | string;
  showPicker?: boolean;
  showItemCount?: number | string;
  entityData?: Record<string, any>;
  onDataChange?: (args: Record<string, any>) => void;
  actionMenu?: IActionButtons;
}

export interface IAppThumbOption {
  icon: TIcon;
  action: (id: number, args?: any) => void; // (event: React.MouseEvent<HTMLElement>) => {},
  text?: string | any;
  helperText?: string;
}

export type TAppThumbOptions = Record<string, IAppThumbOption>; // { [actionKey: string] : { icon: TIcon, action:
// Function }}[]

export const AudienceBuilderThumbNew = (props: IAudienceBuilderThumbNewProps) => {
  const {
    id,
    icon,
    title,
    subTitle,
    children,
    hideIconGutter,
    isSelected,
    onClick,
    onDblClick,
    showPicker,
    entityData,
    onDataChange,
    group,
    gutterColor,
    actionMenu,
  } = props;

  const audienceActions = useAudienceBuilderStore((st) => st.actions);
  const thumbId = React.useRef<HTMLDivElement>(null);

  const handleActive = () => {
    if (group) audienceActions.setActiveFeatureGroupCode(group);

    if (onClick && id) {
      id && onClick(id);
    }
  };

  const handleDblClick = () => {
    onDblClick && onDblClick(id);
  };

  const handleColorChange = (args: { color: string; id?: number }) => {
    if (onDataChange) {
      onDataChange({ color: args.color, id: args.id });
    }
  };

  return (
    <>
      <ThumbContainer
        ref={thumbId}
        onClick={handleActive}
        onDoubleClick={handleDblClick}
        active={isSelected}
        id={props.id}
        className={props.className}
        width={16}
      >
        {!hideIconGutter && (
          <ThumbIconGutter className={"custom-drag-handle"} empty={!Boolean(icon)} gutterColor={gutterColor}>
            {icon && (
              <IconBox>
                <AppIcon display={"block"} fontSize={"tiny"} color={"#FFFFFF"} icon={icon} />
              </IconBox>
            )}
            {showPicker && (
              <IconBox>
                <ColorPicker id={entityData?.id} onChange={handleColorChange} />
              </IconBox>
            )}
          </ThumbIconGutter>
        )}
        <ThumbContentZone>
          <Box
            className={"flex-h flex-main"}
            sx={{
              width: "auto",
              maxWidth: "100%",
              overflow: "hidden",
              minWidth: 0,
            }}
          >
            <Box style={{ flex: 1, minWidth: 0 }}>
              <Columns style={{ width: "auto" }}>
                <Box style={{ overflow: "hidden", minWidth: 0, flex: "1", padding: "0px 0.25rem" }}>
                  {subTitle && (
                    <ThumbSubTitle data-cy-id={entityData?.id} data-cy={"card-audience-id"}>
                      {subTitle}
                    </ThumbSubTitle>
                  )}
                  {title && <ThumbTitle data-cy={"card-audience-title"}>{title}</ThumbTitle>}
                </Box>
                {actionMenu && (
                  <Box sx={{ py: 1, px: 0.5, flex: "0 0 auto" }}>
                    <ActionMenu actions={actionMenu} vars={{ ...entityData, audienceId: id }} />
                  </Box>
                )}
              </Columns>
            </Box>
          </Box>
          <ThumbContent>{children}</ThumbContent>
        </ThumbContentZone>
      </ThumbContainer>
    </>
  );
};

interface IThumbContainerProps {
  isOpen?: boolean;
  selected?: boolean;
  gutterColor?: string;
}

interface IThumbBadgeProps {
  backgroundColor?: string;
}

const ThumbBadge = styledOld(Elem)<IThumbBadgeProps>((props) => ({
  position: "relative",
  overflow: "hidden",
  height: "1rem",
  width: "0.38rem",
  marginLeft: "0.5rem",
  backgroundColor: props.backgroundColor || "chartreuse",
  borderRadius: "100px",
}));

const ThumbContentZone = styledOld(Box)({
  flex: 1,
  display: "flex",
  minWidth: 0,
  // width: 'auto',
  flexDirection: "column",
  position: "relative",
  overflow: "hidden",
  // maxWidth     : '100%',
  height: "100%",
});
const ThumbTitle = styledOld(Box).attrs({ sx: { px: 0.5 } })({
  height: "20px",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "bold",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  minWidth: 0,
  color: "#7587A3",
});
const ThumbSubTitle = styledOld(Box).attrs({ sx: { px: 0.5 } })({
  height: "20px",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  textOverflow: "ellipsis",
  wordBreak: "keep-all",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontWeight: "bold",
});
const ThumbItemCount = styledOld(Box).attrs({ sx: { px: 0.5 } })({
  height: "20px",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  color: "#7587A3",
  fontSize: "10px",
});
const ThumbContent = styledOld(GridItemFullAuto)({
  position: "relative",
  overflow: "auto",
  maxWidth: "100%",
  height: "100%",
  color: "inherit",
  transitionProperty: "*",
  transitionDuration: "0.25s",
});

const ThumbContainer = styled(Columns)((props: { theme: Theme } & IThumbContainerProps) => ({
  borderRadius: "4px",
  backgroundColor: props.theme.palette.background.default,
  overflow: "hidden",
  position: "relative",
  minWidth: "160px",
  maxWidth: "390px",
  boxShadow: props.selected ? "0px 0px 6px rgba(196, 211, 241, 0.85)" : props.theme.skin.boxShadow,
  "&:hover": {
    boxShadow: props.theme.skin.boxShadowHover,
  },
}));

interface IThumbIconGutterProps {
  gutterColor?: string;
  empty?: boolean;
}

const ThumbIconGutter = styledOld(Box)<IThumbIconGutterProps>((props) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: props?.gutterColor ? props.gutterColor : "#8FB6BC",
  justifyContent: "space-between",
  padding: props.empty ? "0.25rem" : 0,
}));

const IconBox = styledOld(Box)<IThumbIconGutterProps>((props) => ({
  padding: "0.25rem",
  marginBottom: "0rem",
  "&:last-child": {
    marginBottom: 0,
  },
}));
