import { CountriesWithStatesDataItem, TreeCountriesWithLocationItem } from "./types";

/**
 * Transforms CountriesWithStatesData[] to TreeCountriesWithLocationItem[]
 * @param {CountriesWithStatesDataItem[]} countriesWithStatesData
 * @returns {TreeCountriesWithLocationItem[]}
 */
export const generateTree = (countriesWithStatesData: CountriesWithStatesDataItem[]) => {
  // sort path alphabetically
  const sortedPaths = countriesWithStatesData.sort((a, b) => (a.path > b.path ? 1 : -1));

  const tree: TreeCountriesWithLocationItem[] = [];

  sortedPaths.forEach((item, itemIdx) => {
    const pathItems = item.path.split("/");
    let currentLevel = tree;

    pathItems.forEach((pathItem, pathItemIdx) => {
      const path = pathItem;
      const existingPath = currentLevel.find((treeItem) => treeItem.name === path);
      if (existingPath) {
        currentLevel = existingPath.children;
      } else {
        const newPart: TreeCountriesWithLocationItem = {
          name: pathItem,
          code: item.code,
          path: pathItems.slice(0, pathItemIdx + 1).join("/"),
          featureValue: sortedPaths[itemIdx],
          children: [],
          level: pathItemIdx + 1,
          parentPath: pathItems.slice(0, pathItemIdx).join("/"),
        };
        currentLevel.push(newPart);
        currentLevel = newPart.children;
      }
    });
  });

  return tree;
};

/**
 * Get the path to expand after search
 * @param {TreeCountriesWithLocationItem[]} search
 * @returns {string[]} a list of paths to expand
 */

export const getPathExpandedAfterSearch = (search: TreeCountriesWithLocationItem[]) => {
  let result: string[] = [];
  search.forEach((item) => {
    if (item.children.length > 0) {
      result.push(item.path);
      result = result.concat(getPathExpandedAfterSearch(item.children));
    }
  });

  return result;
};
