import {Box, Button, InputAdornment, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {accountTypeName, EUserGrade, roleName} from "application/utils/granter.utils";
import React, {FunctionComponent, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {
    FieldZone,
    FormBlock,
    InputFieldLabel,
    ShadowBox,
    TextFieldBlock
} from "../../../components/templates/InfoBlock";
import {objectApi} from "../../../application/entities/dataApi";
import {useAppToast} from "../../../components/live/AppToast";
import {queryClientRefetcher} from "../../../application/utils/useQuery.utils";
import {BackendError} from "../../../types/backendError";


interface IInviteUserProps {
    brandId: number;
    onUserInvited: () => void;
    onClose?: () => void;
}

export const InviteBrandUser: FunctionComponent<IInviteUserProps> = (props: IInviteUserProps) => {
    const accountApi = new objectApi.accounts();
    const appToast = useAppToast();

    const [invited, setInvited] = React.useState<any[]>([]);

    const {
        handleSubmit,
        control,
        register,
        watch,
        setValue,
        formState: {isValid, errors},
    } = useForm({mode: "all"});

    React.useEffect(() => {
        register("role");
        register("approvalRequired", {required: false})
        register("approvalPriceLimit", {required: true, value: 0, min: 0});
    }, [props?.brandId]);


    const role = watch("role")
    const approvalRequired = watch("approvalRequired")
    console.log(role)

    useEffect(() => {
        if (role !== "CREATOR") {
            setValue("approvalRequired", false)
        }
    }, [role]);

    async function onSubmit(dta: any) {
        console.log(dta)


        accountApi
            .inviteBrandUser(props.brandId, {
                email: dta.email,

                userBrandPermission: {
                    role: dta.role,
                    approvalRequired: dta.approvalRequired,
                    approvalPriceLimit: Number(dta.approvalPriceLimit) ?? 0,
                    accountId: props.brandId,
                },
            })
            .then(() => {
                queryClientRefetcher(["accounts", "companyUsers"]);
                appToast.success({
                    message: "The invitation was sent",
                });
                setInvited([...invited, {email: dta.email, companyRole: dta.role}]);
                if (Boolean(props.onUserInvited)) props.onUserInvited();
                if (props.onClose) props?.onClose();
            })
            .catch((e: BackendError) => {
                appToast.error({
                    message: e?.message ?? "An error occurred",
                });
            });
    }

    return (
        <FormBlock style={{height: "auto", width: "100%"}} noBorder title={`Invite users`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ShadowBox className={"pad"} style={{margin: '1rem'}}>
                    <div className={"pad"}>
                        <div className={"flex-h gap-small"}>
                            <div className={"flex-main"}>
                                <FieldZone title={"Email"}>
                                    <TextField
                                        {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                                        fullWidth
                                        error={errors?.email}
                                        data-cy={"invitation-email"}
                                    />
                                </FieldZone>
                            </div>
                            <div>
                                <InputFieldLabel style={{textTransform: "capitalize"}}>Brand Role</InputFieldLabel>
                                <TextFieldBlock>
                                    <Controller
                                        name="role"
                                        rules={{required: "this is required"}}
                                        control={control}
                                        defaultValue={""}
                                        render={({field}) => (
                                            <Select {...field} sx={{width: "150px"}} data-cy={"invitation-role"}>
                                                <MenuItem
                                                    value={EUserGrade.ADMINISTRATOR}>{accountTypeName["ADMINISTRATOR"]}</MenuItem>
                                                <MenuItem value={EUserGrade.CREATOR}>
                                                    {roleName["CREATOR"]}
                                                </MenuItem>
                                                <MenuItem
                                                    value={EUserGrade.CONTRIBUTOR}>{roleName["CONTRIBUTOR"]}</MenuItem>


                                            </Select>
                                        )}
                                    />
                                </TextFieldBlock>
                            </div>
                        </div>

                        {role === "CREATOR" && <>
                            <Box mb={3}>
                                <Typography mb={2} variant={"h6"}>Audience Approval</Typography>
                                <Box sx={{display: "flex", alignItem: 'center', gap: 4}}>

                                    <Box>
                                        <Typography mb={1}>Approval required</Typography>
                                        <Controller
                                            name="approvalRequired"
                                            control={control}
                                            render={({field}) => (
                                                <Switch                  {...field}/>
                                            )}
                                        />
                                    </Box>

                                    {approvalRequired && <Box>
                                        <Typography mb={1}>Max. Audience Budget without Approval</Typography>
                                        <Controller
                                            name="approvalPriceLimit"
                                            control={control}
                                            render={({field}) => (
                                                <TextField {...field}
                                                           sx={{
                                                               width: "80px",
                                                               fontSize: "10x",
                                                               borderRadius: 0,
                                                               textAlign: "center"
                                                           }}
                                                           inputProps={{style: {textAlign: "left"}}}
                                                           error={errors?.approvalPriceLimit}
                                                           type="number"

                                                           InputProps={{
                                                               sx: {textAlign: "center", minWidth: "120px"},
                                                               startAdornment: (
                                                                   <InputAdornment position="start"
                                                                                   sx={{pl: "10px", mr: "0"}}>
                                                                       $
                                                                   </InputAdornment>
                                                               ),
                                                           }}

                                                           data-cy={"max-budget"}
                                                />


                                            )}
                                        />
                                    </Box>}
                                </Box>
                            </Box>
                        </>


                        }

                        <div className={"flex-h flex-align-middle"}>
                            <Button
                                data-cy="cancel-invite-btn"
                                onClick={() => {
                                    if (props.onClose) props?.onClose();
                                }}
                                color={"error"}
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                            <div className={"flex-main"}></div>
                            <Button data-cy="invite-btn" disabled={!isValid} type={"submit"} variant={"outlined"}>
                                Invite
                            </Button>
                        </div>
                    </div>
                </ShadowBox>
                {Boolean(invited?.length) && (
                    <div className={"pad-2"}>
                        <FormBlock noBorder title={`Invitations`}>
                            {invited.map((user: { email: string; companyRole: string }) => (
                                <div className={"flex-h flex-align-middle gap-small border-b"}>
                                    <div>{user.email}</div>
                                    <div>{user.companyRole}</div>
                                </div>
                            ))}
                        </FormBlock>
                    </div>
                )}
            </form>
        </FormBlock>
    );
};
