import { EllipseOff, EllipseOn } from "assets/icons";
import React                     from "react";
import styled from "styled-components";

interface Props {
  steps: number;
  activeStep: number;
  stepOnIcon?: any;
  stepOffIcon?: any;
  onStepClick?: (step:number)=> void
}

export const AppStepper = (props: Props) => {

  const {onStepClick} = props

  return (
    <StepperBox >
      {[...Array(props.steps)].map((value: number, index: number) => {
        return <StepBox key={index}>
          {index === props.activeStep ? <EllipseOn/> : <EllipseOff style={{cursor:'pointer'}}  onClick={()=>{if(onStepClick) onStepClick(index)}} /> }
        </StepBox>;
      })}
    </StepperBox> 
  );
};

const StepperBox = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
`;
const StepBox = styled.div`
  padding: 0 0.25rem;
`;
