import {PageId} from "../../../application/pages/pages.config";
import {MainFrame} from "../../../components/templates/MainFrame";
import {useParams} from "react-router-dom";
import {useAccountTracker} from "../../Agencies/useAccountTracker";
import React from "react";
import {useCompanyBrandRoles} from "../../../components/hooks/data/useCompanyBrandRoles";
import {GridTable} from "../../../components/organisms/GridTable";
import {dataColumnModelUtils} from "../../../application/utils/dataColumnModel.utils";
import {GridRowParams} from "@mui/x-data-grid";
import {ICompanyUsers} from "../../../application/entities/dataTypes/companyUser";
import {IUserAccountAssociation} from "../../../application/entities/dataTypes/brandRoles";
import {Pager} from "../../../components/controls/Pager";
import _ from "lodash";
import {companyBrandRolesColumns} from "../../../application/entities/dataColumns/companyBrandRoles.columns";
import {Button, MenuItem, Select, styled, Switch, Typography} from "@mui/material";
import {accountTypeName, EUserGrade, roleName} from "../../../application/utils/granter.utils";
import {Theme} from "@mui/material/styles";
import {InviteBrandUser} from "./inviteBrandUser";
import {UserBrandPermissionForm} from "./userBrandPermissionForm";

export const BrandUserManagement = () => {
    const uriParams = useParams<{ companyId: string, brandId: string }>() as { companyId: string, brandId: string };

    const accountId = uriParams?.companyId;
    const brandId = uriParams?.brandId;
    useAccountTracker(+brandId);

    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(50);
    const [sortDir, setSortDir] = React.useState<"asc" | "desc">("desc");
    const [sortBy, setSortBy] = React.useState<string>("id");
    const [filterRole, setFilterRole] = React.useState<{
        withCompanyAdmin?: boolean;
        brandRole?: EUserGrade | undefined;
    }>({
        withCompanyAdmin: false,
    });
    const [inviteUser, setInviteUser] = React.useState<boolean>(false);
    const [includeAdmins, setIncludeAdmins] = React.useState<boolean>(false);
    const [disableSelectCompanyAdmin, setDisableSelectCompanyAdmin] = React.useState<boolean>(true);
    const [activeUser, setActiveUser] = React.useState<ICompanyUsers>({} as ICompanyUsers);

    const brandUsersQuery = useCompanyBrandRoles(
        Number(accountId),
        Number(brandId),
        {
            params: filterRole,
            pageSize,
            pageNumber,
            sortDir,
            sortBy,
        },
        {enabled: Boolean(brandId)}
    );


    const brandUsers = brandUsersQuery?.data?.data?.userAccountAssociations ?? [];

    const brandRolesColumns = _.cloneDeep(companyBrandRolesColumns);
    brandRolesColumns.accountType.headerName = "Company Role"
    brandRolesColumns.role.headerName = "Brand Role"


    function handleRowClick(param: GridRowParams<ICompanyUsers | IUserAccountAssociation>) {
        // @ts-ignore
        setActiveUser(param.row.user ? param.row.user : param.row);
        setInviteUser(false);
    }

    function handlePageSizeChange(size: number) {
        setPageNumber(0);
        setPageSize(size);
    }

    function handlePageChange(size: number) {
        setPageNumber(size);
    }

    function changeAdminFilter() {
        setIncludeAdmins(!includeAdmins);
        setFilterRole(prevState => ({
            ...prevState,
            withCompanyAdmin: !includeAdmins
        }))
    }

    return <>

        <MainFrame
            frameId={PageId.brandUserManagement}
            scrollable={true}
            loading={brandUsersQuery?.isLoading}
            checkPageAccess={true}
            grantWith={{accountId: Number(brandId)}}
            breadCrumbProps={{params: {companyId: accountId, brandId: brandId}}}
        >
            <Container className="flex-h h-full overflow-hidden w-full" style={{paddingTop: "1rem", gap: '4rem'}}>
                <div className=" flex-h gap-medium overflow-hidden h-full" style={{minWidth: "500px"}}>
                    <div className={"flex-v"}
                         style={{height: "100%", overflow: "hidden", marginLeft: "2rem", width: "100%"}}>
                        <div style={{borderColor: "#f0f2f6"}}
                             className={"flex-h flex-align-middle border-b"}>
                            <Typography className={"flex-main pad-tb-2"} variant="h5">
                                User Management
                            </Typography>
                            <div className={"pad-1"}>

                                <Button
                                    onClick={() => {
                                        setInviteUser(!inviteUser);
                                    }}
                                    variant={"outlined"}
                                    data-cy={"invite-user-button"}
                                >
                                    Invite users
                                </Button>
                            </div>
                        </div>


                        <div className={"pad flex-h gap-medium flex-align-middle w-full"}>

                            <div style={{width: "95px"}}>
                                <Typography color={"secondary"}>Filter by</Typography>
                            </div>
                            <div>
                                <Select
                                    data-cy={"select_account_type"}

                                    displayEmpty
                                    onChange={(e) => setFilterRole(prevState => ({
                                        ...prevState,
                                        brandRole: e.target.value === "All" ? undefined : e.target.value as EUserGrade
                                    }))}

                                    value={filterRole?.['brandRole'] ?? "All"}
                                    sx={{width: "150px"}}
                                >
                                    <MenuItem value="All">All User Roles</MenuItem>
                                    <MenuItem
                                        value={EUserGrade.ADMINISTRATOR}>{accountTypeName["ADMINISTRATOR"]}</MenuItem>
                                    <MenuItem value={EUserGrade.CREATOR}>
                                        {roleName["CREATOR"]}
                                    </MenuItem>
                                    <MenuItem
                                        value={EUserGrade.CONTRIBUTOR}>{roleName["CONTRIBUTOR"]}</MenuItem>


                                </Select>
                            </div>

                            <div
                                className={"flex-h flex-align-middle gap-small"}
                                style={{justifyContent: "flex-end", width: '100%'}}>
                                Show Company Admins
                                <Switch
                                    data-cy="admin_toggle"

                                    onChange={() => {
                                        changeAdminFilter();
                                    }}
                                    checked={includeAdmins}
                                />
                            </div>

                        </div>


                        <div className={"flex-v flex-main"}
                             style={{height: "100%", overflow: "hidden", position: "relative", width: "100%"}}>
                            <div className={"flex-main"}>


                                <GridTable
                                    noRowsText={'errorMessage'}
                                    dataCy="users-list"
                                    styleClass={"naked"}
                                    loading={brandUsersQuery?.isLoading}
                                    data={{
                                        columns: dataColumnModelUtils.renderModel(brandRolesColumns) as any,
                                        rows: brandUsers as any,
                                    }}
                                    gridProps={{
                                        getRowId: (row: any) => row?.id ?? row?.user?.id,
                                        onRowClick: (param: GridRowParams<ICompanyUsers | IUserAccountAssociation>) => handleRowClick(param),

                                    }}
                                />

                            </div>
                            <div>
                                <Pager
                                    onPageSizeChange={handlePageSizeChange}
                                    onPageChange={handlePageChange}
                                    activePage={pageNumber}
                                    maxCount={brandUsers?.length}
                                    pageSize={pageSize}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div style={{overflow: "hidden", height: "100%"}}>
                    <div style={{overflow: "auto", height: "100%", minWidth: "550px"}}>
                        {Boolean(inviteUser) && (
                            <InviteBrandUser
                                brandId={Number(brandId)}
                                onClose={() => setInviteUser(false)}
                                onUserInvited={() => {
                                    brandUsersQuery.refetch();
                                    setInviteUser(false);
                                }}
                            />
                        )}
                        {!Boolean(inviteUser) && Boolean(activeUser.id) && (
                            <div>

                                <UserBrandPermissionForm
                                    brandId={Number(brandId)}
                                    userId={activeUser.id}
                                    onClose={() => setActiveUser({} as ICompanyUsers)}
                                    onUserUpdate={() => brandUsersQuery.refetch()}/>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </MainFrame>

    </>
}


const Container = styled("div")((props: { theme: Theme }) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
}));