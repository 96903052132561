import styled from 'styled-components/macro';


export const ContentBlock = styled.div`
  height: 100%;
  overflow: auto;
  position:relative;
`;

export const Container = styled.div`
  height:100%;
  display: flex;
  justify-content: center;
`;