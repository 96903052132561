import { Theme, styled } from "@mui/material";
import { GridMiddle } from "components/ui/AppElements";

export const SGut                   = styled('div')((props: { theme: Theme }) => ({
    display: 'flex', gap: '16px', flexDirection: 'column'
  }));
  
  export const SCardTitle = styled('div')((props: { theme: Theme }) => ({
    fontWeight: 'bolder',
    color     : '#7587A3',
    fontSize  : '18px'
  }));
  
  export const SCardTitleNumberBar = styled(GridMiddle)((props: { theme: Theme }) => ({
    color       : '#7587A3',
    marginBottom: '8px',
    gap         : '8px'
  }));
  export const SCardTitleNumber    = styled('div')((props: { theme: Theme }) => ({
    color     : '#7587A3',
    fontSize  : '12px',
    fontWeight: '400',
    // marginBottom: '8px',
  }));
  
  export const SCardNumber = styled('span')((props: { theme: Theme }) => ({
    fontWeight: 'bold',
    color     : '#5B5B5B',
    fontSize  : '18px'
  }));
  
  export const SCard       = styled('div')((props: { theme: Theme }) => ({
    padding: '16px',
    //minWidth       : '297px',
    boxShadow      : props.theme.skin.boxShadow,
    backgroundColor: props.theme.palette.background.default,
    borderRadius   : '12px',
    border         : '1px solid ' + props.theme.skin.borderColor,
  }));

  export const SCardCenter = styled('div')((props: { theme: Theme }) => ({
    padding: '16px',
    //minWidth       : '297px',
    boxShadow      : props.theme.skin.boxShadow,
    backgroundColor: props.theme.palette.background.default,
    borderRadius   : '12px',
    border         : '1px solid ' + props.theme.skin.borderColor,
    alignItems     : 'center',
    display        : 'flex',
    flexDirection  : 'column',
    gap            : '8px'
  }));