import { Skeleton, Typography } from "@mui/material";
import { IAudienceActivationRecap } from "application/entities/dataTypes/audienceActivationRecap";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { providerIconList } from "assets/providerIcons";
import { ShadowBox } from "components/templates/InfoBlock";
import { AppIcon } from "components/ui/AppIcon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type TCheckoutParameters = {
  checkoutParameters: IAudienceActivationRecap[];
  loading: boolean;
  audience: IAudiences;
};

export const CheckoutParameters = (props: TCheckoutParameters) => {
  const { checkoutParameters, loading, audience } = props;
  const { t } = useTranslation();
  const [dspList, setDspList] = useState<any>([]);

  React.useEffect(() => {
    if (audience?.id) {
      const dsps = Array.from(audience?.dspCampaignInfo);
      let dspList: any = [];
      dsps.forEach((dsp: any) => {
        dsp.dspItems.forEach((item: any) => {
          dspList.push(item);
        });
      });
      setDspList(dspList);
    }
  }, [audience]);

  const getDspInfo = (dspCode: string) => {
    return dspList.find((dsp: any) => dsp.code === dspCode);
  };

  return (
    <>
      <Typography variant={"h5"} style={{ paddingBottom: "30px" }}>
        Audience format and platform configuration
      </Typography>
      {loading ? (
        <Skeleton variant="rectangular" height={150} style={{ borderRadius: "10px" }} />
      ) : (
        <ShadowBox className={"pad-2"} style={{ padding: "10px 20px", backgroundColor: "#fff" }} data-cy={"dashboard-your-team"}>
          <table className="channelSummary" data-cy="checkout_parameters">
            <thead>
              <tr>
                <th align="left">{t("dsp.enablementChannel")}</th>
                <th align="left">
                  <Typography>{t("dsp.enablementOption")}</Typography>
                </th>
                <th>
                  <Typography>Format banner</Typography>
                </th>
                <th>
                  <Typography>Format native</Typography>
                </th>
                <th>
                  <Typography>Format video</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {checkoutParameters?.map((dspItem: any, index: number) => {
                return (
                  <tr key={dspItem.id} data-cy={"dsp" + dspItem.id}>
                    <td align="left">
                      <div className={"flex-h flex-align-middle gap-small align-left"}>
                        <div className={"iconCircle"}>
                          <AppIcon fontSize={"small"} icon={providerIconList?.[dspItem.dspCode]} />
                        </div>
                        <div>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }} data-cy={"activationPlatform"}>
                            {getDspInfo(dspItem.dspCode)?.seatName}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td align="left">
                      <Typography data-cy={"dspName"}>{dspItem.dspName}</Typography>
                    </td>

                    <td align="center">
                      <Typography>
                        {dspItem.checkoutParameters.format_banner === false ? (
                          <AppIcon icon="CheckboxUnChecked" />
                        ) : (
                          <AppIcon icon="CheckboxChecked" />
                        )}
                      </Typography>
                    </td>
                    <td align="center">
                      <Typography>
                        {dspItem.checkoutParameters.format_native === false ? (
                          <AppIcon icon="CheckboxUnChecked" />
                        ) : (
                          <AppIcon icon="CheckboxChecked" />
                        )}
                      </Typography>
                    </td>
                    <td align="center">
                      <Typography>
                        {dspItem.checkoutParameters.format_video === false ? (
                          <AppIcon icon="CheckboxUnChecked" />
                        ) : (
                          <AppIcon icon="CheckboxChecked" />
                        )}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ShadowBox>
      )}
    </>
  );
};
