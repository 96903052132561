import { IFiltersValues } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { FeatureLabel } from "../audienceFeature";
import { sliderConfig } from "pages/AudienceBuilder/configuration";
import { buildSliderDisplayValue } from "pages/AudienceBuilder/audienceBuilderUtils";

type ViewAudienceSliderProps = {
  filterValue: IFiltersValues;
};

export const ViewAudienceSlider = (props: ViewAudienceSliderProps) => {
  const { filterValue } = props;
  const sliderConf = sliderConfig[filterValue.code];

  const Selection = () => {
    const selectable = filterValue.selectable[0];
    const selected = filterValue.selected[0];
    if (selected.min === 0 && selected.max === 0) {
      return null;
    }
    if (selected.min === selectable.min && selected.max === selectable.max) {
      return <FeatureLabel data-cy="slider-range-filter-card-value">{buildSliderDisplayValue({ config: sliderConf, type: "all" })}</FeatureLabel>;
    } else if (selected.min === selectable.min && selected.max < selectable.max) {
      return (
        <FeatureLabel data-cy="slider-range-filter-card-value">
          {buildSliderDisplayValue({ config: sliderConf, type: "less", value: { max: selected.max } })}
        </FeatureLabel>
      );
    } else if (selected.min > selectable.min && selected.max === selectable.max) {
      return (
        <FeatureLabel data-cy="slider-range-filter-card-value">
          {buildSliderDisplayValue({ config: sliderConf, type: "more", value: { min: selected.min } })}
        </FeatureLabel>
      );
    } else {
      return (
        <FeatureLabel data-cy="slider-range-filter-card-value">
          {buildSliderDisplayValue({ config: sliderConf, type: "range", value: { min: selected.min, max: selected.max } })}
        </FeatureLabel>
      );
    }
  };

  return (
    <div style={{ padding: "4px", display: "flex", gap: "8px", justifyContent: "space-between" }}>
      <Selection />
    </div>
  );
};
