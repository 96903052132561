import {apiBuilder, IApiOptions, IApiReturn, IPageParameters, ISearchByParameter, ISearchValueParameter, ISortParameters} from '../api/apiService';
import {TEntityName} from '../dataTypes';
import {BackendError} from '../../../types/backendError';

export class AbstractBaseApi<T> {
  
  private readonly entityName: TEntityName;
  private apiEntity: apiBuilder; // apiBuilder
  
  protected constructor(entityName: TEntityName, options?: IApiOptions) {
    if (this.constructor === AbstractBaseApi) {
      throw new TypeError('Abstract class, cannot be instantiated');
    }
    this.entityName = entityName;
    this.apiEntity  = new apiBuilder(this.entityName, undefined, options); //apiService.entity(this.entityName, options);
    // if(options)console.log(options)
  }
  
  get(args?: { page?: IPageParameters, sort?: ISortParameters, output?: IApiReturn }) {
    const defaultProps = {pageNumber: 0, pageSize: 150};
    const fre          = {
      ...defaultProps,
      ...args?.page
    };
    return this.apiEntity
               .page(fre)
               .sort(args?.sort)
               .get(args?.output)
               .then(res => res)
               .catch((error: any) => {
                 throw new Error(error);
               });
  }
  
  find(args: {
    searchField: ISearchByParameter,
    searchValue?: ISearchValueParameter,
    page?: IPageParameters,
    sort?: ISortParameters,
    ret?: IApiReturn
  }) {
    return this.apiEntity
               .find(args.searchField, args.searchValue)
               .page(args?.page)
               .sort(args?.sort)
               .fetch(args?.ret)
               .then(res => res)
               .catch((error: any) => {
                 throw error;
               });
  }
  
  // find had several implementations, legacy
  naturalFind(args: {
    searchValue: string | number,
    page?: IPageParameters,
    sort?: ISortParameters,
    ret?: IApiReturn
  }) {
    return this.apiEntity.naturalFind(args.searchValue)
               .page(args?.page)
               .sort(args?.sort)
               .fetch(args?.ret)
               .then(res => res)
               .catch((error: any) => {
                 throw error;
               });
  }
  
  byId<T = any>(id: number) {
    return this.apiEntity.byId(id)
               .get<T>()
               .then(res => res.data)
               .catch((error: any) => {
                 throw new BackendError(error);
               });
  }
  
  create(data: Partial<T>) {
    return this.apiEntity.post(data)
               .then(res => res.data)
               .catch((error: any) => {
                 throw error;
               });
  }
  
  update<T = any>(id: number, data: Partial<T>) {
    return this.apiEntity.byId(id)
               .patch(data)
               .then((res) => {
                 return res.data;
               })
               .catch((error: any) => {
                 throw error;
               });
  }
  
  delete(id: number) {
    return this.apiEntity
               .byId(id)
               .delete()
               .catch((error: any) => {
                 throw error;
               });
  }
}