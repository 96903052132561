import { Input, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import styledOld from "styled-components";
import signinbackground from "../../../assets/images/signin-temp-background.jpg";

import { styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getLoginBackgroundAlternative } from "application/utils/domain.utils";

// shared styledOld components file,
// declared here for mvp purpose
// will move to the right place after next merge

export const InputStyled = styledOld(Input)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 19px 14px 14px;

  position: relative;
  width: 327px;
  height: 48px;

  /* White */
  background: #ffffff;

  /* Space Grey */
  border: 1px solid #3b4559;
  box-sizing: border-box;
  border-radius: 3px;
`;

export const InputWrapper = styledOld.div`
  margin-bottom: 1rem;
`;

export const HtmlInputLabelStyled = styledOld.div`
  margin-top: 1rem;
  font-weight: bold;
  color: #9daabf;
  padding: 3px 0;
`;

export const HtmlInputStyled = styledOld.input`
  padding: 1rem;
  position: relative;
  margin: 0.5rem 0 0.25rem 0;
  width: 13.75rem;
  height: 30px;
  background: #ffff;
  box-shadow: 0px 0px 6px rgba(196, 211, 241, 0.85);
  border-radius: 20px;
`;

export const AppContainer = styledOld.div`
  position:static;
  height: 100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content:center;
  // background: linear-gradient(180deg, #4262ff 19.27%, #37def9 72.92%);
`;

export const AuthContainer = styled("div")((props: { theme: Theme }) => ({
  height: "100vh",
  background: getLoginBackgroundAlternative() ? "linear-gradient(60deg, #4262ff 19.27%, #37def9 72.92%)" : "url(" + signinbackground + ")",
  backgroundSize: "cover",
}));

export const AuthContainerBar = styledOld.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 18px;
  color: white;
`;

export const AuthButtonLinkBlue = styledOld(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;

  position: static;
  min-width: 64px;
  height: 30px;
  left: 0px;
  top: 108px;

  /* Blue Gradient */

  background: linear-gradient(94.21deg, #00b5e2 0%, #10caf9 100%);
  /* Button - Shadow - Blue */

  box-shadow: 0px 4px 9px rgba(14, 199, 246, 0.3);
  border-radius: 20px;

  /* Inside Auto Layout */
  margin: 24px 0px;
`;

export const AuthButtonSubmit = styledOld.button`
  padding: 6px 14px;
  color: white;
  width: 9rem;
  height: 40px;

  background: linear-gradient(94.21deg, #00b5e2 0%, #10caf9 100%);

  box-shadow: 0px 4px 9px rgba(14, 199, 246, 0.3);
  border-radius: 20px;
  margin: 24px 0px;

  &:disabled {
    color: #3B4559;
    background: #F0F2F7;
  }
`;

export const AuthButtonLinkBlueBordered = styledOld(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;

  position: static;
  min-width: 64px;
  height: 30px;

  /* Other / Clickable */

  background: rgba(255, 255, 255, 0.0001);
  /* Data Blue */

  border: 0.5px solid #00b5e2;
  box-sizing: border-box;
  border-radius: 20px;

  /* Inside Auto Layout */
  margin: 24px 0px;
`;

export const AuthContainerBody = styled("div")((props: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  backgroundColor: getLoginBackgroundAlternative() ? "" : "rgba(0,0,0,0.5)",
}));

// @deprecated
export const HtmlModalInfo = styledOld.div`
  height: 80%;
  width: 250px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 3.3333333333px 6.8965517241px 0 rgba(0, 0, 0, 0.15),
  0 0.6106870229px 1.8604651163px 0 rgba(0, 0, 0, 0.1);
  align-self: center;
  border: 1px solid red;
`;

export const AuthHeader = styled("div")((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.mode === "light" ? "#f7f8fb" : "rgba(255,255,255,0.1)",
  zIndex: 1000,
  position: "sticky",
  top: 0,
  padding: "0.5rem 1rem 1rem 1rem",
  marginBottom: "1rem",
}));

export const AuthModalBox = styled(Paper)((props: { theme: Theme }) => ({
  position: "relative",
  alignSelf: "center",
  width: "353px",
  background: "#ffffff",
  //boxShadow: '0px 2px 11px rgba(191, 191, 191, 0.62)',
  borderRadius: "12px",
  overflow: "hidden",
}));

export const AuthModalBoxInner = styledOld.div`
  position: relative;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  align-self: center;
  height:100%;
  overflow:auto;
`;

export const AuthSignZone = styledOld.div`
  position: relative;
  margin: 0 auto;
  align-self: center;
  height:100%;
`;

export const AuthTStepBox = styledOld.div`
  padding: 0.5rem 0;
  text-align: center;
`;

export const AuthTickBox = styledOld.div`
  display: flex;
  text-align: center;
`;

export const HtmlForm = styledOld.div`
  position: relative;
  padding: 0 2rem 1rem 2rem;
  text-align: center;
`;

export const AuthPassStr = styledOld.div`
  position: relative;
  width: 13.75rem;
  background: #ffff;
  display: inline-block;
`;
