import {useEffect} from 'react';
import {useAuthActions} from '../../../../redux/slices/auth.slices';
import {useNavigate, useParams} from 'react-router-dom';
import {appPages} from '../../../../application/pages/pages.config';

/** used to signout before reaching user_activation page */
export const RedirectInvite = () => {
  const authRedux = useAuthActions();
  const history   = useNavigate();
  const urlParams = useParams<{ email: string, password: string }>() as { email: string, password: string };
  
  useEffect(() => {
    authRedux.doSignOut();
    // redirect to target
    history(appPages.auth.user_activation.path.replace(':email',urlParams.email),{replace:true});
  }, []);
  
  return <></>;
};