import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';


export const ColorButton = withStyles((theme: Theme) => ({
  root: {
    height: '30px',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    color: '#FFFFFF',
    background: 'linear-gradient(94.21deg, #00B5E2 0%, #10CAF9 100%)',
    boxShadow: '0px 4px 9px rgba(14, 199, 246, 0.3)',
    borderRadius: '20px',
    padding: '0 16px',
    fontSize: 12,
    textTransform: 'none',
    '&:focus': {
      boxShadow: '0px 4px 9px rgba(14, 199, 246, 0.3)',
    },
    '&:disabled': {
      color: '#3B4559',
      background: '#F0F2F7',
      boxShadow: `0 0 6px rgba(196, 211, 241, 0.85)`,
    },


  },
}))(Button);