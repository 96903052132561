
import {AbstractBaseApi} from './AbstractBaseApi';
import {IApiOptions} from '../api/apiService';
import {IDspChannels} from '../dataTypes/dspChannels';


export class DspChannels extends AbstractBaseApi<IDspChannels> {
  constructor(options?: IApiOptions) {
    super('dspChannels', options);
  }
}
