import React from 'react';
import {IMainFrameHeaderProps, MainFrameHeader} from '../MainFrameHeader';
import {BreadCrumb, IBreadCrumbProps} from '../../interface/BreadCrumb';
import {Page404} from '../Page404';
import {Box, CircularProgress, Paper} from '@mui/material';
import {styled} from '@mui/styles';
import {useUiStore} from '../../../redux/ui.store';
import {BackendError} from '../../../types/backendError';
import {pageUtils} from '../../../application/pages/pages.utils';
import {granter, IAccessOptions, TAccessObjMatch} from '../../../application/utils/granter.utils';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {useLoggedUser} from '../../hooks/useLoggedInUser';
interface IMainFrameProps {
  frameId: string;
  loading?: boolean;
  restrict404?: boolean;
  /** @deprecated */
  restrictMessage?: string;
  /** @deprecated */
  restrictText?: string;
  /** @deprecated */
  // should be used instead of restrict404 restrictMessage restrictText
  backendError?: BackendError | null; // null, because of useQuery
  children?: any;
  mainFrameHeaderContent?: any; // component to replace whole header;
  headerContent?: any; // content for the mainFrameHeader
  headerProperties?: any;
  headerButtons?: any;
  hideBreadCrumb?: boolean;
  hideHeader?: boolean;
  scrollable?: boolean;
  onClick?: Function;
  breadCrumbProps?: Partial<IBreadCrumbProps>;
  headerProps?: Partial<IMainFrameHeaderProps>;
  checkPageAccess?: boolean;
  grantWith?: TAccessObjMatch;
  debug?: boolean;
  browserTitle?: string;
}

export const MainFrame = (props: IMainFrameProps) => {
  
  let {
        frameId,
        loading,
        restrictMessage,
        headerContent,
        hideBreadCrumb,
        restrictText,
        backendError,
        hideHeader,
        headerProperties,
        breadCrumbProps,
        headerButtons,
        restrict404,
        headerProps,
        mainFrameHeaderContent,
        checkPageAccess,
        grantWith = {},
        debug= false,
        browserTitle
      } = props;
  
 // const {user,AUTHUser} = useLoggedUser();
  
  React.useEffect(() => {
    if (frameId) {
      const page     = pageUtils.getPageById(frameId);
      const title = (browserTitle ?? page?.title ?? 'Evorra');
      if(title!==undefined) document.title =title
      // set active Frame // collide with NavBarLeft usage of useUiStore.setState
      // useUiStore.setState({activeFrame: frameId});
    }
  }, [frameId, props?.headerProps,browserTitle]);
  
  React.useEffect(()=>{
    // set active Frame // collide with NavBarLeft usage of useUiStore.setState
    if(!loading) useUiStore.setState({activeFrame: frameId});
  },[loading])
  
  if (checkPageAccess) {
    const page = pageUtils.getPageById(frameId);
    if (page._accesses) {
      if(debug) console.log('page._accesses',page._accesses)
      const test = granter
      .grantAccesses(page._accesses as IAccessOptions)
      .debug(debug)
      .with(grantWith)
      .toUser(AUTHUser.granterUser);
      
      if (!test) {
        restrict404     = true;
        restrictMessage = 'Unauthorized access';
      }
    }
  }
  
  return (
    <MainFrameContainer data-cy={'MainFrame'}>
      {loading && <MainFrameLoader>
          <CircularProgress size={24}/>
      </MainFrameLoader>}
      {!Boolean(hideBreadCrumb) && <><BreadCrumb {...breadCrumbProps} frameId={frameId}/></>}
      {(!Boolean(mainFrameHeaderContent) && !Boolean(hideHeader) && !Boolean(backendError) && !Boolean(restrict404)) && (
        <MainFrameHeader headerButtons={headerButtons}
                         headerProperties={headerProperties}
                         frameId={frameId}
                         isLoading={loading}
                         {...headerProps}>{headerContent}</MainFrameHeader>
      )}
      {mainFrameHeaderContent}
      <MainFrameInner>
        {(!Boolean(loading) && (Boolean(backendError) || Boolean(restrict404))) ?
         <Page404 backendError={backendError} title={restrictMessage} text={restrictText}/> : props.children ?? null}
      </MainFrameInner>
    </MainFrameContainer>
  );
};


const MainFrameContainer = styled(Box)({
  position     : 'relative',
  height       : '100%',
  width        : '100%',
  maxHeight    : '100%',
  overflow     : 'hidden',
  display      : 'flex',
  flexDirection: 'column',
});

const MainFrameInner = styled(Paper)((props) => ({
  flex        : 1,
  height      : '100%',
  maxHeight   : '100%',
  overflow    : 'hidden',
  borderRadius: 0,
  position    : 'relative'
}));

const MainFrameLoader = styled(Box)({
  zIndex         : 900,
  top            : 0,
  left           : 0,
  position       : 'absolute',
  width          : '100%',
  height         : '100%',
  maxHeight      : '100%',
  overflow       : 'hidden',
  backgroundColor: 'rgba(255,255,255,0.4)',
  // backdropFilter : 'blur(1px)',
  display       : 'flex',
  alignItems    : 'center',
  justifyContent: 'center',
});