import {Box, Divider, FormControlLabel, Switch, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {StyledCheckbox} from "components/form/CheckBox";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import React from "react";
import {AppTip} from "../AudienceBuilderAppTip";

interface InputInclusionListProps {
    featureCode: string;
}

export const InputInclusionList = (props: InputInclusionListProps) => {
    const {featureCode} = props;

    const audienceActions = useAudienceBuilderStore.getState().actions;

    const accountId = useAudienceBuilderStore.getState().audience.account.id;

    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];

    const exclusionLists = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "EXCLUSION_LIST";
    })[0];


    const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode).feature;

    const selectable = filtersValues.selectable;
    const selected = filtersValues.selected as FirstPartyDataFile[];

    const cleanSelectionBeforeUpdate = (selection: FirstPartyDataFile[]): Array<Partial<FirstPartyDataFile>> => {
        return selection.map((list) => {
            return {
                code: list.code,
                numEnrichedProfiles: list.numEnrichedProfiles,
                numRawProfiles: list.numRawProfiles,
                name: list.name,
                useEnrichedProfiles: list?.useEnrichedProfiles ?? false,
            };
        });
    };

    const handleToggleFile = (featureValue: FirstPartyDataFile) => {
        const currentSelection = [...selected];

        const index = currentSelection.findIndex((current) => current.code === featureValue.code);
        if (index !== -1) {
            currentSelection.splice(index, 1);
        } else {
            currentSelection.push(featureValue);
        }
        audienceActions.insertFeatureValues(feature, cleanSelectionBeforeUpdate(currentSelection));
    };

    const toggleUseEnrichedProfiles = (fileCode: string) => {
        const currentSelection = [...selected];

        currentSelection.map((current) => {
            if (current.code === fileCode) {
                current.useEnrichedProfiles = current?.useEnrichedProfiles ? false : true;
            }
            return current;
        });
        audienceActions.insertFeatureValues(feature, cleanSelectionBeforeUpdate(currentSelection));
    };

    return (
        <Box sx={{maxHeight: "40vh", overflowY: "auto"}}>
            {selectable.map((list: FirstPartyDataFile, idx) => {
                const isUseEnrichedProfiles = selected.findIndex((selected) => selected.code === list.code && selected?.useEnrichedProfiles) > -1;
                const listName = list.name.replaceAll('_', ' ')
                return (
                    <React.Fragment key={list.code}>
                        <Box display="flex" alignItems="center" gap={1} mb="5px" sx={{cursor: "pointer"}}
                             title={exclusionLists.selected.includes(list.code) ? "You can't select this list because it's already selected as an exclusion list" : list.description}
                             onClick={() => handleToggleFile(list)}
                        >
                            <StyledCheckbox checked={selected.findIndex((file) => file.code === list.code) > -1}
                                            disabled={exclusionLists.selected.includes(list.code)}

                            />
                            <Typography variant="body1" fontWeight={900}
                                        sx={{lineBreak: listName.split(' ').length > 1 ? "loose" : "anywhere"}}>
                                {listName}
                            </Typography>
                        </Box>
                        <Box ml={3}>
                            <Typography className="color-tech-grey" variant="body1"
                                        sx={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                {profilesFormatter.format(list.numRawProfiles ?? 0)} uploaded profiles
                                <AppTip title="Uploaded Profiles"
                                        text="The total count of unique profiles previously uploaded."/>
                            </Typography>
                            {(list.numEnrichedProfiles ?? 0) > 0 && (
                                <>
                                    <Typography className="color-tech-grey" variant="body1"
                                                sx={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                        {profilesFormatter.format(list.numEnrichedProfiles ?? 0)} enhanced profiles
                                        <AppTip title="Enhanced Profiles"
                                                text="The count of unique profiles enriched with additional targeting IDs from your uploaded list. For example, if you uploaded hashed emails, we match them with more IDs from our data partners to improve audience targeting."/>
                                    </Typography>
                                    {selected.findIndex((file) => file.code === list.code) > -1 && (
                                        <FormControlLabel
                                            sx={{mt: 1}}
                                            control={<Switch onChange={() => toggleUseEnrichedProfiles(list.code)}
                                                             checked={isUseEnrichedProfiles}/>}
                                            label="Use Enhanced Profiles"
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                        {idx !== selectable.length - 1 && <Divider sx={{mb: 2, mt: 2}}/>}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};
