import * as yup from 'yup';


export const userValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup
    .string()
    .trim('The contact name cannot include leading and trailing spaces')
    .matches(/^[^0-9]+$/, 'Must be only letters')
    .min(2, 'Minimum 2 characters length')
    .max(255, 'Max 255 characters length')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .matches(/^[^0-9]+$/, 'Must be only letters')
    .min(2, 'Minimum 2 characters length')
    .max(255, 'Max 255 characters length')
    .required('Last Name is required'),
});

export const validURL = /^((https?):\/\/)?\S+\.+[a-zA-Z]+\/?$/


