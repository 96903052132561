import { InputArrayString } from "./InputArrayString";
import { StyledSlider } from "./InputSlider";
import { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography, styled } from "@mui/material";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import React from "react";
import { IFeaturesDto } from "application/entities/dataTypes/features";
import _ from "lodash";

interface InputAgeProps {
  featureCode: string;
  selectedValues?: { min: number; max: number };
}

export const InputAge = (props: InputAgeProps) => {
  const [tabValue, setTabValue] = useState<number>(0);

  const feature = useAudienceBuilderStore.getState().getFeatureByCode(props.featureCode).feature;
  const audienceActions = useAudienceBuilderStore.getState().actions;
  const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
    return el.code === props.featureCode;
  })[0];

  const [tabArrayFeatureValue, setTabArrayFeatureValue] = useState<string[]>([]);
  const [tabSliderFeatureValue, setTabSliderFeatureValue] = useState<string[]>([]);

  const timer = React.useRef<any>(null);

  useEffect(() => {
    if (filtersValues.selected.length > 0) {
      if (filtersValues.selectable.find((selectable) => selectable.value === filtersValues.selected[0])) {
        setTabValue(0);
      } else {
        setTabValue(1);
      }
    }
  }, [filtersValues.selected]);

  const handleTabChange = (event: React.SyntheticEvent, tabIdx: number) => {
    setTabValue(tabIdx);
    if (tabIdx === 0) {
      setTabSliderFeatureValue(filtersValues.selected);
      audienceActions.insertFeatureValues(feature as IFeaturesDto, tabArrayFeatureValue);
    }

    if (tabIdx === 1) {
      setTabArrayFeatureValue(filtersValues.selected);
      audienceActions.insertFeatureValues(feature as IFeaturesDto, tabSliderFeatureValue);
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ background: "#f8f9fb", borderRadius: "0 0 7px 7px", border: "1px solid #eee", borderTop: "0" }}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }

  const AgeSlider = () => {
    const sliderMin = 18;
    const sliderMax = 100;
    const sliderMarkInterval = 10;
    const step = 1;
    const [stateFeatureValues, setStateFeatureValues] = useState<any>([sliderMin, sliderMax]);

    const convertFilterValueSelecterdToState = () => {
      if (filtersValues.selected.length > 0) {
        const values = filtersValues.selected[0].split("-");
        return { min: parseInt(values[0]), max: parseInt(values[1]) };
      } else {
        return { min: sliderMin, max: sliderMax };
      }
    };
    const [value, setValue] = useState<{ min: number; max: number }>(convertFilterValueSelecterdToState());

    const marks = [{ value: sliderMin, label: sliderMin.toString() }];
    for (let i = 30; i < sliderMax; i += sliderMarkInterval) {
      marks.push({
        value: i,
        label: i.toString(),
      });
    }
    marks.push({ value: sliderMax, label: sliderMax.toString() });

    useEffect(() => {
      if (filtersValues.selected.length > 0) {
        if (filtersValues.selectable.find((selectable) => selectable.value === filtersValues.selected[0])) {
          setTabValue(0);
        } else {
          setTabValue(1);
        }
      }
      if (!_.isEqual(filtersValues.selected, stateFeatureValues))
        setValue(filtersValues.selected.length > 0 ? convertFilterValueSelecterdToState() : { min: sliderMin, max: sliderMax });
    }, [filtersValues.selected]);

    const Selection = () => {
      if (value.min === 0 && value.max === 0) {
        return null;
      } else {
        return (
          <StyledSelection data-cy="age-filter-selected">
            From {value.min} to {value.max}
          </StyledSelection>
        );
      }
    };

    const handleChange = (event: Event, newValues: number | number[]) => {
      //update the dom
      if (typeof newValues !== "number") {
        setValue({ min: newValues[0], max: newValues[1] });
        setStateFeatureValues({ min: newValues[0], max: newValues[1] });

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          audienceActions.insertFeatureValues(feature as IFeaturesDto, [newValues[0] + "-" + newValues[1]]);
        }, 500);
      }
    };

    return (
      <>
        {Selection && (
          <div style={{ width: "100%" }}>
            <Selection></Selection>
          </div>
        )}
        <StyledSlider
          getAriaLabel={() => "Age"}
          value={[value.min, value.max]}
          onChange={handleChange}
          valueLabelDisplay="off"
          marks={marks}
          step={step}
          disableSwap
          max={sliderMax}
          min={sliderMin}
          data-cy="slider-filter"
        />
      </>
    );
  };

  return (
    <StyledTabsWrap>
      <Box>
        <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="" data-cy="age-tabs">
          <StyledTab label="Default ranges" />
          <StyledTab label="Custom range" />
        </StyledTabs>
      </Box>

      <CustomTabPanel value={tabValue} index={0}>
        <InputArrayString featureCode={"AGE"} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <AgeSlider />
      </CustomTabPanel>
    </StyledTabsWrap>
  );
};
const StyledTabsWrap = styled("div")(({ theme }) => ({}));

const StyledSelection = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  paddingBottom: "7px",
  color: "#7587a3",
  fontWeight: "600",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "30px",
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: 0,
  color: "#7587a3",
  fontSize: "11px",
  minHeight: "30px",
  background: "#fff",
  borderRadius: "7px 7px 0 0",
  paddingInline: "5px",
  borderBottom: "1px solid #eee",
  width: "50%",
  transition: "none",
  "&.Mui-selected": {
    background: "#f8f9fb",
    borderRadius: "7px 7px 0 0",
    border: "1px solid #eee",
    borderBottom: "0",
    borderColor: "#eee !important",
  },
  "&:hover": {
    transform: "scale(1)",
  },
}));
