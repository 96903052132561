import {AppIcon} from '../../ui/AppIcon';
import React, {startTransition} from 'react';
import styledOld from 'styled-components';
import {ColumnsMiddle,} from '../../ui/AppElements';
import {Box, CircularProgress, useTheme} from '@mui/material';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';

interface IHistoryControlProps {
  onPrevious: Function;
  onNext: Function;
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  loading?: boolean;
};


export const HistoryControl = (props: IHistoryControlProps) => {
  const theme                                               = useTheme();
  const {onPrevious, onNext, hasPrevious, hasNext, loading} = props;
  
  const previousColor = hasPrevious ? theme.palette.primary.main : '#cccccc';
  const nextColor     = hasNext ? theme.palette.primary.main : '#cccccc';
  
  const [localLoad, setLocalLoad] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    setLocalLoad(Boolean(loading));
  }, [loading]);
  
  function onLocalGoto(dir: 'previous' | 'next') {
    setLocalLoad(true);
    setTimeout(() => {setLocalLoad(false);}, 1250);
    if (loading) return;
    startTransition(() => {
      if (dir === 'previous') onPrevious();
      if (dir === 'next') onNext();
    });
  }
  
  
  return <Box data-cy={'HistoryControl'}>
    <BoxDo disabled={!hasPrevious || (localLoad ?? loading)} data-cy={'btnActionUndo'} onClick={() => { if(hasPrevious) onLocalGoto('previous');}}>
      <AppIcon display={'block'} fontSize={'small'} color={loading ? '#cccccc' : previousColor} icon={'Undo'}/>
      Undo
    </BoxDo>
    {/*<TimeArea width={3} padding={[0, 0.25]}>
     <TimeZone>
     {(localLoad ?? loading) && <CircularProgress style={{display: 'block', marginTop: '0.5rem'}} size={24}/>}
     {(!localLoad ?? !loading) && <AppIcon display={'block'} fontSize={'medium'} icon={'Time'}/>}
     </TimeZone>
     </TimeArea>*/}
    <BoxDo disabled={!hasNext || (localLoad ?? loading)} data-cy={'btnActionRedo'} onClick={() => { if(hasNext) onLocalGoto('next');}}>
      <AppIcon display={'block'} fontSize={'small'} color={loading ? '#cccccc' : nextColor} icon={'Redo'}/>
      Redo
    </BoxDo>
  </Box>;
};

const TimeArea = styledOld(Box)({
  display : 'block',
  position: 'relative',
  zIndex  : 10,
});


const HistBar = styledOld(ColumnsMiddle).attrs({radius: 40})({
  display: 'inline-flex',
  width  : 'auto',
  // border : '1px solid #ededed',
  height: '20px'
});

const BoxDo = styled(Box)((props: { theme: Theme, disabled?: boolean }) => ({
  height         : '52px',
  color: props.disabled ? '#ccc' : '',
  gap            : '4px',
  display        : 'flex',
  flexDirection  : 'column',
  alignItems     : 'center',
  justifyContent : 'center',
  textAlign      : 'center',
  cursor         : 'pointer',
  backgroundColor: props.theme.palette.mode === 'light' ? '#F0F2F7' : props.theme.palette.background.paper,
  zIndex         : 0,
  '&:hover'      : {
    'svg': {
      'path[stroke]': {
        stroke: '#00B5E2',
      },
      'line[stroke]'      : {
        stroke: '#00B5E2',
      },
      'path[fill]'  : {
        fill: '#00B5E2',
      }
    }
  }
}));


const TimeZone = styledOld.div({
  position: 'absolute',
  display : 'inline-block',
  width   : '27px',
  top     : '-18px',
  left    : '-16px',
  padding : 0,
});