import {FormHelperText} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {BootstrapInput, BootstrapLabel} from '../../../components/form/InputAnna';
import {InfoBlockOld} from '../zOld/accountDetails.styled';


interface IAccountNotesProps {
  account: Partial<IAccounts>;
  setAccount: (arg: Partial<IAccounts>) => void;
}

export const CreateAccountNote: React.FC<IAccountNotesProps> = ({
                                                                  account,
                                                                  setAccount,
                                                                }) => {
  
  
  const [inputNote, setInputNote] = React.useState('');
  const {t}              = useTranslation();
  const [error, setError]         = React.useState(false);
  const [touched, setTouched]     = React.useState(false);
  
  React.useEffect(() => {
    if (touched && (inputNote.trim().length < 3 || inputNote.trim().length > 255)) {
      setError(true);
    } else {
      setError(false);
    }
    return () => setTouched(false);
  }, [inputNote]);
  
  React.useEffect(() => {
    if (inputNote !== '' && !error) {
      setAccount({...account, notes: [{text: inputNote}]});
    }
  }, [inputNote, error]);
  
  
  const handleChangeNotes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTouched(true);
    setInputNote(event.target.value as string);
  };
  
  return (
    <>
      <NotesBlock data-cy={'account-note'}>
        <BootstrapLabel htmlFor="bootstrap-input-multiline">
          {t('account.accountNotesLabels.notes')}
        </BootstrapLabel>
        <InputBlock>
          <BootstrapInput
            style={{height: 104, padding: '0', width: '100%'}}
            value={inputNote}
            onChange={handleChangeNotes}
            id="bootstrap-input-multiline"
            multiline
            rows={4}
          />
          {error &&
           <FormHelperText
               style={{color: 'red', fontSize: 10, margin: 5}}
           >
             {t('account.accountNotesLabels.notes')}
           </FormHelperText>}
        </InputBlock>
      </NotesBlock>
    </>
  );
};

const NotesBlock = styled(InfoBlockOld)`
  height: fit-content;
`;

const InputBlock = styled.div`
  width: 100%;
  height: 124px;
`;
