import { Typography } from "@mui/material";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { AUTHUser } from "application/utils/AuthUser";
import { moneyFormatter } from "application/utils/formatters.utils";
import { GlobalAvatarLight } from "components/ui/GlobalAvatar";
import moment from "moment";
import { profilesFormatter } from "../../../_configuration/formaters";
import { TDataColumnModel } from "../../utils/dataColumnModel.utils";
import { IAudiences } from "../dataTypes/audiences";

export const audiencesColumns = (): TDataColumnModel => {
  return {
    id: {
      field: "id",
      headerName: "Audience ID",
      type: "string",
      width: 100,
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        return "#" + params.row?.id;
      },
      code: "audience_id",
    },
    name: {
      field: "name",
      headerName: "Audience Name",
      flex: 1,
      type: "string",
      cellClassName: "titleCell minCell",
      minWidth: 130,
      code: "audience_name",
    },
    company: {
      field: "account",
      headerName: "Brand Name",
      flex: 1,
      type: "string",
      cellClassName: "titleCell minCell",
      minWidth: 130,
      code: "company_name",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        return params.row?.account?.companyName ?? "Unknown";
      },
    },
    status: {
      field: "audienceStatus",
      headerName: "Status",
      flex: 1,
      type: "string",
      cellClassName: "titleCell minCell",
      minWidth: 90,
      code: "company_name",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        const status = params.row?.audienceStatus;
        if (status === "ACTIVATED") return "Enabled";
        if (status === "DRAFT") return "Draft";
        if (status === "PENDING_APPROVAL") return "Pending";
        if (status === "ARCHIVED") return "Archived";
        if (status === "APPROVED") return "Approved";
        return params.row?.audienceStatus;
      },
    },
    creator: {
      field: "author",
      headerName: "Creator",
      type: "none",
      width: 200,
      code: "audience_creator",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        if (params.row.author?.firstName && params.row.author?.lastName) {
          return (
            <GlobalAvatarLight
              user={params.row.author}
              isPending={params.row.status !== "ACTIVATED"}
              size={"28px"}
              fontSize={"15px"}
              showText={true}
            />
          );
        } else if (params.row?.author?.email) {
          return <>{params.row?.author?.email}</>;
        } else if (params.row?.author?.firstName && params.row?.author?.lastName) {
          return (
            <Typography>
              {params.row?.author?.firstName} {params.row?.author?.lastName}
            </Typography>
          );
        } else {
          return <Typography>Unknown user</Typography>;
        }
      },
    },
    requester: {
      field: "requester",
      headerName: "Requester",
      type: "none",
      width: 200,
      code: "audience_requester",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        return (
          <GlobalAvatarLight
            user={params.row.requester}
            isPending={params.row.status !== "ACTIVATED"}
            size={"28px"}
            fontSize={"15px"}
            showText={true}
          />
        );
      },
    },
    requestApprovalTime: {
      field: "requestApprovalTime",
      headerName: "Request date",
      type: "none",
      width: 200,
      code: "audience_request_date",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        return <Typography>{moment(params.row?.requestApprovalTime).format("D-MMM-YYYY h:mm A")}</Typography>;
      },
    },
    approvalTime: {
      field: "approvalTime",
      headerName: "Approval date",
      type: "none",
      width: 200,
      code: "audience_approval_date",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        return <Typography>{moment(params.row?.approvalTime).format("D-MMM-YYYY h:mm A")}</Typography>;
      },
    },
    approverAsked: {
      field: "approverAsked",
      headerName: "Approver asked",
      type: "string",
      width: 200,
      code: "audience_approver_asked",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        const approverFirstName = params.row?.approverAsked?.firstName ?? "";
        const approverLastName = params.row?.approverAsked?.lastName ?? "";
        let fullname = params.row?.approverAsked?.firstName ? approverFirstName + " " + approverLastName : params.row?.approverAsked?.email;
        if (AUTHUser.userId === params.row?.approverAsked?.id) {
          fullname = "You";
        }
        return fullname;
      },
    },
    approver: {
      field: "approver",
      headerName: "Approver",
      type: "string",
      width: 200,
      code: "audience_approver",
      valueGetter: (params: GridValueGetterParams<IAudiences>) => {
        const approverFirstName = params.row?.approver?.firstName ?? "";
        const approverLastName = params.row?.approver?.lastName ?? "";
        let fullname = params.row?.approver?.firstName ? approverFirstName + " " + approverLastName : params.row?.approver?.email;
        if (AUTHUser.userId === params.row?.approver?.id) {
          fullname = "You";
        }
        return fullname;
      },
    },
    active: {
      field: "audienceStatus",
      headerName: "Status",
      type: "string",
    },
    lastAudienceSize: {
      field: "lastAudienceSize",
      headerName: "Unique Profiles",
      type: "string",
      code: "audience_max_size",
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const val = params.row?.lastAudienceSize ?? 0;
        return val === -1 || val === undefined ? "< Minimum" : `${profilesFormatter.format(val)} profiles`;
      },
    },
    price: {
      field: "price",
      headerName: "Price",
      width: 80,
      type: "price",
      code: "audience_price",
      valueGetter: (params: GridValueGetterParams) => {
        const currency = params.row?.displayCurrency;
        let val = params.row.audienceActivationRecap.reduce((acc: any, curr: any) => {
          return acc + curr.pricePaidConverted;
        }, 0);
        val = moneyFormatter(val, { currency: currency });
        return `${val}`;
      },
    },
    cpm: {
      field: "cpm",
      headerName: "CPM min-max",
      width: 120,
      type: "price",
      code: "audience_cpm",
      valueGetter: (params: GridValueGetterParams) => {
        const currency = params.row?.displayCurrency;
        const cpmMin = params.row?.cpmMinConverted ?? 0;
        const cpmMax = params.row?.cpmMaxConverted ?? 0;
        if (cpmMin === 0 && cpmMax === 0) return "Not set";
        if (cpmMin === cpmMax) return moneyFormatter(cpmMin, { currency: currency });
        let min = cpmMin;
        min = moneyFormatter(min, { currency: currency });
        let max = cpmMax;
        max = moneyFormatter(max, { currency: currency });
        return min + " - " + max;
      },
    },

    /*  deterministicPercentage: {
    field      : 'deterministicPercentage',
    headerName : 'Deterministic Data',
    width      : 150,
    type       : 'percentage',
    align      : 'right',
    headerAlign: 'right',
    valueGetter: (params: GridValueGetterParams) => {
      const val = params.row?.deterministicPercentage as number
      return val ? val : undefined
    }
  },
  inferredData           : {
    field      : 'inferredData',
    headerName : 'Inferred Data',
    width      : 150,
    type       : 'percentage',
    valueGetter: (params: GridValueGetterParams) => {
      const val = params.row?.deterministicPercentage as number
      return 100 - val
    }
  },*/
    createdDate: {
      field: "creationTime",
      headerName: "Date Created",
      width: 180,
      type: "string",
      code: "audience_creation_date",
      valueGetter: (params: GridValueGetterParams) => {
        return (
          <div title={"Modified on " + moment(new Date(params.row?.modificationTime)).format("D-MMM-YYYY h:mm A")}>
            {moment(new Date(params.row?.creationTime)).format("D-MMM-YYYY h:mm A")}
          </div>
        );
      },
    },
    modifiedDate: {
      field: "modificationTime",
      headerName: "Last Modified",
      width: 180,
      type: "string",
      code: "audience_last_modified_date",
      valueGetter: (params: GridValueGetterParams) => {
        return moment(new Date(params.row?.modificationTime)).format("D-MMM-YYYY h:mm A");
      },
    },
    actions: {
      headerName: " ",
      width: 30,
      type: "actions",
      field: "none",
      code: "audience_actions",
    },
    actionsBtns: {
      headerName: "",
      width: 200,
      type: "none",
      field: "none",
    },
  };
};
// activationPlatform
// Number of profiles desired
// desired spend
// activation campaign name (select)
// activate this audience
export const audiencesActivateColumns: TDataColumnModel = {
  /*  method            : {
    field      : 'method',
    headerName : 'method',
    type       : 'string',
    headerAlign: 'center',
    align      : 'center',
    width      : 80,
    sortable   : false,
  },*/
  activationPlatform: {
    // should be dspName
    field: "dspName",
    headerName: "Enablement Platform",
    type: "string",
    cellClassName: "titleCell minCell",
    width: 116,
    flex: 1,
  },
  maxSize: {
    field: "maxSize",
    headerName: "Max Size",
    type: "string",
    width: 110,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  cpm: {
    field: "cpm",
    headerName: "CPM",
    type: "string",
    width: 70,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  profiles: {
    field: "profiles",
    headerName: "Number of Profiles",
    type: "string",
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  desiredSpend: {
    field: "desiredSpend",
    headerName: "Desired Spend",
    type: "string",
    width: 180,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  action: {
    field: "action",
    headerName: "Enable",
    type: "string",
    headerAlign: "center",
    align: "center",
    width: 100,
    sortable: false,
  },
};
