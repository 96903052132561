import { Button } from "@mui/material";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { audiencesColumns } from "application/entities/dataColumns/audiences.columns";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { PageId } from "application/pages/pages.config";
import { TDataColumnModel, dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { useAdminPage } from "components/hooks/useAdminPage";
import { GridTable } from "components/organisms/Grid";
import { MainFrame } from "components/templates/MainFrame";
import _ from "lodash";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { AudienceSetManualStep } from "./AudienceSetManualStep";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { useAudiencesManualStep } from "components/hooks/data/useAudiencesQuery";
import { Link } from "react-router-dom";
import { pageUtils } from "application/pages/pages.utils";

export const AudiencesManualStepList = () => {
  useAdminPage();
  const windowDialog = useOpenDialogWindow();

  const { isLoading, error, data, refetch } = useAudiencesManualStep();

  const audiences = data?.data?._embedded?.audiences;
  const flatData: IAudiences[] = !isLoading && !error ? audiences?.flat() ?? [] : [];

  const columns = _.cloneDeep(audiencesColumns());

  columns.activation = {
    field: "activation",
    headerName: "Channels",
    flex: 1,
    type: "string",
    cellClassName: "titleCell minCell",
    minWidth: 130,
    code: "company_name",
    valueGetter: (params: GridValueGetterParams<IAudiences>) => {
      let dsps = "";
      params.row?.audienceActivationRecap?.forEach((recap: any) => {
        if (!recap.dealId) {
          dsps += dsps.length === 0 ? recap.dspName : " - " + recap.dspName;
        }
      });
      return dsps ?? "Unknown";
    },
  };
  columns.todo = {
    field: "toto",
    headerName: "To do",
    flex: 1,
    type: "string",
    cellClassName: "titleCell minCell",
    minWidth: 50,
    code: "todo",
    valueGetter: (params: GridValueGetterParams<IAudiences>) => {
      let isDealId = false;
      let isWaitingForEmail = false;
      params.row?.audienceActivationRecap?.forEach((recap: any) => {
        if (recap.withManualDealIdStep === true) {
          isDealId = true;
        }
        if (recap.waitingForManualStep === true) {
          isWaitingForEmail = true;
        }
      });
      const result = isDealId ? "Set DealId" : isWaitingForEmail ? "Send Email" : "Unknown";
      return result;
    },
  };

  const cols: TDataColumnModel = {
    id: columns.id,
    createdDate: columns.createdDate,
    company: columns.company,
    creator: columns.creator,
    name: columns.name,
    dsp: columns.activation,
    todo: columns.todo,
    actions: columns.actions,
  };

  cols.actions.width = 140;
  cols.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => {
          handleClick(cell.row.id);
        }}
      >
        View audience
      </Button>
    );
  };
  cols.company.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <Link to={pageUtils.getPagePathById(PageId.adminAccountView, { accountId: cell.row?.account?.id })} title={cell.row?.account?.companyName}>
        {cell.row?.account?.companyName}
      </Link>
    );
  };

  const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

  const handleClick = (audienceId: number) => {
    windowDialog.open({
      dialogId: "AudienceDealId",
      component: AudienceSetManualStep,
      componentProps: {
        audienceId: audienceId,
      },
      dialogProps: {
        className: "previewAudiencePopup",
      },
      onClose: () => {
        console.log("closed");
        refetch();
      },
    });
  };

  return (
    <MainFrame
      frameId={PageId.AudiencesManualStepList}
      breadCrumbProps={{ frameId: PageId.audienceSetManualStep }}
      loading={isLoading}
      checkPageAccess={true}
    >
      <div style={{ padding: "1.5rem", height: "100%", width: "100%" }}>
        <GridTable
          styleClass={"naked"}
          height="100%"
          data={{
            columns: audienceColumns as any,
            rows: flatData,
          }}
          noRowsText="No audiences waiting for a manual step"
          gridProps={{
            disableSelectionOnClick: true,
            autoHeight: false,
            hideFooter: true,
            columnBuffer: 30,
            columnThreshold: 10,
          }}
        />
      </div>
    </MainFrame>
  );
};
