import {Button, Divider, styled, TextField, Theme, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {objectApi} from "application/entities/dataApi";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {moneyFormatter} from "application/utils/formatters.utils";
import {useAudiencesManualStep} from "components/hooks/data/useAudiencesQuery";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import Grid from "components/ui/display/grid";
import moment from "moment";
import {PropertiesList} from "pages/Accounts/admin/AdminAccountView";
import {jsonDecodeArray} from "pages/AudienceBuilder/audienceBuilderUtils";
import {TextFieldLabel} from "pages/AudienceBuilder/components/filters/InputLinkedId";
import {useEffect, useRef, useState} from "react";
import {IAccounts} from "types";

const audienceApi = new objectApi.audiences();
const accountApi = new objectApi.accounts();

export const AudienceSetManualStep = (props: { audienceId: number }) => {
    const audienceId = Number(props.audienceId); //4386

    const [loading, setLoading] = useState<boolean>(true);
    const [audience, setAudience] = useState<IAudiences>({} as IAudiences);
    const [parentAccount, setParentAccount] = useState<IAccounts>({} as IAccounts);
    const dspsRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        if (Number(audienceId)) {
            audienceApi
                .byId(Number(audienceId))
                .then((res) => {
                    setAudience(res);
                })
                .catch((err) => {
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [audienceId]);

    useEffect(() => {
        if (audience?.account?.id) {
            accountApi.byId(Number(audience.account.id)).then((res: any) => {
                const parent = res.parentAccountAssociations[0].parentAccount;
                setParentAccount(parent);
            });
        }
    }, [audience]);

    const locations = audience?.audienceFeatures?.filter((feature) => feature.feature.code === "LOCATION")[0]?.featureValues
        ? jsonDecodeArray(audience?.audienceFeatures?.filter((feature) => feature.feature.code === "LOCATION")[0]?.featureValues)
        : [];
    locations.sort((a, b) => (a.path < b.path ? -1 : a.path > b.path ? 1 : 0));

    const dsps = audience?.audienceActivationRecap?.filter((recap) => recap.withManualDealIdStep === true || recap.waitingForManualStep === true);

    const windowDialog = useOpenDialogWindow();
    const {refetch} = useAudiencesManualStep();
    const sendEmail = (recapDspCode: string) => {
        windowDialog.open({
            dialogId: "sendEmailConfirmation",
            buttonActions: {acceptButton: true, cancelButton: true},
            title: "Confirm your action",
            description: "A confirmation email will be sent to the client. Do you want to continue?",
            onAccept: () => {
                audienceApi.setEnablementInfo({
                    audienceId: audienceId,
                    dspCode: recapDspCode,
                    isSuccess: true
                }).then((res) => {
                    refetch();
                });
            },
        });
    };

    return (
        <div>
            {audience && !loading && (
                <Content ref={dspsRef}>
                    <Typography variant="h4" style={{textAlign: "center", marginBottom: "0.5rem"}}>
                        {audience.name}
                    </Typography>

                    <Typography variant="h5" className="color-tech-grey"
                                style={{textAlign: "center", marginBottom: "1.5rem", fontSize: "1.1rem"}}>
                        {audience.account.companyName}- brand of {parentAccount?.companyName}
                    </Typography>

                    <Grid templateColumns="3fr 2fr" justifyContent="space-between" gap={30}>
                        <div>
                            {dsps?.map((recap) => {
                                let checkoutParams: any = null;
                                if (typeof recap?.checkoutParameters === "string") {
                                    checkoutParams = JSON.parse(recap.checkoutParameters);
                                }
                                return (
                                    <div key={recap.dspCode} style={{marginBottom: "1rem"}}>
                                        <Typography variant="h5" style={{textAlign: "left"}}>
                                            {recap.dspName}
                                        </Typography>
                                        <Divider style={{marginBottom: "1rem"}}/>
                                        <PropertiesList>
                                            <li>
                                                <span>Status:</span> {recap?.activationStatus ?? "not set"}
                                            </li>
                                            <li>
                                                <span>Audience ID:</span> {audienceId ?? "not set"}
                                            </li>
                                            <li>
                                                <span>Deal Name:</span> {recap?.externalAudienceName ?? "not set"}
                                            </li>
                                            <li>
                                                <span>Start Date:</span>{" "}
                                                {recap?.activationTime ? moment(new Date(recap?.activationTime)).format("D-MMM-YYYY h:mm A") : "not set"}{" "}
                                            </li>
                                            <li>
                                                <span>End Date:</span>{" "}
                                                {recap?.expirationTime ? moment(new Date(recap?.expirationTime)).format("D-MMM-YYYY h:mm A") : "not set"}
                                            </li>
                                            <li>
                                                <span>Seat Id:</span> {recap.advertiserId ?? "not set"}
                                            </li>
                                            {/* <li>
                        <span>Partner (advertiser) Id:</span> {recap.advertiserId ?? "not set"}
                      </li> */}

                                            <li>
                                                <span>Size:</span> {profilesFormatter.format(recap.profilesNumber)}
                                            </li>
                                            <li>
                                                <span>CPM:</span> {moneyFormatter(recap.cpm, {currency: audience.displayCurrency})}
                                            </li>
                                            {checkoutParams && (
                                                <>
                                                    <Typography variant="h5"
                                                                style={{textAlign: "left", fontSize: "16px"}}>
                                                        Checkout Parameters
                                                    </Typography>
                                                    {Object.keys(checkoutParams).map((key) => {
                                                        let paramValue = checkoutParams[key] === true ? "Yes" : checkoutParams[key] === false ? "No" : checkoutParams[key];
                                                        if (typeof paramValue === "object") {
                                                            paramValue = paramValue.join(", ");
                                                        }
                                                        return (
                                                            <li key={key}>
                                                                <span>{key}:</span> {paramValue}
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </PropertiesList>
                                        {recap.withManualDealIdStep &&
                                            <DealIdForm dspCode={recap.dspCode} audienceId={audienceId}/>}
                                        {!recap.withManualDealIdStep && (
                                            <div style={{marginTop: "1rem", marginBottom: "2rem", textAlign: "center"}}>
                                                <Button variant="contained" color="primary"
                                                        onClick={() => sendEmail(recap.dspCode)}>
                                                    Send Email
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <Typography variant="h5" style={{textAlign: "left"}}>
                                Audience Geographics
                            </Typography>
                            <Divider style={{marginBottom: "1rem"}}/>
                            {locations.length > 0 ? (
                                locations?.map((location) => {
                                    return <p key={location.id}>{location.path}</p>;
                                })
                            ) : (
                                <p>No Geographics for this audience</p>
                            )}
                        </div>
                    </Grid>
                </Content>
            )}
        </div>
    );
};

export const DealIdForm = (props: { dspCode: string; audienceId: number }) => {
    const {dspCode, audienceId} = props;
    const windowDialog = useOpenDialogWindow();
    const {refetch} = useAudiencesManualStep();

    const [input, setInput] = useState<string>("");

    const handleSave = () => {
        if (input.length > 3) {
            const dealId = input;
            refetch();
            windowDialog.close("AudienceDealId");

            audienceApi.setEnablementInfo({
                audienceId: audienceId,
                dealId: dealId,
                dspCode: dspCode,
                isSuccess: true
            }).then((res) => {
                refetch();
                windowDialog.close("AudienceDealId");
            });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2rem",
                marginTop: "1rem",
            }}
        >
            <TextFieldLabel>Deal ID</TextFieldLabel>
            <TextField
                id={dspCode}
                name={dspCode}
                sx={{width: "100px"}}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                inputProps={{"data-cy": "dealID-" + dspCode}}
            />
            <Button variant="contained" onClick={handleSave} disabled={input.length < 4}>
                Save
            </Button>
        </div>
    );
};

const Content = styled("div")((props: { theme: Theme }) => ({
    padding: "16px",
    overflowY: "auto",
    margin: "2rem auto",
    maxWidth: "960px",
    minWidth: "800px",
}));
