import { FormControlLabel, FormGroup, FormHelperText, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import { StyledCheckbox } from '../../../components/form/CheckBox';
import { BootstrapLabel } from '../../../components/form/InputAnna';
import { Permission } from '../../../types/Permission';
import { InfoBlock } from '../userDetails.styled';
import {EUserGrade} from '../../../application/utils/granter.utils';


interface IUserRoleTypeProps {
  userDetails: any;
  setUserDetails: (arg0: any) => void;
  userDetailsFormik?: any;
}

const useStylesCheckBoxGroup = makeStyles((theme: Theme) =>
  createStyles({
      root: {
        '&.MuiFormGroup-root': {
          paddingLeft: '30px',
        },
      },
      formGroup: {
        paddingLeft: '30px',
        flexDirection: 'row',
      },
      checkBoxLabel: {
        fontSize: 12,
        color: '#3B4559',
        fontWeight: 'normal',
      },
      // icon: {
      //   borderRadius: 2,
      //   width: 12,
      //   height: 12,
      //   boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      //   backgroundColor: '#f5f8fa',
      //   backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      //   '$root.Mui-focusVisible &': {
      //     outline: '2px auto rgba(19,124,189,.6)',
      //     outlineOffset: 2,
      //   },
      // },
      // formControl: {
      //   margin: theme.spacing(3),
      // },
    },
  ),
);

export const UserRoleType: React.FC<IUserRoleTypeProps> = ({
  userDetails,
  setUserDetails,
  userDetailsFormik,
}) => {
  const classes = useStylesCheckBoxGroup();

  const [checkedRole, setCheckedRole] = React.useState<string>(userDetails.role as string);

  useEffect(() => {
    userDetails.role && setCheckedRole(userDetails.role as string);
  }, [userDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedRole(event.target.name);
    setUserDetails({ ...userDetails, role: event.target.name as string });

  };

  const error = Boolean(!checkedRole);
  const {t}         = useTranslation();

  return (
    <>
      <ClientTypeBlock>
        <div className={classes.root} data-cy={'role-type-selection'}>
          <div>
            <BootstrapLabel required={true} htmlFor="form-group">
              {t('account.userRoleType.roleType')}
            </BootstrapLabel>
            <FormGroup id="form-group" className={classes.formGroup}>
              <FormControlLabel
                control={<StyledCheckbox
                  checked={EUserGrade.ADMINISTRATOR === checkedRole}
                  onChange={handleChange}
                  name={EUserGrade.ADMINISTRATOR}
                  value={EUserGrade.ADMINISTRATOR}
                />}
                label={
                  <>
                    <Typography component="span" className={classes.checkBoxLabel}>
                      {t('account.userRoleType.administrator')}
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                control={<StyledCheckbox
                  checked={Permission.Creator === checkedRole}
                  onChange={handleChange}
                  name={Permission.Creator}
                />}
                label={
                  <>
                    <Typography component="span" className={classes.checkBoxLabel}>
                      {t('account.userRoleType.creator')}
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                control={<StyledCheckbox
                  checked={Permission.Contributor === checkedRole}
                  onChange={handleChange}
                  name={Permission.Contributor}
                />}
                label={
                  <>
                    <Typography component="span" className={classes.checkBoxLabel}>
                      {t('account.userRoleType.contributor')}
                    </Typography>
                  </>
                }
              />
            </FormGroup>
            {error &&
            <FormHelperText
              style={{ color: 'red', fontSize: 13 }}
            >
              {t('account.textAndWarnings.chooseMinOne')}
            </FormHelperText>}
          </div>
        </div>


      </ClientTypeBlock>
    </>
  );
};


const ClientTypeBlock = styled(InfoBlock)`
  height: 141px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  margin-bottom: 0;
  border-radius: 0;
`;
