import React, {FunctionComponent} from 'react';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {pageUtils} from '../../../application/pages/pages.utils';
import {PageId} from '../../../application/pages/pages.config';
import {Link} from 'react-router-dom';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {DemoModeWrapper} from '../../../components/controls/DemoModeWrapper';

interface IBrandNavigationProps {
    brand: IAccounts;
    companyId?: number;
};


export const BrandNavigation: FunctionComponent<IBrandNavigationProps> = (props: IBrandNavigationProps) => {
    const {brand, companyId} = props;

    const userLink = pageUtils.getPagePathById(PageId.brandAgentList, {
        brandId: brand.id,
        companyId: brand.parentCompanyId
    });
    const editLink = pageUtils.getPagePathById(PageId.brandUpdate, {
        brandId: brand.id,
        companyId: brand.parentCompanyId
    });
    const createLink = pageUtils.getPagePathById(PageId.brandCreate, {
        brandId: brand.id,
        companyId: brand.parentCompanyId
    });
    const viewLink = pageUtils.getPagePathById(PageId.brandView, {brandId: brand.id, companyId: brand.parentCompanyId});
    const companyLink = pageUtils.getPagePathById(PageId.companyDashboard, {companyId: companyId ?? brand.parentCompanyId});
    const dashboard = pageUtils.getPagePathById(PageId.brandDashboard, {
        brandId: brand.id,
        companyId: companyId ?? brand.parentCompanyId
    });
    const audiences = pageUtils.getPagePathById(PageId.audienceLibrary, {
        brandId: brand.id,
        companyId: companyId ?? brand.parentCompanyId
    });

    return (Boolean(brand.id) && AUTHUser.isEvorraAdmin()) ? <DemoModeWrapper>
        <div className={'pad-2 flex-h flex-align-middle gap-small'}>
            {/*<Link to={dashboard}>Dashboard</Link>*/}
            <Link to={userLink}>Manage users</Link>
            <Link to={editLink}>Brand settings</Link>
            {/*<Link to={viewLink}>View brand</Link>
       <Link to={companyLink}>Back to Company dashboard</Link>*/}
        </div>
    </DemoModeWrapper> : null;
};
