import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppToast } from "../../components/live/AppToast";
import { useQuery, useQueryClient } from "react-query";
import { objectApi } from "../../application/entities/dataApi";
import { IDspChannels } from "../../application/entities/dataTypes/dspChannels";
import { IDspEnablementOptions } from "../../application/entities/dataTypes/dspEnablementOptions";
import { MenuLabel } from "../../components/ui/MenuLabel";
import { IDspParameters } from "../../application/entities/dataTypes/dspParameters";
import { StyledCheckbox } from "../../components/form/CheckBox";
import { AppIcon } from "../../components/ui/AppIcon";
import { providerIconList } from "../../assets/providerIcons";
import { dataUtils } from "../../application/utils/dataState.utils";
import { useAllDspChannels, useSelectedDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { getDspAdditionalInfo, getUnselectedDsps } from "application/utils/dsp.utils";

interface DspAccountParameterWizardProps {
  accountId: number;
  isOpen: boolean;
  onValidate: () => void;
}

// DV360   565562
export const DspAccountParameterWizard = (props: DspAccountParameterWizardProps) => {
  const { accountId, isOpen, onValidate = () => {} } = props;

  const myRef = React.useRef<any>(null);
  const queryClient = useQueryClient();

  const accountsApi = new objectApi.accounts();

  const [parametersError, setParametersError] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const appToast = useAppToast();

  const [dspParameters, setDspParameters] = React.useState<Record<string, any>>({ dspParameters: {} });
  const [chosenSSP, setChosenSSP] = React.useState<string[]>([]);
  const [seatName, setSeatName] = React.useState<string>("");
  const [editMode, setEditMode] = React.useState<boolean>(false);

  const [channelsParams, setChannelsParams] = React.useState<Record<string, IDspChannels>>({});
  const [activeChannelCode, setActiveChannelCode] = React.useState<string>("");
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [loadingStep, setLoadingStep] = React.useState<boolean>(false);

  const allDsps = useAllDspChannels(+accountId);

  const dspSelected = useSelectedDspChannels(+accountId);

  const unselectedDsps = getUnselectedDsps(allDsps?.data ?? [], dspSelected?.data ?? []);

  const IconLoadingLogs = loadingStep ? <CircularProgress size={16} /> : <></>;

  React.useEffect(() => {
    setEditMode(isOpen);
    if (isOpen && myRef.current) myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    if (!isOpen) {
      setDspParameters({ dspParameters: {} });
      setChosenSSP([]);
      setSeatName("");
      setChannelsParams({});
      setActiveChannelCode("");
      setActiveStep(1);
      setLoadingStep(false);
    }
  }, [isOpen]);

  function setChannelParameters(channel: IDspChannels) {
    setChosenSSP(channel.dspEnablementOptions.filter((x: IDspEnablementOptions) => x.isSsp).map((x) => x.code) ?? []);
    setActiveChannelCode(channel.code);
    setChannelsParams((curr) => {
      return {
        ...curr,
        [channel.code]: channel,
      };
    });
  }

  function deleteChannelParameters(channel: IDspChannels) {
    setChannelsParams((curr) => {
      delete curr?.[channel.code];
      return { ...curr };
    });
  }

  function onSubmit(data: any) {}

  function handleChange(data: any) {
    // googleads 7109681239  // meta act_1032623223976056 // dv360 728357646 + partnerId 565562 // amazon 577013066315566137
    setDspParameters(data.dspParameters);
  }
  /** Step 1 Select SSP */
  function selectSSP(code: string) {
    if (chosenSSP.indexOf(code) === -1) {
      setChosenSSP((curr) => [...curr, code]);
    } else {
      setChosenSSP((curr) => [...curr].filter((x) => x !== code));
    }
  }

  const checkDspChannelsSelection = (accountId: number, args: any) => {
    return accountsApi
      .checkDspChannelsSelection(accountId, args)
      .then((res) => {
        if (Object.values(res.data.dspOptionsStatus).includes("ERROR")) {
          setParametersError(true);
          return "error";
        }
      })
      .catch((e) => {
        appToast.open({
          toastId: "globalToast",
          severity: "error",
          message: "Configuration error",
        });
      });
  };

  /** handle direction for navigation */
  function goto(dir: "previous" | "next") {
    switch (dir) {
      case "previous":
        setParametersError(false);
        setActiveStep(activeStep - 1);
        break;
      case "next":
        const args = {
          dspChannelCode: activeChannelCode,
          parameterValues: dspParameters[activeChannelCode],
        };

        if (activeStep === 1) {
          setActiveStep(activeStep + 1);
        } else if (activeStep === 2) {
          checkDspChannelsSelection(accountId, args).then((res) => {
            if (res === "error") {
              setLoadingStep(false);
              return;
            }
            if (hasStep3 && nbSteps === 3) {
              setActiveStep(activeStep + 1);
              setLoadingStep(false);
            } else {
              registerAA();
            }
          });
          break;
        } else {
          registerAA();
        }
    }

    /** createUpdate DspChannelsSelections and reset ui */
    function registerAA() {
      const args = {
        isActive: true,
        dspChannelCode: activeChannelCode,
        dspEnablementOptions: chosenSSP,
        parameterValues: dspParameters[activeChannelCode],
        seatName: seatName?.length ? seatName : "Untitled",
      };
      setLoadingStep(true);
      accountsApi
        .createUpdateDspChannelsSelections(accountId, args)
        .then((res) => {
          appToast.open({
            toastId: "globalToast",
            severity: "success",
            message: "The Enablement Channel was successfully created",
          });
          setChosenSSP([]);
          setChannelsParams({});
          setActiveChannelCode("");
          setActiveStep(1);
          setEditMode(false);
          dspSelected.refetch();
          queryClient.invalidateQueries("dspChannelsSelections");

          onValidate();
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoadingStep(false);
        });
    }
  }

  let hasStep2 =
    !channelsParams?.[activeChannelCode] ||
    (channelsParams[activeChannelCode]?.dspParameters && channelsParams[activeChannelCode].dspParameters.length !== 0);
  let hasStep3 = channelsParams?.[activeChannelCode] ? channelsParams?.[activeChannelCode].dspEnablementOptions.filter((x) => x.isSsp).length : 1;
  let nbSteps = 1 + (hasStep2 ? 1 : 0) + (hasStep3 ? 1 : 0);

  let steps = [{ label: t`dspChannelParameters.wizardStep` + 1 + t`dspChannelParameters.wizardStepChannel` }];
  if (hasStep2) steps.push({ label: t`dspChannelParameters.wizardStep` + 2 + t`dspChannelParameters.wizardStepIdentifier` });
  if (hasStep3) steps.push({ label: t`dspChannelParameters.wizardStep` + (hasStep2 ? 3 : 2) + t`dspChannelParameters.wizardStepSSPConfirm` });

  const InfoBox = (props: { title: string; desc?: string; style?: Record<string, any> }) => (
    <div style={props?.style}>
      <Typography style={{ fontWeight: "bold" }}>{props.title}</Typography>
      <Typography sx={{ fontSize: "10px", py: 1, minHeight: "46px" }}>{props.desc}</Typography>
      <Divider sx={{ m: 1 }} />
    </div>
  );

  const newtDisabled = activeStep === 1 ? Object.values(channelsParams).length === 0 : activeStep === 2 ? !isValid : false;
  const disabledChannelKeys = (allDsps?.data ?? [])
    .filter((x: IDspChannels) => dataUtils.getObjectItemById(dspSelected?.data ?? [], x.code, "dspChannel.code").length > 0)
    .map((x: IDspChannels) => x.code);
  const nextText = activeStep === nbSteps ? "Save" : "Next";
  const nextDesc = activeStep === nbSteps ? t`dspChannelParameters.wizardButtonFinalize` : "Step " + activeStep;

  return (
    <Box ref={myRef}>
      <Collapse in={Boolean(editMode)} style={{ transformOrigin: "0 0 0" }}>
        <>
          <Typography className={" pad-l-3"} sx={{ my: 3 }} variant={"h4"}>{t`dspChannelParameters.addEnablementChannel`}</Typography>
          <DspChannelContainer className={"flex-v"} data-cy={"add-channel-wizard"}>
            <Box sx={{ p: 2 }} className={"flex-h flex-align-middle gap-large border-b"}>
              <Typography>{t`dspChannelParameters.seatName`}</Typography>
              <Box className={"flex-main"}>
                <TextField
                  data-cy={"seat-name"}
                  value={seatName}
                  onChange={(event) => {
                    setSeatName(event.target.value);
                  }}
                  sx={{ width: "290px" }}
                />
              </Box>
              <div>
                <AppIcon
                  color={"#3B4559"}
                  onClick={() => {
                    setEditMode(false);
                    onValidate();
                  }}
                  fontSize={"small"}
                  icon={"Close"}
                />
              </div>
            </Box>
            <Box className={"flex-h flex-main"}>
              <DspChannelMenu>
                <Stepper activeStep={activeStep - 1} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel icon={<AppIcon icon={activeStep > index ? "StepDone" : "StepUnDone"} />}>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </DspChannelMenu>
              <Box className={"flex-v flex-main gap-small"} sx={{ p: 2 }}>
                {activeStep === 1 && (
                  <Box className={"flex-h flex-main gap-small"}>
                    <Box className={"flex-main"}>
                      <InfoBox
                        title={t`dspChannelParameters.wizardSelectChannelTitle`}
                        desc={t`dspChannelParameters.wizardSelectChannelDescription`}
                      />
                      <Autocomplete
                        options={(unselectedDsps ?? []) as any}
                        getOptionDisabled={(option: IDspChannels) => disabledChannelKeys.indexOf(option.code) !== -1}
                        autoHighlight
                        disableClearable={true}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event: any, newValue: IDspChannels) => {
                          if (newValue) setChannelParameters(newValue);
                        }}
                        disabled={Object.values(channelsParams).length > 0}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            data-cy={"select-channel"}
                            fullWidth={false}
                            sx={{ width: "216px" }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <div style={{ marginLeft: "0.5rem" }}>
                                  <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} icon={"SearchIcon"} />
                                </div>
                              ),
                            }}
                            inputProps={{
                              ...params.inputProps,

                              placeholder: t(`dspChannelParameters.addChannelPlaceHolder`),
                              autoComplete: "off",
                            }}
                          />
                        )}
                      />
                      <br />
                      {Object.values(channelsParams).map((param: IDspChannels, idx: any) => {
                        return (
                          <div key={idx}>
                            <MenuLabel
                              text={param.name}
                              onClick={() => {
                                setActiveChannelCode(param.code);
                              }}
                              onRemove={() => {
                                deleteChannelParameters(param);
                              }}
                            />
                          </div>
                        );
                      })}
                    </Box>
                    <Box className={"flex-main"}>
                      <InfoBox
                        title={t(`dspChannelParameters.wizardSelectOptionsTitle`)}
                        desc={t(`dspChannelParameters.wizardSelectOptionsDescription`)}
                        style={{ opacity: Object.values(channelsParams).length ? "1" : "0.4" }}
                      />
                      <Box className={"flex-v gap-small"}>
                        {Object.values(channelsParams).map((channel: IDspChannels, idx: any) => {
                          return channel.dspEnablementOptions.map((param: IDspEnablementOptions, idx: any) => {
                            return (
                              <Box data-cy={"dspEnablementOptionsName"} data-cy-testid={param.code} key={idx} sx={{ py: 0.25 }}>
                                {getDspAdditionalInfo(param.code)?.name ?? param.name}
                                {getDspAdditionalInfo(param.code)?.restriction && (
                                  <span className="color-tech-grey" style={{ fontStyle: "italic" }}>
                                    {" "}
                                    - {getDspAdditionalInfo(param.code)?.restriction}
                                  </span>
                                )}
                              </Box>
                            );
                          });
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
                {activeStep === 2 && hasStep2 && (
                  <Box className={"flex-v flex-main gap-small"}>
                    <div className={"pad-tb flex-h flex-align-middle gap-small border-b"}>
                      <div>
                        <AppIcon display={"block"} fontSize={"small"} icon={providerIconList[activeChannelCode]} />
                      </div>
                      <div className={"flex-main"}>{channelsParams?.[activeChannelCode]?.name}</div>
                    </div>
                    <form
                      id={"form" + activeChannelCode}
                      name={"form" + activeChannelCode}
                      onChange={handleSubmit(handleChange)}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={"flex-v"}>
                        {Boolean(activeChannelCode) &&
                          channelsParams?.[activeChannelCode].dspParameters.map((param: IDspParameters, idx: any) => {
                            // @ts-ignore
                            const k: string = t(`dspChannelParameters.${param.description}`) as string;
                            return (
                              <Box sx={{ order: param.order }} key={idx}>
                                <Box sx={{ py: 1 }}>
                                  <Typography style={{ fontWeight: "bold" }}>{param.parameterName}</Typography>
                                </Box>
                                <Box className={"flex-h flex-align-middle gap-medium"}>
                                  <TextField
                                    error={parametersError}
                                    {...register("dspParameters[" + activeChannelCode + "][" + param.code + "]", { minLength: 2 })}
                                    onChange={() => {
                                      setParametersError(false);
                                    }}
                                    name={"dspParameters[" + activeChannelCode + "][" + param.code + "]"}
                                    data-cy={param.code}
                                  />
                                  {Boolean(param.description) && (
                                    <Box className={"flex-h flex-align-middle gap-medium"}>
                                      <AppIcon fontSize={"small"} icon={"Info"} />
                                      <Typography>{k}</Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                      </div>
                      {Boolean(parametersError) && (
                        <div className={"pad"}>
                          <Typography color={"error"}>{t(`dspChannelParameters.wizardValidationError`)}</Typography>
                        </div>
                      )}
                    </form>
                  </Box>
                )}
                {((activeStep === 2 && !hasStep2) || activeStep === 3) && (
                  <Box className={"flex-v flex-main gap-small"}>
                    <div className={"pad-tb flex-h flex-align-middle gap-small border-b"}>
                      <div>
                        <AppIcon display={"block"} fontSize={"small"} icon={providerIconList[activeChannelCode]} />
                      </div>
                      <div className={"flex-main"}>{channelsParams?.[activeChannelCode]?.name}</div>
                    </div>
                    <InfoBox title={t(`dspChannelParameters.wizardSelectSspTitle`)} desc={t(`dspChannelParameters.wizardSelectSspDescription`)} />
                    {Object.values(channelsParams).map((channel: IDspChannels, idx: any) => {
                      return channel.dspEnablementOptions
                        .filter((x) => x.isSsp)
                        .map((param: IDspEnablementOptions, idx: any) => {
                          return (
                            <Box
                              onClick={() => {
                                selectSSP(param.code);
                              }}
                              className={"flex-h flex-align-middle"}
                              key={idx}
                              sx={{ py: 0.25 }}
                            >
                              <div className={"flex-main"}>
                                {getDspAdditionalInfo(param.code)?.name ?? param.name}
                                {getDspAdditionalInfo(param.code)?.restriction && (
                                  <span className="color-tech-grey" style={{ fontStyle: "italic" }}>
                                    {" "}
                                    - {getDspAdditionalInfo(param.code)?.restriction}
                                  </span>
                                )}
                              </div>
                              <div>
                                <StyledCheckbox checked={Boolean(chosenSSP.indexOf(param.code) !== -1)} />
                              </div>
                            </Box>
                          );
                        });
                    })}
                  </Box>
                )}
                <Box className={"flex-h flex-align-middle gap-small"}>
                  <Button
                    disabled={activeStep < 2}
                    variant={"contained"}
                    onClick={() => {
                      goto("previous");
                    }}
                  >
                    Back
                  </Button>
                  <div className={"border-b w-medium"} style={{ width: "32px" }}></div>
                  <div style={{ color: "#B3BCCF" }}>Step {activeStep - 1}</div>
                  <div className={"flex-main"} />
                  <div style={{ color: "#B3BCCF" }}>{nextDesc}</div>
                  <div className={"border-b w-medium"} style={{ width: "32px" }}></div>
                  <Button
                    startIcon={IconLoadingLogs}
                    onClick={() => {
                      goto("next");
                    }}
                    variant={"contained"}
                    disabled={newtDisabled}
                    data-cy={"next-step"}
                  >
                    {nextText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DspChannelContainer>
        </>
      </Collapse>
    </Box>
  );
};

interface IDspChannelParametersContainerProps {}

const DspChannelContainer = styled(Box)<IDspChannelParametersContainerProps>((props) => ({
  background: "#fff",
  borderRadius: "12px",
  boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
  minHeight: "400px",
  maxWidth: "1024px",
}));
const DspChannelMenu = styled(Box)<IDspChannelParametersContainerProps>((props) => ({
  width: "300px",
  background: "#DBE0EB",
  padding: "2rem",
}));
