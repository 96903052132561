import { Typography, useTheme } from "@mui/material";
import React from "react";
import { Columns, GridItem } from "components/ui/AppElements";
import { apiService } from "application/entities/api/apiService";
import { AppIcon } from "components/ui/AppIcon";
import { iconMapping } from "../AppToast";
import { Client, Message } from "@stomp/stompjs";
import { devApi } from "_configuration/api.config";
import { authService } from "application/services/auth.service";

export const MessageBanner = () => {
  const { palette } = useTheme();

  const [bannerDisplayed, setBannerDisplayed] = React.useState<boolean>(true);
  const [message, setMessage] = React.useState<string>("");
  const [oldMessage, setOldMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<string>("error");

  // Needed to access value from callback
  const oldMessageRef = React.useRef<string>();
  oldMessageRef.current = oldMessage;

  const colorMapping: Record<string, string> = {
    error: palette.error.main,
    success: palette.success.main,
    info: palette.info.main,
    warning: palette.warning.main,
  };

  let stompClient: Client;

  const handleClose = () => {
    // Close the banner and don't redisplay it until a different message is received
    setBannerDisplayed(false);
    setOldMessage(message);
  };

  async function GetLastAlertMessage() {
    let response = await apiService
      .entity("alertmessages/search/findLastAlertMessage")
      .fetch()
      .then((inres) => {
        return inres;
      })
      .catch((err) => {
        return [];
      });

    let lastMsg = response?.data?._embedded?.alertmessages ?? [];
    DisplayMessage(lastMsg);
  }

  async function ActivateStompClient() {
    let session = await authService.getCurrentSession();
    const token = session.getIdToken().getJwtToken();

    const stompConfig = {
      brokerURL: `${devApi}/evorra-websocket`.replace("http", "ws"),

      // Uncomment this line for debug messages
      //debug: console.log,

      connectHeaders: { Authorization: token },

      onConnect: function (frame: any) {
        stompClient.subscribe(`/topic/alertmessage`, HandleStompMessage);
      },
    };

    stompClient = new Client(stompConfig);
    stompClient.activate();
  }

  function HandleStompMessage(response: Message) {
    let rawdata = response?.body;
    let data = rawdata ? JSON.parse(rawdata) : null;
    DisplayMessage(data);
  }

  function DisplayMessage(data: any[]) {
    let msg = data[0]?.message;
    setMessage(msg);

    // When the message is different reset the flag to display the banner
    if (msg && msg !== oldMessageRef.current) {
      setBannerDisplayed(true);
    }

    // Set the message type or default on error
    let msgType = data[0]?.messageType?.toLowerCase() || "error";
    setMessageType(msgType);
  }

  React.useEffect(() => {
    GetLastAlertMessage();
    ActivateStompClient();

    return () => {
      stompClient.deactivate();
    };
  }, []);

  return (
    <>
      {bannerDisplayed && message && (
        <Columns
          wrap={"nowrap"}
          style={{
            backgroundColor: colorMapping[messageType] || palette.error.main,
            display: "inline-flex",
            width: "100%",
            margin: "0px auto",
            position: "relative",
            justifyContent: "center",
            height: "auto",
          }}
        >
          <GridItem style={{ padding: "1rem", color: "white" }}>
            <AppIcon icon={iconMapping[messageType]} color={"white"} />
          </GridItem>
          <GridItem style={{ padding: "1rem", color: "white" }}>
            <Typography variant={"h6"}>{message}</Typography>
          </GridItem>
          <GridItem style={{ padding: "1rem", alignSelf: "flex-start" }}>
            <AppIcon icon={"Close"} onClick={handleClose} color={"white"} />
          </GridItem>
        </Columns>
      )}
    </>
  );
};
