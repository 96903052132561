import * as React from 'react';
import {Box} from '@mui/material';
import {ICompanyUsers} from '../../../application/entities/dataTypes/companyUser';
import {GlobalAvatar} from '../../../components/ui/GlobalAvatar';
import {useCompanyUsersQuery} from '../../../components/hooks/data/useCompanyUsersQuery';

export interface IAccountUsersListProps {
  accountId: number;
  companyUsers?: ICompanyUsers[];
  role?: string;
}

export const CompanyRoles = (props: IAccountUsersListProps) => {
  const {accountId}                  = props;
  // All Users, query for company screen
  const accountAllUsersQuery         = useCompanyUsersQuery(accountId, {
    params: {},
  }, {enabled: Boolean(accountId)});
  const allUserData: ICompanyUsers[] = accountAllUsersQuery?.data?.data?._embedded?.companyUsers ?? [];
  
  
  return (
    <div>
      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '8px'}} data-cy={'team-list-avatar'}>
        {allUserData.map((user: ICompanyUsers ) => <GlobalAvatar
          key={user.id}
          user={user}
          size={'28px'}
          fontSize={'15px'}
        />)}
      </Box>
    </div>
  );
  
};