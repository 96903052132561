import {Button} from '@mui/material';
import React, { Fragment } from 'react';
import {useTranslation} from 'react-i18next';
import {objectApi} from '../../application/entities/dataApi';
import {IDspChannelsSelections} from '../../application/entities/dataTypes/dspUserSelections';
import {AppSwitch} from '../../components/form/AppSwitch';
import {AppIcon} from '../../components/ui/AppIcon';
import {providerIconList} from '../../assets/providerIcons';
import {useOpenDialogWindow} from '../../components/live/DialogWindow';
import {DspAccountDspSelectionEdit} from './DspAccountDspSelectionEdit';
import {dataUtils} from '../../application/utils/dataState.utils';
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import { useSelectedDspChannels } from 'components/hooks/data/useDspChannelsQuery';
import { getDspAdditionalInfo } from 'application/utils/dsp.utils';
import { useFeatureFlag } from 'stores/featureFlags.store';

interface DspChannelSelectionTableProps {
  accountId: number;
}


export const DspAccountChannelSelectionTable = (props: DspChannelSelectionTableProps) => {
  const {accountId}              = props;
  const dspChannelsSelectionsApi = new objectApi.dspChannelsSelections();
  
  
  const {t}      = useTranslation();
  const featureFlags = useFeatureFlag(st => st.featureFlags)
  
  /** channelSelectionId beeing in edit mode in the table */
  const [editMode, setEditMode] = React.useState<number | undefined>(undefined);
  
  const dspSelection = useSelectedDspChannels(+accountId)

  if(featureFlags?.dsp_facebook !== true){
    dspSelection.data = dspSelection.data?.filter(x => x.dspChannel.code !== 'facebook')
  }
  
  const dialogWindow = useOpenDialogWindow();
 
  function updateDspSelection(channelSelection: IDspChannelsSelections, checked: boolean) {
    dspChannelsSelectionsApi.update(channelSelection.id, {isActive: checked}).then(res => {
      dspSelection.refetch();
    });
  }


  function deleteChannelsSelection(channelSelection: IDspChannelsSelections) {
    dialogWindow.open({
      dialogId     : 'channelSelectionDelete',
      buttonActions: {acceptButton: true, cancelButton: true},
      title        : 'Delete this Channel Selection ?',
      description  : 'You will not be able to use it',
      icon         : 'Trash',
      onAccept     : onDeleteAccept, dialogArgs: channelSelection
    });
  }
  
  async function onDeleteAccept(args: any, channel: IDspChannelsSelections) {
    try {
      await dspChannelsSelectionsApi.delete(channel.id);
      dspSelection.refetch();
    } catch (error) {
    }
  }
  
  return <table className={'dspTable'} data-cy={'dspTable'} data-cy-table-length={dspSelection?.data ? dspSelection.data.length : 0} style={{width:'100%',maxWidth: '1024px', borderCollapse:'collapse'}}>
    <thead>
    <tr>
      <td className={' pad-l-3'}>
        <div className={'flex-h flex-align-middle gap-small'}>Active</div>
      </td>
      <td>
        <div className={'flex-h flex-align-middle gap-small'}>Enablement Channel</div>
      </td>
      <td>
        <div className={'flex-h flex-align-middle gap-small'}>
          Seat Name

          </div>
      </td>
      <td>
        <div className={'flex-h flex-align-middle gap-small'}>Advertiser ID
          <AppTip  title={'Advertiser Id'} text={t`dsp.dspTable.enablementChannelIdTip`} />
        </div>
      </td>
      <td>
        <div className={'flex-h flex-align-middle gap-small'}>Enablement Methods</div>
      </td>
      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody>
    {(dspSelection?.data ?? []).map((channel: IDspChannelsSelections) => {
      const tt = dataUtils.getObjectByFieldValue(channel.dspChannel.dspParameters ?? [], 'parameterName', 'Advertiser ID')[0] ?? {};
      return <Fragment key={channel.id}>
        {editMode !== channel.id && <tr data-cy={channel.isActive ? 'dsp-item-active': 'dsp-item'} key={'r' + channel.id}>
            <td data-cy={channel.dspChannel.code} className={' pad-l-3'}><AppSwitch data-cy={channel.dspChannel.code +'-switch'} onChange={(event, checked) => {updateDspSelection(channel, checked);}} label={''} checked={channel.isActive}/></td>
            <td>
                <div className={'flex-h flex-align-middle gap-small'}>
                    <div className={'iconCircle'}><AppIcon fontSize={'small'} icon={providerIconList[channel.dspChannel.code]}/></div>
                    <div>{getDspAdditionalInfo(channel.dspChannel.code)?.name ?? channel.dspChannel.name}</div>
                    {getDspAdditionalInfo(channel.dspChannel.code)?.restriction && <AppTip  title="Restriction" text={getDspAdditionalInfo(channel.dspChannel.code)!.restriction!} />}

                </div>
            </td>
            <td>
              {channel.seatName}
              </td>
            <td>{channel.parameterValues?.[tt?.code]}</td>
            <td>
              {channel.dspEnablementOptions.map((enablementOption)=>{
                if(enablementOption.isSsp){
                  return <div className={'flex-h flex-align-middle gap-small'} style={{paddingBlock:'.15rem'}} key={enablementOption.id}>
                    {getDspAdditionalInfo(enablementOption.code)?.name ?? enablementOption.name}
                    {getDspAdditionalInfo(enablementOption.code)?.restriction && <AppTip  title="Restriction" text={getDspAdditionalInfo(enablementOption.code)!.restriction!} />}
                    </div>
                }else{
                  return ''
                }
              })}
            </td>
            <td width={55}><Button data-cy={'edit-dsp'} size={'small'} variant={'outlined'} onClick={() => {setEditMode(channel.id);}}>Edit</Button></td>
            <td width={70}><Button data-cy={'delete-dsp'} color={'error'} size={'small'} variant={'outlined'} onClick={() => {deleteChannelsSelection(channel);}}>Delete</Button></td>
        </tr>}
        {editMode === channel.id && <tr data-cy={'dsp-item'} key={'d' + channel.id}>
            <td colSpan={7}>
                <DspAccountDspSelectionEdit
                    accountId={+accountId}
                    dspChannelsSelectionsId={channel.id}
                    onClose={() => {
                      setEditMode(0);
                      dspSelection.refetch();
                    }}/>
            </td>
        </tr>}
      </Fragment>;
    })}
    </tbody>
  </table>;
};


