import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {buttonLabels} from 'components/form/Buttons/buttonLabels';
import {H4} from 'components/ui/AppElements';
import {objectApi} from '../../../application/entities/dataApi';
import {dataObject} from '../../../application/entities/dataObjects/DataObjectClass';
import {pageUtils} from '../../../application/pages/pages.utils';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {userValidationSchema} from '../../../application/validation';
import {ICompanyUsers} from '../../../types';
import {ColorButton, GhostButton} from '../../../components/form/Buttons';
import {useAppToast} from '../../../components/live/AppToast';
import {MainFrame} from '../../../components/templates/MainFrame';
import {ButtonBlockEditUser} from './ButtonBlockEditUser';
import {UserForm} from '../Form/UserForm';
import styled from '@emotion/styled';
import {FooterBlock, InfoBlockTitle, InfoContainer} from '../../../components/templates/InfoBlock';
import {useAuthActions} from '../../../redux/slices/auth.slices';
import {useUserQuery} from '../../../components/hooks/data/useUserQuery';
import {EPermission} from '../../../application/utils/granter.utils';
import {PageId} from '../../../application/pages/pages.config';
import {GlobalAvatar} from 'components/ui/GlobalAvatar';


interface ICreateAccountProps {
}


export const UserEdit: React.FC<ICreateAccountProps> = () => {

    const navigate = useNavigate();
    const appToast = useAppToast();
    const authActions = useAuthActions();
    const {t} = useTranslation();
    const params = useParams<{ userId: string }>() as { userId: string };
    const userId = params.userId;

    const emptyUserObject = dataObject.getDataObject('companyUsers');
    const [userData, setUserData] = useState<Partial<ICompanyUsers>>(emptyUserObject);

    const userQuery = useUserQuery({userId: +userId});
    const userApi = new objectApi.companyUsers();
    // CMVP-1718
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    useEffect(() => {
        if (userQuery.data) {
            setUserData(userQuery.data);
            // set formik rules
            userDetailsFormik.setValues({...userQuery.data, accountId: userQuery.data.accountId});
        }
    }, [userQuery.data]);

    useEffect(() => {
        // CMVP-1718 : guard for unresolved situations where userQuery.isLoading is defined seconds after the client initial load
        if (userQuery.isLoading !== undefined) setIsLoading(userQuery.isLoading);
    }, [userQuery.isLoading]);

    const handleEditUser = (values: Partial<ICompanyUsers>) => {

        if (values && values.email && values.email.length > 6 && values.firstName && values.firstName.length > 0) {

            delete values.account;
            delete values.authorities;

            if (values.id) {
                userApi.update(values.id, {...values})
                    .then((userRes) => {
                        // update user state with response
                        userQuery.refetch();
                        // refresh auth if self account
                        if (userRes?.id === AUTHUser.userId) {
                            authActions.doRefreshUser();
                        }
                        appToast.open({
                            toastId: 'manageAccount',
                            severity: 'success',
                            message: t('message.user.updatedSuccess'),
                        });

                        const backLink = pageUtils.getPagePathById(PageId.userProfileView, {userId: userData.id});
                        navigate(backLink);
                    })
                    .catch((err) => {
                        const message = err?.response?.data?.error?.message;
                        appToast.open({
                            toastId: 'manageAccount',
                            severity: 'error',
                            message: message ?? t('labels.error'),
                        });
                    });
            }
        }
    };

    const userDetailsFormik = useFormik({
        initialValues: userData,
        validationSchema: userValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: values => {
            const castValues = userValidationSchema.cast(values);
            userData.role && handleEditUser({...castValues, role: userData.role});
        },
    });

    const handleCancel = () => {
        const backLink = pageUtils.getPagePathById(PageId.userProfileView, {userId: userData.id});
        navigate(backLink);
    };

    const headerContent = <div className={'flex-h flex-align-middle pad-tb'}>
        <Box sx={{px: 2}}>
            <GlobalAvatar
                user={userData}
                isPending={userData.status !== 'ACTIVATED'}
                size={'34px'}
                fontSize={'18px'}
            />
        </Box>
        <Box>
            <H4>{userData.firstName} {userData.lastName}</H4>
            <p>{`User ID#: ${userData.id}`}</p>
            <p>{userData?.account?.companyName}, {userData?.account?.country}`</p>
        </Box>
    </div>;

    const headerButtons = <>
        <ButtonBlockEditUser
            userDetails={userData}
            setUserDetails={setUserData}
        />
    </>;

    //  CMVP-1703 Data Client Admins Can Not Delete 'edit' A Team Member
    // @ts-ignore  // CMVP-1777 Oh Snap message in User Settings on app.evorra.com
    const showFrame = AUTHUser.isEvorraAdmin() || (AUTHUser.accountId === userData?.account?.id && (AUTHUser.userId === userData.id || AUTHUser.hasRole(EPermission.COMPANYADMIN)))

    return (
        <MainFrame
            backendError={userQuery?.error}
            restrict404={!showFrame}
            frameId={'user-edit'}
            headerProps={{
                hideHeaderDefault: true,
                hideHeaderIcon: true,
                headerButtons: headerButtons,
            }}
            headerContent={headerContent}
            loading={isLoading}
        >
            <form style={{height: '100%'}} onSubmit={userDetailsFormik.handleSubmit}>
                <Content>
                    <DetailsBox>
                        <InfoContainer>
                            <InfoBlockTitle>
                                {t('account.userDetails.userDetails')}
                            </InfoBlockTitle>
                            <UserForm
                                dataFormik={userDetailsFormik}
                                userDetails={userData}
                                setUserDetails={setUserData}
                            />
                        </InfoContainer>
                    </DetailsBox>
                    <FooterBlock>
                        <ColorButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            data-cy={'submit-btn'}
                        >
                            {buttonLabels.save}
                        </ColorButton>
                        <GhostButton
                            onClick={() => handleCancel()}
                            variant="contained"
                            color="primary"
                        >
                            {buttonLabels.cancel}
                        </GhostButton>
                    </FooterBlock>
                </Content>

            </form>

        </MainFrame>
    )
        ;
};


const Content = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  padding: 0 20px 0px 20px;
  height: 100%;
`;

const DetailsBox = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;