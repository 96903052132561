import * as React from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import { ICompanyUsers } from "../../../application/entities/dataTypes/companyUser";
import { AUTHUser } from "../../../application/utils/AuthUser";
import { objectApi } from "../../../application/entities/dataApi";
import { GlobalAvatar } from "../../../components/ui/GlobalAvatar";
import { IAccounts } from "application/entities/dataTypes/accounts";

export interface IAccountUsersListProps {
  companyUsers?: ICompanyUsers[];
  role?: string;
  account?: IAccounts;
  brandId?: number;
}

export const AccountUsers = (props: IAccountUsersListProps) => {
  const accountsApi = new objectApi.accounts();
  const [companyUser, setCompanyUsers] = React.useState<ICompanyUsers[]>([]);
  const groups = _.groupBy<ICompanyUsers>(companyUser, "role");
  React.useEffect(() => {
    if (props?.brandId && AUTHUser.getUserAccount().accountType !== null) {
      accountsApi.getBrandRoles(AUTHUser.getUserAccount()?.id, props.brandId, { params: { withCompanyAdmin: true } }).then((res) => {
        if (res.data.userAccountAssociations) {
          let users = res.data.userAccountAssociations.map((i) => {
            return i.user;
          });
          setCompanyUsers(users);
          if (props.role) {
            setCompanyUsers(users.filter((x: any) => x.role === props.role));
          }
        }
      });
    } else {
      accountsApi.byId(AUTHUser.getUserAccount()?.id).then((res) => {
        if (res.companyUsers) {
          setCompanyUsers(res.companyUsers);
          if (props.role) {
            setCompanyUsers(res.companyUsers.filter((x: any) => x.role === props.role));
          }
        }
      });
    }
  }, [props.brandId]);

  return (
    <div>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }} data-cy={"team-list-avatar"}>
        {companyUser.map((user: ICompanyUsers) => (
          <GlobalAvatar key={user.id} user={user} size={"28px"} fontSize={"15px"} />
        ))}
      </Box>
    </div>
  );
};
