import { objectApi } from "application/entities/dataApi";
import { PageId } from "application/pages/pages.config"
import { getUnselectedDsps } from "application/utils/dsp.utils";
import { useAllDspChannels, useSelectedDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { MainFrame } from "components/templates/MainFrame"
import { useAccountTracker } from "pages/Agencies/useAccountTracker";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { styled } from "@mui/material";
import { useChannelsSetupStore } from "../ChannelsSetupStore";
import { ChannelSelection } from "../components/ChannelSelection";
import { ChannelConfiguration } from "../components/ChannelConfiguration";
import { useEffect } from "react";

export const ChannelsSetupAdd = () => {
    let {accountId}             =  useParams<{ accountId: string }>() as { accountId: number };
    useAccountTracker(+accountId);
    const accountsApi              = new objectApi.accounts();
    
    const parentAccountId          = useQuery(['parentAccountId', +accountId], () => {
        return accountsApi.byId(+accountId);
    }, {enabled:Boolean(accountId)});

    const allDsps = useAllDspChannels(+accountId);
    const dspChannelsSelected = useSelectedDspChannels(+accountId);
    const unselectedDsps = getUnselectedDsps(allDsps.data , dspChannelsSelected.data).sort((a,b)=>(a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0) )
    const loading = parentAccountId.isLoading || allDsps.isLoading || dspChannelsSelected.isLoading;


    
    const currentStep = useChannelsSetupStore().currentStep
    const channelsSetupStore = useChannelsSetupStore()
  
    
    useEffect(() => {
        channelsSetupStore.setAccountId(+accountId)
        return ()=>{
            channelsSetupStore.reset()
        } 
    },[])

    return (
        
            <MainFrame frameId={PageId.channelsSetupAdd} 
                checkPageAccess={true} 
                grantWith={{accountId:parentAccountId?.data?.parentCompanyId }}
                loading={loading}
                breadCrumbProps={{params:{accountId: accountId.toString(), companyId: parentAccountId?.data?.parentCompanyId, brandId:accountId.toString() }}}
            >
                <div style={{height:'100%', overflow:'auto'}}>
                    <ChannelsSetupWrap sx={{boxShadow: 3}}>
                        {currentStep === 1 &&  <ChannelSelection unselectedDsps={unselectedDsps} />}
                        {(currentStep === 2 ) && <ChannelConfiguration  /> }

                    </ChannelsSetupWrap>
                </div>

            </MainFrame>
        
    )
}


export const ChannelsSetupWrap = styled('div')(() => ({
    padding:'1.5rem',
    width:'980px',
    maxWidth:'calc(100% - 3rem)',
    margin:'1.5rem auto',
    background:'#fff',
    borderRadius:'8px',
    overflowY:'auto'
}));

