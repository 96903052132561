import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {IRules} from '../../../application/entities/dataTypes/rules';
import styledOld from 'styled-components/macro';
import {
    ColumnsCenter,
    ColumnsMiddle,
    Elem,
    GridItemFull,
    GridMiddle,
    H5,
    Stacker
} from '../../../components/ui/AppElements';
import React from 'react';
import {Authorities} from '../../../application/entities/dataTypes/authorities';
import {AppToast, useAppToast} from '../../../components/live/AppToast';
import {useAuthState} from '../../../redux/slices/auth.slices';
import {objectApi} from '../../../application/entities/dataApi';
import {useParams} from 'react-router-dom';
import {dataUtils, getObjectByFieldValue} from '../../../application/utils/dataState.utils';
import {API_PART_TYPE} from '../../../application/entities/api/apiService';
import {ICountry} from '../../../application/entities/dataTypes/country';
import {MainFrame} from '../../../components/templates/MainFrame';
import {Box, Button, Typography} from '@mui/material';
import {AppIcon} from '../../../components/ui/AppIcon';
import {RuleLineCreate} from './DataVaultRuleCreate';
import {DataVaultRule} from './DataVaultRule';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {ButtonEditDataVault} from './ButtonEditDataVaule';
import {BackendError} from '../../../types/backendError';
import {GlobalAvatar} from '../../../components/ui/GlobalAvatar';


export interface IRuleState extends IRules {
    hasIndustries?: boolean;
    hasDomain?: boolean;
    whoChoice?: 'industries' | 'domain';
    //[index: string]: string | any;
}

export const DataVaultPermissions = () => {

        const [loading, setLoading] = React.useState<boolean>(false);
        const [loadingError, setLoadingError] = React.useState<BackendError>();
        const [createMode, setCreateMode] = React.useState<boolean>(false);
        const [featureRules, setFeatureRules] = React.useState<IRuleState[]>([]);
        const [activeFeatureRules, setActiveFeatureRules] = React.useState<IRuleState[]>([]);
        const [inactivefeatureRules, setInactiveFeatureRules] = React.useState<IRuleState[]>([]);
        const [activeLine, setActiveLine] = React.useState<number | null>(null);
        const [account, setAccount] = React.useState<Partial<IAccounts>>();
        const [userRole, setUserRole] = React.useState<Authorities>();

        const appToast = useAppToast();
        const authState = useAuthState();

        const rulesApi = new objectApi.rules();
        const accountsApi = new objectApi.accounts();
        const countriesApi = new objectApi.countries();

        const daRef = React.useRef<any>(null);

        const {accountId} = useParams<{ accountId: string }>() as { accountId: string };

        React.useEffect(() => {
            const authorities = authState?.user?.authorities;
            if (authorities) {
                if (authorities.includes(Authorities.SUPERADMIN)) {
                    setUserRole(Authorities.SUPERADMIN);
                } else if (authorities.includes(Authorities.ADMIN)) {
                    setUserRole(Authorities.ADMIN);
                } else if (authorities.includes(Authorities.EDITOR)) {
                    setUserRole(Authorities.EDITOR);
                } else {
                    setUserRole(Authorities.VIEWER);
                }
            }
        }, []);

        React.useEffect(() => {
            fetchAccount();
        }, [accountId]);

        React.useEffect(() => {
            fetchRule();
        }, [userRole]);

        React.useEffect(() => {
            setActiveFeatureRules(() => getObjectByFieldValue(featureRules, 'isActivated', true));
            setInactiveFeatureRules(() => getObjectByFieldValue(featureRules, 'isActivated', false));
        }, [featureRules]);

        const fetchRule = async () => {
            if (accountId && userRole) {
                try {
                    setLoading(true);
                    const rules = await accountsApi.GetRules(+accountId, {
                        ret: {part: API_PART_TYPE.EMBEDDED},
                    });

                    if (rules) {
                        hydrateRules(rules);
                    }
                    setLoading(false);

                } catch (e) {
                    setLoading(false);
                    setLoadingError(e as BackendError)
                }
            }
        };

        const fetchAccount = async () => {
            if (accountId) {
                try {
                    const data = await accountsApi.byId(accountId as unknown as number);
                    if (data) {
                        setAccount(data);
                    }
                } catch (e) {

                }
            }
        };

        const hydrateRules = async (rules: IRuleState[]) => {
            const countries = await countriesApi.get({output: {part: 'EMBEDDED'}});

            const hydratedRules = rules.map((rule: IRuleState) => {
                let newRule: IRuleState = {} as IRuleState;

                newRule.hasIndustries = !!(rule?.industries?.length || (!rule?.domain?.length && rule?.locations?.length));
                newRule.hasDomain = !!(rule?.domain?.length);
                // changes for countries
                // replace locations form : patch here?
                const locations = rule.locations.map((item: string) => {
                    return dataUtils.getObjectByFieldValue<ICountry>(countries, 'code', item);
                })
                    .flat(1);

                return {
                    ...rule,
                    ...newRule,
                    locations: locations,
                };
            });

            setFeatureRules(() => hydratedRules);
        };

        const handleClick = (index: number) => {
            if (index !== activeLine) {
                setActiveLine(index);
            }
        };

        const dataError = (error: any) => {
            appToast.open({
                toastId: 'globalToast',
                message: error.message,
                severity: 'error',
            });
        };

        // @ts-ignore
        const showFrame = AUTHUser.isEvorraAdmin() || AUTHUser.accountId === +accountId; // integrate in MainFrameGrant

        return (
            <MainFrame
                restrict404={!showFrame}
                frameId={'permissions'}
                loading={loading}
                backendError={loadingError}
                headerButtons={<ButtonEditDataVault setAccount={setAccount} account={account}/>}
            >
                <AppToast toastId={'rulesToast'}/>
                <Stacker style={{height: '100%', overflow: 'auto'}}>
                    <Box>
                        <GridMiddle sx={{height: 'auto'}} padding={[1, 0]}>
                            <ColumnsCenter width={5}>
                                <GlobalAvatar
                                    logoVal={account?.companyName}
                                    size={'26px'}
                                    fontSize={'14px'}
                                />
                            </ColumnsCenter>
                            <GridItemFull padding={[0, 0.5]}>
                                <H5>{account?.companyName}</H5>
                            </GridItemFull>
                            <ColumnsMiddle padding={[0, 4]}>
                                <Button
                                    data-cy={'create-rule-btn'}
                                    disabled={createMode} onClick={() => {
                                    setCreateMode(true);
                                }} variant={'contained'}
                                >Create New Rule</Button>
                                {createMode && <AppIcon
                                    color={'red'} onClick={() => {
                                    setCreateMode(false);
                                }} icon={'Close'}
                                />}
                            </ColumnsMiddle>
                        </GridMiddle>

                    </Box>
                    {createMode &&
                        <RuleLineCreate showHide={setCreateMode} dataReload={fetchRule} dataError={dataError}/>}
                    <GridItemFull padding={[0, 1]}>
                        <div ref={daRef} style={{height: '100%'}}>
                            <Elem padding={[1, 2]}>
                                <H5>Active Rule List</H5>
                            </Elem>
                            {(!loading && !activeFeatureRules.length) && <Elem padding={[1, 2]}>
                                <Typography>You have no active rules</Typography>
                            </Elem>}
                            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}
                                 data-cy={'active-rule-list'}>
                                {activeFeatureRules.map((ruleItem: IRuleState, index: number) => {
                                    return <DataVaultRule
                                        dataError={dataError}
                                        dataReload={fetchRule}
                                        key={`rule_${ruleItem.id}`}
                                        rule={ruleItem}
                                        onClick={() => {
                                            handleClick(ruleItem.id);
                                        }}
                                        active={Boolean(ruleItem.id === activeLine)}
                                    />;
                                })}

                            </div>
                            <Elem padding={[1, 2]}>
                                <H5>Inactive Rule List</H5>
                            </Elem>
                            {(!loading && !inactivefeatureRules.length) && <Elem padding={[1, 2]}>
                                <Typography>You have no inactive rules</Typography>
                            </Elem>}
                            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}
                                 data-cy={'inactive-rule-list'}>
                                {inactivefeatureRules.map((ruleItem: IRuleState, index: number) => {
                                    return <DataVaultRule
                                        dataError={dataError}
                                        dataReload={fetchRule}
                                        key={`rule_${ruleItem.id}`}
                                        rule={ruleItem}
                                        onClick={() => {
                                            handleClick(ruleItem.id);
                                        }}
                                        active={Boolean(ruleItem.id === activeLine)}
                                    />;
                                })}
                            </div>
                        </div>
                    </GridItemFull>
                </Stacker>
            </MainFrame>
        );
    }
;export const VaultLine = styled(Elem)((props: { theme: Theme } & { active?: boolean, onClick: Function }) => ({
    padding: '1rem',
    background: props.theme.palette.background.default,
    border: props.active ? '1px solid #00b5e2' : '1px solid transparent',
    boxSizing: 'border-box',
    boxShadow: props.active
        ? props.theme.skin.boxShadow
        : props.theme.skin.boxShadowHover,
    borderRadius: '12px',
    marginBottom: '1rem',
}));
export const VaultLineHeader = styledOld.div({});
export const RuleLabel = styledOld.span({
    display: 'inline-block',
    color: '#9DAABF',
    paddingRight: '1rem',
});