import { Box, Typography } from "@mui/material";
import { PageId } from "application/pages/pages.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Container, ContentBlock } from "styles/styles/generalFrame.styled";
import { actions } from "../../application/actions/actions";
import { objectApi } from "../../application/entities/dataApi";
import { listFilesColumns } from "../../application/entities/dataColumns/listFiles.columns";
import { IUploadedList } from "../../application/entities/dataTypes/listFiles";
import { dataColumnModelUtils } from "../../application/utils/dataColumnModel.utils";
import { ActionButtons } from "../../components/controls/ActionButtons";
import { useOpenDialogWindow } from "../../components/live/DialogWindow";
import { GridContent, GridTable } from "../../components/organisms/Grid";
import { MainFrame } from "../../components/templates/MainFrame";
import { IOpenDialogAction } from "../../redux/slices/dialog.slices";
import { BackendError } from "../../types/backendError";
import { UploadList } from "./UploadList";

interface IListsTableProps {}

export const PrePackagedLists: React.FC<IListsTableProps> = () => {
  const accountsApi = new objectApi.accounts();
  const dialogWindow = useOpenDialogWindow();
  const { t } = useTranslation();

  const { accountId } = useParams<{ accountId: string }>() as { accountId: string };
  const [exportReference, setExportReference] = React.useState<React.Ref<HTMLButtonElement>>(null);
  const [totalListCount, setTotalListCount] = React.useState<number>(0);
  const [rows, setRows] = React.useState([]);
  const [isDataAvailable, setIsDataAvailable] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<BackendError>();

  const [frameLoaded, setFrameLoaded] = React.useState<boolean>(false);

  const parentAccountId = useQuery(
    ["parentAccountId", +accountId],
    () => {
      return accountsApi.byId(+accountId);
    },
    { enabled: Boolean(accountId) }
  );

  const getFileName = (key: string) => key.split("/")[2];

  const prepareToDataGrid = (arr: IUploadedList[]): object[] => {
    const list = arr.map((item, index) => {
      const fileName = getFileName(item.key);
      return { ...item, id: index, accountId: accountId, fileName: fileName };
    });
    return list;
  };

  const fetchUploadedExclusionLists = async (accountId: number) => {
    return null;
    setLoading(true);
    accountsApi
      .getUploadedExclusionLists(accountId, { page: { pageSize: 1000, pageNumber: 0 } })
      .then((res) => {
        setFrameLoaded(true);
        if (res.data.objects.length > 0) {
          const rows = prepareToDataGrid(res.data.objects);
          setRows(rows as any);
          setIsDataAvailable(true);
          setTotalListCount(res.data.objects.length);
          setLoading(false);
        } else {
          setRows([]);
          setIsDataAvailable(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingError(err);
      });
  };

  const deleteList = (key: string) => {
    return null;

    accountId && accountsApi.deleteUploadedList(+accountId, key).then(() => fetchUploadedExclusionLists(+accountId));
  };

  React.useEffect(() => {
    if (accountId) {
      fetchUploadedExclusionLists(+accountId).then();
    }
  }, [accountId]);

  const uploadListDialogConf: IOpenDialogAction = {
    dialogId: "UploadListModal",
    component: UploadList,
    title: t("uploadLists.uploadExclusionList"),
    icon: "Upload",
    description: t("uploadLists.uploadExclusionListDescription"),
    componentProps: {
      accountId: accountId,
      fetchUploadedLists: fetchUploadedExclusionLists,
    },
  };

  const handleOpenUploadDialogWindow = () => {
    return null;

    dialogWindow.open(uploadListDialogConf).then();
  };

  const columns = dataColumnModelUtils.renderModel(listFilesColumns, "show");

  actions.mylistsCard.deleteList.action = deleteList;
  actions.mylistsCard.deleteList.code = "delete-list";
  actions.mylists.uploadList.action = handleOpenUploadDialogWindow;
  actions.mylists.uploadList.code = "upload-list-btn";
  const headerButtons = <ActionButtons actions={actions.mylists} vars={{}} />;

  // Add the Delete button
  columns[5].renderCell = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
        <ActionButtons actions={actions.mylistsCard} vars={params.row.key} />
      </div>
    );
  };

  return (
    <>
      <MainFrame
        frameId={PageId.myList}
        scrollable={true}
        loading={!frameLoaded}
        backendError={loadingError}
        headerProps={{
          headerButtons,
        }}
        breadCrumbProps={{ params: { companyId: parentAccountId?.data?.parentCompanyId, brandId: accountId } }}
      >
        <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0.9, backgroundColor: "#fff", zIndex: 9 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
            <Typography variant="h4">This feature is temporary unavailable</Typography>
          </Box>
        </Box>
        <ContentBlock>
          <Container>
            <GridContent>
              <GridTable
                dataCy={loading ? "my-list-table-loading" : "my-list-table"}
                data={{ columns: columns as any, rows: rows }}
                noRowsText={loading ? "Loading" : "no audiences lists"}
                gridProps={{
                  localText: {
                    noRowsLabel: "no label rows",
                  },
                  componentsProps: {
                    toolbar: {
                      exportReference: exportReference,
                      setExportReference: setExportReference,
                    },
                  },
                }}
              />
            </GridContent>
          </Container>
        </ContentBlock>
      </MainFrame>
    </>
  );
};
