import {TwitterPicker} from 'react-color';
import {AppIcon} from '../../ui/AppIcon';
import React from 'react';
import {ClickAwayListener, Popper} from '@mui/material';

export interface IColorPickerProps {
  id?: any
  trigger?: any
  onChange?: (args: { color: string, id?: number }) => void
}

export const ColorPicker = (props: IColorPickerProps) => {

  const {trigger, onChange, id} = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleChange = (args: any) => {
    onChange && onChange({id, color: args.hex})
    setAnchorEl(null);
  }

  return <div>
    {!trigger && <AppIcon fontSize={'tiny'} onClick={handleToggle} icon={'EyeDropper'}/>}
    {trigger && <div onClick={handleToggle}>{trigger}</div>}
    <Popper
      id={'idpopper'}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      /*keepMounted={true}*/
    >
      {Boolean(open) && <ClickAwayListener onClickAway={handleClose}>
          <TwitterPicker onChange={handleChange}/>
      </ClickAwayListener>}
    </Popper>
  </div>
}