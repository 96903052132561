import {magniteAdSizesDisplay, magniteAdSizesNative, magniteAdSizesVideo} from "application/datas/ssp/adSizes/magnite";
import {pubmaticAdSizes} from "application/datas/ssp/adSizes/pubmatic";
import {IDspChannels} from "application/entities/dataTypes/dspChannels";
import {DspCheckoutParameterGroupType} from "application/entities/dataTypes/dspEnablementOptions";
import {IDspChannelsSelections} from "application/entities/dataTypes/dspUserSelections";
import {TIcon} from "assets/icons";
import {providerIconList} from "assets/providerIcons";

type DspAdditionalInfo = {
    code: string;
    name?: string;
    restriction?: string;
    restrictionDetails?: string;
    isNew?: boolean;
    locationIncludes?: string[];
    locationExcludes?: string[];
};

const additionalInfo: DspAdditionalInfo[] = [
    {
        code: "liveramp",
        name: "LiveRamp",
        restriction: "US audiences only",
        restrictionDetails:
            "Only US based audiences can be sent via LiveRamp. If you have chosen multiple geographies, only the US audiences will be shown here and in the Enablement step.",
        locationIncludes: ["US"],
    },
    {
        code: "magnite",
        name: "Deal ID - Magnite",
    },
    {
        code: "pubmatic",
        name: "Deal ID - Pubmatic",
    },
];

export const getUnselectedDsps = (allDsps: IDspChannels[] | undefined, selectedDsps: IDspChannelsSelections[] | undefined) => {
    if (!allDsps || !selectedDsps) return [];
    const selectedCodes = selectedDsps?.map((dsp) => dsp.dspChannel.code);
    return allDsps?.filter((dsp) => !selectedCodes?.includes(dsp.code));
};

export const getDspAdditionalInfo = (dspCode: string) => {
    if (additionalInfo.find((info) => info.code === dspCode)) {
        return additionalInfo.find((info) => info.code === dspCode);
    }
    if (dspCode.startsWith("pubmatic_")) {
        return additionalInfo.find((info) => info.code === "pubmatic");
    }
    if (dspCode.startsWith("liveramp_")) {
        return additionalInfo.find((info) => info.code === "liveramp");
    }
    if (dspCode.startsWith("magnite_")) {
        return additionalInfo.find((info) => info.code === "magnite");
    }
    return null;
};

export const getDspIcon = (dspCode: string): TIcon => {
    if (dspCode.startsWith("lotamepdx_")) {
        return "Lotame";
    }
    if (dspCode.startsWith("pubmatic_")) {
        return "Pubmatic";
    }
    if (dspCode.startsWith("liveramp_")) {
        return "Liveramp";
    }
    if (dspCode.startsWith("magnite_")) {
        return "Magnite";
    }
    if (dspCode.startsWith("jamloop_")) {
        return "Jamloop";
    }
    if (dspCode.startsWith("mediagrid_")) {
        return "Mediagrid";
    }
    return providerIconList[dspCode];
};

type CheckoutParameter = {
    parameterName: string;
    type: string;
    title: string;
    description: string;
    appTipContent?: string;
    selectable: { value: string; label: string }[];
};

export const getCheckoutParameterGroup = (
    checkoutParameterGroup: DspCheckoutParameterGroupType
): DspCheckoutParameterGroupType & { title: string; description: string; appTipContent: string; required: boolean } => {
    if (checkoutParameterGroup.code === "ad_format_group") {
        return {
            ...checkoutParameterGroup,
            title: "Advertising Format",
            description: "Select the ad formats you want to use for this enablement method",
            appTipContent: "Select the ad formats you want to use for this enablement method ",
            required: true,
        };
    }
    if (checkoutParameterGroup.code === "ad_size_banner_group") {
        return {
            ...checkoutParameterGroup,
            title: "Banner Advertising Sizes",
            description: "Select all the relevant banner sizes you will be running against this audience, for the campaign",
            appTipContent: "",
            required: false,
        };
    }
    if (checkoutParameterGroup.code === "ad_size_video_group") {
        return {
            ...checkoutParameterGroup,
            title: "Video Advertising Sizes",
            description: "Select all the relevant video sizes you will be running against this audience, for the campaign",
            appTipContent: "",
            required: false,
        };
    }
    if (checkoutParameterGroup.code === "ad_size_native_group") {
        return {
            ...checkoutParameterGroup,
            title: "Native Advertising Sizes",
            description: "Select all the relevant native sizes you will be running against this audience, for the campaign",
            appTipContent: "",
            required: false,
        };
    }
    if (checkoutParameterGroup.code === "viewability_group") {
        return {
            ...checkoutParameterGroup,
            title: "Viewability",
            description: "Select the MINIMUM viewability you require against this audience for your campaign",
            appTipContent: "",
            required: false,
        };
    }
    if (checkoutParameterGroup.code === "ad_platform_group") {
        return {
            ...checkoutParameterGroup,
            title: "Platforms",
            description: "Select all platforms you wish to target with this audience",
            appTipContent: "",
            required: true,
        };
    }
    if (checkoutParameterGroup.code === "domains_group") {
        return {
            ...checkoutParameterGroup,
            title: "Domain Lists",
            description: "Associate a previously uploaded whitelist or blacklist",
            appTipContent: "",
            required: false,
        };
    }
    return {...checkoutParameterGroup, title: "", description: "", appTipContent: "", required: false};
};
export const viewabilityOptions = [
    {
        value: "100",
        label: "All impressions",
    },
    {
        value: "90",
        label: "90% or greater",
    },
    {
        value: "80",
        label: "80% or greater",
    },
    {
        value: "70",
        label: "70% or greater",
    },
    {
        value: "60",
        label: "60% or greater",
    },
    {
        value: "50",
        label: "50% or greater",
    },
    {
        value: "40",
        label: "40% or greater",
    },
    {
        value: "30",
        label: "30% or greater",
    },
];
export const getCheckoutParameter = (sspCode: string, checkoutParamCode: string): {
    options: Array<{ value: string; label: string }>
} => {
    if (sspCode.includes("pubmatic")) {
        if (checkoutParamCode === "ad_size_banner") {
            return {
                options: pubmaticAdSizes,
            };
        }
        if (checkoutParamCode === "ad_size_video") {
            return {
                options: pubmaticAdSizes, //we propse the same ad sizes for video and banner because there is a bug on pubmatic side
            };
        }
        if (checkoutParamCode === "ad_size_native") {
            return {
                options: pubmaticAdSizes,
            };
        }
        if (checkoutParamCode === "min_viewability") {
            return {
                options: viewabilityOptions,
            };
        }
    }
    if (sspCode.includes("magnite")) {
        if (checkoutParamCode === "ad_size_banner") {
            return {
                options: magniteAdSizesDisplay,
            };
        }
        if (checkoutParamCode === "ad_size_video") {
            return {
                options: magniteAdSizesVideo,
            };
        }
        if (checkoutParamCode === "ad_size_native") {
            return {
                options: magniteAdSizesNative,
            };
        }
        if (checkoutParamCode === "min_viewability") {
            return {
                options: viewabilityOptions,
            };
        }
    }

    return {options: []};
};

export const getParamName = (code: string): string => {
    if (code === "format_banner") {
        return "Format Banner";
    }
    if (code === "format_video") {
        return "Format Video";
    }
    if (code === "format_native") {
        return "Format Native";
    }

    if (code === "ad_size_banner") {
        return "Banner Size";
    }
    if (code === "ad_size_video") {
        return "Video Size";
    }
    if (code === "min_viewability") {
        return "Viewability";
    }
    if (code === "platform_desktop") {
        return "Desktop";
    }
    if (code === "platform_mobile_web") {
        return "Mobile Web";
    }
    if (code === "platform_mobile_ios") {
        return "In App iOS";
    }
    if (code === "platform_mobile_android") {
        return "In App Android";
    }
    if (code === "platform_ctv") {
        return "Platform CTV";
    }
    if (code === "include_domains") {
        return "WhiteList domains";
    }
    if (code === "exclude_domains") {
        return "BlackList domains";
    }
    return code;
};
