import React from "react";
import oldStyled from "styled-components/macro";
import { Logo } from "../../../assets/icons";
import { Columns, GridItem } from "../../ui/AppElements";
import { AppIcon } from "components/ui/AppIcon";
import { GlobalAvatar } from "../../ui/GlobalAvatar";
import { UserMenu } from "../UserMenu/UserMenu";
import { CrudHeader } from "../../live/CrudHeader";

import { Theme } from "@mui/material/styles";
import { Box, ListItemIcon, ListItemText, MenuItem, styled, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AUTHUser } from "application/utils/AuthUser";
import { useUserQuery } from "components/hooks/data/useUserQuery";
import { ICompanyUsers } from "../../../application/entities/dataTypes/companyUser";
import { EPermission } from "../../../application/utils/granter.utils";
import { useUiStore } from "../../../redux/ui.store";
import { PageId } from "../../../application/pages/pages.config";
import { pageUtils } from "../../../application/pages/pages.utils";
import { NewsFeed } from "./NewsFeed";

import { getLogoHeader } from "application/utils/domain.utils";

export interface IBreadCrumbProps {
  frameId: string;
  crumbTitle?: string;
  idCompany?: string;
  idBrand?: string;
}

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  // userData
  const userQuery = useUserQuery({ userId: AUTHUser.userId });
  const userData: ICompanyUsers = userQuery?.data ?? {};

  type currentBrandType = {
    [key: string]: any;
  };

  // Get current Brand
  const currentBrand1: currentBrandType = useUiStore((st) => st.currentBrand) ?? {};
  // Get Brand
  const companyId = AUTHUser.getUserAccount().id;
  // @ts-ignore

  const adminOrNot = AUTHUser.hasRole(EPermission.COMPANYADMIN);

  const logoHeader = getLogoHeader();

  return (
    <AppBar>
      <CrudHeader />
      <LogoBlock>
        {logoHeader ? <img src={logoHeader} alt="logo" style={{ display: "block", margin: "auto", maxHeight: "50px" }} /> : <Logo />}
      </LogoBlock>
      <IconsBlock>
        {!currentBrand1.id ? null : (
          <MenuItem disableGutters={true} dense={true}>
            <ListItemIcon>
              <GlobalAvatar logoVal={currentBrand1?.companyName} size={"26px"} fontSize={"14px"} />
            </ListItemIcon>
            <ListItemText>
              <RouterLink
                data-cy="header-brand-item"
                data-cy-brand-id={currentBrand1?.id}
                style={{ color: "inherit", textDecoration: "none" }}
                to={pageUtils.getPagePathById(PageId.brandDashboard, { brandId: currentBrand1?.id, companyId: companyId })}
              >
                <Typography style={{ marginRight: 15 }} title={currentBrand1?.companyName} noWrap>
                  {currentBrand1?.companyName}
                </Typography>
              </RouterLink>
            </ListItemText>

            {adminOrNot ? (
              <RouterLink
                data-cy="header-brand-item-edit"
                to={pageUtils.getPagePathById(PageId.brandUpdate, { brandId: currentBrand1?.id, companyId: companyId })}
              >
                <AppIcon onClick={() => true} style={{ marginRight: "15%" }} fontSize={"tiny"} icon={"Edit"} />
              </RouterLink>
            ) : null}
          </MenuItem>
        )}

        <NewsFeed />

        <UserAvatarContainer data-cy={"header-user-avatar"} onClick={handleClick}>
          <GridItem>
            <GlobalAvatar user={userData} isPending={userData.status !== "ACTIVATED"} size={"34px"} fontSize={"18px"} />
          </GridItem>
          <GridItem style={{ paddingLeft: "0.5rem" }}>
            <AppIcon icon={"ChevronDownIcon"} fontSize={"small"} rotate={menuOpen ? 180 : 0} />
          </GridItem>
        </UserAvatarContainer>
        <UserMenu anchor={anchorEl} open={menuOpen} onClose={handleClose} />
      </IconsBlock>
    </AppBar>
  );
};

const UserAvatarContainer = oldStyled(Columns).attrs({ alignItems: "center" })({
  borderRadius: "10px",
  width: "auto",
  padding: "0.2rem 0.5rem",
  height: "48px",
  ":hover": {
    backgroundColor: "#ededed",
    cursor: "pointer",
    boxShadow: "0 0 6px rgba(196, 211, 241, 0.85)",
  },
});

const AppBar = styled(Box)((props: { theme: Theme }) => ({
  height: "60px",
  background: props.theme.palette.background.default,
  boxShadow: props.theme.skin.boxShadow,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  paddingRight: "0.5rem",
  zIndex: 300,
}));

const LogoBlock = oldStyled.div`
  padding-left: 27px;
  display: flex;
  justify-content: center;
`;

const IconsBlock = oldStyled.div`
  // padding-right: 10px;
  display: flex;
`;
