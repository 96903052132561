import {styled} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {useFeatureFlag} from "stores/featureFlags.store";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {IAudienceFeatureDto} from "application/entities/dataTypes/audienceFeature";
import {trans2Tree, TreeItemType} from "pages/AudienceBuilder/audienceBuilderUtils";
import {IFiltersValues} from "pages/AudienceBuilder/types/audienceBuilderTypes";
import {TreeViewVisualisation} from "./TreeVisualisation";

export type DropAudienceEvent = {
    audienceFeature: IAudienceFeatureDto;
    treeListItem: TreeItemType;
};
export type DrawAudienceFeatureslinkedIdProps = {
    filterValue: IFiltersValues;
    viewMode?: boolean;
};

type TFilterValueSelectedItem = {
    featureId: number;
    linkTo: number;
    selected: any[];
    notOperator?: boolean
};

export const ItemTypes = {
    INTEREST: "interest",
};

export function ViewAudienceFeatureInterest(props: DrawAudienceFeatureslinkedIdProps) {
    const {filterValue, viewMode} = props;
    const featFlagStore = useFeatureFlag((st) => st.featureFlags);

    const audienceStoreActions = useAudienceBuilderStore.getState().actions;
    const hoverSelectId = useAudienceBuilderStore((st) => st.hoverSelectId);

    const hierarchyField = "path";


    const excludedInterests = filterValue.selected.filter((value) => value.notOperator === true);

    const includedInterests = filterValue.selected.filter((value) => value.notOperator === false);

    const firstList = includedInterests.filter((value) => value.linkTo === 0)[0] as unknown as TFilterValueSelectedItem;

    type IThumbProps = {
        filterValueSelectedItem: TFilterValueSelectedItem;
    };
    //for data-cy
    let listIterationNumber: number = -1;

    const InterestsBox = ({interests, isLast}: { interests: TFilterValueSelectedItem, isLast: boolean }) => {
        let isSelectInterestHover = Number(hoverSelectId) === interests?.featureId ? true : false;

        return <>
            <ThumbGrid
                style={{maxWidth: "145px"}}
                data-cy={"featureZone"}
                data-code={"interestList" + listIterationNumber}
                mode={"grouped"}
                className={"nowheel nodrag" + (isSelectInterestHover ? " hover" : "")}
            >
                <TreeContainer>
                    <TreeViewVisualisation
                        tree={trans2Tree(interests.selected, hierarchyField)}
                        expandVisible={true}
                        itemFeatureId={interests.featureId}
                        expandCode={"interest" + interests.featureId}
                        isDragAndDropActive={viewMode ? false : filterValue.selected.length > 1 ? true : false}
                        viewMode={viewMode}
                    ></TreeViewVisualisation>
                </TreeContainer>
            </ThumbGrid>
            {!isLast && interests.notOperator === false && (
                <>
                    <div style={{position: "relative", zIndex: 50}}>
                      <span style={{
                          padding: "3px",
                          position: "absolute",
                          top: "-16px",
                          left: "50%",
                          transform: "translate(-50%,0)"
                      }}>
                        <Joiner style={{backgroundColor: "#02C697"}}>
                            AND
                        </Joiner>
                      </span>
                    </div>

                </>
            )}
        </>
    }


    return <>
        {includedInterests?.map((interests, position) => {
                let isLast = position === includedInterests.length - 1;
                if (includedInterests[position + 1]?.selected?.length === 0) {
                    isLast = true
                }
                const lastSelectedCount = includedInterests[includedInterests.length - 1]?.selected?.length;

                if (interests.selected.length === 0) return null;
                return <InterestsBox interests={interests} isLast={isLast}/>
            }
        )}
        {excludedInterests && excludedInterests?.length > 0 && <>
            {excludedInterests[0].selected.length > 0 &&
                <div style={{position: "relative", zIndex: 50}}>
                      <span style={{
                          padding: "3px",
                          position: "absolute",
                          top: "-16px",
                          left: "50%",
                          transform: "translate(-50%,0)"
                      }}>
                        <Joiner style={{backgroundColor: "#e15959"}}>
                            AND NOT
                        </Joiner>
                      </span>
                </div>
            }
            {excludedInterests.map((interests, position) => {
                    const isLast = position === excludedInterests.length - 1;
                    if (interests.selected.length === 0) return null;
                    return <InterestsBox interests={interests} isLast={isLast}/>
                }
            )}
        </>}
    </>


}


export const FeatureGrid = styled("div")((props: { theme: Theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
    padding: "4px",
}));
export const Joiner = styled("div")((props: { theme: Theme }) => ({
    boxShadow: "0px 1px 2px 1px rgba(203, 222, 227, 0.49)",
    display: "flex",
    borderRadius: "115px",
    padding: "0 0.4rem",
    alignItems: "center",
    fontSize: "8px",
    color: "white",
    backgroundColor: "#02C697",
}));

export const ThumbGrid = styled("div")(
    (props: {
        theme: Theme;
        mode: "single" | "grouped";
        grid?: boolean;
        vertical?: boolean;
        canDrop: boolean;
        isOver: boolean
    } & any) => ({
        display: props.grid === true ? "flex" : "block",
        flexWrap: "wrap",
        // flexDirection       : props.vertical === true ? 'row' : 'column',
        position: "relative",
        zIndex: 0,
        gridGap: "4px",
        borderRadius: "6px",
        margin: "4px",
        marginBottom: "10px",
        padding: props.mode === "grouped" ? "6px 2px" : 0,
        backgroundColor: props.canDrop
            ? props.isOver
                ? "#00b5e2"
                : "#a7edff"
            : props.mode === "grouped"
                ? "#f1f3f7"
                : props.theme.palette.background.default,
        maxWidth: "100%",
        maxHeight: "400px",
        //border         : '1px solid ' + props.theme.skin.borderColor,
        overflowY: "auto",
        "&.hover": {
            backgroundColor: "#a7edff",
        },
    })
);

const TreeContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    zIndex: 0,
    gap: "0.25rem",
    margin: "4px",
    maxWidth: "100%",
    maxHeight: "400px",
    overflowY: "auto",
}));
