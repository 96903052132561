import {CancelToken} from "axios";
import {DspSelectionType} from "pages/Audiences/Enablement/types";
import {BackendError} from "../../../types/backendError";
import {dataUtils} from "../../utils/dataState.utils";
import {apiService, IApiReturn, IPageParameters, ISortParameters} from "../api/apiService";
import {IAudienceFeatureDto} from "../dataTypes/audienceFeature";
import {IAudiences} from "../dataTypes/audiences";
import {IIndustry} from "../dataTypes/industries";
import {ITags} from "../dataTypes/tags";
import {AbstractBaseApi} from "./AbstractBaseApi";

function array_unique($a: any[]) {
    return [...new Set($a)];
}

export class Audiences extends AbstractBaseApi<IAudiences> {
    private apiTags;
    private apiCountries;
    private apiIndustries;
    private apiAudienceFeatures;
    private apiAudienceFeaturesValues;
    private apiAudienceSubFeaturesValues;
    private apiAudienceActivation;
    private apiAudienceArchive;
    private apiAudienceSizePrice;
    private apiRefreshAudience;
    private apiDuplicateAudience;
    private apiRequestApproval;

    private routes: Record<string, any> = {};

    constructor() {
        super("audiences");
        this.apiTags = apiService.entity(["audiences", "tags"]);
        this.apiCountries = apiService.entity(["audiences", "countries"]);
        this.apiIndustries = apiService.entity(["audiences", "industries"]);
        this.apiAudienceFeatures = apiService.entity(["audiences", "audienceFeatures"]);
        this.apiAudienceActivation = apiService.entity(["audiences", "prepareActivation"]);
        this.apiAudienceFeaturesValues = apiService.entity(["audiences", "createUpdateAudienceFeature"]);
        this.apiAudienceSubFeaturesValues = apiService.entity(["audiences", "createUpdateAudienceSubFeature"]);
        this.apiAudienceArchive = apiService.entity(["audiences", "archiveAudience"]);
        this.apiAudienceSizePrice = apiService.entity(["audiences", "updateAudienceSizeAndPrice"]);
        this.apiRefreshAudience = apiService.entity(["audiences", "openAndRefreshAudience"]);
        this.apiDuplicateAudience = apiService.entity(["audiences", "duplicateAudience"]);
        this.apiRequestApproval = apiService.entity(["audiences", "requestApproval"]);

        /** migrate to agencies */
        this.routes["Tags"] = "accounts/{accountId}/tags";
        this.routes["Countries"] = "accounts/{accountId}/countries";
        this.routes["Industries"] = "accounts/{accountId}/industries";
        this.routes["AudienceFeatures"] = "accounts/{accountId}/audienceFeatures";
        this.routes["AudienceActivation"] = "accounts/{accountId}/prepareActivation";
        this.routes["AudienceFeaturesValues"] = "accounts/{accountId}/createUpdateAudienceFeature";
        this.routes["AudienceSubFeaturesValues"] = "accounts/{accountId}/createUpdateAudienceSubFeature";
        this.routes["AudienceArchive"] = "accounts/{accountId}/archiveAudience";
        this.routes["AudienceSizePrice"] = "accounts/{accountId}/updateAudienceSizeAndPrice";
        this.routes["RefreshAudience"] = "accounts/{accountId}/openAndRefreshAudience";
        this.routes["DuplicateAudience"] = "accounts/{accountId}/duplicateAudience";
    }

    private static prepare(data: Partial<IAudiences>): Partial<IAudiences> {
        const dta = {...data};

        delete dta.industries;
        delete dta.audienceFeatures;
        // delete dta.locations;
        delete dta.tags;
        delete dta.audienceFeatures;
        //@ts-ignore
        delete dta._links;

        if (dta.locations && dta.locations.length && typeof dta.locations[0] === "object")
            dta.locations = array_unique(dataUtils.collectObjectField(dta.locations, "code"));

        return dta;
    }

    create(data: Partial<IAudiences>) {
        return super.create(Audiences.prepare(data));
    }

    update(id: number, data: Partial<IAudiences>) {
        return super.update<IAudiences>(id, Audiences.prepare(data));
    }

    async duplicateAudience(data: Partial<IAudiences>) {
        if (data.id) {
            /*return apiService.getRoute(this.routes['DuplicateAudience'],{accountId: 1,audienceId:data.id})
                      .post({})
                      .then((res) => {
                        return res.data;
                      })
                      .catch((e) => {
                        throw new BackendError(e);
                      });*/

            return this.apiDuplicateAudience
                .byId(data.id)
                .post({})
                .then((res) => {
                    return res.data;
                })
                .catch((e) => {
                    throw new BackendError(e);
                });
        }
    }

    async archiveAudience(id: number) {
        return this.apiAudienceArchive
            .byId(id)
            .post({})
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    undoRedo(audienceId: number, audience: Partial<IAudiences>) {
        return apiService
            .route(`audiences/${audienceId}/undoRedo`)
            .post(audience)
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    async updateIndustries(id: number, data: Partial<IIndustry>[]) {
        const industries = dataUtils.collectObjectField(data, "id");
        return this.apiIndustries
            .byId(id)
            .put(industries, {asUriFragment: true})
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    async updateTags(id: number, data: Partial<ITags>[]) {
        const tags = dataUtils.collectObjectField(data, "id");
        return this.apiTags
            .byId(id)
            .put(tags, {asUriFragment: true})
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    prepareActivation(audienceId: number) {
        return this.apiAudienceActivation
            .byId(audienceId)
            .post({})
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    updateAudienceSizeAndPrice(audienceId: number, cancelToken?: CancelToken) {
        return this.apiAudienceSizePrice
            .byId(audienceId)
            .withCancelToken(cancelToken)
            .post({})
            .then((res) => res)
            .catch((error: any) => {
                if (error?.message == "Operation canceled by the user.") {
                    console.log("error", error);
                } else {
                    throw new Error(error);
                }
            });
    }

    createUpdateAudienceFeature(
        audienceId: number,
        featureId: number,
        data: { featureValues: IAudienceFeatureDto["featureValues"] },
        cancelToken?: CancelToken
    ) {
        return this.apiAudienceFeaturesValues
            .byId(audienceId)
            .withCancelToken(cancelToken)
            .subId(featureId)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                if (error?.message == "Operation canceled by the user.") {
                    console.log("error", error);
                } else {
                    throw new Error(error);
                }
            });
    }

    createUpdateAudienceSubFeature(
        audienceId: number,
        subFeatureId: number,
        data: { featureValues: IAudienceFeatureDto["featureValues"] },
        cancelToken?: CancelToken
    ) {
        return this.apiAudienceSubFeaturesValues
            .byId(audienceId)
            .withCancelToken(cancelToken)
            .subId(subFeatureId)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                if (error?.message == "Operation canceled by the user.") {
                    console.log("error", error);
                } else {
                    throw new Error(error);
                }
            });
    }

    createRequestApproval(audienceId: number, dspSelection: DspSelectionType, approverId: number) {
        return apiService
            .route(`audiences/${audienceId}/requestApproval`)
            .post({dspSelection, approverId})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    cancelRequestApproval(audienceId: number) {
        return apiService
            .route(`audiences/${audienceId}/cancelApproval`)
            .post({})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    dropInExistingZone(audienceId: number, originAudienceFeatureId: number, targetAudienceFeatureId: number, featureValues: string[]) {
        return apiService
            .route(`audiences/${audienceId}/dropInExistingZone/from/${originAudienceFeatureId}/to/${targetAudienceFeatureId}`)
            .post({featureValues})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    openAndRefreshAudience(audienceId: number) {
        return this.apiRefreshAudience
            .byId(audienceId)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    approveAudience(audienceId: number, accept: boolean, message: string) {
        return apiService
            .route(`audiences/${audienceId}/approveAudience`)
            .post({approveAudience: accept, message: message})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getAudiencesToApproveByUser(userid: number) {
        return apiService
            .route(`/companyUsers/${userid}/pendingApprovalAudiences`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getAudiencesApprovedByUser(userid: number) {
        return apiService
            .route(`/companyUsers/${userid}/approvedAudiences`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    enableApprovedAudience(audienceId: number, datas: { payLater: boolean; paymentMethodId: number }) {
        return apiService
            .route(`audiences/${audienceId}/enableApprovedAudience`)
            .post(datas)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    async createAndOperatorFeature(audienceId: number, featureId: number, featureValues: IAudienceFeatureDto["featureValues"], notOperator?: boolean) {
        return apiService
            .route(`audiences/${audienceId}/createAndOperatorFeature/${featureId}`)
            .post({featureValues, notOperator: notOperator === undefined ? false : notOperator})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    async updateAndOperatorFeature(audienceId: number, audienceFeatureId: number, featureValues: IAudienceFeatureDto["featureValues"], notOperator?: boolean) {
        return apiService
            .route(`audiences/${audienceId}/updateAndOperatorFeature/${audienceFeatureId}`)
            .post({featureValues, notOperator: notOperator === undefined ? false : notOperator})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    search(arg: { query?: any; page?: IPageParameters; sort?: ISortParameters; ret?: IApiReturn }) {
        return apiService
            .route(`audiences/searchAll`)
            .addParameter("label", arg?.query?.label)
            .addParameter("status", arg?.query?.status)
            .addParameter("kw", arg?.query?.kw)
            .page(arg?.page)
            .sort(arg?.sort)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    getAudiencesManualStep() {
        return apiService
            .route(`audiences/pendingForManualStep`)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    setEnablementInfo(props: { audienceId: number; dealId?: string; dspCode: string; isSuccess: boolean }) {
        const {audienceId, dealId, dspCode, isSuccess} = props;
        const data = {
            dealId,
            dspCode,
            isSuccess,
        };
        return apiService
            .route(`audiences/${audienceId}/enablementInfo`)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getAllAlwaysOnAudiences() {
        return apiService
            .route(`audiences/allAlwaysOn`)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    updateAlwaysOn(props: {
        audienceId: number;
        enableAlwaysOn: boolean;
        refreshIntervalInDays: number | null;
        dspCode: string
    }) {
        const {audienceId, enableAlwaysOn, refreshIntervalInDays, dspCode} = props;
        const data = {
            dspCode,
            enableAlwaysOn,
            refreshIntervalInDays,
        };
        return apiService
            .route(`audiences/${audienceId}/updateAlwaysOn`)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    duplicateAudienceToAccount(props: { audienceId: number; accountDestinationId: number }) {
        const {audienceId, accountDestinationId} = props;
        return apiService
            .route(`audiences/${audienceId}/duplicateAudienceToAccount/${accountDestinationId}`)
            .post()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }
}
