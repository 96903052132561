import {AbstractBaseApi} from './AbstractBaseApi';
import {apiService} from '../api/apiService';
import {ICompanyUsers} from '../dataTypes/companyUser';
import {BackendError} from '../../../types/backendError';

export class CompanyUsers extends AbstractBaseApi<ICompanyUsers> {
    private routes: Record<string, string> = {};


    constructor() {
        super('companyUsers');

        this.routes['changeUserPermissions'] = 'accounts/{accountId}/changeUserPermissions/{userId}';
        this.routes['changeUserBrandPermission'] = 'accounts/{accountId}/changeUserBrandPermission/{userId}';
        this.routes['findByEmail'] = 'companyUsers/search/findByEmail?email={email}';
        this.routes['loginEvent'] = 'companyUsers/{userId}/loginEvent';

    }


    getAccount(id: number) {
        return apiService.entity(['companyUsers', 'account'])
            .byId(id)
            .get();
    }

    changeUserPermissions(accountId: number, userId: number, data: {
        companyRole: string,
        userBrandPermissions?: { accountId: number, role: string }[]
    }) {
        return apiService.getRoute(this.routes['changeUserPermissions'], {accountId, userId})
            .post(data)
            .then(res => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    changeUserBrandPermission(accountId: number, userId: number, data: {
        userBrandPermission: {
            accountId: number,
            role: string | null,
            approvalPriceLimit?: number,
            approvalRequired?: boolean
        }
    }) {
        return apiService.getRoute(this.routes['changeUserBrandPermission'], {accountId, userId})
            .post(data)
            .then(res => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    deleteUserBrandPermission(brandId: number, userId: number) {
        return apiService.getRoute('account/{brandId}/deleteUserBrandPermission/{userId}', {userId, brandId})
            .delete()
            .then(res => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    findCompanyUserByEmail(email: string) {
        return apiService.getRoute(this.routes['findByEmail'], {email}, {showProjection: false})

            .get()
            .then(res => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    loginEvent(userId: number) {
        return apiService.getRoute(this.routes['loginEvent'], {userId})
            .post()
            .then(res => res)
            .catch((error: any) => {
                throw new BackendError(error);
            })
    }
}