import { IAccounts } from '../dataTypes/accounts';


export const company: Partial<IAccounts> = {
  id                    : undefined,
  activated             : false,
  clientTypesDescription: '',
  companyName           : '',
  companyUrlList        : [],
  contractFiles         : '',
  country               : '',
  databaseName          : '',
  dataClient            : false,
  dataProvider          : false,
  industry              : 'industry',
  industryIds           : [],
  notes                 : undefined,
  postalCode            : '',
  size                  : '',
  state                 : '',
  streetAddress         : '',
  tag                   : '',
  vertical              : '',
  subscriptionId        : 6,
}