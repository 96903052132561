import { GridCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { TDataColumnModel } from "../../utils/dataColumnModel.utils";
import { IAccounts } from "../dataTypes/accounts";
import { ICompanyUsers } from "../dataTypes/companyUser";

const flexOne = 1;

export const accountsColumns: TDataColumnModel = {
  id: {
    field: "id",
    headerName: "Account ID",
    width: 100,
    align: "center",
    headerAlign: "center",
    type: "label",
    sortable: true,
    cellClassName: "titleCell minCell",
  },
  companyName: {
    field: "companyName",
    headerName: "Company Name",
    flex: flexOne,
    minWidth: 130,
    align: "left",
    headerAlign: "left",
    type: "logo",
    sortable: true,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      return params.row.companyName;
    },
  },
  brand: {
    field: "brand",
    headerName: "#Brand",
    align: "center",
    headerAlign: "center",
    width: 150,
    type: "string",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      const brandLenght = params.row.brandAccountAssociations.length;
      return brandLenght;
    },
  },
  users: {
    field: "users",
    headerName: "#Users",
    align: "center",
    headerAlign: "center",
    width: 150,
    type: "string",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      const usersLenght = params.row.companyUsers.length;
      return usersLenght;
    },
  },
  country: {
    field: "country",
    headerName: "Country",
    align: "center",
    headerAlign: "center",
    width: 130,
    type: "string",
  },
  clientType: {
    field: "dataClient",
    headerName: "Client Type",
    align: "center",
    headerAlign: "center",
    width: 150,
    type: "string",
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      return params.row.dataClient ? "Data Client" : "Data Provider";
    },
  },
  accountType: {
    field: "accountType",
    headerName: "Account Type",
    align: "center",
    headerAlign: "center",
    width: 110,
    type: "string",
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      const accountType = params.row.accountType;
      if (accountType === "BRAND") {
        return "Brand";
      } else {
        return "Company";
      }
    },
  },

  activatedDate: {
    field: "creationTime",
    headerName: "Creation date",
    align: "left",
    headerAlign: "left",
    type: "date",
    width: 140,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      return params.row.creationTime;
    },
  },
  modifiedDate: {
    field: "modificationTime",
    headerName: "Modification date",
    align: "left",
    headerAlign: "left",
    type: "date",
    width: 140,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      return params.row.modificationTime;
    },
  },
};

export const dataClientColumns: TDataColumnModel = {
  id: accountsColumns.id,
  companyName: {
    field: "companyName",
    headerName: "Brand Name",
    flex: flexOne,
    minWidth: 130,
    align: "left",
    headerAlign: "left",
    type: "logo",
    sortable: true,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      return params.row.companyName;
    },
  },
  parentCompanyName: {
    field: "ParentCompanyName",
    headerName: "Parent Company Name",
    flex: flexOne,
    minWidth: 130,
    align: "left",
    headerAlign: "left",
    type: "string",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      const accountType = params.row.accountType;
      if (accountType === "BRAND") {
        if (params.row.parentAccountAssociations.length > 0) {
          return params.row.parentAccountAssociations[0].parentAccount.companyName;
        }
      }
      return "-";
    },
  },
  accountType: accountsColumns.accountType,
  users: {
    field: "users",
    headerName: "#Users",
    align: "center",
    headerAlign: "center",
    width: 150,
    type: "string",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<IAccounts>) => {
      const accountType = params.row.accountType;
      if (accountType === "BRAND") {
        return params.row?.agentBrandAccountAssociations?.length;
      }
      return params.row.companyUsers.length;
    },
  },
  country: accountsColumns.country,
  activatedDate: accountsColumns.activatedDate,
  modifiedDate: accountsColumns.modifiedDate,
};

export const dataProviderColumns: TDataColumnModel = {
  id: accountsColumns.id,
  companyName: accountsColumns.companyName,
  users: accountsColumns.users,
  country: accountsColumns.country,
  activated: {
    field: "activated",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    width: 110,
    sortable: true,
    type: "status",
  },
  activatedDate: accountsColumns.activatedDate,
  modifiedDate: accountsColumns.modifiedDate,
};
