import { create } from "zustand";
import { IDspChannels } from "application/entities/dataTypes/dspChannels";
import { mountStoreDevtool } from "simple-zustand-devtools";

type TChannelsSetupStore = {
  dspSelected: IDspChannels | undefined;
  currentStep: number;
  loading: boolean;
  accountId: number | undefined;
  setAccountId: (accountId: number | undefined) => void;
  setDspSelected: (dsp: IDspChannels | undefined) => void;
  setCurrentStep: (step: number) => void;
  reset: () => void;
};

const initialState: Pick<TChannelsSetupStore, "currentStep" | "dspSelected" | "loading" | "accountId"> = {
  dspSelected: undefined,
  currentStep: 1,
  loading: true,
  accountId: undefined,
};

export const useChannelsSetupStore = create<TChannelsSetupStore>((set, get) => ({
  ...initialState,
  setDspSelected: (dsp: IDspChannels | undefined) => set({ dspSelected: dsp }),
  setAccountId: (accountId: number | undefined) => set({ accountId: accountId }),
  setCurrentStep: (step: number) => set({ currentStep: step }),
  reset: () => set(initialState),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("useChannelsSetupStore", useChannelsSetupStore);
}
