import {devApi} from '../../../../_configuration/api.config';
import apiRequest from '../../../../application/entities/api/interceptors';
import {ICompanyUsers} from '../../../../types';

// deprecate this file using main api classes

const API_URL = `${devApi}/accounts`;


export const updateAccountNotesPatch = (accountId: number, data: any) => {
  const headers = {'Content-Type': 'application/json-patch+json'};
  const URL     = `${API_URL}/${accountId}`;
  const add     = [{'op': 'add', 'path': '/notes/0', 'value': data.notes}];
  return apiRequest.patch(URL, add, {headers});
};

export const updateAccountUser = (userId: number, data: Partial<ICompanyUsers>) => {
  const URL = `${devApi}/companyUsers/${userId}`;
  return apiRequest.patch(URL, data);
};

export const deleteAccount = (id: number) => {
  const URL = `${API_URL}/${id}`;
  return apiRequest.delete(URL);
};