import {ITags} from '../dataTypes/tags';
import {AbstractBaseApi} from './AbstractBaseApi';

export class Tags extends AbstractBaseApi<ITags> {
  
  constructor() {
    super('tags');
  }
  
  create( data: Partial<ITags> & {account:string}) {
    // @ts-ignore
    return super.create(data);
  }
  
}