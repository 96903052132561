import { Box, Checkbox, Input, InputAdornment, MenuItem, Select, styled, Switch, TextField, Typography } from "@mui/material";
import React, { FunctionComponent, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAccountQuery } from "../../../components/hooks/data/useAccountQuery";
import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import { FormBlock, TextFieldBlock } from "../../../components/templates/InfoBlock";
import { useUserQuery } from "../../../components/hooks/data/useUserQuery";
import { ICompanyUsers } from "../../../application/entities/dataTypes/companyUser";
import { useQuery } from "react-query";
import { objectApi } from "../../../application/entities/dataApi";
import moment from "moment/moment";
import { queryClient } from "../../../application/utils/useQuery.utils";
import { StyledCheckbox } from "components/form/CheckBox/StyledCheckBox";
import { ReactHookFormItem } from "application/entities/dataTypes/forms";
import debounce from "lodash.debounce";

interface IAssociationsProps {
  companyId: any;
  userId: any;
}

export const UserBrandAssociationsEdit: FunctionComponent<IAssociationsProps> = (props: IAssociationsProps) => {
  const accountApi = new objectApi.accounts();
  const uriParams = useParams<{ companyId: string }>() as { companyId: string };
  const companyId = props?.companyId ?? +uriParams?.companyId;

  const redQy = useQuery(["account", companyId, "brandAccountAssociations"], () => {
    return accountApi.getBrandAccountAssociations(companyId).then((res) => res);
  });
  const subAccount: IAccounts["brandAccountAssociations"] = redQy?.data?.data?.brandAccountAssociations ?? [];
  const brandList = subAccount.map((x) => x.subAccount);

  return (
    <table className={"accountAssociations"} cellPadding={0} style={{ width: "100%", maxWidth: "100%" }}>
      <thead>
        <tr style={{ display: "block" }} data-cy={"table-headers"}>
          <td style={{ minWidth: "50px" }}>Status</td>
          <td style={{ minWidth: "130px", width: "130px" }}>Brand</td>
          <td style={{ minWidth: "115px" }}>Brand Role</td>
          <td style={{ minWidth: "120px", width: "120px", textAlign: "center" }}>Max. Audience Budget without Approval</td>
          <td style={{ textAlign: "center" }}>Approval required</td>
        </tr>
      </thead>
      <tbody style={{ maxHeight: "calc(100vh - 620px)", overflowY: "auto", display: "block" }}>
        {brandList.map((brand) => {
          return <UserBrandAssociationsLine key={brand.id} companyId={companyId} brand={brand} userId={props.userId} />;
        })}
      </tbody>
    </table>
  );
};

const UserBrandAssociationsLine = (props: { companyId: number; brand: IAccounts; userId: number }) => {
  const { companyId, brand, userId } = props;
  const accountApi = new objectApi.accounts();

  const redQy = useQuery(["account", companyId, "brandAccountAssociations"], () => {
    return accountApi.getBrandAccountAssociations(companyId).then((res) => res);
  });

  const cuQy = new objectApi.companyUsers();
  const userQuery = useUserQuery({ userId: props.userId });
  const userData: ICompanyUsers = userQuery?.data ?? {};
  const userAssocKeys: Record<number, any> = !userData.agentBrandAccountAssociations
    ? {}
    : userData.agentBrandAccountAssociations.reduce((acc, agentBrandAccountAssociations) => {
        return { ...acc, [agentBrandAccountAssociations.account.id]: agentBrandAccountAssociations };
      }, {});

  const userBrandPermission = userAssocKeys?.[brand.id];
  const role = userBrandPermission?.role;
  const approvalRequired = userBrandPermission?.approvalRequired;
  const approvalPriceLimit = userBrandPermission?.approvalPriceLimit;
  const creationTime = userBrandPermission?.creationTime;
  const newDate = moment(creationTime).format("D-MMM-YYYY");

  const getBrandUserPermission = (brandId: number) => {
    return userData.agentBrandAccountAssociations?.find((x) => x.account.id === brandId);
  };

  const [selectedState, setSelectedState] = React.useState(Boolean(userBrandPermission));
  const [approvalRequiredState, setApprovalRequiredState] = React.useState(Boolean(approvalRequired));
  const [approvalPriceLimitState, setApprovalPriceLimitState] = React.useState(approvalPriceLimit);
  const [roleState, setRoleState] = React.useState(role);

  function createAssociation(brandId: number, role?: any, approvalRequired?: boolean, approvalPriceLimit?: number) {
    const previousState = {
      approvalPriceLimitState,
      approvalRequiredState,
      roleState,
      selectedState,
    };

    if (["ADMINISTRATOR", "CONTRIBUTOR"].includes(role?.props?.value)) {
      approvalPriceLimit = 0;
      approvalRequired = false;
    }
    setApprovalPriceLimitState(approvalPriceLimit ?? 0);
    setApprovalRequiredState(approvalRequired ?? false);
    setRoleState((role?.props?.value as string) ?? (role as string) ?? undefined);
    setSelectedState(Boolean(role));

    cuQy
      .changeUserBrandPermission(companyId, props.userId, {
        userBrandPermission: {
          accountId: brandId,
          role: (role?.props?.value as string) ?? (role as string) ?? undefined,
          approvalRequired: approvalRequired ?? getBrandUserPermission(brandId)?.approvalRequired ?? false,
          approvalPriceLimit: approvalRequired ? approvalPriceLimit ?? getBrandUserPermission(brandId)?.approvalPriceLimit ?? 0 : 0,
        },
      })
      .then((res) => {
        redQy.refetch();
        userQuery.refetch();
      })
      .catch((e) => {
        console.error("error restore previous state", e, previousState);
        setApprovalPriceLimitState(previousState.approvalPriceLimitState);
        setApprovalRequiredState(previousState.approvalRequiredState);
        setRoleState(previousState.roleState);
        setSelectedState(previousState.selectedState);
      });
  }

  const handleFocus = (event: any) => {
    if (approvalPriceLimitState === 0) setApprovalPriceLimitState("");
  };

  return (
    <tr style={{ display: "block" }} key={brand.id} data-cy={"row-brand-permission"} data-cy-id={"brand_" + brand.id}>
      <td style={{ width: "50px" }}>
        <Switch
          data-cy="status_toggle"
          title={selectedState ? "Active since " + newDate : "Not Active"}
          onChange={(event: any, checked: boolean) => {
            createAssociation(brand.id, checked ? userData.role : undefined);
          }}
          checked={selectedState}
        />
      </td>
      <td style={{ width: "130px", maxWidth: "130px" }} data-cy={"brand-name"}>
        <Typography title={brand.companyName} noWrap>
          {brand.companyName}
        </Typography>
      </td>
      <td style={{ width: "115px" }}>
        {selectedState && (
          <Select
            data-cy={"brand_select_role"}
            fullWidth
            value={roleState}
            onChange={(event: any, newValue: any) => {
              createAssociation(brand.id, newValue);
            }}
            size={"small"}
          >
            <MenuItem data-cy={"administrator-role"} value={"ADMINISTRATOR"}>
              Administrator
            </MenuItem>
            <MenuItem data-cy={"creator-role"} value={"CREATOR"}>
              Creator
            </MenuItem>
            <MenuItem data-cy={"viewer-role"} value={"CONTRIBUTOR"}>
              Viewer
            </MenuItem>
          </Select>
        )}
      </td>
      <td style={{ textAlign: "center", width: "120px" }}>
        {selectedState && approvalRequiredState && roleState !== "CONTRIBUTOR" && (
          <>
            <TextField
              name={"approvalPriceLimit-" + brand.id}
              sx={{ width: "80px", fontSize: "10x", borderRadius: 0, textAlign: "center" }}
              inputProps={{ style: { textAlign: "left" } }}
              value={approvalPriceLimitState}
              type="number"
              InputProps={{
                sx: { textAlign: "center" },
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: "10px", mr: "0" }}>
                    $
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setApprovalPriceLimitState(Number(e.target.value))}
              onFocus={handleFocus}
              onBlur={(e) =>
                createAssociation(
                  brand.id,
                  getBrandUserPermission(brand.id)?.role,
                  getBrandUserPermission(brand.id)?.approvalRequired,
                  Number(e.target.value)
                )
              }
              data-cy={"max-budget"}
            />
          </>
        )}
      </td>
      <td style={{ width: "53px", textAlign: "center" }}>
        {selectedState && roleState == "CREATOR" && (
          <>
            <StyledCheckbox
              name={"approvalRequired-" + brand.id}
              disableRipple
              color={"primary"}
              size={"medium"}
              checked={approvalRequiredState}
              onChange={(event: any, newValue) => createAssociation(brand.id, getBrandUserPermission(brand.id)?.role, newValue)}
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
            />
          </>
        )}
      </td>
    </tr>
  );
};
