import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IApiMetaData} from '../../application/entities/api/apiService';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/rootReducer';

const initialState: Record<string, IApiMetaData> = {};
// hold queue for api message logging
const crudOpSlice                                = createSlice({
  name    : 'crudOpSlice',
  initialState,
  reducers: {
    addCrudOp(state, action: PayloadAction<IApiMetaData>) {
      const payloadData = action.payload
      const queryId     = payloadData.queryId
      
      const key = payloadData.operation + payloadData.entity
      state[key]    = payloadData;
    },
    deleteCrudOp(state, action: PayloadAction<IApiMetaData>) {
      const payloadData = action.payload
      const queryId     = payloadData.queryId
      const key = payloadData.operation + payloadData.entity
  
      delete (state[key])
    }
  },
});

export function useCrudOpAction() {
  const dispatch = useDispatch();
  return {
    addCrudOp   : (args: IApiMetaData) => {
      // security here
      window.setTimeout(() => {dispatch(crudOpSlice.actions.deleteCrudOp(args));}, 3000);
      return dispatch(crudOpSlice.actions.addCrudOp(args));
    },
    deleteCrudOp: (args: IApiMetaData) => {
      return dispatch(crudOpSlice.actions.deleteCrudOp(args));
    },
  }
}

export function useCrudOpSlice() {
  return useSelector((state: RootState) => state.crudOpSlice || {});
}

export default crudOpSlice.reducer;