import { Typography } from "@mui/material";
import { IAccounts } from "application/entities/dataTypes/accounts";
import { ICompanyUsers } from "application/entities/dataTypes/companyUser";
import { PageId } from "application/pages/pages.config";
import { AccountUsersList } from "pages/Accounts/AccountUsersList";

export const AdminAccountUsersList = (props: { account: IAccounts }) => {
  const { account } = props;

  const users = account?.companyUsers ?? [];

  let brandUsers: ICompanyUsers[] = [];
  if (account.agentBrandAccountAssociations) {
    account.agentBrandAccountAssociations.forEach((brand) => {
      brandUsers.push(brand.user);
    });
  }

  return (
    <div>
      {(account?.accountType === "COMPANY" || account?.dataProvider === true) && (
        <>
          {users.length === 0 && <Typography>No users </Typography>}
          <AccountUsersList companyUsers={users} pageIdLink={PageId.userProfileView} />
        </>
      )}
      {account?.accountType === "BRAND" && (
        <>
          {brandUsers.length === 0 && <Typography>No specific users, but all company's administrators has access to this brand </Typography>}
          <AccountUsersList companyUsers={brandUsers} pageIdLink={PageId.userProfileView} />
        </>
      )}
    </div>
  );
};
