import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";
import { Box, Theme, styled } from "@mui/material";
import { Input } from "components/form/Input";

interface ICardStripeElementProps {
  onChange: (event: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent) => {};
}

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "12px",
      "::placeholder": {
        color: "#ccc",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const CardStripeElementD = (props: ICardStripeElementProps) => {
  const { onChange } = props;

  return (
    <Box sx={{ width: "225px", margin: "auto" }}>
      <Box sx={{ p: 1 }}>
        <Input type={"text"} name={"name"} label={"Cardholder Name"} required={true} />
      </Box>
      <Box sx={{ p: 1 }}>
        <Input
          type={"element"}
          name={"card-number-element"}
          label={"Card Number"}
          inputElement={<CardNumberElement id="card-number-element" options={cardStyle} onChange={onChange} />}
        />
      </Box>
      <CreditCardWrapper>
        <Box sx={{ p: 1 }}>
          <Input
            type={"element"}
            name={"card-expiry-element"}
            label={"Card Expiration Date"}
            inputElement={<CardExpiryElement id="card-expiry-element" options={cardStyle} onChange={onChange} />}
          />
        </Box>
        <Box sx={{ p: 1 }}>
          <Input
            type={"element"}
            name={"card-cvc-element"}
            label={"Card CVV"}
            inputElement={<CardCvcElement id="card-cvc-element" options={cardStyle} onChange={onChange} />}
          />
        </Box>
      </CreditCardWrapper>
    </Box>
  );
};

export const AddCardForm = (props: ICardStripeElementProps) => {
  const { onChange } = props;

  return <CardStripeElementD onChange={onChange} />;
};

export const CreditCardWrapper = styled("div")((props: { theme: Theme }) => ({
  display: "flex",
  width: "100%",
  gap: "1rem",
  marginTop: "-1rem",
}));
