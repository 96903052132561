import {QueryClientProvider} from 'react-query';
import {queryClient} from '../../application/utils/useQuery.utils';
import React from 'react';


// needed to bring queryClientProvider to authentication components
export function withQueryClient(WrappedComponent: any): React.FC<{}>{
  
  return (props: any)=>{
    return  <QueryClientProvider client={queryClient}><WrappedComponent/></QueryClientProvider>
  }
  
}