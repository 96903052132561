import React from 'react';
import styled from 'styled-components/macro';
import {MainFrame} from '../../components/templates/MainFrame';
import {ActionButtons} from '../../components/controls/ActionButtons';
import {actions} from '../../application/actions/actions';
import {ClientDashboard} from './ClientDashboard';
import {AUTHUser} from '../../application/utils/AuthUser';
import {EUserRole} from '../../application/utils/granter.utils';
import {ProviderDashboard} from './ProviderDashboard';

export const Dashboard    = () => {

  
  const headerButtons = <ActionButtons actions={actions.dashboard} vars={{}}/>;
  const data_client   = AUTHUser.hasDataType(EUserRole.DATA_CLIENT);
  const data_provider = AUTHUser.hasDataType(EUserRole.DATA_PROVIDER);
  
  return (
    <MainFrame
      frameId={'dashboard'}
      headerProps={{headerButtons}}
      hideHeader={true}>
      <Content>
        {data_client && (
          <div style={{margin: '2rem auto'}}>
            <ClientDashboard/>
          </div>
        )}
        {data_provider && (
          <div style={{margin: '2rem auto'}}>
            <ProviderDashboard/>
          </div>
        )}
      </Content>
    </MainFrame>
  );
};
export const Content      = styled('div')`
  padding: 1rem;
  overflow: auto;
  height: 100%;
`;
