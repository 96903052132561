import {styled, Theme} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import {PageId} from '../../application/pages/pages.config';
import {MainFrame} from '../../components/templates/MainFrame';
import {AccountHeader} from '../Agencies/AccountHeader';
import {ClientDashboard} from '../Dashboard/ClientDashboard';
import {useAccountTracker} from '../Agencies/useAccountTracker';

interface IViewBrandProps {
  brandId?: number;
};


export const DashBoardBrand: FunctionComponent<IViewBrandProps> = (props: IViewBrandProps) => {
  const {t} = useTranslation();
  
  // receive accountId and brandId in uri.params or props
  const uriParams              = useParams<{ brandId: string; }>() as { brandId: string };
  const brandId                = props?.brandId ?? +uriParams?.brandId;
  //
  const accountQuery           = useAccountQuery(brandId);
  const accountData: IAccounts = accountQuery?.data ?? {};
  
  // account tracker and mode setter
  useAccountTracker(accountData.id);
  
  return <MainFrame frameId={PageId.brandDashboard}
                    checkPageAccess={true}
                    grantWith={{accountId: accountData.id}}
                    loading={accountQuery?.isLoading}
                    browserTitle={'Home - ' + accountData?.companyName}
                    mainFrameHeaderContent={<AccountHeader
                      accountId={brandId}
                      frameId={PageId.brandDashboard}/>}>
    <Container>
      <div style={{maxWidth: '1980px'}}><ClientDashboard account={accountData} brandId={brandId}/></div>
    </Container>
  </MainFrame>;
};

const Container = styled('div')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
  padding        : '2em',
  overflow       : 'auto'
}));