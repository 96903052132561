import {Box, Skeleton, styled, Theme, Typography} from "@mui/material"
import {IAudiences} from "application/entities/dataTypes/audiences"
import {providerIconList} from "assets/providerIcons"
import {ShadowBox} from "components/templates/InfoBlock"
import {AppIcon} from "components/ui/AppIcon"
import React from "react"
import {useTranslation} from "react-i18next"
import {profilesFormatter} from "_configuration/formaters"
import {moneyFormatter} from "application/utils/formatters.utils"


type TChannelSummaryProps = {
    audience: IAudiences
    loading: boolean
}

export const ChannelSummary = (props: TChannelSummaryProps) => {
    const {audience, loading} = props
    const {t} = useTranslation();
    const currency = audience?.displayCurrency

    const totalProfilesPayOnce = audience?.audienceActivationRecap?.reduce((acc: number, item: any) => {
        if (item.isImpressionBasedBuy) {
            return acc
        }
        return acc + item.profilesNumber
    }, 0)
    const totalProfilesImpressionBasedBuy = audience?.audienceActivationRecap?.reduce((acc: number, item: any) => {
        if (item.isImpressionBasedBuy) {
            return acc + item.profilesNumber
        }
        return acc
    }, 0)
    const totalSpend = audience?.audienceActivationRecap?.reduce((acc: number, item: any) => {
        return acc + item.pricePaidConverted
    }, 0)

    const [dspList, setDspList] = React.useState<any>([]);

    React.useEffect(() => {
        if (audience?.id) {
            const dsps = Array.from(audience?.dspCampaignInfo)
            let dspList: any = []
            dsps.forEach((dsp: any) => {
                dsp.dspItems.forEach((item: any) => {
                    dspList.push(item)
                })
            })
            setDspList(dspList)

        }
    }, [audience])

    const getDspInfo = (dspCode: string) => {
        return dspList.find((dsp: any) => dsp.code === dspCode)
    }


    return <>
        <Typography variant={'h5'} style={{paddingBottom: '30px'}}>Audience Cost and Enablement Channel
            Summary</Typography>
        {loading ? <Skeleton variant="rectangular" height={150} style={{borderRadius: '10px'}}/> :
            <ShadowBox className={'pad-2'} style={{padding: '10px 20px', backgroundColor: '#fff'}}
                       data-cy={'dashboard-your-team'}>
                <table className="channelSummary" data-cy="channel_summary"
                       data-cy-count={audience?.audienceActivationRecap?.length}>
                    <thead>
                    <tr>
                        <th align="left">{t('dsp.enablementChannel')}</th>
                        <th align="left">
                            <Typography>{t('dsp.enablementOption')}</Typography>
                        </th>
                        <th>
                            <Typography>{t('dsp.BuyerId')}</Typography>
                        </th>
                        <th><Typography>{t('dsp.cmp')}</Typography></th>
                        <th><Typography>{t('dsp.desiredNbProfile')}</Typography></th>
                        <th><Typography>{t('dsp.desiredSpend')}</Typography></th>
                        {totalProfilesImpressionBasedBuy > 0 &&
                            <th style={{width: '80px'}}><Typography>Impression based buy</Typography></th>}
                        {totalProfilesImpressionBasedBuy > 0 &&
                            <th style={{width: '80px'}}><Typography>Campaign End Date</Typography></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {audience?.audienceActivationRecap?.map((dspItem: any, index: number) => {
                        return (
                            <tr key={dspItem.id} data-cy={'dsp' + dspItem.id}>
                                <td align="left">
                                    <div className={'flex-h flex-align-middle gap-small align-left'}>
                                        <div className={'iconCircle'}>
                                            <AppIcon fontSize={'small'} icon={providerIconList?.[dspItem.dspCode]}/>
                                        </div>
                                        <div>
                                            <Typography sx={{fontWeight: 'bold', fontSize: '14px'}}
                                                        data-cy={'activationPlatform'}>{getDspInfo(dspItem.dspCode)?.seatName}</Typography>
                                            <Typography
                                                data-cy={'seatName'}> {getDspInfo(dspItem.dspCode)?.name}</Typography>
                                        </div>
                                    </div>
                                </td>
                                <td align="left">
                                    <Typography data-cy={'dspName'}>{dspItem.dspName}</Typography>
                                </td>
                                <td align="center">
                                    <Typography data-cy={'advertiserId'}
                                                noWrap={true}>{getDspInfo(dspItem.dspCode)?.advertizerId}</Typography>
                                </td>
                                <td align="center">
                                    <Typography
                                        data-cy={'cpm'}>{moneyFormatter(dspItem.cpmConverted, {currency: currency})}</Typography>
                                </td>
                                <td align="center">
                                    <Typography>{profilesFormatter.format(dspItem.profilesNumber)}</Typography>
                                </td>
                                <td align="center">
                                    {dspItem.isImpressionBasedBuy > 0 ?
                                        <Typography data-cy={'pricePaid'}>-</Typography> :
                                        <Typography>{moneyFormatter(dspItem.pricePaidConverted, {currency: currency})}</Typography>
                                    }
                                </td>
                                <td>
                                    {dspItem.isImpressionBasedBuy > 0 && <AppIcon icon="CheckboxChecked"/>}
                                </td>
                                {totalProfilesImpressionBasedBuy > 0 && <td align="center">
                                    {dspItem?.campaignEndDate ? new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",

                                    }).format(new Date(dspItem.campaignEndDate)) : 'no set'}
                                </td>}
                            </tr>
                        )
                    })}
                    {totalProfilesImpressionBasedBuy > 0 ?
                        <>
                            <tr className="total">
                                <td colSpan={4} align="right">
                                    <Typography>Total pay once</Typography>
                                </td>
                                <td align="center">
                                    <Typography
                                        data-cy="total_profiles">{profilesFormatter.format(totalProfilesPayOnce)}</Typography>
                                </td>
                                <td align="center">
                                    <Typography
                                        data-cy="total_desidered_spend">{moneyFormatter(totalSpend, {currency: currency})}</Typography>
                                </td>
                                <td></td>
                            </tr>
                            <tr className="total">
                                <td colSpan={4} align="right">
                                    <Typography>Total impression based buy</Typography>
                                </td>
                                <td align="center">
                                    <Typography
                                        data-cy="total_profiles">{profilesFormatter.format(totalProfilesImpressionBasedBuy)}</Typography>
                                </td>
                                <td align="center">
                                    <Typography data-cy="total_desidered_spend">-</Typography>
                                </td>
                                <td></td>
                            </tr>
                        </> :
                        <tr className="total">
                            <td colSpan={4} align="left">
                                <Typography>Total</Typography>
                            </td>
                            <td align="center">
                                <Typography
                                    data-cy="total_profiles">{profilesFormatter.format(totalProfilesPayOnce)}</Typography>
                            </td>
                            <td align="center">
                                <Typography
                                    data-cy="total_desidered_spend">{moneyFormatter(totalSpend, {currency: currency})}</Typography>
                            </td>
                            <td></td>
                        </tr>
                    }
                    </tbody>
                </table>
            </ShadowBox>
        }
    </>
}

const DspHeader = styled(Box)((props: { theme: Theme }) => ({
    color: '#7587A3'
}));

const DspRow = styled(Box)((props: { theme: Theme, active?: boolean }) => ({
    backgroundColor: props.active ? '#F7F7F7' : '',
    border: `1px solid ${Boolean(props.active) ? '#00B5E2' : 'transparent'}`,
    borderTop: `1px solid ${Boolean(props.active) ? '#00B5E2' : '#E4EAF0'}`,
    borderRadius: props.active ? '8px' : '0',
    padding: '0.25rem'
}));