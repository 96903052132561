import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components/macro';

import {useTranslation} from 'react-i18next';
import {objectApi} from '../../../application/entities/dataApi';
import {IVault, OperationStatus} from '../../../application/entities/dataTypes/vault';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {useAuthState} from '../../../redux/slices/auth.slices';
import {ErrorResponse} from '../../../types';
import {buttonLabels, ColorButton} from '../../../components/form/Buttons';
import {useAppToast} from '../../../components/live/AppToast';
import {useOpenDialogWindow} from '../../../components/live/DialogWindow';
import {MainFrame} from '../../../components/templates/MainFrame';
import {ApiAccessKeys} from './ApiAcceessKeys';
import {CreateVault} from './CreateVault';
import {DataVaultChangePassword} from './DataVaultChangePassword';
import {AppSwitch} from '../../../components/form/AppSwitch';
import {BackendError} from '../../../types/backendError';
import {Link} from '@mui/material';
import {useAdminPage} from 'components/hooks/useAdminPage';


interface IDataVaultSettingsProps {
}


export const DataVaultSetup: React.FC<IDataVaultSettingsProps> = () => {

    const {t} = useTranslation();
    const dialogWindow = useOpenDialogWindow();
    const appToast = useAppToast();
    const authState = useAuthState();
    useAdminPage()
    const accountsApi = new objectApi.accounts();

    let {accountId} = useParams<Record<string, string>>() as { accountId: number };
    const isSuperAdmin = authState?.user?.authorities?.includes('EVORRAADMIN');

    const [accessKeyId, setAccessKeyId] = useState();
    const [secretAccessKey, setSecretAccessKey] = useState();
    const [vault, setVault] = useState<IVault>();
    const [error, setError] = useState<Partial<ErrorResponse>>();
    const [disabled, setDisabled] = useState(true);
    const [createVaultDisabled, setCreateVaultDisabled] = useState(true);
    const [startDataPipelineWorkflowDisabled, setDataPipelineWorkflowDisabled] = useState(true);
    const [jobRunState, setJobRunState] = useState<OperationStatus>();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = React.useState<BackendError>();

    const updateVault = () => {
        if (accountId) {
            accountsApi.getVault(+accountId)
                .then(res => {
                    setVault(res);
                })
                .catch();

        }
    };

    React.useEffect(() => {
        if (accessKeyId && secretAccessKey) dialogWindow.open(apiAccessKeysDialogConf);
    }, [accessKeyId, secretAccessKey]);

    React.useEffect(() => {
        if (jobRunState === OperationStatus.SUCCEEDED || OperationStatus.FAILED) {
            updateVault();
        }
    }, [jobRunState]);

    React.useEffect(() => {
        if (accountId) {
            setLoading(true);
            accountsApi.getVault(+accountId)
                .then(res => {
                    setVault(res);
                    if (accountId && res.lastDataProcessingStatus === OperationStatus.PENDING) {
                        accountsApi.getDataPipelineWorkflowJob(+accountId)
                            .then(response => {
                                    const data = JSON.parse(response.data.body).JobRun;
                                    setJobRunState(data.JobRunState);

                                    appToast.open({
                                        toastId: 'manageAccount',
                                        severity: 'info',
                                        message: `${t('vaultSettingsPageLabels.jobRunState')} ${data.JobRunState}` ?? 'getDataPipelineWorkflowJob OK.',
                                    });
                                    if (response.data.errorType) {
                                        const glueJobMessage = response.data.errorType;
                                        appToast.open({
                                            toastId: 'manageAccount',
                                            severity: 'error',
                                            message: `${t('vaultSettingsPageLabels.pipelineJobError')} ${glueJobMessage}` ?? t('labels.error'),
                                        });
                                    }
                                },
                            );
                        updateVault();
                    }
                })
                .catch((err) => {
                    setLoadingError(err);
                    setError(err?.response?.data?.error);
                    const message = err?.response?.data?.error?.message;
                    appToast.open({
                        toastId: 'manageAccount',
                        severity: 'error',
                        message: message ?? t('labels.error'),
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, []);

    React.useEffect(() => {
        if (vault?.lastVaultOperationStatus === OperationStatus.SUCCEEDED || vault?.lastVaultOperationStatus === OperationStatus.FAILED || error?.reason === 'NOT_FOUND' || vault && !vault.lastVaultOperationStatus) {
            setCreateVaultDisabled(false);
        }

        return () => {
            setCreateVaultDisabled(true);
        };
    }, [vault?.lastVaultOperationStatus, error]);

    React.useEffect(() => {
        if (vault?.lastVaultOperationStatus === OperationStatus.SUCCEEDED || error?.reason === 'NOT_FOUND') {
            setDisabled(false);
        }

        return () => {
            setDisabled(true);
        };
    }, [vault?.lastVaultOperationStatus, error]);


    React.useEffect(() => {
        if (vault?.lastDataProcessingStatus === OperationStatus.PENDING || vault?.lastVaultOperationStatus !== OperationStatus.SUCCEEDED) {
            setDataPipelineWorkflowDisabled(true);
        }
        if (vault?.lastDataProcessingStatus !== OperationStatus.PENDING && vault?.lastVaultOperationStatus === OperationStatus.SUCCEEDED) {
            setDataPipelineWorkflowDisabled(false);
        }
    }, [vault?.lastDataProcessingStatus, vault?.lastVaultOperationStatus]);

    const acceptResetApiKeys = () => {
        if (accountId) {
            accountsApi.getVaultKeys(+accountId)
                .then(res => {
                    setAccessKeyId(JSON.parse(res.data.body).AccessKeyId);
                    setSecretAccessKey(JSON.parse(res.data.body).SecretAccessKey);
                })
                .catch((err) => {
                    const message = err?.response?.data?.error?.message;
                    appToast.open({
                        toastId: 'manageAccount',
                        severity: 'error',
                        message: message ?? t('labels.error'),
                    });
                });

        }
    };

    const apiAccessKeysDialogConf = {
        dialogId: 'apiAccessKeys',
        buttonActions: {acceptButton: false, cancelButton: buttonLabels.close},
        component: ApiAccessKeys,
        componentProps: {
            accessKeyId,
            secretAccessKey,
        },
    };

    const apiKeyDialogConf = {
        dialogId: 'ResetApiKeys',
        buttonActions: {acceptButton: buttonLabels.confirm, cancelButton: true},
        description: 'If you confirm this action a new pair of keys \n will be generated. They will' +
            ' disable and replace the previous ones. You will have to save them as they will not be displayed to you again.',
        onAccept: acceptResetApiKeys,
    };

    const dataVaultPasswordDialogConf = {
        dialogId: 'ResetDataVaultPassword',
        buttonActions: {acceptButton: false, cancelButton: false},
        component: DataVaultChangePassword,
        componentProps: {
            accountId: +accountId,
        },
    };


    const handleResetPassword = () => {
        dialogWindow.open(dataVaultPasswordDialogConf);
    };

    const handleResetApiKeys = () => {
        dialogWindow.open(apiKeyDialogConf);
    };

    const onSwitchEnableTracker = (event: any, checked: boolean) => {
        const p: any = {
            'enableTracker': Boolean(checked),
        };
        if (vault) {
            accountsApi.patchVault(vault.id, p)
                .then(() => {
                    updateVault();
                })
                .catch((error: any) => {
                    const message = error?.response?.data?.error?.message;
                    appToast.open({
                        toastId: 'manageAccount',
                        severity: 'error',
                        message: message ?? t('labels.error'),
                    });
                });
        }
    };

    const onSwitchEnableUpload = (event: any, checked: boolean) => {
        const p: any = {
            'enableUpload': Boolean(checked),
        };
        if (vault) {
            accountsApi.patchVault(vault.id, p)
                .then(() => {
                    updateVault();
                })
                .catch((error: any) => {
                    const message = error?.response?.data?.error?.message;
                    appToast.open({
                        toastId: 'manageAccount',
                        severity: 'error',
                        message: message ?? t('labels.error'),
                    });
                });
        }
    };

    const handleStartDataPipelineWorkflow = (accountId?: number) => {
        if (!accountId) return;
        accountsApi.startDataPipelineWorkflow(accountId)
            .then((res) => {
                    setDataPipelineWorkflowDisabled(true);
                    // const data = JSON.parse(res.data.body).JobRun;
                    // console.log('res', JSON.parse(res.data.body).JobRunId);
                    const JobRunId = JSON.parse(res.data.body).JobRunId;
                    if (vault && JobRunId) {
                        setVault({...vault, lastDataProcessingStatus: OperationStatus.PENDING});
                    }

                    appToast.open({
                        toastId: 'manageAccount',
                        severity: 'info',
                        message: t('vaultSettingsPageLabels.requestStartPipelineOk'),
                    });

                },
            )
            .catch(err => {
                const message = err?.response?.data?.error?.message;
                appToast.open({
                    toastId: 'manageAccount',
                    severity: 'error',
                    message: message ?? t('labels.somethingWrong'),
                });
            });
    };


    const showFrame = AUTHUser.isEvorraAdmin() || AUTHUser.accountId === +accountId; // temp /
    // integrate in
    // MainFrameGrant

    const messageVaultCreated = vault
        ? `The ${vault.id} Vault is created.`
        : `No vault is created yet`;

    return (
        <>
            <MainFrame restrict404={!showFrame}
                       loading={loading}
                       backendError={loadingError}
                       frameId="data-vault-setup">
                <ContentContainer>
                    <ContentBlock>
                        {isSuperAdmin ?
                            <ContentBlock>
                                <SettingsContainer>
                                    <AppSwitch
                                        onChange={onSwitchEnableTracker}
                                        label={<Header>{t('vaultSettingsPageLabels.enableTracker')}</Header>}
                                        value={vault?.enableTracker}
                                        checked={Boolean(vault?.enableTracker)}
                                    />
                                </SettingsContainer>
                                <SettingsContainer>
                                    <AppSwitch
                                        onChange={onSwitchEnableUpload}
                                        label={<Header>{t('vaultSettingsPageLabels.enableUpload')}</Header>}
                                        value={vault?.enableUpload}
                                        checked={Boolean(vault?.enableUpload)}
                                    />
                                </SettingsContainer>
                                <SettingsContainer>
                                    <CreateVault
                                        disabled={disabled}
                                        setDisabled={setDisabled}
                                        accountId={+accountId}
                                        vault={vault}
                                        setVault={setVault}
                                        createVaultDisabled={createVaultDisabled}
                                        setCreateVaultDisabled={setCreateVaultDisabled}
                                    />
                                </SettingsContainer>
                            </ContentBlock> : null}

                        {vault ? (<SettingsContainer>
                                <SettingsContent>
                                    <Header>
                                        {t('vaultSettingsPageLabels.yourVault')}
                                    </Header>
                                    {vault.awsAccountUrl ?
                                        <AccountUrl href={vault.awsAccountUrl} target="_blank">
                                            {'AWS Account URL'}
                                        </AccountUrl>
                                        : <AwsIdentity>
                                            {'No awsAccountId'}
                                        </AwsIdentity>}
                                    <Header>
                                        {t('vaultSettingsPageLabels.yourIdentity')}
                                    </Header>
                                    {vault.awsAccountId ?
                                        <AwsIdentity>
                                            {vault.awsAccountId}
                                        </AwsIdentity> : <AwsIdentity>{'No awsAccountId'}</AwsIdentity>}
                                    <Header>
                                        {t('vaultSettingsPageLabels.awsIamUserName')}
                                    </Header>
                                    {vault.awsAccountId ?
                                        <AwsIdentity>
                                            {vault.name}
                                        </AwsIdentity> : <AwsIdentity>{'No awsIamUserName'}</AwsIdentity>}
                                    <Header>
                                        {t('vaultSettingsPageLabels.yourBucket')}
                                    </Header>
                                    {vault.s3Url ?
                                        <AwsIdentity>
                                            {vault?.s3Url}
                                        </AwsIdentity> : <AwsIdentity>{'No Bucket'}</AwsIdentity>}
                                    <Header>
                                        {t('vaultSettingsPageLabels.yourTag')}
                                    </Header>
                                    {vault?.pixelTag ?
                                        <AwsIdentity>
                                            {vault?.pixelTag}
                                        </AwsIdentity> : <AwsIdentity>{'No Tag'}</AwsIdentity>}
                                    {isSuperAdmin ? <>
                                        <Header>
                                            {t('vaultSettingsPageLabels.dataPremium')}
                                        </Header>
                                        <AwsIdentity>
                                            {vault?.dataPremiumPercent + '%'}
                                        </AwsIdentity></> : null}
                                </SettingsContent>
                                <Divider>
                                </Divider>
                                <BtnContainer>

                                    <BtnBlock>
                                        <ColorButton
                                            onClick={() => handleResetPassword()}
                                            variant="contained"
                                            color="primary"
                                            disabled={disabled}
                                        >
                                            {t('vaultSettingsPageLabels.resetPassword')}
                                        </ColorButton>
                                    </BtnBlock>
                                    <BtnBlock>
                                        <ColorButton
                                            onClick={() => handleResetApiKeys()}
                                            variant="contained"
                                            color="primary"
                                            disabled={disabled}
                                        >
                                            {t('vaultSettingsPageLabels.resetApiKeys')}
                                        </ColorButton>
                                    </BtnBlock>

                                    <>{isSuperAdmin && (<BtnBlock>
                                        <ColorButton
                                            onClick={() => handleStartDataPipelineWorkflow(+accountId)}
                                            variant="contained"
                                            color="primary"
                                            disabled={startDataPipelineWorkflowDisabled}
                                        >
                                            {t('vaultSettingsPageLabels.triggerDataPipeline')}
                                        </ColorButton>
                                        <Status>
                                            {t('vaultSettingsPageLabels.lastDataProcessingStatus')}
                                        </Status>
                                        {vault?.lastDataProcessingStatus ?
                                            <Status>
                                                {vault.lastDataProcessingStatus}
                                            </Status> : <AwsIdentity>{`${vault?.lastDataProcessingStatus}`}</AwsIdentity>}
                                    </BtnBlock>)}</>

                                </BtnContainer>

                            </SettingsContainer>) :

                            (<AwsIdentity>{messageVaultCreated}</AwsIdentity>)
                        }
                    </ContentBlock>
                </ContentContainer>
            </MainFrame>
        </>
    );
};


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    margin: 30px;
`;

const ContentBlock = styled.div`
    width: 600px;
`;

const SettingsContainer = styled.div`
    margin: 0 40px;
`;

const SettingsContent = styled.div`
    display: grid;
    grid-template-columns: minmax(150px, 1fr) 2fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items: center;
    border-radius: 20px;
    margin: 20px 0;
`;

const Header = styled.div`
    justify-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.2px;
    color: #3B4559;
    margin: 10px;
`;

const AwsIdentity = styled.div`
    padding: 14px;
`;

const Status = styled.div`
    padding: 5px 0 5px 10px;
`;

const AccountUrl = styled(Link)`
    padding: 14px;: hover {
    text-decoration: underline;
    cursor: pointer;
}
`;

const BtnContainer = styled.div`
    height: 150px;
`;

const BtnBlock = styled.div`
    display: flex;
    margin: 10px 5px;
    align-items: center;
`;

const Divider = styled.div`
    width: 100%;
    border: 1px solid #EEEEED;
    margin-bottom: 20px;
`;

