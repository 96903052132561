import { Skeleton } from "@mui/material";
import { profilesFormatter } from "_configuration/formaters";
import { objectApi } from "application/entities/dataApi";
import { moneyFormatter } from "application/utils/formatters.utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { IAccountConfParameters } from "types";
import { PropertiesList } from "../AdminAccountView";

export const AdminAdvancedParams = (props: { accountId: number; isCompany: boolean }) => {
  const { accountId, isCompany } = props;
  const accountsApi = new objectApi.accounts();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [accountConfParams, setAccountConfParams] = React.useState<IAccountConfParameters>({} as IAccountConfParameters);

  const { t } = useTranslation();

  React.useEffect(() => {
    accountsApi.getAdminAccountConfParameters(accountId).then((res) => {
      setAccountConfParams(res.data.accountConfParameters);
      setLoading(false);
    });
  }, [accountId]);

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width={"100%"} height={"200px"} style={{ borderRadius: "7px" }} />
      ) : (
        <>
          <PropertiesList>
            <li>
              <span>{t("account.accountConfParameters.enable_pay_later")}</span> {accountConfParams.enable_pay_later ? "Yes" : "No"}
            </li>

            <li>
              <span>{t("account.accountConfParameters.enable_impression_based_buy")}</span>{" "}
              {accountConfParams.enable_impression_based_buy ? "Yes" : "No"}
            </li>

            {isCompany && (
              <li>
                <span>{t("account.accountConfParameters.company_monthly_subscription_usd")}</span>{" "}
                {moneyFormatter(accountConfParams.company_monthly_subscription_usd, { currency: "USD" })}
              </li>
            )}
            <li>
              <span>{t("account.accountConfParameters.account_is_monthly_subscription")}</span>{" "}
              {accountConfParams.account_is_monthly_subscription ? "Yes" : "No"}
            </li>
            <li>
              <span>{t("account.accountConfParameters.brand_monthly_subscription_usd")}</span>{" "}
              {moneyFormatter(accountConfParams.brand_monthly_subscription_usd, { currency: "USD" })}
            </li>

            <li>
              <span>{t("account.accountConfParameters.account_price_multiplier")}</span> x{accountConfParams.account_price_multiplier}
            </li>
            <li>
              <span>{t("account.accountConfParameters.fixed_additional_cpm")}</span>{" "}
              {moneyFormatter(accountConfParams.fixed_additional_cpm, { currency: "USD" })}
            </li>
            <li>
              <span>{t("account.accountConfParameters.agency_additional_cpm")}</span>{" "}
              {moneyFormatter(accountConfParams.agency_additional_cpm, { currency: "USD" })}
            </li>
            <li>
              <span>{t("account.accountConfParameters.agency_cpm_multiplier")}</span> x{accountConfParams.agency_cpm_multiplier}
            </li>

            <li>
              <span>{t("account.accountConfParameters.max_enablement_profiles")}</span>{" "}
              {profilesFormatter.format(accountConfParams.max_enablement_profiles)}
            </li>
            <li>
              <span>{t("account.accountConfParameters.fixed_provider_list")}</span>{" "}
              {accountConfParams.fixed_provider_list?.length > 2 ? accountConfParams.fixed_provider_list : "Not set"}
            </li>
            <li>
              <span>{t("account.accountConfParameters.enable_first_party_data")}</span> {accountConfParams.enable_first_party_data ? "Yes" : "No"}
            </li>
          </PropertiesList>
        </>
      )}
    </>
  );
};
