import {Button, MenuItem, Select, SelectChangeEvent, styled, Switch, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {GridRowParams} from "@mui/x-data-grid";
import {PageId} from "application/pages/pages.config";
import {MainFrame} from "components/templates/MainFrame";
import _ from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {brandUsersColumns} from "../../application/entities/dataColumns/brandUsers.columns";
import {companyBrandRolesColumns} from "../../application/entities/dataColumns/companyBrandRoles.columns";
import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {IBrandRoles, IUserAccountAssociation} from "../../application/entities/dataTypes/brandRoles";
import {ICompanyUsers} from "../../application/entities/dataTypes/companyUser";
import {dataColumnModelUtils} from "../../application/utils/dataColumnModel.utils";
import {accountTypeName, EPermission, EUserGrade, roleName} from "../../application/utils/granter.utils";
import {Pager} from "../../components/controls/Pager";
import {ActionGranter} from "../../components/features/ActionGranter";
import {useAccountQuery} from "../../components/hooks/data/useAccountQuery";
import {useBrandAccountAssociation} from "../../components/hooks/data/useBrandAccountAssociation";
import {useCompanyBrandRoles} from "../../components/hooks/data/useCompanyBrandRoles";
import {useCompanyUsersQuery} from "../../components/hooks/data/useCompanyUsersQuery";
import {GridTable} from "../../components/organisms/GridTable";
import {AppTabs, TAppTabArgs} from "../../components/ui/Tabs";
import {AccountHeader} from "../Agencies/AccountHeader";
import {InviteUser} from "../Agencies/InviteUser";
import {useAccountTracker} from "../Agencies/useAccountTracker";
import {UserThumbView} from "../Agencies/UserThumbView";
import {BrandMenu} from "./shared/BrandMenu";

interface ListBrandAgentsProps {
    accountId?: number;
}

export const ListCompanyUsers = (props: ListBrandAgentsProps) => {
    //
    const {t} = useTranslation();
    // receive accountId and companyId in uri.params or props
    const uriParams = useParams<{ companyId: string }>() as { companyId: string };
    const companyId = props?.accountId ?? +uriParams?.companyId;

    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(50);
    const [activeBrand, setActiveBrand] = React.useState<IAccounts>({} as IAccounts);
    const [sortDir, setSortDir] = React.useState<"asc" | "desc">("desc");
    const [sortBy, setSortBy] = React.useState<string>("id");
    const [menuMode, setMenuMode] = React.useState<boolean>(false);
    const [includeAdmins, setIncludeAdmins] = React.useState<boolean>(false);
    const [disableSelectCompanyAdmin, setDisableSelectCompanyAdmin] = React.useState<boolean>(true);
    const [filterRole, setFilterRole] = React.useState<{
        brandId?: number;
        brandRole?: EUserGrade | undefined;
        companyRole?: EUserGrade | undefined;
        withCompanyAdmin?: boolean;
    }>({});

    // All Users, query for company screen
    const accountAllUsersQuery = useCompanyUsersQuery(
        companyId,
        {
            params: filterRole,
            pageSize,
            pageNumber,
            sortDir,
            sortBy,
        },
        {enabled: !menuMode}
    );
    const allUserData: ICompanyUsers[] = accountAllUsersQuery?.data?.data?._embedded?.companyUsers ?? [];
    const allUsersLoading = accountAllUsersQuery.isLoading;

    const brandRolesQuery = useCompanyBrandRoles(
        companyId,
        activeBrand?.id,
        {
            params: filterRole,
            pageSize,
            pageNumber,
            sortDir,
            sortBy,
        },
        {enabled: menuMode && Boolean(activeBrand?.id)}
    );

    const allBrandRolesData: IBrandRoles[] = brandRolesQuery?.data?.data?.userAccountAssociations ?? [];
    const usersByBrandLoading = brandRolesQuery.isLoading;

    const accountQuery = useAccountQuery(companyId);
    const accountData: IAccounts = accountQuery?.data ?? {};

    // CMVP-2101, we need the first brand of the list
    const brandListQuery = useBrandAccountAssociation(companyId);
    const brandListData = brandListQuery?.data ?? [];
    const brandLoading = brandListQuery.isLoading;

    const loading = allUsersLoading ? true : usersByBrandLoading ? true : brandLoading;

    const [inviteUser, setInviteUser] = React.useState<boolean>(false);
    const [activeUser, setActiveUser] = React.useState<ICompanyUsers>({} as ICompanyUsers);

    const brandRolesColumns = _.cloneDeep(companyBrandRolesColumns);
    const companyRoleColumns = _.cloneDeep(brandUsersColumns);

    companyRoleColumns.role.headerName = "Account Type";
    // account tracker and mode setter
    useAccountTracker(companyId);

    // tab List
    const tabList: TAppTabArgs[] = [
        {
            id: "all",
            text: "All Users",
        },
    ];
    //if it's a company (not a data provider)
    if (accountData.accountType === "COMPANY")
        tabList.push({
            id: "brand",
            text: "Users By Brand",
        });

    const finalData = menuMode && !activeBrand?.id ? [] : menuMode ? allBrandRolesData : allUserData;

    const finalColumns = menuMode ? brandRolesColumns : companyRoleColumns;

    React.useEffect(() => {
        if (menuMode) {
            // menu brand open
            setInviteUser(false);
            // CMVP-2101, we need the first brand of the list
            // select first brand  in list, and set activeBrand to it
            setActiveBrand(brandListData?.[0] ?? {});
        } else {
            // menu brand closed
            setActiveBrand({} as IAccounts);
            setFilterRole({});
            setInviteUser(false);
            setActiveUser({} as ICompanyUsers);
        }
    }, [menuMode]);

    React.useEffect(() => {
        // rebuild reference
        let newRole: typeof filterRole = {...filterRole};
        // amend filterRole
        newRole.withCompanyAdmin = includeAdmins;
        setFilterRole(() => newRole);
    }, [includeAdmins]);

    // enable or disable the switch selector for company admins
    React.useEffect(() => {
        setDisableSelectCompanyAdmin(!activeBrand?.id || ![EUserGrade.ADMINISTRATOR, undefined].includes(filterRole?.brandRole));
    }, [activeBrand?.id, filterRole?.brandRole]);

    function forgeUri(role: EUserGrade | "All") {
        const colRole = menuMode ? "brandRole" : "companyRole";

        let out: any = {};
        switch (menuMode) {
            case false:
                if (role !== "All") out[colRole] = role;
                break;
            case true: // brand mode
                out.withCompanyAdmin = includeAdmins;
                if (role !== "All") out[colRole] = role;
                out.brandId = activeBrand.id;
                break;
        }

        setFilterRole(out);
    }

    function changeAdminFilter() {
        setIncludeAdmins(!includeAdmins);
    }

    function handleRowClick(param: GridRowParams<ICompanyUsers | IUserAccountAssociation>) {
        // @ts-ignore
        setActiveUser(param.row.user ? param.row.user : param.row);
        setInviteUser(false);
    }

    function handlePageSizeChange(size: number) {
        setPageNumber(0);
        setPageSize(size);
    }

    function handlePageChange(size: number) {
        setPageNumber(size);
    }

    function switchTab(mode: "all" | "brand") {
        setMenuMode(mode !== "all");
    }

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: {value},
        } = event;
        forgeUri(value);
    };

    function brandMenuClick(param: IAccounts) {
        // reset Active user
        setActiveUser({} as ICompanyUsers);
        setActiveBrand(param);
        // reset adminFilter
        setIncludeAdmins(false);
        // add brandId to
        setFilterRole({brandId: param.id, brandRole: undefined});
    }

    // quick patch
    const selectorCol = menuMode ? "brandRole" : "companyRole";

    // error message
    let errorMessage;
    if (!activeBrand?.id && menuMode) errorMessage = t("userManagement.msgSelectBrand");
    if (activeBrand?.id && !finalData.length && !filterRole?.withCompanyAdmin) errorMessage = t("userManagement.msgSelectUserTypeForBrand");
    if (loading) errorMessage = "Loading, please wait";

    return (
        <MainFrame
            scrollable={false}
            checkPageAccess={true}
            frameId={PageId.companyUserList}
            grantWith={{accountId: accountData.id}}
            loading={accountAllUsersQuery?.isLoading}
            breadCrumbProps={{crumbTitle: "User Management", noParent: true}}
            browserTitle={"User Management - " + accountData?.companyName}
            mainFrameHeaderContent={<AccountHeader accountId={companyId} frameId={PageId.companyUserList}/>}
        >
            <Container className="flex-h h-full overflow-hidden w-full" style={{paddingTop: "1rem"}}>
                <div className="flex-main flex-h gap-medium overflow-hidden h-full">
                    {/*<div style={{marginLeft: '2rem',position:'absolute',border:'1px solid red',width:'700px'}} className={'pad'} />*/}
                    <div className={"flex-v"} style={{height: "100%", overflow: "hidden"}}>
                        <div style={{marginLeft: "2rem", borderColor: "#f0f2f6"}}
                             className={"flex-h flex-align-middle border-b"}>
                            <Typography className={"flex-main pad-tb-2"} variant="h5">
                                User Management
                            </Typography>
                            <div className={"pad-1"}>
                                <ActionGranter accesses={[{grade: EPermission.COMPANYADMIN}]}>
                                    <Button
                                        onClick={() => {
                                            setInviteUser(!inviteUser);
                                        }}
                                        variant={"outlined"}
                                        data-cy={"invite-user-button"}
                                    >
                                        Invite users
                                    </Button>
                                </ActionGranter>
                            </div>
                        </div>
                        <AppTabs
                            density={"dense"}
                            style={{marginLeft: "2rem"}}
                            tabList={tabList}
                            onClick={(param: any) => {
                                switchTab(param.id);
                            }}
                        >
                            <div className={"pad flex-h gap-medium flex-align-middle"}>
                                <div>
                                    <Typography color={"secondary"}>Filter by</Typography>
                                </div>
                                <div>
                                    <Select
                                        data-cy={menuMode ? "select_roles" : "select_account_type"}
                                        disabled={!activeBrand?.id && menuMode}
                                        displayEmpty
                                        onChange={handleChange}
                                        value={filterRole?.[selectorCol] ?? "All"}
                                        sx={{width: "150px"}}
                                    >
                                        <MenuItem value="All">All {menuMode ? "User Roles" : "Account Types"}</MenuItem>
                                        <MenuItem
                                            value={EUserGrade.ADMINISTRATOR}>{menuMode ? roleName["ADMINISTRATOR"] : accountTypeName["ADMINISTRATOR"]}</MenuItem>
                                        {!menuMode && <MenuItem
                                            value={EUserGrade.CONTRIBUTOR}>{accountTypeName["CONTRIBUTOR"]}</MenuItem>}
                                        {menuMode && <MenuItem
                                            value={EUserGrade.CONTRIBUTOR}>{roleName["CONTRIBUTOR"]}</MenuItem>}
                                        {menuMode && (
                                            <MenuItem disabled={!menuMode} value={EUserGrade.CREATOR}>
                                                {roleName["CREATOR"]}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </div>
                                {menuMode && (
                                    <div style={{color: disableSelectCompanyAdmin ? "#ccc" : ""}}
                                         className={"flex-h flex-align-middle gap-small"}>
                                        Show Admins
                                        <Switch
                                            data-cy="admin_toggle"
                                            disabled={disableSelectCompanyAdmin}
                                            onChange={() => {
                                                changeAdminFilter();
                                            }}
                                            checked={includeAdmins}
                                        />
                                    </div>
                                )}
                            </div>
                        </AppTabs>
                        <div
                            className={"flex-main flex-h overflow-hidden pos-rel"}
                            style={{position: "relative", overflow: "hidden", width: "calc( 630px + 2rem )"}}
                        >
                            <BrandMenu isOpen={menuMode} onClick={(brand: any) => brandMenuClick(brand)}
                                       activeBrand={activeBrand} companyId={companyId}/>
                            <div style={{
                                marginLeft: "2rem",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                                width: "100%"
                            }}>
                                <div className={"flex-v"}
                                     style={{height: "100%", overflow: "hidden", position: "relative", width: "100%"}}>
                                    <div className={"flex-main"}>
                                        {Boolean(menuMode && !activeBrand?.id) && (
                                            <div className={"flex-v flex-align-middle-center"} style={{height: "100%"}}>
                                                <Typography color={"secondary"}>{errorMessage}</Typography>
                                            </div>
                                        )}
                                        {Boolean(!menuMode || (activeBrand?.id && menuMode)) && (
                                            <GridTable
                                                noRowsText={errorMessage}
                                                dataCy="users-list"
                                                styleClass={"naked"}
                                                loading={loading}
                                                data={{
                                                    columns: dataColumnModelUtils.renderModel(finalColumns) as any,
                                                    rows: finalData as any,
                                                }}
                                                gridProps={{
                                                    getRowId: (row: any) => row?.id ?? row?.user?.id,
                                                    onRowClick: (param: GridRowParams<ICompanyUsers | IUserAccountAssociation>) => handleRowClick(param),
                                                    // sortingMode      : 'server',
                                                    // sortingOrder     : ['desc', 'asc'],
                                                    // sortModel        : [{field: sortBy, sort: sortDir}],
                                                    // onSortModelChange: handleSortModelChange,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <Pager
                                            onPageSizeChange={handlePageSizeChange}
                                            onPageChange={handlePageChange}
                                            activePage={pageNumber}
                                            maxCount={finalData?.length}
                                            pageSize={pageSize}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex-main"} style={{overflow: "hidden", height: "100%"}}>
                        <div style={{overflow: "auto", height: "100%", maxWidth: "550px"}}>
                            {Boolean(inviteUser) && (
                                <InviteUser
                                    accountId={companyId}
                                    onClose={() => setInviteUser(false)}
                                    onUserInvited={() => {
                                        accountQuery.refetch();
                                        setInviteUser(false);
                                    }}
                                />
                            )}
                            {!Boolean(inviteUser) && Boolean(activeUser.id) && (
                                <div>
                                    <UserThumbView accountId={companyId} userId={activeUser.id} onClose={() => {
                                        setActiveUser({} as ICompanyUsers)
                                    }
                                    }
                                                   onUserUpdate={() => accountAllUsersQuery.refetch()}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </MainFrame>
    );
};

const Container = styled("div")((props: { theme: Theme }) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
}));
