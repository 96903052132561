import { scaleLinear } from "d3-scale";
import React from "react";
import { useTranslation } from "react-i18next";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { IconSet } from "../../../assets/icons";

// const geoUrl = './continent.json';//
// 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

interface WorldMapProps {
  mapData: Record<string, number>;
}

export const WorldMap = (props: WorldMapProps) => {
  const { mapData } = props;

  const { t } = useTranslation();

  const minValue = 1;
  const maxValue = 100;

  const [content, setTooltipContent] = React.useState<string | undefined>(undefined);

  // @ts-ignore
  const colorScale = scaleLinear()
    .domain([minValue, maxValue])
    // @ts-ignore
    .range(["#b5bfd7", "#4572F9"]);

  return (
    <>
      <div style={{ position: "relative", height: "100%" }}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            zIndex: 2000,
            bottom: "0.5rem",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {content && (
            <div
              style={{
                display: "inline-block",
                marginInline: "auto",
                background: "#eee",
                borderRadius: "15px",
                padding: "7px 10px",
              }}
            >
              <div>{content}</div>
            </div>
          )}

          <div style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
            <div>{t("audience.fewerAudiences")}</div>
            <div>
              <IconSet.MapScales />
            </div>
            <div> {t("audience.moreAudiences")} </div>
          </div>
        </div>
        <ComposableMap
          projection={"geoMercator"}
          projectionConfig={{
            rotate: [-10, 0, 0],
            center: [0, 20],
            scale: 105,
          }}
          height={320}
        >
          <Geographies geography={"/world-110m.json"}>
            {({ geographies }) =>
              geographies.map((geo) => {
                // const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);

                const pop = geo.properties.POP_EST || 0;
                const profiles = mapData?.[geo.properties.ISO_A2 ?? geo.id] || 0;
                const profilesRate = profiles / pop > 1 ? 100 : (profiles / pop) * 100;
                let USformatter = new Intl.NumberFormat("en-US", {
                  notation: "compact",
                });
                let profilesFormatted = USformatter.format(profiles);

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                    // @ts-ignore
                    fill={profilesRate > minValue ? colorScale(profilesRate) : "#dbe0eb"}
                    onMouseEnter={() => {
                      const { NAME } = geo.properties;
                      setTooltipContent(`${NAME} — ${profilesFormatted} profiles`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent(undefined);
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
    </>
  );
};
