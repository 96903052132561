import { IAccounts } from "types";
import { PropertiesList } from "../AdminAccountView";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { AppIcon } from "components/ui/AppIcon";
import moment from "moment";
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import { Link } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

export const AdminAccountDetails = (props: { account: IAccounts }) => {
  const { account } = props;
  const accountType = account.accountType === "BRAND" ? "Brand" : "Company";
  const clientType = account.dataClient === true ? "Data Client" : "Data Provider";
  const [accountsBookmark, setAccountsBookmark] = useLocalStorageState<
    {
      id: number;
      companyName: string;
    }[]
  >("accountsBookmark", []);

  const toggleBookmark = (account: IAccounts) => {
    const favorite = accountsBookmark.find((fav) => fav.id === account.id);
    if (favorite) {
      const newBookmark = accountsBookmark.filter((fav) => fav.id !== account.id);
      setAccountsBookmark(newBookmark);
    } else {
      const newBookmark = [...accountsBookmark, { id: account.id, companyName: account.companyName }];
      setAccountsBookmark(newBookmark);
    }
  };
  const isInBookmark = accountsBookmark.find((fav) => fav.id === account.id) ? true : false;

  return (
    <>
      <PropertiesList>
        <li>
          <span>
            Bookmark{" "}
            <AppTip text="Bookmark will be stored in your browser. If you use another browser, it will not appear. If you clean the cache, all bookmarks will be destroyed"></AppTip>
          </span>
          <AppIcon
            icon="Favorite"
            fontSize="small"
            color={isInBookmark ? "#f9ed60" : "#7587A3"}
            fill={isInBookmark ? "#f9ed60" : "#7587A3"}
            onClick={() => toggleBookmark(account)}
          ></AppIcon>
        </li>
        <li>
          <span>Company Name</span> {account.companyName}
        </li>
        <li>
          <span>Account type</span> {accountType}
        </li>
        {account?.accountType === "BRAND" && (
          <li>
            <span> Parent Company </span>

            <Link style={{ textDecoration: "none" }} to={pageUtils.getPagePathById(PageId.adminAccountView, { accountId: account.parentCompanyId })}>
              #{account.parentCompanyId} {account?.parentAccountAssociations[0]?.parentAccount?.companyName}
            </Link>
          </li>
        )}
        <li>
          <span>Client type</span> {clientType}
        </li>
        <li>
          <span>Created on</span> {moment(account.creationTime).format("D-MMM-YYYY")}
        </li>
        <li>
          <span>Modified on</span> {moment(account.modificationTime).format("D-MMM-YYYY")}
        </li>
        <li>
          <span>Address</span> {account.streetAddress} <br /> {account.postalCode}- {account.city} <br /> {account.country}
        </li>
        <li>
          <span>V.A.T. Number</span> {account.billingVatNumber}
        </li>
        <li>
          <span>Company Size</span> {account.companySize}
        </li>
        <li>
          <span>Billing Email</span> {account.billingEmail}
        </li>
        <li>
          <span>Evorra account ID</span> {account.id}
        </li>
        <li>
          <span>Aws account ID</span> {account.awsAccountId}
        </li>
      </PropertiesList>
    </>
  );
};
