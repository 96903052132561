import { Chip, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EntityTreeView } from "../../../components/form/EntityTreeView";
import { companySizeList3 } from "../zOld/mock";
import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import { DetailsBlockGrid, TextFieldBlock, InputFieldLabel } from "../../../components/templates/InfoBlock";
import { array, object, string } from "yup";
import { validURL } from "application/validation/userValidationSchema";
import * as yup from "yup";

interface ICompanyDetailsProps {
  accountDetailsFormik: any;
  account: Partial<IAccounts> | Partial<IAccounts>;
  setAccount: (arg: any) => void;
  accountIndustry?: any;
}

export const EditAccountDetails: React.FC<ICompanyDetailsProps> = ({ accountDetailsFormik, account, setAccount, accountIndustry }) => {
  const [companyUrlListInput, setCompanyUrlListInput] = React.useState<any>(null);
  const [companyUrlListInputError, setCompanyUrlListInputError] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const handleIndustriesChange = (industry: any) => {
    if (industry.length) {
      const industryId = industry[0].id as Record<string, any>;
      setAccount({ ...account, industryIds: [industryId] });
    } else {
      setAccount({ ...account, industryIds: [] });
    }
  };

  const companyUrlSchema = object({
    url: array().of(yup.string().matches(validURL, "Enter some valid URLs")),
  });

  const onKeyDownInputCompanyUrl = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const companyUrls = companyUrlListInput.split("\n");
      companyUrlSchema
        .validate({ url: companyUrlListInput.split("\n") })
        .then((res) => {
          setCompanyUrlListInput("");
          setCompanyUrlListInputError(false);
          accountDetailsFormik.setValues({
            ...accountDetailsFormik.values,
            companyUrlList: [...accountDetailsFormik.values?.companyUrlList, ...companyUrls],
          });
        })
        .catch((err) => {
          setCompanyUrlListInputError(true);
        });
    }
  };

  const deleteChip = (url: string) => {
    accountDetailsFormik.setValues({
      ...accountDetailsFormik.values,
      companyUrlList: accountDetailsFormik.values?.companyUrlList.filter((item: string) => item !== url),
    });
  };

  return (
    <>
      <DetailsBlockGrid data-cy="user-detail-company">
        <div>
          <InputFieldLabel required={true} htmlFor="bootstrap-input">
            {t("account.accountDetailsLabels.companyName")}
          </InputFieldLabel>
          <TextFieldBlock>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              value={accountDetailsFormik.values.companyName}
              onBlur={accountDetailsFormik.handleBlur}
              onChange={accountDetailsFormik.handleChange}
              error={accountDetailsFormik.touched.companyName && Boolean(accountDetailsFormik.errors.companyName)}
              helperText={accountDetailsFormik.touched.companyName && accountDetailsFormik.errors.companyName}
              inputProps={{ "data-cy": "edit_companyName" }}
            />
          </TextFieldBlock>
        </div>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">{t("account.accountDetailsLabels.industry")}</InputFieldLabel>
          <TextFieldBlock data-cy={"user-detail-industry"}>
            <EntityTreeView
              entityName={"industries"}
              defaultField={"name"}
              hierarchyField={"name"}
              singleSelect={true}
              selectedEntityItems={accountIndustry}
              onChange={handleIndustriesChange}
            />
          </TextFieldBlock>
        </div>
        <div>
          <div>
            <InputFieldLabel htmlFor="bootstrap-input">{t("account.accountDetailsLabels.companySize")}</InputFieldLabel>
            <TextFieldBlock>
              <Select
                labelId="companySize"
                id="companySize"
                name="companySize"
                variant={"standard"}
                value={accountDetailsFormik.companySize as string}
                onChange={accountDetailsFormik.handleChange}
                fullWidth={true}
                data-cy={"edit-company-size"}
                {...accountDetailsFormik.getFieldProps("companySize")}
              >
                {companySizeList3.map((size) => (
                  <MenuItem value={size.id as string} key={size.id}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>
            </TextFieldBlock>
          </div>
        </div>
        <div></div>
        <div style={{ gridColumnStart: "1", gridColumnEnd: "3", display: "online" }}>
          <InputFieldLabel htmlFor="bootstrap-input">{t("account.accountDetailsLabels.companyUrl")}</InputFieldLabel>

          <TextFieldBlock>
            <TextField
              variant="standard"
              multiline
              fullWidth
              id="companyUrlList"
              name="companyUrlList"
              value={companyUrlListInput}
              onChange={(e) => setCompanyUrlListInput(e.target.value)}
              placeholder="Enter an url or paste a list of urls (1 by line) and press enter"
              inputProps={{ "data-cy": "edit-company-url" }}
              onKeyDown={onKeyDownInputCompanyUrl}
            />
            {companyUrlListInputError && <p style={{ paddingLeft: "0.5rem", color: "#FC6050", fontWeight: "bold" }}>Invalid url</p>}
          </TextFieldBlock>
          {accountDetailsFormik?.values?.companyUrlList !== "" && (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              {accountDetailsFormik?.values?.companyUrlList?.map((url: any, index: number) => {
                return <Chip key={url} sx={{ color: "#7587A3" }} size="small" label={url} variant="outlined" onDelete={() => deleteChip(url)} />;
              })}
            </div>
          )}
        </div>
      </DetailsBlockGrid>
    </>
  );
};
