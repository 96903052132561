import {Box, Button, Paper, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {idList} from "application/utils/fpd.utils";
import {MainFrame} from "components/templates/MainFrame";
import {useAccountConfParameters} from "pages/Agencies/useAccountConfParameters";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router";
import {ListTitle} from "../index";
import {CsvColMapping} from "./csvColMapping";
import {UploadParquetCsvZip} from "./uploadParquetCsvZip";
import InfoIcon from '@mui/icons-material/Info';

export const UploadFirstParty = () => {
    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    const accountsApi = new objectApi.accounts();
    const navigate = useNavigate();

    useAccountTracker(+accountId);
    const parentAccountId = useQuery(
        ["parentAccountId", +accountId],
        () => {
            return accountsApi.byId(+accountId);
        },
        {enabled: Boolean(accountId)}
    );
    const accountConfParameters = useAccountConfParameters(+accountId);

    const [file, setFile] = useState<{ data: Array<string[]> } | null>(null);
    const [uploadType, setUploadType] = useState<"csv" | "file" | null>('file');
    const [fileName, setFileName] = useState<string>("");

    const [title, setTitle] = useState<string>("Upload your file");
    useEffect(() => {
        if (file) {
            setUploadType("csv");
        }
    }, [file]);

    return (
        <>
            <MainFrame
                restrict404={accountConfParameters.data && !accountConfParameters.data?.enable_first_party_data}
                loading={accountConfParameters.isLoading}
                frameId={PageId.uploadFirstParty}
                scrollable={true}
                breadCrumbProps={{
                    params: {
                        companyId: parentAccountId?.data?.parentCompanyId,
                        brandId: accountId,
                        accountId: accountId,
                    },
                }}
            >
                <Box sx={{overflowY: "auto", height: "100%"}}>
                    <Paper
                        elevation={4}
                        sx={{
                            maxWidth: "1200px",
                            margin: "auto",
                            backgroundColor: "white",
                            borderRadius: "7px",
                            padding: "2rem",
                            mt: 4,
                            marginBottom: "2rem",
                        }}
                    >
                        <ListTitle title={title} withBorder={true}>
                            <Button variant="outlined"
                                    onClick={() => navigate(pageUtils.getPagePathById(PageId.myDatas, {accountId: accountId}))}>
                                Cancel
                            </Button>
                        </ListTitle>


                        {uploadType === null && (
                            <>


                                <InfoBox title="Supported IDs List">
                                    <Typography mb={1}>The IDs which can be uploaded in the file
                                        are:</Typography>
                                    <Box sx={{display: "grid", gridTemplateColumns: "repeat(auto-fit, 165px)"}}>
                                        {idList.map((idItem) => {
                                            return (
                                                <Box sx={{pl: 2}} key={idItem.value}>
                                                    - {idItem.label}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </InfoBox>
                            </>
                        )}

                        {file && uploadType === "csv" &&
                            <CsvColMapping file={file} fileName={fileName} setTitle={setTitle}/>}

                        {uploadType === "file" && <UploadParquetCsvZip/>}
                    </Paper>
                </Box>
            </MainFrame>
        </>
    );
};


const InfoBox = ({title, children}: { title: string, children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                border: "1px solid #ececec",
                borderRadius: "10px",
                padding: "8px",
                background: "radial-gradient(circle, #fefefe 0%, #fafafa 100%)",
                boxShadow: "inset 0px 0px 28px 0px #f1f1f1",
                margin: "auto",
                mt: 4,
                maxWidth: "696px",
            }}
        >
            <Box sx={{display: "flex", alignItems: "center", gap: "8px"}} mb={1}>
                <InfoIcon sx={{color: "#5b667e"}}/>
                <Typography sx={{fontSize: "16px", fontWeight: 900}}>{title}</Typography>
            </Box>

            {children}
        </Box>
    );
}