import * as yup from 'yup';


export const uploadFileValidationSchema = yup.object({
  fileName: yup
    .string()
    .trim()
    .min(3, 'Minimum 3 characters length') ,
  description: yup
    .string()
    .trim(),
});