import {objectApi} from "../../../application/entities/dataApi";
import {useUserQuery} from "../../../components/hooks/data/useUserQuery";
import {GlobalAvatar} from "../../../components/ui/GlobalAvatar";
import {Box, Button, InputAdornment, MenuItem, Select, Switch, TextField, Typography, useTheme} from "@mui/material";
import React from "react";
import {InputFieldLabel, ShadowBox, TextFieldBlock} from "../../../components/templates/InfoBlock";
import moment from "moment/moment";
import {Controller, useForm} from "react-hook-form";
import {accountTypeName, EUserGrade, roleName} from "../../../application/utils/granter.utils";
import {useAppToast} from "../../../components/live/AppToast";
import {BackendError} from "../../../types/backendError";
import {useOpenDialogWindow} from "../../../components/live/DialogWindow";
import {useTranslation} from "react-i18next";

type UserBrandPermissionFormProps = {
    brandId: number
    userId: any;
    onUserUpdate?: () => void;
    onClose?: () => void;
}

export const UserBrandPermissionForm = (props: UserBrandPermissionFormProps) => {
    const theme = useTheme();

    const userQuery = useUserQuery({userId: props.userId});

    const userData = userQuery?.data


    const companyRole = userQuery.data?.role;
    const isCompanyAdmin = ["ACCOUNT_SUPERADMIN", "ADMINISTRATOR"].includes(companyRole)


    const userPermissions = userQuery.data?.agentBrandAccountAssociations?.find((permission: any) => permission.account.id === props.brandId);


    if (!userData) return <></>

    const userUpdated = () => {
        if (props.onUserUpdate) props.onUserUpdate()
        userQuery.refetch()

    }

    return <>
        <ShadowBox style={{margin: '1rem'}}>
            <div style={{backgroundColor: theme.palette.background.paper}}
                 className="flex-h flex-align-middle gap-small border-b pad">
                <GlobalAvatar
                    user={userData}
                    isPending={userData.status !== 'ACTIVATED'}
                    size={'34px'}
                    fontSize={'18px'}
                />
                <div className={'flex-main'}>
                    <div>
                        {userData.firstName} {userData.lastName}
                    </div>
                    <div>
                        {userData.email}
                    </div>

                </div>

                <div className={'pad-ii'}>
                    {(userData.status === 'NOT_ACTIVATED') ? 'Inactive' : 'Active since ' + moment(userData.creationTime).format('D-MMM-YYYY')}
                </div>
            </div>
            {isCompanyAdmin ? <Typography sx={{padding: '1rem'}}>This user can be edited only by a company
                    administrator</Typography> :
                <PermissionForm
                    role={userPermissions.role}
                    approvalRequired={userPermissions.approvalRequired}
                    approvalPriceLimit={userPermissions.approvalPriceLimit}
                    userId={userData.id} brandId={props.brandId} onUserUpdate={userUpdated} onClose={props.onClose}/>
            }


        </ShadowBox>


    </>
}

type PermissionFormType = {
    role: string
    approvalRequired: boolean
    approvalPriceLimit: number
    userId: number
    brandId: number
    onUserUpdate?: () => void;
    onClose?: () => void;
}

const PermissionForm = (props: PermissionFormType) => {
    const {role, approvalRequired, approvalPriceLimit, userId, brandId} = props
    const appToast = useAppToast();
    const windowDialog = useOpenDialogWindow();
    const {t} = useTranslation();
    const userQuery = useUserQuery({userId: props.userId});
    const apiCompanyUsers = new objectApi.companyUsers();
    const {
        handleSubmit,
        control,
        register,
        watch,
        reset,
        setValue,

        formState: {isValid, errors},
    } = useForm({
        mode: "all"
    });

    React.useEffect(() => {
        reset({role, approvalRequired, approvalPriceLimit})
    }, [userId]);


    async function onSubmit(dta: any) {

        const data = {
            userBrandPermission: {
                role: dta.role as string,
                approvalRequired: Boolean(dta.approvalRequired),
                approvalPriceLimit: Number(dta.approvalPriceLimit) ?? 0,
                accountId: brandId,
            }
        }
        apiCompanyUsers.changeUserBrandPermission(brandId, userId, data).then((res) => {
            appToast.success({
                message: "Permissions Saved",
            });
            if (props.onUserUpdate) props.onUserUpdate();
            if (props.onClose) props.onClose();
        }).catch((e) => {
            appToast.error({
                message: e?.message ?? "An error occurred",
            });
        })

    }


    const handleDeleteUser = () => {
        windowDialog.open({
            dialogId: 'nope',
            title: 'Confirm',
            description: t('message.user.deleteUserValidation'),
            buttonActions: {
                acceptButton: 'Remove',
                cancelButton: true
            },
            onAccept: (args: { companyUsersId: number }, oi: any) => {
                apiCompanyUsers
                    .changeUserBrandPermission(brandId, userId, {userBrandPermission: {role: null, accountId: brandId}})
                    .then((res) => {
                        userQuery.refetch()

                        if (props.onUserUpdate) props.onUserUpdate();
                        if (props.onClose) props.onClose();
                        appToast.success({
                            message: 'The user was successfully removed',
                        });
                    })
                    .catch((e: BackendError) =>
                        appToast.error({
                            message: e?.message ?? 'An error occurred',
                        }));
            }
        });
    };


    const currentRole = watch('role')
    const currentApprovalRequired = watch('approvalRequired')

    return <>
        <form onSubmit={handleSubmit(onSubmit)} style={{padding: '1rem'}}>

            <div className={"pad"}>
                <div className={"flex-h gap-small"}>

                    <div>
                        <InputFieldLabel style={{textTransform: "capitalize"}}>Brand Role</InputFieldLabel>
                        <TextFieldBlock>
                            <Controller
                                name="role"
                                rules={{required: "this is required"}}
                                control={control}
                                defaultValue={""}
                                render={({field}) => (
                                    <Select {...field} sx={{width: "150px"}} data-cy={"invitation-role"}>
                                        <MenuItem
                                            value={EUserGrade.ADMINISTRATOR}>{accountTypeName["ADMINISTRATOR"]}</MenuItem>
                                        <MenuItem value={EUserGrade.CREATOR}>
                                            {roleName["CREATOR"]}
                                        </MenuItem>
                                        <MenuItem
                                            value={EUserGrade.CONTRIBUTOR}>{roleName["CONTRIBUTOR"]}</MenuItem>


                                    </Select>
                                )}
                            />
                        </TextFieldBlock>
                    </div>
                </div>

                {currentRole === "CREATOR" && <>
                    <Box mb={3}>
                        <Typography mb={2} variant={"h6"}>Audience Approval</Typography>
                        <Box sx={{display: "flex", alignItem: 'center', gap: 4}}>

                            <Box>
                                <Typography mb={1}>Approval required</Typography>
                                <Controller
                                    name="approvalRequired"
                                    control={control}
                                    render={({field}) => (
                                        <Switch checked={field.value}     {...field}/>
                                    )}
                                />
                            </Box>

                            {currentApprovalRequired && <Box>
                                <Typography mb={1}>Max. Audience Budget without Approval</Typography>
                                <Controller
                                    name="approvalPriceLimit"
                                    control={control}
                                    render={({field}) => (
                                        <TextField {...field}
                                                   sx={{
                                                       width: "80px",
                                                       fontSize: "10x",
                                                       borderRadius: 0,
                                                       textAlign: "center"
                                                   }}
                                                   inputProps={{style: {textAlign: "left"}}}
                                                   error={Boolean(field.value < 0)}
                                                   type="number"

                                                   InputProps={{
                                                       sx: {textAlign: "center", minWidth: "120px"},
                                                       startAdornment: (
                                                           <InputAdornment position="start"
                                                                           sx={{pl: "10px", mr: "0"}}>
                                                               $
                                                           </InputAdornment>
                                                       ),
                                                   }}

                                                   data-cy={"max-budget"}
                                        />


                                    )}
                                />
                            </Box>}
                        </Box>
                    </Box>
                </>


                }

                <div className={"flex-h flex-align-middle"}>
                    <Button
                        data-cy="cancel-invite-btn"
                        onClick={() => {
                            handleDeleteUser();
                        }}
                        color={"error"}
                        variant={"outlined"}
                    >
                        Delete User
                    </Button>
                    <div className={"flex-main"}></div>
                    <Button data-cy="invite-btn" disabled={!isValid} type={"submit"} variant={"outlined"}>
                        Save
                    </Button>
                </div>
            </div>

        </form>
    </>
}