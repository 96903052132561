import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import * as React from "react";
type OptionSelectProps = {
  options: { value: string; label: string }[];
  value: string | undefined;
  onChange: (value: string) => void;
};

export const OptionSelect = (props: OptionSelectProps) => {
  const { options, value, onChange } = props;

  const [selectValue, setSelectValue] = React.useState<string>(value || options[0].value);

  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;

    setSelectValue(value);
    onChange(value);
  };

  return (
    <Select labelId="Select" id="Select" value={selectValue} variant="standard" onChange={handleChange} sx={{ width: "150px" }} displayEmpty={true}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
