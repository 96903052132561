import React, {FunctionComponent} from 'react';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {pageUtils} from '../../../application/pages/pages.utils';
import {PageId} from '../../../application/pages/pages.config';
import {Link} from 'react-router-dom';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {DemoModeWrapper} from 'components/controls/DemoModeWrapper';

interface IBrandNavigationProps {
    account: IAccounts;
};


export const CompanyNavigation: FunctionComponent<IBrandNavigationProps> = (props: IBrandNavigationProps) => {
    const {account} = props;

    const userLink = pageUtils.getPagePathById(PageId.companyUserList, {companyId: account.id});
    const editLink = pageUtils.getPagePathById(PageId.companyUpdate, {companyId: account.id});
    const viewLink = pageUtils.getPagePathById(PageId.companyView, {companyId: account.id});
    const createBrand = pageUtils.getPagePathById(PageId.brandCreate, {companyId: account.id});
    const dashboard = pageUtils.getPagePathById(PageId.companyDashboard, {companyId: account.id});

    return (Boolean(account.id) && AUTHUser.isEvorraAdmin()) ? <DemoModeWrapper>
        <div className={'pad-2 flex-h flex-align-middle gap-small'}>
            {/*<Link to={dashboard}>Dashboard</Link>
      <Link to={viewLink}>View</Link>*/}
            <Link to={editLink}>Company settings</Link>
            <Link to={userLink}>Manage users</Link>
            <Link to={createBrand}>New brand setup</Link>
        </div>
    </DemoModeWrapper> : null
};
