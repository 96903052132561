import { AppTag } from 'components/ui/AppTag';
import { useAudienceBuilderStore } from 'pages/AudienceBuilder/Store/AudienceBuilderStore';


export const DeleteFeature = (props: { featureCode:string, children?: any, doNotDeleteParent?: boolean }) => {

  const audienceActions = useAudienceBuilderStore.getState().actions;
  const filtersValues = useAudienceBuilderStore(st=> st.filtersValues)

  const hasSelectedValue = filtersValues.filter((filterValue: any)=> ((filterValue.code === props.featureCode) || filterValue.parentCode === props.featureCode) && filterValue.selected.length > 0).length > 0 ? true : false
  return   Boolean(hasSelectedValue) ? <span data-cy={'reset-'+ props.featureCode} style={{cursor:'pointer'}} onClick={() => {audienceActions.deleteFeature(props?.featureCode, props.doNotDeleteParent);}}>
    {props.children ?? <AppTag  dense={true}  name={'Reset'} />}
  </span> : null;
};


