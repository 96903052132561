import React from "react";
import {Box, Typography} from "@mui/material";

export const SearchItem = (props: { title: string, children: React.ReactNode }) => {
    return <>
        <Box>
            <Typography variant="body1" fontSize="13px" sx={{mb: '0.25rem', fontWeight: 'bold'}}>
                {props.title}
            </Typography>
            {props.children}
        </Box>
    </>
}