import {Box, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {getDspAdditionalInfo, getDspIcon} from "application/utils/dsp.utils";
import {moneyFormatter} from "application/utils/formatters.utils";
import {AppIcon} from "components/ui/AppIcon";
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import {useTranslation} from "react-i18next";
import {useAudienceEnablementStore} from "../../store/AudienceEnablement.store";
import {DspTable, DspTableRow} from "../DspSelectionStep";
import {DspCheckoutParametersBox} from "./DspCheckoutParametersBox";
import React from "react";
import moment from "moment";
import {shallow} from "zustand/shallow";
import {useFeatureFlag} from "../../../../../stores/featureFlags.store";
import {CampaignEndDate} from "./CampaignEndDate";

export const SummaryTable = () => {
    const {t} = useTranslation();

    const selectionStepForm = useAudienceEnablementStore((state) => state.selectionStepForm);
    const summary = selectionStepForm.filter((item) => item.profiles > 0);
    const campaignEndDates = useAudienceEnablementStore(
        (state) => state.selectionStepForm.reduce((acc, item) => ({...acc, [item.code]: item.campaignEndDate}), {}),
        shallow
    ); //used for rerender when campaignEndDates change
    const isImpressionBasedByActive: boolean = summary.map((item) => item?.isImpressionBasedBuy || false).includes(true);
    const currency = useAudienceEnablementStore((state) => state.audienceData.displayCurrency);
    const isCpmSetToZero = useAudienceEnablementStore((state) => state.isCpmSetToZero);
    const totalSpentPayOnce = useAudienceEnablementStore((st) => st.totalSpentPayOnce);
    const totalProfilesPayOnce = useAudienceEnablementStore((st) => st.totalProfilesPayOnce);
    const totalProfilesImpressionBasedBuy = useAudienceEnablementStore((st) => st.totalProfilesImpressionBasedBuy);
    const audienceEnablementActions = useAudienceEnablementStore((state) => state.actions);

    const currentStep = useAudienceEnablementStore((state) => state.currentStep);

    const featureFlag = useFeatureFlag()

    const isCampaignEndDateEnabled = featureFlag?.featureFlags?.campaign_end_date ?? false

    const tableColumns = [
        {
            id: "enablementChannel",
            title: t("dsp.enablementChannel"),
            appTip: null,
            width: "1fr",
            align: "left",
        },
        {
            id: "enablementOption",
            title: t("dsp.enablementOption"),
            appTip: t("dsp.dspTable.enablementOptionTip"),
            width: "1fr",
            align: "left",
        },
        {
            id: "buyerId",
            title: t("dsp.BuyerId"),
            appTip: t("dsp.dspTable.enablementChannelIdTip"),
            width: "110px",
            align: "center",
        },
        ...(!isCpmSetToZero
            ? [
                {
                    id: "cmp",
                    title: t("dsp.cmp"),
                    appTip: null,
                    width: "110px",
                    align: "center",
                },
            ]
            : []),
        {
            id: "desiredNbProfile",
            title: t("dsp.desiredNbProfile"),
            appTip: null,
            width: "110px",
            align: "center",
        },
        ...(!(isCpmSetToZero || totalProfilesPayOnce === 0)
            ? [
                {
                    id: "desiredSpend",
                    title: t("dsp.desiredSpend"),
                    appTip: null,
                    width: "110px",
                    align: "center",
                },
            ]
            : []),
        ...(isImpressionBasedByActive && isCampaignEndDateEnabled
            ? [
                {
                    id: "campaignEndDate",
                    title: t("dsp.campaignEndDate"),
                    appTip: null,
                    width: "130px",
                    align: "center",
                },
            ]
            : []),
    ];
    const gridTemplate = tableColumns.map((col) => col.width).join(" "); // To fill the grid-template-columns property


    return (
        <DspTable>
            <DspTableRow gridTemplate={gridTemplate}>
                {tableColumns.map((col) => {
                    return (
                        <Box key={col.id} width={col.width}
                             sx={{display: "flex", justifyContent: col.align, gap: "0.25rem"}}>
                            <Typography sx={{
                                textAlign: col.align,
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>{col.title}</Typography>
                            {col.appTip && <AppTip title={col.title} text={col.appTip}/>}
                        </Box>
                    );
                })}
            </DspTableRow>

            {summary.map((dspItem) => {
                const additionnalInfo = getDspAdditionalInfo(dspItem.code);

                const endDate = dspItem?.campaignEndDate ? new Date(dspItem?.campaignEndDate) : null

                return (
                    <DspTableRow key={dspItem.code} gridTemplate={gridTemplate}>
                        <Box>
                            <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.activationPlatformCode)}/>
                                <Typography sx={{fontWeight: "bold", fontSize: "14px"}} data-cy={"activationPlatform"}>
                                    {dspItem.activationPlatform}
                                </Typography>
                            </Box>
                            {dspItem.seatName !== "Untitled" && dspItem.seatName.length > 2 && (
                                <Typography className="color-tech-grey" ml={3} data-cy={"seatName"}>
                                    {dspItem.seatName}
                                </Typography>
                            )}
                        </Box>

                        <Box>
                            <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.code)}/>
                                <Typography sx={{fontWeight: "bold", fontSize: "12px"}} data-cy={"activationPlatform"}>
                                    {additionnalInfo?.name ?? dspItem.name}
                                </Typography>
                            </Box>
                            {additionnalInfo?.restriction && (
                                <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "5px"}}>
                                    <Typography className="color-tech-grey" style={{fontStyle: "italic"}}>
                                        {additionnalInfo?.restriction}
                                    </Typography>
                                    {additionnalInfo?.restrictionDetails && <AppTip title={additionnalInfo?.restriction}
                                                                                    text={additionnalInfo?.restrictionDetails}/>}
                                </Box>
                            )}
                            {dspItem.dspCheckoutParameterGroups.length > 0 &&
                                <DspCheckoutParametersBox paramGroups={dspItem.dspCheckoutParameterGroups}/>}
                        </Box>

                        <Box textAlign={"center"}>
                            <Typography data-cy={"advertiserId"} noWrap={true}>
                                {dspItem.advertizerId}
                            </Typography>
                        </Box>

                        {!isCpmSetToZero && (
                            <Box textAlign={"center"}>
                                <Typography
                                    data-cy={"cpm"}>{moneyFormatter(dspItem.cpmConverted, {currency: currency})}</Typography>
                            </Box>
                        )}

                        <Box style={{textAlign: "center"}}>
                            <Typography data-cy={"profiles"}>{profilesFormatter.format(dspItem.profiles)}</Typography>
                        </Box>
                        {!isCpmSetToZero && totalSpentPayOnce > 0 && (
                            <Box style={{textAlign: "center"}}>
                                <Typography data-cy={"profiles"}>
                                    {dspItem?.isImpressionBasedBuy
                                        ? "Impression based"
                                        : moneyFormatter((dspItem.profiles * (dspItem.cpmConverted ?? 1)) / 1000, {currency: currency})}
                                </Typography>
                            </Box>
                        )}

                        {currentStep === 'Final' && isImpressionBasedByActive && isCampaignEndDateEnabled ? <>
                                <Typography
                                    sx={{fontSize: "0.75rem", textAlign: "center"}}>
                                    {endDate ? moment(endDate).format('DD/MM/YYYY') : 'Not set'}
                                </Typography>
                            </> :
                            <>
                                {isImpressionBasedByActive && isCampaignEndDateEnabled && dspItem?.isImpressionBasedBuy && <>
                                    <CampaignEndDate endDate={endDate} dspItemCode={dspItem.code}/>
                                </>}
                            </>
                        }
                    </DspTableRow>
                );
            })}

            {isImpressionBasedByActive && (
                <DspTableRow mt={2} gridTemplate={gridTemplate} total={true}>
                    <Box textAlign={"right"} fontWeight={"bold"}
                         sx={{gridColumnStart: "1", gridColumnEnd: isCpmSetToZero ? "4" : "5"}}>
                        Total impression based buy
                    </Box>

                    <Box textAlign={"center"}>
                        {!Boolean(totalProfilesImpressionBasedBuy) ? "..." : profilesFormatter.format(totalProfilesImpressionBasedBuy ?? 0)}
                    </Box>
                    {!isCpmSetToZero && totalProfilesPayOnce > 0 && <Box textAlign={"center"}>...</Box>}
                </DspTableRow>
            )}
            {totalProfilesPayOnce > 0 && (
                <DspTableRow mt={isImpressionBasedByActive ? 0 : 2} gridTemplate={gridTemplate} total={true}>
                    <Box textAlign={"right"} fontWeight={"bold"}
                         sx={{gridColumnStart: "1", gridColumnEnd: isCpmSetToZero ? "4" : "5"}}>
                        Total {isImpressionBasedByActive && "pay once"}
                    </Box>
                    <Box
                        textAlign={"center"}>{!Boolean(totalProfilesPayOnce) ? "..." : profilesFormatter.format(totalProfilesPayOnce ?? 0)}</Box>
                    {!isCpmSetToZero && (
                        <Box
                            textAlign={"center"}>{!Boolean(totalSpentPayOnce) ? "..." : moneyFormatter(totalSpentPayOnce ?? 0, {currency: currency})}</Box>
                    )}
                </DspTableRow>
            )}
        </DspTable>
    );
};
