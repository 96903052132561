import {CompanyUsers} from './CompanyUsers';
import {Rules} from './Rules';
import {Audiences} from './Audiences';
import {Tags} from './Tags';
import {Accounts} from './Accounts';
import {Countries} from './Countries';
import {Subscriptions} from './Subscriptions';
import {SubscriptionPlans} from './SubscriptionPlan';
import {Industries} from './Industries';
import {Features} from './Features';
import {FeatureTypes} from './FeatureTypes';
import {AudienceFeatures} from './AudienceFeatures';
import {Affinities} from './Affinities';
import {Notes} from './Notes';
import {FeatureFlags} from './FeatureFlags';
import {AudienceTypes} from './AudienceTypes';
import {DspChannels} from './DspChannels';
import {DspChannelsSelections} from './DspChannelsSelections';
import {JiraIssue} from './JiraIssue';
import {AudienceSegment} from "./AudienceSegment";


// objectApi holds all the declared api classes
// all are derived from src/application/entities/dataApi/AbstractBaseApi.ts
export const objectApi = {
    accounts: Accounts,
    audiences: Audiences,
    audienceTypes: AudienceTypes,
    companyUsers: CompanyUsers,
    countries: Countries,
    features: Features,
    featureTypes: FeatureTypes,
    industries: Industries,
    rules: Rules,
    notes: Notes,
    subscriptionPlans: SubscriptionPlans,
    subscriptions: Subscriptions,
    tags: Tags,
    audienceFeatures: AudienceFeatures,
    affinities: Affinities,
    dspChannels: DspChannels,
    dspChannelsSelections: DspChannelsSelections,
    featureFlags: FeatureFlags,
    jiraIssue: JiraIssue,
    externalSegments: AudienceSegment
};

// global factory to serve classes
// ts loose ref
export function apiDataFactory(type: keyof typeof objectApi) {
    const objectClass = objectApi[type];
    return new objectClass();
}