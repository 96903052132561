import { create } from "zustand";
import { dataUtils } from "../../../application/utils/dataState.utils";
import produce from "immer";

export interface DemoStep {
  id: number | string;
  order?: number;
  active?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
}

interface DemoStoreType {
  active: boolean;
  stepList: DemoStep[];
  activeStepKey: number | string;
  actions: {
    registerStep: (args: DemoStep) => void;
    gotIt: (featureCode: any, dir?: "next" | "back") => void;
    activate: () => void;
    quit: () => void;
  };
}

export const useDemoStore = create<DemoStoreType>((set, get) => ({
  active: false,
  stepList: [],
  activeStepKey: "DATASOURCE",
  actions: {
    registerStep: (step: DemoStep) => {
      let test = dataUtils.getObjectItemById(get().stepList, step.id)[0];
      if (!test) {
        set(
          produce((st) => {
            st.stepList.push(step);
          })
        );
      }
    },
    gotIt: (featureCode, dir: "next" | "back" = "next") => {
      const featureList = get().stepList.map((er) => er.id);
      const idx = featureList.indexOf(featureCode);

      if (idx > -1) {
        switch (dir) {
          case "next":
            // play onNext
            if (get().stepList[idx].onNext) {
              // @ts-ignore
              get()?.stepList?.[idx]?.onNext();
            }
            if (featureList[idx + 1]) {
              set({ activeStepKey: featureList[idx + 1] });
            } else {
              set({ activeStepKey: featureList[0] });
            }

            break;
          case "back":
            if (featureList[idx - 1]) {
              set({ activeStepKey: featureList[idx - 1] });
            } else {
              set({ activeStepKey: featureList[0] });
            }
            break;
        }
      }
    },
    activate: () => {
      set({ active: true });
    },
    quit: () => {
      set({ active: false });
    },
  },
}));
