import React from 'react';
import Switch, {SwitchProps} from '@mui/material/Switch';
import FormControlLabel, {FormControlLabelProps,} from '@mui/material/FormControlLabel';


export const AppSwitch = (
  props: Omit<FormControlLabelProps, 'control'> & SwitchProps
) => {
  
  const [state, setState] = React.useState({
    checked: props.checked,
  });
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    setState({...state, checked: Boolean(event.target.checked)});
    
    props.onChange && props.onChange(event, event.target.checked);
  };
  
  React.useEffect(() => {
    setState((currentState) => {
      return {
        ...currentState,
        checked: Boolean(props?.checked)
      };
    });
  }, [props.checked]);
  
  return (
    <><FormControlLabel
      control={<Switch checked={Boolean(props.checked)} value={state.checked} onChange={handleChange} {...props}/>}
      label={props.label}
      labelPlacement={props.labelPlacement}
      value={state.checked}
      name={props.name}
      checked={Boolean(props.checked)}
    /></>
  );
};
