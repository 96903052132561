import * as yup from 'yup';
import {validURL} from './userValidationSchema';

// those 2 forms should be one

export const createAccountValidationSchema = yup.object({
  companyName   : yup.string()
                     .trim('The Company name cannot include leading and trailing spaces')
                     .min(2, 'Minimum 2 characters length')
                     .max(255, 'Max 255 characters length')
                     .required('Company Name is required'),
  streetAddress : yup.string()
                     .trim('Street address cannot include leading and trailing spaces')
                     .min(3, 'Minimum 3 characters length')
                     .max(255, 'Max 255 characters length'),
  country       : yup.string()
                     .trim()
                     .required('Country is required'),
  companyUrl    : yup.string()
                     .matches(validURL, 'Enter a valid URL')
                     .nullable(),
  companyUrlList: yup
  .array().of(yup.string().matches(validURL, 'Enter some valid URLs'))
  .nullable(),
  email         : yup
  .string()
  .trim()
  .email('Enter a valid email')
  .required('Email is required'),
  firstName     : yup
  .string()
  .trim('The contact name cannot include leading and trailing spaces')
  .matches(/^[^0-9]+$/, 'Must be only letters')
  .min(2, 'Minimum 2 characters length')
  .max(255, 'Max 255 characters length')
  .required('First Name is required'),
  lastName      : yup
  .string()
  .trim()
  .matches(/^[^0-9]+$/, 'Must be only letters')
  .min(2, 'Minimum 2 characters length')
  .max(255, 'Max 255 characters length')
  .required('Last Name is required'),
  dataProvider  : yup.boolean(),
  dataClient    : yup.boolean(),
}).test('dataClientProvider', 'You should choose minimum one', (value) => {
  const test = (value.dataProvider === true || value.dataClient === true);
  
  if (test) return true;
  return new yup.ValidationError(
    'You should choose minimum one',
    null,
    'dataClientProvider'
  );
});


export const editAccountValidationSchema = yup.object({
  companyName   : yup.string()
                     .trim('The Company name cannot include leading and trailing spaces')
                     .min(2, 'Minimum 2 characters length')
                     .max(255, 'Max 255 characters length')
                     .required('Company Name is required'),
  streetAddress : yup.string()
                     .trim('Street address cannot include leading and trailing spaces')
                     .min(3, 'Minimum 3 characters length')
                     .max(255, 'Max 255 characters length').nullable(),
  country       : yup.string()
                     .trim()
                     .required('Country is required'),
  companyUrl    : yup.string()
                     .matches(validURL, 'Enter a valid URL')
                     .nullable(),
  dataProvider  : yup.boolean(),
  dataClient    : yup.boolean(),
  allowPayLaterInvoice:yup.boolean().nullable(),
  //companyUrlList: yup.string(),
  //.array().of(yup.string().matches(validURL, 'Enter some valid URLs'))
  //.transform((value) => {console.log({value}); return value;})
  //.transform((value) => Array.from(new Set(value.split(';'))).join(';'))
                     
}).test('dataClientProvider', 'You should choose minimum one', (value) => {
  const test = (value.dataProvider === true || value.dataClient === true);
  
  if (test) return true;
  return new yup.ValidationError(
    'You should choose minimum one',
    null,
    'dataClientProvider'
  );
});