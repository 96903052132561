import {IAccounts, ICompanyUsers} from "../../types";
import {EPermission, EUserAccountType, EUserGrade, EUserRole, GradeAndGod, TPermission} from "./granter.utils";

export type IGranterUser = {
    accountId?: number;
    userId?: number;
    grade: GradeAndGod[];
    role: EUserRole[];
    userAccountType?: EUserAccountType.COMPANY | EUserAccountType.BRAND | undefined; // undefined for legacy
};

export interface IBrandGrade {
    brandId: number;
    brandPermission: TPermission;
}

export interface IUserPermissions {
    userId: number;
    accountId: number;
    accountGrade: EPermission | EPermission[];
    accountRole: EUserRole | EUserRole[];
    brandGrades: IBrandGrade[] | [];
    userAccountType?: EUserAccountType.COMPANY | EUserAccountType.BRAND | "*" | undefined;
}

// cannot trash, for non reactive places
// grade can be [GOD,SUPERADMIN] && EUserGrade[]
class UserSingleton {
    private _company_brands: Array<{ id: number; name: string; permission: TPermission | undefined }> | [] = [];
    //private _userGrades!: EUserGrade[];
    private _userRoles!: EUserRole[];

    /** one user can have several role and grades */
    private _granterUser!: IUserPermissions;

    get granterUser(): any {
        return this._granterUser;
    }

    private _user!: ICompanyUsers;

    /** @deprecated : user should be retrieved from elsewhere */
    get user(): ICompanyUsers {
        return this._user;
    }

    private _accountId!: number;

    get accountId(): number {
        return this._accountId;
    }

    private _userId!: number;

    get userId(): number {
        return this._userId;
    }

    private _currentBrandId?: number;

    get currentBrandId(): number | undefined {
        return this._currentBrandId;
    }

    private _accountType!: IAccounts["accountType"];

    get accountType(): IAccounts["accountType"] {
        return this._accountType;
    }

    get permissions() {
        return this.buildPermissions();
    }

    get companyBrand() {
        return this._company_brands;
    }

    setCompanyBrands(company: IAccounts) {
        if (company.brandAccountAssociations) {
            const brandList = company.brandAccountAssociations.map((brand) => {
                const permission = this.permissions.brandGrades?.find((brandGrade) => brandGrade.brandId === brand.subAccount.id);
                return {
                    id: brand.subAccount.id,
                    name: brand.subAccount.companyName,
                    permission: this.permissions.accountGrade?.includes(EPermission.COMPANYADMIN) ? ("BRANDADMIN" as TPermission) : permission?.brandPermission,
                };
            });
            this._company_brands = brandList;
        } else {
            this._company_brands = [];
        }
    }

    setUser(userData: ICompanyUsers) {
        this._user = userData;
        this._accountId = userData.account.id;
        this._userId = userData.id;
        this._accountType = userData.account.accountType;
        this._userRoles = this.buildUserRoles();
        this._granterUser = this.buildPermissions();
    }

    setCurrentBrandId(brandId: number) {
        this._currentBrandId = brandId;
    }

    /** @deprecated user should not be read  from here */
    getUser() {
        return this._user;
    }

    /** @deprecated account should not be read  from here */
    getUserAccount() {
        return this._user?.account;
    }

    /** @deprecated stripe condition should not be read from here */
    getUserStripe() {
        return this._user.account.stripe;
    }

    /** grade ==> role  */
    hasRole(role: EPermission) {
        return this._granterUser.accountGrade.includes(role);
    }

    /** role ==> grade  */
    hasDataType(grade: EUserRole) {
        return this._userRoles.includes(grade);
    }

    isEvorraAdmin() {
        return [EUserGrade.ADMINISTRATOR, EUserGrade.COMPANYSUPERADMIN].includes(this._user.role) && this._accountId === 1;
    }

    isAccountSuperAdmin() {
        if ([EUserGrade.ADMINISTRATOR, EUserGrade.COMPANYSUPERADMIN].includes(this._user.role) && this._accountId === 1) {
            return true
        }
        return [EUserGrade.COMPANYSUPERADMIN].includes(this._user.role);
    }

    isBrandAdmin(brandId: number) {
        const brand = this._granterUser.brandGrades.find((brand) => brand.brandId === brandId);
        return brand?.brandPermission === EPermission.BRANDADMIN;
    }

    getPermissionForBrandId(brandId: number): TPermission | undefined {
        const brand = this._granterUser.brandGrades.find((brand) => brand.brandId === brandId);
        return brand?.brandPermission;
    }


    private buildUserRoles() {
        const roles = [];
        if (this._user?.account?.dataClient) roles.push(EUserRole.DATA_CLIENT);
        if (this._user?.account?.dataProvider) roles.push(EUserRole.DATA_PROVIDER);

        return roles;
    }

    private getCompanyPermission(grade: EUserGrade): TPermission {
        if (grade === EUserGrade.ADMINISTRATOR) return EPermission.COMPANYADMIN;
        if (grade === EUserGrade.COMPANYSUPERADMIN) return EPermission.COMPANYADMIN;
        return EPermission.COMPANYUSER;
    }

    private getBrandPermission(grade: string): TPermission {
        if (grade === EUserGrade.ADMINISTRATOR) return EPermission.BRANDADMIN;
        if (grade === EUserGrade.COMPANYSUPERADMIN) return EPermission.BRANDADMIN;
        if (grade === EUserGrade.CREATOR) return EPermission.BRANDCREATOR;
        return EPermission.BRANDVIEWER;
    }

    private buildPermissions(): IUserPermissions {
        let permissionsList: IBrandGrade[] = [];
        if (this._user.agentBrandAccountAssociations?.length > 0) {
            this._user.agentBrandAccountAssociations.forEach((brand) => {
                permissionsList.push({
                    brandId: brand.account.id,
                    brandPermission: this.getBrandPermission(brand.role),
                });
            });
        }

        return {
            userId: this._user.id,
            accountId: this._user.account.id,
            accountRole: this._user.account.dataProvider ? EUserRole.DATA_PROVIDER : EUserRole.DATA_CLIENT,
            accountGrade: this.isEvorraAdmin()
                ? [EPermission.EVADMIN, this.getCompanyPermission(this._user.role)]
                : this.getCompanyPermission(this._user.role),
            brandGrades: permissionsList,
            userAccountType: this._user.account?.accountType,
        };
    }
}

export const AUTHUser = new UserSingleton();
