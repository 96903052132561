import {Button, CircularProgress, Typography} from '@mui/material';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {AbstractBaseApi} from '../../../application/entities/dataApi/AbstractBaseApi';
import {pageUtils} from '../../../application/pages/pages.utils';
import {useTranslation} from 'react-i18next';
import {dataObject} from '../../../application/entities/dataObjects/DataObjectClass';
import {AUTHUser} from '../../../application/utils/AuthUser';

interface IButtonCreateProps {
  entityName: string;
  data: Record<string, any>;
  apiBase: AbstractBaseApi<any>;
  openFrameId?: string;
  onCreate?: (id: number) => void;
  text?: string;
  buttonProps?: any;
}

export const ButtonCreate = (props: IButtonCreateProps) => {
  
  const {entityName, data, apiBase, openFrameId, onCreate, text, buttonProps} = props;
  const history                                                               = useNavigate();
  const {t}                                                                   = useTranslation();
  
  const [loadState, setloadState] = React.useState<boolean>(false);
  const title                     = !loadState ? text ?? `${t('labels.createNew')} ${entityName.charAt(0).toUpperCase() + entityName.slice(1)}` : `... ${t('labels.creating')}`;
  
  let createData = data;
  
  
  switch (entityName) {
    case 'audience':
      createData = dataObject.getDataObject('audiences', {
        name   : t('audience.untitledAudience'),
        account: `account/${AUTHUser.accountId}`,
        ...data
      });
      break;
  }
  
  function handleCreate() {
    try {
      setloadState(true);
      apiBase.create(createData).then((res: any) => {
        setloadState(false);
        if (res.id) {
          if (onCreate) onCreate(res.id);
          if (openFrameId) history(pageUtils.getPagePathById(openFrameId, {id: res.id}));
        }
      });
    } catch (e: any) {
      setloadState(false);
    }
  }
  
  return <Button data-cy={'CreateButton'}
                 startIcon={loadState && <CircularProgress color="inherit" size={14}/>}
                 onClick={handleCreate} variant={'contained'}
                 {...buttonProps}  >
    <Typography noWrap={true}>{title}</Typography>
  </Button>;
};