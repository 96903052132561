import {ThumbGrid} from "./ViewAudienceFeatureInterest";
import {Typography} from "@mui/material";
import moment from "moment";
import {FeatureLabel} from "../audienceFeature";

interface ViewAudienceSubFeatureDateProps {
    values: any[];
    displayDateTitle?: boolean;
}

export const ViewAudienceSubFeatureDate = (props: ViewAudienceSubFeatureDateProps) => {
    const {values, displayDateTitle} = props;

    console.log("values", values)
    return values?.[0] ? (
        <ThumbGrid grid={true}>
            {values.map((featValue) => {
                const date = new Date(featValue.value);
                const newDate = moment(date).format("DD/MM/YYYY");

                return (
                    <div style={{order: featValue.order, minWidth: "150px"}}>
                        {displayDateTitle === true && <Typography sx={{fontSize: "8px"}}>{featValue.text}</Typography>}
                        <ThumbGrid mode={"grouped"} grid={true} style={{marginBottom: "4px", marginLeft: "0px"}}>
                            <FeatureLabel>{newDate}</FeatureLabel>
                        </ThumbGrid>
                    </div>
                );
            })}
        </ThumbGrid>
    ) : null;
};
