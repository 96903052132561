import styled from 'styled-components';
import {ColumnsMiddle, Elem, GridItemFull, GridMiddle} from '../AppElements';
import * as React from 'react';
import {TIcon} from '../../../assets/icons';

export type TAppTabArgs = { id: number|string, text: string, icon?: TIcon, value?: any, disabled?: boolean, dataLabel?:string, dataValue?:string | number, order?: number }

interface IAppTabs {
  tabList: TAppTabArgs[]
  activeTab?: number
  onClick: (props: TAppTabArgs) => void
  children?: any
  style?: any
  density?: 'dense' | 'normal'
  
}

export const AppTabs    = (props: IAppTabs) => {
  
  const {tabList, onClick, children,activeTab,style,density='normal'} = props;

  const [clickedTab, setClickedTab]    = React.useState<number>(activeTab ?? 0);
  
  React.useEffect(() => {
    setClickedTab(activeTab ?? 0)
  }, [activeTab]);

  const handleTabClick = (index: number)=>{
    setClickedTab(index)
    onClick(tabList[index])
  }

  return <AppTabsCss style={style} data-cy={'AppTabs'} >
    {tabList.map((tab, index) => {
      return <AppTabsTabCss onClick={() => {!tab.disabled && handleTabClick(index)}}
                            isActive={clickedTab === index}
                            disabled={Boolean(tab.disabled)}
                            padding={[density==='dense'?0.25:0.5,0]}
                            key={`apptab_${index}`}
                            data-cy={'AppTabs'}
                            data-cy-test-id={tab.text}
                            data-label={tab?.dataLabel}
                            data-value={tab?.dataValue}
                            pointer={true}>{tab.text}</AppTabsTabCss>
    })}
    <GridItemFull></GridItemFull>
    {Boolean(children) && children}
  </AppTabsCss>
}
export const AppTabsCss = styled(GridMiddle)({
                                                  position    : 'relative',
                                                  borderBottom: '1px solid #f0f2f6',
  gap:'1.5rem'
                                                })

export interface IAppTabsTabCss {
  isActive: boolean
  disabled: boolean
}

export const AppTabsTabCss = styled(Elem)((props: IAppTabsTabCss) => ({
  marginBottom: '-1px',
  borderBottom: props.isActive ? '5px solid #00B5E2' : '5px solid transparent',
  color       : props.isActive ? '#00B5E2' : 'inherit',
  transition: "all 100ms ease-in-out",
  opacity: props.disabled ? 0.2 : 1,
  cursor: props.disabled ? 'default' : 'pointer',
}))