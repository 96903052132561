import React from "react";
import styled from "styled-components/macro";
import {useFormik} from "formik";
import {Box, Button, Input, TextField, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {uploadFileValidationSchema} from "application/validation";
import {buttonLabels, ColorButton} from "components/form/Buttons";
import {useAppToast} from "components/live/AppToast";
import {InputFieldLabel} from "../../components/templates/InfoBlock";
import {useOpenDialogWindow} from "../../components/live/DialogWindow";
import {StyledCheckbox} from "../../components/form/CheckBox";
import {Trans, useTranslation} from "react-i18next";
import {LinkTo} from "../../components/controls/LinkTo";
import {pageUtils} from "../../application/pages/pages.utils";
import {PageId} from "../../application/pages/pages.config";

interface IDataVaultChangePasswordProps {
    accountId: number;
    fetchUploadedLists: (accountId: number) => void;
}

export const UploadList: React.FC<IDataVaultChangePasswordProps> = ({accountId, fetchUploadedLists}) => {
    const dialogWindow = useOpenDialogWindow();
    const {t} = useTranslation();
    const appToast = useAppToast();
    const accountsApi = new objectApi.accounts();
    const [selectedFile, setSelectedFile] = React.useState();
    const [fileName, setFileName] = React.useState("");
    const [accepted, setAccepted] = React.useState<boolean>(false);
    const [hasChanged, setHasChanged] = React.useState<boolean>(false);


    const uploadFileFormik = useFormik({
        initialValues: {
            fileName: "",
            description: "",
            file: "",
        },
        validationSchema: uploadFileValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            const castValues = uploadFileValidationSchema.cast(values);
            let formData = new FormData();
            selectedFile && formData.append("file", selectedFile);

            const realFileName = castValues?.fileName?.length ? castValues?.fileName : fileName;

            accountsApi
                .postUploadExclusionFile(accountId, formData, realFileName, castValues.description)
                .then(() => {
                    dialogWindow.close("UploadListModal").then();
                    setFileName("");
                    appToast.open({
                        toastId: "globalToast",
                        severity: "success",
                        message: t("list.uploadSuccess"),
                    });
                    fetchUploadedLists(+accountId);
                })
                .catch((err) => {
                    let message = err?.response?.data?.error?.message;
                    if (message.includes("exist")) {
                        message = t("list.nameAlreadyExist");
                        uploadFileFormik.setFieldError("fileName", message);
                    }
                    appToast.open({
                        toastId: "globalToast",
                        severity: "error",
                        message: message ?? t("labels.error"),
                    });
                });
        },
        onReset: (values) => {
            dialogWindow.close("UploadListModal").then(() => setFileName(""));
        },
    });

    const styles = {
        hidden: {
            display: "none",
        },
        importLabel: {
            color: "black",
        },
    };

    const onInputChange = (e: any) => {
        setSelectedFile(e.currentTarget.files[0]);
        setFileName(e.currentTarget.files[0].name.replace(".csv", ""));
        // uploadFileFormik.values.fileName = (e.currentTarget.files[0].name as string).replace('.csv', '');
    };

    return (
        <FormContainer
            onChange={() => {
                setHasChanged(true);
            }}
            onSubmit={uploadFileFormik.handleSubmit}
        >
            <InnerContainer>
                <br/>
                <Box style={{
                    fontSize: "10px",
                    color: "#9DAABF",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {t("uploadLists.uploadExclusionListDescription2")}
                </Box>
                <br/>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "100%"}}>
                    <InputFieldLabel style={styles.importLabel}>
                        <Input
                            data-cy={"upload-list"}
                            id="import-button"
                            inputProps={{
                                accept: ".csv",
                            }}
                            onChange={(e) => onInputChange(e)}
                            style={styles.hidden}
                            type="file"
                        />
                        <div style={{display: "flex", minWidth: "auto", alignItems: "center", maxWidth: "100%"}}>
                            <Browse>{t("list.selectFile")}</Browse>
                        </div>
                    </InputFieldLabel>
                </Box>
                <div
                    style={{
                        minWidth: "auto",
                        paddingLeft: "7px",
                        overflow: "hidden",
                    }}
                >
                    <Typography title={fileName} noWrap={true}>
                        {fileName}
                    </Typography>
                </div>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "0.5rem"}}>
                    <TextField
                        fullWidth
                        data-cy={"file-name"}
                        id="fileName"
                        name="fileName"
                        value={uploadFileFormik.values.fileName}
                        onBlur={uploadFileFormik.handleBlur}
                        onChange={uploadFileFormik.handleChange}
                        error={uploadFileFormik.touched.fileName && Boolean(uploadFileFormik.errors.fileName)}
                        helperText={uploadFileFormik.touched.fileName && uploadFileFormik.errors.fileName}
                        InputProps={{autoComplete: "new-password"}}
                        style={{width: "223px"}}
                        placeholder={t("list.enterOwnFileName")}
                    />
                </Box>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1rem"}}>
                    <TextField
                        data-cy={"file-description"}
                        id="description"
                        name="description"
                        value={uploadFileFormik.values.description}
                        onBlur={uploadFileFormik.handleBlur}
                        onChange={uploadFileFormik.handleChange}
                        error={uploadFileFormik.touched.description && Boolean(uploadFileFormik.errors.description)}
                        helperText={uploadFileFormik.touched.description && uploadFileFormik.errors.description}
                        InputProps={{autoComplete: "new-password"}}
                        style={{width: "223px"}}
                        placeholder={t("list.enterAdditionalDescription")}
                    />
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                    <Box style={{maxWidth: "260px", display: "flex", alignItems: "start", gap: "0.5rem"}}
                         data-cy={"file-acceptance"}>
                        <StyledCheckbox
                            sx={{m: 0}}
                            id={"acceptance"}
                            size={"small"}
                            checked={accepted}
                            onChange={() => {
                                setAccepted(!accepted);
                            }}
                        />
                        <Box sx={{whiteSpace: "normal", display: "block", fontSize: "10px"}}>
                            <Trans
                                i18nKey="uploadLists.acceptTerms"
                                components={[<LinkTo to={"https://evorra.com/evorra-platform-terms-and-conditions/"}
                                                     title="evorra-platform-terms-and-conditions"/>]}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    gap: "1rem"
                }}>
                    <Button
                        data-cy="cancel-btn"
                        variant="outlined"
                        onClick={() => {
                            dialogWindow.close("UploadListModal").then();
                        }}
                        style={{width: "110px"}}
                    >
                        {buttonLabels.cancel}
                    </Button>
                    <ColorButton variant="contained" color="primary" type="submit" style={{width: "110px"}}
                                 data-cy={"upload-btn"} disabled={!accepted}>
                        {buttonLabels.upload}
                    </ColorButton>
                </Box>
                <Typography style={{width: "100%", fontSize: "8px", textAlign: "center", color: "#7587A3"}}>
                    <Trans
                        i18nKey="uploadLists.acceptTermsNotice"
                        components={[<LinkTo target={"_self"}
                                             to={pageUtils.getPagePathById(PageId.accountEdit, {accountId})}/>]}
                    />
                </Typography>
            </InnerContainer>
        </FormContainer>
    );
};

const FormContainer = styled.form`
    overflow: hidden;
    width: 405px;
    padding: 0 41px;
    padding-bottom: 2rem;
`;
const InnerContainer = styled.div`
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
`;

const Browse = styled.div`
    height: 30px;
    width: fit-content;
    background: linear-gradient(94.21deg, #00b5e2 0%, #10caf9 100%);
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0px 4px 9px rgba(14, 199, 246, 0.3);
    border-radius: 20px;
    color: white;
    font-size: 12px;
    font-weight: normal;

    :hover {
        cursor: pointer;
        box-shadow: 0 4px 9px rgba(14, 199, 246, 0.3);
    }
`;
