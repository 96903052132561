import {TDataColumnModel} from '../../utils/dataColumnModel.utils';


export const rulesColumns: TDataColumnModel = {
  name          : {
    field     : 'name',
    headerName: 'Rule name',
    width     : 250,
    type      : 'select',
    input     : {
      value : 'ALLOW',
      values: [{index: 'ALLOW', text: 'Allow'}]
    }
  },
  isActivated   : {
    field     : 'isActivated',
    headerName: 'active',
    flex      : 4,
    type      : 'boolean',
  },
  permissionType: {
    field     : 'permissionType',
    headerName: 'permission',
    flex      : 4,
    type      : 'string',
  },
  domain        : {
    field     : 'domain',
    headerName: 'domain',
    flex      : 4,
    type      : 'string',
  },
  industries    : {
    field     : 'industries',
    headerName: 'industries',
    flex      : 4,
    type      : 'treeValues',
  },
  countries     : {
    field     : 'countries',
    headerName: 'countries',
    flex      : 4,
    type      : 'treeValues',
  },
  createdDate   : {
    field     : 'createdDate',
    headerName: 'Created date',
    width     : 150,
    type      : 'date',
  },
}

export const rulesInputModel: TDataColumnModel = {
  permissionType  : {
    field     : 'permissionType',
    headerName: 'Permission',
    width     : 150,
    type      : 'select',
    input     : {
      type  : 'select',
      value : 'DENY',
      values: [{index: 'DENY', text: 'Don\'t Allow'}]
    }
  }, hasIndustries: {
    field     : 'hasIndustries',
    headerName: 'Industry',
    width     : 150,
    type      : 'boolean',
    input     : {
      type: 'boolean', value: 'hasIndustries', onChange: (th: any) => {
      }
    }
  }, hasDomain    : {
    field     : 'hasDomain',
    headerName: 'Domain',
    width     : 150,
    type      : 'boolean',
    input     : {
      type: 'boolean', value: 'hasDomain', onChange: (th: any) => {
      }
    }
  }, who          : {
    field     : 'who',
    headerName: 'Who',
    flex      : 2,
    type      : 'freeTextValidate',
    input     : {type: 'freeTextValidate', value: null, entityName: 'industries', defaultField: 'formattedName',},
  }, locations    : {
    field     : 'locations',
    headerName: 'Locations',
    flex      : 2,
    type      : 'treeValues',
    input     : {type: 'treeValues', entityName: 'countries', hierarchyField: 'path', defaultField: 'name'},
  }, doum         : {
    field: 'to',
    headerName: '  ',
    flex: 2,
    type: 'label',
    input: { type: 'label', value: 'to access to my data' },
  }
}