import {Box, FormGroup, InputLabel, Theme, Typography,} from '@mui/material';
import styledOld from 'styled-components/macro';
import {styled} from '@mui/styles';
import React from 'react';
import {TIcon} from '../../assets/icons';
import {AppIcon} from '../ui/AppIcon';
import {TGridDefinedSizes} from '../ui/AppElements';


export const FrameContent   = styledOld.div`
  margin: auto;
  padding: 0 20px 0px 20px;
  height: 100%;
`;
export const FrameContainer = styledOld.div`
  overflow: auto;
  height: 100%;
`;

export const FormBlock = (props: { title?: any,iconFontSize?: TGridDefinedSizes, icon?:TIcon | any, noTitlePadding?: boolean, noBorder?: boolean, description?: string,buttons?: any, children?: any, style?: Record<string, string>, className?: string,fullHeight?: boolean }) => {
  
  return <div className={props.className} style={{display:'flex',flexDirection:'column',height: props.fullHeight? '100%' : 'auto', ...props.style}}>
    <div className={'flex-h gap-small flex-align-middle ' + (!props.noBorder ? 'border-b' : '')}>
      {props.icon && <AppIcon icon={props.icon} fontSize={props?.iconFontSize} />}
      {Boolean(props.title) && <Typography className={'flex-main ' + (!props.noTitlePadding ? 'pad pad-tb-2':'pad-b-2')} style={{color: '#7587A3'}} variant="h5">{props.title}</Typography>}
      {Boolean(props.buttons) && <div>{props.buttons}</div>}
    </div>
    {props.description && <div className={'pad-ii pad-b '}>{props.description}</div>}
    <div   style={{flex:1,position:'relative',overflow:'hidden'}}>
      {props?.children && props.children}
    </div>
  </div>;
};


export const InfoContainer = styled('div')((props: { theme: Theme }) => ({
  maxWidth: '566px',
  minWidth: '566px',
  width   : 'fit-content',
  margin  : '0 8px'
}));

export const InfoBlockTitle = styled('div')((props: { theme: Theme }) => ({
  fontWeight   : 'bold',
  fontSize     : 18,
  lineHeight   : '25px',
  letterSpacing: '-0.2px',
  margin       : '30px 0 22px 10px',
  color        : props.theme?.palette?.mode === 'light' ? '#3B4559' : 'rgba(255,255,255,0.7)',
  
}));


export const DetailBlock = styled(Box)((props: { theme: Theme }) => ({
  borderRadius   : '20px',
  padding        : '2rem',
  backgroundColor: props.theme.palette.mode === 'light' ? '#F1F3F8' : props.theme.palette.background.paper,
  marginBottom   : '1rem'
}));

export const DetailsBlockGrid = styled(DetailBlock)((props: { theme: Theme }) => ({
  display            : 'grid',
  marginBottom       : '1rem',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumnGap      : '56px',
  gridRowGap         : '5px',
  maxWidth           : '566px'
}));

export const InputFieldLabel = styled(InputLabel)((props: { theme: Theme }) => ({
  fontFamily  : props.theme.typography.fontFamily,
  fontWeight  : 'bold',
  color       : '#9DAABF',
  padding     : '0.25rem 0',
  marginBottom: '0.25rem',
  fontSize    : 14,
}));

export const FieldLabel = styled(InputLabel)((props: { theme: Theme }) => ({
  fontFamily  : props.theme.typography.fontFamily,
  fontWeight  : 'bold',
  color       : '#9DAABF',
  padding     : '0.25rem 0',
  marginBottom: '0.15rem',
  fontSize    : 14,
  minHeight:'30px'
}));

export const TextFieldBlock = styled('div')((props: { theme: Theme }) => ({
  // minHeight: '54px'
  marginBottom:'1.5rem'
}));
export const FieldValue     = styled('div')((props: { theme: Theme }) => ({
  minHeight   : '30px',
  padding:'0.35rem 0',
  marginBottom: '0.5rem'
}));

export const FooterBlock = styled('div')((props: { theme: Theme }) => ({
  display      : 'flex',
  flexDirection: 'row',
  gridGap      : '16px',
  padding      : '1rem',
  alignItems   : 'center',
  background   : props.theme.palette.mode === 'light' ? '#F8F9FB' : props.theme.palette.background.default,
  borderRadius : props.theme.palette.mode === 'light' ? 0 : '4px',
  borderTop    : '1px solid ' + props.theme.skin.borderColor,
  position     : 'sticky',
  bottom       : 0,
  marginBottom : 0,
  zIndex       : 20
}));

export const CheckBoxGroupBlock = styled(FormGroup)((props: { theme: Theme }) => ({
  display      : 'flex',
  gridGap      : '16px',
  paddingLeft  : '32px',
  flexDirection: 'row',
}));

export const FormInputContainer = styled('div')((props: { theme: Theme }) => ({
  padding        : '1rem',
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  overflow       : 'hidden',
  width          : '300px',
}));

export const ShadowBox = styled('div')((props: { theme: Theme }) => ({
  boxShadow   : props.theme.skin.boxShadow,
  borderRadius: '10px',
  position:'relative'
}));

export function LabelZone(props: { title?: string, content?: string | string[], className?: string, code?: string, style?: Record<string, any> }) {
  return <div style={{...props.style}} className={props.className ?? ''}>
    <FieldLabel style={{textTransform:'capitalize'}}  className={' '}>{props.title}</FieldLabel>
    <FieldValue className={' '} data-cy={props.code}>
    {
    (Array.isArray(props.content)) ? <>
      {props.content.map((item) => {
        return <Typography key={item} noWrap>{item}</Typography>
      })}
      </>
      :
      <Typography noWrap>{props.content}</Typography>
    }
    </FieldValue>
  </div>;
}



export function FieldZone(props: { title?: string | any, children?: any, className?: string, code?: string, required?: boolean }) {
  return <div data-cy={props.code} className={props.className ?? ''}>
    <InputFieldLabel className={' '} required={props?.required}>{props.title}</InputFieldLabel>
    <TextFieldBlock className={' '}>{props.children}</TextFieldBlock>
  </div>;
}