import {useTranslation} from 'react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import {DetailBlock, FieldZone} from '../../components/templates/InfoBlock';
import {ICompanyUsers} from '../../application/entities/dataTypes/companyUser';
import {Button, MenuItem, Select, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {objectApi} from '../../application/entities/dataApi';
import {useAppToast} from '../../components/live/AppToast';
import {BackendError} from '../../types/backendError';


interface IUserDetailsProps {
  userData: ICompanyUsers;
}

const personTitlesList = ['Mr', 'Ms', 'Dr', 'Other', 'Prefer to leave blank'];

export const UserProfileForm: React.FC<IUserDetailsProps> = (props) => {
  
  const {userData} = props;
  const {t}        = useTranslation();
  const appToast   = useAppToast();
  
  const userApi = new objectApi.companyUsers();
  
  const {
          handleSubmit,
          register,
          reset,
          control,
          formState: {
            isValid,
            errors,
          }
        } = useForm({
    mode: 'all'
  });
  
  React.useEffect(() => {
    if (userData.id) {
      reset({
        title    : userData.title,
        firstName: userData.firstName,
        lastName : userData.lastName,
        email    : userData.email,
        jobTitle : userData.jobTitle,
        phone    : userData.phone,
      });
    }
  }, [userData.id]);
  
  function onSubmit(user: Partial<ICompanyUsers>) {
    userApi.update(userData.id, user).then(() => {
      appToast.success({
        message: 'User was updated',
      });
    }).catch((e: BackendError) => {
      appToast.error({
        message: e?.message ?? 'An error occurred',
      });
    });
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserDetailsBlock>
        <div>
          <FieldZone title={t('account.userDetails.title')} code={'title'} required={true}>
            <Controller
              name="title"
              control={control}
              rules={{required: 'this is required'}}
              render={({field}) => (
                <Select {...field}
                        value={field.value ?? '  '}
                        onChange={(e, val: any) => {
                          field.onChange(val?.props?.value);
                        }}
                        error={Boolean(errors?.title)}
                        fullWidth={true}>
                  {personTitlesList.map(title => <MenuItem value={title}>{title}</MenuItem>)}
                </Select>
              )}
            />
          </FieldZone>
        </div>
        <div></div>
        <div>
          <FieldZone title={t('account.userDetails.firstName')} code={'firstName'} required={true}>
            <TextField {...register('firstName', {required: true})} error={Boolean(errors.firstName)}/>
          </FieldZone>
        </div>
        <div>
          <FieldZone title={t('account.userDetails.surname')} code={'lastName'} required={true}>
            <TextField {...register('lastName', {required: true})} error={Boolean(errors.lastName)}/>
          </FieldZone>
        </div>
        <div>
          <FieldZone title={t('account.userDetails.email')} code={'email'} required={true}>
            {userData.email}
          </FieldZone>
        </div>
        <div>
          <FieldZone title={t('account.userDetails.jobTitle')} code={'jobTitle'} required={true}>
            <TextField {...register('jobTitle', {required: true})} error={Boolean(errors.jobTitle)}/>
          </FieldZone>
        </div>
        <div>
          <FieldZone title={t('account.userDetails.phone')} code={'phone'}>
            <TextField {...register('phone')} error={Boolean(errors.phone)}/>
          </FieldZone>
        </div>
      </UserDetailsBlock>
      <div>
        <Button variant={'contained'} disabled={!isValid} type={'submit'}>Save</Button>
      </div>
    </form>
  );
};

const UserDetailsBlock = styled(DetailBlock)`
  display: grid;
  min-width: 556px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  grid-row-gap: 5px;
`;