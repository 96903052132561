import {InputLinkedId} from "./InputLinkedId";
import {useAudienceBuilderStore} from "../../Store/AudienceBuilderStore";
import {Box} from "@mui/material";
import React from "react";

type InputInterestBrandProps = {
    featureCode: string;
};

export const InputInterestBrand = (props: InputInterestBrandProps) => {

    const locationFilterValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "LOCATION";
    })[0];
    const audienceActions = useAudienceBuilderStore.getState().actions;

    const countriesCodesWithBrandInterest = ["GB", "AU"];

    const isBrandInterestActive = () => {
        if (locationFilterValues?.selected.filter((selection) => {
            return countriesCodesWithBrandInterest.includes(selection.id);
        }).length > 0) {
            return true;
        }
        return false;
    };


    if (!isBrandInterestActive()) {
        return <Box sx={{width: "100%", position: "relative"}}>
            <Box data-cy="brand-interest-disabled" sx={{
                borderRadius: "7px",
                padding: "0.5rem",
                backgroundColor: "#f5f5f5"
            }}>
                <p style={{color: "#7587a3"}}>
                    To enable Brands filter, select at least one of the following countries from the{" "}
                    <span
                        style={{cursor: "pointer", color: "#00b5e2"}}
                        onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
                    >
              location filter
            </span>
                    : <br/>
                    - United Kingdom
                    <br/>- Australia
                </p>
            </Box>
        </Box>
    }

    return <InputLinkedId featureCode={props.featureCode}/>

}