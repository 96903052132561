import {countChildrenAndSubChildren, TreeItemType} from "pages/AudienceBuilder/audienceBuilderUtils";
import {AppTip} from "../../AudienceBuilderAppTip";
import {Theme, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {IFiltersValues} from "pages/AudienceBuilder/types/audienceBuilderTypes";
import _ from "lodash";
import {useDrag} from "react-dnd";
import React from "react";

type TreeViewVisualisationProps = {
    tree: TreeItemType[];
    expandVisible?: boolean;
    maxItemToDisplay?: number;
    expandAll?: boolean;
    expandedPaths?: string[];
    filterValue?: IFiltersValues;
    expandCode?: string; //used by interest only
    itemFeatureId?: number;
    isDragAndDropActive?: boolean;
    viewMode?: boolean;
};
export const TreeViewVisualisation = (props: TreeViewVisualisationProps) => {
    const {
        tree,
        expandVisible = false,
        maxItemToDisplay = 3,
        expandAll = false,
        expandedPaths,
        filterValue,
        expandCode,
        itemFeatureId,
        isDragAndDropActive,
    } = props;

    const audienceBuilderStoreActions = useAudienceBuilderStore.getState().actions;
    const treeExpandedList = useAudienceBuilderStore(
        (st) => st.treeExpandedList.filter((tree) => tree.featureCode === (filterValue?.code ?? expandCode!))[0],
        (old, ne) => _.isEqual(old, ne)
    );

    const toggleExpand = (path: string) => {
        if (filterValue?.code || expandCode) audienceBuilderStoreActions.toggleTreeItem(filterValue?.code ?? expandCode!, path);
    };
    const isExpanded = (path: string) => {
        if (expandAll || props.viewMode) return true;
        if (tree.length === 1 && tree[0].children.length === 1) return true
        return treeExpandedList?.expandedPath.includes(path);
    };

    const DisplayFinalItemWithDrag = (treeListItem: TreeItemType, level: number): any => {
        const [{isDragging}, drag] = useDrag(() => ({
            type: itemFeatureId ? itemFeatureId.toString() : "interest",
            item: {treeListItem, itemFeatureId},
            canDrag: isDragAndDropActive,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));

        return (
            <FeatureLabel
                ref={drag}
                title={treeListItem.name}
                data-cy={treeListItem.name}
                level={level}
                style={{backgroundColor: "#fff", cursor: "grab", opacity: isDragging ? "0.3" : "1"}}
            >
                <Typography noWrap={true} sx={{fontSize: "8px", textOverflow: "ellipsis"}}>
                    {treeListItem.name}
                </Typography>
            </FeatureLabel>
        );
    };

    const DisplayFinalItem = (treeListItem: TreeItemType, level: number): any => {
        return (
            <FeatureLabel data-cy={treeListItem.path} title={treeListItem.name} level={level}
                          style={{backgroundColor: "#fff"}}>
                <Typography noWrap={true} sx={{fontSize: "8px", textOverflow: "ellipsis"}}>
                    {treeListItem.name}
                </Typography>
            </FeatureLabel>
        );
    };

    const DisplayIntermediateLevel = (treeListItem: TreeItemType, level: number): any => {
        return (
            <LevelTitle
                title={treeListItem.name}
                level={level}
                style={{cursor: "pointer", marginBottom: !isExpanded(treeListItem.path) ? "0" : "0.25rem"}}
                onClick={
                    expandVisible
                        ? () => {
                            toggleExpand(treeListItem.path);
                        }
                        : () => {
                        }
                }
            >
                <>
                    {treeListItem.name} ({countChildrenAndSubChildren(treeListItem)})
                </>
            </LevelTitle>
        );
    };

    const dspTree = (treeListItem: TreeItemType, level: number): any => {
        if (!treeListItem?.children?.length) {
            return isDragAndDropActive ? DisplayFinalItemWithDrag(treeListItem, level) : DisplayFinalItem(treeListItem, level);
        } else if (treeListItem?.children?.length > maxItemToDisplay && treeListItem.children.length > maxItemToDisplay + 1) {
            let childList = [...treeListItem?.children];
            return (
                <>
                    <Level level={level}>
                        {DisplayIntermediateLevel(treeListItem, level)}
                        <SubLevel data-cy={"parent-" + treeListItem.path}
                                  className={!isExpanded(treeListItem.path) ? "tree-reduced" : "tree-expanded"}>
                            {childList
                                .slice(0, maxItemToDisplay)
                                .sort((a, b) => (a.name < b.name ? -1 : 1))
                                .map((child: TreeItemType) => {
                                    return <>{dspTree(child, level + 1)}</>;
                                })}
                            <AppTip
                                text={childList
                                    .splice(maxItemToDisplay, childList.length)
                                    .map((item) => {
                                        return item.name;
                                    })
                                    .flat()
                                    .toString()
                                    .replaceAll(",", "\n")}
                            >
                                <FeatureLabel level={level} style={{marginLeft: "0", cursor: "default"}}>
                                    <Typography noWrap={true} sx={{fontSize: "8px", textOverflow: "ellipsis"}}>
                                        + {treeListItem?.children.length - maxItemToDisplay}
                                    </Typography>
                                </FeatureLabel>
                            </AppTip>
                        </SubLevel>
                    </Level>
                </>
            );
        } else {
            return (
                <Level level={level}>
                    {DisplayIntermediateLevel(treeListItem, level)}
                    <SubLevel data-cy={"parent-" + treeListItem.path}
                              className={!isExpanded(treeListItem.path) ? "tree-reduced" : "tree-expanded"}>
                        {treeListItem?.children
                            ?.sort((a, b) => (a.name < b.name ? -1 : 1))
                            .map((item: TreeItemType) => {
                                return <>{dspTree(item, level + 1)}</>;
                            })}
                    </SubLevel>
                </Level>
            );
        }
    };

    return (
        <>
            {tree
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((treeListItem: TreeItemType, index: number) => {
                    return (
                        <div style={{width: "100%"}} key={treeListItem.path}>
                            {dspTree(treeListItem, 1)}
                        </div>
                    );
                })}
        </>
    );
};

const Level = styled("div")((props: { theme: Theme; level: number }) => ({
    // marginTop: props.level >1 ? '0.25rem': '0',
    padding: "0.25rem",
    width: "100%",
    background: props.level === 1 ? "#f1f3f7" : "transparent",
    border: "1px solid #e7e7e7",
    borderRadius: "6px",
    "& .tree-reduced": {
        height: "0px",
        overflow: "hidden",
    },
}));

const SubLevel = styled("div")((props: { theme: Theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "0.25rem",
    maxWidth: "125px",
}));

const LevelTitle = styled("p")((props: { theme: Theme; level: number }) => ({
    fontSize: "8px",
    lineHeight: "12px",
    width: "100%",
    textOverflow: "ellipsis",
    margin: "0",
    //marginBottom: props.level > 1 ? '0.25rem': '0.15rem',
    fontWeight: props.level === 1 ? "900" : "400",
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between",
}));

const FeatureLabel = styled("div")((props: { theme: Theme; level: number; mode?: "single" | "grouped" }) => ({
    fontSize: "8px!important",
    display: "inline-block",
    textOverflow: "ellipsis",
    position: "relative",
    lineHeight: "12px",
    overflow: "hidden",
    borderRadius: "64px",
    border: "1px solid " + props.theme.skin.borderColor,
    padding: "0 0.5rem",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    // marginLeft: props.level >1 ? '0.5rem': '0',
    backgroundColor: props.mode === "grouped" ? props.theme.palette.background.default : props.theme.palette.background.paper,
    "&:hover": {
        border: "1px solid rgba(0,0,0,0.6)",
        boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
    },
}));
