import {Button, Divider, Typography} from "@mui/material";
import {EPermission} from "application/utils/granter.utils";
import {JiraCollector} from "components/interface/JiraCollector";
import {GridMiddleCenter} from "components/ui/AppElements";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {accessList} from "../../application/access/access";
import {objectApi} from "../../application/entities/dataApi";
import {dataObject} from "../../application/entities/dataObjects/DataObjectClass";
import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {PageId} from "../../application/pages/pages.config";
import {pageUtils} from "../../application/pages/pages.utils";
import {AUTHUser} from "../../application/utils/AuthUser";
import {IconSet} from "../../assets/icons";
import {ActionGranter} from "../../components/features/ActionGranter";
import {ButtonCreate} from "../../components/features/ButtonCreate";
import {useAccountQuery} from "../../components/hooks/data/useAccountQuery";
import {useOpenDialogWindow} from "../../components/live/DialogWindow";
import {AppIcon} from "../../components/ui/AppIcon";
import {useFeatureFlag} from "../../stores/featureFlags.store";
import {SCard, SCardTitle, SGut} from "./styledElements";
import {AccountUsers} from "./widgets/AccountUsers";
import {AudiencesListWidget} from "./widgets/Audiences";
import {AudiencesToApprove} from "./widgets/AudiencesToApprove";
import {ChannelsRecommendations} from "./widgets/ChannelsRecommendations";
import {WorldMap} from "./widgets/WorldMap";

interface ClientDashboardProps {
    account?: IAccounts;
    brandId?: number;
}

export const ClientDashboard = (props: ClientDashboardProps) => {
    const accountId = props?.account?.id ?? AUTHUser.accountId;
    const companyId = props?.account?.parentCompanyId

    const dialogWindow = useOpenDialogWindow();
    //
    const accountQuery = useAccountQuery(accountId);
    const accountData: IAccounts = accountQuery?.data ?? {};
    //
    const history = useNavigate();
    const audiencesApi = new objectApi.audiences();
    const accountsApi = new objectApi.accounts();

    const {t} = useTranslation();

    const [mapData, setMapData] = React.useState<Record<string, any>>({});
    const [metrics, setMetrics] = React.useState<any>({});
    const [metricsLoading, setMetricsLoading] = React.useState<boolean>(true);
    const [pendingApproval, setPendingApproval] = React.useState<any>([]);
    const featFlagStore = useFeatureFlag((st) => st.featureFlags);
    const tmpMapData = {};

    const createData = dataObject.getDataObject("audiences", {
        name: t("audience.untitledAudience"),
        account: `account/${accountId}`,
    });

    const formatter = new Intl.NumberFormat("en-IN", {minimumFractionDigits: 0, maximumFractionDigits: 1});

    const iconWeekChange = metrics.weekChangePercent === 0 ? "NoChanges" : metrics.weekChangePercent > 0 ? "ArrowUp" : "ArrowDown";
    const iconMonthChange = metrics.monthChangePercent === 0 ? "NoChanges" : metrics.monthChangePercent > 0 ? "ArrowUp" : "ArrowDown";

    const txtWeekChange =
        metrics.weekChangePercent === 0
            ? t("dashboard.status.noChanges")
            : metrics.weekChangePercent > 0
                ? t("dashboard.actions.increase")
                : t("dashboard.actions.decrease");
    const txtMonthChange =
        metrics.monthChangePercent === 0
            ? t("dashboard.status.noChanges")
            : metrics.monthChangePercent > 0
                ? t("dashboard.actions.increase")
                : t("dashboard.actions.decrease");

    const colorWeekChange = metrics.weekChangePercent === 0 ? "blue" : metrics.weekChangePercent < 0 ? "#FC6050" : "#02C697";
    const colorMonthChange = metrics.monthChangePercent === 0 ? "blue" : metrics.monthChangePercent < 0 ? "#FC6050" : "#02C697";

    const getPendingApproval = () => {
        accountsApi
            .searchFullAudiences({
                byId: +accountId,
                searchValue: "Pending_approval",
                searchField: "status",
            })
            .then((res) => {
                if (res.data?.detailedResults?.length > 0) {
                    //sort approval by approverAsked
                    let datas = res.data.detailedResults[0].result.content;
                    if (datas?.length > 0) {
                        datas.sort((a: any, b: any) => {
                            if (a.approverAsked.id === AUTHUser.userId && b.approverAsked.id !== AUTHUser.userId) {
                                return -1;
                            }
                            if (a.approverAsked.id !== AUTHUser.userId && b.approverAsked.id === AUTHUser.userId) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    setPendingApproval(datas);
                } else {
                    setPendingApproval([]);
                }
            });
    };

    React.useEffect(() => {
        accountsApi.getDashBoard(accountId).then((res) => {
            if (res.data) {
                setMetrics({
                    totalAddressableProfilesForMyAccount: res.data.totalAddressableProfilesForMyAccount ?? 1,
                    totalAddressableProfiles: res.data.totalAddressableProfiles,
                    weekChangePercent: res.data.weekChangePercent,
                    monthChangePercent: res.data.monthChangePercent,
                });
                setMetricsLoading(false);
                if (Object.keys(res.data.countriesProfiles).length === 0) {
                    setMapData(tmpMapData);
                } else {
                    setMapData(res.data.countriesProfiles);
                }
            }
        });

        getPendingApproval();
    }, [accountId]);

    function goToPage(pageId: PageId | string) {
        history(pageUtils.getPagePathById(pageId, {accountId: accountId}));
    }

    function inviteUser() {
        // @ts-ignore
        if (AUTHUser.accountType && ["COMPANY", "BRAND"].includes(AUTHUser.accountType)) {
            history(pageUtils.getPagePathById(PageId.brandAgentList, {
                companyId: props?.account?.parentCompanyId,
                brandId: accountId
            }));
        }
        // legacy
        else {
            goToPage(PageId.userCreate);
        }
    }

    // cas special : si en mode brand, role =  user.assoc.role = 'ADMINISTRATOR'
    if (accountData?.accountType === "BRAND") {
        AUTHUser.getUser().agentBrandAccountAssociations.filter((x) => x.account.id);
    }
    if (accountData?.accountType === "BRAND" && AUTHUser.getUser().account.accountType === "COMPANY") {
        AUTHUser.getUser().agentBrandAccountAssociations.filter((x) => x.account.id);
    }

    function openJiraCollector() {
        dialogWindow.open({
            dialogId: "jira-collector",
            title: "We value your feedback",
            icon: "FeedbackIcon",
            component: JiraCollector,
        });
    }

    return (
        <>
            <div data-cy="dashboard-clientDashboard"
                 style={{display: "flex", gap: "16px", margin: "0", marginBottom: "16px"}}>
                <SGut style={{flex: 1, display: "flex", flexDirection: "column"}}>
                    {!Boolean(props.account) && (
                        <SCard
                            style={{
                                display: "flex",
                                padding: 0,
                                margin: "0 auto",
                                width: "100%",
                                height: "241px",
                                maxHeight: "241px",
                                position: "relative"
                            }}
                        >
                            <div style={{flex: 1, paddingLeft: "1rem", zIndex: 1}}>
                                <Typography sx={{mt: 8, mb: 2, textShadow: "0 0 5px white"}} variant={"h4"}>
                                    {t("audience.buildAudience")}
                                </Typography>
                                <Typography sx={{
                                    width: "300px",
                                    mb: 2,
                                    textShadow: "0 0 5px white"
                                }}>{t("audience.easyBuilding")}</Typography>
                                <ActionGranter accesses={accessList.audiences.create}>
                                    <ButtonCreate
                                        entityName={"audience"}
                                        data={createData}
                                        apiBase={audiencesApi}
                                        text={t("audience.getStarted")}
                                        openFrameId={"audience-builder-update"}
                                    />
                                </ActionGranter>
                            </div>
                            <div style={{flex: 1, zIndex: 0}}>
                                <div style={{position: "absolute", bottom: 0, right: 0}}>
                                    <IconSet.HeaderClient style={{display: "block"}}/>
                                </div>
                                <div style={{position: "absolute", bottom: 0, right: "132px"}}>
                                    <img style={{display: "block"}} height={"240px"} alt={"Welcome screen"}
                                         src={"headerClient.png"}/>
                                </div>
                            </div>
                        </SCard>
                    )}
                    {pendingApproval?.length > 0 && AUTHUser.permissions.accountGrade === EPermission.COMPANYADMIN && (
                        <AudiencesToApprove updatePendingApproval={getPendingApproval} accountId={accountId}
                                            pendingApproval={pendingApproval}/>
                    )}

                    <SCard style={{flex: 1, position: "relative", minHeight: "400px", paddingBottom: "50px"}}
                           data-cy={"dashboard-audiences"}>
                        <GridMiddleCenter sx={{gap: 1, marginBottom: "20px"}}>
                            <div style={{position: "relative"}}>
                                <AppIcon color={"#3B4559"} display={"inline"} icon={"Audiences"} chip={true}/>
                            </div>
                            <SCardTitle style={{flex: 1}}>Your Audiences</SCardTitle>
                        </GridMiddleCenter>
                        <AudiencesListWidget accountId={accountId}/>
                    </SCard>
                    <SCard style={{display: "flex", flexDirection: "column", gap: "16px"}}>


                        <div>
                            <SGut style={{flex: 1}}>
                                <SCardTitle style={{
                                    flex: 1,
                                    paddingLeft: "10px",
                                    marginTop: "15px"
                                }}>{t("dashboard.titles.profileMap")}</SCardTitle>
                                <SCard style={{height: "auto", overflow: "hidden", padding: 0}}
                                       data-cy={"dashboard-map"}>
                                    <WorldMap mapData={mapData}/>
                                </SCard>
                            </SGut>
                        </div>
                    </SCard>
                </SGut>
                <SGut style={{maxWidth: "297px"}}>
                    <ChannelsRecommendations accountId={accountId} goToPage={goToPage}/>
                    <SCard style={{display: "flex", flexDirection: "column", gap: "8px"}}
                           data-cy={"dashboard-your-team"}>
                        <GridMiddleCenter sx={{gap: 1}}>
              <span style={{position: "relative"}}>
                <AppIcon color={"#3B4559"} display={"inline"} icon={"TeamIcon"} chip={true}/>
              </span>
                            <SCardTitle style={{flex: 1}}>{t("dashboard.titles.yourTeam")}</SCardTitle>
                        </GridMiddleCenter>
                        <Divider/>

                        <div style={{flex: 1, overflow: "auto", paddingBlock: "10px"}}>
                            <AccountUsers account={props?.account} brandId={props.brandId}/>
                        </div>
                        {props.brandId && AUTHUser.isBrandAdmin(Number(props.brandId)) &&
                            <Button data-cy="dashboard-users-management-btn"
                                    onClick={() => history(pageUtils.getPagePathById(PageId.brandUserManagement, {
                                        companyId: companyId,
                                        brandId: props.brandId
                                    }))}
                                    variant={"outlined"}>
                                User Management
                            </Button>}

                        {Boolean(accountData.accountType !== "BRAND") && (
                            <>
                                <ActionGranter accesses={accessList.companyUsers.create}>
                                    <>
                                        <Divider/>
                                        <Button style={{fontWeight: "bold"}} data-cy="dashboard-users-management-btn"
                                                onClick={inviteUser} variant={"outlined"}>
                                            {t("dashboard.titles.inviteMore")}
                                        </Button>
                                    </>
                                </ActionGranter>
                                <ActionGranter mode={"deny"} accesses={accessList.companyUsers.create}>
                                    <Divider/>
                                    <Typography>{t("audience.contactAdminMoreMembers")}</Typography>
                                </ActionGranter>
                            </>
                        )}
                    </SCard>
                    <SCard data-cy={"dashboard-your-datalist"}>
                        <GridMiddleCenter sx={{gap: 1}}>
              <span style={{position: "relative"}}>
                <AppIcon color={"#3B4559"} display={"inline"} icon={"List"} chip={true}/>
              </span>
                            <div style={{
                                flex: 1,
                                color: "#7587A3",
                                fontSize: "16px",
                                paddingLeft: "5px"
                            }}>{t("dashboard.titles.yourDataList")}</div>
                            {Boolean(AUTHUser.getUserAccount()?.databaseName) && (
                                <ActionGranter accesses={accessList.prePackagedList.upload}>
                                    <div>
                                        <Button
                                            style={{fontWeight: "bold"}}
                                            onClick={() => {
                                                goToPage(PageId.lists);
                                            }}
                                            variant={"outlined"}
                                        >
                                            {t("dashboard.titles.uploadList")}
                                        </Button>
                                    </div>
                                </ActionGranter>
                            )}
                        </GridMiddleCenter>
                    </SCard>
                    <SCard data-cy={"dashboard-feedback"}>
                        <GridMiddleCenter sx={{gap: 1}}>
                            <div style={{position: "relative"}}>
                                <AppIcon color={"#3B4559"} display={"inline"} icon={"YourfeedbackIcon"} chip={true}/>
                            </div>
                            <div style={{
                                flex: 1,
                                color: "#7587A3",
                                fontSize: "16px",
                                paddingLeft: "5px"
                            }}>{t("dashboard.titles.yourFeedback")}</div>
                            <div>
                                <Button style={{fontWeight: "bold"}} variant={"outlined"} onClick={openJiraCollector}>
                                    {t("dashboard.titles.writeFeedback")}
                                </Button>
                            </div>
                        </GridMiddleCenter>
                    </SCard>
                </SGut>
            </div>

            <div style={{display: "flex", gap: "16px", margin: "0 auto", maxWidth: "1094px"}}></div>
        </>
    );
};
