import { AbstractBaseApi } from './AbstractBaseApi';
import { IJiraIssue } from '../dataTypes/jiraIssue';
import { apiService } from '../api/apiService';

export class JiraIssue extends AbstractBaseApi<IJiraIssue> {
  constructor() {
    super('jiraIssue');
  }

  postJiraIssue(data: IJiraIssue) {
    return apiService.route('createJiraIssue')
      .post({data: JSON.stringify(data)})
      .then(res => res)
      .catch((error: any) => {
        throw new Error(error);
      });
  }

}