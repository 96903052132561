import {ColumnsMiddle, Elem, GridItemFull} from '../../ui/AppElements';
import {Button, ClickAwayListener, Input, Popper, TextField} from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

export type TOnChange = { value: string, switchValue: boolean }

interface AppFreeTextProps {
  value: any;
  onChange?: (item: TOnChange) => void;
  placeHolder?: string
};


export const AppFreeText = (props: AppFreeTextProps) => {
  
  const [anchorEl, setAnchorEl]       = React.useState<null | HTMLElement>(null);
  const [inputValue, setInputValue]   = React.useState<string>('');
  const [switchValue, setSwitchValue] = React.useState<boolean>(true);
  
  const open = Boolean(anchorEl);
  
  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleInputChange = (event: any) => {
    setInputValue(event.currentTarget.value);
  };
  
  const handleValidate = () => {
    setAnchorEl(null);
    if (props.onChange) {
      props.onChange({
        value      : inputValue,
        switchValue: switchValue
      });
    }
  };
  
  const HandleSubmit = (event: any) => {
    event.stopPropagation();
  };
  
  return <React.Fragment>
    <div>
      <TextField
        name={'name'}
        onClick={handleInputClick}
        fullWidth={true}
        inputProps={{readOnly:true}} 
        value={props.value}
        placeholder={ props?.placeHolder ?? `www.example.com`}/>
    </div>
    <Popper
      id={'idpopper'}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{marginTop: '1rem'}}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <SPopper>
          <Elem padding={[0]}>
            <Elem padding={[0.5]}>Enter domain</Elem>
            <Elem>
              <form onSubmit={HandleSubmit} noValidate={true}>
                <ColumnsMiddle>
                  <GridItemFull padding={[0.5]}>
                    <Input
                      name={'name'}
                      onChange={handleInputChange}
                      fullWidth={true}
                      value={inputValue}
                      placeholder={`Pick`}
                    />
                  </GridItemFull>
                  <Elem padding={[0.5]}>
                    <Button
                      type={'submit'}
                      onClick={handleValidate}
                      style={{width: '70px'}}
                      variant={'contained'}
                    >Add</Button>
                  </Elem>
                </ColumnsMiddle>
                {/*TODO it was asked to remove switch*/}
                {/*<Columns>*/}
                {/*    <Elem padding={[0.5]}>*/}
                {/*        <AppSwitch onChange={handleSwitchChange}*/}
                {/*                   label={'Include top level domains\r\ne.g .co.uk'}*/}
                {/*                   checked={switchValue}/>*/}
                {/*    </Elem>*/}
                {/*    <GridItemFull padding={[0.5]}>*/}
                {/*    </GridItemFull>*/}
                {/*</Columns>*/}
              </form>
            </Elem>
          </Elem>
        </SPopper>
      </ClickAwayListener>
    
    </Popper>
  </React.Fragment>;
};

const SPopper = styled(Elem).attrs({padding: [1, 1.5]})({
  border      : '1px solid rgba(27,31,35,.15)',
  boxShadow   : '0px 0px 6px rgba(196, 211, 241, 0.85)',
  borderRadius: 20,
  /*minWidth       : 275,*/
  zIndex         : 1000,
  backgroundColor: 'white'
});