import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';


export const GhostButton = withStyles((theme: Theme) => ({
  root: {
    height: '30px',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    color: '#00B5E2',
    background: theme.palette.background.paper,
    border: '0.5px solid #00B5E2;',
    boxShadow: '0px 4px 9px rgba(14, 199, 246, 0.3)',
    borderRadius: '20px',
    fontSize: 12,
    textTransform: 'none',
    '&:focus': {
      boxShadow: '0px 4px 9px rgba(14, 199, 246, 0.3)',
    },
    '&:hover': {
      background: '#FFFFFF',
    },
    '&:disabled': {
      color: '#3B4559',
      background: '#F0F2F7',
      border: 'transparent 0.5px solid',
    },
  },
}))(Button);