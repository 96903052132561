import React, { useState } from "react";
// import { Navigate ,Routes, Route,  useLocation} from 'react-router-dom';
import useLocalStorageState from "use-local-storage-state";
import { RetrievePassword } from "./RetrievePassword";
import { Signin } from "./Signin";
import { UserInvitation } from "./UserInvitation";
// import {TokenVerify} from '../../../application/utils/aws.utils';
import { CircularProgress, Fade, ThemeProvider, styled } from "@mui/material";
import { AppContainer, AuthContainer, AuthContainerBody, AuthModalBox, AuthModalBoxInner } from "./authentication.styled";

import { authService } from "application/services/auth.service";
import { AppToast, useAppToast } from "components/live/AppToast";
import { useAuthActions, useAuthState } from "redux/slices/auth.slices";

import { getSubdomainList } from "application/utils/domain.utils";
import { Auth } from "aws-amplify";
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import { apiDataFactory, objectApi } from "../../../application/entities/dataApi";
import { PageId, appPages } from "../../../application/pages/pages.config";
import { pageUtils } from "../../../application/pages/pages.utils";
import { AUTHUser } from "../../../application/utils/AuthUser";
import { useUiStore } from "../../../redux/ui.store";
import { authStore } from "../../../stores/auth.store";
import { useFeatureFlag } from "../../../stores/featureFlags.store";
import { createAppTheme } from "../../../styles/theme";
import { useLoggedUser } from "../../hooks/useLoggedInUser";
import { RedirectInvite } from "./UserInvitation/RedirectInvite";

export const withAuthentication = (WrappedComponent: React.FC): React.FC<{}> => {
  const Component = (props: any) => {
    let authHeartBeat: any = React.useRef();

    const authActions = useAuthActions();
    const authState = useAuthState();
    const appToast = useAppToast();
    // new entry
    const loggedInUser = useLoggedUser();

    // should replace the redux store
    const useAuthStore = authStore();

    const featureFlagStoreAction = useFeatureFlag().actions;

    const [showLoadingState, setShowLoadingState] = useState<boolean>(false);

    const [shallPass, setShallPass] = useState<boolean>(false);
    const [userFetched, setUserFetched] = useState<boolean>(false);

    const [lStatus, setLStatus] = useLocalStorageState("login", false);

    const navigate = useNavigate();
    const accountsApi = new objectApi.accounts();

    const companyUsersApi = apiDataFactory("companyUsers"); // new objectApi.companyUsers();
    const companyUsersObjectApi = new objectApi.companyUsers();
    const themeMode = useUiStore((st) => st.mode);

    let newTheme = createAppTheme({
      palette: { mode: themeMode },
    });

    React.useEffect(() => {
      refreshToken();
      return () => {};
    }, []);

    React.useEffect(() => {
      if (!authState.loggedIn && lStatus) {
        refreshToken();
      }
    }, [authState.loggedIn, lStatus]);

    // patch, auto log user if already logged in cognito
    React.useEffect(() => {
      if (loggedInUser?.user?.id) authStore.setState({ loggedIn: true });
    }, [loggedInUser?.user?.id]);

    React.useEffect(() => {
      if (authState.loggedIn && userFetched) {
        setShowLoadingState(false);
        setShallPass(true);
        setLStatus(true);
      } else {
        setShallPass(false);
        setLStatus(false);
      }
    }, [authState.loggedIn, userFetched]);

    //
    React.useEffect(() => {
      if (authState.loggedIn) {
        onLoggedIn();
        startPooler();
      }
      // clear timeout on exit
      return () => {
        window.clearTimeout(authHeartBeat.current);
      };
    }, [authState.loggedIn]);

    React.useEffect(() => {
      /** @deprecated redux */
      if (authState.error) {
        appToast.error({
          toastId: "password_retrieve",
          message: authState.error.message,
        });
        if (Boolean(authState.showError))
          setTimeout(() => {
            authActions.resetError();
          }, 5000);
      }
    }, [authState.error]);

    React.useEffect(() => {
      if (useAuthStore.error) {
        appToast.error({
          toastId: "password_retrieve",
          message: useAuthStore.error.message,
        });
        if (Boolean(useAuthStore.showError)) {
          setTimeout(() => authStore.setState({ showError: false }), 5000);
        }
      }
    }, [useAuthStore.error]);

    async function onLoggedIn() {
      // defines AUTHUser
      await fetchUserData();
      if (AUTHUser.getUser()?.id && !AUTHUser.getUser()?.firstName?.length) {
        // should navigate to profile
        navigate(pageUtils.getPagePathById(PageId.userProfileUpdate, { userId: AUTHUser.getUser().id }));
      }

      const userData = await companyUsersApi.find({ searchField: "loggedInUser" });
      //check the subdomain
      accountsApi.getAccountConfParameters(userData?.data?.account?.id).then((res: any) => {
        const accountSubDomain = res.data.accountConfParameters?.white_label_name;
        if (accountSubDomain && accountSubDomain !== " " && accountSubDomain !== "") {
          if (getSubdomainList().includes(accountSubDomain) && !window.location.hostname.includes(accountSubDomain)) {
            //in the wrong subDomain
            const protocol = window.location.href.split(":")[0];

            let env = "app";
            if (window.location.hostname.split(".")[0] === "ci") {
              env = "ci.app";
            }
            if (window.location.hostname.split(".")[0] === "dev") {
              env = "dev.app";
            }
            if (window.location.hostname.split(".")[0] === "test") {
              env = "test.app";
            }
            const goodUrl = `${protocol}://${accountSubDomain}.${env}.evorra.com`;
            if (window.location.hostname !== "localhost") {
              authService.signOut().then((res: any) => {
                window.location.replace(goodUrl);
              });
            } else {
              console.log("Should redirect to url in no localhost env", goodUrl);
            }
          }
        }
      });
    }

    function fetchUserData() {
      // retrieve user
      // set status to ACTIVATED if needed
      // load featureFlags related to the account
      try {
        setShowLoadingState(true);
        companyUsersApi.find({ searchField: "loggedInUser" }).then((res: any) => {
          if (res?.data) {
            if (res.data.status !== "ACTIVATED") {
              companyUsersApi.update(res.data.id, { status: "ACTIVATED" });
            }
            if (!res.data.first?.length) {
              // should navigate;
            }

            featureFlagStoreAction.fetchFeatureFlags(res.data.account.id);
            // set user singleton, will be deprecated
            AUTHUser.setUser(res.data);
            // new user auth store
            loggedInUser.refetch();

            setTimeout(() => {
              setShowLoadingState(false);
              setUserFetched(true);
              if (authState.loggedIn && AUTHUser.userId) {
                companyUsersObjectApi.loginEvent(AUTHUser.userId);
              }
            }, 150);
          }
        });
      } catch (e) {}
    }

    async function startPooler() {
      // console.log('start pooler ...');
      if (authHeartBeat.current) window.clearTimeout(authHeartBeat.current);
      const curr = Auth.currentSession()
        .then(() => {
          authHeartBeat.current = setTimeout(startPooler, 5000);
        })
        .catch(() => {
          console.log("should logout");
          authActions.doSignOut();
        });
    }

    //
    function refreshToken() {
      // refresh cognito session
      authActions.doRefreshSession().then((res: any) => {
        if (res?.payload) {
          // @ts-ignore
          const token = res.payload.accessToken.getJwtToken();
          // @ts-ignore
          const args = {
            token,
            //@ts-ignore
            ...res.payload.accessToken.payload,
          };
        }
      });
    }

    if (showLoadingState) {
      // showLoadingState ||
      // set loader here
      return (
        <AppContainer>
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={24} />
          </div>
        </AppContainer>
      );
    }

    if (shallPass) {
      // !showLoadingState &&
      return (
        <>
          <WrappedComponent />
        </>
      );
    }
    // private route tips
    // forces redirect on undefined auth
    return (
      <ThemeProvider theme={newTheme}>
        <Fade in={true}>
          <AuthContainer>
            <AuthContainerBody>
              <AuthToastBox>
                <AppToast rounded={true} toastId={"password_retrieve"} fromComponent={"auth"} />
              </AuthToastBox>
              <AuthModalBox sx={{ elevation: 10 }}>
                <AuthModalBoxInner>
                  <Routes>
                    <Route path={appPages.auth.signin.path} element={<Signin />} />
                    <Route path={appPages.auth.retrieve_password.path} element={<RetrievePassword />} />
                    <Route path={appPages.auth.user_invitation.path} element={<RedirectInvite />} />
                    <Route path={appPages.auth.user_activation.path} element={<UserInvitation />} />
                    {/*legacy with password */}
                    <Route path={appPages.auth.user_invitation_full.path} element={<UserInvitation />} />
                    {/*{!authState.loggedIn &&  <Route path="*" element={<Navigate replace to={appPages.auth.signin.path}/>}/>}*/}
                    {!authState.loggedIn && <Route path="*" element={<Signin />} />}
                  </Routes>
                </AuthModalBoxInner>
              </AuthModalBox>
            </AuthContainerBody>
          </AuthContainer>
        </Fade>
      </ThemeProvider>
    );
  };

  return Component;
};

export const AuthToastBox = styled("div")(() => ({
  position: "relative",
  alignSelf: "center",
  width: "353px",
  background: "#ffffff",
  boxShadow: "0px 2px 11px rgba(191, 191, 191, 0.62)",
  borderRadius: "12px",
  marginBottom: "80px",
}));
