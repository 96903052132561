import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import { InputFieldLabel } from '../../../components/templates/InfoBlock';


interface IDataVaultChangePasswordProps {
  accessKeyId: any,
  secretAccessKey: any,
}

export const ApiAccessKeys: React.FC<IDataVaultChangePasswordProps> = ({
  accessKeyId,
  secretAccessKey,
}) => {

  const {t} = useTranslation();

  return (
    <Container>
      <InputFieldLabel required={true} htmlFor="apiAccessKey">
        {t('vaultSettingsPageLabels.apiAccessKey')}
      </InputFieldLabel>
      <TextFieldBlock>
        {accessKeyId}
        {/*{accessKeyId ?? 'Here will be Your Access Key'}*/}
      </TextFieldBlock>
      <InputFieldLabel required={true} htmlFor="apiSecretKey">
        {t('vaultSettingsPageLabels.apiSecretKey')}
      </InputFieldLabel>
      <TextFieldBlock>
        {secretAccessKey}
        {/*{secretAccessKey ?? 'Here will be Your secret key'}*/}
      </TextFieldBlock>
    </Container>
  );
};


const TextFieldBlock = styled.div`

`;

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  padding: 20px;
`;

