import _ from "lodash";
import { GridMiddle } from "components/ui/AppElements";
import { Box, Radio, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { jsonifyArray } from "pages/AudienceBuilder/audienceBuilderUtils";
import { optChkList } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { ISubFeaturesDto } from "application/entities/dataTypes/subFeatures";

export interface FeatureTypeCheckListProps {
  featureCode: string;
  subFeatureCode: string;
}

export interface FeatureTypeLinkedIdConfType {
  entity: string;
  label: string;
  hierarchyField: string;
  defaultField: string;
  idField: string;
}

export const InputCheckList = (props: FeatureTypeCheckListProps, ref: any) => {
  const { featureCode, subFeatureCode } = props;

  const audienceActions = useAudienceBuilderStore.getState().actions;

  const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
    return el.code === subFeatureCode;
  })[0];

  const feature = useAudienceBuilderStore.getState().getFeatureByCode(subFeatureCode);

  const { t } = useTranslation();

  const selectable: optChkList[] = filtersValues?.selectable ?? [];
  const selected: optChkList = filtersValues?.selected[0] as unknown as optChkList;

  const handleChange = (item: optChkList) => {
    audienceActions.insertSubFeatureValues(feature.subFeature as ISubFeaturesDto, jsonifyArray([item])[0]);
  };

  return (
    <div>
      {selectable?.map((select) => {
        return (
          <GridMiddle key={select.value} style={{ gap: "0" }}>
            <Box>
              <Radio
                size={"small"}
                checked={selected?.value === select.value}
                onChange={() => {
                  handleChange(select);
                }}
                id={select.value as string}
              />
            </Box>
            <label htmlFor={select.value} data-cy-value={selected?.value === select.value ? "checked" : ""}>
              <Typography>{t(`${select.text}` as any)}</Typography>
            </label>
          </GridMiddle>
        );
      })}
    </div>
  );
};
