import {Box, Button, Divider, MenuItem, Select, styled, useTheme} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {FieldZone, FormBlock, LabelZone, ShadowBox} from '../../components/templates/InfoBlock';
import {GlobalAvatar} from '../../components/ui/GlobalAvatar';
import {useUserQuery} from '../../components/hooks/data/useUserQuery';
import {ICompanyUsers} from '../../application/entities/dataTypes/companyUser';
import {Controller, useForm} from 'react-hook-form';
import {objectApi} from '../../application/entities/dataApi';
import {useAppToast} from '../../components/live/AppToast';
import {BackendError} from '../../types/backendError';
import moment from 'moment';
import {useOpenDialogWindow} from '../../components/live/DialogWindow';
import {useTranslation} from 'react-i18next';
import {UserBrandAssociationTable} from './shared/UserBrandAssociationsTable';
import {UserBrandAssociationsEdit} from './shared/UserBrandAssociationsEdit';
import {AUTHUser} from '../../application/utils/AuthUser';
import {ActionGranter} from '../../components/features/ActionGranter';
import {accountTypeName, EPermission, EUserGrade} from '../../application/utils/granter.utils';
import {queryClientRefetcher} from '../../application/utils/useQuery.utils';
import {apiService} from 'application/entities/api/apiService';
import {Link} from 'react-router-dom';
import {pageUtils} from 'application/pages/pages.utils';
import {PageId} from 'application/pages/pages.config';

interface IUserThumbViewProps {
    accountId: any;
    userId: any;
    onUserUpdate?: () => void;
    onClose?: () => void;
};


export const UserThumbView: FunctionComponent<IUserThumbViewProps> = (props: IUserThumbViewProps) => {

    const apiCompanyUsers = new objectApi.companyUsers();
    const userQuery = useUserQuery({userId: props.userId});

    const userData: ICompanyUsers = userQuery?.data ?? {};
    const appToast = useAppToast();
    const theme = useTheme();
    const windowDialog = useOpenDialogWindow();
    const [userEditMode, setUserEditMode] = React.useState<boolean>(false);
    const [permEditMode, setPermEditMode] = React.useState<boolean>(false);


    const {t} = useTranslation();

    const form = useForm({mode: 'all', defaultValues: {companyRole: userData.role}});


    const newDate = moment(userData.creationTime).format('D-MMM-YYYY');
    const accountType = userData?.account?.accountType;
    const roletitle = 'Account Type'; // accountType ? accountType.charAt(0).toUpperCase() + accountType.slice(1).toLocaleLowerCase() + ' Role' : 'Role';


    React.useEffect(() => {
        form.reset({
            companyRole: userData.role
        });
        setUserEditMode(false);
        setPermEditMode(false);
    }, [userData.id, userData.role]);

    function onSubmit(dta: any) {
        apiCompanyUsers.changeUserPermissions(props.accountId, props.userId, {companyRole: dta.companyRole})
            .then(() => {
                setUserEditMode(false);
                userQuery.refetch();


                //queryClientRefetcher(['companyUsers', 'accounts','account','user','brandAccountAssociations']);
                appToast.success({
                    message: 'The user was successfully updated',
                });
                if (props.onUserUpdate) props.onUserUpdate();
            }).catch((e: BackendError) => {
            appToast.error({
                message: e?.message ?? 'An error occurred',
            });
        });
    }

    const handleDeleteUser = () => {
        windowDialog.open({
            dialogId: 'nope',
            title: 'Confirm',
            description: t('message.user.deleteUserValidation'),
            buttonActions: {
                acceptButton: 'Remove',
                cancelButton: true
            },
            onAccept: (args: { companyUsersId: number }, oi: any) => {
                apiCompanyUsers
                    .delete(props.userId)
                    .then((res) => {
                        setUserEditMode(false);
                        queryClientRefetcher(['companyUsers', 'accounts', 'account', 'user', 'brandAccountAssociations']);

                        if (props.onUserUpdate) props.onUserUpdate();
                        if (props.onClose) props.onClose();
                        appToast.success({
                            message: 'The user was successfully removed',
                        });
                    })
                    .catch((e: BackendError) =>
                        appToast.error({
                            message: e?.message ?? 'An error occurred',
                        }));
            }
        });
    };

    const actions: Record<string, any> = {};
    actions.delete = {
        title: 'Delete',
        variant: 'outlined',
        action: handleDeleteUser,
        condition: (args: any) => (AUTHUser?.user.id !== args.id)
    };

    const editUserText = userEditMode ? 'Close' : 'Edit';
    const editAssocText = permEditMode ? 'Close' : 'Edit';

    // CMVP-1984 if user of a company and administrator : do not show edit permissions
    const editButton = (accountType === 'COMPANY' && userData.role === 'ADMINISTRATOR') ? null :
        <Button style={{width: '70px', minWidth: '70px', marginRight: '1rem'}}
                onClick={() => {
                    setPermEditMode(!permEditMode);
                }}
                variant={'outlined'}
                data-cy={permEditMode ? 'close-user-permissions' : 'edit-user-permissions'}>{editAssocText}</Button>;
    // CMVP-1984
    const adminMessage = (accountType === 'COMPANY' && ["ADMINISTRATOR", "ACCOUNT_SUPERADMIN"].includes(userData.role)) ? 'Company Administrators have Administrator permissions for all company brands' : undefined;

    // CMVP-1999
    const saveOnClose = () => {
        if (userEditMode) {
            //  if (form.formState.isDirty) {
            if (form?.formState?.touchedFields?.['companyRole']) {
                form.handleSubmit(onSubmit)();
                // queryClientRefetcher(['companyUsers', 'accounts','brandAccountAssociations']);
            } else {
                setUserEditMode(!userEditMode);
            }
        } else {
            setUserEditMode(!userEditMode);
        }
    };

    const resendPassword = () => {
        apiService.route('cognito/resendEmail').post({email: userData.email}).then(res => {
            appToast.success({
                message: 'The activation email was successfully sent',
            });
        }).catch(e => {
            appToast.error({
                message: e?.message ?? 'An error occurred',
            })
        });

    }
    const labelContent = [EUserGrade.CONTRIBUTOR, EUserGrade.ADMINISTRATOR, EUserGrade.COMPANYSUPERADMIN].includes(userData.role) ? accountTypeName[userData.role] : ''
    return <FormBlock style={{maxWidth: '700px'}} noBorder title={`User Details`}
                      buttons={(userData.status === 'NOT_ACTIVATED' || !(userData.status)) ? <div>
                          <Button variant={'outlined'} data-cy={'resend-activation-email'} onClick={resendPassword}>
                              Resend activation email
                          </Button>
                      </div> : null}
    >
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex-v" style={{height: '100%', padding: '10px'}}
              data-cy={'user-details'}>
            <ShadowBox>
                <div style={{backgroundColor: theme.palette.background.paper}}
                     className="flex-h flex-align-middle gap-small border-b pad">
                    <GlobalAvatar
                        user={userData}
                        isPending={userData.status !== 'ACTIVATED'}
                        size={'34px'}
                        fontSize={'18px'}
                    />
                    <div className={'flex-main'}>
                        {userData.firstName} {userData.lastName}
                        {AUTHUser.isEvorraAdmin() && <Link style={{paddingLeft: '8px'}}
                                                           to={pageUtils.getPagePathById(PageId.userEdit, {userId: userData.id})}><Button
                            variant='outlined' size='small'>Edit</Button></Link>}
                    </div>

                    <div
                        className={'pad-ii'}> {(userData.status === 'NOT_ACTIVATED') ? 'Inactive' : 'Active since ' + newDate}</div>
                </div>
                <div className={'pad pad-ii-2'}>
                    <div className={'flex-h gap-small'}>
                        <LabelZone title={'Email'} code={'email'} style={{overflow: 'hidden', maxWidth: '50%'}}
                                   className={'flex-main'} content={userData.email}/>
                        {!Boolean(userEditMode) &&
                            <LabelZone title={roletitle} code={'companyRole'} className={'flex-main'}
                                       content={labelContent}/>}
                        {Boolean(userEditMode) && <div className={'flex-v flex-align-middle gap-small flex-main'}>
                            <FieldZone title={roletitle} code={'companyRole'}>
                                <Controller
                                    name="companyRole"
                                    rules={{required: 'this is required'}}
                                    control={form.control}
                                    render={({field}) => (
                                        <Select {...field}
                                                value={field.value}
                                                sx={{width: '150px'}}
                                                data-cy={'user-role-selector'}>
                                            {accountType !== 'BRAND' && AUTHUser.isAccountSuperAdmin() &&
                                                <MenuItem data-cy={'superadministrator-role'}
                                                          value={EUserGrade.COMPANYSUPERADMIN}>{accountTypeName['ACCOUNT_SUPERADMIN']}</MenuItem>}
                                            <MenuItem data-cy={'administrator-role'}
                                                      value={EUserGrade.ADMINISTRATOR}>{accountTypeName['ADMINISTRATOR']}</MenuItem>

                                            {accountType === 'BRAND' ? <MenuItem data-cy={'creator-role'}
                                                                                 value={EUserGrade.CREATOR}>Creator</MenuItem> : null}

                                            <MenuItem data-cy={'viewer-role'}
                                                      value={'CONTRIBUTOR'}>{accountTypeName['CONTRIBUTOR']}</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FieldZone>
                        </div>}
                        <div style={{marginTop: '2rem'}}>
                            {(AUTHUser.user.id !== userData.id) && <Button style={{width: '70px', minWidth: '70px'}}
                                                                           onClick={() => {
                                                                               saveOnClose();
                                                                           }}
                                                                           variant={'outlined'}
                                                                           data-cy={userEditMode ? 'user-close-button' : 'user-edit-button'}>{editUserText}</Button>}
                        </div>
                    </div>
                    {Boolean(userEditMode) && <>
                        <Divider sx={{my: 1}}/>
                        <div className={'flex-h flex-align-middle gap-small'}>
                            {Boolean(userData.id !== AUTHUser.user.id) &&
                                <ActionGranter accesses={[{grade: EPermission.COMPANYADMIN}]}>
                                    <Button onClick={handleDeleteUser} color={'error'} variant={'outlined'}
                                            data-cy={'user-delete-button'}>
                                        Remove
                                    </Button>
                                </ActionGranter>}
                            <div className={'flex-main'}></div>
                            {/*<Button style={{width: '70px', minWidth: '70px'}} disabled={!isValid} type={'submit'} variant={'contained'} data-cy={'user-edit-save-button'}>
                 Save
                 </Button>*/}
                        </div>
                    </>}
                </div>
                {Boolean(adminMessage) && <div className={'pad-2'} data-cy="admin-permission-message">
                    {adminMessage}
                </div>}
                {(userData.account?.accountType === 'COMPANY' && !["ADMINISTRATOR", "ACCOUNT_SUPERADMIN"].includes(userData.role)) &&
                    <FormBlock noBorder
                               title={'Brand Permissions and Roles'}
                               description={adminMessage}
                               buttons={editButton}
                    >
                        <div className={'pad-ii'} style={{paddingBottom: '15px'}}>
                            {permEditMode ?
                                <UserBrandAssociationsEdit userId={userData.id} companyId={userData.account?.id}/> :
                                <UserBrandAssociationTable userId={userData.id}/>}
                        </div>
                    </FormBlock>}
            </ShadowBox>
        </form>
    </FormBlock>;
};

interface IUserThumbViewContainerProps {
};

const UserThumbViewContainer = styled(Box)<IUserThumbViewContainerProps>((props) => ({}));
