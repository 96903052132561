import {useAudienceBuilderStore} from "../../Store/AudienceBuilderStore";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {EventNote, ExpandMore} from "@mui/icons-material";
import {TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import moment from "moment/moment";
import {IFeaturesDto} from "../../../../application/entities/dataTypes/features";


export const InputDate = ({featureCode}: { featureCode: string }) => {

    const audienceActions = useAudienceBuilderStore.getState().actions;
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];

    const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode);

    const handleDateChange = (date: any) => {
        // MaterialUiPickersDate
        const newDate = moment(new Date(date)).format("YYYY-MM-DD");
        audienceActions.insertFeatureValues(feature.feature as IFeaturesDto, newDate);
    };

    let date = Boolean(filtersValues.selected[0]) ? new Date(filtersValues.selected[0]) : null;

    return <>
        <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{keyboardDate: "dd/mm/yyyy"}}>

            <div>
                <DatePicker
                    components={{
                        OpenPickerIcon: EventNote,
                        SwitchViewIcon: ExpandMore,
                    }}
                    value={date}
                    inputFormat={"dd/MM/yyyy"}
                    onChange={handleDateChange}
                    maxDate={new Date()}
                    showDaysOutsideCurrentMonth={true}
                    OpenPickerButtonProps={{edge: "start", size: "small", color: "primary"}}
                    renderInput={(params: any) => {
                        const par = {
                            ...params,
                            className: "dateTextField",
                            inputProps: {
                                ...params.inputProps,
                                className: "smallPadding",
                                style: {padding: "0.5rem 0.5rem 0.5rem 0.5rem"},
                            },
                        };
                        return <TextField className="dateTextField" data-cy={"date" + featureCode} {...par} />;
                    }}
                />
            </div>
        </LocalizationProvider>
    </>
}