import {EPermission, EUserAccountType, EUserRole, EUserSelf, IAccessOptions} from '../utils/granter.utils';

type AccessListType = Record<string, Record<string, IAccessOptions | any>>
/** list scoped around entities
 * should be the one to be modified
 * all rules are "allow" rules
 * */
export const accessList: AccessListType = {
    cockpit: {
        report: [{grade: EPermission.EVADMIN}],
    },
    admin: {
        evorraDashboard: [{grade: EPermission.EVADMIN}],
    },
    dashboard: {
        dashboard: [{grade: EPermission.EVADMIN, role: [EUserRole.DATA_PROVIDER, EUserRole.DATA_CLIENT]}],
    },
    accounts: {
        list: [{grade: EPermission.EVADMIN}],
        adminView: [{grade: EPermission.EVADMIN}],
        delete: [], // I simply removed this button to avoid mistakes, delete will be done offline
        create: [{grade: EPermission.EVADMIN}],
        suspend: [{grade: EPermission.EVADMIN}],
        download: [{grade: EPermission.EVADMIN}],
        edit: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN, self: EUserSelf.accountId}],
        billings: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN, self: EUserSelf.accountId}],
        subscriptions: [{grade: EPermission.EVADMIN}, {
            grade: EPermission.COMPANYADMIN,
            role: EUserRole.DATA_CLIENT,
            self: EUserSelf.accountId
        }],

        view: [{grade: [EPermission.COMPANYADMIN], self: EUserSelf.accountId}],
    },
    dataVault: {
        permissions: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_PROVIDER,
            grade: EPermission.COMPANYADMIN,
            self: EUserSelf.accountId
        }],
        setup: [{grade: EPermission.EVADMIN}], // CMVP-1589 , removed {role: EUserRole.DATA_PROVIDER, grade: EUserGrade.ADMINISTRATOR, self: EUserSelf.accountId}
        metrics: [{role: EUserRole.DATA_PROVIDER, grade: EPermission.COMPANYADMIN}],
    },
    companyUsers: {
        list: [{grade: EPermission.EVADMIN}],
        create: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN, self: EUserSelf.accountId}],
        edit: [{grade: EPermission.EVADMIN}, {self: EUserSelf.userId}],
        delete: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN}],
        view: [{grade: EPermission.EVADMIN}, {self: EUserSelf.userId}],
        download: [{grade: EPermission.EVADMIN}],
    },
    audiences: {
        library: [{grade: EPermission.EVADMIN}, {role: EUserRole.DATA_CLIENT, self: EUserSelf.accountId}],
        builder: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        create: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        edit: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        copy: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        archive: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        trash: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN, EPermission.BRANDCREATOR],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        approval: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.BRANDADMIN],
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        view: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            self: EUserSelf.accountId,
            brandLevel: true
        }],
        adminView: [{grade: EPermission.EVADMIN}],
        setDealId: [{grade: EPermission.EVADMIN}],
    },
    activation: {
        setup: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.COMPANYADMIN],
            self: EUserSelf.accountId
        }, {role: EUserRole.DATA_CLIENT, grade: [EPermission.BRANDADMIN], self: EUserSelf.accountId, brandLevel: true}],
    },
    prePackagedList: {
        upload: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.COMPANYADMIN],
            self: EUserSelf.accountId
        }, {role: EUserRole.DATA_CLIENT, grade: [EPermission.BRANDADMIN], self: EUserSelf.accountId, brandLevel: true}],
        delete: [{grade: EPermission.EVADMIN}, {
            role: EUserRole.DATA_CLIENT,
            grade: [EPermission.COMPANYADMIN],
            self: EUserSelf.accountId
        }, {role: EUserRole.DATA_CLIENT, grade: [EPermission.BRANDADMIN], self: EUserSelf.accountId, brandLevel: true}],
    },
    companies: {
        view: [{grade: EPermission.EVADMIN}, {grade: '*', self: EUserSelf.accountId}],
        create: [{grade: EPermission.EVADMIN}],
        edit: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN, self: EUserSelf.accountId}],
        delete: [{grade: EPermission.EVADMIN}],
        list: [{grade: EPermission.EVADMIN}],
        dashboard: [{grade: '*', self: EUserSelf.accountId}],
        listUsers: [{grade: EPermission.EVADMIN}, {grade: [EPermission.COMPANYADMIN], self: EUserSelf.accountId}],
        listBrands: [{grade: EPermission.EVADMIN}, {grade: [EPermission.COMPANYADMIN], self: EUserSelf.accountId}],

    },
    brands: {
        view: [{grade: '*', role: EUserRole.DATA_CLIENT}],
        create: [{grade: EPermission.EVADMIN}, {
            grade: EPermission.COMPANYADMIN,
            userAccountType: EUserAccountType.COMPANY
        }],
        edit: [{grade: EPermission.EVADMIN}, {
            grade: EPermission.COMPANYADMIN,
            userAccountType: EUserAccountType.COMPANY
        }],
        delete: [{grade: EPermission.EVADMIN}],
        list: [{grade: EPermission.EVADMIN}],
        dashboard: [{grade: '*', role: EUserRole.DATA_CLIENT}],
        userManagement: [
            {grade: EPermission.EVADMIN},
            {
                grade: EPermission.COMPANYADMIN,
                userAccountType: EUserAccountType.COMPANY
            },
            {grade: EPermission.BRANDADMIN, brandLevel: true}],
        listUsers: [{grade: EPermission.EVADMIN}, {grade: EPermission.COMPANYADMIN, role: EUserRole.DATA_CLIENT}],
        superAdminSettings: [{grade: EPermission.EVADMIN}, {
            grade: EPermission.COMPANYSUPERADMIN,
            self: EUserSelf.accountId
        }]
    },
}//  satisfies  AccessListType;

