import React from 'react';

import { objectApi } from '../../../../application/entities/dataApi';
import { ColumnsMiddle, Elem, GridItem, GridItemFull, H6 } from '../../../../components/ui/AppElements';
import { AppIcon } from '../../../../components/ui/AppIcon';
import { AppSwitch } from '../../../../components/form/AppSwitch';
import { useAppToast } from '../../../../components/live/AppToast';
import { useOpenDialogWindow } from '../../../../components/live/DialogWindow';
import { DataVaultRuleForm } from '../DataVaultRuleForm';
import { IRuleState, RuleLabel, VaultLine, VaultLineHeader } from '../DataVaultPermissions';
import { useTranslation } from 'react-i18next';
import { useAdminPage } from 'components/hooks/useAdminPage';


export interface IDataVaultLineProps {
  rule: IRuleState;
  active?: boolean;
  open?: boolean;
  onClick?: Function;
  dataReload?: Function;
  dataError?: Function;
}

export const DataVaultRule = (props: IDataVaultLineProps) => {

  const { rule, active, dataReload, dataError } = props;

  const {t}     = useTranslation();
  const [featureRule, setFeatureRule] = React.useState<IRuleState>({} as IRuleState);
  const [ruleLineOpen, setRuleLineOpen] = React.useState<boolean>(false);
  const appToast = useAppToast();
  const dialogWindow = useOpenDialogWindow();
  useAdminPage()
  const rulesApi = new objectApi.rules();

  // configuration object for application dialog
  const dialogConf = {
    dialogId: 'ruleDelete',
    buttonActions: { acceptButton: true, cancelButton: true },
    title: 'Delete this rule ?',
    description: 'deleting this rule can not be undone',
    onAccept: onDeleteAccept,
  };

  React.useEffect(() => {
    setFeatureRule(() => ({
      ...rule,
      account: `/account/${rule.accountId}`,
    }));
    props?.open && setRuleLineOpen(props?.open);
  }, [rule]);

  const handleMenuClick = () => {
    setRuleLineOpen(!ruleLineOpen);
  };

  const handleClick = () => {
    if (props?.onClick) {
      props.onClick();
    }
  };

  async function onDeleteAccept() {
    try {
      await rulesApi.delete(featureRule.id);

      dataReload && dataReload();
      appToast.open({
        toastId: 'globalToast',
        message: t('dataVault.ruleDeleted'),
        severity: 'success',
      });
    }
    catch (error) {
      appToast.open({
        toastId: 'globalToast',
        message: t('labels.error'),
        severity: 'error',
      });
    }
  }

  const onDelete = () => {
    dialogWindow.open(dialogConf);
  };

  const onValidate = async () => {
    // update rule
    try {
      await rulesApi.update(featureRule.id, featureRule);
      appToast.open({
        toastId: 'globalToast',
        message: t('labels.success'),
        severity: 'success',
      });
    }
    catch (error) {
      dataError && dataError(error);
    }

    await reloadRule();

    dataReload && dataReload();
  };

  const reloadRule = async () => {
    const data = await rulesApi.byId(featureRule.id);
    setFeatureRule(() => data);

    return data;
  };

  const onSwitchAction = (event: any, checked: boolean) => {
    const p: any = {
      'accountId': featureRule.accountId,
      'isActivated': Boolean(checked),
    };
    rulesApi.update(featureRule.id, p)
      .then(() => {
        dataReload && dataReload();
      })
      .catch((error: any) => {
        dataError && dataError(error);
      });
  };
  return (
    <VaultLine
      data-cy={'rule-item'}
      data-cy-id={featureRule?.id}
      margin={[0, 1]}
      active={active}
      onClick={handleClick}
      onDoubleClick={handleMenuClick}
    >
      <VaultLineHeader>
        <ColumnsMiddle padding={[0.25]}>
          <GridItemFull>
            <H6>Rule #{featureRule.id} {featureRule.name}</H6>
          </GridItemFull>
          <GridItem>
            <Elem padding={[0, 1]}>
              <RuleLabel>Created</RuleLabel>{featureRule.creationTime}
            </Elem>
          </GridItem>
          <GridItem>
            <Elem padding={[0, 1]}  data-cy={'activate-switch'}>
              <AppSwitch
                onChange={onSwitchAction} label={<RuleLabel>Activated</RuleLabel>}
                value={featureRule?.isActivated}
                checked={Boolean(featureRule?.isActivated)}
              />
            </Elem>
          </GridItem>
          <GridItem data-cy={'display-more-icon'}>
            <AppIcon
              onClick={handleMenuClick} icon={'ChevronDownIcon'} fontSize={'small'}
              rotate={ruleLineOpen ? 180 : 0}
            />
          </GridItem>
        </ColumnsMiddle>
      </VaultLineHeader>
      {ruleLineOpen &&
      <DataVaultRuleForm
        onDelete={onDelete}
        onValidate={onValidate}
        mode={'update'}
        setFeatureRule={setFeatureRule}
        featureRule={featureRule}
      />}
    </VaultLine>
  );
};