import {Box, Divider, styled, Typography} from '@mui/material';
import {H5} from '../../../components/ui/AppElements';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {useTranslation} from 'react-i18next';
import {AccountUsersList} from '../AccountUsersList';

import { Link } from 'react-router-dom';
import { PageId } from 'application/pages/pages.config';
import { pageUtils } from 'application/pages/pages.utils';
import { ICompanyUsers } from 'types';

interface IAccountCardProps {
  account: IAccounts
}

export const AccountCard = (props: IAccountCardProps) => {
  
  const {account} = props;
  const {t}              = useTranslation();
  const users = account?.companyUsers ?? [];

  let brandUsers: ICompanyUsers[] = []
  if(account.agentBrandAccountAssociations){
    account.agentBrandAccountAssociations.map((brand) => {
      return brandUsers.push(brand.user)
    })
  }
 
  
  return <Box sx={{width: '350px'}}>
    <Box sx={{p: 2}}>
      <H5> {t('account.generalTitles.info')}</H5>
      <Row>
        <CellTitle> {t('account.accountDetailsLabels.accountType')} </CellTitle>
        <Cell>
          <Typography>{account.dataClient === true ? 'Data client' : 'Data provider'} {account.accountType ==='BRAND' && '(brand)'}</Typography>
        </Cell>
      </Row>
      {account?.accountType === 'BRAND' && 
      <Row>
        <CellTitle> {t('account.accountDetailsLabels.parentCompany')} </CellTitle>
        <Cell>
        <Link style={{textDecoration:'none'}} to={pageUtils.getPagePathById(PageId.accountView, {accountId: account.parentCompanyId})}>#{account.parentCompanyId} {account?.parentAccountAssociations[0]?.parentAccount?.companyName}</Link>
        </Cell>
      </Row>
      }
      <Row>
        <CellTitle> {t('account.accountDetailsLabels.companyName')} </CellTitle>
        <Cell>
          <Typography>{account.companyName}</Typography>
        </Cell>
      </Row>
      <Row>
        <CellTitle> {t('account.accountDetailsLabels.companySize')} </CellTitle>
        <Cell>
          <Typography>{account.companySize}</Typography>
        </Cell>
      </Row>
     
      <Row>
        <CellTitle> {t('account.accountAddressLabels.companyStreetAddress')} </CellTitle>
        <Cell>
          {account.streetAddress}
        </Cell>
      </Row>
      <Row>
        <CellTitle> {t('account.accountAddressLabels.postalCode')} </CellTitle>
        <Cell>
          {account.postalCode}
        </Cell>
      </Row>
      <Row>
        <CellTitle> {t('account.accountAddressLabels.state')} </CellTitle>
        <Cell> 
          {account.state}
        </Cell>
      </Row>
      <Row>
        <CellTitle> {t('account.accountAddressLabels.country')} </CellTitle>
        <Cell>  
          {account.country}  
        </Cell>
      </Row>
      <Row>
        <CellTitle> {t('account.accountDetailsLabels.companyUrl')} </CellTitle>
        <Cell>
          <UrlsList>
            {account.companyUrlList?.map((url, index) => {
              return <Typography key={index}>{url}</Typography>
            })}
            </UrlsList>
          
        </Cell>
      </Row>
    </Box>
    {(account?.accountType === 'COMPANY' ) && <>
      <Divider/>
      <Box sx={{p: 2}}>
        <H5> {t('account.generalTitles.brandList')}</H5>
        <div style={{padding:'8px'}}>
        {account.brandAccountAssociations.length === 0 && <Typography>No brand </Typography>}
        {account.brandAccountAssociations.map((brand, index) => {
          return <div key={brand.subAccount.id} style={{paddingBlock:'2px'}}>
            <Link style={{textDecoration:'none'}} to={pageUtils.getPagePathById(PageId.adminAccountView, {accountId: brand.subAccount.id})} key={index}>#{brand.subAccount.id} - {brand.subAccount.companyName}</Link>
            </div>
        })}
        </div>
      </Box>
      </>
      }
    {(account?.accountType === 'COMPANY' || account?.dataProvider === true) && <>
      <Divider/>
      <Box sx={{p: 2}}>
        <H5> {t('account.generalTitles.users')}</H5>
        {users.length === 0 && <Typography>No users </Typography>}
        <AccountUsersList companyUsers={users}/>
      </Box>
    </>
    }
    {(account?.accountType === 'BRAND') && <>
      <Divider/>
      <Box sx={{p: 2}}>
        <H5> {t('account.generalTitles.users')} in brand</H5>
        {brandUsers.length === 0 && <Typography>No users </Typography>}
        <AccountUsersList companyUsers={brandUsers}/>
      </Box>
    </>
    }
  </Box>
}

const UrlsList = styled(Box)(({theme}) => ({
  maxHeight  : '60px',
  width   : '100%',
  overflow: 'auto',
}))

const Row = styled(Box)(props => ({
  display  : 'flex',
  minHeight: '24px',
  minWidth : '0'
}))

const CellTitle = styled(Box)(({theme}) => ({
  padding: theme.spacing(1),
  color  : '#7587A3',
  width  : '110px'
}))

const Cell = styled(Box)(({theme}) => ({
  padding       : theme.spacing(1),
  flex          : '1',
  textAlign     : 'left',
  display       : 'flex',
  alignContent  : 'start',
  justifyContent: 'left',
  justifyItems  : 'left',
  wordBreak     : 'break-word',
  color         : theme.palette.text.primary
}))