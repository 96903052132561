import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import React from "react";
import {motion} from "motion/react";

export const SelectedSegments = () => {

    const selectedSegments = useSegmentBuilderStore(st => st.selectedSegments)
    const searchDone = useSegmentBuilderStore(st => st.searchDone)
    const displaySegmentSelected = useSegmentBuilderStore(st => st.displaySegmentSelected)

    if (searchDone && selectedSegments.length === 0 && displaySegmentSelected) {
        useSegmentBuilderStore.setState({displaySegmentSelected: false})
    }
    return <>
        {(searchDone && selectedSegments.length > 0) && (
            <Box sx={{

                display: "flex",
                alignItems: "center",
                gap: 0.5,

            }}>
                <Typography variant="h4" fontSize="16px" sx={{}}>
                    <motion.span key={selectedSegments.length}
                                 style={{
                                     background: "#00B5E2",
                                     color: "#fff",
                                     marginRight: '0.5rem',
                                     width: 25,
                                     height: 25,
                                     borderRadius: '50%',
                                     textAlign: 'center',
                                     lineHeight: '25px',
                                     fontSize: '14px',
                                     display: 'inline-flexflex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                 }}
                                 initial={{
                                     scale: 1, display: 'inline-flex'

                                 }}
                                 animate={{
                                     scale: [1, 2.5, 0.5, 1],
                                 }}
                                 transition={{
                                     duration: 0.3,
                                     times: [0, 0.3, 0.6, 1],
                                     ease: "easeInOut"
                                 }}>{selectedSegments.length}</motion.span>
                    {selectedSegments.length > 1 ? 'Segments' : 'Segment'} selected
                </Typography>
                <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>

                    <Button variant="text" size="small"

                            onClick={() => useSegmentBuilderStore.setState({displaySegmentSelected: !displaySegmentSelected})}>
                        {displaySegmentSelected ? 'Back to search' : 'Show'}
                    </Button>

                </Box>


            </Box>
        )}

    </>
}