import {useCrudOpAction, useCrudOpSlice} from '../../../redux/slices/crudmsg.slices';
import React from 'react';
import apiRequest from '../../../application/entities/api/interceptors';
import {IApiMetaData} from '../../../application/entities/api/apiService';
import {dataUtils} from '../../../application/utils/dataState.utils';
import {Box,  LinearProgress} from '@mui/material';

export const CrudHeader = () => {
  const crudOp        = useCrudOpSlice();
  const crudOpActions = useCrudOpAction();

  const allOp = [...listOp('post'), ...listOp('patch'), ...listOp('delete')]

  const isRunning: number = allOp.filter((op: IApiMetaData) => op.progress === 'RUNNING').length;

  React.useEffect(() => {
    apiListening();
  }, []);

  React.useEffect(() => {
    // console.log(crudOp);
  }, [crudOp]);

  function apiListening() {
    apiRequest.interceptors.request.use(async function (config) {
      let dataId = config?.metadata?.queryId;
      // add to queue, with auto-delete as a feature
      if (dataId && config?.metadata) crudOpActions.addCrudOp(config.metadata)
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    apiRequest.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data ===> STATUS !!!
      // console.log(response?.config)
      try {
        let data = response?.config?.metadata;
        if (data && data.queryId) {
          // use timeout to delete
          crudOpActions.addCrudOp({...data, progress: 'STOPPED'})
          window.setTimeout(() => {crudOpActions.deleteCrudOp(data) }, 200);
        }

      } catch (e) {

      }
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
  }

  function listOp(operation: string): IApiMetaData[] {
    return dataUtils.getObjectByFieldValue(Object.values(crudOp), 'operation', operation);
  }

  return <>
  {Boolean(isRunning > 0) &&  <Box style={{ position: 'fixed', top: 0, width: '100%' }} data-cy={isRunning > 0  ? 'api-loading' : 'api-end-loading'}>
          <LinearProgress  />
        </Box>
  }
  </>
}