import {Box} from "@mui/material";
import {styled} from "@mui/styles";
import {GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import {AxiosResponse} from "axios";
import {GridTable} from "components/organisms/GridTable/GridTable";
import {Elem, GridItemFull, StackerFullHeight} from "components/ui/AppElements";
import _ from "lodash";
import {CopyToBrandPopup} from "pages/Audiences/CopyToBrandPopup";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import {profilesFormatter} from "../../../_configuration/formaters";
import {actions} from "../../../application/actions/actions";
import {objectApi} from "../../../application/entities/dataApi";
import {audiencesColumns} from "../../../application/entities/dataColumns/audiences.columns";
import {IAudiences} from "../../../application/entities/dataTypes/audiences";
import {PageId} from "../../../application/pages/pages.config";
import {pageUtils} from "../../../application/pages/pages.utils";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {dataColumnModelUtils, TDataColumnModel} from "../../../application/utils/dataColumnModel.utils";
import {ActionMenu} from "../../../components/controls/ActionMenu";
import {useAudiencesListQuery} from "../../../components/hooks/data/useAudiencesQuery";
import {ApiProgress} from "../../../components/live/ApiProgress";
import {useOpenDialogWindow} from "../../../components/live/DialogWindow";
import {AppStepper} from "../../../components/ui/AppStepper";
import {AppTabs, TAppTabArgs} from "../../../components/ui/Tabs";
import {IOpenDialogAction} from "../../../redux/slices/dialog.slices";
import {useFeatureFlag} from "../../../stores/featureFlags.store";

export const AudiencesListWidget = (props: { accountId?: number }) => {
    const accountId = props?.accountId ?? AUTHUser.accountId;

    const history = useNavigate();
    const {t} = useTranslation();

    const featureFlags = useFeatureFlag((st) => st.featureFlags);


    const defaultAudienceStatus = "All";

    let [pageSizeState, setPageSizeState] = useLocalStorageState<number>("wid-aud-pageSizeState", 50);
    let [recordSizeState, setRecordPageSizeState] = useLocalStorageState<number>("wid-aud-recordSizeState", 50);
    let [pageNumber, setPageNumber] = useLocalStorageState<number>("wid-aud-pageNumber", 0);
    let [sortDir, setSortDir] = React.useState<"asc" | "desc">("desc");
    let [sortBy, setSortBy] = React.useState<"creationTime" | "name" | string>("modificationTime");

    const [openDrawer, setOpenDrawer] = useLocalStorageState<boolean>("aud-drawer", false);
    const [activeAccount, setActiveAccount] = useLocalStorageState<IAudiences>("aud-activeAccount", {} as IAudiences);

    let [audiencesCount, setAudiencesCount] = useLocalStorageState<{
        archived: number;
        draft: number;
        activated: number;
        pendingApproval: number;
        approved: number;
    }>("aud-audiencesCount", {} as any);
    let [audienceStatus, setAudienceStatus] = useLocalStorageState<
        "Activated" | "Draft" | "Archived" | "All" | "Pending_approval" | "Approved" | string
    >("aud-audienceStatus", defaultAudienceStatus);

    const {isLoading, error, data, refetch} = useAudiencesListQuery({
        accountId,
        pageSizeState,
        audienceStatus,
        pageNumber,
        sortDir,
        sortBy,
    });
    let [tabActiveIndex, setTabActiveIndex] = React.useState<number | undefined>(0);

    // @ts-ignore
    const audData = data?.data?.detailedResults ?? [];
    const flatData: IAudiences[] = audData.map((fl: any) => fl?.result?.content).flat() ?? [];

    const columns = _.cloneDeep(audiencesColumns());

    const dialogWindow = useOpenDialogWindow();

    const audiencesApi = new objectApi.audiences();
    const audiencesApiCount = new objectApi.audiences();
    const accountsApi = new objectApi.accounts();

    let cols: TDataColumnModel = {
        creator: columns.creator,
        name: columns.name,
        lastAudienceSize: columns.lastAudienceSize,
        CPM: columns.cpm,
        actions: columns.actions,
    };

    if (audienceStatus === "Pending_approval") {
        cols = {
            requester: columns.requester,
            requestApprovalTime: columns.requestApprovalTime,
            name: {headerAlign: "center", align: "center", ...columns.name},
            approverAsked: {headerAlign: "center", align: "center", ...columns.approverAsked},
            //price is not displayed cause info not there in search but only in searchfull
            actions: columns.actions,
        };
    }
    if (audienceStatus === "Approved") {
        cols = {
            requester: columns.requester,
            name: {headerAlign: "center", align: "center", ...columns.name},
            approver: {headerAlign: "center", align: "center", ...columns.approver},
            approvalTime: columns.approvalTime,
            //price: {headerAlign:'center', align:'center', ...columns.price}, not displayed cause info not there in search but only in searchfull
            actions: columns.actions,
        };
    }

    React.useEffect(() => {
        if (accountId) {
            fetchAudiencesCount();
        }
    }, [accountId, pageSizeState, pageNumber, audienceStatus, sortBy, sortDir]);

    React.useEffect(() => {
        if (data?.data?.detailedResults) updatePaginationState(data?.data?.detailedResults);
    }, [data?.data?.detailedResults]);

    React.useEffect(() => {
        const tab = tabs.find((t) => t.value === audienceStatus);
        if (tab) setTabActiveIndex(tabs.indexOf(tab));
    }, [tabActiveIndex]);

    const dialogArchive: IOpenDialogAction = {
        dialogId: "audienceArchive",
        buttonActions: {acceptButton: true, cancelButton: true},
        title: t("audience.archiveAudience"),
        description: t("audience.archiveAudienceDescription"),
        icon: "Trash",
        onAccept: onArchiveAccept,
    };

    const dialogDelete: IOpenDialogAction = {
        dialogId: "audienceDelete",
        buttonActions: {acceptButton: true, cancelButton: true},
        title: t("audience.deleteAudience"),
        description: t("audience.deleteAudienceDescription"),
        icon: "Trash",
        onAccept: onDeleteAccept,
    };

    const dialogDuplicate: IOpenDialogAction = {
        dialogId: "audienceDuplicate",
        buttonActions: {acceptButton: true, cancelButton: true},
        title: t("audience.duplicateAudience"),
        description: t("audience.duplicateAudienceDescription"),
        icon: "Duplicate",
        onAccept: onDuplicateAccept,
    };

    const dialogCancel: IOpenDialogAction = {
        dialogId: "audienceCancel",
        buttonActions: {acceptButton: t("audience.cancelRequest"), cancelButton: true},
        title: t("audience.cancelRequest"),
        description: t("audience.cancelRequestDescription"),
        icon: "Close",
        onAccept: onCancelRequest,
    };

    const actionButtons = {...actions.audience};

    actionButtons.archive.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            ...dialogArchive,
            dialogArgs: {id: audienceData.id, data: audienceData},
        });
    };
    actionButtons.copy.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            ...dialogDuplicate,
            dialogArgs: {id: audienceData.id, data: audienceData},
        });
    };
    actionButtons.copyToBrand.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            dialogId: "copyToBrand",
            component: CopyToBrandPopup,
            componentProps: {
                audience: audienceData,
            },
        });
    };
    actionButtons.trash.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            ...dialogDelete,
            dialogArgs: {id: audienceData.id, data: audienceData},
        });
    };
    actionButtons.pay.action = (audienceData: IAudiences) => {
        history(pageUtils.getPagePathById(PageId.audienceActivation, {audienceId: audienceData.id}));
    };

    actionButtons.cancel.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogCancel, dialogArgs: {id: audienceData.id, data: audienceData}});
    };


    cols.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div>
                <ActionMenu actions={actionButtons} vars={{...cell.row, audienceId: cell.row.id}}/>
            </div>
        );
    };
    cols.name.valueGetter = (params: any) => {
        if (params.row.audienceStatus === "PENDING_APPROVAL") {
            return params.row.name;
        } else if (params.row.audienceStatus === "APPROVED") {
            return (
                <Link
                    to={pageUtils.getPagePathById(PageId.audienceActivation, {audienceId: params.id})}
                    style={{cursor: "pointer"}}
                    title={params.row.name}
                >
                    {params.row.name}
                </Link>
            );
        } else if (["ACTIVATED", "ARCHIVED"].includes(params.row.audienceStatus)) {
            return (
                <Link to={pageUtils.getPagePathById(PageId.audienceView, {audienceId: params.id})}
                      style={{cursor: "pointer"}} title={params.row.name}>
                    {params.row.name}
                </Link>
            );
        } else {
            return (
                <Link to={getAudiencePath(params)} style={{cursor: "pointer"}} title={params.row.name}>
                    {params.row.name}
                </Link>
            );
        }
    };

    if (cols?.lastAudienceSize) {
        cols.lastAudienceSize.valueGetter = (params: any) => {
            return params.row.lastAudienceSize === -1 || params.row.lastAudienceSize === undefined
                ? "< " + t("audience.minimum")
                : `${profilesFormatter.format(params.row.lastAudienceSize)} profiles`;
        };
    }


    const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

    function updatePaginationState(audiencePage: any[]) {
        const totalElements = audiencePage
            .map((re) => {
                return re?.result?.totalElements;
            })
            .reduce((block, part) => block + part, 0); // add them

        setRecordPageSizeState(() => totalElements);
    }

    const fetchAudiencesCount = () => {
        if (accountId) {
            accountsApi
                .GetAudienceStatusCount(accountId)
                .then((res: AxiosResponse<any> | void) => {
                    if (res?.data) setAudiencesCount(res?.data);
                })
                .catch((error) => {
                });
        }
    };

    const handleRowTableClick = (param: GridRowParams<IAudiences>) => {
        onSelectAudience(param.row);
    };

    const onSelectAudience = (audience: IAudiences) => {
        if (audience.id === activeAccount?.id) {
            setOpenDrawer(!openDrawer);
        } else {
            setOpenDrawer(true);
        }
        setActiveAccount(audience);
    };

    const getAudiencePath = (params: GridRowParams<IAudiences>) => {
        return pageUtils.getPagePathById("audience-builder-update", {
            id: params.id,
        });
    };

    const handleEditAudience = (params: GridRowParams<IAudiences>) => {
        if (params.id && params.row.audienceStatus !== "PENDING_APPROVAL")
            history(pageUtils.getPagePathById("audience-builder-update", {id: params.id}));
    };

    const onTabClick = (args: TAppTabArgs) => {
        if (args.value) {
            setPageNumber(0);
            setAudienceStatus(args.value);
        }
    };

    async function onDeleteAccept(args: any, orgs: any) {
        try {
            await audiencesApi.delete(orgs.id);
            setActiveAccount({} as IAudiences);
            await refetch();
            fetchAudiencesCount();
        } catch (error) {
        }
    }

    async function onArchiveAccept(args: any, orgs: any) {
        try {
            // audiencesApi
            await audiencesApi.archiveAudience(orgs.id);
            await refetch();
            fetchAudiencesCount();
        } catch (error) {
        }
    }

    function onDuplicateAccept(args: any, dta: { id: number; data: Partial<IAudiences> }) {
        try {
            audiencesApi
                .duplicateAudience(dta.data)
                .then((res) => {
                    if (res && res.id) {
                        history(
                            pageUtils.getPagePathById("audience-builder-update", {
                                id: res.id,
                            })
                        );
                    }
                })
                .catch();
        } catch (error) {
        }
    }

    async function onCancelRequest(args: any, dta: { id: number; data: Partial<IAudiences> }) {
        try {
            await audiencesApi.cancelRequestApproval(dta.id);
            await refetch();
            fetchAudiencesCount();
        } catch (error) {
        }
    }

    const handlePageChange = (size: number) => {
        setPageNumber(size);
    };

    const onOpenAudienceLibrary = () => {
        // history(pageUtils.getPagePathById('audience-library'));
    };
    const total =
        (audiencesCount.pendingApproval ?? 0) +
        (audiencesCount.approved ?? 0) +
        (audiencesCount.archived ?? 0) +
        (audiencesCount.draft ?? 0) +
        (audiencesCount.activated ?? 0);

    const totalPages = Math.ceil(recordSizeState / pageSizeState);
    const tabs = [
        {
            order: 1,
            id: 1,
            text: `${t("audience.statusDraft")} (${audiencesCount.draft ?? 0})`,
            value: `Draft`,
            dataLabel: "Draft",
            dataValue: audiencesCount.draft,
        },
        {
            order: 2,
            id: 5,
            text: `${t("audience.pendingApproval")} (${audiencesCount.pendingApproval})`,
            value: `Pending_approval`,
            dataLabel: "Pending_approval",
            dataValue: audiencesCount.pendingApproval,
        },
        {
            order: 3,
            id: 6,
            text: `${t("audience.approved")} (${audiencesCount.approved})`,
            value: `Approved`,
            dataLabel: "Approved",
            dataValue: audiencesCount.approved,
        },
        {
            order: 4,
            id: 2,
            text: `${t("audience.statusActivated")} (${audiencesCount.activated ?? 0})`,
            value: `Activated`,
            dataLabel: "Activated",
            dataValue: audiencesCount.activated ?? 0,
        },
        {
            order: 5,
            id: 3,
            text: `${t("audience.statusArchived")} (${audiencesCount.archived ?? 0})`,
            value: `Archived`,
            dataLabel: "Archived",
            dataValue: audiencesCount.archived ?? 0,
        },
        {
            order: 6,
            id: 4,
            text: `${t("audience.statusAll")} (${total})`,
            value: `All`,
            dataLabel: "All",
            dataValue: total
        },
    ];
    tabs.sort((a, b) => a.order - b.order);

    if (audiencesCount.pendingApproval === 0 && audiencesCount.approved === 0) {
        tabs.splice(1, 2);
    }

    return (
        <StackerFullHeight style={{position: "relative", flex: 1}}>
            <Elem padding={[0, 0]}>
                <AppTabs activeTab={tabActiveIndex} tabList={tabs} onClick={onTabClick}>
                    <Link
                        style={{textDecoration: "none", fontWeight: "bold"}}
                        to={pageUtils.getPagePathById(PageId.audienceLibrary, {
                            accountId: accountId,
                        })}
                        onClick={onOpenAudienceLibrary}
                    >
                        {" "}
                        {t("audience.seeAllAudiences")}{" "}
                    </Link>
                </AppTabs>
            </Elem>
            <GridItemFull style={{overflow: "hidden", position: "relative"}}
                          data-cy={isLoading ? "audience-list-loading" : "audience-list-loaded"}>
                <Box
                    sx={{
                        position: "absolute",
                        width: "100%",
                        padding: "1px",
                        zIndex: 10,
                    }}
                >
                    <ApiProgress entityName={"audiences"}/>
                </Box>
                <GridTable
                    noRowsText={isLoading ? "" : t("audience.buildFirstAudience")}
                    styleClass={"naked"}
                    loading={isLoading}
                    data={{
                        columns: audienceColumns as any,
                        rows: flatData,
                    }}
                    gridProps={{
                        onRowClick: (param: GridRowParams<IAudiences>) => handleRowTableClick(param),
                        onRowDoubleClick: (param: GridRowParams<IAudiences>) => handleEditAudience(param),
                        disableSelectionOnClick: true,
                        autoHeight: false,
                        hideFooter: true,
                        columnBuffer: 30,
                        columnThreshold: 10,
                    }}
                />
            </GridItemFull>
            <Box sx={{p: 1}}>
                <AppStepper steps={totalPages} activeStep={pageNumber} onStepClick={handlePageChange}/>
            </Box>
        </StackerFullHeight>
    );
};

const GridContainer = styled("div")(() => ({
    marginTop: "2rem",
    display: "grid",
    width: "100%",
    gap: "2rem",
    justifyContent: "left",
    gridTemplateColumns: "repeat(auto-fill, minmax(270px, auto))",
}));
