import {Box, Chip, Collapse, styled, Typography} from "@mui/material";
import {DspCheckoutParameterGroupType} from "application/entities/dataTypes/dspEnablementOptions";
import {getCheckoutParameterGroup, viewabilityOptions} from "application/utils/dsp.utils";
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import {useState} from "react";
import {isArray} from "xstate/lib/utils";

type DspCheckoutParametersBoxProps = {
    paramGroups: DspCheckoutParameterGroupType[];
    isCollapseDisabled?: boolean;
    alignCollapseToggle?: 'left' | 'right' | 'center'
    backgroundColor?: string
};

export const DspCheckoutParametersBox = (props: DspCheckoutParametersBoxProps) => {
    const {paramGroups, isCollapseDisabled, alignCollapseToggle, backgroundColor} = props;
    const [show, setShow] = useState<boolean>(isCollapseDisabled || false);

    const optionList = paramGroups
        .map((paramGroup) => {
            let paramGroupExtanded = getCheckoutParameterGroup(paramGroup);
            let value: any[] = [];
            if (["ad_format_group", "ad_platform_group"].includes(paramGroupExtanded.code)) {
                value = paramGroupExtanded.dspCheckoutParameters
                    .map((param) => {
                        if (param?.selected) {
                            return {
                                value: param.parameterName,
                                code: param.code,
                            };
                        }
                        return null;
                    })
                    .filter((el) => el !== null);
                return {
                    title: paramGroupExtanded.title,
                    options: value,
                };
            } else {
                value = paramGroupExtanded.dspCheckoutParameters
                    .map((param) => {
                        if (param?.selected) {
                            return {
                                value: param?.selected,
                                code: param.code,
                            };
                        }
                        return null;
                    })
                    .filter((el) => el !== null);
            }
            return {
                title: paramGroupExtanded.title,
                options: value,
            };
        })
        .filter((el) => el.options.length > 0 && el.options[0].value?.length > 0);

    const getFileName = (key: string) => key?.split("/").pop()?.replaceAll("_", " ");

    return (
        <>
            {!isCollapseDisabled && (
                <Typography variant="body1"
                            sx={{marginTop: "2px", cursor: "pointer", textAlign: alignCollapseToggle ?? 'inherit'}}
                            color="primary" onClick={() => setShow(!show)}>
                    {show ? "Hide" : "Show"} {optionList.length} enablement options
                </Typography>
            )}
            <Collapse in={show}>
                <Box sx={{
                    background: backgroundColor ?? "#fff",
                    padding: "8px",
                    borderRadius: "8px",
                    border: isCollapseDisabled ? "none" : "1px solid #ececec"
                }} mt={1}>
                    {optionList.map((option) => {
                        return (
                            <Box key={option.title} sx={{paddingBlock: "4px"}}>
                                <Typography variant="body1" className="color-tech-grey" sx={{marginBottom: "3px"}}>
                                    {option.title}
                                </Typography>
                                {option.options.map((opt: any) => {
                                    if (["include_domains", "exclude_domains"].includes(opt.code)) {
                                        return (
                                            <StyledChip
                                                variant="outlined"
                                                key={opt.value}
                                                label={opt.code === "exclude_domains" ? `Blacklist` : `Whitelist` + ": " + getFileName(opt.value)}
                                                size="small"
                                            />
                                        );
                                    }
                                    if (typeof opt.value === "string") {
                                        if (option.title === "Viewability") {
                                            return (
                                                <StyledChip
                                                    variant="outlined"
                                                    key={opt.value}
                                                    label={viewabilityOptions.filter((item) => item.value === opt.value)[0]?.label}
                                                    size="small"
                                                />
                                            );
                                        }
                                        return <StyledChip variant="outlined" key={opt.value} label={opt.value}
                                                           size="small"/>;
                                    }
                                    if (isArray(opt.value)) {
                                        return (
                                            <>
                                                {[...opt.value].splice(0, 2).map((val, idx) => (
                                                    <StyledChip variant="outlined" size="small" key={val} label={val}/>
                                                ))}

                                                {opt.value.length > 2 && (
                                                    <AppTip
                                                        key={opt.value}
                                                        text={[...opt.value]
                                                            .splice(2, [...opt.value].length)
                                                            .map((item) => {
                                                                return item;
                                                            })
                                                            .flat()
                                                            .toString()
                                                            .replaceAll(",", "\n")}
                                                    >
                                                        <StyledChip variant="outlined" size="small"
                                                                    label={`+${opt.value.length - 2}`}/>
                                                    </AppTip>
                                                )}
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        );
                    })}
                </Box>
            </Collapse>
        </>
    );
};

const StyledChip = styled(Chip)(({theme}) => ({
    fontSize: "10px",
    height: "19px",
    margin: "2px",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
}));
