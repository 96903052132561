import {Box, MenuItem, Select, styled, TextField,Button} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useFormContext} from 'react-hook-form';
import {useCountryQuery} from '../../../components/hooks/data/useCountryQuery';
import {FormInputContainer, InputFieldLabel, LabelZone, TextFieldBlock} from '../../../components/templates/InfoBlock';
import {companySizeList3} from '../../Accounts/zOld/mock';
import {EntityTreeView} from '../../../components/form/EntityTreeView';
import Autocomplete from '@mui/material/Autocomplete';
import {IconSet} from '../../../assets/icons';
import {ICountry} from '../../../application/entities/dataTypes/country';
import {useTranslation} from 'react-i18next';
import {useAccountQuery} from '../../../components/hooks/data/useAccountQuery';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';

interface ICompanyFormProps {
  accountId: number;
};


export const CompanyFormView: FunctionComponent<ICompanyFormProps> = (props: ICompanyFormProps) => {
  const {accountId} = props;
  
  const {t} = useTranslation();
  
  const accountQuery       = useAccountQuery(accountId);
  const account: IAccounts = accountQuery?.data ?? {};
  
  return <div className="flex-h gap-small">
    
    <FormInputContainer className="flex-v gap-small">
      <LabelZone
        title={'Company Name'}
        content={account.companyName}
        code={'companyName'}
      />
      <LabelZone
        title={'Company Size'}
        content={account.companySize}
        code={'companySize'}
      />
      <LabelZone
        title={t('account.accountAddressLabels.companyStreetAddress')}
        content={account.streetAddress}
        code={'streetAddress'}
      />
      <LabelZone
        title={'Company Url'}
        content={account.companyUrlList}
        code={'companyUrlList'}
      />
    
    </FormInputContainer>
    <FormInputContainer className="flex-v gap-small">
      <LabelZone
        title={'Industry'}
        content={Boolean(account.industries) && account.industries.map((res: any) => res.name)}
        code={'industries'}
      />
      
      <div className={'flex-h flex-align-middle gap-small'}>
        <div className={'flex-main'}>
          <LabelZone
            title={t('account.accountAddressLabels.postalCode')}
            content={account.postalCode}
            code={'postalCode'}
          />
        </div>
        <div className={'flex-main'}>
          <LabelZone
            title={t('account.accountAddressLabels.city')}
            content={account.city}
            code={'city'}
          />
        </div>
      </div>
      <LabelZone
        title={'Country'}
        content={account.country}
        code={'country'}
      />
    </FormInputContainer>
  </div>;
};
