import * as React from "react";
import { ICompanyUsers } from "../../types";
import { Box, Button, List, ListItem, ListItemAvatar, ListItemText, ListSubheader } from "@mui/material";
import _ from "lodash";
import { GlobalAvatar } from "../../components/ui/GlobalAvatar";
import { Link, useNavigate } from "react-router-dom";
import { pageUtils } from "application/pages/pages.utils";

export interface IAccountUsersListProps {
  companyUsers?: ICompanyUsers[];
  pageIdLink?: string;
}

export const AccountUsersList: React.FC<IAccountUsersListProps> = ({ companyUsers, pageIdLink }) => {
  const groups = _.groupBy<ICompanyUsers>(companyUsers, "role");
  const navigate = useNavigate();
  return (
    <>
      <List dense={true} sx={{ width: "100%" }} subheader={<li />}>
        {Object.keys(groups).map((group: string) => {
          const users = groups[group] ?? [];
          return (
            <li key={group}>
              <ul>
                <ListSubheader style={{ position: "relative", textTransform: "capitalize" }}>{group.toLowerCase()}</ListSubheader>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {users.length > 0 &&
                    users.map((item) => (
                      <ListItem key={item.id} sx={{ gap: "7px" }}>
                        <ListItemAvatar sx={{ width: "24px" }}>
                          <GlobalAvatar user={item} isPending={item.status !== "ACTIVATED"} size={"28px"} fontSize={"15px"} />
                        </ListItemAvatar>
                        <ListItemText
                          style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                          primary={`${item.firstName} ${item.lastName}`}
                          secondary={item.email}
                        >
                          {item.role}
                        </ListItemText>
                        {pageIdLink && (
                          <Button
                            style={{ marginRight: "3px" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              navigate(pageUtils.getPagePathById(pageIdLink, { userId: item.id }));
                            }}
                          >
                            View
                          </Button>
                        )}
                      </ListItem>
                    ))}
                </Box>
              </ul>
            </li>
          );
        })}
      </List>
    </>
  );
};
