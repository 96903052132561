import { objectApi } from "application/entities/dataApi";
import { IVault, OperationStatus } from "application/entities/dataTypes/vault";
import { useAppToast } from "components/live/AppToast";
import { CreateVault } from "pages/DataVault/DataVaultSetup/CreateVault";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorResponse } from "types";
import { PropertiesList } from "../AdminAccountView";

type VaultInfoType = {
  CORSAllowOrigins: string;
  "browser-sdk": boolean;
  data_client: boolean;
  data_provider: boolean;
  db_id: number;
  enable_tracker: boolean;
  enable_upload: boolean;
  env: string;
  "glue-scripts": string;
  last_run: string;
  region: string;
  user_email: string;
  vault_key: string;
};

export const AdminAccountDataVaultSetup = (props: { accountId: number }) => {
  const { accountId } = props;
  const [vault, setVault] = useState<IVault>();
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState(true);
  const [createVaultDisabled, setCreateVaultDisabled] = useState(true);
  const [error, setError] = useState<Partial<ErrorResponse>>();
  const [jobRunState, setJobRunState] = useState<OperationStatus>();

  const appToast = useAppToast();
  const { t } = useTranslation();

  const accountsApi = new objectApi.accounts();

  const vaultStatusTimer = useRef<any>(null);

  const vaultInfo: VaultInfoType = vault?.state ? JSON.parse(vault?.state) : {};

  useEffect(() => {
    accountsApi.getVault(+accountId).then((res) => {
      setVault(res);
      if (accountId && res.lastDataProcessingStatus === OperationStatus.PENDING) {
        accountsApi.getDataPipelineWorkflowJob(+accountId).then((response) => {
          const data = JSON.parse(response.data.body).JobRun;
          setJobRunState(data.JobRunState);

          appToast.open({
            toastId: "manageAccount",
            severity: "info",
            message: `${t("vaultSettingsPageLabels.jobRunState")} ${data.JobRunState}` ?? "getDataPipelineWorkflowJob OK.",
          });
          if (response.data.errorType) {
            const glueJobMessage = response.data.errorType;
            appToast.open({
              toastId: "manageAccount",
              severity: "error",
              message: `${t("vaultSettingsPageLabels.pipelineJobError")} ${glueJobMessage}` ?? t("labels.error"),
            });
          }
        });
        updateVault();
      }
    });
  }, []);

  useEffect(() => {
    if (
      vault?.lastVaultOperationStatus === OperationStatus.SUCCEEDED ||
      vault?.lastVaultOperationStatus === OperationStatus.FAILED ||
      error?.reason === "NOT_FOUND" ||
      (vault && !vault.lastVaultOperationStatus)
    ) {
      setCreateVaultDisabled(false);
    }

    return () => {
      setCreateVaultDisabled(true);
    };
  }, [vault?.lastVaultOperationStatus, error]);

  useEffect(() => {
    if (vault?.lastVaultOperationStatus === OperationStatus.SUCCEEDED || error?.reason === "NOT_FOUND") {
      setDisabled(false);
      vaultStatusTimer.current && clearInterval(vaultStatusTimer.current);
    }

    if (vault?.lastVaultOperationStatus === "PENDING") {
      vaultStatusTimer.current = setInterval(() => {
        updateVault();
      }, 25000);
    }

    return () => {
      setDisabled(true);
      vaultStatusTimer.current && clearInterval(vaultStatusTimer.current);
    };
  }, [vault?.lastVaultOperationStatus, error]);

  const updateVault = () => {
    if (accountId) {
      accountsApi
        .getVault(+accountId)
        .then((res) => {
          setVault(res);
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div>
        <CreateVault
          disabled={disabled}
          setDisabled={setDisabled}
          accountId={+accountId}
          vault={vault}
          setVault={setVault}
          createVaultDisabled={createVaultDisabled}
          setCreateVaultDisabled={setCreateVaultDisabled}
          style={{ width: "220px", marginInline: "auto", justifyContent: "center" }}
        />
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <PropertiesList>
          <li>
            <span>{t("vaultSettingsPageLabels.yourVault")}</span>
            {vault?.awsAccountUrl ? (
              <a href={vault?.awsAccountUrl} target="_blank" rel="noreferrer">
                {vault?.awsAccountUrl}
              </a>
            ) : (
              "No awsAccountId"
            )}
          </li>
          <li>
            <span>Tracker enabled</span>
            {vaultInfo?.enable_tracker ? "Yes" : "No"}
          </li>
          <li>
            <span>Upload enabled</span>
            {vaultInfo?.enable_upload ? "Yes" : "No"}
          </li>
          <li>
            <span>Vault</span>
            {vaultInfo.vault_key} on {vaultInfo.env} env
          </li>

          <li>
            <span>AWS region</span>
            {vaultInfo?.region}
          </li>
          <li>
            <span>Glue Script</span>
            {vaultInfo?.["glue-scripts"]}
          </li>
          <li>
            <span>Browser SDK</span>
            {vaultInfo?.["browser-sdk"]}
          </li>
          <li>
            <span>Last Run</span>
            {vaultInfo?.last_run}
          </li>
          <li>
            <span>User Email</span>
            {vaultInfo?.user_email}
          </li>
          <li>
            <span>{t("vaultSettingsPageLabels.yourBucket")}</span>
            {vault?.s3Url ?? "No Bucket"}
          </li>
          <li>
            <span>CORS Allow Origin</span>
            {vaultInfo?.CORSAllowOrigins}
          </li>
        </PropertiesList>
      </div>
    </>
  );
};
