import { IAccounts } from '../dataTypes/accounts';


export const accounts: Partial<IAccounts> = {
  activated             : false,
  clientTypesDescription: '',
  companyName           : '',
  companySize           : '',
  companyUrlList        : [],
  contractFiles         : '',
  country               : '',
  databaseName          : '',
  dataClient            : false,
  dataProvider          : false,
  industry              : undefined,
  industries            : [],
  industryIds           : [],
  notes                 : [
    {
      title: null,
      authorName: 'Alain Peyrat',
      text: 'test note2 from UI',
    },
  ],
  postalCode: '',
  size: '',
  state: '',
  streetAddress: '',
  tag: '',
  vertical: '',
  subscriptionId: undefined,
}