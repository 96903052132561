import {objectApi} from "application/entities/dataApi";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {useQuery} from "react-query";

export const useFirstPartyData = (accountId: number, isAutoRefreshActive: boolean = false) => {
    const accountsApi = new objectApi.accounts();
    const {isLoading, error, data, refetch, remove} = useQuery<FirstPartyDataFile[]>(
        ["account", +accountId, "firstPartyDataFiles"],
        () => {
            return accountsApi.getFirstPartyData(+accountId).then((res) => res?.data?.firstPartyDatas);
        },
        {
            enabled: Boolean(accountId),
            cacheTime: 30000,
            refetchInterval: isAutoRefreshActive ? 15000 : false,
        }
    );
    return {isLoading, error, data, refetch, remove};
};
