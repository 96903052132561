import React from 'react';
import { useUiStore } from 'redux/ui.store';

export const useAdminPage=()=>{
    const adminMode = useUiStore(st => st.isAdminPage);
    React.useEffect(() => {
        useUiStore.setState({isAdminPage: true})
        return () => {
            useUiStore.setState({isAdminPage: false})
        }
    },[])

    return adminMode
}