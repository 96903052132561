import React from "react";
import { AppIcon } from "../AppIcon";
import oldStyled from "styled-components/macro";
import { ColumnsMiddle } from "../AppElements";
import { styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Box, Collapse } from "@mui/material";

interface ILeftPanelProps {
  isOpen?: boolean;
  children?: any;
  onClose?: (closed: boolean) => void;
}

export const AppSlidePanel = (props: ILeftPanelProps) => {
  const [state, setState] = React.useState<boolean>(props.isOpen ?? false);

  const handleClick = () => {
    setState(!state);
    props.onClose && props.onClose(state);
  };

  React.useEffect(() => {
    props.onClose && props.onClose(state);
  }, [state]);

  return (
    <PaneParent isOpen={state}>
      <PaneButton data-cy={"paneButton"} onClick={handleClick}>
        <AppIcon color={"white"} icon={"ChevronDownIcon"} fontSize={"tiny"} rotate={state ? 90 : 270} />
      </PaneButton>
      <Pane isOpen={state}>{props?.children && props.children}</Pane>
    </PaneParent>
  );
};

const PaneParent = oldStyled.div<ILeftPanelProps>((props) => ({
  zIndex: 0,
  top: 0,
  marginTop: 0,
  position: "sticky",
  height: "100%",
  width: props.isOpen ? "auto" : "0px",
  minWidth: props.isOpen ? "260px" : "0px",
  transitionProperty: "*",
  transitionDuration: "0.25s",
}));

const Pane = styled("div")((props: { theme: Theme } & ILeftPanelProps) => ({
  display: "block",
  position: "relative",
  left: props.isOpen ? "0px" : "-280px",
  minHeight: "100%",
  height: "100%",
  background: "#FFF",
  transitionProperty: "*",
  transitionDuration: "0.25s",
  boxShadow: props.theme.skin.boxShadow,
  zIndex: 2,
}));

const PaneButton = styled(Box)((props: { theme: Theme }) => ({
  cursor: "pointer",
  position: "absolute",
  top: "0px",
  right: "0",
  transform: "translate(16px,0)",
  width: "16px",
  boxShadow: props.theme.skin.boxShadow,
  height: "24px",
  borderBottomRightRadius: "8px",
  borderTopRightRadius: "8px",
  background: props.theme.palette.primary.main,
  zIndex: 1,
  display: "flex",
  alignItems: "center",
}));
