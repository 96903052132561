
const currentLocale = 'en-US';

export const profilesFormatConfig = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};


export const profilesFormatter    = new Intl.NumberFormat(currentLocale, profilesFormatConfig);
