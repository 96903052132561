import { Box } from "@mui/material";
import { actions } from "application/actions/actions";
import { objectApi } from "application/entities/dataApi";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { pageUtils } from "application/pages/pages.utils";
import { ActionButtons } from "components/controls/ActionButtons";
import { useAppToast } from "components/live/AppToast";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { GridItem, GridItemFull, GridMiddle } from "components/ui/AppElements";
import { CopyToBrandPopup } from "pages/Audiences/CopyToBrandPopup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { AudienceTagsHeader } from "./AudienceTagsHeader";

export const HeaderContent = (props: { audience: IAudiences }) => {
  const audience = props.audience;
  //hooks
  const { t } = useTranslation();
  const history = useNavigate();
  const appToast = useAppToast();
  const dialogWindow = useOpenDialogWindow();
  const audiencesApi = new objectApi.audiences();

  const dialogDelete: IOpenDialogAction = {
    dialogId: "audienceDelete",
    buttonActions: { acceptButton: true, cancelButton: true },
    title: t("audience.deleteAudience"),
    description: t("audience.deleteAudienceDescription"),
    icon: "Trash",
    onAccept: (args: any, orgs: any) => {
      const accountId = audience.account.id;
      audiencesApi
        .delete(orgs.id)
        .then(() => {
          history(pageUtils.getPagePathById("audience-library", { accountId: accountId }));
        })
        .catch((e: any) => {
          appToast.error({
            message: t("audience.errorDeletingAudience"),
          });
        });
    },
  };

  const dialogDuplicate: IOpenDialogAction = {
    dialogId: "audienceDuplicate",
    buttonActions: { acceptButton: true, cancelButton: true },
    title: t("audience.duplicateAudience"),
    description: t("audience.duplicateAudienceDescription"),
    icon: "Duplicate",
    onAccept: (args: any, dta: { id: number; data: Partial<IAudiences> }) => {
      audiencesApi
        .duplicateAudience(dta.data)
        .then((res: any) => {
          if (res && res.id) {
            history(pageUtils.getPagePathById("audience-builder-update", { id: res.id }));
          }
        })
        .catch(() => {
          appToast.error({
            message: "Boom !",
          });
        });
    },
  };

  const actionButtons = { ...actions.audience };
  delete actionButtons.edit;
  delete actionButtons.activate;
  delete actionButtons.archive;

  actionButtons.copy.action = (audienceData: IAudiences) => {
    dialogWindow.open({ ...dialogDuplicate, dialogArgs: { id: audienceData.id, data: audienceData } });
  };
  actionButtons.trash.action = (audienceData: IAudiences) => {
    dialogWindow.open({ ...dialogDelete, dialogArgs: { id: audienceData.id, data: audienceData } });
  };
  actionButtons.copyToBrand.action = (audienceData: IAudiences) => {
    dialogWindow.open({
      dialogId: "copyToBrand",
      component: CopyToBrandPopup,
      componentProps: {
        audience: audienceData,
      },
    });
  };

  if (audience.audienceType.code === "FIRST_PARTY") {
    delete actionButtons.copyToBrand;
    delete actionButtons.copy;
  }

  const headerContent = (
    <div className="history-zone" style={{ display: "block" }}>
      <GridMiddle>
        <GridItem>
          <AudienceTagsHeader />
        </GridItem>
        <GridItemFull></GridItemFull>

        <Box sx={{ p: 1 }}>
          <ActionButtons buttonSize={"small"} actions={actionButtons} vars={{ ...audience, audienceId: audience?.id }} />
        </Box>
      </GridMiddle>
    </div>
  );

  return headerContent;
};
