import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TToastId = string | 'main_toast';
export type TOpenToastAction = TToastId;
export type TCloseToastAction = TToastId;
export type TToastAttributes = Record<string, any>;

export type INotifySlice = Record<TToastId, INotifySliceDetail>;
export type TSeverity = "success" | "info" | "warning" | "error";

export interface INotifySliceDetail {
  toastId: TToastId;
  severity: TSeverity;
  isOpen: boolean;
  message: string;
  messageText?: string;
}

export interface IOpenToastAction {
  toastId: TToastId;
  severity: TSeverity;
  message: string;
  messageText?: string | any;
  autoHideDelay?: number
}

export interface IOpenToastHookArgs {
  toastId: TToastId;
  severity: TSeverity;
  message: string;
  messageText?: string;
}

const initialState: INotifySlice = {};

const toastSlice = createSlice({
  name: "toastSlice",
  initialState,
  reducers: {
    openToast(state, action: PayloadAction<IOpenToastHookArgs>) {
      // in object
      state[action.payload.toastId] = {
        toastId: action.payload.toastId,
        severity: action.payload.severity,
        message: action.payload.message,
        messageText: action.payload?.messageText,
        isOpen: true,
      };
    },
    closeToast(state, action: PayloadAction<TCloseToastAction>) {
      if(state[action.payload]?.isOpen) state[action.payload].isOpen = false;
    },
    setToastAttributes(state, action: PayloadAction<TToastAttributes>) {
      // some changes here ?
      // some changes here ?
      // some changes here ?
    },
  },
});

export const { openToast, closeToast, setToastAttributes } =
  toastSlice.actions;

export default toastSlice.reducer;
