/**
 created by Mydde
 */

import {TreeItem, TreeView} from "@mui/lab";
import {Box, ClickAwayListener, TextField, Theme} from "@mui/material";
import Popper from "@mui/material/Popper";
import {styled} from "@mui/styles";
import * as React from "react";
import styledOld from "styled-components/macro";
import {TEntityName} from "../../../application/entities/dataTypes";
import {Elem, GridItemFull} from "../../ui/AppElements";
import {AppIcon} from "../../ui/AppIcon";
import {StyledCheckbox} from "../CheckBox";

interface IEntityTreeViewProps<T = any> {
    entityName: TEntityName;
    entityList: Record<string, any>[];
    defaultField: string; // field used to display data
    hierarchyField: string; // field containing the path
    entityVars?: string[];
    selectedEntityItems?: Record<string, any>[]; // data with selected items
    onChange?: (item: Record<string, T>) => void;
    dataWatcher?: any;
    singleSelect?: boolean; // default true - allow multiple / single selection
    textOnEmpty?: string;
    entityTextName?: string;
    mode?: "float" | "block";
    ref?: any;
    onToggleExpand?: (path: string[]) => void;
    displaySearch?: boolean
    selectStyle?: any
    maxWidth?: string
}

interface ITreeItemData {
    name: string;
    parent?: string;
    path: string;
    entityData?: any;
}

interface TreeItemType extends ITreeItemData {
    name: string;
    children: TreeItemType[];
}

type ISelectedItemsObjectState = Record<string, ISelectedListItemState>;

interface ISelectedListItemState {
    name: string;
    path: string;
    item: ITreeItemData; // not needed ?
    entityData: Record<string, any>;
}

const TreeViewSelectRef = (props: IEntityTreeViewProps, ref: any) => {
    const {
        entityName,
        entityList,
        onChange,
        selectedEntityItems,
        defaultField,
        hierarchyField,
        singleSelect,
        entityTextName,
        displaySearch = true,
        selectStyle,
        maxWidth = "200px",
        mode = "float"
    } = props;
    const [isPending, startTransition] = React.useTransition();

    // const [entityList, setEntityList]                 = React.useState<Record<string, any>[]>([]);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [filteredEntityList, setFilteredEntityList] = React.useState<Record<string, any>[]>([]);
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const [treeList, setTreeList] = React.useState<TreeItemType[]>([]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    /** selected list of checkbox items */
    const [selected, setSelected] = React.useState<string[]>([]);

    const inputSearchField = React.useRef(null);
    const timerSearchField: any = React.useRef();

    const timerChange: any = React.useRef(null);

    const open = Boolean(anchorEl);
    const id = `tree_${entityName}`;

    React.useImperativeHandle(ref, () => ({inputValue}));

    React.useEffect(() => {
        //
        if (entityList.length) {
            startTransition(() => {
                setFilteredEntityList(entityList);
            });
        }

        return () => {
            setFilteredEntityList([]);
            setSelected([]);
            setTreeList([]);
            setInputValue("");
        };
    }, [entityList]);

    React.useEffect(() => {
        startTransition(() => {
            setTreeList(trans2Tree(filteredEntityList, hierarchyField));
        });
    }, [filteredEntityList]);

    React.useEffect(() => {
        if (selectedEntityItems && Array.isArray(selectedEntityItems)) {
            setSelected(makeSelectedIds(selectedEntityItems));
            setInputValue(buildInputValue(singleSelect));
        }
    }, [selectedEntityItems]);

    React.useEffect(() => {
        setInputValue(buildInputValue(singleSelect));
    }, [selected]);

    /**
     * Transforms dataSet into treeList data
     * @param {any[]} paths
     * @param {string} pathKey
     * @param {string} splitter
     * @returns {TreeItemType[]}
     */
    function trans2Tree(paths: any[], pathKey: string = "path", splitter: string = "/"): TreeItemType[] {
        const tree: TreeItemType[] = [];

        // sort path alphabetically
        const sortedPaths = paths.sort((a, b) => (a[pathKey] > b[pathKey] ? 1 : -1));

        for (let i = 0; i < sortedPaths.length; i++) {
            const path = sortedPaths[i][pathKey];

            const pathSplice = path.split(splitter);

            let currentLevel = tree;

            for (let j = 0; j < pathSplice.length; j++) {
                const part = pathSplice[j];

                const existingPath = currentLevel.find((k) => k.name === part); //findWhere(currentLevel, 'name', part);

                if (existingPath) {
                    // @ts-ignore
                    currentLevel = existingPath.children;
                } else {
                    const newPart = {
                        name: part,
                        path: pathSplice.slice(0, j + 1).join(splitter),
                        entityData: sortedPaths[i],
                        children: [],
                    };
                    //
                    currentLevel.push(newPart);
                    currentLevel = newPart.children;
                }
            }
        }
        return tree;
    }

    /** Return array of path from an item preselection . props.selectedEntityItem at start*/
    const makeSelectedIds = (itemPreSelection: any) => {
        const array: string[] = [];

        itemPreSelection.forEach((itemData: any) => {
            if (itemData?.[defaultField]) {
                const path = itemData?.[hierarchyField];
                const pathSplice = path.split("/");
                for (let j = 0; j < pathSplice.length; j++) {
                    array.push(pathSplice.slice(0, j + 1).join("/"));
                }
            }
        });
        return array;
    };

    // single selection
    const onSelectObjectSingle = (item: TreeItemType, checkState: boolean) => {
        // if false, remove from Obj
        const selected: ISelectedItemsObjectState = {} as ISelectedItemsObjectState;
        if (checkState) {
            selected[item.name] = {
                name: item.name,
                path: item?.path ?? "",
                item: item,
                entityData: item.entityData,
            };
        }

        return selected;
    };

    function checkItemSelected(item: TreeItemType, selectedState: string[]) {
        return selectedState.some((someItemId) => someItemId === item.path);
    }

    /**  build an entityData list from string[] */
    function exportSelectedIds(itemsSelected: string[]) {
        // should rmo parent ?
        return entityList.filter((entityItem) => itemsSelected.includes(entityItem[hierarchyField]));
    }

    function collectParentNodes(treeList: any[]): string[] {
        function getParentNodes(node: any, parentNodes: string[]): void {
            if (!node.children || node.children.length === 0) {
                return;
            }
            parentNodes.push(node.path);
            for (const child of node.children) {
                getParentNodes(child, parentNodes);
            }
        }

        const parentNodes: string[] = [];
        for (const node of treeList) {
            getParentNodes(node, parentNodes);
        }
        return parentNodes;
    }

    /** handle treeItem selection */
    function handleOnChange(checked: boolean, item: TreeItemType) {
        if (!item.name) return;

        // remove all leaf nodes
        const parentsNodes = collectParentNodes(treeList);

        const allNodes: string[] = getTreeItemDescendants(item, item.path);

        let array = checked ? [...selected, ...allNodes] : selected.filter((value) => !allNodes.includes(value));

        array = array.filter((x) => parentsNodes.indexOf(x) === -1);

        const selectionObj = singleSelect ? onSelectObjectSingle(item, checked) : exportSelectedIds(array);

        //fix bug parent checkbox not checked and checked after update
        let parent: string[] = [];
        array.map((item) => {
            const pathSplice = item.split("/");
            parent.push(pathSplice[0]);
        });

        array = [...new Set([...array, ...parent])];

        startTransition(() => {
            setSelected(array);
        });

        if (onChange) {
            if (timerChange.current) clearTimeout(timerChange.current);
            timerChange.current = setTimeout(() => {
                onChange(selectionObj);
            }, 10);
        }
    }

    /** Recurse find the selected treeItem */
    function getTreeItemByPath(treeItem: TreeItemType, id: string) {
        if (treeItem.path === id) {
            return treeItem;
        } else if (Array.isArray(treeItem.children)) {
            let result = null;
            treeItem.children.forEach((node) => {
                if (!!getTreeItemByPath(node, id)) {
                    result = getTreeItemByPath(node, id);
                }
            });
            return result;
        }

        return null;
    }

    /** get an array of recursive descendants */
    function getTreeItemDescendants(nodeItem: TreeItemType, id: string) {
        let array: string[] = [];

        // parse self and children
        function getAllChildren(treeItem: TreeItemType | null) {
            if (treeItem === null) return [];
            array.push(treeItem.path);
            if (Array.isArray(treeItem.children)) {
                treeItem.children.forEach((node) => {
                    const nodeChildren = getAllChildren(node).filter((val, x, self) => self.indexOf(val) === x);
                    array = [...array, ...nodeChildren].filter((val, x, self) => self.indexOf(val) === x);
                });
            }
            return array;
        }

        return getAllChildren(nodeItem);
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        if (props.onToggleExpand) props.onToggleExpand(nodeIds);
        setExpanded(nodeIds);
    };

    const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setFilteredEntityList(entityList);
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) => (oldExpanded.length === 0 ? selected : []));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;
        if (searchString && searchString.trim().length !== 0) {
            if (timerSearchField.current) {
                window.clearTimeout(timerSearchField.current);
            }
            //
            const results = preformSearch(searchString);
            timerSearchField.current = window.setTimeout(() => {
                setFilteredEntityList(results);
                setExpanded(makeSelectedIds(results));
                if (props.onToggleExpand) props.onToggleExpand(makeSelectedIds(results));
            }, 500);
        } else {
            resetSearch();
        }
    };

    const resetSearch = () => {
        setFilteredEntityList(entityList);
        setExpanded([]);
        if (props.onToggleExpand) props.onToggleExpand([]);
    };

    const preformSearch = (searchString: string) => {
        const regex = new RegExp(`.*${searchString}.*`, "gi");

        return entityList.filter((item) => {
            return item[hierarchyField].search(regex) === 0;
        });
    };

    /** draw indeterminate state for checkboxes */
    const getIndeterminate = (item: TreeItemType | undefined) => {
        if (item?.children?.length) {
            // take all children, verify they are checked all / some
            if (
                item?.children.every((it: TreeItemType) => {
                    return selected.includes(it.path); //Boolean(selectedItems[it.name]);
                })
            ) {
                return false;
            } else {
                return item?.children.some((it: TreeItemType) => {
                    return selected.includes(it.path);
                });
            }
        }
        return false;
    };

    // forge root input value
    const buildInputValue = (single: boolean | undefined) => {
        if (single) {
            return exportSelectedIds(selected)?.[0]?.name ?? "";
        } else {
            return exportSelectedIds(selected).length === 0 ? "Select " + (entityTextName ?? entityName) : `${exportSelectedIds(selected).length} selected`; // CMVP-1538
        }
    };

    const BuildTree = (props: { list: TreeItemType[] }): any => {
        const {list} = props;

        return list.map((treeListItem: TreeItemType, index: number) => {
            const label = buildTreeLabel({item: treeListItem, parentRoot: index === 0});

            if (treeListItem?.children?.length) {
                return (
                    <TreeItem key={`${treeListItem.name}_${index}`} nodeId={treeListItem?.path ?? ""} label={label}
                              data-cy={treeListItem?.name}>
                        <BuildTree list={treeListItem?.children}/>
                    </TreeItem>
                );
            } else {
                return <TreeItem key={`${treeListItem.name}_${index}`} nodeId={treeListItem.path ?? ""} label={label}/>;
            }
        });
    };

    // set label and listeners on tree item
    const buildTreeLabel = (props: { item: TreeItemType; parentRoot: boolean, }): any => {
        const {item,} = props;

        const chkStateBis = checkItemSelected(props.item, selected);

        return (
            <div data-cy={"treeLabel"} className={"flex-h flex-align-middle"}>
                <Box sx={{width: "24px", textAlign: "center"}}>
                    {!(singleSelect && props.item.children?.length) && (
                        <StyledCheckbox
                            disableRipple
                            color={"primary"}
                            size={"small"}
                            indeterminate={getIndeterminate(props.item)}
                            checked={chkStateBis}
                            inputProps={{
                                style: {
                                    padding: 0,
                                },
                            }}
                            data-cy={"checkbox-" + item.path}
                            title={item.path}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                event.stopPropagation();
                                handleOnChange(event.currentTarget.checked, props.item);
                            }}
                        />
                    )}
                </Box>
                <GridItemFull
                    data-cy={"treeLabelName"}
                    data-test-value={props.item.name}
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingRight: "8px",
                    }}
                >
                    {props.item.name}
                </GridItemFull>
            </div>
        );
    };

    return Boolean(mode === "float") ? (
        <React.Fragment>
            <div style={{maxWidth: maxWidth}}>
                <TextField
                    data-cy={"treeViewInput-" + entityTextName}
                    className="hoverPointer"
                    onClick={handleInputClick}
                    name={"name"}
                    value={inputValue}
                    style={{width: "100%", maxWidth: maxWidth}}
                    placeholder={`Pick ${entityName}`}
                    inputProps={{spellCheck: "false", style: selectStyle}}
                    InputProps={{
                        endAdornment: (
                            <div style={{marginRight: "0.5rem", cursor: "pointer"}}>
                                <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} rotate={open ? 180 : 0}
                                         icon={"ChevronDownIcon"}/>
                            </div>
                        ),
                    }}
                />
            </div>

            <PopperContainer
                id={id}
                data-cy={`popup-${entityTextName}`}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: "document",
                            padding: 8,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <InnerPopper>
                        <div style={{height: "100%", overflow: "auto"}}>
                            {displaySearch && (
                                <Box sx={{py: 0, mb: 1}}>
                                    <TextField
                                        inputRef={inputSearchField}
                                        autoFocus
                                        fullWidth={true}
                                        type={"search"}
                                        placeholder={`Search ${entityTextName ?? entityName}`}
                                        onChange={handleInputChange}
                                        data-cy={"treeViewSearch-" + entityTextName}

                                        InputProps={{
                                            startAdornment: (
                                                <div style={{marginLeft: "0.5rem"}}>
                                                    <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"}
                                                             icon={"SearchIcon"}/>
                                                </div>
                                            ),
                                        }}
                                    />
                                </Box>
                            )}
                            <GridItemFull>
                                <div style={{overflow: "auto", maxHeight: "450px"}} data-cy={"tree-list"}>
                                    <TreeView
                                        defaultCollapseIcon={<AppIcon fontSize={"small"} icon={"Minus"}/>}
                                        defaultExpandIcon={<AppIcon fontSize={"small"} icon={"Plus"}/>}
                                        expanded={expanded}
                                        onNodeToggle={handleToggle}
                                    >
                                        <BuildTree list={treeList}/>
                                    </TreeView>
                                </div>
                            </GridItemFull>
                        </div>
                    </InnerPopper>
                </ClickAwayListener>
            </PopperContainer>
        </React.Fragment>
    ) : (
        <BlockPopper>
            <div style={{height: "100%", overflow: "hidden", display: "flex", flexDirection: "column"}}>
                <Box sx={{mb: 2}}>
                    <TextField
                        inputRef={inputSearchField}
                        autoFocus
                        fullWidth={true}
                        type={"search"}
                        placeholder={`Search ${entityTextName ?? entityName}`}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <div style={{marginLeft: "0.5rem"}}>
                                    <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} icon={"SearchIcon"}/>
                                </div>
                            ),
                            // endAdornment: <div style={{marginRight: '0.5rem',cursor:'pointer'}}><AppIcon display={'block'}   fontSize={'tiny'} icon={'Close'}/></div>,
                        }}
                    />
                </Box>

                <div style={{flex: 1, overflow: "auto"}}>
                    <TreeView
                        defaultCollapseIcon={<AppIcon fontSize={"small"} icon={"Minus"}/>}
                        defaultExpandIcon={<AppIcon fontSize={"small"} icon={"Plus"}/>}
                        selected={selected}
                    >
                        <BuildTree list={treeList}/>
                    </TreeView>
                </div>
            </div>
        </BlockPopper>
    );
};
// @ts-ignore
export const TreeViewSelect = React.forwardRef(TreeViewSelectRef);

const PopperContainer = styledOld(Popper)({
    margin: "1rem",
    maxHeight: "calc(100% - 2rem)",
    zIndex: 1000,
    overflow: "hidden",
    minWidth: "250px",
});

const InnerPopper = styledOld(Elem).attrs({padding: [1, 1.5]})({
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
    borderRadius: 20,
    backgroundColor: "white",
    maxHeight: "100%",
    overflow: "auto",
});

const BlockPopper = styled("div")((props: { theme: Theme }) => ({
    padding: "0 1rem",
    backgroundColor: props.theme.palette.background.default,
    maxHeight: "100%",
    height: "100%",
    overflow: "hidden",
}));
