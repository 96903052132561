import {devApi} from '_configuration/api.config';
import apiRequest from '../../../application/entities/api/interceptors';


const API_URL_USER = `${devApi}/companyUsers`;

export const createUser = (userDetails: any) => {
  const URL = `${API_URL_USER}`;
  return apiRequest.post(URL, userDetails);
};


export const deleteUser = (id: number) => {
  const URL = `${API_URL_USER}/${id}`;
  return apiRequest.delete(URL);
};
