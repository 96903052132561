import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {Formik, useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Button} from '@mui/material';
import {objectApi} from '../../../application/entities/dataApi';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {INote} from '../../../application/entities/dataTypes/note';
import {ISubscriptions} from '../../../application/entities/dataTypes/subscriptions';
import {createAccountValidationSchema} from '../../../application/validation';
import {ICompanyUsers} from '../../../types';
import {Permission} from '../../../types/Permission';
import {ActionGranter} from '../../../components/features/ActionGranter';
import {buttonLabels} from '../../../components/form/Buttons';
import {useAppToast} from '../../../components/live/AppToast';
import {MainFrame} from '../../../components/templates/MainFrame';
import {EditAccountClientType} from '../Form/EditAccountClientType';
import {EditAccountAddress} from '../Form/EditAccountAddress';
import {EditAccountDetails} from '../Form/EditAccountDetails';
import {EditSubscription} from '../Form/EditSubscription';
import {AccountTitle, StatusDot, StatusItem} from '../zOld/shared';
import {accessList} from '../../../application/access/access';
import {FooterBlock, FrameContainer, FrameContent, InfoBlockTitle, InfoContainer} from '../../../components/templates/InfoBlock';
import {AccountUserRoleType} from '../Form/AccountUserRoleType';
import {CreateAccountNote} from '../Form/CreateAccountNote';
import {UserForm} from '../../CompanyUsers/Form/UserForm';
import {pageUtils} from '../../../application/pages/pages.utils';
import {PageId} from '../../../application/pages/pages.config';
import {EUserGrade} from '../../../application/utils/granter.utils';
import { useAdminPage } from 'components/hooks/useAdminPage';


interface ICreateAccountProps {
}

export const AccountCreate: React.FC<ICreateAccountProps> = () => {
  
  const {t}         = useTranslation();
  const subPlansApi = new objectApi.subscriptionPlans();
  const accountsApi = new objectApi.accounts();
  const notesApi    = new objectApi.notes();
  
  const navigate = useNavigate();
  const appToast = useAppToast();
  useAdminPage()
  
  const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);
  const [account, setAccount]                             = useState<Partial<IAccounts>>({
    activated     : false,
    companyName   : '',
    companySize   : '',
    companyUrlList: [],
    country       : '',
    dataClient    : false,
    dataProvider  : false,
    awsRegion     : '',
    industryIds   : [],
    notes         : [],
    postalCode    : '',
    state         : '',
    streetAddress : '',
  });
  
  
  const [userDetails, setUserDetails] = useState<Partial<ICompanyUsers>>({
    title    : '',
    firstName: '',
    lastName : '',
    email    : '',
    phone    : undefined,
    role     : EUserGrade.ADMINISTRATOR,
    jobTitle : '',
  });
  
  const currentDate = Date.now();
  const oneYear     = 31536000000;
  
  const [subscription, setSubscription] = useState<Partial<ISubscriptions>>({
    renewalDate       : new Date(currentDate + oneYear),
    startDate         : new Date(currentDate),
    subscriptionPlanId: 1,
  });
  
  
  useEffect(() => {
    fetchData();
  }, []);
  
  
  const headerProperties = <>
    <AccountTitle>
    {t('account.generalTitles.status')}
    </AccountTitle>
    <Box sx={{pt: 1}}>
      <StatusItem active={account.activated}>
        <StatusDot/> {account.activated ? 'Active' : 'Inactive'}
      </StatusItem>
    </Box>
  </>;
  
  const gePageInfo = () => {
    return {
      title       : account.companyName,
      companyName : account.companyName,
      description1: `Company ID#: ${account.id ?? '...'}`,
      description2: account.companyName && account.country && `${account.companyName}, ${account.country}`,
      activated   : account.activated,
    };
  };
  
  const fetchData = () => {
    
    subPlansApi.get({output: {part: 'EMBEDDED'}})
               .then((subPlan) => {
                 if (subPlan) setSubscriptionPlansList(subPlan);
               })
               .catch((e) => {
               });
    
  };
  
  const accountDetailsFormik = useFormik({
    initialValues   : {...userDetails, ...account, companyUrlList: ''},
    validationSchema: createAccountValidationSchema,
    validateOnChange: true,
    validateOnBlur  : true,
    onSubmit        : values => {
      const castValues = createAccountValidationSchema.cast(values);
      
      handleCreateAccount(
        {
          ...castValues,
          country: account.country,
        });
    },
  });
  const handleCreateAccount = async (values: any & Partial<IAccounts> & Partial<ICompanyUsers>) => {
    if (values && values.companyName) {
      const dtaAcc = {...values};
      if (dtaAcc.notes && dtaAcc.notes.length < 1) delete dtaAcc.notes;
      
      const initialUser = {
        title    : values.title,
        firstName: values.firstName,
        lastName : values.lastName,
        email    : values.email,
        phone    : values.phone,
        role     : EUserGrade.ADMINISTRATOR,
        jobTitle : values.jobTitle,
      };
      
      try {
        const newAccount = await accountsApi.createAccount({
          ...values,
          initialUser,
          subscription,
          industryIds: account.industryIds,
        } as unknown as IAccounts);
        if (newAccount.data.id) {
          if (account.notes && account.notes[0]) {
            const dataNotes: Partial<INote> = {
              text   : account.notes[0]?.text,
              account: `/accounts/${newAccount.data.id}`,
            };
            await notesApi.create(dataNotes);
          }
          setAccount(newAccount.data);
          appToast.open({
            toastId : 'globalToast',
            severity: 'success',
            message : t('account.textAndWarnings.creationSuccessful'),
          });
          setTimeout(() => navigate(pageUtils.getPagePathById(PageId.accountList)), 2000);
        }
      } catch (err: any) {
        let message = err?.response?.data?.error?.message; // api
        message     = message ?? err?.message; // aws
        
        // quick patch
        if (message.includes('email')) {
          message = 'User with this email already exists';
          accountDetailsFormik.setFieldError('email', message);
        }
        if (message.includes(values.companyName)) {
          message = `Company with this name already exists`;
          accountDetailsFormik.setFieldError('companyName', message);
        }
        appToast.open({
          toastId : 'manageAccount',
          severity: 'error',
          message : message ?? t('labels.error'),
        });
      }
      return false;
      
    } else {
      // console.log('Some required fields are missing');
    }
  };
  
  const handleCancel = () => {
    
    navigate(pageUtils.getPagePathById(PageId.accountList));
  };
  
  // automation test
  return (
    <MainFrame
      frameId={PageId.accountCreate}
      headerProps={{headerProperties, frameInfo: gePageInfo()}} >
      <FrameContainer>
        <form autoComplete={'off'} onSubmit={accountDetailsFormik.handleSubmit}>
          <FrameContent>
            <DetailsBox>
              <InfoContainer>
                <InfoBlockTitle>
                  {t('account.userDetails.userDetails')}
                </InfoBlockTitle>
                <UserForm
                  dataFormik={accountDetailsFormik}
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
                <AccountUserRoleType
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />

                <InfoBlockTitle>
                  {t('account.accountDetailsLabels.accountDetails')}
                </InfoBlockTitle>

                <EditAccountDetails
                  accountDetailsFormik={accountDetailsFormik}
                  account={account}
                  setAccount={setAccount}
                />
                <EditAccountAddress
                  accountDetailsFormik={accountDetailsFormik}
                  account={account}
                  setAccount={setAccount}
                />

                <EditAccountClientType
                  account={account}
                  accountFormData={accountDetailsFormik}
                  creationMode={true}
                />
               
                
              </InfoContainer>
              <br/><br/><br/>
              <InfoContainer>
                <ActionGranter accesses={accessList.accounts.subscriptions}>
                  <InfoBlockTitle>
                    {t('account.accountSubscriptionLabels.subscriptionDetails')}
                  </InfoBlockTitle>
                  <EditSubscription
                    subscription={subscription}
                    setSubscription={setSubscription}
                    subscriptionPlansList={subscriptionPlansList}
                  />
                </ActionGranter>
                <CreateAccountNote
                  account={account}
                  setAccount={setAccount}
                />
              </InfoContainer>
            </DetailsBox>
            <FooterBlock>
              <div style={{width:'1140px', display:'flex', justifyContent:'space-between'}}>
                <Button
                  variant={'outlined'}
                  onClick={() => handleCancel()}
                >
                  {buttonLabels.cancel}
                </Button>
                <Button data-cy={'account-btn-submit'} variant={'contained'}  disabled={!accountDetailsFormik.isValid} type="submit">
                {buttonLabels.create}
                </Button>
              </div>
              
              
            </FooterBlock>
          </FrameContent>
        </form>
      </FrameContainer>
    </MainFrame>
  );
};


const DetailsBox = styled.div`
  display: flex;
  flex-direction: row;
`;


