import {AbstractBaseApi} from './AbstractBaseApi';
import {apiService, IApiOptions, IPageParameters, ISortParameters} from '../api/apiService';
import {IExternalSegment} from "../dataTypes/externalSegment";
import {BackendError} from "../../../types/backendError";


export class AudienceSegment extends AbstractBaseApi<IExternalSegment> {
    constructor(options?: IApiOptions) {
        super('externalSegments', options);
    }


    getExternalSegments() {
        return apiService
            .route(`externalSegments`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    vectorialSegmentSearch(props: {
        query: string,
        limit?: number,
        type?: 'keyword' | 'nearText' | 'hybrid' | 'nearVector' | 'strictKeywords'
        distance?: number
        filters?: {
            countryCode?: string[]
            providerCode?: string[]
        }
    }) {
        const {query, limit, type, distance, filters} = props;
        const key = "21eeec97-4a18-427c-a31a-74aff0b550e7"
        let queryParams: string = `?key=${key}&query=${query}`;
        if (limit) queryParams += `&limit=${limit}`;
        if (type) queryParams += `&type=${type}`;
        if (distance) queryParams += `&distance=${distance}`;
        if (filters?.countryCode) queryParams += `&countryCode=${filters.countryCode}`;
        if (filters?.providerCode) queryParams += `&providerCode=${filters.providerCode}`;

        if (query.length === 0) {
            return []
        }


        return fetch(`https://prod-v2.app.evorra.com/api/segments${queryParams}`).then(res => {
            return res.json().then(res => {
                if (res.results?.objects) {
                    return res.results.objects.map((segment: any) => {
                            return {...segment.properties, metaData: segment.metadata}
                        }
                    )
                }
                return []
            })

        })

    }

    searchExternalSegments(args: {
        kw?: string,
        countryCode?: string,
        providerCode?: string,
        page?: number,
        size?: number
        sort?: ISortParameters
    }) {
        const {kw, countryCode, providerCode, page, size, sort} = args;

        const pageParams: IPageParameters = {pageSize: size, pageNumber: page || 0};
        return apiService
            .route(`externalSegments/search`)
            .addParameters({kw: kw?.length ? kw : undefined, countryCode, providerCode})
            .page(pageParams)
            .sort(sort)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }
}