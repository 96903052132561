/**
 created by Mydde
 */

import {LinearProgress} from '@mui/material';
import {TEntityName} from '../../../application/entities/dataTypes';
import {IUseApiProgressOpt, useApiProgress} from '../../hooks/useApiProgress';

interface IApiProgressProps {
  entityName: TEntityName
  options?: IUseApiProgressOpt
}

export const ApiProgress = (props: IApiProgressProps) => {
  const {entityName, options} = props

  const progress = useApiProgress(entityName, options);

  return Boolean(progress?.length) ? <LinearProgress variant="query" data-cy={'api-progress-bar'}/> : null
}