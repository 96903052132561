import {createGlobalStyle} from 'styled-components';
import './fonts/stylesheet.css';


export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  #root {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
    white-space: pre-line;
  }

  .App {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100vw;
    overflow: hidden;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font: inherit;
    color: inherit;
  }

  .smallPadding {
    padding-left: 3px !important;
    padding-right: 0 !important;
    display: block;
    flex: 1;
  }

  .dateTextField {
    display: flex !important;
    padding: 0 !important;
  }

  .MuiInputAdornment-root {
    margin-left: 0 !important;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', 'OpenSanc', sans-serif;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 17px;
    color: #333333;
    background: white;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  ol, ul {
    list-style: none;
  }

  p, ul, li {
    margin: 0;
    padding: 0;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 0;
    background-color: transparent;

    &:focus {
      outline: 0;
      // box-shadow: none; // we need box shadow
    }
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;

    &:disabled {
      cursor: initial;
    }
  }

  a {
    color: blue;
  }

  .formUnder {
    display: inline-block;
    width: auto !important;
    min-width: auto;
  }

  .underInput {
    color: blue;
    height: auto;
    padding: 0;
    margin: 0;
    width: 100% !important;
    min-width: auto;
    border: none;
    font-size: inherit;
    box-shadow: none;
    border-bottom: 3px solid #ededed;
    border-radius: 3px;
  }

  .bold, .bold > * {
    font-weight: bolder !important;
  }

  .minCell > * {
    min-width: 130px !important;
  }

  .a {
    cursor: pointer;
    text-decoration: underline;
  }

  .a:hover {
    text-decoration: underline;
  }


  /* evorra styles */
  .color-tech-grey {
    color: #7587A3
  }

  .bg-tech-grey {
    background-color: #7587A3
  }

  .debug {
    outline: 1px solid red;
  }
  
  .cursor  {
    cursor:pointer
  }
`;
