import React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export type IUseSizeState = {
  bottom: number;
  top: number;
  left: number;
  right: number;
  width: number;
  height: number;
  x: number;
  y: number;
}
export const useSize = (target: any) => {
  const [size, setSize] = React.useState<IUseSizeState | any>();

  React.useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};