import {ThumbGrid} from "./ViewAudienceFeatureInterest";
import {FeatureLabel} from "../audienceFeature";
import moment from "moment/moment";

export const ViewDate = ({values}: { values: any }) => {
    const date = new Date(values);
    const newDate = moment(date).format("DD/MM/YYYY");
    return <ThumbGrid mode={'grouped'} grid={true} style={{marginLeft: '0.25rem'}}>
        <FeatureLabel data-cy="checklist-card-value">{newDate}</FeatureLabel>
    </ThumbGrid>
}