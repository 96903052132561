import {Box, Button, Typography} from "@mui/material";
import {useCreateAudience} from "components/hooks/useCreateAudience";
import {ShadowBox} from "components/templates/InfoBlock";
import {MainFrame} from "components/templates/MainFrame"
import {AppIcon} from "components/ui/AppIcon";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {useParams} from "react-router";
import {PageId} from "application/pages/pages.config";
import {useAudienceBuilderList} from "components/hooks/useAudienceBuilderList";
import {BuilderListItem} from "./types/audienceBuilderTypes";

export const AudienceBuilderSelection = () => {
    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    useAccountTracker(Number(accountId));
    const createAudience = useCreateAudience(Number(accountId))

    const builderList = useAudienceBuilderList()

    function handleCreateAudience(pageId: PageId) {
        createAudience.createAudience(pageId)
    }

    const BuilderBox = (props: { listItem: BuilderListItem }) => {
        const {listItem} = props;
        return <ShadowBox style={{
            padding: '20px',
            paddingBottom: '110px',
            width: '290px',
            backgroundColor: '#F8F9FB',
            position: 'relative'
        }} data-cy={'builder-' + listItem.id}>
            <div style={{width: '42px', marginInline: 'auto'}}>
                <AppIcon icon={listItem.icon} fontSize="medium" color="#3B4559" display="inline" chip={true}/>
            </div>
            <Typography variant="h5" style={{
                textAlign: 'center',
                marginTop: '1rem',
                marginBottom: '1rem'
            }}>{listItem.name} </Typography>

            <Typography variant="body1" className="color-tech-grey ">{listItem.description} </Typography>
            <div style={{
                textAlign: 'center',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '0',
                paddingBlock: '30px',
                background: '#fff',
                borderRadius: '0 0 10px 10px'
            }}>
                {listItem.warning && <p style={{
                    textAlign: 'center',
                    fontWeight: '900',
                    color: 'var(--color-scheme-error-alpha-low)',
                    padding: ' 0 0.5rem',
                    border: '1px solid var(--color-scheme-error-alpha-low)',
                    borderRadius: '15px',
                    display: 'inline-block',
                    position: 'absolute',
                    top: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: '#fff'
                }}>
                    {listItem.warning}
                </p>
                }
                <Button variant="contained" onClick={() => handleCreateAudience(listItem.pageId)}
                        sx={{maxWidth: '80%', height: 'auto', minHeight: '30px'}}>{listItem.buttonText}</Button>
            </div>
            {listItem.badge && <ShadowBox style={{
                position: 'absolute',
                fontWeight: '900',
                top: '-10px',
                right: '-10px',
                backgroundColor: 'var(--color-scheme-error-alpha-low)',
                color: '#fff',
                padding: '0.5rem 1rem'
            }}>
                {listItem.badge}
            </ShadowBox>
            }
        </ShadowBox>
    }

    return (
        <MainFrame
            frameId="audience-builder-selection"
            scrollable={true}
        >
            <Box style={{height: '100%', width: '100%', position: 'relative', padding: '1rem', overflowY: 'auto'}}>
                <Typography variant="h2"
                            style={{marginTop: '2rem', marginBottom: '3rem', textAlign: 'center', fontSize: '20px'}}>Select
                    your Audience Builder</Typography>
                {/* <Typography variant="body1" style={{marginBottom:'2rem', textAlign:'center'}}>Select the audience builder that best fits your campaign goals.</Typography> */}
                <Box style={{display: 'flex', gap: '2rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        builderList.map((item, index) => {
                            return <BuilderBox key={item.id} listItem={item}/>
                        })
                    }
                </Box>
            </Box>
        </MainFrame>
    )
}