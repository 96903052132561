import {useTranslation} from 'react-i18next';
import * as React from 'react';
import styled from 'styled-components/macro';
import {ICompanyUsers} from '../../../types';
import {DetailBlock, InputFieldLabel, TextFieldBlock} from '../../../components/templates/InfoBlock';


interface IUserDetailsProps {
  userDetails: Partial<ICompanyUsers>;
}

export const UserViewDetails: React.FC<IUserDetailsProps> = (props) => {
  
  const {userDetails} = props;
  const {t}         = useTranslation();
  
  return (
    <UserDetailsBlock>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.title')}
        </InputFieldLabel>
        <TextFieldBlock>
          {`${userDetails.title}`}
        </TextFieldBlock>
      </div>
      <div></div>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.firstName')}
        </InputFieldLabel>
        <TextFieldBlock>
          {userDetails.firstName}
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.surname')}
        </InputFieldLabel>
        <TextFieldBlock>
          {userDetails.lastName}
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.email')}
        </InputFieldLabel>
        <TextFieldBlock>
          {userDetails.email}
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.jobTitle')}
        </InputFieldLabel>
        <TextFieldBlock>
          {userDetails.jobTitle}
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel>
          {t('account.userDetails.phone')}
        </InputFieldLabel>
        <TextFieldBlock>
          {userDetails.phone}
        </TextFieldBlock>
      </div>
    </UserDetailsBlock>
  );
};

const UserDetailsBlock = styled(DetailBlock)`
  display: grid;
  min-width: 556px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  grid-row-gap: 5px;
`;