import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3'
import {fromCognitoIdentityPool} from '@aws-sdk/credential-providers'
import {authService} from "../application/services/auth.service";
import {awsRegion, centralAccountId, identityPoolId, userPoolId} from "../_configuration/amplify.config";
import {getCurrentEnv} from "../application/utils/env.utils";


const cognitoLogin = "cognito-idp." + awsRegion + ".amazonaws.com/" + userPoolId

let env = getCurrentEnv()


const Credentials = async (brandId: number) => {
    let session;
    try {
        session = await authService.getCurrentSession();
    } catch (er) {
        console.log('error', er)
    }

    if (!session) {
        throw new Error('No session found for AWS SDK Usage')
    }
    const idToken = session.getIdToken().getJwtToken()

    //todo: remove console log after testing

    console.log('**** AWS SDK informations ****')
    console.log('Environment: ', env)
    console.log('Brand ID: ', brandId)
    console.log('AWS Region: ', awsRegion)
    console.log('Identity Pool ID: ', identityPoolId)
    console.log('Cognito Login: ', cognitoLogin)
    console.log('Central Account ID: ', centralAccountId)
    console.log('customRoleArn: ', `arn:aws:iam::${centralAccountId}:role/${env}-brand-${brandId}-role`)


    return fromCognitoIdentityPool({
        clientConfig: {region: awsRegion},
        identityPoolId: identityPoolId,
        logins: {
            [cognitoLogin]: idToken,
        },
        customRoleArn: `arn:aws:iam::${centralAccountId}:role/${env}-brand-${brandId}-role`
    })
}

const Client = async (brandId: number) => {
    const credentials = await Credentials(brandId)
    return new S3Client({
        region: awsRegion,
        credentials: credentials,
    })
}

export const uploadFile = async (brandId: number, file: File, fileName: string, bucket: string) => {
    const client = await Client(brandId)
    const command = new PutObjectCommand({
        Bucket: bucket,
        Key: fileName,
        Body: file,
    })

    return await client.send(command)
}


