import React, {useEffect, useState} from 'react';

import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ICompanyUsers} from '../../../types';
import styled from '@emotion/styled';
import {useParams} from 'react-router-dom';
import {dataObject} from '../../../application/entities/dataObjects/DataObjectClass';
import {ColumnsMiddle, H4} from '../../../components/ui/AppElements';
import {ButtonBlockEditUser} from '../Update/ButtonBlockEditUser';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {MainFrame} from '../../../components/templates/MainFrame';
import {InfoBlockTitle} from '../../../components/templates/InfoBlock';
import {EUserGrade} from '../../../application/utils/granter.utils';
import {useUserQuery} from '../../../components/hooks/data/useUserQuery';
import {GlobalAvatar} from '../../../components/ui/GlobalAvatar';


export const UserView: React.FC = () => {

    const params = useParams<{ userId: string }>() as { userId: string };
    const userId = params.userId;
    const {t} = useTranslation();
    const emptyUserObject = dataObject.getDataObject('companyUsers');

    const [userData, setUserData] = useState<Partial<ICompanyUsers>>(emptyUserObject);

    const userQuery = useUserQuery({userId: +userId});

    useEffect(() => {
        if (userQuery.data) {
            setUserData(userQuery.data);
        }
    }, [userQuery.data]);

    const headerContent = <ColumnsMiddle>
        <Box sx={{px: 2}}>
            <GlobalAvatar
                user={userData}
                isPending={userData.status !== 'ACTIVATED'}
                size={'34px'}
                fontSize={'18px'}
            />
        </Box>
        <Box>
            <H4>{userData.firstName} {userData.lastName}</H4>
            <p>{`User ID#: ${userData.id}`}</p>
            <p>{userData?.account?.companyName}, {userData?.account?.country}`</p>
        </Box>
    </ColumnsMiddle>;

    const headerButtons = <>
        <ButtonBlockEditUser
            userDetails={userData}
            setUserDetails={setUserData}
        />
    </>;

    // @ts-ignore
    const showFrame = AUTHUser.isEvorraAdmin() || AUTHUser.accountId === userData.accountId || AUTHUser.userId === userData.id || AUTHUser.hasRole(EUserGrade.ADMINISTRATOR);

    return (
        <MainFrame
            restrict404={!showFrame}
            loading={userQuery?.isLoading}
            backendError={userQuery?.error}
            frameId={'user-edit'}
            headerProps={{
                hideHeaderDefault: true,
                hideHeaderIcon: true,
                headerButtons: headerButtons,
            }}
            headerContent={headerContent}
        >
            <Content>
                <DetailsBox>
                    <Box>
                        <InfoBlockTitle>
                            {t('account.userDetails.userDetails')}
                        </InfoBlockTitle>

                    </Box>
                </DetailsBox>
            </Content>
        </MainFrame>
    )
        ;
};


const Content = styled.div`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    padding: 0 20px 0px 20px;
    height: 100%;
`;

const DetailsBox = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
`;
