import {TreeViewInterest} from "components/form/EntityTreeView/TreeViewInterest";
import _ from "lodash";
import {AppIcon} from "components/ui/AppIcon";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {jsonDecodeArray, jsonifyArray} from "pages/AudienceBuilder/audienceBuilderUtils";
import {useAppToast} from "../../../../components/live/AppToast";
import {useEffect} from "react";
import {Box, Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export type InterestFeaturesMenuProps = {
    featureCode: string;
};

export const InterestFeaturesMenu = (props: InterestFeaturesMenuProps) => {
    const featureCode = props.featureCode;
    const audienceAccountId = useAudienceBuilderStore((st) => st.audience?.account?.id);
    const appToast = useAppToast();
    const audienceActions = useAudienceBuilderStore.getState().actions;
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];


    const {interestIncludes, interestExcludes} = useAudienceBuilderStore((st) => {
        const filterValues = st.filtersValues.filter((filter: any) => filter.code === featureCode)[0];
        let interestIncludes = filterValues.selected?.filter(select => select.notOperator === false).sort((a: any, b: any) => a?.featureId - b?.featureId);
        if (!interestIncludes?.length) {
            interestIncludes = [
                {
                    featureId: 1,
                    linkTo: 0,
                    selected: [],
                    notOperator: false
                }
            ]
        }
        let interestExcludes = filterValues.selected?.filter(select => select.notOperator === true).sort((a: any, b: any) => a?.featureId - b?.featureId);
        return {interestIncludes, interestExcludes}
    });


    const evFeature = useAudienceBuilderStore(
        (st) => st.EvFeatures.find((feature) => feature.code === featureCode),
        (old, ne) => _.isEqual(old, ne)
    );

    const featureConf = evFeature?.featureValues ? jsonDecodeArray(evFeature?.featureValues)[0] : null;

    const interestSelects =
        filtersValues.selected.length > 0
            ? filtersValues.selected.sort((a: any, b: any) => a?.linkTo - b?.linkTo)
            : [
                {
                    featureId: null,
                    linkTo: null,
                    selected: [],
                    notOperator: false
                },
            ];


    //if no interest, remove intent
    useEffect(() => {
        if (filtersValues.selected?.length === 0) {
            audienceActions.deleteFeature('INTENT')
        }
    }, [filtersValues.selected]);

    const addSelect = (notOperator?: boolean) => {
        notOperator = notOperator === undefined ? false : notOperator
        audienceActions.addInterestSelect(interestSelects[interestSelects.length - 1]?.featureId, notOperator);
    };

    const removeSelect = (id: number | null) => {
        audienceActions.removeInterestSelect(id);
    };

    const handleChangeIncludes = (item: any, selectId?: number, selectIdx?: number) => {
        handleChange({item, selectId: selectId ?? null, selectIdx: selectIdx ?? 0, notOperator: false})
    }
    const handleChangeExcludes = (item: any, selectId?: number, selectIdx?: number) => {
        handleChange({item, selectId: selectId ?? null, selectIdx: selectIdx ?? 0, notOperator: true})
    }

    const handleChange = ({item, selectId, selectIdx, notOperator}: {
        item: any,
        selectId: number | null,
        selectIdx: number,
        notOperator: boolean
    }) => {

        const newValues = item
            .map((item: any) => {
                return {
                    id: item?.id ?? item[featureConf.idField],
                    notOperator: notOperator,
                    [featureConf.defaultField]: item[featureConf.defaultField],
                    [featureConf.hierarchyField]: item[featureConf.hierarchyField],
                };
            })
            .flat(1);


        //remove all selection in the first select when there is more than one select
        if (selectIdx === 0 && !notOperator && selectId && (interestIncludes.length + interestExcludes.length > 1) && (jsonifyArray(newValues)?.length === 0)) {
            appToast.open({
                toastId: 'globalToast',
                severity: 'error',
                message: 'You can\'t remove all interests here because you have an "AND" condition.',
            });
            audienceActions.updateAndOperatorFeature(selectId, jsonifyArray(filtersValues.selected[0].selected), notOperator)
            return
        }

        //first include select add edit when unique
        if (selectIdx === 0 && !notOperator) {
            if (interestIncludes.length === 1) {
                audienceActions
                    .insertFeatureValues(evFeature!, jsonifyArray(newValues))
                    .then(() => {
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            } else if (selectId) {
                audienceActions
                    .updateAndOperatorFeature(selectId, jsonifyArray(newValues), notOperator)
                    .then(() => {
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        }
        //first exclude select add
        if (selectIdx === 0 && notOperator) {
            if (!selectId) {
                audienceActions
                    .createAndOperatorFeature(evFeature!.id, jsonifyArray(newValues), notOperator)
                    .then(() => {
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            } else if (newValues.length === 0) {
                audienceActions.removeInterestSelect(selectId)
            } else {
                audienceActions
                    .updateAndOperatorFeature(selectId, jsonifyArray(newValues), notOperator)
                    .then(() => {
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        }

        //add new interest select
        if (selectIdx > 0) {
            if (!selectId) {
                audienceActions
                    .createAndOperatorFeature(evFeature!.id, jsonifyArray(newValues), notOperator)
                    .then(() => {
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            } else {
                if (newValues.length === 0) {
                    audienceActions.removeInterestSelect(selectId)
                } else {
                    audienceActions
                        .updateAndOperatorFeature(selectId, jsonifyArray(newValues), notOperator)
                        .then(() => {
                        })
                        .catch((e) => {
                            console.error(e);
                        });

                }
            }
        }


    };

    const handleMouseEnter = (id: any) => {
        if (interestSelects.length > 1) audienceActions.setHoverSelectId(id?.toString() ?? null);
    };

    const handleMouseLeave = () => {
        if (interestSelects.length > 1) audienceActions.setHoverSelectId(null);
    };

    const onToggleExpand = (path: string[], featureCode: string) => {
        console.log('toggle', path, featureCode)
        audienceActions.toggleTreeItem(featureCode, path);
    };


    const DisplayInterestSelects = (interestSelectsToDisplay: any[], notOperator: boolean) => {
        return <>
            {interestSelectsToDisplay?.map((interestSelect: any, idx: any) => {
                return (
                    <div key={idx} style={{marginBottom: "20px"}}>
                        {interestSelectsToDisplay.length > 0 && idx !== 0 && (
                            <div
                                style={{
                                    marginInline: "auto",
                                    width: "70px",
                                    marginBlock: "10px",
                                    marginTop: "-10px",
                                    display: "block",
                                    backgroundColor: "#02C697",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontWeight: "800",
                                    borderRadius: "15px",
                                }}
                            >
                                AND
                            </div>
                        )}
                        <div
                            style={{display: "flex", alignItems: "center", gap: "7px"}}
                            onMouseEnter={() => handleMouseEnter(interestSelect?.featureId)}
                            onMouseLeave={handleMouseLeave}
                            data-cy="interest-select-id"
                            data-cy-value={interestSelect?.featureId ?? 0}
                        >

                            <TreeViewInterest
                                entityName={featureConf.entity.replace("{accountId}", `${audienceAccountId}`)}
                                entityList={filtersValues.selectable}
                                entityTextName={featureConf?.label ?? featureConf.entity}
                                defaultField={featureConf.defaultField ?? "name"}
                                hierarchyField={featureConf.hierarchyField}
                                onChange={notOperator ? handleChangeExcludes : handleChangeIncludes}
                                selectId={interestSelect?.featureId ?? null}
                                selectIdx={idx}
                                selectedEntityItems={interestSelect.selected ?? []}
                                dataCy={`interest-select-${idx}`}
                                onToggleExpand={(path) => onToggleExpand(path, "interest" + interestSelect.featureId)}
                            />
                            {(idx > 0 || interestSelect.notOperator === true) && (
                                <div
                                    data-cy={`remove-interest-select-${idx}`}
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        removeSelect(interestSelect?.featureId ?? null);
                                    }}
                                >
                                    <AppIcon icon="Trash" fontSize="small" color="red"/>
                                </div>
                            )}
                        </div>

                    </div>
                );
            })}
        </>
    }


    return (
        <div>
            {interestIncludes && DisplayInterestSelects(interestIncludes, false)}


            {(interestExcludes.length) > 0 && <>
                <div
                    style={{
                        marginInline: "auto",
                        width: "70px",
                        marginBlock: "10px",
                        marginTop: "-10px",
                        display: "block",
                        backgroundColor: "#e15959",
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: "800",
                        borderRadius: "15px",
                    }}
                >
                    AND NOT
                </div>


            </>}

            {interestExcludes.length > 0 && DisplayInterestSelects(interestExcludes, true)}


            {interestIncludes[0].selected.length > 0 &&
                <>
                    <Box sx={{display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'start'}}>
                        {!(interestExcludes.length && interestExcludes[0]?.selected?.length === 0) &&
                            <Button data-cy="NewAndCondition" variant="outlined" size="small"
                                    sx={{
                                        color: '#02C697',
                                        borderColor: '#02C697 !important',
                                        '&:hover': {boxShadow: '0px 4px 9px rgba(2, 198, 151, 0.3) !important;'}
                                    }}
                                    onClick={() => addSelect()}
                                    disabled={!!(interestIncludes.length && interestIncludes[interestIncludes.length - 1]?.selected?.length === 0)}
                                    startIcon={<AddCircleOutlineIcon/>}>
                                AND
                            </Button>
                        }

                        {!interestExcludes?.length && <>
                            <Button data-cy="NewNotCondition" variant="outlined" size="small"
                                    disabled={!(interestIncludes.length && interestIncludes[interestIncludes.length - 1]?.selected?.length)}
                                    sx={{
                                        color: '#e15959',
                                        borderColor: '#e15959 !important',
                                        '&:hover': {boxShadow: '0px 4px 9px rgba(255, 89, 89, 0.3) !important;'}
                                    }}
                                    onClick={() => addSelect(true)}
                                    startIcon={<RemoveCircleOutlineIcon/>}>
                                AND NOT
                            </Button>

                        </>}
                    </Box>
                </>
            }


        </div>
    );
};
