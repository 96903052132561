import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {editAccountValidationSchema} from "../../../application/validation";
import {buttonLabels, ColorButton, GhostButton} from "../../../components/form/Buttons";
import {useAppToast} from "../../../components/live/AppToast";
import {ICompanyUsers} from "../../../types";
import {EditAccountClientType} from "../Form/EditAccountClientType";
import {EditAccountAddress} from "../Form/EditAccountAddress";
import {EditAccountDetails} from "../Form/EditAccountDetails";
import {EditSubscription} from "../Form/EditSubscription";
import {EditAccountConfParameters} from "../Form/EditAccountConfParameters";
import {EditAccountNotes} from "../Form/EditAccountNotes";
import {objectApi} from "../../../application/entities/dataApi";
import {dataObject} from "../../../application/entities/dataObjects/DataObjectClass";
import {IAccounts} from "../../../application/entities/dataTypes/accounts";
import {MainFrame} from "../../../components/templates/MainFrame";
import {Box} from "@mui/material";
import {ColumnsWrap, Stacker} from "components/ui/AppElements";
import {ISubscriptions} from "../../../application/entities/dataTypes/subscriptions";
import {ButtonEditAccount} from "./ButtonEditAccount";
import {AccountTitle, StatusDot, StatusItem} from "../zOld/shared";
import {ActionGranter} from "../../../components/features/ActionGranter";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {accessList} from "../../../application/access/access";
import {
    FooterBlock,
    FrameContainer,
    FrameContent,
    InfoBlockTitle,
    InfoContainer
} from "../../../components/templates/InfoBlock";
import {ViewAccountType} from "../View/ViewAccountType";
import {useAuthActions} from "../../../redux/slices/auth.slices";
import {BackendError} from "../../../types/backendError";
import {PageId} from "../../../application/pages/pages.config";
import {pageUtils} from "../../../application/pages/pages.utils";
import {useAccountQuery} from "../../../components/hooks/data/useAccountQuery";
import {useSubscriptionPlansQuery} from "../../../components/hooks/data/useSubscriptionPlanQuery";
import {useAdminPage} from "components/hooks/useAdminPage";
import {ProviderEditAccountConfParameters} from "../Form/ProviderEditAccountConfParameters";

export const EditAccount: React.FC = () => {
    const navigate = useNavigate();
    useAdminPage();
    const {accountId} = useParams<Record<string, string>>() as { accountId: number };
    const appToast = useAppToast();
    const authActions = useAuthActions();

    const emptyAccountObject = dataObject.getDataObject("accounts");

    const accountsApi = new objectApi.accounts();
    const subscriptionsApi = new objectApi.subscriptions();
    const subscriptionPlanApi = new objectApi.subscriptionPlans();

    const [account, setAccount] = useState<Partial<IAccounts>>(emptyAccountObject);
    const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);

    const [accountUsers, setAccountUsers] = useState<Partial<ICompanyUsers>[]>([]);
    const [accountIndustry, setAccountIndustry] = useState<any>([]);
    const [subscription, setSubscription] = useState<Partial<ISubscriptions>>({});

    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingError, setLoadingError] = React.useState<BackendError>();

    const accountQuery = useAccountQuery(+accountId);
    const subscriptionPlanQuery = useSubscriptionPlansQuery();

    const {t} = useTranslation();

    const accountDetailsFormik = useFormik({
        initialValues: {...account, companyUrlList: ""},
        validationSchema: editAccountValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            const castValues = editAccountValidationSchema.cast(values);
            handleEditAccount({
                ...castValues,
                country: account.country,
            });
        },
    });

    useEffect(() => {
        if (accountQuery.data) {
            fetchSubscriptionsPlans(+accountId);
            setAccount(accountQuery.data);
            if (accountQuery.data.industries) setAccountIndustry(accountQuery.data.industries);
            if (accountQuery.data.companyUsers) setAccountUsers(accountQuery.data.companyUsers);
            if (accountQuery.data.subscription) {
                setSubscription({
                    ...accountQuery.data.subscription,
                    subscriptionPlanId: accountQuery.data.subscriptionPlan.id,
                });
            }
            accountDetailsFormik.setValues({...accountQuery.data});
            setLoading(false);
        }
    }, [accountQuery.data]);

    const fetchSubscriptionsPlans = async (accId: number) => {
        subscriptionPlanApi.get({output: {part: "EMBEDDED"}}).then((subscriptionPlans) => {
            if (subscriptionPlans) setSubscriptionPlansList(subscriptionPlans);
        });
    };

    const getPageInfo = () => {
        return {
            title: account.companyName,
            companyName: account.companyName,
            description1: `Company ID#: ${account.id}`,
            description2: account.companyName && account.country && `${account.companyName}, ${account.country}`,
            activated: account.activated,
        };
    };

    const handleEditAccount = async (values: any & Partial<IAccounts>) => {
        if (values && values.id) {
            const dtaAcc = {...values};
            delete dtaAcc.industryIds;
            delete dtaAcc.companyUsers;
            delete dtaAcc.industries;
            delete dtaAcc.subscription;
            delete dtaAcc.notes;
            delete dtaAcc.affinities;

            delete dtaAcc.vault;

            try {
                // update account
                const dataAcc = await accountsApi.update(values.id, dtaAcc as any);
                // refresh auth if self account
                if (dataAcc && dataAcc.id === AUTHUser.accountId) {
                    authActions.doRefreshUser();
                }

                // update industries
                if (account.industryIds) await accountsApi.updateIndustries(values.id, account.industryIds);
                // update subscription

                if (subscription.id) await subscriptionsApi.update(subscription.id, subscription);

                appToast.open({
                    toastId: "manageAccount",
                    severity: "success",
                    message: t("message.account.updatedSuccess"),
                });

                accountQuery.refetch();
            } catch (err: any) {
                const message = err?.response?.data?.error?.message;
                appToast.open({
                    toastId: "manageAccount",
                    severity: "error",
                    message: message ?? t("labels.error"),
                });
            }
        }
    };

    const headerProperties = (
        <>
            <AccountTitle>{t("account.generalTitles.status")}</AccountTitle>
            <Box sx={{pt: 1}}>
                <StatusItem active={account?.activated} data-cy={"account-status"}>
                    <StatusDot/> {account?.activated ? t("message.account.active") : t("message.account.inactive")}
                </StatusItem>
            </Box>
        </>
    );

    const headerButtons = <ButtonEditAccount account={account as IAccounts} setAccount={setAccount}/>;

    const handleCancel = () => {
        navigate(`${pageUtils.getPagePathById(PageId.adminAccountView, {accountId: accountId})}`);
    };

    // @ts-ignore
    const showFrame = AUTHUser.isEvorraAdmin() || AUTHUser.accountId === +accountId; // integrate in
    // MainFrameGrant
    return (
        <MainFrame
            restrict404={!showFrame}
            frameId={"edit-accounts"}
            loading={loading}
            backendError={loadingError}
            headerProps={{
                frameInfo: getPageInfo(),
                headerButtons,
                headerProperties,
            }}
        >
            {account && (
                <FrameContainer>
                    <form onSubmit={accountDetailsFormik.handleSubmit}>
                        <FrameContent>
                            <Stacker>
                                <ColumnsWrap>
                                    <InfoContainer>
                                        <InfoBlockTitle>{t("account.accountDetailsLabels.accountDetails")}</InfoBlockTitle>
                                        <EditAccountDetails
                                            accountDetailsFormik={accountDetailsFormik}
                                            account={account}
                                            setAccount={setAccount}
                                            accountIndustry={accountIndustry}
                                        />
                                        <EditAccountAddress accountDetailsFormik={accountDetailsFormik}
                                                            account={account} setAccount={setAccount}/>
                                        {AUTHUser.isEvorraAdmin() ? (
                                            <EditAccountClientType account={account}
                                                                   accountFormData={accountDetailsFormik}/>
                                        ) : (
                                            <ViewAccountType account={account}/>
                                        )}
                                        {/* <AccountUsers
                                  account={account}
                                  accountUsers={accountUsers}
                              /> */}
                                    </InfoContainer>

                                    <InfoContainer>
                                        <ActionGranter accesses={accessList.accounts.subscriptions}>
                                            <InfoBlockTitle>Configuration</InfoBlockTitle>
                                            <EditSubscription subscription={subscription}
                                                              setSubscription={setSubscription}
                                                              subscriptionPlansList={subscriptionPlansList}/>
                                        </ActionGranter>
                                        {account.dataProvider === false ?
                                            <EditAccountConfParameters account={account}/>
                                            :
                                            <ProviderEditAccountConfParameters account={account}/>}

                                        <EditAccountNotes account={account} setAccount={setAccount}/>
                                    </InfoContainer>
                                </ColumnsWrap>
                                <FooterBlock>
                                    <div style={{width: "1140px", display: "flex", justifyContent: "space-between"}}>
                                        <GhostButton onClick={() => handleCancel()} variant="contained" color="primary">
                                            {buttonLabels.cancel}
                                        </GhostButton>
                                        <ColorButton variant="contained" color="primary" type="submit"
                                                     data-cy={"edit-save"} disabled={!accountDetailsFormik.isValid}>
                                            {" "}
                                            {buttonLabels.save}
                                        </ColorButton>
                                    </div>
                                </FooterBlock>
                            </Stacker>
                        </FrameContent>
                    </form>
                </FrameContainer>
            )}
        </MainFrame>
    );
};
