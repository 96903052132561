import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import {BackendError} from '../../../types/backendError';

interface AudiencesQueryProps {
  userId?: number;
}


export const useUserQuery = (props: AudiencesQueryProps) => {
  
  const {userId}     = props;
  const companyUsers = new objectApi.companyUsers();
  
  const {isLoading, error, data, refetch, remove} = useQuery<any, BackendError>(['user', userId], () => {
    if (userId) return companyUsers.byId(userId).then(res => res);
    
  }, {
    // cacheTime  : 60000,
    enabled: Boolean(userId),
    // initialData: emptyUserObject
  });
  
  return {isLoading, error, data, refetch, remove};
};