import { PageId } from "application/pages/pages.config";
import { AUTHUser } from "application/utils/AuthUser";
import { useAccountConfParameters } from "pages/Agencies/useAccountConfParameters";
import { BuilderListItem } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { useFeatureFlag } from "stores/featureFlags.store";

export const useAudienceBuilderList = () => {
  const featureFlags = useFeatureFlag((st) => st.featureFlags);
  const accountId = AUTHUser.currentBrandId;

  const accountConfParameters = useAccountConfParameters(accountId || AUTHUser.accountId);

  const builderList: BuilderListItem[] = [
    {
      id: 1,
      name: "Interest Audiences",
      icon: "InterestBuilder",
      description: "Build an audience based on interests and location.",
      pageId: PageId.audienceBuilderFormInterest,
      buttonText: "Create Interest Audience",
    },
    {
      id: 2,
      name: "Advanced Audiences",
      icon: "AdvancedBuilder",
      description: "Build an audience based on industries, location, interests, and more.",
      pageId: PageId.audienceBuilderForm,
      buttonText: "Create Advanced Audience",
    },
    {
      id: 3,
      name: "Travel Audiences",
      icon: "Travel",
      description: "Get traveler profiles based on their travel intent (Dates, destination...)",
      pageId: PageId.audienceBuilderFormTravel,
      buttonText: "Create Travel Audience",
    },
  ];

  if (featureFlags?.automotive_builder) {
    builderList.push({
      id: 4,
      name: "U.S. Auto Audiences",
      icon: "Car",
      description: "Get profiles interested by automotive brands, models",
      warning: "US audience only",
      pageId: PageId.audienceBuilderFormAutomotive,
      buttonText: "Create Automotive Audience",
    });
  }
  if (accountConfParameters.data?.enable_first_party_data) {
    builderList.push({
      id: 5,
      name: "My Data Audiences",
      icon: "MyListsIcon",
      description: "Build audience with your Data",
      pageId: PageId.audienceBuilderFirstPartyData,
      buttonText: "Create My Data Audience",
    });
    if (featureFlags?.cpg_builder) {
      builderList.push({
        id: 6,
        name: "CPG Audience",
        icon: "ShoppingCart",
        description: "Build audience with Consumer Packaged Goods Data",
        pageId: PageId.audienceBuilderCPG,
        buttonText: "Create CPG Audience",
      });
    }
  }

  return builderList;
};
