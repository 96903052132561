const adSizeFromMagniteNetworkCatch = [
  {
    id: 1,
    name: "Banner",
    format: "Display",
    dimensions: {
      width: 468,
      height: 60,
    },
  },
  {
    id: 2,
    name: "Leaderboard",
    format: "Display",
    dimensions: {
      width: 728,
      height: 90,
    },
  },
  {
    id: 3,
    name: "Half Banner",
    format: "Display",
    dimensions: {
      width: 234,
      height: 60,
    },
  },
  {
    id: 4,
    name: "Micro Bar",
    format: "Display",
    dimensions: {
      width: 88,
      height: 31,
    },
  },
  {
    id: 5,
    name: "Button 1",
    format: "Display",
    dimensions: {
      width: 120,
      height: 90,
    },
  },
  {
    id: 6,
    name: "Button 2",
    format: "Display",
    dimensions: {
      width: 120,
      height: 60,
    },
  },
  {
    id: 7,
    name: "Button",
    format: "Display",
    dimensions: {
      width: 125,
      height: 125,
    },
  },
  {
    id: 8,
    name: "Skyscraper",
    format: "Display",
    dimensions: {
      width: 120,
      height: 600,
    },
  },
  {
    id: 9,
    name: "Wide Skyscraper",
    format: "Display",
    dimensions: {
      width: 160,
      height: 600,
    },
  },
  {
    id: 10,
    name: "Half Page Ad",
    format: "Display",
    dimensions: {
      width: 300,
      height: 600,
    },
  },
  {
    id: 12,
    name: "Vertical Banner",
    format: "Display",
    dimensions: {
      width: 120,
      height: 240,
    },
  },
  {
    id: 13,
    name: "Small Square",
    format: "Display",
    dimensions: {
      width: 200,
      height: 200,
    },
  },
  {
    id: 14,
    name: "Square",
    format: "Display",
    dimensions: {
      width: 250,
      height: 250,
    },
  },
  {
    id: 15,
    name: "Medium Rectangle",
    format: "Display",
    dimensions: {
      width: 300,
      height: 250,
    },
  },
  {
    id: 16,
    name: "Large Rectangle",
    format: "Display",
    dimensions: {
      width: 336,
      height: 280,
    },
  },
  {
    id: 17,
    name: "Vertical Rectangle",
    format: "Display",
    dimensions: {
      width: 240,
      height: 400,
    },
  },
  {
    id: 18,
    name: "Rectangle",
    format: "Display",
    dimensions: {
      width: 180,
      height: 150,
    },
  },
  {
    id: 19,
    name: "3:1 Rectangle",
    format: "Display",
    dimensions: {
      width: 300,
      height: 100,
    },
  },
  {
    id: 24,
    name: "Vertical Links - 160",
    format: "Display",
    dimensions: {
      width: 160,
      height: 90,
    },
  },
  {
    id: 25,
    name: "Vertical Links - 180",
    format: "Display",
    dimensions: {
      width: 180,
      height: 90,
    },
  },
  {
    id: 26,
    name: "Vertical Links - 200",
    format: "Display",
    dimensions: {
      width: 200,
      height: 90,
    },
  },
  {
    id: 27,
    name: "Horizontal Links - 468",
    format: "Display",
    dimensions: {
      width: 468,
      height: 15,
    },
  },
  {
    id: 28,
    name: "Horizontal Links - 728",
    format: "Display",
    dimensions: {
      width: 728,
      height: 15,
    },
  },
  {
    id: 29,
    name: "Large Half Page Ad",
    format: "Display",
    dimensions: {
      width: 300,
      height: 850,
    },
  },
  {
    id: 31,
    name: "Nordic Panorama",
    format: "Display",
    dimensions: {
      width: 980,
      height: 120,
    },
  },
  {
    id: 32,
    name: "Swedish Stortavla",
    format: "Display",
    dimensions: {
      width: 250,
      height: 360,
    },
  },
  {
    id: 33,
    name: "Norwegian Skyscraper",
    format: "Display",
    dimensions: {
      width: 180,
      height: 500,
    },
  },
  {
    id: 35,
    name: "Norwegian Top Banner",
    format: "Display",
    dimensions: {
      width: 980,
      height: 150,
    },
  },
  {
    id: 36,
    name: "Nordic Billboard",
    format: "Display",
    dimensions: {
      width: 140,
      height: 350,
    },
  },
  {
    id: 37,
    name: "Nordic Giant Rectangle",
    format: "Display",
    dimensions: {
      width: 468,
      height: 400,
    },
  },
  {
    id: 38,
    name: "Danish Megaboard",
    format: "Display",
    dimensions: {
      width: 930,
      height: 180,
    },
  },
  {
    id: 39,
    name: "Polish Billboard",
    format: "Display",
    dimensions: {
      width: 750,
      height: 100,
    },
  },
  {
    id: 40,
    name: "Polish Double Billboard",
    format: "Display",
    dimensions: {
      width: 750,
      height: 200,
    },
  },
  {
    id: 41,
    name: "Polish Triple Billboard",
    format: "Display",
    dimensions: {
      width: 750,
      height: 300,
    },
  },
  {
    id: 42,
    name: "Skin",
    format: "Display",
    dimensions: {
      width: 2,
      height: 4,
    },
  },
  {
    id: 43,
    name: "Mobile Banner 1",
    format: "Display",
    dimensions: {
      width: 320,
      height: 50,
    },
  },
  {
    id: 44,
    name: "Mobile Banner 2",
    format: "Display",
    dimensions: {
      width: 300,
      height: 50,
    },
  },
  {
    id: 45,
    name: "Mobile Banner 3",
    format: "Display",
    dimensions: {
      width: 480,
      height: 75,
    },
  },
  {
    id: 46,
    name: "Mobile Banner 4",
    format: "Display",
    dimensions: {
      width: 480,
      height: 60,
    },
  },
  {
    id: 47,
    name: "Mobile Banner 5",
    format: "Display",
    dimensions: {
      width: 240,
      height: 37,
    },
  },
  {
    id: 48,
    name: "Square 300",
    format: "Display",
    dimensions: {
      width: 300,
      height: 300,
    },
  },
  {
    id: 49,
    name: "Square 336 (deprecated)",
    format: "Display",
    dimensions: {
      width: 336,
      height: 280,
    },
  },
  {
    id: 50,
    name: "Tablet Landscape Banner",
    format: "Display",
    dimensions: {
      width: 1024,
      height: 90,
    },
  },
  {
    id: 51,
    name: "Tablet Portrait Banner",
    format: "Display",
    dimensions: {
      width: 768,
      height: 90,
    },
  },
  {
    id: 52,
    name: "Tablet Full Page (Status Bar)",
    format: "Display",
    dimensions: {
      width: 1004,
      height: 768,
    },
  },
  {
    id: 53,
    name: "Tablet Interstitial Landscape",
    format: "Display",
    dimensions: {
      width: 1024,
      height: 768,
    },
  },
  {
    id: 54,
    name: "Portrait",
    format: "Display",
    dimensions: {
      width: 300,
      height: 1050,
    },
  },
  {
    id: 55,
    name: "Pushdown",
    format: "Display",
    dimensions: {
      width: 970,
      height: 90,
    },
  },
  {
    id: 56,
    name: "Super Leaderboard",
    format: "Display",
    dimensions: {
      width: 900,
      height: 250,
    },
  },
  {
    id: 57,
    name: "IAB Billboard",
    format: "Display",
    dimensions: {
      width: 970,
      height: 250,
    },
  },
  {
    id: 58,
    name: "Leaderboard 2",
    format: "Display",
    dimensions: {
      width: 1000,
      height: 90,
    },
  },
  {
    id: 59,
    name: "Mobile Banner 6",
    format: "Display",
    dimensions: {
      width: 320,
      height: 80,
    },
  },
  {
    id: 60,
    name: "Mobile Banner 7",
    format: "Display",
    dimensions: {
      width: 320,
      height: 150,
    },
  },
  {
    id: 61,
    name: "Full Page",
    format: "Display",
    dimensions: {
      width: 1000,
      height: 1000,
    },
  },
  {
    id: 62,
    name: "Leaderboard 3",
    format: "Display",
    dimensions: {
      width: 980,
      height: 50,
    },
  },
  {
    id: 63,
    name: "Leaderboard 4",
    format: "Display",
    dimensions: {
      width: 1000,
      height: 40,
    },
  },
  {
    id: 64,
    name: "Norwegian Netboard",
    format: "Display",
    dimensions: {
      width: 580,
      height: 500,
    },
  },
  {
    id: 65,
    name: "Monster",
    format: "Display",
    dimensions: {
      width: 640,
      height: 480,
    },
  },
  {
    id: 66,
    name: "Danish Monsterboard",
    format: "Display",
    dimensions: {
      width: 930,
      height: 600,
    },
  },
  {
    id: 67,
    name: "Mobile Interstitial Portrait",
    format: "Display",
    dimensions: {
      width: 320,
      height: 480,
    },
  },
  {
    id: 68,
    name: "Wallpaper/Skin",
    format: "Display",
    dimensions: {
      width: 1800,
      height: 1000,
    },
  },
  {
    id: 72,
    name: "Swedish Takeover",
    format: "Display",
    dimensions: {
      width: 320,
      height: 320,
    },
  },
  {
    id: 73,
    name: "Swedish Half Takeover",
    format: "Display",
    dimensions: {
      width: 320,
      height: 160,
    },
  },
  {
    id: 74,
    name: "Nordic Stortavla",
    format: "Display",
    dimensions: {
      width: 1250,
      height: 240,
    },
  },
  {
    id: 78,
    name: "Double Panorama",
    format: "Display",
    dimensions: {
      width: 980,
      height: 240,
    },
  },
  {
    id: 79,
    name: "Monster Panorama",
    format: "Display",
    dimensions: {
      width: 980,
      height: 300,
    },
  },
  {
    id: 80,
    name: "Nordic Parade",
    format: "Display",
    dimensions: {
      width: 980,
      height: 400,
    },
  },
  {
    id: 82,
    name: "Large Mobile Banner",
    format: "Display",
    dimensions: {
      width: 300,
      height: 75,
    },
  },
  {
    id: 85,
    name: "Mini Rectangle",
    format: "Display",
    dimensions: {
      width: 300,
      height: 120,
    },
  },
  {
    id: 86,
    name: "SideKick",
    format: "Display",
    dimensions: {
      width: 300,
      height: 250,
    },
  },
  {
    id: 87,
    name: "Slider",
    format: "Display",
    dimensions: {
      width: 950,
      height: 90,
    },
  },
  {
    id: 88,
    name: "FilmStrip",
    format: "Display",
    dimensions: {
      width: 300,
      height: 600,
    },
  },
  {
    id: 89,
    name: "Large Portrait",
    format: "Display",
    dimensions: {
      width: 300,
      height: 1200,
    },
  },
  {
    id: 91,
    name: "Wallpaper 300x250",
    format: "Display",
    dimensions: {
      width: 300,
      height: 250,
    },
  },
  {
    id: 92,
    name: "Wallpaper 728x90",
    format: "Display",
    dimensions: {
      width: 728,
      height: 90,
    },
  },
  {
    id: 93,
    name: "Wallpaper 160x600",
    format: "Display",
    dimensions: {
      width: 160,
      height: 600,
    },
  },
  {
    id: 95,
    name: "Small Czech Leaderboard",
    format: "Display",
    dimensions: {
      width: 970,
      height: 100,
    },
  },
  {
    id: 99,
    name: "Slider 300x250",
    format: "Display",
    dimensions: {
      width: 300,
      height: 250,
    },
  },
  {
    id: 100,
    name: "Curtain 300x250",
    format: "Display",
    dimensions: {
      width: 300,
      height: 250,
    },
  },
  {
    id: 101,
    name: "Mobile Interstitial Landscape",
    format: "Display",
    dimensions: {
      width: 480,
      height: 320,
    },
  },
  {
    id: 102,
    name: "Tablet Interstitial Portrait",
    format: "Display",
    dimensions: {
      width: 768,
      height: 1024,
    },
  },
  {
    id: 103,
    name: "Nordic Helsida",
    format: "Display",
    dimensions: {
      width: 480,
      height: 280,
    },
  },
  {
    id: 104,
    name: "Nordic Panorama XXL",
    format: "Display",
    dimensions: {
      width: 1250,
      height: 360,
    },
  },
  {
    id: 105,
    name: "Nordic Outsider XXL",
    format: "Display",
    dimensions: {
      width: 250,
      height: 800,
    },
  },
  {
    id: 108,
    name: "Mobile Half Page Ad",
    format: "Display",
    dimensions: {
      width: 320,
      height: 240,
    },
  },
  {
    id: 111,
    name: "Swedish Big Panorama",
    format: "Display",
    dimensions: {
      width: 1250,
      height: 480,
    },
  },
  {
    id: 113,
    name: "Large Leaderboard",
    format: "Display",
    dimensions: {
      width: 1000,
      height: 300,
    },
  },
  {
    id: 116,
    name: "Billboard",
    format: "Display",
    dimensions: {
      width: 950,
      height: 250,
    },
  },
  {
    id: 117,
    name: "Mobile Double Size Banner",
    format: "Display",
    dimensions: {
      width: 320,
      height: 100,
    },
  },
  {
    id: 120,
    name: "Wideboard",
    format: "Display",
    dimensions: {
      width: 994,
      height: 250,
    },
  },
  {
    id: 121,
    name: "Tablet Interstitial Landscape",
    format: "Display",
    dimensions: {
      width: 1280,
      height: 800,
    },
  },
  {
    id: 122,
    name: "Tablet Interstitial Portrait",
    format: "Display",
    dimensions: {
      width: 800,
      height: 1280,
    },
  },
  {
    id: 124,
    name: "Mobile Leaderboard XXL",
    format: "Display",
    dimensions: {
      width: 640,
      height: 150,
    },
  },
  {
    id: 125,
    name: "German Billboard",
    format: "Display",
    dimensions: {
      width: 800,
      height: 250,
    },
  },
  {
    id: 126,
    name: "Super Wide Sky",
    format: "Display",
    dimensions: {
      width: 200,
      height: 600,
    },
  },
  {
    id: 144,
    name: "Nordic Blackboard",
    format: "Display",
    dimensions: {
      width: 980,
      height: 600,
    },
  },
  {
    id: 146,
    name: "Billboard",
    format: "Display",
    dimensions: {
      width: 980,
      height: 250,
    },
  },
  {
    id: 152,
    name: "German Masthead",
    format: "Display",
    dimensions: {
      width: 1000,
      height: 250,
    },
  },
  {
    id: 153,
    name: "Bavarian Billboard",
    format: "Display",
    dimensions: {
      width: 850,
      height: 250,
    },
  },
  {
    id: 156,
    name: "Mobile Banner 8",
    format: "Display",
    dimensions: {
      width: 640,
      height: 320,
    },
  },
  {
    id: 158,
    name: "Big Leaderboard",
    format: "Display",
    dimensions: {
      width: 840,
      height: 250,
    },
  },
  {
    id: 159,
    name: "Mobile IMU",
    format: "Display",
    dimensions: {
      width: 320,
      height: 250,
    },
  },
  {
    id: 161,
    name: "Content Wall",
    format: "Native",
  },
  {
    id: 162,
    name: "App Wall",
    format: "Native",
  },
  {
    id: 163,
    name: "News Feed",
    format: "Native",
  },
  {
    id: 164,
    name: "Chat List",
    format: "Native",
  },
  {
    id: 165,
    name: "Carousel",
    format: "Native",
  },
  {
    id: 166,
    name: "Content Stream",
    format: "Native",
  },
  {
    id: 167,
    name: "Grid Adjoining the Content",
    format: "Native",
  },
  {
    id: 172,
    name: "Retina asset size (600x500)",
    format: "Display",
    dimensions: {
      width: 600,
      height: 500,
    },
  },
  {
    id: 174,
    name: "Retina Asset Size 2 (640x100)",
    format: "Display",
    dimensions: {
      width: 640,
      height: 100,
    },
  },
  {
    id: 175,
    name: "Polish Billboard 2 (950x200)",
    format: "Display",
    dimensions: {
      width: 950,
      height: 200,
    },
  },
  {
    id: 177,
    name: "Widescreen Wallpaper (1920x1200)",
    format: "Display",
    dimensions: {
      width: 1920,
      height: 1200,
    },
  },
  {
    id: 178,
    name: "Rich Media Masthead (970x500)",
    format: "Display",
    dimensions: {
      width: 970,
      height: 500,
    },
  },
  {
    id: 179,
    name: "Big Skyscraper (250x600)",
    format: "Display",
    dimensions: {
      width: 250,
      height: 600,
    },
  },
  {
    id: 181,
    name: "Mobile Web Interstitial (640x832)",
    format: "Display",
    dimensions: {
      width: 640,
      height: 832,
    },
  },
  {
    id: 182,
    name: "Billboard GR (940x200)",
    format: "Display",
    dimensions: {
      width: 940,
      height: 200,
    },
  },
  {
    id: 184,
    name: "Mobile Interstitial (320x270)",
    format: "Display",
    dimensions: {
      width: 320,
      height: 270,
    },
  },
  {
    id: 185,
    name: "Banner Interstitial",
    format: "Display",
    dimensions: {
      width: 800,
      height: 600,
    },
  },
  {
    id: 187,
    name: "Medium Portrait (260x500)",
    format: "Display",
    dimensions: {
      width: 260,
      height: 500,
    },
  },
  {
    id: 190,
    name: " Square 240 (240x200)",
    format: "Display",
    dimensions: {
      width: 240,
      height: 200,
    },
  },
  {
    id: 191,
    name: "Mobile Banner 9 (360x50)",
    format: "Display",
    dimensions: {
      width: 360,
      height: 50,
    },
  },
  {
    id: 192,
    name: "Leaderboard (728x40)",
    format: "Display",
    dimensions: {
      width: 728,
      height: 40,
    },
  },
  {
    id: 195,
    name: "Megabox (600x300)",
    format: "Display",
    dimensions: {
      width: 600,
      height: 300,
    },
  },
  {
    id: 198,
    name: "Hungarian Roadblock (640x360)",
    format: "Display",
    dimensions: {
      width: 640,
      height: 360,
    },
  },
  {
    id: 199,
    name: "Aurea strip ad 2 (640x200)",
    format: "Display",
    dimensions: {
      width: 640,
      height: 200,
    },
  },
  {
    id: 201,
    name: "Pre-Roll",
    format: "Video",
  },
  {
    id: 202,
    name: "Interstitial",
    format: "Video",
  },
  {
    id: 203,
    name: "Outstream in-article",
    format: "Video",
  },
  {
    id: 204,
    name: "Mid-Roll",
    format: "Video",
  },
  {
    id: 205,
    name: "Post-Roll",
    format: "Video",
  },
  {
    id: 206,
    name: "Pre-Roll",
    format: "Audio",
  },
  {
    id: 207,
    name: "Vertical Video",
    format: "Video",
  },
  {
    id: 215,
    name: "Nordic Interstitial (990x720)",
    format: "Display",
    dimensions: {
      width: 990,
      height: 720,
    },
  },
  {
    id: 219,
    name: "OOH 16:9 Screen (Landscape)",
    format: "Display",
    dimensions: {
      width: 1920,
      height: 1080,
    },
  },
  {
    id: 221,
    name: "1x1 Floating",
    format: "Display",
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    id: 229,
    name: "Mobile Billboard",
    format: "Display",
    dimensions: {
      width: 320,
      height: 180,
    },
  },
  {
    id: 230,
    name: "Czech Branding",
    format: "Display",
    dimensions: {
      width: 2000,
      height: 1400,
    },
  },
  {
    id: 231,
    name: "Smart billboard",
    format: "Display",
    dimensions: {
      width: 694,
      height: 250,
    },
  },
  {
    id: 232,
    name: "Nordic Netboard",
    format: "Display",
    dimensions: {
      width: 580,
      height: 400,
    },
  },
  {
    id: 234,
    name: "Rubicon Custom (Native via Display)",
    format: "Display",
    dimensions: {
      width: 6,
      height: 6,
    },
  },
  {
    id: 235,
    name: "adMax Leaderboard",
    format: "Display",
    dimensions: {
      width: 990,
      height: 90,
    },
  },
  {
    id: 238,
    name: "OOH Airport Panoramic Video Wall Horizontal",
    format: "Display",
    dimensions: {
      width: 3840,
      height: 1080,
    },
  },
  {
    id: 251,
    name: "Catch All",
    format: "Display",
    dimensions: {
      width: 2,
      height: 2,
    },
  },
  {
    id: 256,
    name: "Czech Interscroller",
    format: "Display",
    dimensions: {
      width: 480,
      height: 820,
    },
  },
  {
    id: 258,
    name: "Czech mobile sticky",
    format: "Display",
    dimensions: {
      width: 500,
      height: 200,
    },
  },
  {
    id: 259,
    name: "XL Czech Leaderboard",
    format: "Display",
    dimensions: {
      width: 998,
      height: 200,
    },
  },
  {
    id: 264,
    name: "Skin NL",
    format: "Display",
    dimensions: {
      width: 970,
      height: 1000,
    },
  },
  {
    id: 273,
    name: "Outstream in-feed",
    format: "Video",
  },
  {
    id: 274,
    name: "Custom Horizontal (1800x200)",
    format: "Display",
    dimensions: {
      width: 1800,
      height: 200,
    },
  },
  {
    id: 275,
    name: "Outstream slider/floating",
    format: "Video",
  },
  {
    id: 277,
    name: "Outstream in-feed/detach",
    format: "Video",
  },
  {
    id: 278,
    name: "Custom Vertical (320x500)",
    format: "Display",
    dimensions: {
      width: 320,
      height: 500,
    },
  },
  {
    id: 282,
    name: "Norwegian Portrait",
    format: "Display",
    dimensions: {
      width: 320,
      height: 400,
    },
  },
  {
    id: 520,
    name: "Custom Horizontal",
    format: "Display",
    dimensions: {
      width: 320,
      height: 200,
    },
  },
  {
    id: 568,
    name: "Custom Horizontal",
    format: "Display",
    dimensions: {
      width: 300,
      height: 150,
    },
  },
  {
    id: 572,
    name: "Custom Vertical",
    format: "Display",
    dimensions: {
      width: 250,
      height: 350,
    },
  },
  {
    id: 590,
    name: "Custom Horizontal",
    format: "Display",
    dimensions: {
      width: 360,
      height: 56,
    },
  },
  {
    id: 600,
    name: "Content In-Feed",
    format: "Native",
  },
  {
    id: 601,
    name: "Content In-Article",
    format: "Native",
  },
  {
    id: 602,
    name: "Content Out-Feed",
    format: "Native",
  },
  {
    id: 603,
    name: "Content Recommendation",
    format: "Native",
  },
  {
    id: 604,
    name: "Social In-Feed",
    format: "Native",
  },
  {
    id: 605,
    name: "Social In-Article",
    format: "Native",
  },
  {
    id: 606,
    name: "Social Out-Feed",
    format: "Native",
  },
  {
    id: 607,
    name: "Social Recommendation",
    format: "Native",
  },
  {
    id: 608,
    name: "Product In-Feed",
    format: "Native",
  },
  {
    id: 609,
    name: "Product In-Article",
    format: "Native",
  },
  {
    id: 610,
    name: "Product Out-Feed",
    format: "Native",
  },
  {
    id: 611,
    name: "Product Recommendation",
    format: "Native",
  },
  {
    id: 617,
    name: "No Context In-Feed",
    format: "Native",
  },
  {
    id: 618,
    name: "No Context In-Article",
    format: "Native",
  },
  {
    id: 619,
    name: "No Context Out-Feed",
    format: "Native",
  },
  {
    id: 620,
    name: "No Context Recommendation",
    format: "Native",
  },
  {
    id: 624,
    name: "No Context No Placement",
    format: "Native",
  },
];

const filterSizesByType = (type: "Display" | "Video" | "Native"): Array<{ label: string; value: string }> => {
  const sizes = adSizeFromMagniteNetworkCatch
    .filter((size) => size.format === type)
    .map((size) => {
      const value = size.dimensions ? `${size.name} (${size.dimensions.width}x${size.dimensions.height})` : size.name;
      const label = size.dimensions ? `${size.name} (${size.dimensions.width}x${size.dimensions.height})` : size.name;
      return {
        value: value,
        label: label,
      };
    });

  if (sizes.length) {
    return sizes;
  }
  return [
    {
      value: "No sizes available",
      label: "No sizes available",
    },
  ];
};

export const magniteAdSizesDisplay = filterSizesByType("Display");

export const magniteAdSizesVideo = filterSizesByType("Video");

export const magniteAdSizesNative = filterSizesByType("Native");
