import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { AccountTitle, StatusDot, StatusItem } from "../Accounts/zOld/shared";
import { IAccounts } from "../../application/entities/dataTypes/accounts";
import { useTranslation } from "react-i18next";
import { MainFrameHeader } from "../../components/templates/MainFrameHeader";
import { PageId } from "../../application/pages/pages.config";
import { useAccountQuery } from "../../components/hooks/data/useAccountQuery";
import { CompanyNavigation } from "../Companies/shared/CompanyNavigation";
import { BrandNavigation } from "../Brands/shared/BrandNavigation";
import { Logo } from "assets/icons";
import { AppIcon } from "../../components/ui/AppIcon";

interface IAccountHeaderProps {
  account: IAccounts;
}

export const AccountHeaderProperties = (props: IAccountHeaderProps) => {
  const { account } = props;
  const { t } = useTranslation();

  return null;

  return (
    <>
      <AccountTitle>{t("account.generalTitles.status")}</AccountTitle>
      <Box sx={{ pt: 1 }}>
        <StatusItem active={account.activated}>
          <StatusDot /> {account.activated ? "Active" : "Inactive"}
        </StatusItem>
      </Box>
    </>
  );
};

export const AccountHeaderContent = (props: IAccountHeaderProps) => {
  const { account } = props;

  const { t } = useTranslation();

  let desc2 = `${account.companyName}, ${account.country}`;
  if (account.accountType === "BRAND") {
    desc2 = `Company: ${account?.parentAccountAssociations?.[0]?.parentAccount?.companyName}`;
  }

  return Boolean(account) ? (
    <Box sx={{ py: 2, mr: 2 }}>
      <div style={{ display: "flex" }}>
        {account?.companyName !== undefined ? (
          <div>
            <Typography variant={"h4"}> {account.companyName}</Typography>
            <p style={{ textTransform: "capitalize" }}>{`${account?.accountType !== null ? account?.accountType?.toLowerCase() : "Account "} ID#: ${
              account.id
            }`}</p>
          </div>
        ) : (
          <div>
            <Skeleton variant="text" width={100} height={25} />
            <Skeleton variant="text" width={83} />
          </div>
        )}
      </div>
    </Box>
  ) : null;
};

export const AccountHeader = (props: { accountId: number; frameId: PageId }) => {
  const { frameId, accountId } = props;
  //
  const accountQuery = useAccountQuery(accountId);
  const accountData: IAccounts = accountQuery?.data ?? {};

  const { t } = useTranslation();
  const headerButtons = accountData.accountType === "BRAND" ? <BrandNavigation brand={accountData} /> : <CompanyNavigation account={accountData} />;

  return (
    <MainFrameHeader
      frameId={frameId}
      hideHeaderDefault={true}
      headerProperties={<AccountHeaderProperties account={accountData} />}
      headerContent={<AccountHeaderContent account={accountData} />}
      headerButtons={headerButtons}
    />
  );
};
