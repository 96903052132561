import { List, ListItem, Typography } from "@mui/material";
import { IAccounts } from "application/entities/dataTypes/accounts";
import { providerIconList } from "assets/providerIcons";
import { useSelectedDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { AppIcon } from "components/ui/AppIcon";

export const AdminAccountEnablementChannels = (props: { account: IAccounts }) => {
  const { account } = props;

  const dspSelection = useSelectedDspChannels(+account.id);
  const channels = dspSelection.data ?? [];

  return (
    <>
      {channels.length === 0 && <Typography>No channels </Typography>}
      <List>
        {channels.map((dsp, idx) => {
          return (
            <>
              <ListItem key={dsp.id} style={{ opacity: !dsp.isActive ? "0.5" : "1", paddingBlock: "8px" }}>
                <AppIcon icon={providerIconList?.[dsp.dspChannel.code] ?? "Settings"} />{" "}
                <Typography style={{ marginLeft: "7px" }}>
                  {dsp.dspChannel.name} {!dsp.isActive && "(Disabled)"}
                </Typography>
              </ListItem>
            </>
          );
        })}
      </List>
    </>
  );
};
