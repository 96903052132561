import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Autocomplete, ClickAwayListener, Paper, Popper, TextField } from "@mui/material";
type OptionSelectMultipleProps = {
  options: { value: string; label: string }[];
  value: string[] | undefined;
  onChange: (value: string[]) => void;
};

export const OptionSelectMultiple = (props: OptionSelectMultipleProps) => {
  const { options, value, onChange } = props;

  const [selectValues, setSelectValues] = React.useState<string[]>(value || []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDelete = (chipToDelete: string) => {
    if (selectValues.length === 1) {
      setAnchorEl(null);
    }
    setSelectValues((chips) => chips.filter((chip) => chip !== chipToDelete));
    onChange(selectValues.filter((chip) => chip !== chipToDelete));
  };

  const handleChangeBis = (val: { label: string; value: string }[]) => {
    const newValues = val.map((item) => item.value);
    setSelectValues(newValues);
    onChange(newValues);
  };

  const selectedValues = React.useMemo(() => options.filter((item) => selectValues.includes(item.value)), [options, selectValues]);

  return (
    <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
      <Autocomplete
        disablePortal
        multiple
        id="combo-box-demo"
        options={options}
        value={selectedValues}
        sx={{ width: 200, "& .MuiAutocomplete-popper": { width: "300px" } }}
        renderInput={(params) => <TextField {...params} label="" placeholder="Select or enter a size" />}
        onChange={(e, val) => {
          e.preventDefault();
          if (val === null) return;
          handleChangeBis(val);
        }}
        renderTags={(props, option) => {
          return null;
        }}
        disableClearable={true}
        disableCloseOnSelect={true}
      />
      {selectedValues.length > 0 && (
        <>
          <StyledChip
            size="small"
            label={`${selectValues.length} selected`}
            onClick={(event) => (selectValues.length > 0 ? setAnchorEl(anchorEl ? null : event.currentTarget) : {})}
          />
          <Popper id="sizes" open={open} anchorEl={anchorEl} placement="right">
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Paper elevation={4} sx={{ borderRadius: "7px" }}>
                <Box
                  p={2}
                  sx={{
                    background: "#fff",
                    display: "grid",
                    gridTemplateColumns: "repeat(3,95px)",
                    gap: "0.5rem",
                    maxWidth: "360px",
                    borderRadius: "7px",
                  }}
                >
                  {[...selectValues].map((selectValue) => (
                    <StyledChip
                      size="small"
                      key={selectValue}
                      label={selectValue}
                      onDelete={() => {}}
                      deleteIcon={
                        // use this beacause of a bug in mui ref: https://github.com/mui/material-ui/issues/18658
                        <HighlightOffSharpIcon
                          onMouseDown={() => {
                            handleDelete(selectValue);
                          }}
                        />
                      }
                    />
                  ))}
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    </Box>
  );
};

const StyledChip = styled(Chip)(() => ({
  fontSize: "11px",
  padding: "2px",
}));
