import {IAffinities} from '../dataTypes/affinities';

export const affinities: Partial<IAffinities> = {
  "id": undefined,
  "parentId": undefined,
  "condensedName": undefined,
  "tier1": undefined,
  "tier2": undefined,
  "tier3": false,
  "tier4": undefined,
}