import { audiencesColumns } from "application/entities/dataColumns/audiences.columns";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { PageId } from "application/pages/pages.config";
import { TDataColumnModel, dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { useAudiencesAlwaysOn } from "components/hooks/data/useAudiencesQuery";
import { MainFrame } from "components/templates/MainFrame";
import { GridValueGetterParams } from "@mui/x-data-grid";

import _ from "lodash";
import { GridTable } from "components/organisms/GridTable";
import { Button } from "@mui/material";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { AlwaysOnDialog } from "./AlwaysOnDialog";
import { useAdminPage } from "components/hooks/useAdminPage";

export const AudiencesAlwaysOn = () => {
  const { isLoading, error, data, refetch } = useAudiencesAlwaysOn();
  const windowDialog = useOpenDialogWindow();
  useAdminPage();

  const audiences = data?.data?._embedded?.audiences;
  const flatData: IAudiences[] = !isLoading && !error ? audiences?.flat() ?? [] : [];

  const columns = _.cloneDeep(audiencesColumns());

  columns.dsps = {
    field: "dsps",
    headerName: "Channels",
    flex: 1,
    type: "string",
    cellClassName: "titleCell minCell",
    minWidth: 130,
    code: "company_name",
    valueGetter: (params: GridValueGetterParams<IAudiences>) => {
      let dsps = "";
      params.row?.audienceActivationRecap?.forEach((recap: any) => {
        if (recap.alwaysOnActivated) {
          dsps += dsps.length === 0 ? recap.dspName : " - " + recap.dspName;
        }
      });
      return dsps ?? "Unknown";
    },
  };

  columns.actions.width = 140;
  columns.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <Button variant="contained" size="small" color="primary" onClick={() => openAlwaysOnAudience(Number(cell.id))}>
        View audience
      </Button>
    );
  };

  const cols: TDataColumnModel = {
    id: columns.id,
    createdDate: columns.createdDate,
    company: columns.company,
    creator: columns.creator,
    name: columns.name,
    dsp: columns.dsps,
    actions: columns.actions,
  };

  const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

  const openAlwaysOnAudience = (audienceId: number) => {
    windowDialog.open({
      dialogId: "AudienceDealId",
      component: AlwaysOnDialog,
      componentProps: {
        audienceId: audienceId,
      },
      dialogProps: {
        className: "alwaysOnPopup",
      },

      onClose: () => {
        refetch();
      },
    });
  };

  return (
    <MainFrame frameId={PageId.audiencesAlwaysOn} breadCrumbProps={{ frameId: PageId.audiencesAlwaysOn }} loading={isLoading} checkPageAccess={true}>
      <div style={{ padding: "1.5rem", height: "100%", width: "100%" }}>
        <GridTable
          styleClass={"naked"}
          height="100%"
          data={{
            columns: audienceColumns as any,
            rows: flatData,
          }}
          noRowsText="No Always On Audiences"
          gridProps={{
            disableSelectionOnClick: true,
            autoHeight: false,
            hideFooter: true,
            columnBuffer: 30,
            columnThreshold: 10,
          }}
        />
      </div>
    </MainFrame>
  );
};
