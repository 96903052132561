import React, {useEffect} from "react";
import {IAccountConfParameters} from "../../application/entities/dataTypes/accounts";
import {objectApi} from "../../application/entities/dataApi";
import {Box, Button, Collapse, Paper, styled, Typography} from "@mui/material";
import {FormBlock} from "../templates/InfoBlock";
import {AdditionnalFixedCPM} from "./additionnalFixedCPM";
import {AdditionnalVariableCPM} from "./additionnalVariableCPM";

export const CPMManagement = ({brandId}: { brandId: number }) => {
    const [formSelected, setFormSelected] = React.useState<"fixed" | "variable" | null>(null);
    const [accountConfParams, setAccountConfParams] = React.useState<IAccountConfParameters>({} as IAccountConfParameters);
    const [currencyRate, setCurrencyRate] = React.useState<number>(1);


    const fetchAccountConfParameters = (brandId: number) => {
        const accountApi = new objectApi.accounts();
        accountApi.getAccountConfParameters(brandId).then((res) => {
            const accountConfParameters = res.data.accountConfParameters as IAccountConfParameters;
            if (accountConfParameters) {
                setAccountConfParams(accountConfParameters);
                if (accountConfParameters.agency_additional_cpm > 0) setFormSelected("fixed");
                if (accountConfParameters.agency_cpm_multiplier > 1) setFormSelected("variable");
                if (accountConfParameters.currency) {
                    accountApi.getCurrencyRate(accountConfParameters.currency).then((res) => {
                        setCurrencyRate(res.data.conversionRate)
                    })

                }
            }
        });
    };
    console.log('currencyRateToUSD', currencyRate)

    useEffect(() => {
        fetchAccountConfParameters(brandId);
        setFormSelected(null);
    }, [brandId]);

    return (
        <ItemBox>
            <Box>
                <FormBlock title={"CPM Management"}></FormBlock>

                {formSelected === null && (
                    <>
                        <Box
                            sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "7px",
                                padding: "1rem",
                                marginTop: "1rem",
                            }}
                        >
                            <Typography variant="body1" color="#7587a3" pb={"0.25rem"}>
                                You can manage the CPM charged for this brand/client here. Any CPM changes here will be:
                            </Typography>
                            <Typography variant="body1" pl={2} color="#7587a3" pb={"0.25rem"}>
                                - Reflected in the Evorra interface for any users building audiences on top of the base
                                CPM of the platform. The specific fixed increase will not be specifically
                                highlighted for users
                            </Typography>
                            <Typography variant="body1" pl={2} color="#7587a3">
                                - Passed onto the activation endpoint as part of the overall CPM if a user chooses an
                                activation method which also passes through the CPM (ie. impression based
                                buy)
                            </Typography>
                            <Typography variant="body1" pt={1} color="#7587a3">
                                You will only be able to increase the CPM via this functionality from the base price
                                offered in Evorra (no decrease in CPM can be managed here).
                            </Typography>
                        </Box>

                        <Box mt={4} textAlign="center">
                            <Button variant="contained" aria-haspopup="true" onClick={() => setFormSelected("fixed")}>
                                Manage CPM
                            </Button>

                        </Box>
                    </>
                )}
                <Collapse in={formSelected !== null}>
                    {formSelected === "fixed" && (
                        <AdditionnalFixedCPM
                            accountConfParams={accountConfParams}
                            currency={accountConfParams.currency ?? "USD"}
                            currencyRateToUSD={currencyRate}
                            setFormSelected={setFormSelected}
                            brandId={brandId}
                            fetchAccountConfParameters={fetchAccountConfParameters}
                        />
                    )}
                    {formSelected === "variable" && (
                        <AdditionnalVariableCPM
                            accountConfParams={accountConfParams}
                            currency={accountConfParams.currency ?? "USD"}
                            currencyRateToUSD={currencyRate}
                            setFormSelected={setFormSelected}
                            brandId={brandId}
                            fetchAccountConfParameters={fetchAccountConfParameters}
                        />
                    )}
                </Collapse>
            </Box>
        </ItemBox>
    );
};

const ItemBox = styled(Paper)(() => ({
    padding: "1rem",
    paddingTop: 0,
    background: "#fff",
    borderRadius: "8px",
    overflowY: "auto",
}));