import { objectApi } from "application/entities/dataApi";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IFeatureFlagsDto } from "../application/entities/dataTypes/featureFlags";
import { mountStoreDevtool } from "simple-zustand-devtools";

type FeatFlagValType = "client_dashboard" | "provider_dashboard" | "dsp_amazonads" | "feature_travel" | string;

type FeatureFlagStoreType = {
  accountId: number | undefined;
  featureFlags: Record<FeatFlagValType, boolean>;
  featureFlagList: IFeatureFlagsDto[];
  actions: {
    fetchFeatureFlags: (accountId: number) => void;
  };
};

export const useFeatureFlag = create<FeatureFlagStoreType>()(
  devtools((set, get) => ({
    accountId: undefined,
    featureFlags: {},
    featureFlagList: [],
    actions: {
      fetchFeatureFlags: (accountId: number) => {
        const flagApi = new objectApi.accounts();
        if (Boolean(accountId) && get().accountId !== accountId) {
          flagApi.getMyFeatureFlags(accountId).then((res) => {
            set({ accountId, featureFlags: res?.data?.featureFlags ?? [] });
          });
        }
      },
    },
  }))
);

//Display debug in React Dev Tools
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("useFeatureFlag", useFeatureFlag);
}
