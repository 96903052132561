import {Box, styled} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useDemoMode} from '../hooks/useDemoMode';

interface IDemoModeWrapperProps {
  children?: any;
};


export const DemoModeWrapper: FunctionComponent<IDemoModeWrapperProps> = (props: IDemoModeWrapperProps) => {
  const {children } = props;
  const [demoMode, setDemoMode] = useDemoMode();
  
  return demoMode ? <>{children}</> : null;
};