import {ColumnsMiddle} from 'components/ui/AppElements';
import styled from 'styled-components/macro';
import {Theme} from '@mui/material/styles';
import {Tooltip, tooltipClasses, TooltipProps} from '@mui/material';
import React from 'react';
import {useSize} from '../../hooks/useSize';
import {AppTag} from '../../ui/AppTag';

type TListItem = {
  id: number,
  text: string
}

interface ITagListProps {
  list: TListItem[];
  onRemove?: Function
  style?:any
}

export const TagListMini = (props: ITagListProps) => {
  const {list,onRemove, style}     = props;
  const TagListRef = React.useRef<any>(null);
  const [nbTagsToDisplay, setNbTagsToDisplay] = React.useState(0);

  const TagContainerRef = React.useRef<any>(null);
  const tagContainerSize = useSize(TagContainerRef);
  const tagListSize = useSize(TagListRef);
  
  const nbTags = list.length

  const finalList=list?.sort((a:TListItem,b:TListItem)=>{
    return a?.text > b?.text ? 1 : a?.text < b?.text ? -1 : 0
  })


  const getNbTagToDisplay = () =>{
    const childNodes: HTMLElement[] = Array.from(TagListRef.current?.children ?? []) as HTMLElement[];
    let nbToDisplay = 0
    let size=0
    const sizeLimit= Number(tagContainerSize?.width +30)
    if (childNodes.length > 0) {
      childNodes.forEach((node:HTMLElement)=>{
        size = Number(size + 5 + node.getBoundingClientRect().width)
        if(size < sizeLimit){
          nbToDisplay++
        }
      })
    }
    setNbTagsToDisplay(nbToDisplay)
  }
  
React.useLayoutEffect(() => {
  if (TagListRef.current && tagListSize?.width) {
    getNbTagToDisplay()
  }
}, [tagListSize]);




  return <TagContainer data-cy={'TagListMini'} ref={TagContainerRef}>
    <TagListMain style={{gap:'0.25rem', ...props.style}} ref={TagListRef}>
      {finalList.slice(0, nbTagsToDisplay).map((listItem: TListItem, index: number) => {
        return <AppTag dense={true} id={0} name={listItem.text}  key={'finalList'+index} />
          
      })}
      {
        nbTags > nbTagsToDisplay && <>
        <HtmlTooltip placement="right" title={<InnerToolTip>
                  <div>
                    {finalList.slice(nbTagsToDisplay,nbTags).map((listItem: TListItem, index: number) => {
                      return <div key={listItem.text} style={{marginBlock:'0.5rem'}}>
                          <AppTag dense={true} id={0} name={listItem.text} />
                        </div>
                      })}
                  </div>
                  </InnerToolTip> }>
          <div style={{maxWidth:'100%', display:'inline-flex', cursor:'pointer'}}>
            <AppTag  dense={true} id={0} name={'+' + Number(nbTags - nbTagsToDisplay)} />
          </div>
        </HtmlTooltip>
       
        </>
      }
    </TagListMain>
    
  </TagContainer>
}

const TagContainer = styled(ColumnsMiddle)({
  width   : 'auto',
  position: 'relative',
  overflow: 'hidden',
})

const TagListMain = styled(ColumnsMiddle)({
  // height  : '20px',
  display : 'flex',
  flexWrap: 'nowrap',
  position: 'relative',
  //overflow: 'hidden',
})
const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    padding        : '0',
    maxWidth       : '300px',
    borderRadius   : '12px'
  },
}));

const InnerToolTip      = styled('div')((props: { theme: Theme }) => ({
  padding        : '16px',
  color          : '#3B4559',
  boxShadow      : '0px 0px 6px rgba(196, 211, 241, 0.60)',
  backgroundColor: '#fff',
  borderRadius   : '12px',
  border         : 'rgb(237, 237, 237,0.9)',
}));