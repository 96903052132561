import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {SegmentCpm} from "./SegmentCpm";
import {useNavigate} from "react-router-dom";
import {pageUtils} from "../../../application/pages/pages.utils";
import {PageId} from "../../../application/pages/pages.config";
import {useParams} from "react-router";
import {SegmentVolumeTotal} from "./SegmentVolumeTotal";
import {SelectedSegments} from "./SelectedSegments";


export const SegmentBuilderFooter = () => {
    let {id} = useParams<{ id: string }>() as { id: string };
    const history = useNavigate();


    function activateAudience() {

        history(pageUtils.getPagePathById(PageId.audienceEnablement, {audienceId: id}));

    }

    return <>
        <Paper sx={{
            background: "#fff",
            padding: "1rem",

        }}>
            <Box sx={{display: "flex", alignItems: 'center', gap: 1, justifyContent: "space-between", width: '100%'}}>


                <Box>
                    <Box sx={{display: "flex", alignItems: 'center', gap: 1}}>
                        <SelectedSegments/>

                    </Box>


                </Box>
                <Box sx={{display: 'flex', gap: '1.5rem', alignItems: 'center'}}>
                    <SegmentCpm/>
                    <SegmentVolumeTotal/>


                </Box>
                <Button variant="contained" onClick={activateAudience}>Enable</Button>
            </Box>

            <Box>

            </Box>
        </Paper>
    </>
}