import {MenuItem, Select, TextField} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useCountryQuery} from '../../../components/hooks/data/useCountryQuery';
import {FieldZone, FormInputContainer} from '../../../components/templates/InfoBlock';
import {companySizeList3} from '../../Accounts/zOld/mock';
import {EntityTreeView} from '../../../components/form/EntityTreeView';
import Autocomplete from '@mui/material/Autocomplete';
import {IconSet} from '../../../assets/icons';
import {ICountry} from '../../../application/entities/dataTypes/country';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import InputChips from 'components/ui/InputChips';

interface ICompanyFormProps {
  accountData?: IAccounts;
};


export const CompanyForm: FunctionComponent<ICompanyFormProps> = (props: ICompanyFormProps) => {
  
  const methods   = useFormContext();
  const countryQy = useCountryQuery();
  
  const countryData: ICountry[] = countryQy?.data ?? [];
  
  
  const {t} = useTranslation();
  
  //
  const companyName    = methods.register('companyName', {required: true, minLength: 2});
  const companyUrlListWatch = methods.watch('companyUrlList');
  const postalCode     = methods.register('postalCode', {required: true, minLength: 2});
  const city           = methods.register('city', {required: true, minLength: 2});
  const streetAddress  = methods.register('streetAddress', {required: true, minLength: 2});
  
  methods.register('industries', {required: true,});
  
  
  return <div style={{display:'grid', gridTemplateColumns:'1fr 1fr',gap:'20px'}} className="">
    <FormInputContainer className="">
      <FieldZone title={'Company Name'} code={'companyName'} required={true}>
        <TextField  {...companyName} error={methods.formState.errors?.companyName} fullWidth/>
      </FieldZone>

      <FieldZone title={'Company Size'} code={'companySize'} required={true}>
        <Controller
          name="companySize"
          control={methods.control}
          rules={{required: 'this is required'}}
          render={({field}) => (
            <Select {...field}
                    value={field.value ?? '  '}
                    onChange={(e, val: any) => {
                      field.onChange(val?.props?.value);
                    }}
                    error={Boolean(methods.formState.errors?.companySize)}
                    fullWidth={true}>
              {companySizeList3.map(size =>
                <MenuItem key={size.name} value={size.id as string}>{size.name}</MenuItem>)}
            </Select>
          )}
        />
      </FieldZone>

      <FieldZone title={t('account.accountAddressLabels.companyStreetAddress')} code={'companyStreetAddress'} required={true}>
        <TextField
          {...streetAddress}
          fullWidth
          multiline
          error={methods.formState.errors.streetAddress}
        />
      </FieldZone>

      <InputChips dataCy='companyUrlList' validateUrl={false} label={'Company URL list'} placeholder={'Add an url and press enter'} chips={companyUrlListWatch} updateChips={(chips)=>{methods.setValue('companyUrlList', chips)}}/> 
    
    </FormInputContainer>

    <FormInputContainer className="">
      <FieldZone title={'Industry'} code={'Industry'} required={true}>
        <EntityTreeView
          entityName={'industries'}
          defaultField={'name'}
          hierarchyField={'name'}
          singleSelect={true}
          selectedEntityItems={methods.getValues('industries')}
          onChange={(industry) => methods.setValue('industries', industry, {shouldValidate: true, shouldDirty: true})}
        />
      </FieldZone>
      
      <div className={'flex-h flex-align-middle gap-small'}>
        <div className={'flex-main'}>
          <FieldZone title={t('account.accountAddressLabels.postalCode')} code={'postalCode'} required={true}>
            <TextField
              {...postalCode}
              error={methods.formState.errors.postalCode}
              fullWidth
            />
          </FieldZone>
        </div>
        <div className={'flex-main'}>
          <FieldZone title={t('account.accountAddressLabels.city')} code={'city'} required={true}>
            <TextField
              {...city}
              fullWidth
              error={methods.formState.errors.city}
            />
          </FieldZone>
        </div>
      </div>
      <FieldZone title={'Country'} code={'country'} required={true}>
        <Controller
          name="country"
          rules={{required: 'this is required'}}
          control={methods.control}
          render={({field}) => (
            <Autocomplete
              popupIcon={<IconSet.ChevronDownBlue/>}
              clearIcon={<IconSet.Close/>}
              options={countryQy?.data ?? []}
              getOptionLabel={(option: ICountry) => option?.name ?? '...'}
              clearOnBlur={false}
              value={field.value ? countryData.find(item => item.code === field.value) : null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={methods.formState.errors?.country}
                  InputProps={{...params.InputProps, autoComplete: 'new-password'}}
                />)}
            />
          )}
        />
      </FieldZone>
      
    </FormInputContainer>
  </div>;
};