import {Grid, Stack, Typography} from '@mui/material';
import styledOld from 'styled-components';
import {Palette,  Theme} from '@mui/material/styles';
import { styled } from '@mui/styles';


type TNodeOptionsRadius = Record<string, number> | number

type TNodeOptionsPadding = 0 | 0.25 | 0.5 | 1 | 1.5 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
type TNodeOptionsDimension = 0 | 0.25 | 0.5 | 1 | 1.5 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12 | 16 |20| 24 | 32 | 48 | 64

export type   TGridDefinedSizes = keyof typeof NodeOptionsDimensionsPresets

export interface NodeProps {
  isOpen?: boolean;
}

const NodeOptionsDimensionsPresets: Record<string, TNodeOptionsDimension> = {
  tiny   : 8,
  small  : 16,
  smallbis:20,
  default: 24,
  medium : 32,
  large  : 48
};

export interface IGridStyleProps {
  padding?: TNodeOptionsPadding[];
  margin?: TNodeOptionsPadding[];
  width?: TNodeOptionsDimension;
  height?: TNodeOptionsDimension;
  theme?: Theme;
  palette?: Palette;
  themeColor?: Palette;
  themeBackgroundColor?: Palette;
  active?: boolean;
  debug?: boolean;
  rotate?: number;
  pointer?: boolean;
  radius?: TNodeOptionsRadius;
  border?: string;
  alignCenter?: boolean;
  shadow?: boolean;
  children?:any;
}

export const H1 = styledOld(Typography).attrs({variant: 'h1'})({});
export const H2 = styledOld(Typography).attrs({variant: 'h2'})({});
export const H3 = styledOld(Typography).attrs({variant: 'h3'})({});
export const H4 = styledOld(Typography).attrs({variant: 'h4'})({});
export const H5 = styledOld(Typography).attrs({variant: 'h5'})({});
export const H6 = styledOld(Typography).attrs({variant: 'h6'})({});

/** @deprecated  use mui.Box instead **/
export const Node = styledOld.div<IGridStyleProps>((props) => ({
  ...getGridStyle(props)
}));

export const Elem = styledOld.div<IGridStyleProps>((props) => ({
  ...getGridStyle(props)
}));

export const Columns = styledOld(Grid).attrs({
  container: true,
  wrap     : 'nowrap'
})<IGridStyleProps>((props, theme?: Theme) => ({
  ...getGridStyle(props),
  height: '100%',
  width : '100%',
}));

export const GridMiddle = styled(Grid)((props : {theme: Theme} & IGridStyleProps) => ({
  display   : 'flex',
  alignItems: 'center',
  flexWrap  : 'nowrap',
  ...getGridStyle(props)
}));

export const GridMiddleCenter = styled(Grid)((props : {theme: Theme} & IGridStyleProps) => ({
  display   : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap  : 'nowrap',
  ...getGridStyle(props)
}));

/** @deprecated
 * because use GridMiddle
 * */
export const ColumnsMiddle = styledOld(Grid).attrs({
  container : true,
  wrap      : 'nowrap',
  alignItems: 'center',
})<IGridStyleProps>((props, theme?: Theme) => ({
  ...getGridStyle(props)
}));

export const ColumnsCenter = styledOld(Grid).attrs({
  container     : true,
  wrap          : 'nowrap',
  justifyContent: 'center',
})<IGridStyleProps>((props, theme?: Theme) => ({
  ...getGridStyle(props)
}));

export const ColumnsMiddleCenter = styledOld(Grid).attrs({
  container     : true,
  wrap          : 'nowrap',
  justifyContent: 'center',
  alignItems    : 'center',
})<IGridStyleProps>((props, theme?: Theme) => ({
  ...getGridStyle(props)
}));

export const ColumnsWrap = styledOld(Grid).attrs({container: true})<IGridStyleProps>((props) => ({
  ...getGridStyle(props)
}));

export const Stacker = styledOld(Stack).attrs({
  container: true,
  wrap     : 'nowrap'
})<IGridStyleProps>((props, theme?: Theme) => ({
  flexDirection: 'column',
  height       : '100%',
  width        : 'auto',
  ...getGridStyle(props)
}));

export const StackerFullHeight = styledOld(Grid).attrs({
  container: true,
  wrap     : 'nowrap'
})<IGridStyleProps>((props, theme?: Theme) => ({
  display      : 'flex',
  flexDirection: 'column',
  width        : 'auto',
  height       : '100%',
  ...getGridStyle(props)
}));

export const GridItem = styledOld(Grid).attrs({item: true})<IGridStyleProps>((props) => ({...getGridStyle(props)}));

export const GridItemFull = styledOld(Grid).attrs({item: true})<IGridStyleProps>((props) => ({
  flex: '1 1 0',
  ...getGridStyle(props)
}));

export const GridItemFullAuto = styledOld(Grid).attrs({item: true})<IGridStyleProps>((props) => ({
  flex: '1 1 auto',
  ...getGridStyle(props)
}));

function getGridStyle(props: IGridStyleProps) {
  
  const style: Record<string, any> = {};
  
  if (props.padding) {
    style.padding = reducePad(props.padding);
  }
  
  if (props.margin) {
    style.margin = reducePad(props.margin);
  }
  
  if (props.width) {
    style.width = props.width + 'rem'; // * 16 + 'px'
  }
  
  if (props.height) {
    style.height = props.height + 'rem'; // * 16 + 'px'
  }
  
  if (props.rotate !== undefined) {
    style.transform          = `rotate(${props.rotate}deg)`;
    style.transitionProperty = '*';
    style.transitionDuration = '0.25s';
  }
  
  if (props.pointer) {
    style.cursor = 'pointer';
  }
  
  if (props.radius) {
    if (['number', 'string'].includes(typeof props.radius)) {
      style.borderRadius = props.radius + 'px';
    } else {
    
    }
  }
  
  if (props.active) {
    style.border = '1px solid #00B5E2';
  }
  
  if (props.debug) {
    style.border = '0.5px solid #ededed';
  }
  
  if (props.border) {
    style.border = '1px solid #ededed';
  }
  
  if (props.alignCenter) {
    style.textAlign = 'center';
  }
  
  
  if (props.shadow) {
    style.boxShadow = '0px 0px 6px rgba(196, 211, 241, 0.85)';
  }
  
  function reducePad(values: TNodeOptionsPadding[]) {
    return values.map((val, index) => {
      return (val) + 'rem'; // * 16 + 'px'
    }, []).join(' ');
  }
  
  return {
    ...style
  };
}

//
//@deprecated
export const ElementRowOpposedHContainer = styledOld.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
//@deprecated
export const ElementRowHContainer        = styledOld.div`
  display: flex;
  width: 100%;
`;

/**@deprecated*/
export const BoxRow = styledOld.div`
  padding: 0.25rem;
`;
//@deprecated
export const ElementRowCentered = styledOld.div`
  display: flex;
  padding: 0.25rem;
  justify-content: center;
`;
/**@deprecated*/
export const BoxRowFull         = styledOld.div`
  flex: 1 1 auto;
  padding: 0.5rem;
`;
/**@deprecated*/
export const HeaderZone         = styled('div')((props: {theme: Theme}) => ({
  backgroundColor:  props.theme.palette.mode==='light' ? '#f7f8fb' : 'rgba(255,255,255,0.1)',
  padding        : '0.5rem 1rem 1rem 1rem',
  marginBottom   : '1rem'
}));

/**@deprecated*/
export const HeaderZoneMessage = styledOld.div`
  text-align: center;
  position: relative;
  padding: 0rem 1.5rem;
  align-self: center;
  color: #9DAABF;
`;
//@deprecated
export const HeaderZoneTitle   = styledOld.div`
  text-align: center;
  margin-bottom: 0.5rem;
`;
//@deprecated
export const HeaderZoneIconBar = styledOld.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-align: center;
`;