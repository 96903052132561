import {
    Box,
    Button,
    CircularProgress,
    Divider,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {idList} from "application/utils/fpd.utils";
import {useFirstPartyData} from "components/hooks/useFirstPartyData";
import {useAppToast} from "components/live/AppToast";
import {useEffect, useState} from "react";
import {jsonToCSV} from "react-papaparse";
import {useNavigate, useParams} from "react-router";
import {profilesFormatter} from "../../../_configuration/formaters";
import {objectApi} from "../../../application/entities/dataApi";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type CsvColMappingProps = {
    file: { data: Array<string[]> };
    fileName: string;
    setTitle: (value: string) => void
};


export const CsvColMapping = (props: CsvColMappingProps) => {
    const {file} = props;

    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    const navigate = useNavigate();

    const accountApi = new objectApi.accounts();
    const appToast = useAppToast();

    const [headers, setHeaders] = useState<string[]>(Array.from(Array(file.data[0].length).fill("donotuse")));
    const [fileName, setFileName] = useState<string>(props.fileName);
    const [fileNameError, setFileNameError] = useState<string | null>(null);
    const [fileDescription, setFileDescription] = useState<string>("");
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    const [isUploading, setIsUploading] = useState(false);

    const {data: existingFPDFiles, isLoading, error, refetch} = useFirstPartyData(+accountId);

    const existingFPD = existingFPDFiles?.map((fpd) => fpd.name.toLowerCase());

    const handleSelectChange = (value: string, idx: number) => {
        setHeaders((prev) => {
            const newHeaders = [...prev];
            newHeaders[idx] = value;
            return newHeaders;
        });
    };

    useEffect(() => {
        if (fileName.length > 3 && !headers.every((header) => header === "donotuse") && !existingFPD?.includes(fileName.toLowerCase())) {
            setIsBtnDisabled(false);
            setFileNameError(null);
        } else {
            if (fileName.length < 3 && fileName.length > 0) {
                setFileNameError("This field must contain at least 3 characters");
            }
            if (fileName.length > 3 && existingFPD?.includes(fileName.toLowerCase())) {
                setFileNameError("This file name already exists");
            }
            setIsBtnDisabled(true);
        }
    }, [fileName, headers, existingFPD]);


    useEffect(() => {
        const idListValues = idList.map((id) => id.value);
        const newHeaders = file.data[0].map(header =>
            idListValues.includes(header) ? header : "donotuse"
        );
        setHeaders(newHeaders);
        props.setTitle('Uploaded File Details')
    }, [file]);

    const handleSubmit = () => {
        setIsUploading(true);
        //rename the first line columns with the headers
        const newFile = {
            data: [headers, ...file.data.slice(1)],
        };
        //remove the columns that are not used
        const filteredFile: { data: string[][] } = {
            data: newFile.data.map((row) => {
                return row.filter((cell, idx) => headers[idx] !== "donotuse");
            }),
        };

        const csvOutput = jsonToCSV(
            {
                fields: filteredFile!.data[0],
                data: filteredFile!.data,
            },
            {
                quotes: false, //or array of booleans
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: false,
                newline: "\r\n",
                skipEmptyLines: true, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
            }
        );
        // Convert the CSV string to a Blob
        const csvBlob = new Blob([csvOutput], {type: "text/csv"});

        const data = new FormData();
        data.append("file", csvBlob, "filename4.csv");

        accountApi
            .uploadFirstPartyData({
                accountId: +accountId,
                fileName: fileName,
                description: fileDescription,
                data: data,
            })
            .then((res) => {
                appToast.open({
                    toastId: "globalToast",
                    severity: "success",
                    message: "Your First Party Data has been uploaded",
                });
                refetch();
                navigate(pageUtils.getPagePathById(PageId.myDatas, {accountId: accountId}));
            })
            .catch((e) => {
                console.error(e);
                appToast.open({
                    toastId: "globalToast",
                    severity: "error",
                    message: "Error while uploading Your First Party Data, please try later",
                });
            })
            .finally(() => {
                setIsUploading(false);
            });
    };

    if (isUploading) {
        return (
            <Box sx={{textAlign: "center"}} mt={4}>
                <CircularProgress size={24}/>
                <Typography mt={2}>Upload in progress, please wait...</Typography>
            </Box>
        );
    }


    return (
        <Box mt={3}>
            <Typography variant="h6" mb={1}>
                Uploaded File preview (first 4 rows of {profilesFormatter.format(file.data.length - 1)})
            </Typography>
            <Typography mb={2}>
                Here are the header columns and the 4 first rows of your file to confirm that the file you uploaded
                is correct.
            </Typography>
            <Box mb={2} sx={{
                background: "#fafafa",
                padding: "8px 16px",
                borderRadius: "7px",
                border: "1px solid #ececec"
            }}>

                <TableContainer component={Box} sx={{backgroundColor: "#fafafa"}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {file.data[0].map((cell: string, index: number) => (
                                    <TableCell key={index}>
                                        <Typography sx={{fontSize: "14px", fontWeight: "900"}}>{cell}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {file.data.slice(1, 5).map((row, idx) => (
                                <TableRow key={idx} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    {row.map((cell, index) => (
                                        <TableCell key={index}>{cell}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Divider sx={{marginTop: '2rem', marginBottom: '24px'}}/>

            <Typography variant="h6" mb={1}>
                Associate Your Headers with ID Type
            </Typography>
            <Typography mb={2}>
                In order to use this data, you need to associate each column with an ID type. If a column does not
                contain an ID, select "Ignore Column".
            </Typography>

            <Box
                mb={2}
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
                    alignItems: "center",
                    gap: "16px",
                    borderBottom: '1px solid #ececec',
                    paddingBottom: '1.5rem',
                    marginBottom: '24px'

                }}
            >
                {file.data[0].map((cell: string, index: number) => (
                    <Box key={index} mt={1} sx={{width: "180px"}}>
                        <Typography mb={1} sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                            gap: '8px',
                            justifyContent: "center"
                        }}>
                            {cell} {headers[index] !== "donotuse" &&
                            <CheckCircleIcon sx={{fontSize: '15px', color: "#3ab03a"}}/>}
                        </Typography>
                        <Select value={headers[index]}
                                onChange={(e: SelectChangeEvent) => handleSelectChange(e.target.value, index)}
                                fullWidth>
                            <MenuItem value="donotuse">Ignore Column</MenuItem>
                            {idList.map((id) => (
                                <MenuItem key={id.value} value={id.value}>
                                    {id.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                ))}
            </Box>
            <Box sx={{display: "block", margin: "auto", width: "350px"}}>
                <Typography variant="h6" mb={2} sx={{textAlign: "center"}}>
                    Set File Name and Description
                </Typography>
                <Box mb={2}>
                    <Typography mb={1}>File Name *</Typography>
                    <TextField
                        id={"fileName"}
                        value={fileName}
                        sx={{width: "350px"}}
                        onChange={(e) => {
                            setFileName(e.target.value);
                        }}
                        error={fileNameError !== null}
                        helperText={fileNameError}
                    />
                </Box>

                <Box mb={2}>
                    <Typography mb={1}>File Description</Typography>
                    <TextField
                        id={"fileDescription"}
                        value={fileDescription}
                        onChange={(e) => {
                            setFileDescription(e.target.value);
                        }}
                        multiline
                        maxRows={4}
                        sx={{width: "350px"}}
                        inputProps={{
                            style: {
                                height: "70px",
                                padding: "10px 10px !important",
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Button variant="contained" disabled={isBtnDisabled} onClick={handleSubmit}>
                    Upload
                </Button>
            </Box>
        </Box>
    );
};
