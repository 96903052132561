import {TDataColumnModel} from '../../utils/dataColumnModel.utils';

export const listFilesColumns: TDataColumnModel = {
    fileName  : {
      field     : 'fileName',
      headerName: 'List Name',
      flex      : 1,
      type      : 'string'
    },
    description  : {
      field     : 'description',
      headerName: 'Description',
      flex      : 1,
      type      : 'string'
    },
    uploadedAt: {
      field     : 'uploadedAt',
      headerName: 'Upload Date & Time',
      flex      : 1,
      type      : 'string'
    },
    uploadedBy: {
      field     : 'uploadedBy',
      headerName: 'Uploaded By',
      flex      : 1,
      type      : 'string'
    },
    rowsCount : {
      field     : 'rowsCount',
      headerName: 'Records Uploaded',
      type      : 'string',
      align     : 'center', 
      headerAlign : 'center',
      flex      : 1,
    },
    delete    : {
      field      : 'delete',
      headerName : 'Action',
      sortable   : false,
      align      : 'center',
      headerAlign: 'center',
      flex       : 1,
      type       : 'string',
    }
}