import {TextField} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {FieldZone, FormInputContainer} from '../../../components/templates/InfoBlock';
import {EntityTreeView} from '../../../components/form/EntityTreeView';
import Autocomplete from '@mui/material/Autocomplete';
import {IconSet} from '../../../assets/icons';
import {ICountry} from '../../../application/entities/dataTypes/country';
import {Controller, useFormContext} from 'react-hook-form';
import {useCountryQuery} from '../../../components/hooks/data/useCountryQuery';

interface IBrandFormProps {
}


export const BrandForm: FunctionComponent<IBrandFormProps> = (props: IBrandFormProps) => {
  
  const methods   = useFormContext();
  const countryQy = useCountryQuery();
  
  const companyName    = methods.register('companyName', {required: true, minLength: 2});
  const companyUrlList = methods.register('companyUrlList', {required: true, minLength: 2});
  
  methods.register('industries', {required: true,});
  
  const countryData: ICountry[] = countryQy?.data ?? [];
  
  
  return <div className={'flex-h gap-small'}>
    <FormInputContainer>
      <FieldZone title={'Brand Name'} code={'companyName'} required={true}>
        <TextField  {...companyName} error={methods.formState.errors?.companyName} fullWidth/>
      </FieldZone>
      <FieldZone title={'Company URL'} code={'companyUrlList'} required={true}>
        <TextField  {...companyUrlList} error={methods.formState.errors?.companyUrlList} fullWidth/>
      </FieldZone>
    </FormInputContainer>
    <FormInputContainer>
      <FieldZone title={'Industry'} code={'industries'} required={true}>
        <EntityTreeView
          entityName={'industries'}
          defaultField={'name'}
          hierarchyField={'name'}
          singleSelect={true}
          selectedEntityItems={methods.getValues('industries')}
          onChange={(industry) => methods.setValue('industries', industry, {shouldValidate: true, shouldDirty: true})}
        />
      </FieldZone>
      <FieldZone title={'Country'} code={'country'} required={true}>
        <Controller
          name="country"
          rules={{required: 'this is required'}}
          control={methods.control}
          render={({field}) => (
            <Autocomplete
              popupIcon={<IconSet.ChevronDownBlue/>}
              clearIcon={<IconSet.Close/>}
              options={countryQy?.data ?? []}
              getOptionLabel={(option: ICountry) => option?.name ?? '...'}
              clearOnBlur={false}
              value={field.value ? countryData.find(item => item.code === field.value) : null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={methods.formState.errors?.country}
                  InputProps={{...params.InputProps, autoComplete: 'new-password'}}
                />)}
            />
          )}
        />
      </FieldZone>
    </FormInputContainer>
  </div>;
};
