import {styled, Theme,Button} from '@mui/material';
import {PageId} from 'application/pages/pages.config';
import {MainFrame} from 'components/templates/MainFrame';
import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {FormBlock, LabelZone, ShadowBox} from '../../components/templates/InfoBlock';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import React from 'react';
import {ViewSubscription} from '../Accounts/View/ViewSubscription';
import {useTranslation} from 'react-i18next';
import {CompanyFormView} from './shared/CompanyFormView';
import {AccountHeader} from '../Agencies/AccountHeader';
import {useParams,useNavigate} from 'react-router-dom';
import { pageUtils } from 'application/pages/pages.utils';
import {useAccountTracker} from '../Agencies/useAccountTracker';
import { ActionButtons } from 'components/controls/ActionButtons';



interface UpdateCompanyProps {
  companyId?: number;
}

export const ViewCompany = (props: UpdateCompanyProps) => {
  
  const uriParams = useParams<{ companyId: string; }>() as { companyId: string };
  const companyId = props?.companyId ?? +uriParams?.companyId;
  
  const {t}          = useTranslation();
  const accountQuery = useAccountQuery(companyId);
  
  const accountData: IAccounts = accountQuery?.data ?? {};
  const navigate = useNavigate();
  
  // account tracker and mode setter
  useAccountTracker(companyId);

  
  const MAX_WIDTH = '1150px';

  return <MainFrame
    frameId={PageId.companyView}
    browserTitle={'View - ' + accountData?.companyName}
    breadCrumbProps={{crumbTitle:'Company details', noParent: true}}
    mainFrameHeaderContent={<AccountHeader
      accountId={companyId}
      frameId={PageId.companyView}/>}>
    <Container className={'flex-v'} style={{height: '100%'}}>
      <div style={{padding:'2em',overflow: 'auto',maxWidth:MAX_WIDTH, display: 'grid', gridTemplateColumns:'3fr 2fr', gap:'40px'}}>

      
        <FormBlock buttons={<Button data-cy="edit-company-btn"  onClick={() => {navigate(pageUtils.getPagePathById(PageId.companyUpdate ,{companyId:companyId})) }} variant={'outlined'}>Edit Company</Button>} title={'Company Settings'}>

        
          <CompanyFormView   accountId={companyId}/>

        </FormBlock>
        <div>
          <FormBlock style={{height: 'auto'}}  noBorder title={'Subscription Details'}>
            {Boolean(accountData.subscription) && <ViewSubscription
                accountId={companyId} // CMVP-2098
                subscription={{...accountData.subscription, subscriptionPlanId: accountData.subscriptionPlan.id}}
            />}
          </FormBlock>
          <FormBlock style={{height: 'auto'}} className={'shadow-box pad-2'} noTitlePadding={true} noBorder title={'Billing Information'}>
              <LabelZone
                code="billing-contact-email"
                title={'Billing Contact Email'}
                content={accountData.billingEmail}/>
              <LabelZone
                code="vat-number"
                title={'VAT / TAX number'}
                content={accountData.billingVatNumber}/>
          </FormBlock>
        </div>
      </div>
    </Container>
  </MainFrame>;
};


const Container = styled('div')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
}));