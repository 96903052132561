import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import {BackendError} from '../../../types/backendError';




export const useAccountQuery = (accountId: number) => {
  
  const accountsApi     = new objectApi.accounts();
  
  const {isLoading, error, data, refetch, remove} = useQuery<any, BackendError>(['account', accountId,'accounts'], () => {
    return accountsApi.byId(accountId).then(res => res);
    
  }, {
    cacheTime  : 60000,
    enabled    : Boolean(accountId),
  });
  
  return {isLoading, error, data, refetch, remove};
};