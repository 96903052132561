import {Button, MenuItem, Select, TextField} from "@mui/material";
import {accountTypeName} from "application/utils/granter.utils";
import React, {FunctionComponent} from "react";
import {Controller, useForm} from "react-hook-form";
import {objectApi} from "../../application/entities/dataApi";
import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {queryClientRefetcher} from "../../application/utils/useQuery.utils";
import {useAccountQuery} from "../../components/hooks/data/useAccountQuery";
import {useAppToast} from "../../components/live/AppToast";
import {FieldZone, FormBlock, InputFieldLabel, ShadowBox, TextFieldBlock} from "../../components/templates/InfoBlock";
import {BackendError} from "../../types/backendError";

interface IInviteUserProps {
    accountId: number;
    onUserInvited: () => void;
    onClose?: () => void;
}

export const InviteUser: FunctionComponent<IInviteUserProps> = (props: IInviteUserProps) => {
    const accountApi = new objectApi.accounts();
    const appToast = useAppToast();
    const accountQuery = useAccountQuery(props.accountId);
    const accountData: IAccounts = accountQuery?.data ?? {};

    const [invited, setInvited] = React.useState<any[]>([]);

    const {
        handleSubmit,
        control,
        register,
        formState: {isValid, errors},
    } = useForm({mode: "all"});

    React.useEffect(() => {
        register("role");
    }, [props?.accountId]);

    const roletitle = accountData.accountType ? accountData.accountType?.toLocaleLowerCase() + " Role" : "Role";

    async function onSubmit(dta: any) {
        accountApi
            .inviteUser(props.accountId, {
                email: dta.email,
                companyRole: dta.role,
                userBrandPermissions: [],
            })
            .then(() => {
                queryClientRefetcher(["accounts", "companyUsers"]);
                appToast.success({
                    message: "The invitation was sent",
                });
                setInvited([...invited, {email: dta.email, companyRole: dta.role}]);
                if (Boolean(props.onUserInvited)) props.onUserInvited();
                if (props.onClose) props?.onClose();
            })
            .catch((e: BackendError) => {
                appToast.error({
                    message: e?.message ?? "An error occurred",
                });
            });
    }

    return (
        <FormBlock style={{height: "auto", width: "100%"}} noBorder title={`Invite users`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ShadowBox className={"pad"}>
                    <div className={"pad"}>
                        <div className={"flex-h gap-small"}>
                            <div className={"flex-main"}>
                                <FieldZone title={"Email"}>
                                    <TextField
                                        {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                                        fullWidth
                                        error={errors?.email}
                                        data-cy={"invitation-email"}
                                    />
                                </FieldZone>
                            </div>
                            <div>
                                <InputFieldLabel style={{textTransform: "capitalize"}}>{roletitle}</InputFieldLabel>
                                <TextFieldBlock>
                                    <Controller
                                        name="role"
                                        rules={{required: "this is required"}}
                                        control={control}
                                        defaultValue={""}
                                        render={({field}) => (
                                            <Select {...field} sx={{width: "150px"}} data-cy={"invitation-role"}>
                                                <MenuItem data-cy={"superadministrator-role"}
                                                          value={"ACCOUNT_SUPERADMIN"}>
                                                    {accountTypeName["ACCOUNT_SUPERADMIN"]}
                                                </MenuItem>
                                                <MenuItem data-cy={"administrator-role"} value={"ADMINISTRATOR"}>
                                                    {accountTypeName["ADMINISTRATOR"]}
                                                </MenuItem>
                                                <MenuItem data-cy={"viewer-role"} value={"CONTRIBUTOR"}>
                                                    {accountTypeName["CONTRIBUTOR"]}
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </TextFieldBlock>
                            </div>
                        </div>
                        <div className={"flex-h flex-align-middle"}>
                            <Button
                                data-cy="cancel-invite-btn"
                                onClick={() => {
                                    if (props.onClose) props?.onClose();
                                }}
                                color={"error"}
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                            <div className={"flex-main"}></div>
                            <Button data-cy="invite-btn" disabled={!isValid} type={"submit"} variant={"outlined"}>
                                Invite
                            </Button>
                        </div>
                    </div>
                </ShadowBox>
                {Boolean(invited?.length) && (
                    <div className={"pad-2"}>
                        <FormBlock noBorder title={`Invitations`}>
                            {invited.map((user: { email: string; companyRole: string }) => (
                                <div className={"flex-h flex-align-middle gap-small border-b"}>
                                    <div>{user.email}</div>
                                    <div>{user.companyRole}</div>
                                </div>
                            ))}
                        </FormBlock>
                    </div>
                )}
            </form>
        </FormBlock>
    );
};
