import {IAppPage} from './pages.utils.types.tmp';
import {getFlatPaths, PageId} from './pages.config';
import { useQuery } from 'react-query';
import { apiDataFactory, objectApi } from 'application/entities/dataApi';
import { dataObject } from 'application/entities/dataObjects/DataObjectClass';
import { AudienceTypesDto } from 'application/entities/dataTypes/audienceTypes';
import { dataUtils } from 'application/utils/dataState.utils';
import { useUiStore } from 'redux/ui.store';
import { useNavigate } from 'react-router';


export const getPageById = (id: string): IAppPage => {
  const flatRoutes = getFlatPaths();
  
  if (flatRoutes?.[id]) return flatRoutes[id];
  
  return {} as IAppPage; // IAppPage
};

const getPagePathById = (id: PageId | string, replaceArgs: Record<string, string | number | undefined> = {}): string => {
 
  

  let path = getPageById(id)?.path ?? `/404/${id}`;
  
  
 
  return Object.keys(replaceArgs).length ? GFG_Fun(path, replaceArgs) : path;
  
  function GFG_Fun(str: string, replaceArgs: Record<string, string | number | undefined>) {
    
    const addDots = Object.keys(replaceArgs).reduce((acc: Record<string, string>, key: string) => {
      acc[`:${key}`] = `${replaceArgs[key as string]}`;
      return acc;
    }, {});
    
    const RE = new RegExp(Object.keys(addDots).join('|'), 'gi');
    


  
    
    return str.replace(RE, function (matched: string) {
      return addDots[matched as string];
    });
  }
};

const getPageParentFromId = (id: string): IAppPage | undefined => {
  const page = getPageById(id);
  
  if (page._parent) return getPageById(page._parent);
  
  return;
};

export const pageUtils = {
  getPageById,
  getPagePathById,
  getPageParentFromId,
};