import { TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';


export const TextFieldStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {

      'label + &': {
        marginTop: 10,
        // marginLeft: 10,
        color: '#9DAABF',
        // width: '220px',
        height: 30,
        border: '1px solid transparent',
        display: 'flex',
        alignItems: 'center',

      },
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: '1px solid transparent',
      boxShadow: `0 0 6px rgba(196, 211, 241, 0.85)`,
      fontSize: 12,
      height: '100%',
      width: '100%',
      padding: '0px 15px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.2px',
      color: '#3B4559',
      '&:focus': {
        borderRadius: 20,
        border: '0.5px solid #00B5E2',
        backgroundColor: '#FFFFFF',
      },
      // textarea: {
      //   padding: '7px 15px',
      //   backgroundColor: 'red',
      // },

    },
  }),
)(TextField);
