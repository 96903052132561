import {styled} from '@mui/styles';
import {Box, Typography} from '@mui/material';
import { TIcon } from 'assets/icons';
import { GridItemFull, GridMiddle } from 'components/ui/AppElements';
import { AppIcon } from 'components/ui/AppIcon';
import { AppTip } from './AudienceBuilderAppTip';

export interface AppInfoBoxProps {
  text?: string;
  subText?: string;
  helperText?: string;
  icon?: TIcon;
  children?: any;
  deleteComponent?: any;
}

export const AudienceBuilderMenuBox = (props: AppInfoBoxProps) => {
  
  const {text, children, icon, subText, helperText, deleteComponent} = props;
  
  
  return <Box data-cy={text ? 'filtersWrap-' + text : 'filtersWrap'} sx={{mt: 1, mb: 3, px: 1}} style={{width: '240px', position: 'relative'}}>
    <AppInfoBoxTitle>
      <Box sx={{px: 0, mr: 1}} style={{textAlign: 'left', width: '16 px'}}>
        <AppIcon pointer={true} icon={icon ?? 'CaretDown'} fontSize={'small'} color={'#7587A3'}/>
      </Box>
      <GridItemFull>{text && <Typography noWrap={true} sx={{fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize'}}>{text}</Typography>}</GridItemFull>
      {Boolean(deleteComponent) && deleteComponent}
      {helperText && <AppTip title={text} text={helperText}/>}
    </AppInfoBoxTitle>
    {Boolean(subText || Boolean(deleteComponent)) && <Box sx={{py: 0.5, mb: 1}} style={{color: '#7587A3'}}>
      {subText}
    </Box>}
    <Box>
      {Boolean(children) && children}
    </Box>
  </Box>;
};
const AppInfoBoxTitle               = styled(GridMiddle)(({theme}) => ({
  paddingBottom: '4px',
  borderBottom : '1px solid ' + theme.skin.borderColor,
  marginBottom : '0.5rem',
  gap:'0.25rem'
}));