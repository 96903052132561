import { Box, Button, TextField, Typography } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { useFirstPartyData } from "components/hooks/useFirstPartyData";
import { useAppToast } from "components/live/AppToast";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { useEffect, useState } from "react";
type RenameListFormProps = {
  code: string;
  currentName: string;
  accountId: number;
  currentDescription: string;
};

export const RenameListForm = (props: RenameListFormProps) => {
  const { code, currentName, accountId, currentDescription } = props;
  const { data: existingFPDFiles, isLoading, error, refetch } = useFirstPartyData(accountId);
  const appToast = useAppToast();
  const dialogWindow = useOpenDialogWindow();

  const existingFPD = existingFPDFiles?.map((fpd) => fpd.name.toLowerCase());
  const [fileName, setFileName] = useState<string>(currentName);
  const [fileNameError, setFileNameError] = useState<string | null>(null);
  const [fileDescription, setFileDescription] = useState<string>(currentDescription);
  const accountApi = new objectApi.accounts();

  useEffect(() => {
    if (currentName === fileName) {
      setFileNameError(null);
    } else if (fileName.length > 3 && !existingFPD?.includes(fileName.toLowerCase())) {
      setFileNameError(null);
    } else {
      if (fileName.length < 3 && fileName.length > 0) {
        setFileNameError("This field must contain at least 3 characters");
      }
      if (fileName.length > 3 && existingFPD?.includes(fileName.toLowerCase())) {
        setFileNameError("This file name already exists");
      }
    }
  }, [fileName, existingFPD]);

  const handleSubmit = () => {
    const data = {
      filecode: code,
      name: fileName,
      description: fileDescription,
    };
    accountApi
      .renameFirstPartyData(accountId, data)
      .then((res) => {
        refetch();
        dialogWindow.close("RenameListModal");
      })
      .catch((e) => {
        appToast.open({
          toastId: "globalToast",
          severity: "error",
          message: "Error while saving file, please try later",
        });
      });
  };
  return (
    <Box sx={{ minWidth: "350px", p: 2 }}>
      <Box mb={2}>
        <Typography mb={1}>New File Name *</Typography>
        <TextField
          id={"fileName"}
          value={fileName}
          sx={{ width: "100%" }}
          onChange={(e) => {
            setFileName(e.target.value);
          }}
          error={fileNameError !== null}
          helperText={fileNameError}
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>New File Description</Typography>
        <TextField
          id={"fileDescription"}
          value={fileDescription}
          onChange={(e) => {
            setFileDescription(e.target.value);
          }}
          multiline
          maxRows={4}
          sx={{ width: "100%" }}
          inputProps={{
            style: {
              height: "70px",
              padding: "10px 10px !important",
            },
          }}
        />
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          disabled={(currentName === fileName && currentDescription === fileDescription) || fileNameError !== null || fileName.length < 3}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
