import { Skeleton, Theme, styled, Typography, Divider } from "@mui/material"
import { IAudiences } from "application/entities/dataTypes/audiences"
import { ShadowBox } from "components/templates/InfoBlock"
import moment from "moment"
import { useUiStore } from "redux/ui.store"

type TAudienceDetailsProps = {
    audience: IAudiences
    loading: boolean
}
export const AudienceDetails = (props:TAudienceDetailsProps)=>{
    const {audience, loading} = props
    const currentBrand = useUiStore(st => st.currentBrand) 


    return  <>
    <Typography variant={'h5'} style={{paddingBottom:'10px'}}>Audience details</Typography>
    <Divider style={{marginBottom:'30px'}}/>
    <ShadowBox className={'pad-2'} style={{padding:'20px', backgroundColor:'#fff'}} data-cy={'dashboard-your-team'}>
        <DetailLabel>Client</DetailLabel>
        {
        (loading && currentBrand?.companyName) ? <Skeleton  width={200} /> : <DetailValue data-cy="request_brand_name">{currentBrand?.companyName}</DetailValue>
        } 
        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr',gap:'1.5rem', marginTop:'20px'}}>
            <div>
                <DetailLabel>Audience name</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_audience_name">{audience?.name}</DetailValue>}
            </div>
            {audience?.requester ? <div>
                <DetailLabel>Requested by</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_audience_requester">{audience?.requester?.firstName} {audience?.requester?.lastName}</DetailValue>}
            </div>:
            <div>
                <DetailLabel>Created by</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_audience_creator">{audience?.author?.firstName} {audience?.author?.lastName}</DetailValue>}
            </div>
            }
        </div>
        {audience?.approverAsked &&
        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr',gap:'1.5rem', marginTop:'20px'}}>
             <div>
                <DetailLabel>Approver asked</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_requester_name">{audience?.approverAsked?.firstName ? audience?.approverAsked?.firstName +' '+audience?.approverAsked?.lastName: audience?.approverAsked?.email}</DetailValue>}
            </div>
            <div>
                <DetailLabel>Requested on</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_requested_date">{moment(audience.requestApprovalTime).format("D-MMM-YYYY h:mm A")}</DetailValue>}
            </div>
        </div>
        }
        {audience?.activatedByUser &&
            <div style={{display:'grid', gridTemplateColumns:'1fr 1fr',gap:'1.5rem', marginTop:'20px'}}>
                <div>
                <DetailLabel>Enabled by</DetailLabel>
                {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_requester_name">{audience?.activatedByUser?.firstName ? audience?.activatedByUser?.firstName +' '+audience?.activatedByUser?.lastName: audience?.activatedByUser?.email}</DetailValue>}
                </div>
                <div>
                    {/* <DetailLabel>Enabled on</DetailLabel>
                    {loading ? <Skeleton  width={100} /> : <DetailValue data-cy="request_requested_date">{moment(audience.requestApprovalTime).format("D-MMM-YYYY h:mm A")}</DetailValue>} */}
                </div>
            </div>
        }

  </ShadowBox>
  </>
}

const DetailLabel = styled('div')((props: { theme: Theme }) => ({
    color: '#7587A3',
    fontSize: '12px',
    fontWeight:'bold',
    marginBottom:'0.5rem',
  }));
  
  const DetailValue = styled('p')((props: { theme: Theme }) => ({
    fontSize: '12px',
    color:'#3B4559',
    lineHeight: '16px',
    fontWeight:'bold',
  
  }))