import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import React from 'react';
import {Box} from '@mui/material';



export const FloatingMenuHandle = (props: {children?:any, position?: Record<string, any>,hide?: boolean  }) => {
  
  return   !props?.hide ? <ProviderStatusContainer position={props.position} open={true}>
    {props.children}
  </ProviderStatusContainer> : null
};

const ProviderStatusContainer = styled(Box)((props: { open?: boolean, theme: Theme, width?: string, position?: {} }) => ({
  position       : 'absolute',
  fontSize       : '12px',
  fontWeight     : 'bold',
  margin         : '0.5rem',
  backgroundColor: props.theme.palette.background.default,
  boxShadow      : props.open ? props.theme.skin.boxShadow : 'none',
  transition     : 'all 200ms ease-in-out',
  borderBottomLeftRadius: '8px',
  borderTopLeftRadius: '8px',
  overflow       : 'hidden',
  border         : '1px solid transparent'  ,
  width          : props?.width,
  minWidth       : props?.width,
  ...props.position,
  '&:hover': {
    boxShadow      : props.theme.skin.boxShadow,
    backgroundColor: props.theme.palette.background.default
  }
}));