import {Box, Typography,} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components/macro';
import {InputLabel} from '../../../components/form/Input';
import {DetailBlock} from '../../../components/templates/InfoBlock';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {AppIcon} from '../../../components/ui/AppIcon';


interface IClientTypeProps {
  account: Partial<IAccounts>;
}

export const ViewAccountType: React.FC<IClientTypeProps> = (props) => {
  
  const {account} = props;
  const {t}         = useTranslation();
  
  return (
    <>
      <ClientTypeBlock>
        <div>
          <InputLabel>
            {t('account.accountClientType.clientType')}
          </InputLabel>
          {Boolean(account?.dataProvider) && <Box sx={{display: 'flex',gap:'0.25rem'}}>
              <AppIcon fontSize={'small'} icon={'CheckboxTrue'}/> {t('account.accountClientType.dataProvider')}
          </Box>}
          <br/>
          {Boolean(account?.dataClient) && <Box sx={{display: 'flex',gap:'0.25rem'}}>
              <AppIcon fontSize={'small'} icon={'CheckboxTrue'}/><Typography>{t('account.accountClientType.dataClient')}</Typography>
          </Box>}
        </div>
        <Box>
          <InputLabel>
            {t('account.accountClientType.clientTypesDescription')}
          </InputLabel>
          <Description>
              {t('account.textAndWarnings.warnDetailSpecificTotype')}
          </Description>
        </Box>
      </ClientTypeBlock>
    </>
  );
};


const ClientTypeBlock = styled(DetailBlock)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
`;


const Description = styled.div`
  color: #9DAABF;
  font-size: 12px;
  margin-top: 10px;
  width: 220px;
`;
