import {Box, Button, CircularProgress, styled, Typography} from "@mui/material";
import {accessList} from "application/access/access";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {AUTHUser} from "application/utils/AuthUser";
import {EPermission} from "application/utils/granter.utils";
import {HistoryControl} from "components/controls/HistoryControl";
import {ActionGranter} from "components/features/ActionGranter";
import {InlineInput} from "components/form/InlineInput";
import {useSelectedDspChannels} from "components/hooks/data/useDspChannelsQuery";
import {useAppHistory} from "components/hooks/useAppHistory";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {MainFrame} from "components/templates/MainFrame";
import {AppDrawer} from "components/ui/AppDrawer";
import {Columns, GridItemFull, GridMiddle} from "components/ui/AppElements";
import {AppIcon} from "components/ui/AppIcon";
import {AppSlidePanel} from "components/ui/AppSlidePanel";
import {useAccountConfParameters} from "pages/Agencies/useAccountConfParameters";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {AudienceCard} from "pages/Audiences/Card";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import Tour from "reactour";
import {IOpenDialogAction} from "redux/slices/dialog.slices";
import useLocalStorageState from "use-local-storage-state";
import {AudienceBuilderFiltersMenu} from "./components/AudienceBuilderFiltersMenu";
import {AudienceBuilderStatus} from "./components/AudienceBuilderStatus";
import {AudienceBuilderSum} from "./components/AudienceBuilderSum";
import {AudienceBuilderTabMenu} from "./components/AudienceBuilderTabMenu";
import {AudienceFlowColumns} from "./components/CardsZone";
import {HeaderContent} from "./components/HeaderContent";
import {FloatingMenuHandle} from "./components/TransparencyCard/FloatingMenuHandle";
import {ProviderStatus} from "./components/TransparencyCard/ProviderStatus";
import {useAudienceBuilderStore} from "./Store/AudienceBuilderStore";

export const NewAudienceBuilder = () => {
    //store variables
    const audience = useAudienceBuilderStore((st) => st.audience);
    const template = useAudienceBuilderStore((st) => st.audienceTemplate);
    const audienceBuilderActions = useAudienceBuilderStore((st) => st.actions);
    const loading = useAudienceBuilderStore((st) => st.loading);
    const historyLoading = useAudienceBuilderStore((st) => st.historyLoading);
    const isInit = useAudienceBuilderStore((st) => st.isInit);
    const loadingSizeAndPriceError = useAudienceBuilderStore((st) => st.loadingSizeAndPriceError);

    // use hooks
    const account = useAccountTracker(audience?.account?.id);
    const accountConfParameters = useAccountConfParameters(audience?.account?.id);

    const navigate = useNavigate();
    const dialogWindow = useOpenDialogWindow();
    const {t} = useTranslation();
    const {id} = useParams<{ id: string }>() as { id: string };

    const {historyDataState, ...appHistory} = useAppHistory(audience);

    const isBrandAdmin =
        AUTHUser.hasRole(EPermission.COMPANYADMIN || AUTHUser.hasRole(EPermission.EVADMIN)) ||
        AUTHUser.permissions?.brandGrades[0]?.brandPermission === EPermission.BRANDADMIN
            ? true
            : false;

    const [displayWarning, setDisplayWarning] = useLocalStorageState<boolean>("audience-builder-dsp-warning", true);

    //menu highlight
    const frameIds: Record<string, any> = {
        STANDARD: PageId.audienceBuilderForm,
        TRAVEL: PageId.audienceBuilderFormTravel,
        INTEREST: PageId.audienceBuilderFormInterest,
        AUTOMOTIVE: PageId.audienceBuilderFormAutomotive,
        FIRST_PARTY: PageId.audienceBuilderFirstPartyData,
        CPG: PageId.audienceBuilderCPG,
    };

    const frameId = frameIds?.[audience?.audienceType?.code] ?? PageId.audienceBuilderForm;

    // state variables
    const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false); //transparency card
    const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>(undefined);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = React.useState<boolean>(true);

    const [retryApiFetch, setRetryApiFetch] = React.useState<any>(false);
    const [audienceNameState, setAudienceNameState] = React.useState<any>(audience.name);
    const [audienceNameIsFocused, setAudienceNameIsFocused] = useState(false);
    //defaut mode for transparency card
    const defaultMode = "transparency";
    const drawerTitle =
        menuMode === "channels"
            ? t("audience.cardTips.channelTipTitle")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTipTitle")
                : t("audience.cardTips.transparencyTipTitle");
    const drawerTip =
        menuMode === "channels"
            ? t("audience.cardTips.channelTip")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTip")
                : t("audience.cardTips.transparencyTip");
    let provStatus = visibleDrawer ? {right: "340px", top: "0.5rem"} : {right: "-0.5rem", top: "0.5rem"};

    const editMode: boolean = !["ACTIVATED", "ARCHIVED", "PENDING_APPROVAL", "APPROVED"].includes(audience.audienceStatus);

    const intialAudienceName = (): string => {
        if (audience?.audienceType?.code === "INTEREST") {
            return "Untitled Interest Audience";
        } else if (audience?.audienceType?.code === "TRAVEL") {
            return "Untitled Travel Audience";
        } else if (audience?.audienceType?.code === "AUTOMOTIVE") {
            return "Untitled Automotive Audience";
        } else if (audience?.audienceType?.code === "FIRST_PARTY_DATA") {
            return "Untitled My Data Audience";
        } else if (audience?.audienceType?.code === "CPG") {
            return "Untitled My CPG Audience";
        } else {
            return "Untitled Audience";
        }
    };

    const defautAudienceName = (): string => {
        return intialAudienceName() + " #" + audience.id;
    };

    React.useEffect(() => {
        if (id) {
            audienceBuilderActions.reset();
            audienceBuilderActions.init(Number(id));
        }
        dialogWindow.close("AudiencePreviewPopup");
    }, [id]);

    React.useEffect(() => {
        setAudienceNameState(audience.name);
        if (audience.name === intialAudienceName()) {
            audienceBuilderActions.updateAudienceName(defautAudienceName());
        }
    }, [audience.name]);

    useEffect(() => {

        return () => {
            audienceBuilderActions.reset();
        };
    }, []);

    useEffect(() => {
        if (template[0]) {
            audienceBuilderActions.setActiveFeatureGroupCode(template[0].featureGroupCode);
        }
    }, [template]);

    useEffect(() => {
        if (audience?.audienceType?.code === "SEGMENTS") {
            navigate(pageUtils.getPagePathById(PageId.segmentBuilder, {id: audience.id}));
        }
    }, [audience]);

    //undo redo
    React.useEffect(() => {
        if (historyDataState) {
            audienceBuilderActions.restoreAudience(historyDataState as IAudiences);
        }
    }, [historyDataState]);

    //DSP warning
    let dspWarningDialog = {
        buttonActions: {
            acceptButton: isBrandAdmin ? "Go to enablement setup" : "Close",
            cancelButton: "Don't remind me",
            ...(isBrandAdmin && {dismissButton: "Close"}),
        },
        description: isBrandAdmin
            ? "To enable your audience, you must connect and active at least one enablement channel"
            : "To enable an audience you must connect and active at least one enablement channel. Build your audience  then contact your Evorra admin for the next steps",
    };
    const dspWarning: IOpenDialogAction = {
        dialogId: "dspWarning",
        buttonActions: dspWarningDialog.buttonActions,
        title: "Connect an enablement channel",
        description: dspWarningDialog.description,
        icon: "Info",
        onAccept: () => {
            if (isBrandAdmin) {
                navigate(pageUtils.getPagePathById(PageId.channelsSetup, {accountId: AUTHUser.currentBrandId}));
            } else {
                return;
            }
        },
    };
    dspWarning.onClose = () => {
        setDisplayWarning(false);
        dialogWindow.close("dspWarning");
    };

    const dspSelected = useSelectedDspChannels(audience?.account?.id);
    if (!dspSelected.isLoading) {
        dspSelected.data?.length === 0 && displayWarning && dialogWindow.open({...dspWarning});
        dspSelected.data?.filter((channel: any) => channel.isActive).length === 0 && displayWarning && dialogWindow.open({...dspWarning});
    }

    // Audience name input
    const timerForAudienceName: any = React.useRef();
    const headerTitle = (
        <InlineInput
            dataCy="audience-name-input"
            defaultValue={audienceNameIsFocused && audience.name === defautAudienceName() ? "" : audience.name}
            placeholder={"Please enter a name (min 4 characters)"}
            onFocus={(value: React.FocusEvent<HTMLInputElement>) => {
                setAudienceNameIsFocused(true);
            }}
            onKeyUp={(value: React.KeyboardEvent<HTMLInputElement>) => {
                if (audience.name !== value.target.value && value.target.value.length > 3) {
                    if (timerForAudienceName.current) {
                        window.clearTimeout(timerForAudienceName.current);
                    }
                    timerForAudienceName.current = window.setTimeout(() => {
                        if (value.target.value.length > 3) {
                            setAudienceNameState(value.target.value);
                            audienceBuilderActions.updateAudienceName(value.target.value);
                        }
                    }, 1500);
                }
            }}
            onBlur={(value: React.FocusEvent<HTMLInputElement>) => {
                if (value.target.value.length > 3 && value.target.value !== audience.name) {
                    setAudienceNameState(value.target.value);
                    audienceBuilderActions.updateAudienceName(value.target.value);
                }
                setAudienceNameIsFocused(false);
            }}
            onChange={(value: string) => {
                setAudienceNameState(value);
            }}
            hasError={audienceNameState?.length < 4 && audienceNameIsFocused}
        />
    );

    //Transparency card
    function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
        if (mode !== menuMode) {
            setMenuMode(mode);
            setVisibleDrawer(true);
        }
    }

    function handleToggleDrawer(fact: any) {
        if (fact && !menuMode) setMenuMode(defaultMode);
        setVisibleDrawer(fact);
    }

    //error Api on CPM and profiles
    const retryUpdateAudience = () => {
        setRetryApiFetch(true);
        useAudienceBuilderStore
            .getState()
            .actions.updateAudienceSizeAndPrice()
            .then(() => setRetryApiFetch(false));
    };

    const ApiError = () => {
        return (
            <Box className={"flex-h flex-align-middle"} padding={[0, 1]}>
                <Typography noWrap={true} style={{marginRight: "20px", fontWeight: "bold"}} color={"#FC6050"}>
                    An error occurred while fetching the Unique Profiles and CPM.
                </Typography>
                <Button size={"medium"} color={"error"} variant="contained" onClick={retryUpdateAudience}>
                    <Typography noWrap={true}>Try again</Typography>
                </Button>
            </Box>
        );
    };

    const StepRender = (props: { title?: string; description: string; hint?: string }) => {
        const {title, description, hint} = props;

        return (
            <>
                <Typography variant={"h5"}>{title}</Typography>
                <Typography variant={"body1"}>{description}</Typography>
                {hint && (
                    <Typography variant={"body1"} style={{marginTop: "0.5rem", fontStyle: "italic"}}>
                        {hint}
                    </Typography>
                )}
            </>
        );
    };

    //React Tour
    const [tourOpen, setTourOpen] = React.useState<any>(false);

    const commonSteps = [
        {
            selector: '[data-cy="Input"]',
            content: StepRender({
                title: "Change the Audience's Name",
                description: "Be sure and name your audience to find it easier in your Audience Library. Audience names must be at least 4 characters long.",
            }),
        },
        {
            selector: '[data-cy="buttonAddTag"]',
            content: StepRender({
                title: "Adding tags",
                description: "We highly recommend you add tags to your audience to be able to group, sort and find it more easily in your Audience Library.",
            }),
        },
        {
            selector: '[data-cy="cpm-profiles"]',
            content: StepRender({
                title: "CPM and Unique Profiles",
                description:
                    "As you build your audience and add filters, the Estimated CPM and the Unique Profiles size will update here based on the selections you have made. You are always in control of your audience cost.",
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
            },
        },
        {
            selector: '[data-cy="btnActionToggleAudienceDrawer"]',
            content: StepRender({
                title: "More informations about your audience",
                description: "You can see more details about your audience by clicking on this button.",
            }),
        },
        {
            selector: '[data-cy="AppDrawer"]',
            content: StepRender({
                title: "Transparency Card",
                description:
                    "The Transparency Card gives you more details about the makeup of your audience, its recency, data sources and types of IDs it has behind it.",
            }),
            action: () => {
                handleToggleDrawer(true);
                setDrawerMode("transparency");
            },
        },
        {
            selector: '[data-cy="btnActionOpenChannels"]',
            content: StepRender({
                title: "Estimated Audiences by Channel",
                description:
                    "For all the Enablement Channels you've connected, this tab allows you to see the maximum potential audience you could send to a channel to fuel your campaign.",
            }),
            action: () => {
                handleToggleDrawer(true);
                setDrawerMode("channels");
            },
        },
        {
            selector: '[data-cy="AppDrawer"]',
            content: StepRender({
                title: "Estimated Audiences by Channel",
                description:
                    "For all the Enablement Channels you've connected, this tab allows you to see the maximum potential audience you could send to a channel to fuel your campaign and the associated data CPM.",
            }),
            action: () => {
                handleToggleDrawer(true);
                setDrawerMode("channels");
            },
        },
        {
            selector: '[data-cy="enable-audience-btn"]',
            content: StepRender({
                title: "Enable Audience",
                description: "When your audience is ready and you want to send it to your channels of choice, you can enable it by clicking on this button.",
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
            },
        },
    ];

    const stepsTourAdvanced = [
        {
            content: StepRender({
                title: "Welcome",
                description:
                    "Welcome to our Advanced Audience Builder. Here, you can create audiences anyway you want using a wide range of filters available on Evorra",
                hint: 'You can go to the next step by clicking the "Next" button or by hitting the right arrow key on your keyboard.',
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
                audienceBuilderActions.setActiveFeatureGroupCode("BASICS");
            },
        },
        {
            selector: '[data-cy="tab-menu"]',
            content: StepRender({
                title: "Filter categories",
                description: "This menu allows you to navigate through various filter categories. Click on the category to view available filters.",
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
                audienceBuilderActions.setActiveFeatureGroupCode("BASICS");
            },
        },
        {
            selector: '[data-cy="filters-list"]',
            content: StepRender({
                title: "Data Sources Filters",
                description:
                    "In the Data Sources category, you can filter audiences by their data source and the location of the audience at a country level.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("BASICS");
            },
        },
        {
            selector: '[data-cy="filters-list"]',
            content: StepRender({
                title: "Strategy Filters",
                description:
                    "Under Strategy you can filter audiences based on their interest they have shown and what level of intent they are showing to purchase.",
                hint: "We are still building out intent filters as we onboard more data partners. Not all interest areas will have intent audiences available.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("STRATEGY");
            },
        },
        {
            selector: '[data-cy="filters-list"]',
            content: StepRender({
                title: "Age, Gender and Income Filters",
                description: "Under Demographics you can filter audiences based on age groups or ranges, gender and income ranges for some geographies.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("DEMOGRAPHICS");
            },
        },
        {
            selector: '[data-cy="filters-list"]',
            content: StepRender({
                title: "General Filters",
                description:
                    "Under Filters, you can add an Exclusion list, Specify the Recency of the Audiences you want or the Degree of Certainty for any probabilistic attributes i n your audience.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("FILTERS");
            },
        },
        ...commonSteps,
    ];

    const stepsTourInterest = [
        {
            content: StepRender({
                title: "Welcome",
                description: "Welcome to our Interest based Audience Builder. Here, you can quickly build interest based audiences.",
                hint: 'You can go to the next step by clicking the "Next" button or by hitting the right arrow key on your keyboard',
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
            },
        },
        {
            selector: '[data-cy="filtersWrap-Interest"]',
            content: StepRender({
                title: "Interest Filter",
                description: "You can filter your audience by interest. Add as many interest as you want to increase your potential Unique Profiles.",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Location"]',
            content: StepRender({
                title: "Location Filter",
                description: "If you want to filter your audience by location, this filter will help you do that.",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Exclusion list"]',
            content: StepRender({
                title: "Exclusion list",
                description: 'You can use an exclusion list uploaded in "My lists" to exclude audiences you already have as customers or prospects.',
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Recency"]',
            content: StepRender({
                title: "Recency",
                description: "The recency filter allows you to target people based on how recently they have shown the interests you are looking to target.",
            }),
        },
        ...commonSteps,
    ];

    const stepsTourTravel = [
        {
            content: StepRender({
                title: "Welcome",
                description: "This Travel Audience Builder lets you build audiences based on individuals in-market, and searching for flights.",
                hint: 'You can go to the next step by clicking the "Next" button or by hitting the right arrow key on your keyboard.',
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
                audienceBuilderActions.setActiveFeatureGroupCode("TRAVEL");
            },
        },
        {
            selector: '[data-cy="filtersWrap-Travel route"]',
            content: StepRender({
                title: "Travel Route",
                description: "You can filter audiences based on where they are travelling from or where they are looking to travel.",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Date range"]',
            content: StepRender({
                title: "Date Range",
                description:
                    "Filtering by date range enables you to build audiences who are looking to fly within a specific time period (ie. October or Summer months)",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Recency"]',
            content: StepRender({
                title: "Recency",
                description: "To target people who have traveled recently, you can do this via the recency filter.",
            }),
        },
        ...commonSteps,
    ];

    const stepsTourAutomotive = [
        {
            content: StepRender({
                title: "Welcome",
                description: "This Automotive Audience Builder lets you build audiences based on vehicle owned and interest.",
                hint: 'You can go to the next step by clicking the "Next" button or by hitting the right arrow key on your keyboard.',
            }),
            action: () => {
                handleToggleDrawer(false);
                setDrawerMode("transparency");
                audienceBuilderActions.setActiveFeatureGroupCode("CAROWNED");
            },
        },
        {
            selector: '[data-cy="filtersWrap-Model"]',
            content: StepRender({
                title: "Model",
                description: "You can filter audiences based on where the vehicle brand/model owned.",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Vehicle age"]',
            content: StepRender({
                title: "Vehicle Age",
                description:
                    "Filtering by vehicle age enables you to build audiences who have owned a vehicle for a specific time period (ie. 1 year or 5 years)",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Brand loyalty"]',
            content: StepRender({
                title: "Brand Loyalty",
                description: "To target people who are loyal to a specific brand",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("CAROWNED");
            },
        },
        {
            selector: '[data-cy="filtersWrap-Model"]',
            content: StepRender({
                title: "Model",
                description: "You can filter audiences based on where the vehicle brand/model interest.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("CARINTEREST");
            },
        },
        {
            selector: '[data-cy="filtersWrap-Vehicle segment"]',
            content: StepRender({
                title: "Vehicle Segment",
                description: "Filtering by segment enables you to build audiences who are interested by a vehicle specific segment",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Vehicle value"]',
            content: StepRender({
                title: "Vehicle Value",
                description: "Target a value range of vehicle",
            }),
        },

        {
            selector: '[data-cy="filtersWrap-Energy"]',
            content: StepRender({
                title: "Vehicle Energy",
                description: "Select a vehicle energy type",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Status"]',
            content: StepRender({
                title: "Vehicle Status",
                description: "You can filter audiences based on where the vehicle status.",
            }),
        },
        {
            selector: '[data-cy="filtersWrap-Purchase method"]',
            content: StepRender({
                title: "Purchase Method",
                description: "You can filter audiences based on where the purchase method.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("CARINTEREST");
            },
        },
        {
            selector: '[data-cy="filters-list"]',
            content: StepRender({
                title: "Age, Gender and Income Filters",
                description: "Under Demographics you can filter audiences based on age groups or ranges, gender and income ranges for some geographies.",
            }),
            action: () => {
                audienceBuilderActions.setActiveFeatureGroupCode("DEMOGRAPHICS");
            },
        },

        ...commonSteps,
    ];

    const steps =
        audience.audienceType?.code === "INTEREST"
            ? stepsTourInterest
            : audience.audienceType?.code === "TRAVEL"
                ? stepsTourTravel
                : audience.audienceType?.code === "AUTOMOTIVE"
                    ? stepsTourAutomotive
                    : stepsTourAdvanced;

    return (
        <>
            {!isInit ? (
                <Box
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress size={24}/>
                    <p style={{color: "#3b4559", marginTop: "10px"}}>Loading, please wait...</p>
                </Box>
            ) : (
                <MainFrame
                    headerContent={<HeaderContent audience={audience}/>}
                    headerProps={{headerTitle}}
                    loading={loading}
                    browserTitle={audience.name ?? undefined}
                    //backendError={loadingError}
                    frameId={frameId}
                >
                    <Box style={{height: "100%", width: "100%", position: "relative"}}>
                        <Tour
                            accentColor="#00B5E2"
                            rounded={10}
                            startAt={0}
                            prevButton={<Button variant={"outlined"}>Back</Button>}
                            badgeContent={(curr, tot) => `${curr}/${tot}`}
                            lastStepNextButton={<Button variant={"contained"}>Done! </Button>}
                            showNavigation={false}
                            steps={steps}
                            isOpen={tourOpen}
                            disableInteraction={true}
                            nextButton={<Button variant={"contained"}>Next</Button>}
                            onRequestClose={() => setTourOpen(false)}
                        />

                        <Box
                            style={{
                                display: "flex",
                                maxHeight: "100%",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                            }}
                        >
                            <ActionGranter accesses={accessList.audiences.edit}>
                                <SlideParent isFilterMenuOpen={isFilterMenuOpen}>
                                    <div className={"flex-v"} style={{backgroundColor: "#F0F2F7"}}>
                                        <div className={"flex-main"} data-cy="tab-menu" style={{overflow: "auto"}}>
                                            <AudienceBuilderTabMenu template={template}/>
                                        </div>
                                        <div>
                                            <HistoryControl
                                                loading={historyLoading}
                                                hasPrevious={appHistory.hasPrevious}
                                                hasNext={appHistory.hasNext}
                                                onPrevious={appHistory.stepBackward}
                                                onNext={appHistory.stepForward}
                                            />
                                        </div>
                                        <Box sx={{p: 1, px: 0}}>
                                            <Box>
                                                <AppIcon onClick={() => setTourOpen(true)} icon={"Tip"}/>
                                            </Box>
                                            <Box style={{fontSize: "10px", textAlign: "center"}}>Quick Tour</Box>
                                        </Box>
                                    </div>
                                    <AppSlidePanel isOpen={isFilterMenuOpen}
                                                   onClose={(isOpen) => setIsFilterMenuOpen(isOpen)}>
                                        <AudienceBuilderFiltersMenu template={template}
                                                                    accountConfParameters={accountConfParameters.data}/>
                                    </AppSlidePanel>
                                </SlideParent>
                            </ActionGranter>

                            <BuilderThumbs data-cy={"audienceBuilderContainer"}>
                                <div
                                    style={{
                                        position: "relative",
                                        zIndex: 0,
                                        height: "calc(100% - 65px)",
                                        width: "auto",
                                    }}
                                >
                                    {!loading && <AudienceFlowColumns/>}
                                </div>
                            </BuilderThumbs>
                        </Box>
                        <AudienceStatus>
                            {Boolean(loadingSizeAndPriceError && !retryApiFetch && editMode && audience.audienceStatus !== "PENDING_APPROVAL") ? (
                                <ApiError/>
                            ) : (
                                <>
                                    <Box>
                                        <AudienceBuilderSum/>
                                    </Box>
                                    <Box>
                                        <AudienceBuilderStatus/>
                                    </Box>
                                </>
                            )}
                        </AudienceStatus>

                        <FloatingMenuHandle position={provStatus}>
                            <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
                                <button data-cy={"btnActionToggleAudienceDrawer"}
                                        onClick={() => handleToggleDrawer(!visibleDrawer)}>
                                    <AppIcon
                                        themeColor={!visibleDrawer ? "primary" : "secondary"}
                                        fontSize={"small"}
                                        icon={"ChevronDownIcon"}
                                        rotate={visibleDrawer ? -90 : 90}
                                    />
                                </button>
                                <button data-cy={"btnActionOpenTransparencyCard"}
                                        onClick={() => setDrawerMode("transparency")}>
                                    <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"}
                                             fontSize={"small"} icon={"FileSearch"}/>
                                </button>
                                <button data-cy={"btnActionOpenChannels"} onClick={() => setDrawerMode("channels")}>
                                    <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"}
                                             icon={"ChannelsNav"}/>
                                </button>
                            </div>
                        </FloatingMenuHandle>
                        {Boolean(visibleDrawer) && (
                            <Box
                                sx={{
                                    mb: 3,
                                    position: "absolute",
                                    top: 0,
                                    width: "auto",
                                    right: 0,
                                    height: "calc(100% - 2rem)",
                                }}
                            >
                                <AppDrawer
                                    title={drawerTitle}
                                    helperText={drawerTip}
                                    helperTitle={drawerTitle}
                                    hideHandle={true}
                                    onClose={() => {
                                        handleToggleDrawer(false);
                                    }}
                                    open={visibleDrawer}
                                >
                                    <>
                                        {menuMode === "channels" &&
                                            <ProviderStatus audienceData={audience} position={provStatus}/>}
                                        {menuMode !== "channels" &&
                                            <AudienceCard menuMode={menuMode} audience={audience}/>}
                                    </>
                                </AppDrawer>
                            </Box>
                        )}
                    </Box>
                </MainFrame>
            )}
        </>
    );
};

//Styled div
const SlideParent = styled(Columns)((props: { isFilterMenuOpen: boolean }) => ({
    zIndex: 1,
    top: 0,
    marginTop: 0,
    position: "relative",
    height: "100%",
    width: props.isFilterMenuOpen ? "364px !important" : "110px !important",
}));

export const AudienceStatus = styled(GridMiddle)((props) => ({
    padding: "0.5rem",
    zIndex: 0,
    bottom: 0,
    right: 0,
    borderRadius: "0px",
    position: "absolute",
    maxWidth: "100%",
    width: "100%",
    overflow: "hidden",
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "right",
    backgroundColor: "transparent",
}));

const BuilderThumbs = styled(GridItemFull)((props) => ({
    height: "100%",
    width: "100%",
    overflow: "auto",
}));
