import {Auth,} from 'aws-amplify';
import {CognitoUserSession, ISignUpResult} from 'amazon-cognito-identity-js';
import {AuthException} from 'application/utils/exceptions.utils';

export interface ISignUpAction {
  email: string;
  username: string;
  family_name: string;
  company: string;
  companyUrl: string;
  password: string;
  phoneNumber?: string; // E.164 number convention
}

export interface IConfirmSignUp {
  email: string;
  code: string;
}

export interface ISignIn {
  email: string;
  password: string;
}

export interface IUserInvitationArgs {
  email: string;
  password: string;
  tempPassword: string;
  username: string;
  family_name: string;
  company: string;
  companyUrl: string;
  phoneNumber?: string;
  given_name?: string;
}

export interface IRetrievePassword {
  email: string;
}

export interface IRedefinePassword {
  email: string;
  code: string;
  password: string;
}

/** @deprecated */
async function signIn({email, password}: ISignIn): Promise<any> {
  
  return Auth.signIn(email, password)
             .then(res => {
               return res;
             });
  // .catch(e => {throw AuthException(e);});
}

/** @deprecated */
async function retrievePassword({
                                  email,
                                }: IRetrievePassword): Promise<ISignUpResult | any> {
  return Auth.forgotPassword(email)
             .then((res) => {
    
               return res;
             })
             .catch((error) => {
    
               throw AuthException(error);
             });
}

/** @deprecated */
async function redefinePassword({
                                  email,
                                  code,
                                  password,
                                }: IRedefinePassword): Promise<ISignUpResult | any> {
  
  return Auth.forgotPasswordSubmit(email, code, password)
             .then((res) => {
    
               return res;
             })
             .catch((error) => {
    
               throw AuthException(error);
             });
}

async function userInvitation({
                                email,
                                password,
                                tempPassword,
                              }: IUserInvitationArgs) {
  
  console.log('First step credentials', {
    email,
    tempPassword,
  });
  
  return await Auth.signIn(email, tempPassword)
                   .then((user) => {
                      console.log('first signin granted',{user})
                     if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                       console.log('NEW_PASSWORD_REQUIRED',{user})
                       return Auth.completeNewPassword(
                         user, // the Cognito User Object
                         password, // the new password
                       )
                                  .then((userNew) => {
                                    // at this time the user is logged in if no MFA required
                                    return Auth.currentAuthenticatedUser()
                                               .then((userData) => {
                                                 return userData;
                                               })
                                               .catch((error) => {
                                                 throw AuthException(error);
                                               });
                                  })
                                  .catch((error) => {
                                    // throw new Error(error.code);
                                    throw AuthException(error);
                                  });
                     } else {
                       //
                       throw AuthException({} as any);
                     }
                   })
                   .catch((error) => {
                     throw AuthException(error);
                   });
}

async function signOut() {
  try {
    return await Auth.signOut(); // { global: true }
  } catch (error: any) {
    console.log('error signing out: ', error);
    throw AuthException(error);
  }
}

async function getCurrentSession() {
  
  return Auth.currentSession()
             .then((data: CognitoUserSession) => {
               return data;
             })
             .catch((error) => {
               signOut();
               throw AuthException(error);
             });
}

export const authService = {
  signIn,
  retrievePassword,
  redefinePassword,
  signOut,
  userInvitation,
  getCurrentSession,
};
