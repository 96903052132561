import {TIcon} from '../../../../assets/icons';
import {Box, styled} from '@mui/material';
import {AppIcon} from '../../../../components/ui/AppIcon';

export interface FeatureNodeBoxProps {
  icon?: TIcon;
  gutterColor?: string;
  children?: any;
  viewMode?: boolean;
  removeAction?: () => void;
}

export const AudienceFeatureNodeBox = (props: FeatureNodeBoxProps) => {
  return <NodeBoxContainer viewMode={props?.viewMode ?? false}>
    <NodeBoxGutter gutterColor={props?.gutterColor}>
      <Box sx={{p: 0.5}}>
        <AppIcon display={'block'}
                 fontSize={'tiny'} color={'#FFFFFF'} icon={props?.icon ?? 'SettingsIcon'}/>
      </Box>
      {Boolean(props?.removeAction) && <Box sx={{p: 0.5, cursor: 'pointer'}} data-cy={'audience-box-delete'}>
          <AppIcon display={'block'}
                   onClick={() => {if (props.removeAction) props.removeAction();}}
                   fontSize={'tiny'}
                   color={'#FFFFFF'} icon={'Trash'}/>
      </Box>}
    </NodeBoxGutter>
    <div style={{flex: 1,padding:'0 0.25rem'}}>{props.children}</div>
  </NodeBoxContainer>;
};
export const NodeBoxContainer = styled<any>('div')((props) => ({
  display        : 'flex',
  width          : 'auto',
  borderRadius   : '4px',
  backgroundColor: props.theme.palette.background.default,
  boxShadow: props.theme.skin.boxShadow,
  //overflow       : 'hidden',
  position : 'relative',
  minWidth : '180px',
  maxWidth : '180px',
  minHeight: '52px',
  height   : 'auto',
  '&:hover': {
    boxShadow: props.theme.skin.boxShadowHover,
  }
}));
export const NodeBoxGutter    = styled<any>(Box)((props) => ({
  display               : 'flex',
  flexDirection         : 'column',
  borderTopLeftRadius   : '4px',
  borderBottomLeftRadius: '4px',
  backgroundColor       : props?.gutterColor ?? '#8FB6BC',
  justifyContent        : 'space-between'
}));