import {create} from "zustand";
import {mountStoreDevtool} from "simple-zustand-devtools";
import {objectApi} from "../../application/entities/dataApi";
import {IExternalSegment} from "../../application/entities/dataTypes/externalSegment";
import axios, {CancelTokenSource} from "axios";
import {AUTHUser} from "../../application/utils/AuthUser";
import {DspCampaignInfo} from "../../application/entities/dataTypes/dspCampaignInfo";
import {DEFAULT_DISTANCE} from "./Segments.utils";


export type SearchType = 'keyword' | 'nearText' | 'hybrid' | 'strictKeywords'

type SegmentBuilderStoreType = {
    currency: string
    currencyRate?: number
    query?: string
    isLoading: boolean
    sidebarLoading: boolean
    error?: string
    accountId?: number
    searchType: SearchType
    distance: number
    limit: number
    countryCode: string[]
    providerCode: string[]
    countriesList: Array<{ value: string, label: string }>
    providersList: Array<{ value: string, label: string }>
    selectedSegments: IExternalSegment[]
    searchDone: boolean
    displaySegmentSelected: boolean
    getCountryLabelByCode: (code: string) => string
    getProviderLabelByCode: (code: string) => string
    init: (audienceId: number) => void
    reset: () => void;
    cancelToken?: CancelTokenSource;
    audienceId?: number
    updateSelectedSegments: (segments: IExternalSegment[]) => void
    dspCampaignInfo?: DspCampaignInfo[]
    channelsSelected?: string[]
    displaySidePanel: boolean
};

const accountsApi = new objectApi.accounts();
const audienceApi = new objectApi.audiences();


const initialState: Omit<SegmentBuilderStoreType, 'init' | 'reset' | 'getCountryLabelByCode' | 'getProviderLabelByCode' | 'updateSelectedSegments'> = {
    currency: 'USD',
    isLoading: true,
    sidebarLoading: false,
    query: undefined,
    searchType: 'nearText',
    displaySegmentSelected: false,
    distance: DEFAULT_DISTANCE,
    limit: 50,
    countryCode: [],
    providerCode: [],
    countriesList: [{
        value: '',
        label: 'Loading Countries'
    }],
    selectedSegments: [],
    providersList: [{
        value: '',
        label: 'Loading Providers'
    }],
    searchDone: false,
    displaySidePanel: false

};


export const useSegmentBuilderStore = create<SegmentBuilderStoreType>((set, get) => ({
    ...initialState,
    init: (audienceId: number) => {
        console.log('init', audienceId)
        accountsApi.getSegmentsParameters(+audienceId).then((res) => {
            let currencyRate = 1
            if (res.data.currencyConversionRate) {
                currencyRate = res.data.currencyConversionRate
            }
            set({currencyRate: currencyRate})
        })
        audienceApi.byId(audienceId).then((res) => {
            const accountId = res.account.id
            console.log('audience', res)
            set({
                audienceId: audienceId,
                query: "",
                selectedSegments: convertSegmentAudienceFeatureValuesToObject(res.audienceFeatures[0]?.featureValues ?? []),
                currency: res.displayCurrency ?? 'USD',
                accountId: +accountId,
                searchDone: res.audienceFeatures[0]?.featureValues ? true : false,
                displaySegmentSelected: res.audienceFeatures[0]?.featureValues ? true : false,
                dspCampaignInfo: res.dspCampaignInfo
            })

            console.log('audience', res)
            //get providers
            accountsApi.getSegmentsProviders(+accountId).then((providers) => {
                const accountProviders = providers.data.providers.map((provider: any) => {
                    return {
                        value: provider.providerCode,
                        label: provider.providerName
                    }
                })
                set({
                    providersList: [
                        {value: '', label: 'All Providers'},
                        ...accountProviders
                    ]
                })
            })

            AUTHUser.setCurrentBrandId(+accountId)

            //get countries
            accountsApi.getSegmentsCountries(+accountId).then((countries) => {
                const accountCountries = countries.data.countries.map((country: any) => {
                    return {
                        value: country.code,
                        label: country.name
                    }
                })
                set({
                    countriesList: [
                        {value: '', label: 'All Countries'},
                        ...accountCountries
                    ]
                })


            })
            set({isLoading: false})
        }).catch(e => console.log(`Error - get audience ${audienceId}`, e))
    },
    reset: () => set(initialState),

    getCountryLabelByCode: (code: string) => {
        return get().countriesList.find((country) => country.value === code)?.label ?? code;
    },

    getProviderLabelByCode: (code: string) => {
        return get().providersList.find((provider) => provider.value === code)?.label ?? code;
    },

    updateSelectedSegments: (segments: IExternalSegment[]) => {
        set({selectedSegments: segments, sidebarLoading: true})

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = get().cancelToken
        if (token) {
            token.cancel("Operation canceled by the user.");
        }
        set({cancelToken: source})
        console.log('updateAudienceSegments', segments)
        const featureValues = segments.length > 0 ? convertSegmentForApiCall(segments) : []
        audienceApi.createUpdateAudienceFeature(get().audienceId!, 43, {featureValues: featureValues}, source.token).then((res: any) => {
            console.log('res', res)
            console.log('converted feature', convertSegmentAudienceFeatureValuesToObject(res.data.audienceFeatures[0].featureValues))
            console.log('selectedSegments', get().selectedSegments)
            set({
                selectedSegments: convertSegmentAudienceFeatureValuesToObject(res.data.audienceFeatures[0].featureValues),
                dspCampaignInfo: res.data.dspCampaignInfo,
                sidebarLoading: false
            })

        }).catch(e => {
            console.log('error', e)
            set({sidebarLoading: false})
        })


    },

}));


const convertSegmentForApiCall = (segments: IExternalSegment[]) => {
    return segments.map(segment => {
        return JSON.stringify(segment)
    })
}

const convertSegmentAudienceFeatureValuesToObject = (featureValues: Array<any>) => {
    if (featureValues.length === 0) return []
    return featureValues.map(featureValue => {
        return JSON.parse(featureValue)
    })
}

if (process.env.NODE_ENV === "development") {
    mountStoreDevtool("useSegmentBuilderStore", useSegmentBuilderStore);
}
