import { Button, Typography } from "@mui/material";
import { ColumnsMiddle } from "components/ui/AppElements";
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import { useNavigate } from "react-router-dom";
import { pageUtils } from "../../application/pages/pages.utils";
import { IAccessOptions, TAccessObjMatch } from "../../application/utils/granter.utils";
import { TIcon } from "../../assets/icons";
import { ActionGranter } from "../features/ActionGranter";

export interface TActionBase<T = any> {
  title: string | ((args: any) => string);
  variant?: "text" | "outlined" | "contained";
  color?: "success" | "error";
  icon?: TIcon | ((args: any) => TIcon);
  action?: any;
  actionVars?: string[];
  pageId?: string;
  accesses?: IAccessOptions;
  objMatch?: TAccessObjMatch;
  condition?: (args: T) => boolean;
  conditionDisable?: (args: T) => boolean;
  tip?: (args: T) => string | any;
  code?: string;
  path?: string;
}

type ActionBaseType = {
  title: string;
  variant?: "text" | "outlined" | "contained";
};
// future usage
type aa =
  | ActionBaseType
  | (ActionBaseType & { pageId?: string })
  | (ActionBaseType & { action?: any; actionVars?: string[] })
  | (ActionBaseType & {
      accesses?: IAccessOptions;
      objMatch?: TAccessObjMatch;
    });

export interface IActionButtonsList<T = any> {
  [index: string]: IActionButtons<T>;
}

export interface IActionButtons<T = any> {
  [action: string]: TActionBase<T>;
}

interface IActionButtonsProps {
  actions: Record<string, TActionBase>;
  buttonSize?: "small" | "medium" | "large";
  vars?: any;
}

/** receive a list of button to display */
export const ActionButtons = (props: IActionButtonsProps) => {
  const { actions, vars } = props;
  const id = props.vars?.accountId;
  const history = useNavigate();

  const buttonSize = props?.buttonSize ?? "medium";

  const getActions = (key: string, args?: any) => {
    const act = actions[key];
    if (!act) return;
    if (act.pageId) {
      const id = vars.accountId;

      if (act.pageId === "company-dashboard") {
        history(pageUtils.getPagePathById(act.pageId, { companyId: id }));
      } else {
        history(pageUtils.getPagePathById(act.pageId, args));
      }
      return;
    }
    if (act.action) {
      act.action(args);
      return;
    }
  };

  return (
    <ColumnsMiddle sx={{ gap: 1, flexWrap: "wrap" }}>
      {Object.keys(actions).map((action: string, index) => {
        const act = actions[action];

        let accesses;
        let objMatch;

        if (act.accesses) accesses = act.accesses;
        if (act.objMatch) objMatch = act.objMatch;

        if (act.pageId) {
          const page = pageUtils.getPageById(act.pageId);
          // accesses is overridden by pageId
          if (page._accesses) accesses = page._accesses;
        }

        // use the act.condition to apply dedicated rule
        if (act.condition && !act.condition(vars)) return null;

        const title = typeof act.title === "function" ? (act.title(vars) as string) : act.title;
        const tip = typeof act.tip === "function" ? (act.tip(vars) as string) : act.tip;

        return (
          <ActionGranter key={index} objMatch={objMatch} accesses={accesses}>
            <AppTip disabled={!tip} text={tip ?? ""}>
              <Button
                data-cy={"actionButton"}
                variant={act?.variant ?? "contained"}
                size={buttonSize}
                disabled={act.conditionDisable ? act.conditionDisable(vars) : false}
                onClick={() => {
                  getActions(action, vars);
                }}
                color={act?.color ?? "primary"}
              >
                <Typography noWrap={true} data-cy-path={act?.path} data-cy={act.code ? act.code : "actionButton-" + title}>
                  {title}
                </Typography>
              </Button>
            </AppTip>
          </ActionGranter>
        );
      })}
    </ColumnsMiddle>
  );
};
