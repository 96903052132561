import {FormControlLabel, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyledCheckbox} from '../../../components/form/CheckBox';
import {Permission} from '../../../types/Permission';
import {CheckBoxGroupBlock, DetailsBlockGrid, InputFieldLabel} from '../../../components/templates/InfoBlock';
import {EUserGrade} from '../../../application/utils/granter.utils';


interface IUserRoleTypeProps {
  userDetails: any;
  setUserDetails: (arg0: any) => void;
}

const useStylesCheckBoxGroup = makeStyles((theme: Theme) =>
  createStyles({
      root         : {
      },
      checkBoxLabel: {
        fontSize  : 12,
        color     : '#3B4559',
        fontWeight: 'normal',
      },
      // icon: {
      //   borderRadius: 2,
      //   width: 12,
      //   height: 12,
      //   boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      //   backgroundColor: '#f5f8fa',
      //   backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      //   '$root.Mui-focusVisible &': {
      //     outline: '2px auto rgba(19,124,189,.6)',
      //     outlineOffset: 2,
      //   },
      // },
      // formControl: {
      //   margin: theme.spacing(3),
      // },
    },
  ),
);

export const AccountUserRoleType: React.FC<IUserRoleTypeProps> = ({
                                                             userDetails,
                                                             setUserDetails,
  
                                                           }) => {
  const classes = useStylesCheckBoxGroup();
  const {t}              = useTranslation();
  const [checkedRole, setCheckedRole] = React.useState<string>(EUserGrade.ADMINISTRATOR);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedRole(event.target.name);
    setUserDetails({...userDetails, role: event.target.name as string});
  };
  
  return (
    <DetailsBlockGrid>
      <div className={classes.root}>
        <div>
          <InputFieldLabel htmlFor="form-group">
            {t('account.userRoleType.roleType')}
          </InputFieldLabel>
          <CheckBoxGroupBlock id="form-group" >
            <FormControlLabel
              control={<StyledCheckbox
                checked={EUserGrade.ADMINISTRATOR === checkedRole}
                onChange={handleChange}
                name={EUserGrade.ADMINISTRATOR}
              />}
              label={
                <>
                  <Typography component="span" className={classes.checkBoxLabel}>
                    {t('account.userRoleType.administrator')}
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              control={<StyledCheckbox
                disabled={true}
                checked={Permission.Creator === checkedRole}
                onChange={handleChange}
                name={Permission.Creator}
              />}
              label={
                <>
                  <Typography component="span" className={classes.checkBoxLabel}>
                    {t('account.userRoleType.creator')}
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              control={<StyledCheckbox
                disabled={true}
                checked={Permission.Contributor === checkedRole}
                onChange={handleChange}
                name={Permission.Contributor}
              />}
              label={
                <>
                  <Typography component="span" className={classes.checkBoxLabel}>
                    {t('account.userRoleType.contributor')}
                  </Typography>
                </>
              }
            />
          </CheckBoxGroupBlock>
        </div>
      </div>
    </DetailsBlockGrid>
  );
};

