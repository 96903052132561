import {devApi} from '../../../../_configuration/api.config';
import apiRequest from '../../../../application/entities/api/interceptors';


const API_URL_PLANS      = `${devApi}/subscriptionPlans`;
const API_URL_COUNTRIES  = `${devApi}/countries`;

/** @deprecated use api.accounts */
export const getSubscriptionPlans = () => {
  const URL = `${API_URL_PLANS}`;
  return apiRequest.get(URL);
};

/** @deprecated use api.countries */
export const getCountries = () => {
  const URL = `${API_URL_COUNTRIES}`;
  return apiRequest.get(URL);
};