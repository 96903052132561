import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dataObject} from "../../../application/entities/dataObjects/DataObjectClass";
import {objectApi} from "../../../application/entities/dataApi";
import {IAccounts} from "../../../application/entities/dataTypes/accounts";
import {ICompanyUsers} from "../../../application/entities/dataTypes/companyUser";
import {ISubscriptions} from "../../../application/entities/dataTypes/subscriptions";
import {useFormik} from "formik";
import {editAccountValidationSchema} from "../../../application/validation";
import {AccountTitle, StatusDot, StatusItem} from "../zOld/shared";
import {Box, Button} from "@mui/material";
import {ButtonEditAccount} from "../Update/ButtonEditAccount";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {MainFrame} from "../../../components/templates/MainFrame";
import {ColumnsWrap, Stacker} from "../../../components/ui/AppElements";
import {useTranslation} from "react-i18next";
import {ActionGranter} from "../../../components/features/ActionGranter";
import {ViewAccountDetails} from "./ViewAccountDetails";
import {ViewAccountAddress} from "./ViewAccountAddress";
import {accessList} from "../../../application/access/access";
import {ViewSubscription} from "./ViewSubscription";
import {ViewAccountType} from "./ViewAccountType";
import {AccountUsersList} from "../AccountUsersList";
import {
  DetailBlock,
  FrameContainer,
  FrameContent,
  InfoBlockTitle,
  InfoContainer
} from "../../../components/templates/InfoBlock";
import {InputLabel} from "../../../components/form/Input";
import {ViewAccountNoteList} from "./ViewAccountNoteList";
import {BackendError} from "../../../types/backendError";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";

export const ViewAccount: React.FC = () => {
    const history = useNavigate();
    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    const {t} = useTranslation();

    const emptyAccountObject = dataObject.getDataObject("accounts");

    const accountsApi = new objectApi.accounts();
    const subscriptionPlanApi = new objectApi.subscriptionPlans();

    const [account, setAccount] = useState<IAccounts>(emptyAccountObject);
    const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);

    const [accountUsers, setAccountUsers] = useState<ICompanyUsers[]>([]);
    const [accountIndustry, setAccountIndustry] = useState<any>([]);
    const [subscription, setSubscription] = useState<Partial<ISubscriptions>>({});

    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingError, setLoadingError] = React.useState<BackendError>();

    useEffect(() => {
        if (accountId) {
            fetchData(+accountId).then();
        }
    }, [accountId]);

    const transformCompanyUrlLis = function (list: string[] = []): string {
        return list.join("\r\n");
    };

    const fetchData = async (accId: number) => {
        setLoading(true);
        subscriptionPlanApi.get({output: {part: "EMBEDDED"}}).then((subscriptionPlans) => {
            if (subscriptionPlans) setSubscriptionPlansList(subscriptionPlans);
        });

        accountsApi
            .byId(accId)
            .then((res) => {
                if (res) setAccount(res);
                if (res.industries) setAccountIndustry(res.industries);
                if (res.companyUsers) setAccountUsers(res.companyUsers);
                if (res.subscription) {
                    setSubscription({
                        ...res.subscription,
                        subscriptionPlanId: res.subscriptionPlan.id,
                    });
                }

                accountDetailsFormik.setValues({...res, companyUrlList: transformCompanyUrlLis(res.companyUrlList)});
            })
            .catch((err) => {
                setLoadingError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const gePageInfo = () => {
        return {
            title: account.companyName,
            companyName: account.companyName,
            description1: `Company ID#: ${account.id}`,
            description2: account.companyName && account.country && `${account.companyName}, ${account.country}`,
            activated: account.activated,
        };
    };

    const accountDetailsFormik = useFormik({
        initialValues: {...account, companyUrlList: ""},
        validationSchema: editAccountValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            const castValues = editAccountValidationSchema.cast(values);

            if (values.companyUrlList) {
                let newVal: string[] = values.companyUrlList.trim().split(/\s+/);
                //@ts-ignore
                castValues.companyUrlList = newVal;
            }
        },
    });

    const headerProperties = (
        <>
            <AccountTitle>{t("account.generalTitles.status")}</AccountTitle>
            <Box sx={{pt: 1}}>
                <StatusItem active={account.activated}>
                    <StatusDot/> {account.activated ? "Active" : "Inactive"}
                </StatusItem>
            </Box>
        </>
    );

    const headerButtons = (
        <>
            <div className={"flex-h gap-small flex-align-middle "}>
                <Button
                    onClick={() => {
                        history(pageUtils.getPagePathById(PageId.accountEdit, {accountId: accountId}));
                    }}
                    variant={"outlined"}
                >
                    Edit
                </Button>
                <ButtonEditAccount account={account} setAccount={setAccount}/>
            </div>
        </>
    );
    // @ts-ignore
    const showFrame = AUTHUser.isEvorraAdmin() || AUTHUser.accountId === +accountId; // integrate in
    // MainFrameGrant

    return (
        <MainFrame
            restrict404={!showFrame}
            frameId={"view-account"}
            loading={loading}
            backendError={loadingError}
            headerProps={{
                frameInfo: gePageInfo(),
                headerButtons,
                headerProperties: account.dataProvider ? headerProperties : undefined,
            }}
        >
            {account && (
                <FrameContainer>
                    <form onSubmit={accountDetailsFormik.handleSubmit}>
                        <FrameContent>
                            <Stacker>
                                <ColumnsWrap>
                                    <InfoContainer>
                                        <InfoBlockTitle>{t("account.accountDetailsLabels.accountDetails")}</InfoBlockTitle>
                                        <ViewAccountDetails account={account} accountIndustry={accountIndustry}/>
                                        <ViewAccountAddress account={account}/>
                                        <ViewAccountType account={account}/>
                                        <DetailBlock>
                                            <InputLabel>{t("account.generalTitles.userList")}</InputLabel>
                                            <AccountUsersList companyUsers={accountUsers}/>
                                        </DetailBlock>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <ActionGranter accesses={accessList.accounts.subscriptions}>
                                            <InfoBlockTitle>{t("account.accountSubscriptionLabels.subscriptionDetails")}</InfoBlockTitle>
                                            <ViewSubscription accountId={account.id} subscription={subscription}
                                                              subscriptionPlansList={subscriptionPlansList}/>
                                        </ActionGranter>
                                        <DetailBlock>
                                            <InfoBlockTitle>{t("account.accountNotesLabels.notes")}</InfoBlockTitle>
                                            <ViewAccountNoteList account={account}/>
                                        </DetailBlock>
                                    </InfoContainer>
                                </ColumnsWrap>
                                {/*<FooterBlock>
                     <BtnBlock>
                     <GhostButton
                     onClick={() => handleCancel()}
                     variant="contained"
                     color="primary"
                     > Back to account list
                     </GhostButton>
                     </BtnBlock>
                     </FooterBlock>*/}
                            </Stacker>
                        </FrameContent>
                    </form>
                </FrameContainer>
            )}
        </MainFrame>
    );
};
