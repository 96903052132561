import { Box, Button, Divider, Skeleton, styled, TextField, Theme, Typography } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { MainFrame } from "components/templates/MainFrame";
import { useAccountTracker } from "pages/Agencies/useAccountTracker";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AudienceDetails } from "components/audiences/details";
import { ChannelSummary } from "components/audiences/channelSummary";
import { TextFieldBlock } from "components/templates/InfoBlock";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { AUTHUser } from "application/utils/AuthUser";
import { useAppToast } from "components/live/AppToast";
import { t } from "i18next";
import { CheckoutParameters } from "components/audiences/checkoutParameters";
import { IAudienceActivationRecap } from "application/entities/dataTypes/audienceActivationRecap";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { AudienceViewAttributes } from "components/audiences/audienceViewAttributes/AudienceViewAttributes";

export const AudienceApproval = () => {
  const { audienceId } = useParams<{ audienceId: string }>() as { audienceId: string };
  const appToast = useAppToast();

  const audienceApi = new objectApi.audiences();
  const [audience, setAudience] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isPendingApproval, setIsPendingApproval] = React.useState<boolean>(true);

  //using audience store to be able to display attributes boxes
  const audienceActions = useAudienceBuilderStore((st) => st.actions);
  const dialogWindow = useOpenDialogWindow();

  const [message, setMessage] = useState("");

  const [checkoutParameters, setCheckoutParameters] = useState<IAudienceActivationRecap[]>([] as IAudienceActivationRecap[]);

  const [emailSent, setEmailSent] = useState(false);

  const [error, setError] = useState(false);

  React.useEffect(() => {
    audienceApi
      .openAndRefreshAudience(Number(audienceId))
      .then((res) => {
        if (res.data.audienceStatus !== "PENDING_APPROVAL") {
          setIsPendingApproval(false);
          setError(true);
        }
        if (res) {
          setAudience(res.data);
          Promise.all([audienceActions.init(Number(audienceId))])
            .catch((err) => {
              console.log(err);
              setError(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
    return () => {
      audienceActions.reset();
    };
  }, []);

  useEffect(() => {
    if (audience?.id) {
      let checkoutParametersBuild = audience.audienceActivationRecap
        .map((item: any) => {
          if (typeof item.checkoutParameters !== "object") item.checkoutParameters = JSON.parse(item.checkoutParameters);
          return item;
        })
        .filter((item: any) => item.checkoutParameters?.format_banner);
      setCheckoutParameters(checkoutParametersBuild);
    }
  }, [audience]);

  useAccountTracker(audience?.account?.id);

  const handleSubmit = (state: "Approve" | "Reject") => {
    dialogWindow.open({
      dialogId: "confirmRequestApproval",
      buttonActions: { acceptButton: state === "Approve" ? "Approve" : "Reject", cancelButton: true },
      title: "Confirm your answer",
      description:
        "You are going to " +
        (state === "Approve" ? "approve" : "reject") +
        " this audience. " +
        audience?.requester?.firstName +
        " " +
        audience?.requester?.lastName +
        " will be notified.",
      icon: "HandTool",
      onAccept: (args: any, orgs: any) => {
        audienceApi
          .approveAudience(Number(audienceId), state === "Approve" ? true : false, message)
          .then((res) => {
            if (res.status === 200) {
              appToast.open({
                toastId: "globalToast",
                severity: "success",
                message: state === "Approve" ? t("audience.acceptRequestApproval") : t("audience.denyRequestApproval"),
              });
              setEmailSent(true);
            }
          })
          .catch((e) => {
            setEmailSent(false);
            console.log(e);
          });
      },
    });
  };

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleApprove = () => {
    handleSubmit("Approve");
  };

  const handleReject = () => {
    handleSubmit("Reject");
  };

  return (
    <MainFrame
      breadCrumbProps={{
        crumbTitle: error ? "Audience approval request" : loading ? "Audience approval request" : `Audience approval request - ${audience?.name}`,
      }}
      headerProps={{
        headerTitle: loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={24} style={{ borderRadius: "10px" }} />
        ) : (
          <Typography variant={"h4"}>{error ? "Error" : `Audience approval request: ${audience?.name}`}</Typography>
        ),
      }}
      frameId={"audience-approval"}
      loading={loading}
      checkPageAccess={true}
      grantWith={{ accountId: AUTHUser.accountId }}
    >
      {error || !isPendingApproval ? (
        <ContainerFull style={{ marginTop: "0" }}>
          <Typography variant={"h4"} style={{ paddingBottom: "10px", textAlign: "center" }}>
            Error loading audience
          </Typography>
          {!isPendingApproval && (
            <Typography variant={"h5"} style={{ paddingBottom: "10px", textAlign: "center" }}>
              Audience is not pending approval
            </Typography>
          )}
        </ContainerFull>
      ) : (
        <Box style={{ height: "100%", overflowY: "auto", overflowX: "auto" }}>
          <Container>
            <div>
              <AudienceDetails audience={audience} loading={loading} />
            </div>

            <div>
              {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={400} style={{ borderRadius: "10px" }} />
              ) : (
                <AudienceViewAttributes loading={loading} />
              )}
            </div>
          </Container>

          <ContainerFull>
            {loading ? (
              <Skeleton variant="rectangular" width={"100%"} height={400} style={{ borderRadius: "10px" }} />
            ) : (
              <ChannelSummary audience={audience} loading={loading} />
            )}
          </ContainerFull>

          {checkoutParameters?.length > 0 && (
            <ContainerFull style={{ marginTop: "0" }}>
              <CheckoutParameters checkoutParameters={checkoutParameters} audience={audience} loading={loading} />
            </ContainerFull>
          )}

          {emailSent ? (
            <ContainerFull style={{ marginTop: "0" }}>
              <Typography variant={"h5"} style={{ paddingBottom: "10px", textAlign: "center" }}>
                Your answer was sent to {audience?.requester?.firstName} {audience?.requester?.lastName}
              </Typography>
            </ContainerFull>
          ) : (
            <ContainerFull style={{ marginTop: "0" }}>
              <Typography variant={"h5"} style={{ paddingBottom: "10px" }}>
                Message (Optional)
              </Typography>
              <Divider style={{ marginBottom: "30px" }} />
              <TextFieldBlock>
                <TextField
                  sx={{ height: "120px", width: "380px" }}
                  style={{ boxShadow: "rgba(196, 211, 241, 0.6) 0px 0px 6px", borderRadius: "10px", padding: "10px" }}
                  variant="standard"
                  multiline
                  fullWidth
                  placeholder="Enter your message here"
                  inputProps={{ "data-cy": "approval_message" }}
                  value={message}
                  onChange={handleChange}
                />
              </TextFieldBlock>
              <BtnWrapper>
                <Button data-cy={"approve_btn"} variant={"contained"} onClick={handleApprove}>
                  <Typography noWrap={true}>Approve</Typography>
                </Button>
                <Button data-cy={"reject_btn"} variant={"outlined"} color={"error"} onClick={handleReject}>
                  <Typography noWrap={true}>Reject</Typography>
                </Button>
              </BtnWrapper>
            </ContainerFull>
          )}
        </Box>
      )}
    </MainFrame>
  );
};
const Container = styled("div")((props: { theme: Theme }) => ({
  maxHeight: "100%",
  width: "100%",
  padding: "1.5rem",
  display: "grid",
  gridTemplateColumns: "380px 1fr",
  gap: "3rem",
}));

const ContainerFull = styled("div")((props: { theme: Theme }) => ({
  width: "100%",
  marginTop: "2rem",
  padding: "1.5rem",
}));

const BtnWrapper = styled("div")((props: { theme: Theme }) => ({
  display: "flex",
  gap: "1rem",
  marginLeft: "5px",
}));
