import { Box, MenuItem, Select } from "@mui/material";
import { ChevronDownBlue } from "assets/icons";
import * as React from "react";
import { Elem } from "../../ui/AppElements";
import { AppIcon } from "../../ui/AppIcon";

export interface IPagerProps {
  maxCount: number;
  pageSize: number;
  activePage: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

export const Pager = (props: IPagerProps) => {
  const { maxCount, pageSize, onPageChange, onPageSizeChange, activePage } = props;

  const [maxPageLimit, setMaxPageLimit] = React.useState(5);
  const [minPageLimit, setMinPageLimit] = React.useState(0);
  const totalPages = Math.ceil(maxCount / pageSize);

  let pageIncrementEllipses = null;
  if (maxCount > maxPageLimit) {
    // pageIncrementEllipses = <li onClick={handleNextClick}>&hellip;</li>
  }
  let pageDecremenEllipses = null;
  if (minPageLimit >= 1) {
    // pageDecremenEllipses = <li onClick={handlePrevClick}>&hellip;</li>
  }

  // event : unable to load {/*SelectChangeEvent*/}
  const from = (activePage + 1) * pageSize - pageSize + 1;
  const to = (activePage + 1) * pageSize > maxCount ? maxCount : (activePage + 1) * pageSize;

  return (
    <Box sx={{ py: 2, px: 2, pb: 1, maxWidth: "100%" }} data-cy={"Pager"}>
      <div className={"flex-h flex-align-middle"}>
        <Box data-cy="pager-total" data-cy-value={maxCount} sx={{ px: 1, minWidth: "150px", color: "#7587A3", fontSize: "1.2em" }}>
          Total :{" "}
          <span style={{ fontWeight: "600" }}>
            {totalPages > 1 ? `${from}-${to} of ` : ""} {maxCount}
          </span>
        </Box>
        <Box sx={{ px: 1, color: "#7587A3", fontSize: "1.2em" }}>Showing</Box>
        <Select
          data-cy={"paginationShowingSelect"}
          role="select"
          sx={{ width: "60px", ".MuiSelect-select": { color: "#7587A3 !important" } }}
          onChange={(event: any) => {
            onPageSizeChange(event.target.value);
          }}
          value={pageSize}
          IconComponent={ChevronDownBlue}
        >
          <MenuItem key={"pager10"} role="option" value={10}>
            10
          </MenuItem>
          <MenuItem key={"pager30"} value={30}>
            30
          </MenuItem>
          <MenuItem key={"pager50"} value={50}>
            50
          </MenuItem>
          <MenuItem key={"pager100"} value={100}>
            100
          </MenuItem>
        </Select>
        {totalPages > 1 && <Box sx={{ px: 1, color: "#7587A3", fontSize: "1.2em" }}>per page</Box>}
        {pageDecremenEllipses}
        {totalPages > 1 &&
          [
            ...new Set(
              [
                0,
                1,
                2,
                3,
                4,
                activePage - 2,
                activePage - 1,
                activePage,
                activePage + 1,
                activePage + 2,
                totalPages - 5,
                totalPages - 4,
                totalPages - 3,
                totalPages - 2,
                totalPages - 1,
              ].filter((n) => n >= 0 && n <= totalPages - 1)
            ),
          ]
            .sort((a, b) => a - b)
            .map((index) => {
              const targetPage = index + 1;

              return (
                <>
                  {(index === activePage - 2 && activePage - 3 > 4 && index <= totalPages - 5) ||
                  (index === totalPages - 5 && activePage - 2 > totalPages - 5 && index > 4) ? (
                    <AppIcon
                      key={"key-ellipsis-1-" + targetPage}
                      display={"inline"}
                      fontSize={"small"}
                      color={"grey"}
                      rotate={90}
                      icon={"VerticalEllipsis"}
                    />
                  ) : (
                    ""
                  )}
                  <Elem
                    key={"pa" + targetPage}
                    active={index === activePage}
                    onClick={() => {
                      onPageChange(index);
                    }}
                    pointer={true}
                    radius={10}
                    margin={[0, 0.25]}
                    role={"pager"}
                    padding={[0.25, 0.5]}
                    shadow={true}
                    data-cy={"paginationPage" + targetPage}
                    style={{ color: "#7587A3" }}
                  >
                    {targetPage}
                  </Elem>
                  {(index === activePage + 2 && activePage + 3 < totalPages - 5 && activePage + 2 >= 4) ||
                  (index === 4 && activePage + 2 < 4 && index < totalPages - 6) ? (
                    <AppIcon
                      key={"key-ellipsis-2-" + targetPage}
                      display={"inline"}
                      fontSize={"small"}
                      color={"grey"}
                      rotate={90}
                      icon={"VerticalEllipsis"}
                    />
                  ) : (
                    ""
                  )}{" "}
                </>
              );
            })}
        {pageIncrementEllipses}
      </div>
    </Box>
  );
};
