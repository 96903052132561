import { Button } from "@mui/material";
import { GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { objectApi } from "application/entities/dataApi";
import { audiencesColumns } from "application/entities/dataColumns/audiences.columns";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { pageUtils } from "application/pages/pages.utils";
import { TDataColumnModel, dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { GridTable } from "components/organisms/GridTable";
import useLocalStorageState from "use-local-storage-state";
import { AppIcon } from "components/ui/AppIcon";
import { AppDrawer } from "components/ui/AppDrawer";
import { useTranslation } from "react-i18next";
import { AudienceCard } from "pages/Audiences/Card";
import { FloatingMenuHandle } from "pages/Audiences/Card/FloatingMenuHandle";
import { ProviderStatus } from "pages/Audiences/Card/ProviderStatus";
import { AudiencePreviewPopup } from "pages/Audiences/List/AudienceLibraryThumb/audiencePreviewPopup";

const accountApi = new objectApi.accounts();
export const AdminAccountAudiences = (props: { accountId: number }) => {
  const { accountId } = props;
  const audiencesApi = new objectApi.audiences();

  const [nbAudiences, setNbAudiences] = React.useState(0);
  const [audiencesList, setAudiencesList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  //transparency card
  const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false);
  const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>("transparency");
  const [activeAudience, setActiveAudience] = React.useState<IAudiences>({} as any);
  const [tcLoading, setTcLoading] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const [pageConfig, setPageConfig] = useLocalStorageState<{
    pageSize: number;
    pageNumber: number;
  }>("adminAccountAudience-pageConfig", {
    pageSize: 50,
    pageNumber: 0,
  });
  const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>("adminAccountAudience-sortModel", [{ field: "id", sort: "desc" }]);

  const flatData: IAudiences[] = audiencesList.flat() ?? [];
  const columns = _.cloneDeep(audiencesColumns());

  const windowDialog = useOpenDialogWindow();
  const navigate = useNavigate();

  const cols: TDataColumnModel = {
    id: columns.id,
    createdDate: columns.createdDate,
    company: columns.company,
    creator: columns.creator,
    name: columns.name,
    status: columns.status,
    lastAudienceSize: columns.lastAudienceSize,
    CPM: columns.cpm,
    actions: columns.actions,
  };
  cols.id.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        #{cell.row.id}
        <AppIcon
          onClick={() => {
            openTransparencyCard(cell.row.id, cell.row.audienceStatus);
          }}
          dataCy={"audienceTransparencyCardIcon"}
          display={"inline"}
          fontSize={"small"}
          icon={"FileSearch"}
          title={"Open Transparency card"}
        />
      </div>
    );
  };

  cols.creator.sortable = false;

  cols.actions.width = 140;
  cols.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            handlePreview(cell.row.id);
          }}
        >
          Preview
        </Button>
        {["ARCHIVED", "ACTIVATED", "DRAFT"].includes(cell.row.audienceStatus) && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              handleEditView(cell.row.id, cell.row.audienceStatus);
            }}
          >
            {cell.row.audienceStatus === "DRAFT" ? "Edit" : "View"}
          </Button>
        )}
      </div>
    );
  };

  const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

  const fetchDatas = React.useCallback(() => {
    setLoading(true);
    accountApi
      .getAudiences(accountId, {
        page: pageConfig,
        sort: {
          sortBy: sortModel[0].field,
          sortDir: sortModel[0].sort ?? "asc",
        },
      })
      .then((res: any) => {
        setNbAudiences(res.data.page.size);
        setAudiencesList(res.data._embedded.audiences);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [accountId, pageConfig, sortModel]);

  React.useEffect(() => {
    setPageConfig({
      pageSize: 50,
      pageNumber: 0,
    });
    setSortModel([{ field: "id", sort: "desc" }]);
  }, [setPageConfig, setSortModel]);

  React.useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const handlePreview = (audienceId: number) => {
    const previewDialog: IOpenDialogAction = {
      dialogId: "AudiencePreviewPopup",
      component: AudiencePreviewPopup,
      componentProps: {
        audienceId: audienceId,
        previewMode: true,
        hideButtons: true,
        onDelete: () => {},
      },
      dialogProps: {
        className: "previewAudiencePopup",
      },
    };
    windowDialog.open(previewDialog).then();
  };

  const handleEditView = (audienceId: number, status: string) => {
    if (status === "DRAFT") {
      navigate(pageUtils.getPagePathById("audience-builder-update", { id: audienceId }));
    } else if (["ARCHIVED", "ACTIVATED"].includes(status)) {
      navigate(pageUtils.getPagePathById("audience-view", { audienceId: audienceId }));
      return;
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setPageConfig((curr) => {
      return { ...curr, pageNumber };
    });
  };

  const handleSortModelChange = (params: GridSortModel) => {
    if (params[0].field !== sortModel[0].field || params[0].sort !== sortModel[0].sort) {
      setSortModel(params);
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageConfig((curr) => {
      return { ...curr, pageSize, pageNumber: 0 };
    });
  };

  function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
    if (mode === menuMode) {
      /*if (visibleDrawer) setMenuMode(undefined);
       setVisibleDrawer(!visibleDrawer);*/
    } else {
      setMenuMode(mode);
      setVisibleDrawer(true);
    }
  }

  function handleToggleDrawer(fact: any) {
    if (fact && !menuMode) setMenuMode("transparency");
    setVisibleDrawer(fact);
  }

  function openTransparencyCard(audienceId: number, audienceStatus: string) {
    if (!activeAudience || audienceId === activeAudience.id) {
      setVisibleDrawer(false);
      setActiveAudience({} as any);
    } else {
      setTcLoading(true);
      setVisibleDrawer(true);
      audiencesApi.byId(audienceId).then((res) => {
        setActiveAudience(res);
        setTcLoading(false);
      });
    }
  }

  let provStatus = visibleDrawer ? { right: "340px", top: "0.5rem" } : { right: 0, top: 0 };
  const drawerTitle =
    menuMode === "channels"
      ? t("audience.cardTips.channelTipTitle")
      : menuMode === "enablement"
      ? t("audience.cardTips.enablementTipTitle")
      : t("audience.cardTips.transparencyTipTitle");
  const drawerTip =
    menuMode === "channels"
      ? t("audience.cardTips.channelTip")
      : menuMode === "enablement"
      ? t("audience.cardTips.enablementTip")
      : t("audience.cardTips.transparencyTip");

  return (
    <>
      <GridTable
        styleClass={"naked"}
        height="100%"
        customStyle={{ height: "450px" }}
        data={{
          columns: audienceColumns as any,
          rows: flatData,
        }}
        loading={loading}
        pagerProps={{
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          activePage: pageConfig.pageNumber,
          maxCount: nbAudiences,
          pageSize: pageConfig.pageSize,
        }}
        gridProps={{
          onSortModelChange: handleSortModelChange,
          disableSelectionOnClick: true,
          autoHeight: false,
          hideFooter: true,
          columnBuffer: 30,
          columnThreshold: 10,
        }}
      />

      <FloatingMenuHandle hide={!visibleDrawer} position={provStatus}>
        <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
          <button style={{ width: "24px" }} data-cy={"btnActionToggleAudienceDrawer"} onClick={() => handleToggleDrawer(!visibleDrawer)}>
            <AppIcon
              themeColor={!visibleDrawer ? "primary" : "secondary"}
              fontSize={"small"}
              icon={"ChevronDownIcon"}
              rotate={visibleDrawer ? -90 : 90}
            />
          </button>
          <button data-cy={"btnActionOpenTransparencyCard"} onClick={() => setDrawerMode("transparency")}>
            <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"} fontSize={"small"} icon={"FileSearch"} />
          </button>
          {["DRAFT"].includes(activeAudience.audienceStatus) && (
            <button style={{ width: "24px" }} data-cy={"btnActionOpenChannels"} onClick={() => setDrawerMode("channels")}>
              <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"} icon={"ChannelsNav"} />
            </button>
          )}
          {!["DRAFT"].includes(activeAudience.audienceStatus) && (
            <button style={{ width: "24px" }} data-cy={"btnActionOpenEnablement"} onClick={() => setDrawerMode("enablement")}>
              <AppIcon themeColor={menuMode === "enablement" ? "primary" : "secondary"} fontSize={"small"} icon={"Channels"} />
            </button>
          )}
        </div>
      </FloatingMenuHandle>
      <AppDrawer
        title={drawerTitle}
        helperText={drawerTip}
        helperTitle={drawerTitle}
        open={visibleDrawer}
        loading={tcLoading}
        hideHandle={true}
        actionButtonsVars={{ ...activeAudience, audienceId: activeAudience?.id }} // add audienceId
        onClose={() => {
          handleToggleDrawer(false);
        }}
      >
        {menuMode === "channels" && <ProviderStatus audienceData={activeAudience} position={provStatus} />}
        {menuMode !== "channels" && <AudienceCard menuMode={menuMode} audience={activeAudience} />}
      </AppDrawer>
    </>
  );
};
