import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import { IPageParameters, ISortParameters, IApiReturn } from 'application/entities/api/apiService';

export interface useQueryProps {
  params?: Record<string,any> // uri parameters
  pageSize?: number;
  pageNumber?: number;
  sortDir?: 'desc' | 'asc';
  sortBy?: string;
}

interface AudiencesQueryProps extends useQueryProps {
  accountId: number;
  audienceStatus: string;
  kw?: string;
  pageSizeState?: number;
  pageNumber?: number;
  sortDir?: 'desc' | 'asc';
  sortBy: string;
}

interface AudiencesSearch{
  query?: any,
  page?: IPageParameters,
  sort?: ISortParameters,
  ret?: IApiReturn
}



export const useAudiencesListQuery = (props: AudiencesQueryProps) => {
  const accountsApi = new objectApi.accounts();

  const {accountId, audienceStatus, kw, pageSizeState = 50, pageNumber = 0, sortBy, sortDir = 'desc'} = props;
  
  
  const {isLoading, error, data, refetch} = useQuery(['audiences', accountId, pageSizeState, pageNumber, sortBy, sortDir, audienceStatus, kw], () => {
    return accountsApi.searchAudiences({
      byId       : accountId,
      searchValue: audienceStatus === 'All' ? undefined : audienceStatus,
      searchField: 'status',
      kwValue    : kw?.length ? kw : undefined,
      page       : {pageSize: pageSizeState, pageNumber: pageNumber},
      sort       : {sortBy: sortBy, sortDir: sortDir}
    }).then(res => res);
  }, {cacheTime: 0});
  
  return {isLoading, error, data, refetch};
};


export const useAudienceQuery = (audienceId: number) => {
  
  const accountsApi = new objectApi.audiences();
  
  const {isLoading, error, data, refetch} = useQuery(['audience', audienceId], () => {
    return accountsApi.byId(audienceId).then(res => res);
  }, {cacheTime: 30000});
  
  return {isLoading, error, data, refetch};
};


export const useSearchAudiences = (props: AudiencesSearch)=>{
  const audienceApi = new objectApi.audiences();

  const { query, page, sort, ret } = props
  const {isLoading, error, data, refetch} = useQuery(['admin-audiences', page, sort, query.status, query?.kw], ()=>{
    return audienceApi.search({query, page, sort, ret}).then(res => res);
  }, {cacheTime: 10000})

  return {isLoading, error, data, refetch};
}


export const useAudiencesManualStep = () => {
  const audienceApi = new objectApi.audiences();

    const {isLoading, error, data, refetch} = useQuery(['audiences-manual-step-list'], ()=>{
      return audienceApi.getAudiencesManualStep().then(res => res);
  }, {cacheTime: 1000})
  return {isLoading, error, data, refetch};
}

export const useAudiencesAlwaysOn = () => {
  const audienceApi = new objectApi.audiences();

    const {isLoading, error, data, refetch} = useQuery(['audiences-always-on-list'], ()=>{
      return audienceApi.getAllAlwaysOnAudiences().then(res => res);
  }, {cacheTime: 1000})
  return {isLoading, error, data, refetch};
}

