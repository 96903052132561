import {audiences} from './audiences';
import {company} from './company';
import {TEntityName} from '../dataTypes';
import {companyUsers} from './companyUsers';
import {accounts} from './accounts';
import {affinities} from './affinities';


export const dataObjects: Partial<Record<TEntityName, any>> = {
  audiences,
  company,
  companyUsers,
  accounts,
  affinities
} as const