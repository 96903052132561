/**
 created by Mydde
 */

import {ColumnsWrap}          from 'components/ui/AppElements';
import * as React             from 'react';
import {AppTag, IAppTagProps} from "../../ui/AppTag";

interface ITagListProps {
  tags: IAppTagProps[],
  dense?: boolean,
  viewMode?: boolean,
  fullSize?: boolean,
  onRemoveTag?: (id: number, index?: number)=>void
};

export const TagList = (props: ITagListProps) => {
  
  const {tags,onRemoveTag,dense, viewMode, fullSize} = props
  
  
  try{
    const finalList=tags?.sort((a:IAppTagProps,b:IAppTagProps)=>{
 
      return a?.name > b?.name ? 1 : a?.name < b?.name ? -1 : 0
    })
  }catch (e){
  
  }
  
  const handleRemove = (id: number, index?: number) => {
    onRemoveTag && onRemoveTag(id, index)
  }
  
  return (
    <ColumnsWrap style={{gap:'0.25rem'}}>
      {tags && tags.map((tag, index: number) => {
        return <AppTag dense={dense} fullSize={fullSize} key={tag.id} id={tag.id} index={index} handleRemove={handleRemove} name={tag.name} viewMode={viewMode}/>
      })}
    </ColumnsWrap>
  );
};