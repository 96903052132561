import React from 'react';
import {IconSet, TIcon} from '../../../assets/icons';
import styled from 'styled-components/macro';
import {IGridStyleProps, Node, TGridDefinedSizes} from 'components/ui/AppElements';
import {Box, useTheme} from '@mui/material';


export interface IAppIconProps {
    icon: TIcon;
    pointer?: boolean;
    fontSize?: TGridDefinedSizes;
    color?: string; // update to stroke
    fill?: string;
    backgroundColor?: 'contrast' | string;
    themeColor?: string; // keyof Palette;
    onClick?: (event: any) => void;
    rotate?: number;
    nodeProps?: IGridStyleProps
    display?: 'block' | 'inline'
    children?: any
    circle?: boolean | string
    chip?: boolean
    style?: Record<string, any>
    title?: string
    dataCy?: string
    className?: string
}

export const AppIcon = (props: IAppIconProps) => {

    const {
        fontSize,
        icon,
        color,
        themeColor,
        fill,
        circle,
        style,
        chip,
        onClick,
        rotate,
        pointer,
        nodeProps,
        children,
        className
    } = props;

    const theme = useTheme();

    const Icon = IconSet?.[icon] ?? undefined;
    const args = {} as any;

    let iconsize = 24;
    let iconStyle: Record<string, any> = {...style};

    switch (fontSize) {
        case 'tiny':
            iconsize = 12;
            break;
        case 'small':
            iconsize = 16;
            break;
        case 'smallbis':
            iconsize = 20;
            break;
        case 'middle':
            iconsize = 24;
            break;
        case 'default':
            iconsize = 32; // should be 24
            break;
        case 'medium':
            iconsize = 42;
            break;
        case 'large':
            iconsize = 56;
            break;
        case 'big':
            iconsize = 64;
            break;
    }

    if (color) {
        args.stroke = color;
        // @ts-ignore
    } else if (themeColor) args.stroke = theme.palette?.[themeColor]?.main ?? ''

    if (!Icon) return null

    if (pointer || onClick) {
        iconStyle.cursor = 'pointer';
        args.hoverstroke = '#00B5E2'
    }

    iconStyle.fontSize = iconsize;
    return <IconBoxCss {...args} display={props.display} iconsize={iconsize} {...nodeProps} title={props?.title}
                       data-cy={props?.dataCy} className={className}
                       rotateicon={rotate}>{chip && <IconChipCss/>}{/*
   */}{!children && <Icon width={iconsize} height={iconsize} {...iconStyle} onClick={onClick} className={className}/>}{/*
   */}</IconBoxCss>
};


interface IIconBoxCss {
    iconsize?: number
    rotateicon?: number
    display?: 'block' | 'inline'
    circle?: boolean | string
    stroke?: string
    hoverstroke?: string
}

const IconBoxCss = styled(Box)<IIconBoxCss>(props => ({
    display: props.display === 'inline' ? 'inline-flex' : 'flex',
    fontSize: 0,
    alignItems: 'center',
    justifyContent: 'center',
    /*width         : props.iconsize + 'px',
     height        : props.iconsize + 'px',*/
    position: 'relative',
    'svg': {
        transform: `rotate(${props.rotateicon}deg)`,
        transition: 'all 0.5s ease-in-out',
        transitionDuration: '0.25s',
        transitionProperty: '*',
        display: 'block',
        position: 'relative',
        'path[stroke]': {
            stroke: props.stroke,
        },
        'path[fill]': {
            fill: props.stroke,
        },
        'line[stroke]': {
            stroke: props.stroke,
        },
        '&:hover': {
            'path[stroke]': {
                stroke: props.hoverstroke ?? props.stroke,
            }, 'path[fill]': {
                fill: props.hoverstroke ?? props.stroke,
            },
            'line[stroke]': {
                fill: props.hoverstroke ?? props.stroke,
            },

        }
    }
}))

const IconChipCss = styled(Node)<IIconBoxCss>(props => ({
    position: 'absolute',
    top: '0',
    right: '0',
    borderRadius: '50%',
    width: '57%',
    height: '57%',
    zIndex: 0,
    border: '1px solid rgb(172, 226, 240,0.7)',
    backgroundColor: 'rgb(172, 226, 240,0.7)',
}))

//
// top: 0px;
// right: 1px;
// border-radius: 50%;
// width: 54%;
// height: 54%;