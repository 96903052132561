import { Box, Button, Collapse, Divider, Paper, Typography, styled } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { IDspChannelsSelections } from "application/entities/dataTypes/dspUserSelections";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { getDspAdditionalInfo, getDspIcon } from "application/utils/dsp.utils";
import { providerIconList } from "assets/providerIcons";
import { AppSwitch } from "components/form/AppSwitch";
import { useSelectedDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { AppIcon } from "components/ui/AppIcon";
import { useState } from "react";
import { useNavigate } from "react-router";

export const ChannelDetails = (props: { channel: IDspChannelsSelections; accountId: number }) => {
  const { channel, accountId } = props;
  const dspChannelsSelectionsApi = new objectApi.dspChannelsSelections();
  const dspSelection = useSelectedDspChannels(accountId);
  const dialogWindow = useOpenDialogWindow();
  const navigate = useNavigate();

  const [isSendMethodsExpanded, setIsSendMethodsExpanded] = useState(false);
  const enablementOptions = [...channel?.dspEnablementOptions]?.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0)) ?? [];

  const updateDspSelection = (channelSelection: IDspChannelsSelections, checked: boolean) => {
    dspChannelsSelectionsApi.update(channelSelection.id, { isActive: checked }).then((res) => {
      dspSelection.refetch();
    });
  };

  const deleteChannelsSelection = (channelSelection: IDspChannelsSelections) => {
    dialogWindow.open({
      dialogId: "channelSelectionDelete",
      buttonActions: { acceptButton: true, cancelButton: true },
      title: "Delete this Channel?",
      description: "You will not be able to use it",
      icon: "Trash",
      onAccept: onDeleteAccept,
      dialogArgs: channelSelection,
    });
  };

  async function onDeleteAccept(args: any, channel: IDspChannelsSelections) {
    try {
      await dspChannelsSelectionsApi.delete(channel.id).then(() => {
        dspSelection.refetch();
      });
    } catch (error) {}
  }

  return (
    <ChannelBox elevation={channel.isActive ? 8 : 4} style={{ opacity: channel.isActive ? 1 : 0.6 }}>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-between" }} mb={1}>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <AppIcon className="dsp-logo" fontSize={"smallbis"} icon={providerIconList[channel.dspChannel!.code]} />
          <Typography variant="h2" style={{ fontSize: "18px" }}>
            {channel.dspChannel?.name}
          </Typography>
        </Box>
        <AppSwitch
          data-cy={channel.dspChannel.code + "-switch"}
          onChange={(event, checked) => {
            updateDspSelection(channel, checked);
          }}
          label={""}
          labelPlacement="start"
          checked={channel.isActive}
        />
      </Box>

      <Divider sx={{ marginBottom: "0.75rem" }} />

      <Typography variant="body1" textAlign="center" sx={{ fontSize: "14px", fontWeight: "normal" }} mb={2}>
        <span className="color-tech-grey" style={{ fontWeight: "bold" }}>
          Seat name:
        </span>{" "}
        {channel.seatName}
      </Typography>

      {channel.parameterValues?.advertiserId?.length > 0 || channel.parameterValues?.partnerId?.length > 0 ? (
        <Box sx={{ display: "flex", gap: "2rem", alignItems: "center", justifyContent: "center" }}>
          {channel.parameterValues?.advertiserId?.length > 0 && (
            <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: "normal" }}>
              <span className="color-tech-grey" style={{ fontWeight: "bold" }}>
                Advertiser Id
              </span>{" "}
              <br /> {channel.parameterValues?.advertiserId}
            </Typography>
          )}

          {channel.parameterValues?.partnerId?.length > 0 && (
            <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: "normal" }}>
              <span className="color-tech-grey" style={{ fontWeight: "bold" }}>
                Partner Id
              </span>
              <br /> {channel.parameterValues?.partnerId}
            </Typography>
          )}
          {channel.parameterValues?.currency?.length > 0 && (
            <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: "normal" }}>
              <span className="color-tech-grey" style={{ fontWeight: "bold" }}>
                Currency
              </span>
              <br /> {channel.parameterValues?.currency}
            </Typography>
          )}
          {channel.parameterValues?.region?.length > 0 && (
            <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: "normal" }}>
              <span className="color-tech-grey" style={{ fontWeight: "bold" }}>
                Region
              </span>
              <br /> {channel.parameterValues?.region}
            </Typography>
          )}
        </Box>
      ) : (
        <div style={{ height: "36px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography className="color-tech-grey" variant="body1" sx={{ fontSize: "12px", fontWeight: "normal" }}>
            No ids required for this channel
          </Typography>
        </div>
      )}

      <Box>
        <Box
          mt={2}
          mb={1}
          sx={{ cursor: "pointer", display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "center" }}
          onClick={() => setIsSendMethodsExpanded((state) => !state)}
        >
          <Typography className="color-tech-grey" variant="body1" textAlign="center" sx={{ fontSize: "14px", fontWeight: "bold" }}>
            {channel?.dspEnablementOptions.length} Enablement Method{channel?.dspEnablementOptions.length > 1 && "s"} Active
          </Typography>
          <div style={{ transform: isSendMethodsExpanded ? "rotate(180deg)" : "rotate(0deg)" }}>
            <AppIcon icon="ChevronDownIcon" color="#7587A3" fontSize="small" />
          </div>
        </Box>
        <Collapse orientation="vertical" in={isSendMethodsExpanded}>
          {enablementOptions.map((option, index) => {
            return (
              <Box sx={{ display: "flex", gap: "0.5rem", marginBlock: "0.75rem", marginLeft: "1rem" }}>
                <AppIcon className="dsp-logo" fontSize={"small"} icon={getDspIcon(option.code)} />
                <Typography variant="body1">
                  {getDspAdditionalInfo(option.code)?.name ?? option.name}
                  {getDspAdditionalInfo(option.code)?.restriction && (
                    <span className="color-tech-grey" style={{ fontStyle: "italic" }}>
                      {" "}
                      - {getDspAdditionalInfo(option.code)?.restriction}
                    </span>
                  )}
                </Typography>
              </Box>
            );
          })}
        </Collapse>
      </Box>

      <Divider sx={{ marginBlock: "0.5rem" }} />

      <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "space-between" }}>
        <Button
          data-cy={"delete-dsp"}
          color={"error"}
          size={"small"}
          variant={"outlined"}
          onClick={() => {
            deleteChannelsSelection(channel);
          }}
        >
          Remove
        </Button>
        <Button
          data-cy={"edit-dsp"}
          size={"small"}
          variant={"outlined"}
          onClick={() => navigate(pageUtils.getPagePathById(PageId.channelsSetupEdit, { accountId: accountId, channelId: channel.id }))}
        >
          Edit
        </Button>
      </Box>
    </ChannelBox>
  );
};

const ChannelBox = styled(Paper)(() => ({
  padding: "1rem",
  background: "#fff",
  borderRadius: "8px",
  overflowY: "auto",
}));
