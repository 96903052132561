import {Box, styled} from "@mui/material";
import {ColumnsMiddle, H5} from "../../../components/ui/AppElements";

import {ICompanyUsers} from "../../../application/entities/dataTypes/companyUser";
import {useTranslation} from "react-i18next";
import {accountTypeName, EUserGrade, roleName} from "application/utils/granter.utils";
import moment from "moment";

interface IAccountCardProps {
    user: ICompanyUsers;
}

export const UserCard = (props: IAccountCardProps) => {
    const {user} = props;
    const {t} = useTranslation();
    console.log(user.role)
    return (
        <Box sx={{width: "350px"}}>
            <Box sx={{p: 2}}>
                <H5>{t("account.generalTitles.info")}</H5>
                <Row>
                    <CellTitle>{t("account.userDetails.title")}</CellTitle>
                    <Cell>{user.title}</Cell>
                </Row>
                <Row>
                    <CellTitle>{t("account.userDetails.name")}</CellTitle>
                    <Cell>
                        {user.firstName} {user.lastName}
                    </Cell>
                </Row>
                <Row>
                    <CellTitle>{t("account.userDetails.email")}</CellTitle>
                    <Cell>{user.email}</Cell>
                </Row>
                <Row>
                    <CellTitle>{t("account.userDetails.phone")}</CellTitle>
                    <Cell>{user.phone}</Cell>
                </Row>
                <Row>
                    <CellTitle>Company Role</CellTitle>
                    <Cell>{accountTypeName[user.role]}</Cell>
                </Row>
                <Row>
                    <CellTitle>Last login</CellTitle>
                    <Cell>{user?.lastLogin ? moment(user?.lastLogin).format("D-MMM-YYYY h:mm A") : "-"}</Cell>
                </Row>
            </Box>
            <Box sx={{p: 2}}>
                <H5>Brand permissions</H5>
                {user.agentBrandAccountAssociations?.length > 0 && user.role !== "ADMINISTRATOR" ? (
                    user.agentBrandAccountAssociations.map((brandAccountAssociation, index) => {
                        return (
                            <Row key={index}>
                                <CellTitle>{brandAccountAssociation.account.companyName}</CellTitle>
                                <Cell>{roleName[brandAccountAssociation.role as EUserGrade]}</Cell>
                            </Row>
                        );
                    })
                ) : (
                    <p style={{marginTop: "15px", textAlign: "center"}}>
                        {!["ADMINISTRATOR", "ACCOUNT_SUPERADMIN"].includes(user.role) ? "No brand permission" : "Company Administrators have Administrator permissions for all company brands"}
                    </p>
                )}
            </Box>
        </Box>
    );
};

const Row = styled(ColumnsMiddle)((props) => ({
    height: "24px",
}));

const CellTitle = styled(Box)((props) => ({
    padding: 1,
    color: "#7587A3",
    width: "110px",
}));

const Cell = styled(Box)((props) => ({
    padding: 1,
    flex: "1",
    textAlign: "right",
    display: "flex",
    alignContent: "end",
    justifyContent: "right",
    justifyItems: "right",
}));
