import {ColumnsMiddle, Elem, GridItemFull} from '../../../../components/ui/AppElements';
import {Autocomplete, Box, Button, TextField} from '@mui/material';
import {createFilterOptions} from '@mui/material/useAutocomplete';
import * as React from 'react';
import {ChangeEvent} from 'react';
import {objectApi} from '../../../../application/entities/dataApi';
import {AUTHUser} from '../../../../application/utils/AuthUser';
import {pageUtils} from '../../../../application/pages/pages.utils';
import {useNavigate} from 'react-router-dom';
import {ISortParameters} from '../../../../application/entities/api/apiService';
import {AppIcon} from '../../../../components/ui/AppIcon';

interface ISearchFreeType {
  inputValue?: string;
  name: string;
  label: string;
  result: any;
  GRP: string;
  id: number;
}

interface IAudienceBuilderSearchProps {
  audienceStatus: 'Activated' | 'Draft' | 'Archived' | 'All' | string,
  setKw: (args: string)=>void
  sort?: ISortParameters,
  getResults: (options: INaturalSearchResultSet[]) => void
}

export interface INaturalSearchResultSet {
  label: string;
  result: {
    content: any[],
    empty: boolean
    first: boolean
    last: boolean
    number: number
    numberOfElements: number
    size: number
    totalElements: number
    totalPage: number
  };
}

const filter = createFilterOptions<ISearchFreeType>();

export const AudienceBuilderSearch = (props: IAudienceBuilderSearchProps) => {
  
  const {getResults, audienceStatus, sort, setKw} = props;
  
  const [loading, setLoading]       = React.useState(false);
  const [open, toggleOpen]          = React.useState(false);
  const [options, setOptions]       = React.useState<ISearchFreeType[]>([]);
  const [resultSet, setResultSet]   = React.useState<INaturalSearchResultSet[]>([]);
  const [inputValue, setInputValue] = React.useState<string | null>('');
  const [rawInputValue, setRawInputValue] = React.useState<string>('');
  
  const timerSearch             = React.useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
  const history = useNavigate();
  
  const accountsApi = new objectApi.accounts();
  const accountId   = AUTHUser.currentBrandId;
  const noOptText   = inputValue === '' ? 'Type any keyword to find audiences' : `No audiences were found (status : ${audienceStatus})`;
  
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    
    if (!event.target.value) return;
    if (timerSearch.current) clearTimeout(timerSearch.current);
    setInputValue(event.target.value);
    performSearch(event.target.value);
  };
  
  const performSearch = (kw: string, updateState?: boolean) => {
    if (!kw?.length) return;
    
    setLoading(true);
    timerSearch.current = setTimeout(() => {
      accountsApi.naturalFind({
        page       : {pageSize: 250, pageNumber: 0},
        byId       : accountId,
        searchValue: kw,
        sort       : {sortBy: sort?.sortBy ?? 'creationTime', sortDir: sort?.sortDir ?? 'desc'},
        status     : (audienceStatus === 'All') ? undefined : audienceStatus
      }).then((res) => {
        const tmpOpt = (res.data?.detailedResults ?? []).map((listItem: any) => {
          const grp = listItem.label;
          return (listItem.result.content ?? []).map((item: any) => {
            return {GRP: grp, ...item};
          });
        }).flat(1);
        
        setLoading(false);
        
        setOptions(tmpOpt ?? []);
        setResultSet(res.data?.detailedResults ?? []);
        if (updateState) {
          getResults(res.data?.detailedResults ?? []);
        }
      }).catch(() => {
        setLoading(false);
      });
    }, 900);
  };
  
  const resetOptions = () => {
    setInputValue('');
    setResultSet([]);
    setOptions([]);
    getResults([]);
    setInputValue('');
    setKw('');
  };
  
  return <GridItemFull data-cy={'AudienceBuilderSearch'}>
    <ColumnsMiddle>
      <Elem>
        Filter Audiences
      </Elem>
      <Box sx={{px: 1}}>
        <form onSubmit={(event)=>{event.preventDefault();// event.stopPropagation();
          setKw(rawInputValue);
          getResults(resultSet);
          toggleOpen(true);}}>
        <Autocomplete
          style={{width: 250}}
          data-cy={'filterAudienceInput'}
          inputValue={inputValue ?? ''}
          onOpen={() => {
            toggleOpen(true);
          }}
          onClose={() => {
            toggleOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setRawInputValue(newInputValue)
          }}
          onChange={(event, newValue) => {
            event.preventDefault();
            event.stopPropagation();
            if (typeof newValue === 'string') {
              toggleOpen(true);
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              // getResults(resultSet);
              setKw(rawInputValue)
              setInputValue(rawInputValue)
            } else {
              if (newValue?.id) history(pageUtils.getPagePathById('audience-builder-update', {id: newValue.id}));
            }
            
            return false;
          }}
          groupBy={(option) => option.GRP}
          getOptionLabel={(option: any) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name ?? '';
          }}
          options={options ?? []}
          loading={loading}
          autoSelect={false}
          blurOnSelect={true}
          noOptionsText={noOptText}
          popupIcon={<AppIcon display={'block'} color={'#00B5E2'} icon={'ChevronDownBlue'}/>}
          renderInput={(params) => (
            <TextField {...params} data-cy={'InputSearch'} placeholder={'Type keywords to find audiences'}
                       onChange={handleSearch}
                       InputProps={{
                         ...params.InputProps,
                         startAdornment: <div style={{marginLeft: '0.8rem'}}><AppIcon display={'block'} color={'#00B5E2'} fontSize={'tiny'} icon={'SearchIcon'}/></div>,
                       }} />)}
          filterOptions={(options, params) => {
            if (options.length > 1) {
              options.splice(0, 0, {
                inputValue: `See all the results for "${inputValue}"`
              } as ISearchFreeType);
            }
            return [...options];
          }}
        />
        </form>
      </Box>
      {Boolean(options.length) && <Box sx={{px: 1}}>
          <Button data-cy={'BtnResetSearch'} variant={'text'} onClick={resetOptions}>reset search</Button>
      </Box>}
    </ColumnsMiddle>
  </GridItemFull>;
};
