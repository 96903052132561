import { useQuery } from "react-query";
import { objectApi } from "../../../application/entities/dataApi";
import { BackendError } from "../../../types/backendError";

export const useSubscriptionPlansQuery = () => {
  const accountsApi = new objectApi.subscriptionPlans();

  const { isLoading, error, data, refetch } = useQuery<any, BackendError>(
    ["subscriptionPlans"],
    () => {
      return accountsApi.get().then((res) => res);
    },
    {
      cacheTime: 0,
      enabled: true,
    }
  );

  return { isLoading, error, data, refetch };
};
