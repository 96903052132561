import {Box, styled,Button} from '@mui/material';
import {FunctionComponent} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
import {useUserQuery} from '../../components/hooks/data/useUserQuery';
import {ICompanyUsers} from '../../application/entities/dataTypes/companyUser';
import {PageId} from '../../application/pages/pages.config';
import { pageUtils } from 'application/pages/pages.utils';
import {MainFrame} from '../../components/templates/MainFrame';
import {FormBlock, ShadowBox} from '../../components/templates/InfoBlock';
import {UserViewDetails} from '../CompanyUsers/View/UserViewDetails';
import {UserBrandAssociationTable} from './shared/UserBrandAssociationsTable';
import {H4} from 'components/ui/AppElements';
import { GlobalAvatar } from 'components/ui/GlobalAvatar';
import { AUTHUser } from 'application/utils/AuthUser';
import { granter, EUserRole } from 'application/utils/granter.utils';
interface IUserProfileViewProps {
  userId: number;
};


export const UserProfileView: FunctionComponent<IUserProfileViewProps> = (props: IUserProfileViewProps) => {
  const uriParams = useParams<{ userId: string; }>() as { userId: string };
  const userId    = props?.userId ?? +uriParams?.userId;
  
  const userQuery               = useUserQuery({userId: userId});
  const userData: ICompanyUsers = userQuery?.data ?? {};
  const adminMessage = userData.role ==='ADMINISTRATOR' && userData.account.accountType === "COMPANY" ? 'Company Administrators have Administrator permissions for all company brands' : undefined;
  const noPermissionMessage = userData.role !='ADMINISTRATOR' && userData.agentBrandAccountAssociations?.length === 0 ? 'At present, no brand permissions have yet been granted to your user profile':undefined; 

  const navigate = useNavigate();
  const headerContent = <div className={'flex-h flex-align-middle pad-tb'}>
    <Box sx={{px: 2}}>
      <GlobalAvatar
        user={userData}
        isPending={userData.status !== 'ACTIVATED' }
        size={'34px'}
        fontSize={'18px'}
      />
    </Box>
    <Box>
      <H4>{userData.firstName} {userData.lastName}</H4>
      <p>{`User ID#: ${userData.id}`}</p>
      <p>{userData?.account?.companyName}, {userData?.account?.country}`</p>
    </Box>
  </div>;
 
  
  return <MainFrame
  headerProps={{
    hideHeaderDefault: true,
    hideHeaderIcon   : true,
    
  }}
    headerContent={headerContent}
    frameId={PageId.userProfileView}>
    <UserProfileViewContainer>
      <div className="flex-main flex-h gap-small">
        <FormBlock className={'pad-ii-3 pad-b-2'} noBorder
                   fullHeight
                   style={{width: '700px'}}
                  buttons={<Button onClick={() => {navigate(pageUtils.getPagePathById(PageId.userEdit ,{userId:userId})) }} variant={'outlined'}>Edit User</Button>}
                   title={'User Details'}>
          <UserViewDetails userDetails={userData}/>
        </FormBlock>
        {granter.grantAccesses([{role: EUserRole.DATA_CLIENT}]).toUser(AUTHUser.granterUser) &&
        <div>
          <FormBlock noBorder title={'My Brands and Permissions'}>
            {Boolean(adminMessage) ? 
            <div className={'pad-2'} data-cy="admin-permission-message">
              {adminMessage}
            </div>
          :
          Boolean(noPermissionMessage) ?
          <div className={'pad-2'} data-cy="admin-permission-message">
              {noPermissionMessage}
            </div>
          :
          <div className={'pad-1'}>
            <ShadowBox className={'pad-2'}>
              <UserBrandAssociationTable userId={userId}/>
            </ShadowBox>
          </div>
          }
          </FormBlock>

        </div>
        }
      </div>
    
    </UserProfileViewContainer>
  </MainFrame>;
};

interface IUserProfileViewContainerProps {
};

const UserProfileViewContainer = styled(Box)<IUserProfileViewContainerProps>((props) => ({
  backgroundColor: props.theme.palette.background.default,
  maxHeight      : '100%',
  height         : '100%',
  width          : '100%',
}));

