import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {IAccountConfParameter, IAccountConfParameters, IAccounts} from "application/entities/dataTypes/accounts";
import {getCurrencySymbol} from "application/utils/formatters.utils";
import {StyledCheckbox} from "components/form/CheckBox";
import {useAppToast} from "components/live/AppToast";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {DetailBlock, InfoBlockTitle, InputFieldLabel} from "components/templates/InfoBlock";
import {AppIcon} from "components/ui/AppIcon";
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import React, {FocusEvent} from "react";
import {useTranslation} from "react-i18next";

export const EditAccountConfParameters = (props: { account: Partial<IAccounts> }) => {
    const {account} = props;
    const accountId = Number(account.id);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [accountConfParams, setAccountConfParams] = React.useState<IAccountConfParameters>({} as IAccountConfParameters);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [providerList, setProviderList] = React.useState<{ companyName: string; id: string; checked: boolean }[]>([]);
    const initialAccountConfParams = React.useRef({} as IAccountConfParameters);

    const appToast = useAppToast();

    const {t} = useTranslation();
    const dialogWindow = useOpenDialogWindow();

    const accountsApi = new objectApi.accounts();

    React.useEffect(() => {
        if (accountId) {
            Promise.all([
                accountsApi.getAdminAccountConfParameters(accountId),
                accountsApi.search({
                    page: {
                        pageSize: 200,
                        pageNumber: 0,
                    },
                    query: {dataProvider: true},
                    sort: {
                        sortBy: "id",
                        sortDir: "asc",
                    },
                }),
            ]).then((res) => {
                const accountConfParameters = res[0].data.accountConfParameters;

                setAccountConfParams(accountConfParameters);
                initialAccountConfParams.current = accountConfParameters as IAccountConfParameters;
                const providersFormatted = res[1].data.searchResult?.content.map((provider: any) => {
                    return {
                        companyName: provider.companyName,
                        id: String(provider.id),
                        checked: accountConfParameters.fixed_provider_list.split(",").includes(String(provider.id)) ? true : false,
                    };
                });
                setProviderList(providersFormatted);

                setLoading(false);
            });
        }
    }, [accountId]);

    const handleChange = (e: any) => {
        //for Boolean values
        if (["account_is_monthly_subscription", "enable_impression_based_buy", "enable_pay_later", "enable_first_party_data"].includes(e.target.name)) {
            if (e.target.name === "enable_first_party_data" && e.target.checked === false) {
                //exception for enable_first_party_data to confirm disable
                dialogWindow.open({
                    dialogId: "disableFirstPartyData",
                    buttonActions: {acceptButton: "Disable First Party Data", cancelButton: true},
                    title: "Disable First Party Data?",
                    description: "This action CAN'T BE UNDONE, all the data will be REMOVED from our server",
                    icon: "Trash",
                    onAccept: () => {
                        saveAccountConfParameter(e.target.name, false);
                        setAccountConfParams((prev) => ({...prev, [e.target.name]: false}));
                    },
                });
            } else {
                saveAccountConfParameter(e.target.name, e.target.checked);
                setAccountConfParams((prev) => ({...prev, [e.target.name]: e.target.checked}));
            }
        } else {
            setAccountConfParams((prev) => ({...prev, [e.target.name]: e.target.value}));
        }
    };

    const handleChangeProviderList = (event: SelectChangeEvent<String[]>) => {
        const newValues = event.target.value;
        setProviderList((prev) =>
            prev.map((provider) => {
                if (newValues.includes(provider.id)) {
                    provider.checked = true;
                } else {
                    provider.checked = false;
                }
                return provider;
            })
        );
    };

    const handleBlurProviderList = () => {
        const providerToSave = providerList.filter((provider) => provider.checked).map((provider) => provider.id);
        saveAccountConfParameter("fixed_provider_list", providerToSave.length === 0 ? " " : providerToSave.join(","));
    };

    const saveAccountConfParameter = (paramName: IAccountConfParameter, value: boolean | number | string) => {
        const data = {
            paramName: paramName,
            paramValue: value,
        };
        accountsApi.setAdminAccountConfParameters(accountId, data).then((res) => {
            initialAccountConfParams.current = {...initialAccountConfParams.current, [paramName]: value};
            const newValue = value === true ? "On" : value === false ? "Off" : value;
            if (res.status !== 200) {
                appToast.error({
                    message: "Error while saving " + paramName + ", please retry",
                });
            } else {
                appToast.success({
                    message: t(`account.accountConfParameters.${paramName}`) + " has been changed to " + newValue,
                });
            }
        });
    };

    const onBlurInputText = (e: FocusEvent<HTMLInputElement>) => {
        let paramName = e.target.name as IAccountConfParameter;
        let value = Object.entries(accountConfParams).filter((item) => item[0] === e.target.name)[0][1];
        let initalValue = Object.entries(initialAccountConfParams.current).filter((item) => item[0] === e.target.name)[0][1];
        if (value !== initalValue) {
            saveAccountConfParameter(paramName, value);
        }
    };

    return (
        <>
            <DetailBlock data-cy={"subscription-details"}>
                <div
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer"}}
                    onClick={() => setIsVisible(!isVisible)}
                >
                    <InfoBlockTitle style={{margin: "0"}}>Advanced parameters</InfoBlockTitle>
                    <AppIcon icon="ChevronDownBlue" fontSize="medium" rotate={isVisible ? 180 : 0}></AppIcon>
                </div>

                {isVisible && !loading && (
                    <div style={{paddingTop: "1rem"}}>
                        <div
                            style={{
                                marginBottom: "1.5rem",
                                background: "#00b5e217",
                                borderRadius: "7px",
                                padding: "1rem",
                                fontWeight: "bold"
                            }}
                            className="color-tech-grey"
                        >
                            Advanced parameters are updated live when you click on a checkbox or change a value and exit
                            the field.
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            marginBottom: "1rem"
                        }}>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={Boolean(accountConfParams?.enable_pay_later)}
                                        onChange={handleChange}
                                        name="enable_pay_later"
                                        value={Boolean(accountConfParams?.enable_pay_later)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography
                                            component="span">{t("account.accountConfParameters.enable_pay_later")}</Typography>
                                    </>
                                }
                            />

                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={Boolean(accountConfParams?.enable_impression_based_buy)}
                                        onChange={handleChange}
                                        name="enable_impression_based_buy"
                                        value={Boolean(accountConfParams?.enable_impression_based_buy)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography
                                            component="span">{t("account.accountConfParameters.enable_impression_based_buy")}</Typography>
                                    </>
                                }
                            />

                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={Boolean(accountConfParams?.account_is_monthly_subscription)}
                                        onChange={handleChange}
                                        name="account_is_monthly_subscription"
                                        value={Boolean(accountConfParams?.account_is_monthly_subscription)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography
                                            component="span">{t("account.accountConfParameters.account_is_monthly_subscription")}</Typography>
                                    </>
                                }
                            />
                        </div>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            marginBottom: "1rem"
                        }}>
                            {account.accountType === "COMPANY" && Boolean(accountConfParams?.account_is_monthly_subscription) && (
                                <div>
                                    <InputFieldLabel>{t("account.accountConfParameters.brand_monthly_subscription_usd")}</InputFieldLabel>

                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="brand_monthly_subscription_usd"
                                        name="brand_monthly_subscription_usd"
                                        value={accountConfParams?.brand_monthly_subscription_usd}
                                        onChange={handleChange}
                                        onBlur={onBlurInputText}
                                        inputProps={{"data-cy": "edit_brand_monthly_subscription_usd"}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" style={{marginLeft: "10px"}}>
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}

                            {account.accountType === "COMPANY" && Boolean(accountConfParams?.account_is_monthly_subscription) && (
                                <div>
                                    <InputFieldLabel>{t("account.accountConfParameters.company_monthly_subscription_usd")}</InputFieldLabel>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="company_monthly_subscription_usd"
                                        name="company_monthly_subscription_usd"
                                        value={accountConfParams?.company_monthly_subscription_usd}
                                        onChange={handleChange}
                                        onBlur={onBlurInputText}
                                        inputProps={{"data-cy": "edit_company_monthly_subscription_usd"}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" style={{marginLeft: "10px"}}>
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}

                            <div>
                                <InputFieldLabel sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    {t("account.accountConfParameters.account_price_multiplier")}{" "}
                                    <AppTip
                                        text={
                                            'Apply a multiplier on CPM. If set to "0" the CPM will be 0. If you want to apply a 10% discount put 0.9. If you want to apply a 10% increase , put 1.1'
                                        }
                                    />
                                </InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="account_price_multiplier"
                                    name="account_price_multiplier"
                                    value={accountConfParams?.account_price_multiplier}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_account_price_multiplier"}}
                                />
                            </div>

                            <div>
                                <InputFieldLabel sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    {t("account.accountConfParameters.fixed_additional_cpm")} <AppTip
                                    text={"Apply a fixed cost to CPM. Must be at least 0"}/>
                                </InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="fixed_additional_cpm"
                                    name="fixed_additional_cpm"
                                    value={accountConfParams?.fixed_additional_cpm}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_fixed_additional_cpm", style: {textAlign: "left"}}}
                                    InputProps={{
                                        sx: {textAlign: "left", marginBottom: "0px"},
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{pl: "10px", mr: "0"}}>
                                                {getCurrencySymbol("USD")}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div>
                                <InputFieldLabel sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    {t("account.accountConfParameters.agency_cpm_multiplier")}
                                    <AppTip
                                        text={"Multiplier set by the agency for CPM management. Must be at least 1"}/>
                                </InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="agency_cpm_multiplier"
                                    name="agency_cpm_multiplier"
                                    value={accountConfParams?.agency_cpm_multiplier}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_agency_cpm_multiplier"}}
                                />
                            </div>

                            <div>
                                <InputFieldLabel sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    {t("account.accountConfParameters.agency_additional_cpm")}
                                    <AppTip
                                        text={"Additionnal fee set by agency for CPM management. Must be at least 0"}/>
                                </InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="agency_additional_cpm"
                                    name="agency_additional_cpm"
                                    value={accountConfParams?.agency_additional_cpm}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_agency_additional_cpm", style: {textAlign: "left"}}}
                                    InputProps={{
                                        sx: {textAlign: "left", marginBottom: "0px"},
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{pl: "10px", mr: "0"}}>
                                                {getCurrencySymbol("USD")}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div>
                                <InputFieldLabel>{t("account.accountConfParameters.max_enablement_profiles")}</InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="max_enablement_profiles"
                                    name="max_enablement_profiles"
                                    value={accountConfParams?.max_enablement_profiles}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_max_enablement_profiles"}}
                                />
                            </div>

                            <div>
                                <InputFieldLabel>{t("account.accountConfParameters.fixed_provider_list")}</InputFieldLabel>
                                <Select
                                    id="fixed_provider_list"
                                    labelId="fixed_provider_label"
                                    multiple
                                    value={providerList.filter((provider) => provider.checked).map((provider) => provider.id)}
                                    fullWidth
                                    onChange={handleChangeProviderList}
                                    onBlur={handleBlurProviderList}
                                    renderValue={(selected) => selected.join(", ")}
                                >
                                    {providerList.map((provider) => (
                                        <MenuItem key={provider.id} value={provider.id}>
                                            <Checkbox size="small" checked={provider.checked}/>
                                            <ListItemText primary={provider.id + " - " + provider.companyName}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        <div>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={Boolean(accountConfParams?.enable_first_party_data)}
                                        onChange={handleChange}
                                        name="enable_first_party_data"
                                        value={Boolean(accountConfParams?.enable_first_party_data)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography
                                            component="span">{t("account.accountConfParameters.enable_first_party_data")}</Typography>
                                    </>
                                }
                            />
                        </div>
                    </div>
                )}
            </DetailBlock>
        </>
    );
};
