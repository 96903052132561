import React from 'react';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {useAppToast} from '../../../components/live/AppToast';
import {ActionButtons} from '../../../components/controls/ActionButtons';
import {useTranslation} from 'react-i18next';
import {objectApi} from '../../../application/entities/dataApi';
import {actions} from 'application/actions/actions';
import {AUTHUser} from '../../../application/utils/AuthUser';
import {useAuthActions} from '../../../redux/slices/auth.slices';
import {useOpenDialogWindow} from '../../../components/live/DialogWindow';

export interface ButtonEditDataVaultProps {
    account: any; // IAccounts;
    setAccount: (arg: IAccounts & Partial<IAccounts>) => void;
}

export const ButtonEditDataVault = (props: ButtonEditDataVaultProps) => {
    const {
        account,
        setAccount,
    } = props;

    const appToast = useAppToast();
    const authActions = useAuthActions();
    const windowDialog = useOpenDialogWindow();

    const {t} = useTranslation();
    const accountsApi = new objectApi.accounts();

    const handleChangeActivation = (args?: { accountId: number }) => {
        if (account && account.id) {


            const meth = account.activated ? accountsApi.suspendAccount : accountsApi.activateAccount;

            meth(account.id).then((res) => {
                if (res) setAccount({...account, activated: !account.activated});
                // reload user session
                if (AUTHUser.accountId === account.id) {
                    authActions.doRefreshUser();
                }
                appToast.open({
                    toastId: 'manageAccount',
                    severity: 'success',
                    message: t('message.account.updatedSuccess'),
                });
            }).catch(err => {
                const message = err?.response?.data?.error?.message;
                appToast.open({
                    toastId: 'manageAccount',
                    severity: 'error',
                    message: message ?? t('labels.error'),
                });
            });
        }
    };

    const confirmActivation = () => {
        const desc = account.activated ? 'You are monetizing your data.  To turn off your data from the Evorra marketplace, please contact your Evorra representative or support@evorra.com.' : 'I confirm that I would like to monetize my data in the Evorra marketplace.';
        windowDialog.open({
            icon: 'Permissions',
            dialogId: 'DataActivation',
            title: 'Activation',
            description: desc,
            buttonActions: {acceptButton: true, cancelButton: true},
            onAccept: handleChangeActivation
        });
    };

    let actionsButtons = actions.dataVault;
    actionsButtons.suspend.action = confirmActivation;
    actionsButtons.suspend.code = 'suspend-btn'

    return (
        <>
            {account?.id && <ActionButtons actions={actionsButtons} vars={{
                account,
                accountId: account.id,
                overDisabled: AUTHUser.isEvorraAdmin()
            }}/>}
        </>
    );
};
