import { Box } from "@mui/material";
import { styled } from "@mui/styles";

import _ from "lodash";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { featureColor, featureGroupColor, featureIcon, featureTypesPresets } from "pages/AudienceBuilder/configuration";
import { IFiltersValues } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { AudienceFeatureNodeBox } from "./AudienceFeatureNodeBox";
import { EventDropFeatureProps } from "./Cards/ViewAudienceFeatureTree";
import { ViewInclusionList } from "./Cards/ViewInclusionList";
import { drawAudienceFeatures } from "./audienceFeature";

export interface AudienceFeatureItemProps {
  eventDropData: EventDropFeatureProps;
  id: any;
  viewMode?: boolean;
  code?: string;
  parentCode?: string;
}

export const AudienceFeatureNode = (props: any) => {
  const cardCode = props.code;

  const propsData = props.data;
  const featureId = propsData?.feature?.id;
  const viewMode = props.viewMode ?? false;

  const filtersValues = useAudienceBuilderStore(
    (st) =>
      st.filtersValues.filter((el: any) => {
        return el.code === cardCode;
      })[0]
  );

  const evFeature = useAudienceBuilderStore(
    (st) => st.EvFeatures.find((feature) => feature.code === cardCode),
    (old, ne) => _.isEqual(old, ne)
  );

  const audienceActions = useAudienceBuilderStore((st) => st.actions);

  const getGutterColor = (filterValue: IFiltersValues) => {
    if (featureColor?.[filterValue.code]) {
      return featureColor?.[filterValue.code];
    } else if (filterValue?.parentCode && featureColor?.[filterValue.parentCode]) {
      return featureColor?.[filterValue.parentCode];
    } else {
      return featureGroupColor?.[filterValue.groupCode];
    }
  };

  const getIcon = (filterValue: IFiltersValues) => {
    if (featureIcon?.[filterValue.code]) {
      return featureIcon?.[filterValue.code];
    } else if (filterValue?.parentCode && featureIcon?.[filterValue.parentCode]) {
      return featureIcon?.[filterValue.parentCode];
    } else {
      return featureIcon?.[filterValue.groupCode];
    }
  };

  let nodeBoxProps: Record<string, any> = {
    gutterColor: filtersValues && getGutterColor(filtersValues),
    icon: filtersValues && getIcon(filtersValues),
  };

  if (!viewMode) {
    nodeBoxProps.removeAction = () => {
      audienceActions.deleteFeature(filtersValues.code, ["TRAVELROUTE", "TRAVELPERIOD"].includes(filtersValues?.parentCode ?? "") ? true : false);
    };
  }

  //exception for inclusion list cause we display a box by file selected
  if (cardCode === "INCLUSION_LIST") {
    return <ViewInclusionList viewMode={viewMode ?? false} />;
  }
  return (
    <FeatureContainer
      onClick={() => {
        if (!viewMode) {
          audienceActions.setActiveFeatureGroupCode(filtersValues.groupCode);
        }
      }}
      data-cy={filtersValues?.code}
    >
      <AudienceFeatureNodeBox {...nodeBoxProps} viewMode={viewMode ?? false}>
        <Box sx={{ p: 0.5, fontWeight: "bold", textTransform: "capitalize" }}>
          {featureTypesPresets[filtersValues.code]?.title ?? filtersValues.name}
        </Box>
        <AudienceFeaturesWrapper viewMode={props.viewMode ?? false}>{drawAudienceFeatures(filtersValues, viewMode)}</AudienceFeaturesWrapper>
      </AudienceFeatureNodeBox>
    </FeatureContainer>
  );
};

export const FeatureContainer = styled("div")({});

export const AudienceFeaturesWrapper = styled("div")((props: { viewMode: boolean }) => ({
  maxHeight: "auto",
  maxWidth: "100%",
  overflowY: "auto",
}));
