import {AbstractBaseApi} from './AbstractBaseApi';
import {ISubscriptions} from '../dataTypes/subscriptions';
import {apiService} from '../api/apiService';
import {AxiosResponse} from 'axios';
import {TEntities} from '../dataTypes';


export class Subscriptions extends AbstractBaseApi<ISubscriptions> {
  constructor() {
    super('subscriptions');
  }

  getSubscriptionPlan(id: number) {
    return apiService.entity(['subscriptions', 'subscriptionPlan'])
                     .byId(id)
                     .get()
                     .then((res: AxiosResponse<TEntities['subscriptionPlans']>) => {
                       return res?.data;
                     });
  }
  
  updateSubscriptionPlan(id: number, subscriptionPlanId: any) {
    
    return apiService.entity(['subscriptions', 'subscriptionPlan'])
                     .byId(id)
                     .put([subscriptionPlanId],{asUriFragment: true})
                     .then((res: AxiosResponse<TEntities['subscriptionPlans']>) => {
                       return res?.data;
                     });
  }
  
}