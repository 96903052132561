import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState}                  from "redux/store/rootReducer";
import {useDispatch, useSelector}   from "react-redux";
import {DialogProps}                from "@mui/material/Dialog/Dialog";

export type TDialogId = string;
export type TOpenDialogAction = TDialogId;
export type TCloseDialogAction = TDialogId;

export type TComponentProps = Record<string, any>;

interface IDialogButtonActions {
  cancelButton?: Boolean | string;
  dismissButton?: Boolean | string;
  acceptButton?: Boolean | string;
  acceptValue?: Boolean | any;
}

interface IDialog {
  dialogId: string;
  title?: string | undefined;
  description?: string;
  open?: boolean;
  component?: any;
  componentProps?: TComponentProps;
  content?: any;
  onClose?: (value?: any) => void
  onAccept?: (value?: any) => void
  dialogProps?: Partial<Omit<DialogProps, 'open'>>
  buttonActions?: IDialogButtonActions
}

interface ISetDialogAttributes {
  dialogId?: string;
  title?: string;
  description?: string;
  componentProps?: TComponentProps;
  component?: any;
  content?: any;
  onClose?: (value?: any) => void
  onAccept?: (value?: any) => void
  dialogProps?: Partial<Omit<DialogProps, 'open'>>
  buttonActions?: IDialogButtonActions
}

type IDialogState = Record<string, IDialog>

const initialState: IDialogState = {} as IDialogState;

export interface IDialogAttributes {
  dialogId: string;
  title?: string;
  description?: string;
  componentProps?: TComponentProps;
  component?: any;
  content?: any;
  onClose?: (value?: any) => void
  onAccept?: (value?: any) => void
  dialogProps?: Partial<Omit<DialogProps, 'open'>>
  buttonActions?: IDialogButtonActions
}


const dialogSliceBis = createSlice({
  name    : "dialogSliceBis",
  initialState,
  reducers: {
    openDialog(state, action: PayloadAction<{ dialogId: string }>) {
      
      const {dialogId} = action.payload
      
      if (state[dialogId]) {
        state[dialogId] = {open: true, ...state[dialogId]}
      }
      
    },
    closeDialog(state, action: PayloadAction<string>) {
      const dialogId = action.payload
      if (state[dialogId]) {
        state[dialogId] = {open: false, ...state[dialogId]}
      }
      return {...state}
    },
    setDialogAttributes: function (state, action: PayloadAction<any>) {
      const {dialogId} = action.payload
      
      if (dialogId && state[dialogId]) {
        state[dialogId] = {...state[dialogId],...action.payload}
      }
      return {...state}
    },
    setComponentProps(state, action: PayloadAction<any>) {
      const {dialogId} = action.payload;
      
      if (state[dialogId]) {
        const componentProps = action.payload.componentProps;
        if (!state[dialogId].componentProps) {
          state[dialogId].componentProps = componentProps
        } else {
          state[dialogId].componentProps = {...state[dialogId]?.componentProps, ...componentProps}
        }
        
        return {...state}
      }
      
    },
  },
});

const {openDialog, closeDialog, setDialogAttributes} = dialogSliceBis.actions;

function DialogActions() {
  const dispatch = useDispatch();
  
  return {
    dialog: (dialogId: string) => {
      return {
        openDialog   : async () => {
          return dispatch(openDialog({dialogId}));
        },
        close        : async (args: any) => {
          return dispatch(closeDialog(dialogId));
        },
        setAttributes: async (args: ISetDialogAttributes) => {
          args.dialogId = dialogId;
          return dispatch(setDialogAttributes(args));
        },
      }
    },
  };
}

function DialogState(dialogId?: string) {
  return useSelector((state: RootState) => dialogId ? state.dialogSliceBis[dialogId] : state.dialogSliceBis || {});
}

export const useDialogActions = DialogActions;
export const useDialogState   = DialogState;

export {openDialog, closeDialog, setDialogAttributes}

export default dialogSliceBis.reducer;
