import {Box, styled} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {TIcon} from '../../../assets/icons';
import {AppIcon} from '../../ui/AppIcon';

const SlideBoxContext = React.createContext<SlideBoxContextProps>({} as SlideBoxContextProps);

type SlideBoxContextProps = {
  actions: SlideBoxActionsProps,
  state: any
}

type SlideBoxActionsProps = {
  open: () => void,
  close: () => void,
  next: () => void,
  previous: () => void,
  last: () => void,
  first: () => void,
}

interface SlideBoxProps {
  children?: any;
  list: SlideBoxListType[];
}

interface CompoundedComponent extends React.ForwardRefExoticComponent<SlideBoxProps & React.RefAttributes<HTMLInputElement>> {
  ButtonPrevious: FunctionComponent<any>;
  ButtonNext: FunctionComponent<any>;
  Content: FunctionComponent<any>;
}

type ContextState = {
  activeIndex: number
  list: SlideBoxListType[]
}

export type SlideBoxListType = {
  title?: string,
  content: any
}

export const SlideBox = React.forwardRef((props: SlideBoxProps, ref: React.Ref<{ actions: SlideBoxActionsProps }>) => {
  
  const initialState: ContextState = {
    activeIndex: 0,
    list       : props.list ?? []
  };
  
  const {children}  = props;
  const [state, setState] = React.useState<ContextState>(initialState);
  
  React.useImperativeHandle(ref, () => ({actions}));
  
  const actions = {
    open    : () => {},
    close   : () => {},
    next    : () => {
      setState((curr) => {
        return {
          ...curr,
          activeIndex: (state.activeIndex === (state.list.length - 1)) ? 0 : (curr.activeIndex + 1)
        };
      });
    },
    previous: () => {
      if (state.activeIndex === 0) return;
      setState((curr) => {
        return {
          ...curr,
          activeIndex: (curr.activeIndex - 1)
        };
      });
    },
    last    : () => {
      setState((curr) => {
        return {
          ...curr,
          activeIndex: (state.list.length - 1)
        };
      });
    },
    first   : () => {
      setState((curr) => {
        return {
          ...curr,
          activeIndex: 0
        };
      });
      
    },
  };
  
  return <SlideBoxContainer data-cy={'SlideBox'}>
    <SlideBoxContext.Provider value={{actions, state}}>
      {React.Children.toArray(children).map((child: any, index: any) => {
        return React.cloneElement(child, {...props});
      })}
    </SlideBoxContext.Provider>
  </SlideBoxContainer>;
}) as CompoundedComponent;

type SlideBoxButtonsProps = {
  icon?: TIcon
}

const ButtonPrevious = (props: SlideBoxButtonsProps) => {
  const context = useSlideBoxContext();
  
  return <Box sx={{p: 0.5, px: 2,}}>
    <AppIcon onClick={context.actions.previous} fontSize={'tiny'} color={'#00B5E2'} icon={'ChevronDownIcon'} rotate={90}/>
  </Box>;
};

const ButtonNext = (props: SlideBoxButtonsProps) => {
  const context = useSlideBoxContext();
  
  return <Box sx={{p: 0.5, px: 2,}}>
    <AppIcon onClick={context.actions.next} fontSize={'tiny'} color={'#00B5E2'} icon={'ChevronDownIcon'} rotate={-90}/>
  </Box>;
};

const Content          = () => {
  const context = useSlideBoxContext();
  
  const {content} = context.state.list?.[context.state.activeIndex];
  
  return <Box sx={{flex: 1, p: 0.5, py: 1, width: '360px', overflow: 'hidden'}}>
    {content}
  </Box>;
};
const ContentContainer = styled(Content)((props) => ({...props}));

SlideBox.ButtonPrevious = ButtonPrevious;
SlideBox.ButtonNext     = ButtonNext;
SlideBox.Content        = ContentContainer;

interface ISlideBoxContainerProps {
}

const SlideBoxContainer = styled(Box)<ISlideBoxContainerProps>((props) => ({}));

function useSlideBoxContext() {
  const context = React.useContext(SlideBoxContext);
  if (!context) {
    throw new Error(
      `SlideBox compound components cannot be rendered outside the SlideBox component`,
    );
  }
  return context;
}