import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButtons, IActionButtonsList } from '../../../components/controls/ActionButtons';
import {accessList} from '../../../application/access/access';

/**
 * The Block of buttons for User List Page
 * these buttons will be shown in the FrameContent Header
 *
 */

interface IButtonBlockForHeaderUsersProps {
  exportReference?: React.Ref<HTMLButtonElement>;
}

export const ButtonBlockForHeaderUsers: React.FC<IButtonBlockForHeaderUsersProps> = ({
  exportReference,
}) => {

  const { t } = useTranslation();
  const expRef = exportReference as RefObject<HTMLButtonElement>;

  const handleDownloadAll = (args:any) => {
    expRef && expRef?.current?.click();
  };

  const actions: IActionButtonsList = {
    usersList: {
      download: {
        title: t('labels.downloadAll'),
        variant: 'outlined',
        action: handleDownloadAll,
        actionVars: ['expRef'],
        accesses: accessList.accounts.download,
      },
    },
  };

  return <ActionButtons actions={actions.usersList} vars={{expRef}} />;
};
