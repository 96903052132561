import { objectApi } from "application/entities/dataApi";
import React from "react";
import { IAccounts } from "types";
import { apiService } from "../../application/entities/api/apiService";
import { ICompanyUsers } from "../../application/entities/dataTypes/companyUser";
import { AUTHUser, IGranterUser } from "../../application/utils/AuthUser";
import { authStore } from "../../stores/auth.store";
import { useUserQuery } from "./data/useUserQuery";

const searchLoggedInUser = () => {
  return apiService.entity("companyUsers").find("loggedInUser").fetch();
};

type IUseLoggedUser = {
  user: ICompanyUsers | undefined;
  AUTHUser: IGranterUser | undefined; // casted data for grant operations
} & any;

const accountsApi = new objectApi.accounts();

export const useLoggedUser = (): IUseLoggedUser => {
  const [userId, setUserID] = React.useState<number | undefined>(undefined);
  // used to monitor query changes with client queryReFetch
  const qyUser = useUserQuery({ userId }); // react-query
  const userData = qyUser?.data ?? {};
  // new auth.store
  const loggedIn = authStore((st) => st.loggedIn);

  const [privateAUTHUser, setPrivateAUTHUser] = React.useState<typeof AUTHUser>(AUTHUser);

  React.useEffect(() => {
    init();
  }, [loggedIn]);

  React.useEffect(() => {
    if (userData.id) {
      // used to keep  UserSingleton dynamic ...
      AUTHUser.setUser(userData);
      //get the company to list brands
      accountsApi.byId(userData.account.id).then((account: IAccounts) => {
        AUTHUser.setCompanyBrands(account);
      });

      setPrivateAUTHUser(AUTHUser);
    }
  }, [qyUser?.isLoading, userData]);

  function init() {
    searchLoggedInUser()
      .then((res) => {
        if (res?.data?.id) {
          setUserID(res.data.id);
        }
      })
      .catch((e) => {
        // means user is not logged
        console.error("User is not logged in");
      });
  }

  function refetch() {
    qyUser.refetch();
  }
  function remove() {
    qyUser.remove();
  }
  return {
    user: userData, // full user with projection
    AUTHUser: privateAUTHUser,
    reload: init,
    refetch: refetch,
    remove: remove,
  };
};
