import { Theme, styled, Button, Typography, Box } from "@mui/material";
import { GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { actions } from "application/actions/actions";
import { objectApi } from "application/entities/dataApi";
import { audiencesColumns } from "application/entities/dataColumns/audiences.columns";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { AUTHUser } from "application/utils/AuthUser";
import { dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { ApiProgress } from "components/live/ApiProgress";
import { useAppToast } from "components/live/AppToast";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { GridTable } from "components/organisms/GridTable";
import { GridItemFull, GridMiddleCenter, StackerFullHeight } from "components/ui/AppElements";
import { AppIcon } from "components/ui/AppIcon";
import { t } from "i18next";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

export const AudiencesToApprove = (props: { accountId?: number; pendingApproval: any; updatePendingApproval: () => void }) => {
  const accountId = props?.accountId ?? AUTHUser.accountId;
  const audienceApi = new objectApi.audiences();
  const navigate = useNavigate();
  const appToast = useAppToast();

  const pendingApproval = props?.pendingApproval ?? [];

  const dialogWindow = useOpenDialogWindow();

  const columns = _.cloneDeep(audiencesColumns());
  const cols = {
    requester: columns.requester,
    name: columns.name,
    approverAsked: columns.approverAsked,
    price: columns.price,
    actionsBtns: columns.actionsBtns,
  };

  cols.actionsBtns.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
    return (
      <div style={{ display: "flex", gap: "15px" }}>
        <Button data-cy={"review_btn"} variant={"outlined"} onClick={() => handleReview(cell.row.id, cell.row.approverAsked.id === AUTHUser.userId)}>
          <Typography noWrap={true}>Review</Typography>
        </Button>
        <Button
          data-cy={"approve_btn"}
          variant={"contained"}
          onClick={() => handleApprove(cell.row.id, cell.row.approverAsked.id === AUTHUser.userId)}
        >
          <Typography noWrap={true}>Approve</Typography>
        </Button>
      </div>
    );
  };

  const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

  React.useEffect(() => {}, []);

  const handleApprove = (audienceId: number, isForMe: boolean = false) => {
    dialogWindow.open({
      dialogId: "confirmRequestApproval",
      buttonActions: { acceptButton: "Approve", cancelButton: true },
      title: isForMe ? "Confirm your answer" : "You are not the asked approver",
      description: isForMe
        ? "You are going to Approve this audience"
        : "You are going to Approve this audience, but you are not the asked approver. Are your sure you want to approve this request?",
      icon: "HandTool",
      onAccept: (args: any, orgs: any) => {
        audienceApi
          .approveAudience(Number(audienceId), true, "")
          .then((res) => {
            if (res.status === 200) {
              appToast.open({
                toastId: "globalToast",
                severity: "success",
                message: t("audience.acceptRequestApproval"),
              });
              props.updatePendingApproval();
            }
          })
          .catch((e) => {
            appToast.open({
              toastId: "globalToast",
              severity: "error",
              message: t("audience.confirmApprovalError"),
            });
            console.log(e);
          });
      },
    });
  };

  const handleReview = (audienceId: number, isForMe: boolean = false) => {
    if (isForMe) {
      navigate(pageUtils.getPagePathById(PageId.audienceApproval, { audienceId: audienceId }));
    } else {
      dialogWindow.open({
        dialogId: "confirmRequestReview",
        buttonActions: { acceptButton: "Review", cancelButton: true },
        title: "You are not the asked approver",
        description: "You are going to review this audience, but you are not the asked approver. Are your sure you want to review this request?",
        icon: "HandTool",
        onAccept: (args: any, orgs: any) => {
          navigate(pageUtils.getPagePathById(PageId.audienceApproval, { audienceId: audienceId }));
        },
      });
    }
  };

  return (
    <SCard style={{ flex: 1, position: "relative", minHeight: "400px" }} data-cy={"audiences-to-be-approved"}>
      <GridMiddleCenter sx={{ gap: 1, marginBottom: "20px" }}>
        <div style={{ position: "relative" }}>
          <AppIcon color={"#3B4559"} display={"inline"} icon={"Approval"} chip={true} />
        </div>
        <SCardTitle style={{ flex: 1 }}>{pendingApproval.length > 1 ? "Audiences" : "Audience"} to be Approved</SCardTitle>
      </GridMiddleCenter>
      <StackerFullHeight style={{ position: "relative", flex: 1 }}>
        <GridItemFull style={{ overflow: "hidden", position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              padding: "1px",
              zIndex: 10,
            }}
          >
            <ApiProgress entityName={"audiences"} />
          </Box>
          <GridTable
            noRowsText={t("audience.buildFirstAudience")}
            styleClass={"naked"}
            customStyle={{ maxHeight: "300px" }}
            loading={false}
            rowHeight={50}
            dataCy={"audiences-to-be-approved"}
            data={{
              columns: audienceColumns as any,
              rows: pendingApproval,
            }}
            gridProps={{
              disableSelectionOnClick: true,
              autoHeight: false,
              hideFooter: true,
              columnBuffer: 30,
              columnThreshold: 10,
            }}
          />
        </GridItemFull>
      </StackerFullHeight>
    </SCard>
  );
};

const SCard = styled("div")((props: { theme: Theme }) => ({
  padding: "16px",
  //minWidth       : '297px',
  boxShadow: props.theme.skin.boxShadow,
  backgroundColor: props.theme.palette.background.default,
  borderRadius: "12px",
  border: "1px solid " + props.theme.skin.borderColor,
}));

const SCardTitle = styled("div")((props: { theme: Theme }) => ({
  fontWeight: "bolder",
  color: "#7587A3",
  fontSize: "18px",
}));
