import React from 'react';
import {Stacker} from '../../ui/AppElements';
import {Box, Button, Typography} from '@mui/material';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {AppIcon} from '../../ui/AppIcon';
import {useNavigate} from 'react-router-dom';
import {BackendError} from '../../../types/backendError';
import {AUTHUser} from '../../../application/utils/AuthUser';


interface Page404Props {
    title?: string;
    text?: string;
    backendError?: BackendError | null // because of useQuery;
}

export const Page404 = (props: Page404Props) => {
    const {title, text, backendError} = props;
    const history = useNavigate();

    let titleFinal = title ?? 'Oh snap!';
    //  CMVP-1296  // let txt        = text ?? backendError?.message ?? 'Seems like something went wrong.\r\nDon\'t worry, we\'re working on it. Come back shortly.\r\nwe\'ll have things up and running in no time.';
    let txt = 'Looks like something went wrong!\r\n Choose one of the buttons below and try again.';

    switch (backendError?.error?.reason) {
        case 'NOT_FOUND' :
            titleFinal = 'Not found';
            // CMVP-1296 // txt        = 'The requested resource could not be found';
            break;
        default:
    }

    function goHome() {
        history('/');
    }

    function goBack() {
        history(-1);
    }

    return <Container data-cy="not-found-error">
        <Box sx={{p: 1}}>
            <AppIcon chip={true} icon={'Robot'} color={'#3b4559'} fontSize={'medium'}/>
        </Box>
        <Box sx={{color: '#8c9bb3', textAlign: 'center'}}>
            <Typography variant={'h3'}>{titleFinal}</Typography>
        </Box>
        <Box sx={{color: '#8c9bb3', textAlign: 'center'}}>
            <p>{txt}</p>
        </Box>
        <Box></Box>
        <Box sx={{display: 'flex', gap: '3rem'}}>
            <Button variant={'outlined'} onClick={goBack}>Back</Button> <Button onClick={goHome}
                                                                                variant={'contained'}>Home</Button>
        </Box>
        <Box></Box>
        {(AUTHUser.isEvorraAdmin() && backendError?.error) && <Box sx={{maxWidth: '100%'}}>
            <Typography variant={'h5'} sx={{fontWeight: 'bold'}}>Administrator message</Typography>
            {(typeof backendError.error === 'string') ? backendError.error : Object.keys(backendError.error).map((err) =>
                <div className={'flex-h flex-align-middle'} style={{gap: '1rem'}}>
                    <Typography sx={{width: '80px', minWidth: '80px', fontWeight: 'bold'}}>{err}</Typography>
                    <Typography noWrap={true}>{backendError?.error?.[err as keyof BackendError['error']]}</Typography>
                </div>)}
        </Box>}
    </Container>;
};

const Container = styled(Stacker)((props: { theme: Theme }) => ({
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: 'red',//props.theme.palette.background.paper,
    padding: '5rem',
    alignItems: 'center',
    gap: '1rem',
    backgroundImage: `url(/errorBackground.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom left',
    position: 'relative'
}));