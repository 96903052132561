import {AbstractBaseApi} from './AbstractBaseApi';
import {ICountry} from '../../../types';
import {IApiOptions} from '../api/apiService';
import {AudienceTypesDto} from '../dataTypes/audienceTypes';


export class AudienceTypes extends AbstractBaseApi<AudienceTypesDto> {
  constructor(options?: IApiOptions) {
    super('audienceTypes', options);
  }
}