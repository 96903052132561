import {Box, Button} from "@mui/material";
import {PageId} from "application/pages/pages.config";
import {useNavigate} from "react-router-dom";
import {useFeatureFlag} from "stores/featureFlags.store";
import {pageUtils} from "../../../application/pages/pages.utils";
import {Columns} from "../../../components/ui/AppElements";
import {useAudienceBuilderStore} from "../Store/AudienceBuilderStore";

export const AudienceBuilderStatus = () => {
    const audienceStore = useAudienceBuilderStore((stre) => stre.sizeAndPrice);
    const audienceDataStore = useAudienceBuilderStore((stre) => stre.audience);
    const loadingSizeAndPrice = useAudienceBuilderStore((stre) => stre.loadingSizeAndPrice);
    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    const history = useNavigate();

    const isFDPAudience = audienceDataStore.audienceType.id === 5 ?? false;

    const isEnough: boolean =
        audienceStore?.dspCampaignInfo
            ?.map((a) => a?.dspItems)
            ?.map((item) => item?.map((element) => element?.maxSize > element?.minSize)?.reduce((prev, next) => prev || next, false))
            ?.reduce((prev, next) => prev || next, false) ?? false;

    function activateAudience() {

        history(pageUtils.getPagePathById(PageId.audienceEnablement, {audienceId: audienceStore.id}));

    }

    const enoughProfile = isEnough;

    return (
        <Box>
            {audienceDataStore.audienceStatus !== "ACTIVATED" && (
                <Box sx={{px: 1}} style={{alignSelf: "center"}}>
                    <Columns>
                        <Button data-cy={"enable-audience-btn"} disabled={!enoughProfile || loadingSizeAndPrice}
                                onClick={activateAudience} variant={"contained"}>
                            Enable
                        </Button>
                    </Columns>
                </Box>
            )}
        </Box>
    );
};
