import React from 'react';
import {useTranslation} from 'react-i18next';
import {MainFrame} from '../../../components/templates/MainFrame';
import {FrameContainer, ShadowBox} from '../../../components/templates/InfoBlock';
import {PageId} from '../../../application/pages/pages.config';
import { actions } from 'application/actions/actions';
import apiRequest from 'application/entities/api/interceptors';
import { devApi } from '_configuration/api.config';
import { useAppToast } from 'components/live/AppToast';
import { ActionButtons } from 'components/controls/ActionButtons';
import { Theme , styled} from '@mui/material';
import { useAdminPage } from 'components/hooks/useAdminPage';


interface IReportsProps {
}

export const Reports: React.FC<IReportsProps> = () => {
    const globalCockpit = `${devApi}/globalCockpit`;
    const [url, setUrl] = React.useState();
    const {t}         = useTranslation();
    const appToast    = useAppToast();
    useAdminPage()


    React.useEffect(() => {
        apiRequest.get(globalCockpit).then(r => setUrl(r.data));
    }, []);
  
    const generateReport = (args?: { reportName: string }) => {
        if (args && args.reportName) {
            apiRequest.post(`${devApi}/reporting/buildReport`, {'reportName': args.reportName})
                .then((res) => {
            // Download response as file. Did not find a prettier way
            const reportUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = reportUrl;
            link.setAttribute('data-cy',  'buildReport');
            link.setAttribute('download', args.reportName + '.csv');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(reportUrl)
            document.body.removeChild(link)
        }).catch(err => {
            const message = err?.response?.data?.error?.message;
            appToast.open({
            toastId : 'manageAccount',
            severity: 'error',
            message : message ?? t('labels.error'),
            });
        });
    }
  };

  actions.reports.accountReport.action = () => {generateReport({reportName:'AccountUserReport'})};
  actions.reports.audienceReport.action = () => {generateReport({reportName:'AudienceFeaturesReport'})};
  actions.reports.paymentReport.action = () => {generateReport({reportName:'AudiencePaimentReport'})};
//   actions.reports.idsConsentReport.action = () => {generateReport({reportName:'idsConsentReport'})}; //waiting for Simeon's report

  return (
    <MainFrame frameId={PageId.reports}>
      <FrameContainer>
        <ContentContainer>
          <ShadowBox style={{backgroundColor:"#fff", padding:'1.5rem'}}>
            <TitleBox>Interfaces</TitleBox>
            <ActionButtons actions={actions.reportsLinks} vars={{accountId: 1}}/>

          </ShadowBox>
          <ShadowBox style={{backgroundColor:"#fff", padding:'1.5rem'}}>
            <TitleBox>CSV Downloads</TitleBox>
            <ActionButtons actions={actions.reports}/>
            </ShadowBox>
        </ContentContainer>
      </FrameContainer>
    </MainFrame>
  );
};
const TitleBox= styled('h2')((props: { theme: Theme }) => ({
  color: "#333",
  fontSize: "1.5rem",
  marginBottom: "1.5rem"
}))

const ContentContainer = styled('div')((props: { theme: Theme }) => ({
  display: "flex",
  gap:"2.5rem",
  overflow: "auto",
  padding: "30px",
  flexDirection: "row",
}))