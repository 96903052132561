import React from 'react';
import {ColumnsMiddle} from '../../ui/AppElements';
import {AppIcon} from '../../ui/AppIcon';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {Box} from '@mui/material';

interface IInlineInputProps {
  defaultValue: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  dataCy?: string;
}

export const InlineInput = (props: IInlineInputProps) => {
  
  const {defaultValue, placeholder, onChange,onKeyUp,onFocus,onBlur,hasError, disabled, dataCy} = props;
  
  const [value, setValue] = React.useState<string>(defaultValue);
  
  const inputId = React.useRef<any>(null);
  
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  
  const doFocus = (focus: boolean) => {
    if (focus && inputId.current) inputId.current.focus();
  };
  
  return <ColumnsMiddle style={{marginLeft:'1.35rem',width: 'auto'}}>
    <InputCss
      data-cy={dataCy ?? 'Input'}
      ref={inputId}
      type={'text'}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      onChange={function (event) {
        setValue(event.target.value);
        onChange(event.target.value);
      }}
      onBlur={(event) => {
        if(onBlur) onBlur(event)
        if (event.target.value && event.target.value.length) onChange(event.target.value);
        if (!event.target.value?.length) {
          setValue(defaultValue);
          onChange(defaultValue);
        }
      }}
      style={{color: hasError ? '#FC6050':'inherit'}}
    />
    {!disabled && <Box sx={{px: 1}} padding={[0, 0.5]}>
      <AppIcon onClick={() => {doFocus(true);}} fontSize={'tiny'} icon={'Edit'}/>
    </Box>}
  </ColumnsMiddle>;
};


const InputCss = styled('input')((props: { theme: Theme }) => ({
  color    : props.theme.palette.text.primary,
  fontSize : '18px',
  border   : '1px solid transparent',
  padding  : '0 0.5rem',
  minWidth:'350px',
  '&:hover': {
    backgroundColor: 'rgb(240, 242, 247)'
  },
  '&:focus': {
    border         : '1px solid #00B5E2',
    backgroundColor: 'rgb(240, 242, 247)'
  }
}));
