import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, Button, Collapse, Paper, Typography } from "@mui/material";
import { stripeService } from "application/services/stripe.service";
import { AUTHUser } from "application/utils/AuthUser";
import { useAppToast } from "components/live/AppToast";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { useTranslation } from "react-i18next";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { useShallow } from "zustand/react/shallow";
import { StepDescription, StepTitle } from "..";
import { useAudienceEnablementStore } from "../store/AudienceEnablement.store";
import { CardMethodsType } from "../types";
import { BillingAddress } from "./PaymentStep/BillingAddress";
import { CreditCard } from "./PaymentStep/CreditCard";
import { StripeForm } from "./PaymentStep/StripeForm";

export const PaymentStep = () => {
  const { t } = useTranslation();
  const dialogWindow = useOpenDialogWindow();
  const appToast = useAppToast();

  const actions = useAudienceEnablementStore((state) => state.actions);
  const selectedCardMethod = useAudienceEnablementStore(useShallow((st) => st.selectedCardMethod));
  const paymentMethods = useAudienceEnablementStore((st) => st.paymentMethods);
  const paymentType = useAudienceEnablementStore((st) => st.paymentType);
  const billingAddress = useAudienceEnablementStore((st) => st.billingAddress);
  const audienceData = useAudienceEnablementStore((st) => st.audienceData);
  const dspSelected = useAudienceEnablementStore((st) => st.dspSelected);

  const sentVars = {
    //for stripe form
    paymentType: "audience",
    paymentData: { id: audienceData.id, ...dspSelected },
  };
  const isBillingAddressValid = () => {
    if (!billingAddress.line1 || !billingAddress.country || !billingAddress.city || !billingAddress.postal_code) {
      return false;
    }
    return true;
  };

  const isValidStep = () => {
    if (paymentType === undefined) {
      return false;
    }
    if (paymentType === "card" && !selectedCardMethod.id) {
      return false;
    }
    if (paymentType === "card" && !isBillingAddressValid()) {
      return false;
    }
    return true;
  };

  const deleteCard = (paymentMethodId: string) => {
    const dialogDeleteCard: IOpenDialogAction = {
      dialogId: "deleteCard",
      buttonActions: { acceptButton: "Delete Card", cancelButton: true },
      title: "Delete Credit Card",
      description: "Your credit card will be deleted. You won't be able to use it anymore.",
      icon: "Close",
      onAccept: () => {
        stripeService
          .deleteCustomerPaymentMethod(AUTHUser.accountId.toString(), paymentMethodId)
          .then(() => {
            if (selectedCardMethod.id === paymentMethodId) {
              useAudienceEnablementStore.setState((st) => {
                return { ...st, selectedCardMethod: {} as CardMethodsType };
              });
            }
            actions.fetchPaymentMethods("card");
            appToast.open({
              toastId: "globalToast",
              severity: "success",
              autoHideDelay: 1500,
              message: "Card deleted successfully",
            });
          })
          .catch(() => {
            appToast.open({
              toastId: "globalToast",
              severity: "error",
              message: "Error deleting card, please try later",
            });
          });
      },
    };

    dialogWindow.open(dialogDeleteCard);
  };

  const addCard = () => {
    const dialogAddCard: IOpenDialogAction = {
      dialogId: "addCard",
      title: "New Credit Card",
      icon: "CardHolder",
      component: StripeForm,
      componentProps: {
        paymentMethod: "card",
        paymentReason: "register_card",
        onSuccess: (e: any) => {
          dialogWindow.close("addCard");
          actions.fetchPaymentMethods("card");
        },
        vars: sentVars,
      },
    };

    dialogWindow.open(dialogAddCard);
  };

  return (
    <>
      <StepTitle>
        <span>Select Billing</span>
        <Button variant="outlined" onClick={() => actions.goToPreviousStep()}>
          Back
        </Button>
      </StepTitle>
      <StepDescription>Choose a payment method and check your billing informations.</StepDescription>

      <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "1fr  380px" }}>
        <Box>
          <Box mb={4}>
            <Typography variant="h6" mb={2}>
              Invoice
            </Typography>

            <Paper
              onClick={() => {
                actions.selectInvoice();
              }}
              elevation={paymentType === "invoice" ? 0 : 6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "110px",
                width: "190px",
                borderRadius: 1,
                cursor: "pointer",
                textAlign: "center",
                background: "#fafafa",
                position: "relative",
                border: paymentType === "invoice" ? "2px solid #00B5E2" : "0px solid #ececec",
              }}
            >
              <Box>
                {paymentType === "invoice" && (
                  <Box sx={{ position: "absolute", bottom: "0px", right: "8px" }}>
                    <CheckCircleIcon sx={{ color: "primary.main" }} fontSize="medium" />
                  </Box>
                )}
                <DescriptionOutlinedIcon fontSize="large" style={{ color: "#555" }} />
                <Typography variant="body1" fontWeight={600}>
                  Pay by Invoice
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Typography variant="h6">Credit Card</Typography>
          <Typography mb={2} variant="body1" className="color-tech-grey">
            {paymentMethods.card.length > 0 && "Click on a card to select it as your payment method"}
            {paymentMethods.card.length === 0 && "Add a new card to use it as your payment method"}
          </Typography>
          <Box mb={4}>
            <TransitionGroup style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}>
              {paymentMethods.card.map((method) => {
                return (
                  <Collapse orientation="horizontal" key={method.id} unmountOnExit>
                    <CreditCard
                      brand={method.card.brand}
                      last4={method.card.last4}
                      expMonth={method.card.expMonth}
                      expYear={method.card.expYear}
                      selected={selectedCardMethod.id === method.id}
                      onClick={() => {
                        actions.selectCard(method);
                      }}
                    />
                    <Typography
                      mt={1}
                      variant="body1"
                      textTransform="uppercase"
                      fontSize={10}
                      color="#999"
                      fontWeight={600}
                      textAlign="center"
                      sx={{ cursor: "pointer", "&:hover": { color: "#fc6050" } }}
                      onClick={() => {
                        deleteCard(method.id);
                      }}
                    >
                      Delete card
                    </Typography>
                  </Collapse>
                );
              })}
              <Paper
                elevation={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "110px",
                  width: "190px",
                  cursor: "pointer",
                  background: "linear-gradient(#888, #333)",
                  textAlign: "center",
                }}
              >
                <Box onClick={addCard}>
                  <AddCircleOutlineIcon fontSize="large" style={{ color: "#ececec" }} />
                  <Typography variant="body1" color="#ececec" fontWeight={600}>
                    Add a new card
                  </Typography>
                </Box>
              </Paper>
            </TransitionGroup>
          </Box>
        </Box>
        <Box>
          {paymentType === "card" && (
            <>
              <Typography variant="h6">Billing Address</Typography>
              <Typography variant="body1" className="color-tech-grey" mb={2}>
                Billing address is mandatory for all payment methods.
              </Typography>
              <Box sx={{ background: "#fafafa", borderRadius: "8px", border: "1px solid #ececec" }} p={2}>
                <BillingAddress />
              </Box>
            </>
          )}
        </Box>
      </Box>

      {/* <Box mt={6}>
        <StripeForm
          paymentMethod="card"
          paymentReason={"register_card"}
          onSuccess={(e: any) => {
            console.log("success", e);
            actions.fetchPaymentMethods("card");
          }}
          vars={sentVars}
        />
      </Box> */}

      <Box textAlign={"center"} mt={2}>
        <Button disabled={!isValidStep()} onClick={actions.goToNextStep} variant={"contained"} value={"Continue"} data-cy={"continue-btn"}>
          {t("dsp.continue")}
        </Button>
      </Box>
    </>
  );
};
