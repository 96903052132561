import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {Box, Paper, Skeleton, Typography} from "@mui/material";
import {SegmentsDspInfos} from "./SegmentsDspInfos";
import React from "react";
import {AnimatePresence, motion} from "motion/react"

export const SegmentsSideBar = () => {

    const displaySidePanel = useSegmentBuilderStore((state) => state.displaySidePanel)
    const sidebarLoading = useSegmentBuilderStore((state) => state.sidebarLoading)
    return <AnimatePresence>
        {displaySidePanel && (
            <motion.div
                initial={{width: 0, height: '100%', opacity: 0}}
                animate={{width: '400px', opacity: 1}}
                exit={{width: 0, opacity: 0}}
                transition={{duration: 0.3}}
            >
                <Paper sx={{
                    height: "100%",
                    overflowY: "auto",
                    background: "#fff",
                    position: "relative",
                    borderRadius: "1rem",
                    transition: "width 0.8s ease-in-out",
                }}>
                    {sidebarLoading ? (
                        <Skeleton variant="rectangular" width={"100%"} height={"100%"}/>
                    ) : <>
                        <Box sx={{position: "sticky", top: 0, zIndex: 1}}>

                            <Typography variant={"h5"}
                                        sx={{
                                            textAlign: "left",
                                            background: "#8691a2",
                                            color: "#fff",
                                            pl: "1rem",
                                            lineHeight: "34px",
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            height: "34px"
                                        }}>
                                Ids Volume details
                            </Typography>

                        </Box>
                        <Box mt={2}>
                            <SegmentsDspInfos/>
                        </Box>
                    </>
                    }
                </Paper>
            </motion.div>

        )}
    </AnimatePresence>
}