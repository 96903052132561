import { InputBase, InputLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';


/**
 * Restyled Material UI InputAnna
 * Multiline InputAnna
 * InputAnna Label
 */

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .Mui-error': {
        border: '1px solid red',
      },
      'label + &': {
        marginTop: 10,
        color: '#9DAABF',
        height: 30,
        border: '1px solid transparent',
        display: 'flex',
        alignItems: 'center',

      },
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: '1px solid transparent',
      boxShadow: `0 0 6px rgba(196, 211, 241, 0.85)`,
      fontSize: 12,
      height: '100%',
      width: '100%',
      padding: '0px 15px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.2px',
      color: '#3B4559',

      '&:focus': {
        borderRadius: 20,
        border: '0.5px solid #00B5E2',
        backgroundColor: '#FFFFFF',
      },
    },
  }),
)(InputBase);

export const BootstrapMultilineInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiInputBase-inputMultiline': {
        height: 104,
        width: '100%',
        padding: '7px 15px',
        backgroundColor: 'red',
      },
    },
  }),
)(InputBase);


export const NumberInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 10,
        color: '#9DAABF',
        height: 30,
        border: '1px solid transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      border: '1px solid transparent',
      fontSize: 12,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.2px',
      color: '#3B4559',
      '&:focus': {
        borderRadius: 20,
        border: '0.5px solid transparent',
        backgroundColor: '#FFFFFF',
      },
    },
  }),
)(InputBase);

/** @deprecated by TextFieldLabel */
export const BootstrapLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: '0 15px',
      color: '#9DAABF',
      fontColor: '#9DAABF',
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'bold',
      height: '20px',
    },
  }),
)(InputLabel);

