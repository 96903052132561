import {GridMiddle} from '../../ui/AppElements';
import {AppIcon} from '../../ui/AppIcon';
import * as React from 'react';
import {Box, Fade, Menu, MenuItem, Typography} from '@mui/material';
import {ISortParameters} from '../../../application/entities/api/apiService';
import {TIcon} from '../../../assets/icons';
import {styled} from '@mui/styles';


interface ISorterProps {
  sortFields: string[]
  sortParams: ISortParameters
  setSortParams: { setSortDir: (dir: 'asc' | 'desc') => void, setSortBy: (by: string) => void },
}


type TSortKeys = 'name' | 'creationTime' | 'modificationTime'

type TSortIconName = Record<TSortKeys, { asc: TIcon, desc: TIcon }>

// to improve when there will have multiple field to sort
export const Sorter = (props: ISorterProps) => {
  
  const {sortParams, setSortParams, sortFields} = props;
  
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen]         = React.useState<boolean>(false);
  
  const sorters: Record<TSortKeys, any> = {
    modificationTime: 'Modification Date',
    creationTime    : 'Creation Date',
    name            : 'Audience Name',
  };
 
  const sortIcons: TSortIconName = {
    name            : {
      asc : 'SortAZ',
      desc: 'SortZA',
    },
    creationTime    : {
      asc : 'SortAsc',
      desc: 'SortDesc',
    },
    modificationTime: {
      asc : 'SortAsc',
      desc: 'SortDesc',
    },
  };
  
  
  let title = `${sortParams.sortBy} ${sortParams.sortDir}`;
  
  
  if (sortParams.sortBy === 'modificationTime') {
    title = sortParams.sortDir === 'asc' ? 'Modification Date (Old to New)' : 'Modification Date (New to Old)';
  }
  if (sortParams.sortBy === 'creationTime') {
    title = sortParams.sortDir === 'asc' ? 'Creation Date (Old to New)' : 'Creation Date (New to Old)';
  }
  
  if (sortParams.sortBy === 'name') {
    title = sortParams.sortDir === 'asc' ? 'Audience Name (A-Z)' : 'Audience Name (Z-A)';
  }
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  
  const setSort = (dir: 'asc' | 'desc', field: string) => {
    setSortParams.setSortDir(dir);
    setSortParams.setSortBy(field);
  };
  // Sort Name A to Z
  // Sort Creation Date Oldest / Sort Creation Name Newest
  return <><Box data-cy={'Sorter'} sx={{px: 1, cursor: 'pointer', paddingRight:'0'}}>
    <GridMiddle onClick={handleClick}>
      <Box sx={{px: 0.5, cursor: 'pointer'}}><Typography>Sort {title}</Typography></Box>
      <Box sx={{px: 1}}>
        <AppIcon color={'white'}
                 display={'block'}
                 fontSize={'small'}

                 icon={'SortIcon'}
                 />
      </Box>
    </GridMiddle>
  </Box>
    <Menu
      id="fade-menu-sort"
      anchorOrigin={{
        vertical  : 'bottom',
        horizontal: 'left',
      }}
      // getContentAnchorEl={null}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
      TransitionComponent={Fade}>
      {sortFields.map((field: string) => {
        const otherSort = sortParams.sortDir === 'asc' ? 'desc' : 'asc';
        
        const iconShowOrder = field === sortParams.sortBy ? otherSort : sortParams.sortDir;
        
        const finIcon = sortIcons?.[field as TSortKeys]?.[iconShowOrder];
        
        return <MenuItem key={field}>
          <GridMiddle sx={{gap: 0.5}}
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setSort(iconShowOrder, field);
                      }}
          >
            <div style={{width: '24px', textAlign: 'center'}} data-cy={'desc'}>
              <AppIcon fontSize={'middle'}
                       color={(sortParams.sortBy === field && sortParams.sortDir === 'desc') ? '#00B5E2' : ''}
                       icon={finIcon}/>
            </div>
            <MenuText sx={{flex: 1}} data-cy={field}>
              {sorters[field as TSortKeys] ?? field}
            </MenuText>
          </GridMiddle>
        </MenuItem>;
      })}
    </Menu>
  </>;
};

const MenuText = styled(Typography)(({theme}) => ({
  flex : 1,
  color: '#7587A3'
}));