import { trans2Tree } from "pages/AudienceBuilder/audienceBuilderUtils";
import { IFiltersValues } from "pages/AudienceBuilder/types/audienceBuilderTypes"
import { TreeViewVisualisation } from "./TreeVisualisation";
import {styled} from '@mui/styles';
import _ from "lodash";
import { Theme } from "@mui/material/styles";

type ViewAudienceLinkedIdProps = {
    filterValue: IFiltersValues
    viewMode?: boolean
}

export const ViewAudienceLinkedId = (props: ViewAudienceLinkedIdProps)=> {
    const {filterValue} = props;
    const hierarchyField = filterValue.selected[0].path ? 'path' : 'name';
    const newTreeData = trans2Tree(filterValue.selected,  hierarchyField);
    

    return <TreeContainer className={'nowheel nodrag'  } data-cy={'featureZone'}>
        <TreeViewVisualisation tree={newTreeData} expandVisible={true} filterValue={filterValue} viewMode={props.viewMode}/>
    </TreeContainer>
    
}

const TreeContainer = styled('div')(() => ({
    display : 'flex' ,
    flexWrap: 'wrap',
    position       : 'relative',
    zIndex         : 0,
    gap        : '0.25rem',
    margin         : '4px',
    maxWidth       : '100%',
    maxHeight      : '400px',
    overflowY      : 'auto',
    paddingBottom:'7px'
  
  }));

