import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import * as React from "react";
import { INote } from "../../../application/entities/dataTypes/note";
import { Note } from "./Note";
import styled from "styled-components/macro";

interface IAccountNotesProps {
  account: Partial<IAccounts>;
}

/** taken from companyUser/AccountNotes */
export const ViewAccountNoteList: React.FC<IAccountNotesProps> = (props: IAccountNotesProps) => {
  const { account } = props;
  const [notesList, setNotesList] = React.useState<Partial<INote>[]>([]);

  React.useEffect(() => {
    if (account.notes) setNotesList(account.notes);
  }, [account]);

  return (
    <>
      <NotesList>
        {notesList?.map((item, index) => (
          <Note key={item.id} item={item} index={index} />
        ))}
      </NotesList>
    </>
  );
};

const NotesList = styled.div`
  max-height: 200px;
  max-width: 505px;
  padding: 5px;
  overflow: auto;

  > div:not(:last-child) {
    border-bottom: 1px solid #dbe0eb;
  }

  &::-webkit-scrollbar {
    width: 4px;
    padding: 1px;

    :hover {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #7587a3;
    border-radius: 120px;
  }
`;
