import {Box, TextField, Typography} from '@mui/material';
import {buttonLabels, ColorButton, GhostButton} from 'components/form/Buttons';
import {useFormik} from 'formik';
import React from 'react';
import styled from 'styled-components/macro';

import {useTranslation} from 'react-i18next';
import {objectApi} from '../../../application/entities/dataApi';
import {passwordValidationSchema} from '../../../application/validation';
import {useAppToast} from '../../../components/live/AppToast';
import {useOpenDialogWindow} from '../../../components/live/DialogWindow';
import {InputFieldLabel} from '../../../components/templates/InfoBlock';
import { useAdminPage } from 'components/hooks/useAdminPage';


interface IDataVaultChangePasswordProps {
  accountId: number;
}

export const DataVaultChangePassword: React.FC<IDataVaultChangePasswordProps> = ({accountId,}) => {

  const {t} = useTranslation();
  useAdminPage()
  const dialogWindow              = useOpenDialogWindow();
  const appToast                  = useAppToast();
  const accountsApi               = new objectApi.accounts();
  
  const dataVaultPasswordFormik = useFormik({
    initialValues   : {
      newPassword    : '',
      confirmPassword: '',
    },
    validationSchema: passwordValidationSchema,
    validateOnChange: true,
    validateOnBlur  : true,
    onSubmit        : values => {
      const castValues = passwordValidationSchema.cast(values);
      accountsApi.setVaultPassword(+accountId, castValues.newPassword)
                 .then(res => {
                     dialogWindow.close('ResetDataVaultPassword')
                                 .then();
                     const message = res?.data?.body;
                     appToast.open({
                       toastId : 'manageAccount',
                       severity: 'success',
                       message : message ?? t('auth.signIn.passWordSet'),
                     });
                   },
                 )
                 .catch((err) => {
                   const message = err?.response?.data?.error?.message;
                   appToast.open({
                     toastId : 'manageAccount',
                     severity: 'error',
                     message : message ?? t('labels.error'),
                   });
                 });
      
    },
    onReset         : (values) => {
      dialogWindow.close('ResetDataVaultPassword')
                  .then();
    },
  });
  
  const hasErrors = Boolean(Object.keys(dataVaultPasswordFormik.errors).length);
  const touched = Object.keys(dataVaultPasswordFormik.touched).length > 0;
  
 
  
  return (
    <FormContainer   onSubmit={dataVaultPasswordFormik.handleSubmit}>
      <>
        <InputFieldLabel required={true} htmlFor="newPassword">
          {t('vaultSettingsPageLabels.newPassword')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            type="password"
            value={dataVaultPasswordFormik.values.newPassword}
            onBlur={dataVaultPasswordFormik.handleBlur}
            onChange={dataVaultPasswordFormik.handleChange}
            error={dataVaultPasswordFormik.touched.newPassword &&
                   Boolean(dataVaultPasswordFormik.errors.newPassword)}
            /*helperText={dataVaultPasswordFormik.touched.newPassword &&
             dataVaultPasswordFormik.errors.newPassword}*/
            InputProps={{autoComplete: 'new-password'}}
          />
        </TextFieldBlock>
        <Box sx={{p: 1}}>
          <Typography variant={'caption'} sx={{color: Boolean(dataVaultPasswordFormik?.errors?.newPassword) ? 'red' : 'inherit'}}>* &nbsp;&nbsp;&nbsp;</Typography>
          Your password must Contain 8 characters, One Uppercase and One Number
        </Box>
        <br/>
        <InputFieldLabel required={true} htmlFor="confirmPassword">
          {t('vaultSettingsPageLabels.confirmPassword')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={dataVaultPasswordFormik.values.confirmPassword}
            onBlur={dataVaultPasswordFormik.handleBlur}
            onChange={dataVaultPasswordFormik.handleChange}
            error={dataVaultPasswordFormik.touched.confirmPassword &&
                   Boolean(dataVaultPasswordFormik.errors.confirmPassword)}
            helperText={dataVaultPasswordFormik.touched.confirmPassword &&
                        dataVaultPasswordFormik.errors.confirmPassword}
            InputProps={{autoComplete: 'new-password'}}
          />
        </TextFieldBlock>
        <br/>
        <br/>
        <BtnBox>
          <BtnBlock>
            <GhostButton
              variant="outlined"
              color="primary"
              type="reset"
              onClick={dataVaultPasswordFormik.handleReset}
            >
              {buttonLabels.cancel}
            </GhostButton>
          </BtnBlock>
          <BtnBlock>
            <ColorButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={Boolean(hasErrors || !touched)}
            >
              {buttonLabels.changePassword}
            </ColorButton>
          </BtnBlock>
        </BtnBox>
      </>
    </FormContainer>
  );
};


const TextFieldBlock = styled.div`
  //height: 55px;
`;

const FormContainer = styled.form`
  width: 330px;
  padding: 30px 30px 0 30px;
`;

const BtnBox = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 17px;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 17px;
`;
