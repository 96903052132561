import { Button, Divider, Skeleton, Typography } from "@mui/material";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { GlobalAvatar } from "components/ui/GlobalAvatar";
import moment from "moment";
import { useNavigate } from "react-router";

export const LastUsersList = (props: { users: never[]; loading: boolean }) => {
  const { users, loading } = props;

  const UserItem = (props: { user: any; noDivider?: boolean }) => {
    const { user, noDivider } = props;
    const navigate = useNavigate();
    const handleView = () => {
      navigate(pageUtils.getPagePathById(PageId.userProfileView, { userId: user.id }));
    };

    return (
      <div key={user.id}>
        <div
          style={{ display: "grid", gap: "5px", alignItems: "center", gridTemplateColumns: "31px 180px 35px", marginBottom: "0.5em", width: "100%" }}
        >
          <GlobalAvatar user={user} isPending={user.status !== "ACTIVATED"} size={"28px"} fontSize={"15px"} />
          <Typography
            variant="body1"
            title={user.firstName + " " + user.lastName}
            style={{ fontWeight: "600", fontSize: "14px", lineHeight: "unset", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
          >
            {user.firstName + " " + user.lastName} <br />
            <span style={{ fontSize: "12px" }} className="color-tech-grey">
              Created on {moment(user.creationTime).format("D-MMM-YYYY")}
            </span>
            <br />
            <span style={{ fontSize: "12px" }} className="color-tech-grey">
              {user.email}
            </span>
          </Typography>
          <div style={{ textAlign: "right" }}>
            <Button variant="contained" size="small" color="primary" onClick={handleView}>
              View
            </Button>
          </div>
        </div>
        {!noDivider && <Divider style={{ marginBlock: "8px" }} />}
      </div>
    );
  };

  return (
    <div style={{ maxHeight: "300px", overflowY: "auto", width: "280px", maxWidth: "100%" }}>
      {loading === true ? (
        <Skeleton variant="rectangular" width={"100%"} height={"300px"} style={{ borderRadius: "7px" }} />
      ) : (
        users.map((user: any, idx: number) => {
          return UserItem({ user: user, noDivider: idx === users.length - 1 });
        })
      )}
    </div>
  );
};
