import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {apiService} from 'application/entities/api/apiService';
import {authService} from 'application/services/auth.service';
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Auth, Cache} from 'aws-amplify';
import {RootState} from 'redux/store/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Authorities, IAccounts, ICompanyUsers} from 'types';
import {AUTHUser} from '../../application/utils/AuthUser';
import {AuthException} from '../../application/utils/exceptions.utils';
import { objectApi } from 'application/entities/dataApi';


export interface IAuthStateUser {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  notes: string;
  role: string;
  permission: string;
  account: Partial<IAccounts>;
  authorities: Authorities[];
  fullName: string;
  jhiPrimary: boolean;
  jobTitle: string;
  company: Partial<IAccounts>;
  accountId: number;
  phone: number;
  primary: boolean;
  marketingOptIn: boolean;
  analyticsOptIn: boolean;
  termsAndConditionsAccepted: boolean;
}

export interface IAuthState {
  loggedIn: boolean;
  username: string | undefined;
  loggedInDateTime?: Date | undefined;
  //@deprecated
  signupStep?: any; // delete
  challengeName?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'SELECT_MFA_TYPE' | 'MFA_SETUP' | 'PASSWORD_VERIFIER' | 'CUSTOM_CHALLENGE' | 'DEVICE_SRP_AUTH' | 'DEVICE_PASSWORD_VERIFIER' | 'ADMIN_NO_SRP_AUTH' | 'NEW_PASSWORD_REQUIRED'; // replace signupStep
  user: Partial<ICompanyUsers> | undefined;
  cognitoUser: any;
  showError?: boolean;
  error?: any;
  password?: string | undefined;
  userCheckingState?: 'pending' | 'done';
}

export interface IDoSignInArgs {
  email: string;
  password: string;
}

export interface IDoRetrievePasswordArgs {
  email: string;
}

export interface IDoRedefinePasswordArgs {
  email: string;
  code: string;
  password: string;
}

export interface IDoUserInvitationStep1 {
  email: string;
  tempPassword: string;
}

export interface IDoUserInvitationStep2 {
  user: CognitoUser;
  password: string;
  firstname?: string;
  lastname?: string;
}

export interface IDoUserInvitationArgs {
  email: string;
  username: string;
  family_name: string;
  company: string;
  companyUrl: string;
  password: string;
  tempPassword: string;
  given_name: string;
  phoneNumber?: string;
  marketingOptIn?: boolean;
  analyticsOptIn?: boolean;
  termsAndConditionsAccepted?: boolean;
}

const initialState: IAuthState = {
  loggedIn         : false,
  username         : undefined,
  loggedInDateTime : undefined,
  signupStep       : undefined,
  challengeName    : undefined,
  user             : undefined,
  cognitoUser      : null,
  error            : undefined,
  userCheckingState: undefined,
};

const searchLoggedInUser = () => {
  return apiService
  .entity('companyUsers')
  .find('loggedInUser')
  .fetch();
};

const doSignin = createAsyncThunk(
  'auth/doSignin',
  async (args: IDoSignInArgs, thunkAPI) => {
    
    return Auth.signIn(args?.email, args?.password)
               .then(async (res) => {
      
                 const session: CognitoUserSession = res?.signInUserSession;
      
                 if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
                   throw AuthException({code: res.challengeName, message: 'A new password is required', name: 'NEW_PASSWORD_REQUIRED'});
                 }
      
                 if (!session?.getIdToken()) throw AuthException({code: '404', message: 'An error occurred', name: 'signing error'});
      
                 const [idToken, refreshToken, accessToken] = [
                   session.getIdToken(),
                   session.getRefreshToken(),
                   session.getAccessToken(),
                 ];
      
                 const {data} = await searchLoggedInUser();
      
                 return {
                   cognitoUser: res,
                   user       : data as Partial<ICompanyUsers>,
                 };
      
               });
  },
);

const doRetrievePassword = createAsyncThunk(
  'auth/doRetrievePassword',
  async (args: IDoRetrievePasswordArgs, thunkAPI) => {
    return await Auth.forgotPassword(args.email)
                     .then((res) => {
                       return res;
                     })
                     .catch((error) => {
                       throw AuthException(error);
                     });
  },
);

const doRedefinePassword = createAsyncThunk(
  'auth/doRedefinePassword',
  async (args: IDoRedefinePasswordArgs, thunkAPI) => {
    return await authService.redefinePassword(args);
  },
);

const doUserInvitationStep1 = createAsyncThunk(
  'auth/doUserInvitationStep1',
  async (args: IDoUserInvitationStep1, thunkAPI): Promise<CognitoUser | any> => {
    
    try {
      return Auth.signIn(args.email, args.tempPassword)
                       .then((user) => {
                         return user;
                       })
                       .catch((error) => {
                         throw AuthException(error);
                       });
    } catch (error:any) {
      return   AuthException(error);
    }
    
    
  },
);

const doUserInvitationStep2 = createAsyncThunk(
  'auth/doUserInvitationStep2',
  async (args: IDoUserInvitationStep2, thunkAPI): Promise<{ user: Partial<ICompanyUsers>, cognitoUser: CognitoUser | any }> => {

    return await Auth.completeNewPassword(args.user, args.password)
                     .then((userNew) => {
                       // at this time the user is logged in if no MFA required
                       return Auth.currentAuthenticatedUser()
                                  .then(async (userCognito: CognitoUser) => {
                                    const {data} = await searchLoggedInUser();
                                    
                                    //Complete firstname and lastname
                                    data.firstName = args.firstname;
                                    data.lastName  = args.lastname;
                                    const userApi   = new objectApi.companyUsers();
                                    userApi.update(data.id, {firstName: data.firstName, lastName: data.lastName});
                                    return {
                                      cognitoUser: userCognito,
                                      user       : data as Partial<ICompanyUsers>,
                                    };
                                  })
                                  .catch((error) => {
                                    throw AuthException(error);
                                  });
                     })
                     .catch((error) => {
                       throw AuthException(error);
                     });
  },
);

const doUserInvitation = createAsyncThunk(
  'auth/doUserInvitation',
  async (args: IDoUserInvitationArgs, thunkAPI) => {
    
    return await authService
    .userInvitation(args)
    .then(async (res) => {
      const {data} = await searchLoggedInUser();
      
      return {
        cognitoUser: res,
        user       : data as Partial<ICompanyUsers>,
      };
    });
    
  },
);
// reload info on user, recalled on account or user self changes
const doRefreshUser    = createAsyncThunk(
  'auth/doUserRefresh',
  async () => {
    
    return await searchLoggedInUser();
    
  },
);

const doSignOut = createAsyncThunk(
  'auth/doSignOut',
  async (args: undefined, thunkAPI) => {
    localStorage.clear();
    const response = await authService.signOut();
    return response;
  },
);

const refreshUserSession = createAsyncThunk(
  'auth/refreshUserSession',
  (thunkAPI) => {
    return authService.getCurrentSession()
                      .then((res: CognitoUserSession) => {
                        return res;
                      });
  },
);

const authSlice = createSlice({
  name         : 'authSlice',
  initialState,
  reducers     : {
    resetError(state) {
      state.showError = false;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(
      doSignin.fulfilled.type,
      (state, action: PayloadAction<{ cognitoUser: any, user: any }>) => {
        const {payload}           = action;
        const {cognitoUser, user} = payload;
        
        const session: CognitoUserSession = cognitoUser.signInUserSession;
        
        const [idToken, refreshToken, accessToken, isValid] = [
          session.getIdToken(),
          session.getRefreshToken(),
          session.getAccessToken(),
          session.isValid(),
        ];
        
        if (payload) {
          // cache entry
          Cache.setItem('userAttributes', user);
          Cache.setItem('tokens', {idToken, refreshToken, accessToken});
          
          state.loggedIn      = isValid; //Boolean(!cognitoUser);
          state.cognitoUser   = cognitoUser;
          state.signupStep    = cognitoUser.challengeName;
          state.challengeName = cognitoUser.challengeName;
          state.user          = user;
          // put user in singleton
          AUTHUser.setUser(user);
          //@ts-ignore
          const meta = action?.meta ?? undefined;
          
          if (meta) {
            // tricky
            state.password = meta.arg.password;
          }
          
          //
          if (['NEW_PASSWORD_REQUIRED'].includes(cognitoUser.challengeName)) {
            state.loggedIn = false;
          }
        }
        state.userCheckingState = 'done';
        
      },
    )
    .addCase(
      doSignin.rejected.type,
      (state, action: PayloadAction<any, any, any, any>) => {
        Cache.removeItem('userAttributes');
        Cache.removeItem('tokens');
        state.loggedIn          = false;
        state.error             = action.error;
        state.showError         = true;
        state.challengeName     = action.error?.code;
        state.userCheckingState = 'done';
      },
    )
    .addCase(doRetrievePassword.fulfilled.type, (state, action) => {
    })
    .addCase(doRetrievePassword.rejected.type, (state, action: any) => {
      state.userCheckingState = 'done';
      state.error             = action.error;
      state.showError         = true;
    })
    .addCase(doRedefinePassword.fulfilled.type, (state, action) => {
      state.userCheckingState = 'done';
    })
    .addCase(doRedefinePassword.rejected.type, (state, action: any) => {
      state.error             = action.error;
      state.showError         = true;
      state.userCheckingState = 'done';
    });
    // do UserInvitation Step1
    builder.addCase(
      doUserInvitationStep1.fulfilled.type,
      (state, action: PayloadAction<CognitoUser>) => {
        const {payload}     = action;
        state.challengeName = 'NEW_PASSWORD_REQUIRED';
        state.cognitoUser   = payload;
      },
    );
    builder.addCase(
      doUserInvitationStep1.rejected.type,
      (state, action: any ) => {
        const {error}     = action;
        
        if(error){
          state.error = error
          state.showError = true
        }
      },
    );
    builder.addCase(
      doUserInvitationStep2.fulfilled.type,
      (state, action: PayloadAction<{ cognitoUser: CognitoUser, user: Partial<ICompanyUsers> }>) => {
        const {payload}           = action;
        const {cognitoUser, user} = payload;
        
        if (payload) {
          // cache entry, needed for refreshToken ?
          Cache.setItem('userAttributes', user);
          state.loggedIn      = true;
          state.cognitoUser   = cognitoUser;
          state.signupStep    = undefined; // cognitoUser.challengeName;
          state.challengeName = undefined; // cognitoUser.challengeName;
          state.user          = user;
        }
        
        state.userCheckingState = 'done';
        // consider logged in
      },
    );
    builder.addCase(
      doUserInvitation.fulfilled.type,
      (state, action: PayloadAction<any>) => {
        const {payload}           = action;
        const {cognitoUser, user} = payload;
        
        if (payload) {
          // cache entry
          Cache.setItem('userAttributes', user);
          state.loggedIn      = true;
          state.cognitoUser   = cognitoUser;
          state.signupStep    = cognitoUser.challengeName;
          state.challengeName = cognitoUser.challengeName;
          state.user          = user;
        }
        
        state.userCheckingState = 'done';
        // consider logged in
      },
    );
    builder.addCase(doUserInvitation.rejected.type, (state, action: any) => {
      state.error             = action.error;
      state.showError         = true;
      state.userCheckingState = 'done';
    });
    //
    builder.addCase(
      refreshUserSession.fulfilled.type,
      (state, action: PayloadAction<any>) => {
        const user = action.payload.getIdToken().payload;
        
        if (user && Cache.getItem('userAttributes')) {
          // retrieve user from storage
          state.user     = Cache.getItem('userAttributes');
          state.loggedIn = true;
        }
        
        state.cognitoUser       = user;
        state.userCheckingState = 'done';
      },
    );
    builder.addCase(refreshUserSession.pending.type, (state, action: any) => {
      state.userCheckingState = 'pending';
    });
    builder.addCase(refreshUserSession.rejected.type, (state, action: any) => {
      state.loggedIn          = false;
      state.userCheckingState = 'done';
    });
    //
    builder.addCase(
      doRefreshUser.fulfilled.type,
      (state, action: PayloadAction<any>) => {
        // put user in singleton
        if (action.payload.data.id) AUTHUser.setUser(action.payload.data);
      },
    );
    builder.addCase(
      doSignOut.fulfilled.type,
      (state, action: PayloadAction<any>) => {
        state.loggedIn = false;
      },
    );
    //
  },
});

function AuthActions() {
  const dispatch = useDispatch<any>();
  
  return {
    resetError: () => {
      authSlice.actions.resetError();
    },
    doSignin  : async (args: IDoSignInArgs) => {
      return dispatch(doSignin(args));
    },
    /** @deprecated */
    doRetrievePassword   : async (args: IDoRetrievePasswordArgs) => {
      return dispatch(doRetrievePassword(args));
    },
    doRedefinePassword   : async (args: IDoRedefinePasswordArgs) => {
      return dispatch(doRedefinePassword(args));
    },
    doUserInvitation     : async (args: IDoUserInvitationArgs) => {
      return dispatch(doUserInvitation(args));
    },
    doUserInvitationStep1: async (args: IDoUserInvitationStep1) => {
      return dispatch(doUserInvitationStep1(args));
    },
    doUserInvitationStep2: async (args: IDoUserInvitationStep2) => {
      return dispatch(doUserInvitationStep2(args));
    },
    doSignOut            : async () => {
      return dispatch(doSignOut());
    },
    doRefreshSession     : async () => {
      return dispatch(refreshUserSession());
    },
    doRefreshUser        : async () => {
      return dispatch(doRefreshUser());
    },
  };
}

export function useAuthState() {
  return useSelector((state: RootState) => state.authSlice || {});
}

export const useAuthActions = AuthActions;

export default authSlice.reducer;
