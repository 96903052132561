import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';




export const useBrandAccountAssociation = (companyId: number) => {
  
  const accountApi      = new objectApi.accounts();
  
  const {isLoading, error, data, refetch}      = useQuery<any,IAccounts[]>(['accounts', companyId, 'brandAccountAssociations'], () =>{
    return accountApi.getBrandAccountAssociations(companyId).then(res => {
      const brandDataSet = (res?.data?.brandAccountAssociations ?? []).map((x: any) => x.subAccount);
      return  brandDataSet;
    })
  });
 
  
  
  
  return {isLoading, error, data, refetch};
};