import {FormControlLabel, TextField, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {
    IAccountConfParameter,
    IAccounts,
    IProviderAccountConfParameters
} from "application/entities/dataTypes/accounts";
import {StyledCheckbox} from "components/form/CheckBox";
import {useAppToast} from "components/live/AppToast";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {DetailBlock, InfoBlockTitle, InputFieldLabel} from "components/templates/InfoBlock";
import {AppIcon} from "components/ui/AppIcon";
import React, {FocusEvent} from "react";
import {useTranslation} from "react-i18next";

export const ProviderEditAccountConfParameters = (props: { account: Partial<IAccounts> }) => {
    const {account} = props;
    const accountId = Number(account.id);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [accountConfParams, setAccountConfParams] = React.useState<IProviderAccountConfParameters>({} as IProviderAccountConfParameters);
    const [loading, setLoading] = React.useState<boolean>(true);
    const initialAccountConfParams = React.useRef({} as IProviderAccountConfParameters);

    const appToast = useAppToast();

    const {t} = useTranslation();
    const dialogWindow = useOpenDialogWindow();

    const accountsApi = new objectApi.accounts();

    React.useEffect(() => {
        if (accountId) {
            accountsApi.getAdminAccountConfParameters(accountId).then(res => {
                const accountConfParameters = res.data.accountConfParameters;
                setAccountConfParams(accountConfParameters);
                initialAccountConfParams.current = accountConfParameters as IProviderAccountConfParameters;
                setLoading(false);
            })
        }
    }, [accountId]);

    const handleChange = (e: any) => {
        //for Boolean values
        if (["provider_share_on_gross_revenue"].includes(e.target.name)) {

            saveAccountConfParameter(e.target.name, e.target.checked);
            setAccountConfParams((prev) => ({...prev, [e.target.name]: e.target.checked}));

        } else {
            setAccountConfParams((prev) => ({...prev, [e.target.name]: e.target.value}));
        }
    };


    const saveAccountConfParameter = (paramName: IAccountConfParameter, value: boolean | number | string) => {
        const data = {
            paramName: paramName,
            paramValue: value,
        };
        accountsApi.setAdminAccountConfParameters(accountId, data).then((res) => {
            initialAccountConfParams.current = {...initialAccountConfParams.current, [paramName]: value};
            const newValue = value === true ? "On" : value === false ? "Off" : value;
            if (res.status !== 200) {
                appToast.error({
                    message: "Error while saving " + paramName + ", please retry",
                });
            } else {
                appToast.success({
                    message: t(`account.accountConfParameters.${paramName}`) + " has been changed to " + newValue,
                });
            }
        });
    };

    const onBlurInputText = (e: FocusEvent<HTMLInputElement>) => {
        let paramName = e.target.name as IAccountConfParameter;
        let value = Object.entries(accountConfParams).filter((item) => item[0] === e.target.name)[0][1];
        let initalValue = Object.entries(initialAccountConfParams.current).filter((item) => item[0] === e.target.name)[0][1];
        if (value !== initalValue) {
            saveAccountConfParameter(paramName, value);
        }
    };

    return (
        <>
            <DetailBlock data-cy={"subscription-details"}>
                <div
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer"}}
                    onClick={() => setIsVisible(!isVisible)}
                >
                    <InfoBlockTitle style={{margin: "0"}}>Advanced parameters</InfoBlockTitle>
                    <AppIcon icon="ChevronDownBlue" fontSize="medium" rotate={isVisible ? 180 : 0}></AppIcon>
                </div>

                {isVisible && !loading && (
                    <div style={{paddingTop: "1rem"}}>
                        <div
                            style={{
                                marginBottom: "1.5rem",
                                background: "#00b5e217",
                                borderRadius: "7px",
                                padding: "1rem",
                                fontWeight: "bold"
                            }}
                            className="color-tech-grey"
                        >
                            Advanced parameters are updated live when you click on a checkbox or change a value and exit
                            the field.
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            marginBottom: "1rem"
                        }}>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={Boolean(accountConfParams?.provider_share_on_gross_revenue)}
                                        onChange={handleChange}
                                        name="provider_share_on_gross_revenue"
                                        value={Boolean(accountConfParams?.provider_share_on_gross_revenue)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography
                                            component="span">{t("account.accountConfParameters.provider_share_on_gross_revenue")}</Typography>
                                    </>
                                }
                            />


                        </div>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            marginBottom: "1rem"
                        }}>


                            <div>
                                <InputFieldLabel>{t("account.accountConfParameters.provider_share")}</InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="provider_share"
                                    name="provider_share"
                                    value={accountConfParams?.provider_share}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_provider_share"}}
                                />
                            </div>

                            <div>
                                <InputFieldLabel>{t("account.accountConfParameters.provider_data_premium")}</InputFieldLabel>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="provider_data_premium"
                                    name="provider_data_premium"
                                    value={accountConfParams?.provider_data_premium}
                                    onChange={handleChange}
                                    onBlur={onBlurInputText}
                                    inputProps={{"data-cy": "edit_provider_data_premium"}}
                                />
                            </div>


                        </div>


                    </div>
                )}
            </DetailBlock>
        </>
    );
};
