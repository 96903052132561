import {useTranslation} from 'react-i18next';
import React from 'react';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {DetailBlock, TextFieldBlock, InputFieldLabel} from '../../../components/templates/InfoBlock';
import {Typography} from '@mui/material';


interface ICompanyDetailsProps {
  account: Partial<IAccounts>;
}

export const CompanyDetails: React.FC<ICompanyDetailsProps> = ({
                                                                 account
                                                               }) => {
  const {t}         = useTranslation();
  
  return (
    <>
      <DetailBlock>
        <div>
          <InputFieldLabel htmlFor="bootstrap-input">
            {t('account.accountDetailsLabels.companyName')}
          </InputFieldLabel>
          <TextFieldBlock>
            <Typography>{account.companyName}</Typography>
          </TextFieldBlock>
        </div>
      </DetailBlock>
    
    </>
  );
};