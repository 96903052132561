import {MainFrame} from 'components/templates/MainFrame';
import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {PageId} from "../../application/pages/pages.config";
import {useUserQuery} from "../../components/hooks/data/useUserQuery";
import {AUTHUser} from "../../application/utils/AuthUser";
import {ICompanyUsers} from "../../application/entities/dataTypes/companyUser";
import {objectApi} from "../../application/entities/dataApi";
import {useQuery} from "react-query";
import {Box, Typography} from "@mui/material";
import {BrandSettings} from "./BrandSettings";

const SuperAdminBrandsSettings = ({companyId}: { companyId?: number }) => {
    const uriParams = useParams<{ companyId: string }>() as { companyId: string };
    const accountId = companyId ?? +uriParams?.companyId;

    const accountApi = new objectApi.accounts();


    const userQuery = useUserQuery({userId: AUTHUser.userId});
    const userData: ICompanyUsers = userQuery?.data ?? {};

    const brandQy = useQuery(["account", accountId, "brandAccountAssociations"], () =>
        accountApi.getBrandAccountAssociations(accountId).then((res) => res)
    );

    const brandList: Array<{
        id: number,
        name: string
    }> = brandQy.data?.data?.brandAccountAssociations?.map((brand: any) => {
        return {id: brand.subAccount.id, name: brand.subAccount.companyName}
    })


    const [brandSelected, setBrandSelected] = useState<number | undefined>(undefined)

    return (
        <MainFrame
            frameId={PageId.brandsSettingsSuperAdmin}
            scrollable={true}
            loading={brandQy.isLoading || userQuery.isLoading}
            grantWith={{accountId: userData.account?.id}}
            breadCrumbProps={{params: {companyId: accountId.toString()}}}
        >
            <Box sx={{overflowY: 'auto', height: '100%'}}>


                <Box sx={{display: "flex", gap: 8, padding: 4}}>
                    <Box
                        sx={{
                            background: "#fff",
                            boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.60)",
                            borderRadius: '10px',
                            padding: 2, width: "350px"
                        }}>
                        <Typography fontSize={16} fontWeight={600} mb={2}>Your Brands</Typography>
                        {brandList?.map((brand) => {
                            return <Typography sx={{
                                cursor: "pointer",
                                borderTop: "1px solid #ececec",
                                paddingBlock: '8px'
                            }} color={brandSelected === brand.id ? "primary.main" : "text.primary"}
                                               fontWeight={brandSelected === brand.id ? 600 : 400}
                                               onClick={() => setBrandSelected(brand.id)}>{brand.name}</Typography>
                        })
                        }
                    </Box>
                    <Box width="100%" sx={{display: "flex", justifyContent: "center"}}>
                        {!brandSelected && <Box>
                            <Typography>Select a Brand</Typography>
                        </Box>}

                        {brandSelected && <Box>
                            <BrandSettings brandId={brandSelected}/>
                        </Box>
                        }


                    </Box>

                </Box>
            </Box>
        </MainFrame>
    );
};


export default SuperAdminBrandsSettings;