import { Box, Theme, styled, useTheme } from "@mui/material";
import { TIcon } from "assets/icons";
import { Elem } from "components/ui/AppElements";
import { AppIcon } from "components/ui/AppIcon";
import { useAudienceBuilderStore } from "../Store/AudienceBuilderStore";
import { TypeAudienceTemplate } from "../types/audienceBuilderTypes";

type AudienceBuilderTabMenuProps = {
  template: TypeAudienceTemplate[];
};
export const AudienceBuilderTabMenu = (props: AudienceBuilderTabMenuProps) => {
  const audienceBuilderTemplate = props.template;
  const audienceBuilderActions = useAudienceBuilderStore((st) => st.actions);
  const activeFeatureGroupCode = useAudienceBuilderStore((st) => st.activeFeatureGroupCode);

  //Variables for style
  const theme = useTheme<Theme>();
  const bgCol = theme.palette.mode === "light" ? "#F0F2F7" : "rgba(255,255,255,0.1)";
  const activeBgColor = "#00B5E2";
  const colore = theme.palette.mode === "light" ? "#7587A3" : "white";
  const activeColor = theme.palette.mode === "light" ? "white" : "black";

  const iconList: TIcon[] = ["Basics", "Strategy", "Demographics", "Filters", "Travel", "Carowned", "Carinterest", "Stores", "Products"];

  return (
    <SlideParent>
      {audienceBuilderTemplate?.map((group, idx) => {
        const active = group.featureGroupCode === activeFeatureGroupCode;
        const bgColor = active ? activeBgColor : bgCol;
        const color = active ? activeColor : colore;
        //Set the icon
        let iconIdx = 0;
        const findIconIdx = iconList.findIndex((i) => i.toLocaleLowerCase() === group.featureGroupCode.toLocaleLowerCase());
        if (findIconIdx > -1) iconIdx = findIconIdx;

        return (
          <Elem
            key={group.featureGroupCode}
            pointer={true}
            onClick={() => {
              audienceBuilderActions.setActiveFeatureGroupCode(group.featureGroupCode);
            }}
            margin={[0.25, 0]}
          >
            <Elem
              radius={8}
              padding={[0.5, 0.25]}
              data-cy={"linkFeatureGroup_" + group.featureGroupCode}
              style={{ textAlign: "center", backgroundColor: bgColor, color: color, transition: "0.25s ease-in-out " }}
            >
              <AppIcon color={color} fontSize={"small"} display={"inline"} icon={iconList[iconIdx]} />
              <br />
              {group.featureGroupName}
            </Elem>
          </Elem>
        );
      })}
    </SlideParent>
  );
};

const SlideParent = styled(Box)((props: { theme: Theme }) => ({
  padding: "0.5rem",
  height: "100%",
  width: "104px",
  backgroundColor: props.theme.palette.mode === "light" ? "#F0F2F7" : props.theme.palette.background.paper,
}));
