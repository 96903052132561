
import { ThumbGrid } from './ViewAudienceFeatureInterest';
import {useTranslation} from 'react-i18next';

import { FeatureLabel } from '../audienceFeature';

interface ViewAudienceSubFeatureCheckListProps{
  values: any;
}

export const ViewAudienceSubFeatureCheckList = (props:ViewAudienceSubFeatureCheckListProps)=>{
  
  const {values} = props
  const {t} = useTranslation();
  
 // const confValues: optChkList[]  = jsonDecodeArray(values ?? []) ?? [];
  
  return  <ThumbGrid mode={'grouped'} grid={true} style={{marginLeft:'0.25rem'}}>
      <FeatureLabel data-cy="checklist-card-value">{t(`${values.text}` as any)}</FeatureLabel>
  </ThumbGrid>
}