import StylesProvider from '@mui/styles/StylesProvider';
import {StyledEngineProvider} from '@mui/material/styles';
import React from 'react';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import App from './App';
import {store} from './redux/store';

import i18n from './i18n.config';
import {BrowserRouter as Router} from 'react-router-dom';
import "@medyll/cssfabric/src/lib/styles/vars.min.css"
import "@medyll/cssfabric/src/lib/styles/flex/flex.min.css"
import "@medyll/cssfabric/src/lib/styles/box/box.min.css"

import TagManager from 'react-gtm-module'


import "./styles/css/main.scss";
import "./styles/css/fonts/stylesheet.css";
import {createRoot} from 'react-dom/client';
import Favicon from 'react-favicon';
import {getFaviconUrl} from 'application/utils/domain.utils';
import {getCurrentEnv} from "./application/utils/env.utils";

const container = document.getElementById('root');
const root = createRoot(container!);
const GTM = {
    ci: 'GTM-MMMHS4X',
    app: 'GTM-NFGT6KP'
}
require('./_configuration/amplify.config');
//@ts-ignore
window.process = {};

const env = getCurrentEnv()

console.log('ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT)

if (env === 'ci') {
    console.log('Installing GTM on CI')
    TagManager.initialize({
        gtmId: GTM.ci
    })
}
if (env === 'prod') {
    console.log('Installing GTM ')
    TagManager.initialize({
        gtmId: GTM.app
    })
}


const faviconUrl = getFaviconUrl()

root.render(
    <I18nextProvider i18n={i18n}>
        <Favicon url={faviconUrl}/>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                {/*<CssBaseline/>*/}
                <StylesProvider injectFirst>
                    <Router>
                        <App/>
                    </Router>
                </StylesProvider>
            </StyledEngineProvider>
        </Provider>
    </I18nextProvider>
);
