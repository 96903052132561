import {granter, IAccessOptions, TAccessObjMatch} from '../../../application/utils/granter.utils';
import {AUTHUser} from '../../../application/utils/AuthUser';

export interface ActionGranterProps {
  accesses?: IAccessOptions,
  objMatch?: TAccessObjMatch, // replace the objectMatch object
  objMerge?: TAccessObjMatch, // merges with the objectMatch object
  children?: any,
  mode?: 'allow' | 'deny'
  debug?: boolean
}

export const ActionGranter = (props: ActionGranterProps) => {
  
  const {objMatch, accesses, mode = 'allow',objMerge={}, debug =false} = props;
  
  let test: boolean = true;
  // if objectMatch is not provided, extractIt from AUTH
  const matchWith   = objMatch ?? {userId: AUTHUser.userId, accountId: AUTHUser.accountId, userAccountType: AUTHUser.accountType,...objMerge};
  
  if (accesses) {
    test = granter.grantAccesses(accesses, matchWith).debug(debug).toUser(AUTHUser.granterUser);
    if (mode === 'deny') test = !test;
  }
  
  return test ? props?.children : null;
};