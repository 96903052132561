import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from 'translation-check'
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import {translationEN} from "./_locales/en/translation";

// the translations
export const resources = {
  en: {
    translation: translationEN,
  },
} as const;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(i18nextPlugin)
  // .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    react: {
       useSuspense: false,
    },
  });

export default i18n;
