import {IFeatureGroupDto, TFeatureGroupCode} from "application/entities/dataTypes/featureGroups";
import {IFeaturesDto} from "application/entities/dataTypes/features";
import {TIcon} from "assets/icons";

export const featIcnConf: Record<TFeatureGroupCode | string, any> = {
    FILTERS: {
        icon: "Filters",
        title: "Filters",
    },
    BASICS: {
        icon: "UserGroup",
        title: undefined,
    },
    DEMOGRAPHICS: {
        icon: "Demographics",
        title: "Demographics",
    },
    STRATEGY: {
        icon: "Strategy",
        title: "Strategy",
    },
    LOCA: {
        title: "Location",
        icon: "Location",
    },
    INDUS: {
        title: "DataSources",
        icon: "DataSources",
    },
    CAROWNED: {
        title: "CarOwned",
        icon: "CarSteeringWheel",
    },
    STORE: {
        title: "store",
        icon: "Store",
    },
    PRODUCT: {
        title: "product",
        icon: "Boxes",
    },
};

export const featureGroupColor: Record<IFeatureGroupDto["code"], string> = {
    BASICS: "#217CD0",
    STRATEGY: "#62C93E",
    DEMOGRAPHICS: "#A877CF",
    FILTERS: "#5FCBC4",
    TRAVEL: "#52C1E3",
    CARINTEREST: "#62C93E",
    CAROWNED: "#217CD0",
};

export const featureColor: Record<IFeaturesDto["code"], string> = {
    INTEREST: "#62C93E",
    LOCATION: "#217CD0",
    RECENCY: "#45BAB3",
    TRAVELROUTE: "#217CD0",
    TRAVELPERIOD: "#A877CF",
    TRAVELRECENCYFEATURE: "#45BAB3",
    PROFILERECENCYFEATURE: "#45BAB3",
    AGE: "#41A51F",
    GENDER: "#F33992",
    INCOME: "#FFB800",
    HOMEOWNERSHIP: "#23C6D0",
    AUTO_INMARKET_VALUE: "#E4C20C",
    AUTO_CURRENT_MODEL: "#A877CF",
    AUTO_CURRENT_AGE: "#6E5DDA",
    AUTO_CURRENT_LOYALTY: "#F25532",
    AUTO_CURRENT_PURCHASE: "#4DABEF",
    AUTO_INMARKET_MODEL: "#D82686",
    AUTO_INMARKET_SEGMENT: "#23C6D0",
    AUTO_INMARKET_ENERGY: "#62C93E",
    AUTO_INMARKET_NEW_USED: "#53606B",
    AUTO_INMARKET_PURCHASE: "#4DABEF",
    POLITICAL_PARTY_AFFILIATION: "#4DABEF",
    POLITICAL_OUTLOOK: "#4DABEF",
    EDUCATION: "#217CD0",
    INCLUSION_LIST: "#A877CF",
    CPG_PURCHASED_PRODUCT_TYPE: "#A877CF",
    CPG_BRAND: "#E4C20C",
    CPG_VISITED_STORE: "#6E5DDA",
    CPG_STORE_SIZE: "#23C6D0",
    CPG_SPENDING_BEHAVIOR: "#F25532",
    CPG_PURCHASE_RECENCY: "#45BAB3",
    INTEREST_BRAND: "#A877CF"
};

export const featureIcon: Record<IFeaturesDto["code"], TIcon> = {
    LOCATION: "Location",
    DATASOURCE: "DataSources",
    INTENT: "Strategy",
    INTEREST: "Strategy",
    AGE: "Age",
    GENDER: "Demographics",
    CERTAIN: "Oclock",
    INCLUSION_LIST: "DataSources",
    EXCLUSION_LIST: "ExclInclu",
    TRAVELROUTE: "Route",
    TRAVELPERIOD: "TravelPeriod",
    TRAVELRECENCYFEATURE: "Recency",
    PROFILERECENCYFEATURE: "Recency",
    INCOME: "HouseholdIncome",
    STORE: "Store",
    PRODUCT: "Boxes",
    HOMEOWNERSHIP: "HouseholdIncome",
    AUTO_CURRENT_MODEL: "CarVariant",
    AUTO_CURRENT_AGE: "Age",
    AUTO_CURRENT_LOYALTY: "HandShake",
    AUTO_CURRENT_PURCHASE: "Wallet",
    AUTO_INMARKET_MODEL: "CarVariant",
    AUTO_INMARKET_SEGMENT: "CarVariant",
    AUTO_INMARKET_VALUE: "CarVariant",
    AUTO_INMARKET_ENERGY: "Plug",
    AUTO_INMARKET_NEW_USED: "Tools",
    AUTO_INMARKET_PURCHASE: "Wallet",
    FAMILY_FILTER: "Demographics", // to change when new icon is ready
    POLITICAL_PARTY_AFFILIATION: "Politics",
    POLITICAL_OUTLOOK: "Politics",
    EDUCATION: "Education",
    CPG_PURCHASED_PRODUCT_TYPE: "Boxes",
    CPG_BRAND: "Store",
    CPG_VISITED_STORE: "Store",
    CPG_STORE_SIZE: "Store",
    CPG_SPENDING_BEHAVIOR: "Wallet",
    INTEREST_BRAND: "Strategy",
    CPG_PURCHASE_RECENCY: "Recency",
    AGE_CHILDREN: "Demographics"
};

export const featureTypesPresets: Record<
    IFeaturesDto["code"],
    {
        desc: string;
        helperText: string;
        title?: string;
        inputLabel?: string;
    }
> = {
    LOCATION: {
        title: "Geographies",
        desc: "Choose where your audience should be located",
        helperText: "Choose the location of the profiles you are targeting at a country, state or a Designated Market Area (DMA) if a location has them.",
    },
    DATASOURCE: {
        desc: "Build your audience using all the data available to you or use data from select industries.",
        helperText: "Build your audience using all the data available to you or use data from select industries.",
    },
    CERTAIN: {
        desc: "The degree you need to filter out inferred attributes (applied to age & gender) to retain only profiles with deterministic (age & gender) data values.",
        helperText:
            "Every audience is made up of attributes. Some of these attributes are are 100% known via direct observation or declaration, while others are inferred. Adjusting this filter lets you decide to what percent you want the AGE and GENDER attributes in your audience to be deterministic (observed or declared). The higher the percentage you choose, the more sure you limit your profile selection to those with deterministic age and gender values. However, this will make the audience smaller.",
    },
    AGE: {
        desc: "Filter by age range, use default ranges or set your own",
        helperText: "Filter your audience by age ranges. Leave blank to reach all age ranges  from 18 to 55+.",
    },
    GENDER: {
        desc: "Filter by gender",
        helperText: "Filter your audience by gender. Leave blank to reach all genders",
    },
    INTENT: {
        desc: "Target audience based on their readiness to buy",
        helperText:
            "Filtering your audience by intent enables you to specify how far down the purchase funnel they may be based on purchase intent signals",
    },
    INTEREST: {
        desc: "Target audience based on their interest / passions / lifestyles",
        helperText: "Filter your audience by what they are interested in (e.g., autos, fashion)",
    },
    INCLUSION_LIST: {
        title: "My Data Inclusion List",
        desc: "Select uploaded lists you want to include",
        helperText: "This allows you to select one or more previously uploaded 1st Party Data Lists to build an audience from. Once you have selected a list(s) you can then enhance the list to see how many additional targeting IDs Evorra has which you can leverage.",
    },
    EXCLUSION_LIST: {
        desc: "Select a list from your 'My Data' here. None of the audiences from that list will be included in this audience build",
        helperText:
            "This feature enables you to select a previously uploaded list from 'My Lists' as an exclusion list. When you enable your audience to your chosen platform(s), any IDs which are on your associated exclusion list will not be sent. ",
    },
    TRAVELROUTE: {
        desc: "Reach profiles who are in-market and actively searching for airline travel",
        helperText:
            "Use this filter to specifically select audience profiles searching to travel via air from specific origins (ie. Europe, Rome) to specific destinations (ie. New York, or Asia)",
    },
    TRAVELPERIOD: {
        desc: "Reach profiles who are searching for travel between specific dates",
        helperText:
            "This filter allows you to specify the exact date range within which someone is searching to travel by air in (ie. Everyone looking to travel in November, or Thanksgiving long weekend)",
    },
    TRAVELRECENCYFEATURE: {
        desc: "Filter for profiles who have searched for travel",
        helperText:
            "This filter allows you to filter your audience by people who have been searching for air travel most recently (in the last seven days) or in the last two weeks or everyone in the last month",
    },
    PROFILERECENCYFEATURE: {
        desc: "Filter based on the last time we received an associated interest event for a given user; default is within the last year, and the Transparency Card estimates selected profile recency.",
        helperText: "Filter your audience by people who have been searching with specific interest behaviour most recently.",
    },
    INCOME: {
        desc: "Filter by annual household income",
        helperText:
            "Filter your audience by income ranges. Leave blank to reach all income ranges. To set an income range (such as $50k-$75k), drag the left and/or right sides of the slider.",
    },
    AUTO_CURRENT_MODEL: {
        title: "Current Car",
        desc: "Filter by make and model",
        helperText: "Filter for vehicles currently in the profile's household",
    },
    AUTO_CURRENT_AGE: {
        title: "Vehicle Age",
        desc: "Filter by age of vehicles in household",
        helperText: "",
    },
    AUTO_CURRENT_LOYALTY: {
        desc: "Filter by loyalty to brand",
        helperText: "",
    },
    AUTO_CURRENT_PURCHASE: {
        title: "Purchase Method",
        desc: "Filter by purchase method",
        helperText: "",
    },
    AUTO_INMARKET_MODEL: {
        title: "Make & Model",
        desc: "Filter by brand or specific vehicle models",
        helperText: "",
    },
    AUTO_INMARKET_SEGMENT: {
        desc: "Filter by vehicle segment",
        helperText: "",
    },
    AUTO_INMARKET_VALUE: {
        title: "Price Range",
        desc: "Filter by shoppers' budget for a vehicle",
        helperText: "",
    },
    AUTO_INMARKET_ENERGY: {
        title: "Engine Type",
        desc: "Filter for shoppers interested in hybrid or electric-only vehicles",
        helperText: "",
    },
    AUTO_INMARKET_NEW_USED: {
        title: "New or Used",
        desc: "Filter for shoppers looking for a new or used vehicle",
        helperText: "",
    },
    AUTO_INMARKET_PURCHASE: {
        title: "Purchase Method",
        desc: "Filter for shoppers who are only interested in leasing a vehicle",
        helperText: "",
    },
    FAMILY_FILTER: {
        title: "Family",
        desc: "Activate the family filter, which infers profiles that have family interests",
        helperText: "",
    },
    HOMEOWNERSHIP: {
        title: "Homeownership",
        desc: "Filter for profiles based on whether they own or rent their home",
        helperText:
            "Homeownership is determined from a mix of sources, including property records, surveys and modeled data. Homeowner includes profiles who may have mortgage payments.",
    },
    POLITICAL_PARTY_AFFILIATION: {
        title: "Political Party Affiliation",
        desc: "Filter for profiles based on political party affiliation",
        helperText: "To be in compliance with U.S. privacy regulations, profiles' party affiliation is based on modeled data at a household level",
    },
    POLITICAL_OUTLOOK: {
        title: "Political Outlook",
        desc: "Filter for profiles based on political outlook",
        helperText: "To be in compliance with U.S. privacy regulations, profiles' political outlook is based on modeled data at a household level",
    },
    EDUCATION: {
        title: "Education Level",
        desc: "Filter for profiles based on education level",
        helperText: "",
    },
    CPG_BRAND: {
        title: "Brand",
        desc: "Filter for profiles based on brand",
        helperText: "",
        inputLabel: "Brand",
    },
    CPG_PURCHASED_PRODUCT_TYPE: {
        title: "Purchased Product",
        desc: "Filter for profiles based on product",
        helperText: "",
        inputLabel: "Product",
    },
    CPG_VISITED_STORE: {
        title: "Store Name",
        desc: "Filter for profiles based on store Name",
        helperText: "",
        inputLabel: "Store Name",
    },
    CPG_STORE_SIZE: {
        title: "Store Size",
        desc: "Filter for profiles based on store size",
        helperText: "",
    },
    CPG_SPENDING_BEHAVIOR: {
        title: "Spending behavior",
        desc: "Filter for profiles based on Spending behavior",
        helperText: "",
    },
    CPG_PURCHASE_RECENCY: {
        title: "Purchase Recency",
        desc: "Choose the date to start filtering purchases from.",
        helperText: "",

    },
    INTEREST_BRAND: {
        title: "Brands",
        desc: "Filter for profiles based on a brand",
        helperText: "",
    },
    AGE_CHILDREN: {
        title: "Age Of Children",
        desc: "Filter for profiles who have children in the following age ranges",
        helperText: "",
    }
};

export type TypeSliderConfig = {
    displayAll: string;
    singular: string;
    plurial: string;
    abreviation: string;
    type: "money" | "number";
    step: number;
};

export const sliderConfig: Record<string, TypeSliderConfig> = {
    INCOME: {
        displayAll: "Incomes",
        singular: "Dollar",
        plurial: "Dollars",
        abreviation: "$",
        type: "money",
        step: 5000,
    },
    AUTO_INMARKET_VALUE: {
        displayAll: "Values",
        singular: "Dollar",
        plurial: "Dollars",
        abreviation: "$",
        type: "money",
        step: 5000,
    },
    AUTO_CURRENT_AGE: {
        displayAll: "Ages",
        singular: "year old",
        plurial: "years old",
        abreviation: "yrs",
        type: "number",
        step: 1,
    },
    AGE_CHILDREN: {
        displayAll: "Ages",
        singular: "year old",
        plurial: "years old",
        abreviation: "yrs",
        type: "number",
        step: 1,
    },
};
