import { useOpenDialogWindow } from "components/live/DialogWindow";
import { useNavigate } from "react-router";
import { StepTitle, StepDescription } from "..";
import { useUserQuery } from "components/hooks/data/useUserQuery";
import { AUTHUser } from "application/utils/AuthUser";
import { ICompanyUsers } from "types";
import { objectApi } from "application/entities/dataApi";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useAudienceEnablementStore } from "../store/AudienceEnablement.store";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { ChevronDownBlue } from "assets/icons";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { convertSummaryToFinalDspSelection } from "../utils";
import { moneyFormatter } from "application/utils/formatters.utils";
import { pageUtils } from "application/pages/pages.utils";
import { PageId } from "application/pages/pages.config";

export const ApprovalStep = () => {
  const dialogWindow = useOpenDialogWindow();
  const navigate = useNavigate();

  const userQuery = useUserQuery({ userId: AUTHUser.userId });
  const userData: ICompanyUsers = userQuery?.data ?? {};
  const audienceData = useAudienceEnablementStore(useShallow((state) => state.audienceData));
  const selectionStepForm = useAudienceEnablementStore((state) => state.selectionStepForm);
  const approvalAmount = useAudienceEnablementStore((state) => state.approvalAmount);

  const accountApi = new objectApi.accounts();
  const audienceApi = new objectApi.audiences();

  const [adminsList, setAdminsList] = useState<ICompanyUsers[]>([]);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [requester, setRequester] = useState<ICompanyUsers>({} as ICompanyUsers);
  const currency = audienceData.displayCurrency;

  const summary = selectionStepForm.filter((item) => item.profiles > 0);

  let finalDspSelection = convertSummaryToFinalDspSelection(summary);

  const getUserFullname = (user: ICompanyUsers) => {
    if (user.firstName === null && user.lastName === null) {
      return user.email;
    }
    return user.firstName + " " + user.lastName + " - " + user.email;
  };

  useEffect(() => {
    accountApi
      .getBrandRoles(userData?.account?.id, audienceData?.account?.id, { params: { withCompanyAdmin: true, brandRole: "ADMINISTRATOR" } })
      .then((res) => {
        const active_admin = res?.data?.userAccountAssociations?.filter((a) => a.user.status === "ACTIVATED");
        const admins = active_admin.map((account) => {
          return account.user;
        });
        setAdminsList(admins);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [audienceData]);

  const handleSelectAdmin = (e: any) => {
    const newRequester = adminsList.find((admin: any) => admin.id === e.target.value) ?? ({} as ICompanyUsers);
    setRequester(newRequester);
  };

  const handleSubmit = () => {
    dialogWindow.open(dialogConfirm);
  };
  const dialogConfirm: IOpenDialogAction = {
    dialogId: "confirmRequestApproval",
    buttonActions: { acceptButton: "Request", cancelButton: true },
    title: "Confirm your request",
    description: "A request will be sent to " + getUserFullname(requester) + " to approve your audience.",
    icon: "Archive",
    onAccept: (args: any, orgs: any) => {
      audienceApi.createRequestApproval(audienceData.id, finalDspSelection, requester.id).then((res) => {
        useAudienceEnablementStore.setState({ stepBackDisabled: true });
        setEmailSent(true);
      });
    },
  };

  return emailSent ? (
    <>
      <StepTitle>Approval request sent</StepTitle>
      <Typography variant="body1" mb={4}>
        Your request has been sent to {getUserFullname(requester)}. You will be notified once your request is approved or rejected.
      </Typography>
      <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
        <Button
          variant={"outlined"}
          value={"backToDashboard"}
          onClick={() => navigate(pageUtils.getPagePathById(PageId.brandDashboard, { brandId: audienceData?.account?.id }))}
        >
          Back to dashboard
        </Button>
        <div>
          <Button
            onClick={() => {
              navigate(pageUtils.getPagePathById(PageId.audienceBuilderSelection, { accountId: audienceData?.account?.id }));
            }}
            variant="contained"
          >
            Create a new audience
          </Button>
        </div>
      </Box>
    </>
  ) : (
    <>
      <StepTitle>Approval request</StepTitle>
      <StepDescription>
        Your limit to enable an audience is {moneyFormatter(approvalAmount, { currency: currency })}, you need to request an approval from an
        administrator. Once approved, you will be notified and you will be able to continue enabling your audience.
      </StepDescription>

      <Box mt={6} sx={{ width: "350px", marginInline: "auto", textAlign: "center" }}>
        <Typography mb={2} variant="body1" sx={{ textAlign: "center" }}>
          Please select an administrator to review your audience
        </Typography>

        <Select
          data-cy="select_approver"
          displayEmpty
          value={requester?.id ?? "none"}
          role="select"
          sx={{
            marginBottom: "25px",
            width: "300px",
            ".MuiSelect-select": { color: "#7587A3 !important", maxWidth: "250px" },
          }}
          onChange={handleSelectAdmin}
          IconComponent={ChevronDownBlue}
        >
          <MenuItem disabled value="none">
            <em>Select an administrator</em>
          </MenuItem>

          {adminsList.map((admin) => (
            <MenuItem key={admin.id} role="option" value={admin.id} data-cy={admin.email}>
              {getUserFullname(admin)}
            </MenuItem>
          ))}
        </Select>
        <Button data-cy="send_request" disabled={!requester?.id} variant={"contained"} value={"Request Approval"} onClick={handleSubmit}>
          Request Approval
        </Button>
      </Box>
    </>
  );
};
