import {FormHelperText, TextField} from '@mui/material';
import * as React from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {objectApi} from '../../../application/entities/dataApi';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {INote} from '../../../application/entities/dataTypes/note';
import {ColorButton} from '../../../components/form/Buttons';
import {useAppToast} from '../../../components/live/AppToast';
import {Note} from '../View/Note';
import {DetailBlock, TextFieldBlock, InputFieldLabel} from '../../../components/templates/InfoBlock';


interface IAccountNotesProps {
  account: Partial<IAccounts> ;
  setAccount: (arg: Partial<IAccounts>  ) => void;
}

export const EditAccountNotes: React.FC<IAccountNotesProps> = ({
                                                                 account,
                                                                 setAccount,
                                                               }) => {
  
  const appToast   = useAppToast();
  const notesApi   = new objectApi.notes();
  const {pathname} = useLocation();
  const {t}              = useTranslation();
  const [inputNote, setInputNote] = React.useState('');
  const [error, setError]         = React.useState(false);
  const [touched, setTouched]     = React.useState(false);
  const [disabled, setDisabled]   = React.useState(false);
  const [notesList, setNotesList] = React.useState<Partial<INote>[]>([]);
  
  
  React.useEffect(() => {
    if (account.notes) {
      // setNotesList(account.notes);
      getNotes();
    }
  }, [account]);
  
  React.useEffect(() => {
    if (touched && (inputNote.trim().length < 3 || inputNote.trim().length > 255)) {
      setError(true);
    } else {
      setError(false);
    }
    return () => setTouched(false);
  }, [inputNote]);
  
  
  React.useEffect(() => {
    if (inputNote === '' || error) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [inputNote, error]);
  
  const getNotes = () => {
    if (account.id) {
      notesApi.getAccountNotes(account.id)
              .then(
                res => {
                  const {notes} = res._embedded;
                  setNotesList(notes);
                },
              )
              .catch(err => {
                const message = err?.response?.data?.error?.message;
                appToast.open({
                  toastId : 'globalToast',
                  message : 'error',
                  severity: message ?? t('labels.error'),
                });
              });
    }
    
  };
  
  const handleChangeNotes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTouched(true);
    setInputNote(event.target.value as string);
  };
  
  const handleSaveNote = async () => {
    setDisabled(true);
    
    if (inputNote && account.id) {
      const dataNotes: Partial<INote> = {
        text   : inputNote,
        account: `/accounts/${account.id}`,
      };
      const newNote                   = await notesApi.create(dataNotes);
      if (newNote) {
        getNotes();
        setInputNote('');
      }
    }
    
  };
  
  const handleDeleteNote = async (id: number | undefined) => {
    if (id) {
      await notesApi.delete(id);
      getNotes();
    }
  };
  
  
  return (
    <>
      <DetailBlock>
        <InputFieldLabel htmlFor="bootstrap-input-multiline">
          {t('account.accountNotesLabels.notes')}
        </InputFieldLabel>
        {error &&
         <FormHelperText
             style={{color: 'red', fontSize: 10, margin: 5}}
         >
           {t('account.accountNotesLabels.noteLength')}
         </FormHelperText>}
        <TextFieldBlock>
          <TextField
            style={{padding: '0', width: '100%'}}
            value={inputNote}
            onChange={handleChangeNotes}
            id="bootstrap-input-multiline"
            multiline
            rows={4}
          />
        </TextFieldBlock>
        {pathname !== '/create_account' && <>
            <BtnBlock>
                <ColorButton
                    onClick={() => handleSaveNote()}
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                >
                  {t('account.accountNotesLabels.saveNote')}
                </ColorButton>
            </BtnBlock>
            <NotesList>
              {notesList?.map((item, index) => <Note
                key={item.id}
                item={item}
                index={index}
                handleDeleteNote={handleDeleteNote}
              />)}
            </NotesList>
        </>}
      </DetailBlock>
    </>
  );
};

const NotesList = styled.div`
  max-height: 200px;
  max-width: 505px;
  padding: 5px 25px 5px 5px;
  overflow: auto;

  > div:not(:last-child) {
    border-bottom: 1px solid #DBE0EB;
  }

  &::-webkit-scrollbar {
    width: 4px;
    padding: 1px;

    :hover {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #7587A3;
    border-radius: 120px;
  }

`;


const BtnBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin: 1rem 0;
`;