import * as React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ISubscriptions } from "../../../application/entities/dataTypes/subscriptions";
import { Typography } from "@mui/material";

import { DetailBlock, FieldLabel } from "../../../components/templates/InfoBlock";
import { ISubscriptionPlans } from "../../../application/entities/dataTypes/subscriptionPlans";
import { objectApi } from "../../../application/entities/dataApi";
import { useQuery } from "react-query";
import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import { useAccountQuery } from "../../../components/hooks/data/useAccountQuery";

interface ISubscriptionInfoProps {
  accountId: number; // CMVP-2098
  subscription: Partial<ISubscriptions>;
  subscriptionPlansList?: ISubscriptionPlans[];
}

export const ViewSubscription: React.FC<ISubscriptionInfoProps> = (props) => {
  const { subscription, accountId } = props;
  const startDate = subscription.startDate as Date;
  const renewalDate = subscription.renewalDate as Date;
  const startD = new Date(startDate);
  const startR = startDate ? new Intl.DateTimeFormat("en-US").format(startD) : "";
  const { t } = useTranslation();
  const renewalDateD = new Date(renewalDate);
  const renewalDateR = startDate ? new Intl.DateTimeFormat("en-US").format(renewalDateD) : "";

  // CMVP-2098, quick patch : we need accountData
  const accountQuery = useAccountQuery(accountId);
  const accountData: IAccounts = accountQuery?.data ?? {};

  // rewrite to internal call
  const subPlansApi = new objectApi.subscriptionPlans();
  const subPlanQy = useQuery("subPlassnQy", () => {
    return subPlansApi.get({ output: { part: "EMBEDDED" } }).then((res) => res);
  });

  const plan = (subPlanQy?.data ?? []).filter((x: any) => x.id === subscription.subscriptionPlanId);

  return (
    <>
      <DetailBlock>
        <DateBlock>
          <div>
            <FieldLabel>{t("account.accountSubscriptionLabels.startDate")}</FieldLabel>
            <Typography data-cy="subscription-start">{startR}</Typography>
          </div>
          <div>
            <FieldLabel>{t("account.accountSubscriptionLabels.renewalDate")}</FieldLabel>
            <Typography data-cy="subscription-renewal">{renewalDateR}</Typography>
          </div>
        </DateBlock>
        <DateBlock>
          <div>
            <FieldLabel># users in subscription</FieldLabel>
            <Typography data-cy="subscription-users-subscription" data-cy-data={subscription.maxUsers}>
              {subscription.maxUsers}
            </Typography>
          </div>
          <div>
            <FieldLabel>Available user invites</FieldLabel>
            <Typography data-cy="subscription-users-available" data-cy-data={(subscription?.maxUsers ?? 0) - (plan?.[0]?.maxNbUsers ?? 0)}>
              {(subscription?.maxUsers ?? 0) - (accountData?.companyUsers?.length ?? 0)} of {subscription.maxUsers}
            </Typography>
          </div>
        </DateBlock>
        {/*<ViewSubscriptionPlanList
         subscription={subscription}
         subscriptionPlansList={subscriptionPlansList}
         />*/}
      </DetailBlock>
    </>
  );
};

const DateBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  grid-row-gap: 25px;
`;
