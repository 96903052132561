import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {useNavigate} from "react-router-dom";
import {useLoggedUser} from "../hooks/useLoggedInUser";
import {useCountryQuery} from "../hooks/data/useCountryQuery";
import {useAccountQuery} from "../hooks/data/useAccountQuery";
import React from "react";
import {useTranslation} from "react-i18next";
import {objectApi} from "../../application/entities/dataApi";
import {useAppToast} from "../live/AppToast";
import {useOpenDialogWindow} from "../live/DialogWindow";
import {FormProvider, useForm} from "react-hook-form";
import {BackendError} from "../../types/backendError";
import {queryClientRefetcher} from "../../application/utils/useQuery.utils";
import {AUTHUser} from "../../application/utils/AuthUser";
import {EUserGrade} from "../../application/utils/granter.utils";
import {FormBlock} from "../templates/InfoBlock";
import {BrandForm} from "../../pages/Brands/shared/BrandForm";
import {Button, styled, Theme} from "@mui/material";
import {pageUtils} from "../../application/pages/pages.utils";
import {PageId} from "../../application/pages/pages.config";

export const FormEditBrand = ({brandId, accountData}: { brandId: number; accountData: IAccounts }) => {
    const history = useNavigate();
    // used to reload credential informations

    const loggedInUser = useLoggedUser();

    const countryQy = useCountryQuery();

    const accountQuery = useAccountQuery(brandId);

    React.useEffect(() => {
        if (Object.keys(accountData).length && countryQy?.data) {
            reset({
                industries: accountData.industries,
                country: accountData.country,
                companyName: accountData.companyName,
                companyUrlList: accountData.companyUrlList?.[0],
            });
        }
    }, [accountData, countryQy?.data]);

    //
    const {t} = useTranslation();

    const accountApi = new objectApi.accounts();

    const toast = useAppToast();
    // used to archive brand
    const dialogWindow = useOpenDialogWindow();
    // form to check input data
    const methods = useForm({
        mode: "all",
        defaultValues: {
            industries: accountData.industries,
            country: accountData.country,
            companyName: accountData.companyName,
            companyUrlList: accountData.companyUrlList?.[0],
        },
    });

    const {
        handleSubmit,
        reset,
        formState: {isValid, dirtyFields, isDirty},
    } = methods;

    async function onSubmit(account: any & Partial<IAccounts>) {
        const dtaAcc = {...account, companyUrlList: [account.companyUrlList]};

        delete dtaAcc.industries;

        await accountApi
            .update(brandId, {...dtaAcc})
            .then((rs) =>
                toast.success({
                    message: "Brand was successfully updated",
                })
            )
            .catch((e: BackendError) =>
                toast.error({
                    message: e?.message ?? "An error occurred",
                })
            );
        // update industries
        if (account.industries?.[0]?.id) {
            accountApi.updateIndustries(brandId, [account.industries?.[0]?.id]);
        }

        await accountQuery.refetch();
        queryClientRefetcher(["accounts", "accounts"]);
        loggedInUser.refetch();
    }

    function doArchiveBrands() {
        dialogWindow.open({
            dialogId: "brandArchive",
            buttonActions: {acceptButton: true, cancelButton: true},
            title: t("brand.archiveBrand"),
            description: `Are you sure you want to \r\n delete "${accountData?.companyName}" brand?`,
            icon: "Archive",
            onAccept: async () => {
                await accountApi
                    .archiveBrand(accountData.parentCompanyId, accountData.id)
                    .then((rs) => {
                        toast.success({
                            message: "The brand was successfully updated",
                        });
                        queryClientRefetcher(["brandAccountAssociations", "account", "accounts"]);
                    })
                    .catch((e: BackendError) =>
                        toast.error({
                            message: e?.message ?? "An error occurred",
                        })
                    );
            },
        });
    }

    // @ts-ignore
    const showArchiveButton = brandId !== AUTHUser.getUserAccount().id && AUTHUser.hasRole(EUserGrade.ADMINISTRATOR);

    return (
        <FormProvider {...methods}>
            <Form>
                <div>
                    <FormBlock title={"Brand Account Settings"}>
                        <form id={"updateBrand"} onSubmit={handleSubmit(onSubmit)}>
                            <BrandForm/>
                        </form>

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingInline: "1rem",
                            paddingBottom: "0.5rem"
                        }}>
                            <div className={"flex-h gap-small"}>
                                <Button
                                    onClick={() => {
                                        history(pageUtils.getPagePathById(PageId.brandDashboard, {
                                            brandId: brandId,
                                            companyId: accountData.parentCompanyId
                                        }));
                                    }}
                                    color={"error"}
                                    variant={"outlined"}
                                >
                                    Cancel
                                </Button>
                                {showArchiveButton && (
                                    <Button onClick={doArchiveBrands} variant={"outlined"}>
                                        Delete
                                    </Button>
                                )}
                            </div>
                            <div>
                                <Button form={"updateBrand"} type="submit" disabled={!isValid || !isDirty}
                                        variant={"contained"}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </FormBlock>
                </div>
            </Form>
        </FormProvider>
    );
};

const Form = styled("div")((props: { theme: Theme }) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: "100%",

    width: "100%",
}));