import {TEntityName} from '../dataTypes';
import {dataObjects} from './index';


export class dataObject {
  
  static getDataObject<T>(name: TEntityName, data?: Partial<T>) {
    if (!dataObjects[name]) throw new Error(`Missing data object for ${name}`);
    return {...dataObjects[name], ...data};
  }
  
}