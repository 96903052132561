import * as React from "react";
import { DataGrid, GridColDef, GridRowData } from "@mui/x-data-grid";
import { useStylesGridTable } from "./useStylesGridTable";
import { SortIcon, SortNone } from "../../../assets/icons";
import { Box, LinearProgress, Typography } from "@mui/material";
import { IPagerProps, Pager } from "../../controls/Pager";
import { GridCustomToolbar } from "../../../pages/Accounts/GridCustomToolbar";

interface IGridTableProps {
  // GridComponentProps
  height?: string;
  width?: string;
  loading?: boolean;
  data: { columns: GridColDef[]; rows: GridRowData[] };
  padding?: number;
  gridProps?: any; // Partial<DataGridProps>; // bug
  pagerProps?: IPagerProps; // bug GridComponentProps
  /** @deprecated */
  exportReference?: React.Ref<HTMLButtonElement>;
  /** @deprecated */
  setExportReference?: (arg0: React.Ref<HTMLButtonElement>) => void;
  styleClass?: "default" | "naked";
  noRowsText?: string;
  rowHeight?: number | "auto";
  dataCy?: string;
  customStyle?: React.CSSProperties;
  autoHeight?: boolean;
}

export const GridTable = (props: IGridTableProps) => {
  const { data, padding, pagerProps, loading, styleClass, noRowsText = "no results", rowHeight, dataCy, customStyle } = props;
  const { columns, rows } = data;

  const ROW_HEIGHT = 40;

  const getClasses = useStylesGridTable(styleClass);
  const classes = getClasses();
  const divRef = React.useRef<HTMLDivElement>(null);

  const dataGridProps = {
    hideFooter: true,
    autoPageSize: false,
    disableColumnSelector: true,
    disableColumnMenu: true,
    rowHeight: ROW_HEIGHT,
    ...props.gridProps,
    components: {
      Toolbar: GridCustomToolbar,
      ColumnSortedAscendingIcon: SortIcon,
      ColumnSortedDescendingIcon: SortIcon,
      ColumnUnsortedIcon: SortNone,
      NoRowsOverlay: () => (
        <div
          style={{
            /*display: "flex",
            alignItems: "center",
            justifyContent: "center",*/
            marginTop: "3rem",
            color: "#c5c8df",
            textAlign: "center",
            height: "100%",
            padding: "0 2rem",
          }}
        >
          <Typography style={{ textAlign: "center", color: "#bac4d2" }}>{noRowsText}</Typography>
        </div>
      ),
      ...(props?.gridProps?.components ?? { ...{} }),
    },
  };

  let pagerArgs = {
    onPageSizeChange: (page: number) => {},
    onPageChange: (page: number) => {},
    activePage: 0,
    maxCount: 1000,
    pageSize: 50,
  };
  if (pagerProps) {
    pagerArgs = {
      ...pagerArgs,
      onPageSizeChange: pagerProps.onPageSizeChange,
      onPageChange: pagerProps.onPageChange,
      activePage: pagerProps?.activePage ?? 0,
      maxCount: pagerProps?.maxCount ?? 1000,
      pageSize: pagerProps?.pageSize ?? 50,
    };
  }

  // @ts-ignore
  return (
    <Box
      ref={divRef}
      style={{ height: "100%", width: "100%", padding: padding ?? "0", ...customStyle }}
      className={classes.root}
      data-cy={dataCy ? dataCy : "GridTable"}
      data-cy-row-length-value={rows.length}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        {Boolean(loading) && (
          <Box sx={{ position: "absolute", width: "100%", px: 4, zIndex: 10 }} data-cy={"grid-loading"}>
            <LinearProgress variant="query" />
          </Box>
        )}
        <Box sx={{ flex: 1 }} style={{ opacity: loading ? "0.5" : "1" }}>
          <DataGrid
            autoHeight={props.autoHeight || false}
            rows={rows}
            columns={columns}
            headerHeight={45}
            getRowHeight={() => rowHeight ?? ROW_HEIGHT}
            getRowClassName={(params) => `super-app-theme--row`}
            {...dataGridProps}
          />
        </Box>
        {Boolean(pagerProps) && (
          <Box>
            <Pager {...pagerArgs} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
