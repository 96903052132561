import moment from "moment/moment";
import {useAudienceEnablementStore} from "../../store/AudienceEnablement.store";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Box, Button, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {CancelOutlined, EventNote, ExpandMore} from "@mui/icons-material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

export const CampaignEndDate = (props: { endDate: Date | null, dspItemCode: string }) => {
    const {endDate, dspItemCode} = props;
    const audienceEnablementActions = useAudienceEnablementStore((state) => state.actions);


    const [active, setActive] = React.useState(Boolean(endDate));

    const handleSetCampaignEndDate = (dspItemCode: string, date: Date | null) => {
        const date_formatted = date !== null ? moment(date).format('YYYY-MM-DD') : null
        if (date === null) setActive(false)
        audienceEnablementActions.setCampaignEndDate(dspItemCode, date_formatted)
    }

    const today = new Date();

    return <LocalizationProvider dateAdapter={AdapterDateFns}
                                 dateFormats={{keyboardDate: "dd/mm/yyyy"}}>
        {active ?
            <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                <DatePicker
                    components={{
                        OpenPickerIcon: EventNote,
                        SwitchViewIcon: ExpandMore,
                    }}
                    defaultCalendarMonth={new Date(today.setMonth(today.getMonth() + 1))}
                    value={endDate}
                    disableHighlightToday={true}
                    inputFormat={"dd/MM/yyyy"}
                    onChange={(date) => handleSetCampaignEndDate(dspItemCode, date)}
                    minDate={new Date(today.setMonth(today.getMonth()))}
                    maxDate={new Date(today.setMonth(today.getMonth() + 5))}
                    showDaysOutsideCurrentMonth={true}
                    OpenPickerButtonProps={{edge: "start", size: "small", color: "primary"}}
                    renderInput={(params: any) => {
                        const par = {
                            ...params,
                            className: "dateTextField",
                            inputProps: {
                                ...params.inputProps,
                                className: "smallPadding",
                                style: {padding: "0.5rem 0.5rem 0.5rem 0.5rem"},
                            },
                        };
                        return <TextField className="dateTextField"
                                          data-cy={"campaignEndDate" + dspItemCode} {...par} />;
                    }}
                />
                {endDate &&
                    <CancelOutlined fontSize='small' sx={{cursor: 'pointer'}}
                                    onClick={() => handleSetCampaignEndDate(dspItemCode, null)}/>}
            </Box>
            :
            <Button variant={"contained"} onClick={() => setActive(true)}>Set Date</Button>
        }
    </LocalizationProvider>;
}