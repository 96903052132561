import { stripeConfig } from "_configuration/stripe.config";
import apiRequest from "application/entities/api/interceptors";
import { BackendError } from "../../types/backendError";

type PostDspSelection = {
  dspSelection: Record<string, { campaignName: string; profilesNumber: number; isImpressionBasedBuy: boolean }>;
};

const getCustomerPaymentMethods = (accountId: number, paymentMethod: "card" | "sepa_debit") => {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeAccountPaymentMethods/" + accountId, {
      paymentMethod,
    })
    .then((res) => res.data)
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
};

const hasCustomerPaymentMethods = async (accountId: string) => {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeHasPaymentMethod/" + accountId)
    .then((res) => res.data)
    .catch((e) => {
      throw new BackendError(e);
    });
};

const deleteCustomerPaymentMethod = async (accountId: string, paymentMethodId: string) => {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeDeleteAccountPaymentMethod/" + accountId, {
      paymentMethodId: paymentMethodId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
};

const setSetupIntent = async (accountId: string | number, paymentMethod: "card" | "iban") => {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeCreateSetupIntent/" + accountId, {
      mode: "setup",
      customer: accountId,
      payment_method_types: [paymentMethod],
    })
    .then((res) => res.data)
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
};

const stripeCreateAudienceInvoice = function (audienceId: number, args: PostDspSelection & { payLater: boolean; paymentMethodId: string }) {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeCreateAudienceInvoice/" + audienceId, { ...args })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
};

// stripeCreateAudienceInvoice

function createAudiencePaymentIntent<T = Record<string, any>>(audienceId: number | string, paymentData: T) {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeCreateAudiencePaymentIntent/" + audienceId, {
      // paymentIntent
      ...paymentData,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
}

export const getStripePortal = async (accountId: string, returnUrl: string) => {
  return apiRequest
    .post(stripeConfig.backendHost + "/stripeCreatePortalSession/" + accountId, {
      returnUrl: returnUrl,
    })
    .then((res) => {
      // console.log({res})
      return res.data;
    })
    .catch((e) => {
      console.log({ e });
      throw new BackendError(e);
    });
};

export const getAdminPortal = async (accountId: string) => {
  return apiRequest.get(stripeConfig.backendHost + "/get-dashboard").then((res) => {
    // console.log({res})
    return res.data;
  });
};

export const stripeService = {
  setSetupIntent,
  getCustomerPaymentMethods,
  hasCustomerPaymentMethods,
  deleteCustomerPaymentMethod,
  createAudiencePaymentIntent,
  stripeCreateAudienceInvoice,
  getStripePortal,
  getAdminPortal,
};
