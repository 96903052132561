import {Box, Button, Divider, styled, Theme} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {DemoStep, useDemoStore} from './demo.store';

interface IDemoBoxProps {
  demoStep: DemoStep;
  children?: any;
};


export const DemoBox: FunctionComponent<IDemoBoxProps> = (props: IDemoBoxProps) => {
  const {children, demoStep} = props;
  
  const demoStore = useDemoStore();
  
  
  React.useEffect(() => {
    demoStore.actions.registerStep(demoStep);
  }, []);
  
  
  return <>
    {demoStore.active && <div style={{
      height: '100%', left: 0, top: 0, position: 'fixed',
      width : '100%', backgroundColor: 'rgba(255,255,255,0.5)', zIndex: 3000
    }}>
        <Button variant={'contained'} onClick={()=>{demoStore.actions.quit()}} >get me out !!!!</Button>
    </div>}
    <DemoBoxContainer demoMode={demoStore.active} active={demoStore.active && demoStore.activeStepKey === demoStep.id}>
      {children}<ZoneBox demoMode={demoStore.active} active={demoStore.active && demoStore.activeStepKey === demoStep.id}>
      <div style={{padding:'0.5rem'}}>Some nice text</div>
      <Divider />
      <br />
      <Button variant={'contained'} onClick={() => {demoStore.actions.gotIt(demoStep.id);}}>got it</Button>
      <Button variant={'text'} onClick={() => {demoStore.actions.gotIt(demoStep.id,'back');}}>previous</Button>
    </ZoneBox>
    </DemoBoxContainer></>;
};

interface IDemoBoxContainerProps {
  demoMode: boolean;
  active?: boolean;
};

const DemoBoxContainer = styled(Box)<IDemoBoxContainerProps>((props) => ({
  backgroundColor: props.active ? 'white' : 'transparent',
  zIndex         : props.active ? 4000 : 'auto',
  border         : props.active ? '1px solid #ccc' : 'none',
  borderRadius   : props.active ? '6px' : '0',
  position       : 'relative',
  //visibility     : props.active ? 'visible' : 'hidden',
  boxShadow: props.active ? `0 0 6px rgba(196, 211, 241, 0.85)` : '',
}));

const ZoneBox = styled(Box)((props:IDemoBoxContainerProps & {theme:Theme}) => ({
  width:'250px',
  padding   : '1rem',
  position  : 'fixed',
  marginLeft: '250px',
  marginTop: '-64px',
  zIndex : 5000,
  display: props.active ? 'block' : 'none',
  backgroundColor: props.theme.palette.background.paper
}));


// tour is active
// cell is active
// afterAction
// onDismissTour