import {Box, Paper, styled} from "@mui/material";
import {PageId} from "application/pages/pages.config";
import {MainFrame} from "components/templates/MainFrame";
import React from "react";
import {useParams} from "react-router-dom";
import {useFeatureFlag} from "stores/featureFlags.store";
import {IAccounts} from "../../../application/entities/dataTypes/accounts";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {EPermission} from "../../../application/utils/granter.utils";
import {useAccountQuery} from "../../../components/hooks/data/useAccountQuery";
import {AccountHeaderContent, AccountHeaderProperties} from "../../Agencies/AccountHeader";
import {useAccountTracker} from "../../Agencies/useAccountTracker";
import {BrandNavigation} from "../shared/BrandNavigation";
import {FormEditBrand} from "../../../components/brands/FormEditBrand";
import {CPMManagement} from "../../../components/brands/CPMManagement";

interface UpdateBrandProps {
    brandId?: number;
}

export const UpdateBrand = (props: UpdateBrandProps) => {
    const uriParams = useParams<{ brandId: string }>() as { brandId: string };
    const brandId = props?.brandId ?? +uriParams?.brandId;

    const accountQuery = useAccountQuery(brandId);
    const accountData: IAccounts = accountQuery?.data ?? {};

    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    // account tracker and mode setter
    useAccountTracker(brandId);

    return (
        <MainFrame
            checkPageAccess={true}
            grantWith={{accountId: accountData.id}}
            loading={accountQuery?.isLoading}
            browserTitle={"Update - " + accountData?.companyName}
            headerProps={{
                hideHeaderDefault: true,
                headerProperties: <AccountHeaderProperties account={accountData}/>,
                headerContent: <AccountHeaderContent account={accountData}/>,
                headerButtons: <BrandNavigation brand={accountData}/>,
            }}
            frameId={PageId.brandUpdate}
        >
            <Box
                sx={{
                    padding: "2rem",
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                        maxWidth: "700px",
                        margin: "auto",
                    }}
                >
                    <ItemBox>
                        <FormEditBrand brandId={brandId} accountData={accountData}/>
                    </ItemBox>
                    {featureFlags?.feature_service_fee && AUTHUser.permissions.accountGrade.includes(EPermission.COMPANYADMIN) && (
                        <CPMManagement brandId={brandId}/>
                    )}
                </Box>
            </Box>
        </MainFrame>
    );
};


const ItemBox = styled(Paper)(() => ({
    padding: "1rem",
    paddingTop: 0,
    background: "#fff",
    borderRadius: "8px",
    overflowY: "auto",
}));
