import styled from 'styled-components/macro';


export const StatusActive = styled.div`
  background: rgba(2, 198, 151, 0.1);
  height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0 8px 0 0;
  border-radius: 26px;
  margin: 0 0 5px 0;
  color: rgba(2, 198, 151, 1);
  font-size: 10px;
`;
export const DotActive = styled.div`
  background: rgba(2, 198, 151, 1);
  width: 8px;
  height: 8px;
  margin: 7px;
  border-radius: 50%;
  border: white solid 1px;
  box-shadow: 0 0 3px rgba(2, 198, 151, 0.5);
`;

export const StatusInactive = styled(StatusActive)`
  background: rgba(252, 96, 80, 0.1);
  color: rgba(252, 96, 80, 1);
`;
export const DotInactive = styled(DotActive)`
  background: rgba(252, 96, 80, 1);
  box-shadow: 0 0 3px rgba(252, 96, 80, 0.5);
`;

export const GreyDot = styled.div`
  background: #7587A3;
  width: 8px;
  height: 8px;
  margin: 7px;
  border-radius: 50%;
  border: #F1F3F8 solid 1px;
`;
