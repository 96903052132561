import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {useShallow} from "zustand/react/shallow";
import {Typography} from "@mui/material";
import React from "react";
import {getCPMFromSegmentsList} from "../Segments.utils";

export const SegmentCpm = () => {
    const selectedSegments = useSegmentBuilderStore(useShallow((state) => state.selectedSegments))


    return <>
        <Typography variant="h4" fontSize="16px">CPM: {getCPMFromSegmentsList(selectedSegments)}</Typography>
    </>
}