import {StepDescription, StepTitle} from "../index";
import {useTranslation} from "react-i18next";
import {Box, Button, Checkbox, Collapse, Typography} from "@mui/material";
import {AppTip} from "../../../AudienceBuilder/components/AudienceBuilderAppTip";
import {DspTable, DspTableRow} from "./DspSelectionStep";
import {useAudienceEnablementStore} from "../store/AudienceEnablement.store";
import {useShallow} from "zustand/react/shallow";
import {getDspAdditionalInfo, getDspIcon} from "../../../../application/utils/dsp.utils";
import {AppIcon} from "../../../../components/ui/AppIcon";
import {Link} from "react-router-dom";
import {pageUtils} from "../../../../application/pages/pages.utils";
import {PageId} from "../../../../application/pages/pages.config";
import {useSelectedDspChannels} from "../../../../components/hooks/data/useDspChannelsQuery";
import {getMaxFromIdsCountList, getTotalIdsForSegmentsList} from "../../../SegmentBuilder/Segments.utils";
import {profilesFormatter} from "../../../../_configuration/formaters";
import React, {useEffect} from "react";
import {SelectionStepFormType} from "../types";
import {moneyFormatter} from "../../../../application/utils/formatters.utils";

export const DspSelectionSegementStep = () => {
    const {t} = useTranslation();
    const selectionStepForm = useAudienceEnablementStore(useShallow((state) => state.selectionStepForm));
    const audienceData = useAudienceEnablementStore(useShallow((state) => state.audienceData));
    const audienceEnablementActions = useAudienceEnablementStore((state) => state.actions);

    const {data: channelsActive} = useSelectedDspChannels(audienceData?.account?.id); //to build the link to channel setup is dspitem has error


    const [displayDetailChannelCodeList, setDisplayDetailChannelCodeList] = React.useState<string[]>([])


    const tableColumns = [
        {
            id: "enableChannel",
            title: "Select",
            appTip: null,
            width: "80px",
            align: "left",
        },
        {
            id: "enablementChannel",
            title: t("dsp.enablementChannel"),
            appTip: null,
            width: "1fr",
            align: "left",
        },
        {
            id: "enablementOption",
            title: t("dsp.enablementOption"),
            appTip: t("dsp.dspTable.enablementOptionTip"),
            width: "1fr",
            align: "left",
        },
        {
            id: "buyerId",
            title: t("dsp.BuyerId"),
            appTip: t("dsp.dspTable.enablementChannelIdTip"),
            width: "110px",
            align: "center",
        },
        {
            id: "maxIds",
            title: "Ids Volume Max",
            appTip: "Ids Volume Maximum can be enabled",
            width: "150px",
            align: "center",
        },
        {
            id: "details",
            title: "Segment Selected",
            appTip: null,
            width: "120px",
            align: "center",
        }
    ]
    const gridTemplate = tableColumns.map((col) => col.width).join(" ")

    const getDspItemId = (code: string) => {
        if (channelsActive) {
            const item = channelsActive?.find((channel) => channel.dspChannel.code === code);
            return item?.id;
        }
    };

    const handleSelectChannel = (dspItem: SelectionStepFormType, checked: boolean) => {
        const segmentsUuid = dspItem.externalSegments?.map(segment => segment.uuid)
        audienceEnablementActions.updateSelectionFormStep(dspItem.code, {
            segmentSelection: checked ? segmentsUuid : [],
            profiles: 0
        })

        setDisplayDetailChannelCodeList(checked ? [...displayDetailChannelCodeList, dspItem.code] : displayDetailChannelCodeList.filter(code => code !== dspItem.code))
    }

    const handleSelectChannelSegment = (dspItem: SelectionStepFormType, segmentUuid: string, checked: boolean) => {
        const selectionStepFormItem = selectionStepForm.find(item => item.code === dspItem.code)
        if (!selectionStepFormItem) return
        selectionStepFormItem.segmentSelection = checked ? [...selectionStepFormItem.segmentSelection!, segmentUuid] : selectionStepFormItem.segmentSelection?.filter(uuid => uuid !== segmentUuid)
        audienceEnablementActions.updateSelectionFormStep(dspItem.code, selectionStepFormItem)

    }


    const selectedChannelsCount = selectionStepForm.filter(item => item.segmentSelection?.length).length


    useEffect(() => {

        let hasOptionsStep = false;
        selectionStepForm.forEach((dspItem, idx) => {

            if (dspItem.dspCheckoutParameterGroups?.length > 0 && dspItem?.segmentSelection?.length) {
                hasOptionsStep = true;
            }
        });

        if (hasOptionsStep) {
            audienceEnablementActions.displayStep("EnablementOptions");
        } else {
            audienceEnablementActions.hideStep("EnablementOptions");
        }


        //check if there is a dsp with checkout params to activate step
    }, [selectionStepForm]);

    return <>
        <StepTitle>Select Channels</StepTitle>
        <StepDescription>
            Select which channels you would like to enable audiences on.
        </StepDescription>

        <DspTable>
            <DspTableRow gridTemplate={gridTemplate} header={true}>
                {tableColumns.map((col) => {
                    return (
                        <Box key={col.id} width={col.width}
                             sx={{display: "flex", justifyContent: col.align, gap: "0.25rem"}}>
                            <Typography sx={{
                                textAlign: col.align,
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>{col.title}</Typography>
                            {col.appTip && <AppTip title={col.title} text={col.appTip}/>}
                        </Box>
                    );
                })}
            </DspTableRow>

            {selectionStepForm.map((dspItem) => {
                const totalIds = getTotalIdsForSegmentsList(dspItem.externalSegments)
                const maxIds = getMaxFromIdsCountList(totalIds)
                const additionnalInfo = getDspAdditionalInfo(dspItem.code);
                const segmentSelectedCount = dspItem?.segmentSelection?.length ?? 0
                return (
                    <DspTableRow key={dspItem.code} gridTemplate={gridTemplate} data-cy={`dsp-${dspItem.code}`}>
                        <Box>
                            <Checkbox checked={dspItem?.segmentSelection?.length ? true : false} onChange={(e: any) => {
                                console.log('e', e.target.checked)
                                handleSelectChannel(dspItem, e.target.checked)
                            }}/>
                        </Box>
                        <Box>
                            <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.activationPlatformCode)}/>
                                <Typography sx={{fontWeight: "bold", fontSize: "14px"}}
                                            data-cy={"activationPlatform"}>
                                    {dspItem.activationPlatform}
                                </Typography>
                            </Box>
                            {dspItem.seatName !== "Untitled" && dspItem.seatName.length > 2 && (
                                <Typography className="color-tech-grey" ml={3} data-cy={"seatName"}>
                                    {dspItem.seatName}
                                </Typography>
                            )}
                        </Box>

                        <Box>
                            <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.code)}/>
                                <Typography sx={{fontWeight: "bold", fontSize: "12px"}}
                                            data-cy={"activationPlatform"}>
                                    {additionnalInfo?.name ?? dspItem.name}
                                </Typography>
                            </Box>
                            {additionnalInfo?.restriction && (
                                <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "5px"}}>
                                    <Typography className="color-tech-grey" style={{fontStyle: "italic"}}>
                                        {additionnalInfo?.restriction}
                                    </Typography>
                                    {additionnalInfo?.restrictionDetails && (
                                        <AppTip title={additionnalInfo?.restriction}
                                                text={additionnalInfo?.restrictionDetails}/>
                                    )}
                                </Box>
                            )}
                            {dspItem.hasError && (
                                <Box pt={1}>
                                    <Typography color="error">
                                        Error, please check your channel setup{" "}
                                        <Link
                                            to={pageUtils.getPagePathById(PageId.channelsSetupEdit, {
                                                accountId: audienceData.account.id,
                                                channelId: getDspItemId(dspItem.activationPlatformCode),
                                            })}
                                        >
                                            on this page
                                        </Link>
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <Box textAlign={"center"}>
                            <Typography data-cy={"advertiserId"} noWrap={true}>
                                {dspItem.advertizerId}
                            </Typography>
                        </Box>

                        <Box textAlign={"center"}>
                            <Typography data-cy={"maxIds"} noWrap={true}>
                                {profilesFormatter.format(maxIds)}
                            </Typography>
                        </Box>

                        <Box textAlign={"center"}>

                            {segmentSelectedCount === 0 && (
                                <Typography data-cy={"maxIds"} noWrap={true}>
                                    0
                                </Typography>
                            )}
                            {segmentSelectedCount > 0 && (
                                <Typography data-cy={"maxIds"} noWrap={true} sx={{color: "#00B5E2", cursor: "pointer"}}
                                            onClick={() => {
                                                if (displayDetailChannelCodeList.includes(dspItem.code)) {
                                                    setDisplayDetailChannelCodeList(displayDetailChannelCodeList.filter(code => code !== dspItem.code))
                                                } else {
                                                    setDisplayDetailChannelCodeList([...displayDetailChannelCodeList, dspItem.code])
                                                }
                                            }}>
                                    {displayDetailChannelCodeList.includes(dspItem.code) ? `Hide ${segmentSelectedCount} selected` : `Show ${segmentSelectedCount} selected`}
                                </Typography>
                            )}

                        </Box>
                        <Collapse in={displayDetailChannelCodeList.includes(dspItem.code)} sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: tableColumns.length + 1,
                        }}>
                            <Box sx={{
                                background: "#fcfcfc",
                                padding: ' 0 1rem',
                                borderRadius: '7px',
                                border: '1px solid #ececec',
                                width: "800px",
                                mx: 'auto',
                            }}>
                                <DspTable>
                                    <DspTableRow gridTemplate={"50px 1fr 120px 80px"} header={true}>
                                        <Box>
                                            <Typography sx={{
                                                textAlign: 'left',
                                                fontSize: "12px",
                                                fontWeight: "bold"
                                            }}>Active</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{
                                                textAlign: 'left',
                                                fontSize: "12px",
                                                fontWeight: "bold"
                                            }}>Segment Name</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{
                                                textAlign: 'center',
                                                fontSize: "12px",
                                                fontWeight: "bold"
                                            }}>Max Ids Volume</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{
                                                textAlign: 'center',
                                                fontSize: "12px",
                                                fontWeight: "bold"
                                            }}>CPM</Typography>
                                        </Box>
                                    </DspTableRow>
                                    {dspItem.externalSegments?.map(segment => {
                                        const totalIds = getTotalIdsForSegmentsList([segment])
                                        const maxIds = getMaxFromIdsCountList(totalIds)
                                        const isSelected = dspItem?.segmentSelection?.length ? dspItem?.segmentSelection?.includes(segment.uuid!) : false
                                        return <DspTableRow key={segment.uuid} gridTemplate={"50px 1fr 120px 80px"}>
                                            <Box>
                                                <Checkbox checked={isSelected}
                                                          onChange={(e: any) => {
                                                              const isChecked = e.target.checked
                                                              handleSelectChannelSegment(dspItem, segment.uuid!, isChecked)
                                                              if (!isChecked && segmentSelectedCount === 1) {
                                                                  setDisplayDetailChannelCodeList(displayDetailChannelCodeList.filter(code => code !== dspItem.code))
                                                              }
                                                          }}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={{
                                                    textAlign: 'left',
                                                    fontSize: "12px",

                                                }}>{segment.segmentName}</Typography>
                                                <Typography sx={{
                                                    textAlign: 'left',
                                                    fontSize: "12px",
                                                    color: "#7587A3"
                                                }}>{segment.segmentDescription}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{
                                                    textAlign: 'center',
                                                    fontSize: "12px",
                                                }}>{profilesFormatter.format(maxIds)}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{
                                                    textAlign: 'center',
                                                    fontSize: "12px",
                                                }}>{moneyFormatter(segment.segmentCpm ?? 0, {currency: "USD"})}</Typography>
                                            </Box>
                                        </DspTableRow>
                                    })}
                                </DspTable>
                            </Box>
                        </Collapse>


                    </DspTableRow>
                );
            })}

        </DspTable>


        <Box textAlign={"center"} mt={1}>
            <Button
                disabled={selectedChannelsCount === 0}
                onClick={audienceEnablementActions.goToNextStep}
                variant={"contained"}
                value={"Continue"}
                data-cy={"continue-btn"}
            >
                {t("dsp.continue")}
            </Button>
        </Box>
    </>
}