import { Divider, Typography } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { IAccounts } from "application/entities/dataTypes/accounts";
import { MainFrame } from "components/templates/MainFrame";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { AdminAccountUsersList } from "./components/AdminAccountUsersList";
import { AdminAccountEnablementChannels } from "./components/AdminAccountEnablementChannels";
import { ButtonEditAccount } from "../Update/ButtonEditAccount";
import { AdminCard } from "components/ui/AdminUi/AdminCard";
import { AdminAccountAudiences } from "./components/AdminAccountAudiences";
import { useAdminPage } from "components/hooks/useAdminPage";
import { AdminAdvancedParams } from "./components/AdminAdvancedParams";
import { AdminAccountDetails } from "./components/AdminAccountDetails";
import { AdminAccountFeatureFlag } from "./components/AdminAccountFeatureFlag";
import { AdminAccountDataVaultSetup } from "./components/AdminAccountDataVaultSetup";
import { AdminWrap, Content } from "pages/EvorraDashboard";

const accountsApi = new objectApi.accounts();

export const AdminAccountView = () => {
  const { accountId } = useParams<{ accountId: string }>() as { accountId: number };
  const navigate = useNavigate();
  useAdminPage();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<{ message: string; reason: string } | null>(null);
  const [account, setAccount] = React.useState<IAccounts>({} as IAccounts);

  const fetchData = useCallback((accountId: number) => {
    accountsApi
      .byId(accountId)
      .then((res) => {
        setAccount(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err._error);
      });
  }, []);
  React.useEffect(() => {
    if (accountId) {
      setLoading(true);
      fetchData(accountId);
    }
  }, [fetchData, accountId]);

  const headerButtons = <ButtonEditAccount account={account as IAccounts} setAccount={setAccount} viewMode={true} />;

  const editAccount = () => {
    navigate(pageUtils.getPagePathById(PageId.accountEdit, { accountId: account.id }));
  };

  const editDataVaultSetup = () => {
    navigate(pageUtils.getPagePathById(PageId.vaultSetup, { accountId: account.id }));
  };

  const goToUserManagement = () => {
    navigate(
      pageUtils.getPagePathById(PageId.companyUserList, { companyId: account.accountType === "BRAND" ? account.parentCompanyId : account.id })
    );
  };

  return (
    <>
      <MainFrame
        frameId={PageId.adminAccountView}
        loading={loading}
        checkPageAccess={true}
        headerProps={{
          headerButtons: headerButtons,
          headerTitle: (
            <Typography variant={"h4"} data-cy="page-title">
              {account.companyName}
            </Typography>
          ),
        }}
      >
        {error ? (
          <Error>
            {error?.message} {error?.reason}{" "}
          </Error>
        ) : (
          <AdminWrap>
            <Content>
              <MinMaxGrid>
                <AdminCard title="Account details" icon="Account" action={{ text: "Edit", onClick: editAccount }}>
                  <AdminAccountDetails account={account} />
                </AdminCard>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  {account.dataClient === true && (
                    <>
                      <AdminCard title="Advanced configuration" icon="SettingsIcon" action={{ text: "Edit", onClick: editAccount }}>
                        <AdminAdvancedParams accountId={account.id} isCompany={account?.accountType === "BRAND" ? false : true} />
                      </AdminCard>
                    </>
                  )}
                  {account?.dataProvider === true && (
                    <>
                      <AdminCard title="Vault Setup" icon="SettingsIcon" action={{ text: "Edit", onClick: editDataVaultSetup }}>
                        <AdminAccountDataVaultSetup accountId={account.id} />
                      </AdminCard>
                    </>
                  )}
                  <AdminCard title="Feature flags" icon="Flag" toggle={true}>
                    <AdminAccountFeatureFlag accountId={account.id} />
                  </AdminCard>
                </div>

                {account?.accountType === "COMPANY" && account.dataClient === true && (
                  <>
                    <AdminCard title="Brands list" icon="MyListsIcon">
                      <div>
                        {account.brandAccountAssociations?.length === 0 && <Typography>No brand </Typography>}
                        {account.brandAccountAssociations
                          ?.sort((a, b) => a.subAccount.id - b.subAccount.id)
                          .map((brand, index) => {
                            return (
                              <div key={brand.subAccount.id} style={{ paddingBlock: "2px" }}>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={pageUtils.getPagePathById(PageId.adminAccountView, { accountId: brand.subAccount.id })}
                                  key={index}
                                >
                                  #{brand.subAccount.id} - {brand.subAccount.companyName}
                                </Link>
                                <Divider style={{ marginBlock: "5px" }} />
                              </div>
                            );
                          })}
                      </div>
                    </AdminCard>
                  </>
                )}

                <AdminCard title="Users" icon="UserList" action={{ text: "Users Management", onClick: goToUserManagement }}>
                  <AdminAccountUsersList account={account} />
                </AdminCard>

                {account?.accountType === "BRAND" && account.dataClient === true && (
                  <>
                    <AdminCard title="Channels Configuration">
                      <AdminAccountEnablementChannels account={account} />
                    </AdminCard>
                  </>
                )}
              </MinMaxGrid>

              <div style={{ marginTop: "16px" }}>
                {account?.accountType === "BRAND" && account.dataClient === true && (
                  <>
                    <AdminCard title="Audiences" icon="Audience">
                      <AdminAccountAudiences accountId={account.id} />
                    </AdminCard>
                  </>
                )}
              </div>
            </Content>
          </AdminWrap>
        )}
      </MainFrame>
    </>
  );
};

export const PropertiesList = styled("ul")((props) => ({
  "& li": {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    color: "#7587A3",
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingRight: "7px",
    borderTop: "1px solid #eee",
    textAlign: "right",
    "&:first-of-type": {
      paddingTop: "0px",
      borderTop: "0",
    },
    "& span": {
      fontWeight: "600",
      textAlign: "left",
      color: "#525d70",
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
  },
}));
const Error = styled("div")((props) => ({
  position: "absolute",
  inset: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1.2rem",
  color: "#e96363",
}));

const MinMaxGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
  gap: "24px",
  alignItems: "start",
});
