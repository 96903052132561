import {TEntityName} from '../../application/entities/dataTypes';
import {useCrudOpSlice} from '../../redux/slices/crudmsg.slices';
import {dataUtils} from '../../application/utils/dataState.utils';
import {TCrudOp} from '../../application/entities/api/apiService';

export interface IUseApiProgressOpt {
  operation?: TCrudOp
  callsSteps?: string
  progress?: 'RUNNING' | 'STOPPED'
  queryId?: string,
}

export const useApiProgress = (entity: TEntityName, options?: IUseApiProgressOpt) => {
  const defaultOptions: Partial<IUseApiProgressOpt> = {
    operation: 'get'
  }

  const finalOptions = {...defaultOptions, ...options}
  const crudOp       = useCrudOpSlice();

  let callsSteps = dataUtils.getObjectByFieldValue(Object.values(crudOp), 'entity', entity);
  callsSteps     = dataUtils.getObjectByFieldValue(callsSteps, 'operation', finalOptions.operation);
  if (finalOptions.queryId) callsSteps = dataUtils.getObjectByFieldValue(callsSteps, 'queryId', finalOptions.queryId);
  if (finalOptions.progress) callsSteps = dataUtils.getObjectByFieldValue(callsSteps, 'progress', finalOptions.progress);

  return callsSteps
}