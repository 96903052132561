import {objectApi} from '../../../application/entities/dataApi';
import {useQuery} from 'react-query';
import {BackendError} from '../../../types/backendError';
import {useQueryProps} from './useAudiencesQuery';

export const useCompanyBrandRoles = (accountId: number,brandId: number,options: useQueryProps = {} as useQueryProps, hookOpt: Record<string, any> = {}) => {
  
  const {params = {}, pageSize = 50, pageNumber = 0, sortBy = 'firstName', sortDir = 'desc'} = options;
  const accountsApi = new objectApi.accounts();
  //
  return useQuery<any, BackendError>([brandId,'brandRoles', options], () => {
    return accountsApi
    .getBrandRoles(accountId, brandId,{params, page: {pageSize, pageNumber}, sort: {sortBy, sortDir}})
    .then(res => res);
  }, {
    enabled: Boolean(brandId),
    ...hookOpt,
    keepPreviousData: false,
    cacheTime       : 0
  });
};