import {TDataColumnModel} from '../../utils/dataColumnModel.utils';
import {GridValueGetterParams} from '@mui/x-data-grid';
import {Permission} from '../../../types/Permission';
import {ICompanyUsers} from '../dataTypes/companyUser';
import {EUserGrade, accountTypeName} from '../../utils/granter.utils';

function getFullName(params: any) {
  return `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }`;
}

const flexThree = 3;

export const companyUsersColumns: TDataColumnModel = {
  id           : {
    field        : 'id', headerName: 'User ID',
    width        : 130,
    align        : 'center', headerAlign: 'center',
    type         : 'string',
    cellClassName: 'titleCell minCell',
  },
  fullName     : {
    field         : 'name',
    headerName    : 'Name',
    description   : 'This column is not sortable yet.',
    sortable      : true,
    flex          : flexThree,
    minWidth      : 130,
    align         : 'left',
    headerAlign   : 'left',
    type          : 'avatar',
    valueGetter   : (params: GridValueGetterParams<ICompanyUsers>) =>
      `${params.row.firstName || ''} ${
        params.row.lastName || ' '
      }`,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      getFullName(cellParams1)
      .localeCompare(getFullName(cellParams2)),
  },
  email      : {
    field      : 'email',
    headerName    : 'Email',
    minWidth      : 250,
    align      : 'left', 
    headerAlign: 'left',
    type       : 'string',
    
  },
  companyName  : {
    field      : 'companyName',
    headerName : 'Company Name',
    flex       : flexThree,
    align      : 'left',
    headerAlign: 'left',
    type       : 'logo',
    valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
      const account = params.row.account;
      return account?.companyName;
    }
  },
  role      : {
    field      : 'role',
    headerName    : 'Company Role',
    width      : 180,
    align      : 'left', 
    headerAlign: 'left',
    type       : 'string',
    valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
      const user = params.row;
      return accountTypeName[user.role as EUserGrade]
    }
    
  },
  country      : {
    field      : 'country', headerName: 'Country',
    width      : 110,
    align      : 'center', headerAlign: 'center',
    type       : 'string',
    valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
      const account = params.row.account;
      return account?.country;
    }
  },
  // Administrator: {
  //   field      : 'Administrator',
  //   headerName : 'Administrator',
  //   align      : 'center',
  //   headerAlign: 'center',
  //   type       : 'boolean',
  //   width      : 150,
  //   valueGetter: (params: GridValueGetterParams) => {
  //     return Boolean(params.row.role === EUserGrade.ADMINISTRATOR);
  //   },
  // },
  // Creator      : {
  //   field      : 'Creator', headerName: 'Creator',
  //   align      : 'center', headerAlign: 'center',
  //   type       : 'boolean',
  //   width      : 110,
  //   valueGetter: (params: GridValueGetterParams) => {
  //     return Boolean(params.row.role === EUserGrade.CREATOR);
  //   },
  // },
  // contributor  : {
  //   field      : 'contributor',
  //   headerName : 'Contributor',
  //   align      : 'center',
  //   headerAlign: 'center',
  //   type       : 'boolean',
  //   width      : 150,
  //   valueGetter: (params: GridValueGetterParams) => {
  //     return Boolean(params.row.role === EUserGrade.CONTRIBUTOR);
  //   },
  // },
};
