import { Avatar, Typography, styled } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ICompanyUsers } from "application/entities/dataTypes/companyUser";
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface IGlobalAvatarProps {
  user?: ICompanyUsers | Partial<ICompanyUsers>;
  logoVal?: string;
  isPending?: boolean;
  size: string;
  fontSize: string;
  showText?: boolean;
}

export const GlobalAvatar = (props: IGlobalAvatarProps) => {
  if (props.user) return CreateUserAvatar(props);
  if (props.logoVal) return CreateLogoAvatar(props);
  return <></>;
};

export const GlobalAvatarLight = (props: IGlobalAvatarProps) => {
  if (props.user) return CreateUserAvatarLight(props);
  return <></>;
};

function CreateLogoAvatar(props: IGlobalAvatarProps) {
  const { logoVal, size, fontSize, showText } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar data-cy={"avatar-logo"} className={classes.logoContainer} style={{ width: size, height: size, fontSize: fontSize }}>
        <Avatar className={classes.logo}>{logoVal?.slice(0, 1).toUpperCase()}</Avatar>
      </Avatar>
      <Typography className={classes.typo}>{Boolean(showText) ? logoVal : ""}</Typography>
    </div>
  );
}

function CreateUserAvatar(props: IGlobalAvatarProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, size, fontSize, showText } = props;
  const isPending = user?.status !== "ACTIVATED" ? true : false;
  const color = isPending ? "#D3D3D3" : randomColour(user?.email);
  const title = isPending
    ? t("account.accountPending.title")
    : (user?.title ?? "") + " " + (user?.firstName && user?.lastName ? user?.firstName + " " + user?.lastName : user?.email);
  let text = isPending ? t("account.accountPending.message") : user?.jobTitle ? user.jobTitle : "";
  const initial = user?.firstName ? user?.firstName.slice(0, 1).toUpperCase() : user?.email?.slice(0, 1).toUpperCase();

  if (user?.lastLogin) {
    text += `\n Last login: ${moment(user?.lastLogin).add(1, "hour").format("D-MMM-YYYY h:mm A")}`;
  }

  return (
    <div className={classes.root}>
      <AppTip title={title} text={text}>
        <Avatar
          data-cy={"avatar-user"}
          className={classes.medium}
          alt="avatar"
          style={{ background: color, width: size, height: size, fontSize: fontSize }}
        >
          {initial}
          {isPending ? <AvatarInActiveDot> </AvatarInActiveDot> : ""}
        </Avatar>
      </AppTip>
      <Typography className={classes.typo}>
        {Boolean(showText) ? (user?.title ?? "") + " " + (user?.firstName ?? "") + " " + (user?.lastName ?? "") : ""}
      </Typography>
    </div>
  );
}

function CreateUserAvatarLight(props: IGlobalAvatarProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, size, fontSize, showText } = props;
  const isPending = user?.status !== "ACTIVATED" ? true : false;
  const color = randomColour(user?.email);
  const title = isPending
    ? t("account.accountPending.title")
    : (user?.title ?? "") + " " + (user?.firstName && user?.lastName ? user?.firstName + " " + user?.lastName : user?.email);
  const text = isPending ? t("account.accountPending.message") : user?.jobTitle ? user.jobTitle : "";
  const initial = user?.firstName ? user?.firstName.slice(0, 1).toUpperCase() : user?.email?.slice(0, 1).toUpperCase();

  return (
    <div className={classes.root}>
      <Avatar
        data-cy={"avatar-user"}
        className={classes.medium}
        alt="avatar"
        style={{ background: color, width: size, height: size, fontSize: fontSize }}
      >
        {initial}
      </Avatar>

      <Typography className={classes.typo}>{Boolean(showText) ? (user?.firstName ?? "") + " " + (user?.lastName ?? "") : ""}</Typography>
    </div>
  );
}

var randomColour = function (email?: string) {
  //list from https://www.figma.com/file/yK8qyau88850QX868x4LTl/Evorra---VD?node-id=6415%3A29494&t=OLGMOMnZnQLz2e9w-0
  if (typeof email === "undefined") return;
  const colorsList = [
    "#576FAD",
    "#45BAB3",
    "#4DABEF",
    "#217CD0",
    "#41A51F",
    "#E4C20C",
    "#F08F35",
    "#EE734C",
    "#6E5DDA",
    "#A877CF",
    "#D255D3",
    "#D82686",
    "#1C1C1C",
    "#994B9A",
    "#F33992",
    "#DA2318",
    "#F25532",
    "#5E879E",
    "#FFB800",
    "#62C93E",
    "#53606B",
    "#1799F8",
    "#23C6D0",
    "#3661D0",
  ];
  var hash = 0;
  for (var i = 0; i < email.length; i++) {
    hash = email.charCodeAt(i) + ((hash << 5) - hash);
  }
  var number = Math.abs(hash % 22);

  return colorsList[number];
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
    medium: {
      margin: "0px",
      padding: "0px",
      boxShadow: "0px 0px 3px rgba(222, 222, 222, 0.85)",
      color: "#FFFFFF",
      borderTop: "1px transparent solid",
      overflow: "visible",
    },
    typo: {
      display: "flex",
      alignItems: "center",
      marginLeft: "5px",
    },
    logoContainer: {
      background: "linear-gradient(180deg, #4262FF 19.27%, #37DEF9 72.92%)",
      borderRadius: "50%",
    },
    logo: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 3px rgba(222, 222, 222, 0.85)",
      color: "#3B4559",
      height: "90%",
      width: "90%",
    },
  })
);

const AvatarInActiveDot = styled(Avatar)({
  background: "grey",
  border: "5px",
  height: "45%",
  width: "45%",
  marginTop: "65%",
  marginLeft: "65%",
  position: "absolute",
});
