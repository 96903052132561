import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import React from "react";
import {PopupSelectList} from "../../../../components/form/PopupSelectList";

export interface FeatureTypeTemplateUrlListType {
    featureCode: string;
}

export const InputTemplateUrlList = (props: FeatureTypeTemplateUrlListType) => {
    const {featureCode} = props;
    const audienceActions = useAudienceBuilderStore.getState().actions;

    const accountId = useAudienceBuilderStore.getState().audience.account.id;

    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];
    const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode).feature;


    let mainPanelHandle = React.useRef<React.ElementRef<typeof PopupSelectList>>(null);
    const inputRef = React.useRef<null | any>();

    const handleChange = (featureValue: string[]) => {
        if (featureValue?.length !== undefined) {
            audienceActions.insertFeatureValues(feature, featureValue);
        }
    };

    return (
        <PopupSelectList
            selectedItems={filtersValues.selected}
            onChange={handleChange}
            text={`Select ${feature.name}`}
            field={"key"}
            ref={mainPanelHandle}
            inputRef={inputRef}
            items={filtersValues.selectable || []}
            accountId={+accountId}
        />
    );
};
