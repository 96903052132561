import EvorraLogo from "assets/images/Evorra Logo.png";

const domainsAssets = [
  {
    faviconUrl: "/the7stars.ico",
    logo: "/the7stars.png",
    subdomain: "the7stars",
    loginBackgroundAlternative: true,
  },
  {
    faviconUrl: "/nunnmedia.ico",
    logo: "/nunnmedia.png",
    subdomain: "nunnmedia",
    loginBackgroundAlternative: true,
  },
  {
    faviconUrl: "/emdatamesh.ico",
    logo: "/emdatamesh.png",
    subdomain: "emdatamesh",
    loginBackgroundAlternative: true,
  },
  {
    faviconUrl: "/vervegroup.ico",
    logo: "/vervegroup.png",
    subdomain: "vervegroup",
    loginBackgroundAlternative: true,
  },
  {
    faviconUrl: "/dunnhumby.ico",
    logo: "/dunnhumby.png",
    subdomain: "dunnhumby",
    loginBackgroundAlternative: true,
  },
];

const subdomain = window.location.hostname.split(".")[0];

export const getFaviconUrl = () => {
  const assets = domainsAssets.find((asset) => asset.subdomain.startsWith(subdomain));
  if (assets) return assets.faviconUrl;
  return "/favicon.ico";
};

export const getLogoLogin = () => {
  const assets = domainsAssets.find((asset) => asset.subdomain.startsWith(subdomain));
  if (assets) return assets.logo;
  return null;
};

export const getLogoHeader = () => {
  const assets = domainsAssets.find((asset) => asset.subdomain.startsWith(subdomain));
  if (assets) return assets.logo;
  return EvorraLogo;
};

export const getLoginBackgroundAlternative = () => {
  const assets = domainsAssets.find((asset) => asset.subdomain.startsWith(subdomain));
  if (assets) return assets.loginBackgroundAlternative;
  return false;
};

export const getSubdomainList = () => {
  return domainsAssets.map((domain) => domain.subdomain);
};
