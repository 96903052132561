import {DialogWindow} from 'components/live/DialogWindow';
import React from 'react';
import styled from 'styled-components/macro';
import {withAuthentication} from './components/features/Authentication';
import {Header} from './components/interface/Header';
import {AppToast} from './components/live/AppToast';
import {MessageBanner} from './components/live/MessageBanner';
import {AppRoutes} from './components/routes';
import {Columns, GridItemFull, Stacker} from 'components/ui/AppElements';
import {createAppTheme} from './styles/theme';
import {ThemeProvider} from '@mui/material';
import {Tour} from './components/live/Tour';
import {useUiStore} from './redux/ui.store';
import {NavBarLeftAgencies} from './components/interface/NavBarLeft/NavBarLeftAgencies';
import {withQueryClient} from './components/hooks/withQueryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

const App = () => {
  const themeMode     = useUiStore(st => st.mode); 
  
  
  let newTheme = createAppTheme({
    palette: {mode: themeMode}
  });
 
  
  return (
    <ThemeProvider theme={newTheme}>
      <DndProvider backend={HTML5Backend}>
        <AppContainer>
          <MessageBanner/>
          <AppToast toastId={'manageAccount'}/>
          <AppToast toastId={'globalToast'}/>
          <Header/>
          <AppContainerBox>
            <AppContainerBody>
              <NavBarLeftAgencies/>
              <AppContainerInner>
                <AppRoutes/>
              </AppContainerInner>
            </AppContainerBody>
          </AppContainerBox>
        </AppContainer>
        <DialogWindow
          open={false}/>
        <Tour/>
        <ReactQueryDevtools />
      </DndProvider>
    </ThemeProvider>
  );
};


export default withQueryClient(withAuthentication(App));

const AppContainer = styled(Stacker)({
  height   : '100%',
  maxHeight: '100%',
  overflow : 'hidden',
  position : 'relative',
});

const AppContainerBody = styled(Columns)({
  height   : '100%',
  maxHeight: '100%',
  overflow : 'hidden',
});

const AppContainerBox = styled(GridItemFull)({
  height   : '100%',
  maxHeight: '100%',
  overflow : 'hidden',
});

// @anna : switch to auto to work later on scrolling issues
const AppContainerInner = styled(GridItemFull)({
  overflow: 'hidden',
});
