import { IAudienceFeatureDto } from "application/entities/dataTypes/audienceFeature";
import { TFeatureTypesCode } from "application/entities/dataTypes/featureTypes";
import { IFeaturesDto } from "application/entities/dataTypes/features";
import { dataUtils, getObjectByFieldValue } from "application/utils/dataState.utils";
import { TypeSliderConfig } from "./configuration";
import { profilesFormatter } from "_configuration/formaters";
import { moneyFormatter } from "application/utils/formatters.utils";

export type PathDataType = {
  name: string;
  path: string;
  data: Record<string, any>;
};

/** accepts array of string or array of path with linked data */
export type PathsType = PathDataType[];

export interface TreeItemType {
  name: string;
  path: string;
  data?: Record<string, any>;
  children: TreeItemType[];
}

// Move
export const trans2Tree = (paths: PathsType, pathKey: string = "path"): TreeItemType[] => {
  const tree: TreeItemType[] = [];

  for (let i = 0; i < paths.length; i++) {
    //@ts-ignore
    const path = paths[i][pathKey] ?? "";
    const pathSplice = path.split("/");

    let currentLevel = tree;
    let currentPath: string[] = [];
    for (let j = 0; j < pathSplice.length; j++) {
      const part: string = pathSplice[j];
      currentPath.push(part);
      const existingPath = currentLevel.find((k) => k.name === part);
      // console.log({part})
      if (existingPath) {
        currentLevel = existingPath.children;
      } else {
        const newPart = {
          name: part,
          path: currentPath.join("/"),
          data: dataUtils.getObjectByFieldValue(paths, "path", currentPath.join("/"))[0],
          children: [],
        };

        currentLevel.push(newPart);
        currentLevel = newPart.children;
      }
    }
  }
  return tree;
};

// Move
export function buildTreeData(entityList: Record<string, any>[], defaultField: string, path?: string) {
  const parentality = [] as any;

  // console.log({result});

  entityList.forEach((entityData: any) => {
    if (entityData[defaultField]) {
      const spl = entityData[path ?? defaultField].split("/");
      let lastPiece: string;
      spl.forEach((piece: string) => {
        const test = getObjectByFieldValue<any>(parentality, "name", piece).length;
        if (!test) {
          parentality.push({
            name: piece,
            parent: lastPiece,
            path: entityData[path ?? defaultField],
            code: piece.replaceAll(" ", ""),
            entityData,
          });
        }

        lastPiece = piece;
      });
    }
  });

  // return parentality;
  return nestDirectories(parentality);
}

export function nestDirectories(directories: any, parent?: string) {
  let node: any = [];
  directories
    .filter(function (d: any) {
      return d.parent === parent;
    })
    .forEach(function (d: any) {
      const cd = d;
      cd.children = nestDirectories(directories, d.name);
      return node.push(cd);
    });
  return node;
}

export function flattenTreeListData(ob: TreeItemType) {
  const ret: any = [];
  if (ob["data"]) ret.push(ob["data"]);
  if (ob["children"]) {
    for (const val of ob["children"]) {
      ret.push(flattenTreeListData(val));
    }
  }

  return ret.flat();
}

export function jsonDecodeArray<T = any>(arr: string[]): T[] {
  return arr.map((what: any) => {
    try {
      return JSON.parse(what);
    } catch (e) {}
  });
}

export function jsonifyArray(arr: any[]): string[] {
  return arr.map((what: any) => {
    return JSON.stringify(what);
  });
}

export const transformArrayToTree = (arr: any) => {
  const tree = [];
  const map: any = {};
  let node: any;
  for (let i = 0; i < arr.length; i += 1) {
    node = {
      id: arr[i].id,
      name: arr[i].name,
    };
    if (arr[i].name.indexOf("/") > -1) {
      const parts = arr[i].name.split("/");
      node.name = parts[0];
      node.children = [];
      for (let j = 1; j < parts.length; j += 1) {
        const childNode = {
          id: arr[i].id + j,
          pid: arr[i].id + j - 1,
          name: parts[j],
        };
        node.children.push(childNode);
        map[arr[i].id + j] = childNode;
      }
    }
    map[arr[i].id] = node;

    if (arr[i].name.indexOf("/") === -1) {
      tree.push(node);
    }
  }
  for (const key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      const childNode = map[key];
      if (childNode.pid) {
        const parentNode = map[childNode.pid];
        if (!parentNode.children) {
          parentNode.children = [];
        }
        parentNode.children.push(childNode);
      }
    }
  }
  return tree;
};

export const countChildrenAndSubChildren = (treeListItem: TreeItemType) => {
  const countChildren = (treeListItem: TreeItemType) => {
    if (treeListItem?.children?.length > 0) {
      treeListItem?.children?.map((item: TreeItemType) => {
        return countChildren(item);
      });
    } else {
      childrenAndSubChildrenCount++;
    }
    return childrenAndSubChildrenCount;
  };
  let childrenAndSubChildrenCount = 0;
  return countChildren(treeListItem);
};

export const orderArrayStringSelected = (selectable: any[], selected: any[]) => {
  const result = [];
  for (let i = 0; i < selectable.length; i++) {
    const index = selected.indexOf(selectable[i].value);
    if (index !== -1) {
      result.push(selected[index]);
    }
  }
  return result;
};

export const buildSliderMarkerLabel = (value: number, config: TypeSliderConfig) => {
  if (config.type === "money") {
    if (value === 0) return config.abreviation + value;
    return config.abreviation + value / 1000 + "k";
  } else {
    //number
    return value + config.abreviation;
  }
};

type TypeSliderDisplayValue = {
  type: "less" | "more" | "range" | "all";
  value?: { min?: number; max?: number };
  config: TypeSliderConfig;
};

export const buildSliderDisplayValue = (props: TypeSliderDisplayValue) => {
  const { type, value, config } = props;
  if (type === "all") {
    return "All " + config.displayAll;
  }

  if (type === "less") {
    if (config.type === "money" && value?.max) {
      let max = moneyFormatter(value.max - 1, { maxDigits: 0, hideCurrency: true });
      return "Less than " + config.abreviation + max;
    } else if (config.type === "number" && value?.max) {
      let max = profilesFormatter.format(value.max);
      return "Less than " + max + " " + (value.max > 1 ? config.plurial : config.singular);
    }
  }

  if (type === "more") {
    if (config.type === "money" && value?.min) {
      let min = moneyFormatter(value.min, { maxDigits: 0, hideCurrency: true });
      return "More than " + config.abreviation + min;
    } else if (config.type === "number" && value?.min) {
      let min = profilesFormatter.format(value.min);
      return "More than " + min + " " + (value.min > 1 ? config.plurial : config.singular);
    }
  }

  if (type === "range") {
    if (config.type === "money" && value?.min && value?.max) {
      let min = moneyFormatter(value.min, { maxDigits: 0, hideCurrency: true });
      let max = moneyFormatter(value.max - 1, { maxDigits: 0, hideCurrency: true });
      return "From " + config.abreviation + min + " to " + config.abreviation + max;
    } else if (config.type === "number" && value?.min && value?.max) {
      let min = profilesFormatter.format(value.min);
      let max = profilesFormatter.format(value.max);
      return "Between " + min + " to " + max + " " + (value.min > 1 ? config.plurial : config.singular);
    }
  }
  return "";
};
