import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {getDspAdditionalInfo, getDspIcon} from "application/utils/dsp.utils";
import {ChevronDownBlue} from "assets/icons";
import {providerIconList} from "assets/providerIcons";
import {useSelectedDspChannels} from "components/hooks/data/useDspChannelsQuery";
import {useAppToast} from "components/live/AppToast";
import {AppIcon} from "components/ui/AppIcon";
import React, {useEffect, useState} from "react";
import {useQueryClient} from "react-query";
import {useNavigate} from "react-router";
import {useChannelsSetupStore} from "../ChannelsSetupStore";

export interface IChannelConfig {
    seatName: string;
    isActive: boolean;
    sendMethods: string[];
    parameterValues?: { [key: string]: string };
}

export const ChannelConfiguration = (props: { channelConfig?: IChannelConfig }) => {
    const channelConfig = props.channelConfig;
    const dspData = useChannelsSetupStore().dspSelected;
    const setStep = useChannelsSetupStore().setCurrentStep;
    const step = useChannelsSetupStore().currentStep;
    const accountId = useChannelsSetupStore().accountId;
    const navigate = useNavigate();
    const appToast = useAppToast();
    const accountsApi = new objectApi.accounts();

    const [errors, setErrors] = useState<string[]>([]);
    const [apiLoad, setApiLoad] = useState<boolean>(false);

    const [sendMethodsSelected, setSendMethodsSelected] = useState<string[]>(channelConfig?.sendMethods ?? []);

    const [parametersValues, setParametersValues] = useState<{
        [key: string]: string
    }>(props.channelConfig?.parameterValues ?? {});

    const [seatName, setSeatName] = useState<string>(channelConfig?.seatName ?? "");

    const queryClient = useQueryClient();

    const channelsParameters = sendMethodsSelected?.map((sendMethod: string) => {
        const dspChannelParameters = dspData?.dspEnablementOptions.find((option) => option.code === sendMethod)?.dspChannelParameters;
        return {
            sendMethodCode: sendMethod,
            parametersRequiredCode: dspChannelParameters?.map((param) => param.code),
        };
    });

    const dspChannelsSelected = useSelectedDspChannels(accountId!);

    const paramsToShow = () => {
        let res: string[] = [];

        sendMethodsSelected.forEach((sendMethodCode: string) => {
            const params = channelsParameters?.find((param) => param.sendMethodCode === sendMethodCode)?.parametersRequiredCode;
            if (params) res = [...res, ...params];
        });

        return [...new Set(res)];
    };

    useEffect(() => {
        if (sendMethodsSelected.includes("amazonads")) {
            setParametersValues((state) => ({...state, region: "EU"}));
        }
    }, [sendMethodsSelected]);

    const displayChannelsParametersBox = () => {
        if (sendMethodsSelected.length === 0) {
            return false;
        }
        let show = false;
        dspData?.dspParameters.forEach((dspParam) => {
            if (paramsToShow().includes(dspParam.code)) {
                show = true;
            }
        });
        return show;
    };

    const submitStep = () => {
        setApiLoad(() => true);
        setErrors((state) => []);

        const params = {
            isActive: true,
            dspChannelCode: dspData!.code,
            dspEnablementOptions: sendMethodsSelected,
            parameterValues: parametersValues,
            seatName: seatName,
        };

        accountsApi
            .checkDspChannelsSelection(accountId!, {
                dspChannelCode: dspData!.code,
                parameterValues: parametersValues,
            })
            .then((res) => {
                //check parameters errors
                const parametersStatus = res.data.dspOptionsStatus;
                if (parametersStatus?.advertiserId === "ERROR" || parametersStatus?.partnerId === "ERROR") {
                    if (parametersStatus?.advertiserId === "ERROR") setErrors((state) => [...state, "advertiserId"]);
                    if (parametersStatus?.partnerId === "ERROR") setErrors((state) => [...state, "partnerId"]);
                    setApiLoad(() => false);
                } else {
                    //save dsp
                    accountsApi.createUpdateDspChannelsSelections(accountId!, params).then((res) => {
                        setErrors((state) => []);
                        queryClient.invalidateQueries({queryKey: ["dspChannels"]});
                        dspChannelsSelected.refetch().then(() => {
                            navigate(pageUtils.getPagePathById(PageId.channelsSetup, {accountId: accountId}));
                        });
                    });
                }
            })
            .catch((err) => {
                setApiLoad(() => false);
                appToast.open({
                    toastId: "globalToast",
                    severity: "error",
                    message: "Something went wrong, please retry later",
                });
            });
    };

    const isFormValid = () => {
        if (dspData?.dspEnablementOptions?.length && !sendMethodsSelected.length) {
            return false;
        }
        let isValid: boolean[] = [];
        paramsToShow().forEach((param) => {
            isValid.push(parametersValues[param]?.length > 0 ? true : false);
        });
        if (isValid.includes(false)) {
            return false;
        }
        return true;
    };

    const getRequiredCause = (paramCode: string) => {
        let causes: string[] = [];

        channelsParameters?.forEach((param) => {
            if (param.parametersRequiredCode?.includes(paramCode)) {
                const enablementOptionCode = param.sendMethodCode;
                const dspEnablementOption = dspData?.dspEnablementOptions.find((option) => option.code === enablementOptionCode);
                if (dspEnablementOption && sendMethodsSelected.includes(dspEnablementOption.code)) {
                    causes.push(getDspAdditionalInfo(dspEnablementOption.code)?.name ?? dspEnablementOption.name);
                }
            }
        });
        return causes;
    };


    //clean unused parameters
    Object.keys(parametersValues).forEach((key) => {
        if (!paramsToShow().includes(key)) {
            setParametersValues((state) => {
                delete state[key];
                return state;
            })
        }
    })

    const awsCurrencyList = [
        {label: "AED", value: "AED"},
        {label: "AUD", value: "AUD"},
        {label: "BRL", value: "BRL"},
        {label: "CAD", value: "CAD"},
        {label: "CHF", value: "CHF"},
        {label: "CNY", value: "CNY"},
        {label: "DKK", value: "DKK"},
        {label: "EUR", value: "EUR"},
        {label: "GBP", value: "GBP"},
        {label: "INR", value: "INR"},
        {label: "JPY", value: "JPY"},
        {label: "MXN", value: "MXN"},
        {label: "NOK", value: "NOK"},
        {label: "SAR", value: "SAR"},
        {label: "SEK", value: "SEK"},
        {label: "SGD", value: "SGD"},
        {label: "TRY", value: "TRY"},
        {label: "USD", value: "USD"},
    ];

    return (
        <>
            {dspData && (
                <Box sx={{opacity: apiLoad ? 0.5 : 1}}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Box sx={{display: "flex", gap: "1rem", alignItems: "center"}} mb={2}>
                            <AppIcon className="dsp-logo" fontSize={"medium"} icon={providerIconList[dspData!.code]}/>
                            <Typography variant="h2">{dspData?.name}</Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={() => (step === 2 ? setStep(1) : navigate(pageUtils.getPagePathById(PageId.channelsSetup, {accountId: accountId})))}
                        >
                            Back
                        </Button>
                    </Box>
                    <Divider/>
                    <Typography variant="h4" mb={2} mt={2}>
                        Seat Name
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Provide a name for this channel (optional). This name will be used to identify this seat in the
                        audience enablement process.
                    </Typography>

                    <Box>
                        <StyledLabel>Seat Name</StyledLabel>
                        <TextField
                            type={"text"}
                            required={true}
                            name="seatName"
                            role={"presentation"}
                            style={{width: 224}}
                            inputProps={{"data-cy": "seatName"}}
                            value={seatName}
                            onChange={(e) => setSeatName(e.target.value)}
                        />
                    </Box>

                    <Divider sx={{marginBlock: "1rem"}}/>

                    <Typography variant="h4" mb={2} mt={2}>
                        Enablement Methods
                    </Typography>

                    <Typography variant="body1" mb={2}>
                        Select the enablement method(s) you would like to use to send data to this channel. Some methods
                        will need extra parameters to be
                        configured.
                    </Typography>

                    {dspData?.dspEnablementOptions.map((option, index) => {
                        return (
                            <div key={option.code}
                                 style={{marginBottom: "1rem", display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                <Checkbox
                                    data-cy={`checkbox-${option.code}`}
                                    size={"small"}
                                    name={option.name}
                                    checked={sendMethodsSelected.includes(option.code)}
                                    onChange={(e) => {
                                        if (sendMethodsSelected.includes(option.code)) {
                                            setSendMethodsSelected((state) => state.filter((method) => method !== option.code));
                                        } else {
                                            setSendMethodsSelected((state) => [...state, option.code]);
                                        }
                                    }}
                                />
                                <AppIcon className="dsp-logo" fontSize={"small"} icon={getDspIcon(option.code)}/>
                                <Typography variant="body1">
                                    {getDspAdditionalInfo(option.code)?.name ?? option.name}
                                    {getDspAdditionalInfo(option.code)?.restriction && (
                                        <span className="color-tech-grey" style={{fontStyle: "italic"}}>
                      {" "}
                                            - {getDspAdditionalInfo(option.code)?.restriction}
                    </span>
                                    )}
                                </Typography>
                            </div>
                        );
                    })}

                    <Collapse orientation="vertical" in={displayChannelsParametersBox()}>
                        <Divider sx={{marginBlock: "1rem"}}/>
                        <Typography variant="h4" mb={2} mt={2}>
                            Channels parameters
                        </Typography>

                        <Box sx={{
                            marginTop: "1rem",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            alignItems: "start"
                        }}>
                            {dspData?.dspParameters.map((param) => {
                                if (paramsToShow().includes(param.code)) {
                                    return (
                                        <div key={param.id}>
                                            {param.code === "currency" ? (
                                                <Box>
                                                    <StyledLabel htmlFor={param.parameterName}>Amazon Ads Account
                                                        Currency</StyledLabel>

                                                    <Select
                                                        data-cy={"select-accout-type"}
                                                        role="select"
                                                        sx={{
                                                            width: "224px",
                                                            ".MuiSelect-select": {color: "#7587A3 !important"}
                                                        }}
                                                        onChange={(e: any) => {
                                                            setParametersValues((state) => ({
                                                                ...state,
                                                                [param.code]: e.target.value
                                                            }));
                                                        }}
                                                        value={parametersValues[param.code]}
                                                        IconComponent={ChevronDownBlue}
                                                        error={errors.includes(param.code)}
                                                    >
                                                        {awsCurrencyList.map((currency) => {
                                                            return (
                                                                <MenuItem key={currency.value} role="option"
                                                                          value={currency.value}>
                                                                    {currency.label}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <StyledLabel htmlFor={param.parameterName}>
                                                        {param.code === "region" ? "Amazon Ads Account Region" : param.parameterName}
                                                    </StyledLabel>
                                                    <TextField
                                                        type={"text"}
                                                        name={param.code}
                                                        required={true}
                                                        onChange={(e) => {
                                                            setParametersValues((state) => ({
                                                                ...state,
                                                                [param.code]: e.target.value
                                                            }));
                                                        }}
                                                        role={"presentation"}
                                                        style={{width: 224}}
                                                        inputProps={{"data-cy": param.code}}
                                                        value={parametersValues[param.code]}
                                                        error={errors.includes(param.code)}
                                                        helperText={
                                                            (errors.includes(param.code) && "Invalid value") ||
                                                            (param.code === "region" && "If you need to set another region, please Contact your Evorra Account Manager.")
                                                        }
                                                        disabled={param.code === "region"}
                                                    />
                                                </Box>
                                            )}
                                            <Box mt={1}>
                                                <Typography className="color-tech-grey" variant="body1"
                                                            sx={{display: "flex", gap: "0.25rem"}}>
                                                    <AppIcon icon="Info" fontSize="small"/>
                                                    Required because you have selected:
                                                </Typography>

                                                {getRequiredCause(param.code)?.map((cause) => {
                                                    return (
                                                        <Typography key={cause} className="color-tech-grey"
                                                                    variant="body1" sx={{paddingLeft: "1.5rem"}}>
                                                            - {cause}
                                                        </Typography>
                                                    );
                                                })}
                                            </Box>
                                        </div>
                                    );
                                }
                                return <React.Fragment key={"no-params"}></React.Fragment>;
                            })}
                        </Box>
                    </Collapse>

                    <Box sx={{marginTop: "1.5rem", textAlign: "center"}}>
                        <Button data-cy="save-btn" variant="contained" disabled={!isFormValid()} onClick={submitStep}>
                            {apiLoad ? <CircularProgress size={24} color="inherit" style={{color: "#fff"}}/> : "Save"}
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

const StyledLabel = styled(InputLabel)(() => ({
    fontWeight: 500,
    color: "#7587A3",
    marginBottom: "0.5rem",
}));
