import { Box, TextField } from "@mui/material";
import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useAudienceEnablementStore } from "../../store/AudienceEnablement.store";
import { objectApi } from "application/entities/dataApi";
import { AUTHUser } from "application/utils/AuthUser";
import { IconSet } from "assets/icons";
import { GridMiddle } from "components/ui/AppElements";
import { ICountry } from "types";
import { Input, InputLabel } from "components/form/Input";

const countriesApi = new objectApi.countries();
export const BillingAddress = () => {
  const { t } = useTranslation();
  const billingAddress = useAudienceEnablementStore((st) => st.billingAddress);

  const [countryList, setCountryList] = React.useState<ICountry[]>([]);

  const predefinedCountry = countryList.find((item) => item.code === billingAddress?.country) ?? {
    name: "",
    code: "",
    continentCode: "",
    continentName: "",
    path: "",
  };

  React.useEffect(() => {
    const account = AUTHUser.getUserAccount();
    countriesApi
      .get()
      .then((res) => {
        const { countries } = res.data._embedded;
        countries && setCountryList(countries);
      })
      .catch((err) => {
        // console.log(err);
      });
    useAudienceEnablementStore.setState({
      billingAddress: {
        city: account?.city,
        country: account?.country,
        line1: account?.streetAddress,
        postal_code: account?.postalCode,
      },
    });
  }, []);

  function setInputVal(event: any) {
    const node = event.currentTarget;
    const field = node.name;
    const value = node.value;
    useAudienceEnablementStore.setState({ billingAddress: { ...billingAddress, [field]: value } });
  }

  const errors = [];

  if (billingAddress?.line1?.length < 2) {
    errors.push("Street address is required");
  }
  if (billingAddress?.city?.length < 2) {
    errors.push("City is required");
  }
  if (billingAddress?.country?.length < 2 || billingAddress?.country === undefined) {
    errors.push("Country is required");
  }
  if (billingAddress?.postal_code?.length < 2) {
    errors.push("Postal code is required");
  }
  return (
    <Box data-cy={"billing-address"}>
      {errors.length > 0 && (
        <Box>
          {errors.map((error) => (
            <Box sx={{ color: "error.main" }}>{error}</Box>
          ))}
        </Box>
      )}
      <Box sx={{ paddingBlock: 1 }}>
        <Input
          onChange={setInputVal}
          data-cy={"billing-street"}
          name={"line1"}
          value={billingAddress.line1}
          label={t("payment.billingAddress.street")}
          placeholder={t("payment.billingAddress.street")}
        />
      </Box>
      <Box sx={{ paddingBlock: 1 }}>
        <Input
          onChange={setInputVal}
          data-cy={"billing-city"}
          name={"city"}
          value={billingAddress.city}
          label={t("payment.billingAddress.city")}
          placeholder={t("payment.billingAddress.city")}
        />
      </Box>

      <Box sx={{ paddingBlock: 1 }}>
        <InputLabel>{t("payment.billingAddress.country")}</InputLabel>
        <Autocomplete
          id="country"
          popupIcon={<IconSet.ChevronDownBlue />}
          clearIcon={<IconSet.Close />}
          options={countryList}
          getOptionLabel={(option: ICountry) => option.name}
          value={predefinedCountry ?? {}}
          clearOnBlur={false}
          onChange={(event, newValue) => {
            useAudienceEnablementStore.setState({ billingAddress: { ...billingAddress, country: newValue?.code } });
          }}
          renderInput={(params) => (
            <TextField {...params} data-cy={"billing-country"} InputProps={{ ...params.InputProps, autoComplete: "new-password" }} />
          )}
        />
      </Box>
      <GridMiddle style={{ gap: "1rem", paddingBlock: "8px" }}>
        <Box sx={{ width: "100%" }}>
          <Input
            onChange={setInputVal}
            data-cy={"billing-postal_code"}
            name={"postal_code"}
            value={billingAddress.postal_code}
            label={t("payment.billingAddress.zipPost")}
            placeholder={t("payment.billingAddress.zipPost")}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            onChange={setInputVal}
            data-cy={"billing-state"}
            name={"state"}
            value={billingAddress.state}
            label={t("payment.billingAddress.state")}
            placeholder={t("payment.billingAddress.state")}
          />
        </Box>
      </GridMiddle>
    </Box>
  );
};
