/**
 created by Mydde
 */

import * as React from "react";
import styled from "styled-components/macro";
import { Elem, GridItemFullAuto } from "../AppElements";
import { AppIcon } from "../AppIcon";
import { Box } from "@mui/material";

interface IAppLabelProps {
  text: string | JSX.Element;
  id?: number;
  index?: number;
  selected?: boolean;
  dataCy?: string;
  dataCode?: string;
  noMaxWidth?: boolean;
  onRemove?: (id?: number, index?: number) => void;
  onClick?: (id?: number, index?: number) => void;
}

export const AppLabel = (props: IAppLabelProps) => {
  const { text, onRemove, id, index, onClick, selected } = props;

  const handleRemove = () => {
    onRemove && onRemove(id, index);
  };

  const handleClick = () => {
    onClick && onClick(id, index);
  };

  return (
    <Label
      data-cy={props.dataCy ? props.dataCy : "label"}
      data-code={props.dataCode}
      clickable={Boolean(onClick)}
      selected={selected}
      noMaxWidth={props.noMaxWidth}
      onClick={handleClick}
    >
      <LabelText data-cy={"labelText"}>{text}</LabelText>
      {onRemove && (
        <LabelCloser data-cy={"labelCloser"}>
          <AppIcon onClick={handleRemove} color={"red"} icon={"Close"} display={"block"} />
        </LabelCloser>
      )}
    </Label>
  );
};

const LabelCloser = styled(Box)({
  position: "absolute",
  visibility: "hidden",
  right: 0,
  top: 0,
  marginTop: 0,
  color: "red",
  cursor: "pointer",
});

interface ILabelProps {
  selected?: boolean;
  clickable?: boolean;
  noMaxWidth?: boolean;
}

const Label = styled(GridItemFullAuto).attrs({
  radius: "10",
})<ILabelProps>((props) => ({
  display: "inline-flex",
  margin: "2px",
  marginBottom: "4px",
  border: "0.5px solid #00B5E2",
  backgroundColor: props.selected ? "#00B5E2" : "#fff",
  color: props.selected ? "#ffffff" : "#00B5E2",
  position: "relative",
  maxWidth: props.noMaxWidth ? "auto" : "96px",
  cursor: props.clickable ? "pointer" : "",
  "&:hover": {
    [LabelCloser]: {
      visibility: "visible",
    },
  },
}));

const LabelText = styled(Box)({
  maxHeight: "20px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  maxWidth: "100%",
  textAlign: "center",
  width: "100%",
  padding: "2px 4px",
});
