import React, { RefObject } from "react";
import { Input, MenuItem, Radio, Select, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Elem } from "components/ui/AppElements";
import { TEntityName } from "../entities/dataTypes";
import { AppFreeText, TOnChange } from "../../components/form/AppFreeText";
import { AppIcon } from "../../components/ui/AppIcon";
import { GlobalAvatar } from "../../components/ui/GlobalAvatar";
import { EntityTreeView } from "../../components/form/EntityTreeView";
import moment from "moment";
import { StatusDot, StatusItem } from "../../pages/Accounts/zOld/shared";
import { Box, LinearProgress } from "@mui/material";

export interface IInputEvent {
  rowId: number | string;
  fieldName: string;
  fieldValue: any;
}

// GridColType
type TDisplayType =
  | "boolean"
  | "status"
  | "select"
  | "treeValues"
  | "string"
  | "date"
  | "avatar"
  | "user"
  | "logo"
  | "price"
  | "percentage"
  | "freeTextValidate"
  | "actions"
  | "none"
  | "label";

interface inputType {
  type?: TDisplayType;
  value?: any;
  onChange?: (ev: IInputEvent) => void;
  onClose?: (ev: IInputEvent) => void;
  onOpen?: (ev: IInputEvent) => void;
}

interface inputTypeLabel extends inputType {
  type?: "label";
  value: string;
}

interface inputTypeText extends inputType {
  type?: "string";
  value: any;
}

interface inputTypeRadio extends inputType {
  type?: "boolean";
  checked?: boolean;
  value: any;
}

interface inputTypeSelect extends inputType {
  type?: "select";
  value: any;
  values: { index: any; text: string }[];
}

interface inputTreeView extends Partial<inputType> {
  type?: "treeValues";
  entityName: TEntityName;
  defaultField: string;
  hierarchyField: string;
  singleSelect?: boolean;
  values?: any[]; // remove
}

interface inputTextFree extends Partial<inputType> {
  type?: "freeTextValidate";
  entityName: TEntityName;
  value: any;
  defaultField: string;
}

type IInput =
  | inputTypeRadio
  | inputTypeSelect
  | inputTreeView
  | inputTypeText
  | inputTextFree
  | inputTypeLabel;

export interface TDataRowModel extends GridColDef {
  field: string;
  type: TDisplayType;
  fieldValue?: any;
  width?: number;
  flex?: number;
  input?: IInput;
  render?: (params: GridCellParams) => void;
  code?: string;
}

export type TDataColumnModel = Record<string, TDataRowModel>;
// GridColDef

export const renderModel = (
  models: TDataColumnModel,
  visualizationType: "edit" | "show" = 'show',
  formRef?: RefObject<HTMLFormElement>
) => {
  //(dataModel, 'edit', formRef),

  return Object.values(models).map((column: TDataRowModel) => {
    // weakness here, cannot send type : add to main grid tips
    return {
      ...column,
      renderCell: (params: GridCellParams) => {
        return visualizationType === "edit"
          ? DrawInput(column, params, formRef)
          : drawText(column.type, params);
      },
      renderEditCell: (params: GridCellParams) => {
        return DrawInput(column, params, formRef);
      },
    };
  });
};

export const drawText = (type: TDisplayType, params: GridCellParams) => {
  const defaultValue = params.value;
  // console.log("value", params);

  switch (type) {
    case "treeValues":
      return <TextTreeLabel title={params.field} value={defaultValue} />;
    case "boolean":
      return (
        <AppIcon
          fontSize={"small"}
          icon={Boolean(defaultValue) ? "CheckboxTrue" : "CheckboxFalse"}
        />
      );
    case "select":
      return <Typography>{defaultValue}</Typography>;
    case "status":
      return (
        <div>
          <StatusItem active={Boolean(defaultValue)}>
            <StatusDot /> {defaultValue ? "Active" : "Inactive"}
          </StatusItem>
        </div>
      );
    case "avatar":
      return (
        <>
          <GlobalAvatar
            user={params.row?.id ? params.row : params.row?.user}
            isPending={params.row.status !== 'ACTIVATED' }
            size={'28px'}
            fontSize={'15px'}
            showText={true}
          />
        </>
      );
    case "logo":
      return (
        <div data-cy={params.row?.code}>
          <GlobalAvatar
            logoVal={defaultValue}
            size={'26px'}
            fontSize={'14px'}
            showText={true}
          />
        </div>
      );

    case "date":
      return (
        <Typography>
          {moment(defaultValue as string).format("D MMMM YYYY")}
        </Typography>
      );
    case "percentage":
      // return <Typography style={{fontSize:'10px'}} >{defaultValue} {(defaultValue!== undefined) ? '%' : ''}</Typography>
      return (
        <Box sx={{ p: 2, width: "100%", fontSize: "11px" }}>
          <Typography style={{ fontSize: "10px" }}>
            {defaultValue} {defaultValue !== undefined ? "%" : ""}
          </Typography>
          {defaultValue !== undefined && (
            <LinearProgress
              variant="determinate"
              value={(defaultValue as number) ?? 0}
            />
          )}
        </Box>
      );
    case "string":
      return (
        <Typography title={defaultValue} noWrap={true}>
          {defaultValue}
        </Typography>
      );
    case "none":
      return(<>
        {defaultValue}
      </>)
    case "actions":
      return(<div>
        {defaultValue}
      </div>)   
    case "label":

    default:
      return (
        <Typography title={defaultValue} noWrap={true}>
          {defaultValue}
        </Typography>
      );
  }
};

interface ITextLabelProps {
  value: any;
  title: string;
}

const TextTreeLabel = (props: ITextLabelProps) => {
  const value = props.value;
  const title = props.title;

  const label =
    value && Array.isArray(value) && value.length
      ? `${value.length} ${title}`
      : "";
  return <Elem>{label}</Elem>;

  // return <ColumnsMiddle>{(value && Array.isArray(value)) ? value.map(()=><Elem>{value}</Elem>) :
  // ''}</ColumnsMiddle>
};

export const DrawInput = (
  rowModel: TDataRowModel,
  params: GridCellParams,
  formRef?: RefObject<HTMLFormElement>
) => {
  if (!rowModel?.input) return null;

  const input = rowModel.input;
  const rowValue = params.getValue(params.id, params.field);

  switch (input.type) {
    case "boolean":
      return (
        <Radio
          value={params.field}
          checked={Boolean(rowValue)}
          size={"small"}
          color={"primary"}
          name={params.field + rowValue}
          title={`${params?.value}` ?? ""}
          data-cy={rowModel?.code}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            ev.stopPropagation();
            if (input.onChange) {
              input.onChange({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: ev.currentTarget.checked,
              });
            }
          }}
        />
      );
    case "treeValues":
      return (
        <EntityTreeView
          data-cy={rowModel?.code}
          entityName={input.entityName}
          defaultField={input.defaultField}
          hierarchyField={input.hierarchyField}
          singleSelect={input.singleSelect}
          selectedEntityItems={input.value as any}
          onChange={(item: any) => {
            if (input.onChange) {
              // console.log('onChange input', input.onChange)
              input.onChange({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: item,
              });
            }
          }}
        />
      );
    case "select":
      return (
        <Select
          data-cy={rowModel?.code}
          fullWidth={true}
          value={rowValue}
          variant={"standard"}
          onClose={(ev: any) => {
            const newVal = ev.target.value;
            if (input.onClose) {
              input.onClose({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: newVal,
              });
            }
          }}
          onOpen={() => {
            input.onOpen &&
              input.onOpen({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: params.value,
              });
          }}
          onChange={(ev) => {
            const newVal = ev.target.value;
            input.onChange &&
              input.onChange({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: newVal,
              });
          }}>
          {input.values &&
            input.values.map((inp) => (
              <MenuItem value={inp.index}>{inp.text}</MenuItem>
            ))}
        </Select>
      );
    case "freeTextValidate":
      return (
        <AppFreeText
          data-cy={rowModel?.code}
          onChange={(item: TOnChange) => {
            if (input.onChange) {
              input.onChange({
                rowId: params.id,
                fieldName: params.field,
                fieldValue: item,
              });
            }
          }}
          value={input.value}
        />
      );
    
    case "string":
      return <Input value={input.value} data-cy={rowModel?.code} />;
    case "label":
      return <div>{input.value}</div>;
  }

  return <div>format not set</div>;
};

export const dataColumnModelUtils = {
  renderModel,
};
