import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "../../../redux/slices/auth.slices";
import { useAppToast } from "../../../components/live/AppToast";
import { ICompanyUsers } from "../../../types";
import { deleteUser } from "../Details/requests";
import { Grid } from "@mui/material";
import { useOpenDialogWindow } from "../../../components/live/DialogWindow";
import { ActionButtons, IActionButtonsList } from "../../../components/controls/ActionButtons";
import { pageUtils } from "../../../application/pages/pages.utils";
import { PageId } from "../../../application/pages/pages.config";
import { useTranslation } from "react-i18next";
import { BackendError } from "../../../types/backendError";
import { useFeatureFlag } from "stores/featureFlags.store";
import { queryClientRefetcher } from "../../../application/utils/useQuery.utils";
import { AUTHUser } from "application/utils/AuthUser";

/**
 * Block of buttons for FrameContent Header
 *
 */

interface IButtonBlockEditUserProps {
  userDetails: Partial<ICompanyUsers>;
  setUserDetails: (arg: Partial<ICompanyUsers>) => void;
}

export const ButtonBlockEditUser: React.FC<IButtonBlockEditUserProps> = ({ userDetails, setUserDetails }) => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };

  const appToast = useAppToast();
  const { t } = useTranslation();

  const [deleteIsVisible, setDeleteIsVisible] = React.useState(false);

  const authState = useAuthState();
  const windowDialog = useOpenDialogWindow();
  const featFlagStore = useFeatureFlag((st) => st.featureFlags);
  React.useEffect(() => {
    setDeleteIsVisible(+id !== authState.user?.id);
  }, [id, authState.user?.id]);

  const handleDeleteUser = () => {
    windowDialog.open({
      dialogId: "nope",
      title: "Confirm",
      description: t("message.user.deleteUserValidation"),
      buttonActions: {
        acceptButton: "Remove",
        cancelButton: true,
      },
      onAccept: deleteUserFun,
    });
  };

  const deleteUserFun = (args?: { companyUsersId: number }) => {
    if (userDetails.id) {
      deleteUser(userDetails.id)
        .then((res) => {
          appToast.success({
            message: t("message.user.deletedSuccess"),
          });
          // reload data
          queryClientRefetcher(["user"]);
          // CMVP-1910 Wrong redirection after delete user in data provider admin account
          setTimeout(() => history(pageUtils.getPagePathById(PageId.accountView, { accountId: AUTHUser.accountId })), 1000);
        })
        .catch((err: BackendError) => {
          appToast.error({
            message: err?.message ?? t("labels.error"),
          });
        });
    }
  };

  const actions: IActionButtonsList = {
    companyUsers: {
      delete: {
        title: "Delete",
        variant: "outlined",
        action: handleDeleteUser,
        condition: (args) => authState?.user?.id !== args.id,
      },
    },
  };

  // needed id
  return (
    <Grid container>
      {<ActionButtons actions={actions.companyUsers} vars={{ id: userDetails.id, userId: userDetails.id, accountId: userDetails?.account?.id }} />}
    </Grid>
  );
};
