import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ICompanyUsers } from "../application/entities/dataTypes/companyUser";
import { Auth } from "aws-amplify";
import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import { AuthException } from "../application/utils/exceptions.utils";
import { apiService, evorraApi } from "../application/entities/api/apiService";
import { IDoRedefinePasswordArgs, IDoRetrievePasswordArgs } from "../redux/slices/auth.slices";
import { BackendError } from "../types/backendError";
import { pageUtils } from "application/pages/pages.utils";
import { PageId } from "application/pages/pages.config";
import { AUTHUser } from "application/utils/AuthUser";
import { mountStoreDevtool } from "simple-zustand-devtools";

interface IDoSignInArgs {
  email: string;
  password: string;
}

export interface IDoUserInvitationArgs {
  email: string;
  username: string;
  family_name: string;
  company: string;
  companyUrl: string;
  password: string;
  tempPassword: string;
  given_name: string;
  phoneNumber?: string;
  marketingOptIn?: boolean;
  analyticsOptIn?: boolean;
  termsAndConditionsAccepted?: boolean;
}

export interface IDoCheckTempPasswordArgs {
  email: string;
  tempPassword: string;
}
export interface IDoSetNewPasswordArgs {
  user: CognitoUser;
  password: string;
}

type AuthStoreType = {
  loggedIn: boolean;
  challengeName?:
    | "SMS_MFA"
    | "SOFTWARE_TOKEN_MFA"
    | "SELECT_MFA_TYPE"
    | "MFA_SETUP"
    | "PASSWORD_VERIFIER"
    | "CUSTOM_CHALLENGE"
    | "DEVICE_SRP_AUTH"
    | "DEVICE_PASSWORD_VERIFIER"
    | "ADMIN_NO_SRP_AUTH"
    | "NEW_PASSWORD_REQUIRED"; // replace signupStep
  cognitoUser?: CognitoUser | null;
  error?: any;
  showError?: boolean;
  password?: string | undefined;
  userCheckingState?: "pending" | "done";
  actions: {
    doSignin: (args: IDoSignInArgs) => Promise<any>;
    doCheckTempPassword: (args: IDoCheckTempPasswordArgs) => void;
    doSetNewPassword(args: IDoSetNewPasswordArgs): void;
    doRetrievePassword: (args: IDoRetrievePasswordArgs) => Promise<any>; // {flowType:'FORGOT_PASSWORD' | 'USER_ACTIVATION'}
    doRedefinePassword: (args: IDoRedefinePasswordArgs) => void | any;
    doSignOut: () => void;
  };
};

const searchLoggedInUser = () => {
  return apiService.entity("companyUsers").find("loggedInUser").fetch();
};

export const authStore = create<AuthStoreType>()(
  devtools((set, get) => ({
    loggedIn: false,
    challengeName: undefined,
    cognitoUser: null,
    error: undefined,
    userCheckingState: undefined,
    actions: {
      doSignin: async (args) => {
        console.log("doSignin", args);
        return await Auth.signIn(args.email, args.password)
          .then(async (cognitoUser: any) => {
            console.log(cognitoUser);
            const session: CognitoUserSession = cognitoUser.signInUserSession;
            //catch singIn error
            if (!session?.getIdToken()) throw AuthException({ code: "404", message: "An error occurred", name: "signing error" });

            // means we are in the wrong screen, and should be in USER_INVITATION screen
            if (["NEW_PASSWORD_REQUIRED"].includes(cognitoUser.challengeName)) {
              set({ loggedIn: false });
              const url = pageUtils.getPagePathById(PageId.user_invitation, { email: args.email });
              setTimeout(() => {
                document.location.href = url;
                // history(url); CMVP-1440
              }, 4000);
              return {
                cognitoUser: undefined,
                user: undefined,
              };
            }

            //get user
            const { data } = await searchLoggedInUser();
            if (data) {
              AUTHUser.setUser(data);
              set({
                loggedIn: session.isValid(),
                cognitoUser: cognitoUser,
                challengeName: cognitoUser.challengeName,
                userCheckingState: "done",
              });
            } else {
              set({ loggedIn: false });
            }
            console.log(cognitoUser, data);
            return {
              cognitoUser: cognitoUser,
              user: data as Partial<ICompanyUsers>,
            };
          })
          .catch((e) => {
            set({ loggedIn: false });
            throw new Error(e);
          });
      },
      doCheckTempPassword: async (args) => {
        const { email, tempPassword } = args;
        Auth.signIn(email, tempPassword)
          .then((user) => {
            return user;
          })
          .catch((error) => {
            throw AuthException(error);
          });
      },
      doSetNewPassword: async (args) => {
        Auth.completeNewPassword(args.user, args.password)
          .then(() => {
            // at this time the user is logged in if no MFA required
            return Auth.currentAuthenticatedUser()
              .then(async (userCognito: CognitoUser) => {
                const { data } = await searchLoggedInUser();
                return {
                  cognitoUser: userCognito,
                  user: data as Partial<ICompanyUsers>,
                };
              })
              .catch((error) => {
                throw AuthException(error);
              });
          })
          .catch((error) => {
            throw AuthException(error);
          });
      },
      doRetrievePassword: async (args) => {
        return evorraApi
          .route(`/cognito/forgotPasswordOrResendEmail`)
          .post({ email: args.email })
          .then((res) => {
            return res;
          })
          .catch((error) => {
            throw new BackendError(error);
          });
      },
      doRedefinePassword: async (args) => {
        const { email, code, password } = args;
        return Auth.forgotPasswordSubmit(email, code, password)
          .then((res) => res)
          .catch((error) => {
            set({
              error: error,
              showError: true,
              userCheckingState: "done",
            });
            throw AuthException(error);
          });
      },
      doSignOut: async () => {
        return Auth.signOut().then((res) => {
          set({
            loggedIn: false,
            challengeName: undefined,
            cognitoUser: null,
            error: undefined,
            userCheckingState: undefined,
          });
        }); // { global: true }
      },
    },
  }))
);

//Display debug in React Dev Tools
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("authStore", authStore);
}
