import {devApi} from "_configuration/api.config";
import axios, {AxiosInstance} from "axios";
import {authService} from "../../services/auth.service";

const apiRequest: AxiosInstance = axios.create({
    baseURL: devApi,
    maxRedirects: 0,
    /*httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),*/
});

/*apiRequest.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2));
  return request;
});*/
apiRequest.interceptors.request.use(
    async function (config) {
        let session;
        try {
            session = await authService.getCurrentSession();
        } catch (er) {
        }

        if (session) config.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiRequest.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default apiRequest;
