import {createTheme} from '@mui/material';
import {Theme,useTheme} from '@mui/material/styles';
import {makeStyles, styled} from '@mui/styles';


const defaultTheme = createTheme();

export const useStylesGridTable = (type: 'default' | 'naked' = 'default') => {
  
  const theme = useTheme();
  
  const headStyle = {
    default: {
      backgroundColor                     : theme.palette.mode === 'light' ? '#F7F8FB' : 'rgba(255,255,255,0.2)',
      border                              : '1px solid ' + theme.skin.borderColor
    },
    naked: {
      fontWeight:'bold'
    }
  }
  
  
  return makeStyles(
    (theme: Theme) => {
      return {
        root: {
          '& .MuiDataGrid-root'             : {
            border: '0px solid transparent',
            // minHeight               : '100%',
            '& .MuiDataGrid-main': {
              border: '0px solid transparent',
              // height                            : '100%',
              '& .MuiDataGrid-overlay'          : {zIndex: 300},
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
              },
              '& .MuiDataGrid-columnHeaders'    : {
                ...headStyle[type],
                boxShadow                           : theme.skin.boxShadow,
                //borderRadius                        : '5px',
                marginBottom                        : '16px',
                '& .MuiDataGrid-iconButtonContainer': {
                  position: 'absolute',
                  right   : 0
                },
                '& .MuiDataGrid-columnHeader'       : {
                  '&:focus'                       : {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                },
              },
              '& .MuiDataGrid-virtualScroller'  : {
                // height                                 : '100%',
                '& .MuiDataGrid-virtualScrollerContent': {
                  border: '0px solid red',
                  // marginTop                                 : '8px',
                  // height                                    : '100%',
                  width                                     : '100%',
                  '& .MuiDataGrid-virtualScrollerRenderZone': {
                    '& .super-app-theme--row, .MuiDataGrid-row': {
                      backgroundColor                : theme.palette.background.default,
                      fontSize                       : '0.8rem',
                      border                         : '1px solid transparent',
                      maxWidth                       : '100%',
                      borderBottom                   : '1px solid ' + theme.skin.borderColor,                      
                      zIndex                         : 0,
                      marginBottom                   : '2px',
                      '&.Mui-selected'               : {
                        zIndex         : 10,
                        backgroundColor: theme.palette.action.selected,
                        borderRadius                   : '5px',
                      },
                      '&:hover'                      : {
                        zIndex         : 10,
                        borderRadius                   : '5px',
                        backgroundColor: theme.palette.action.hover,
                        border         : '1px solid #00B5E2',
                        boxShadow      : theme.shadows[2],
                      },
                      '& .MuiDataGrid-cell'          : {
                        border     : 'none',
                        '&.minCell': {},
                        '&:focus'  : {
                          outline: 'none',
                        },
                      },
                      '& .titleCell, .titleCell > * ': {
                        fontWeight: 'bolder!important',
                        color     : '#3B4559'
                      },
                      '& .MuiDataGrid-cell--textCenter > p':{
                        textAlign:'center'
                      }
                    },
                  },
                },
              },
            },
          },
          '& .super-app-theme--cellWithIcon': {
            display      : 'flex',
            flexDirection: 'row',
            alignItems   : 'center',
          },
          '& .super-app-theme--inactive'    : {
            borderRadius: '10px',
            // margin: "10px 0",
            '&:hover': {
              boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
            },
          },
          '& .super-app-theme--Rejected'    : {
            backgroundColor: '#FFFFFF',
            boxShadow      : '0px 5px 11px rgba(196, 211, 241, 0.6)',
            borderRadius   : '10px',
            margin         : '10px 0',
            '&:hover'      : {
              backgroundColor: '#FFFFFF',
              border         : '1px solid #00B5E2',
              boxShadow      : '0px 5px 11px rgba(196, 211, 241, 0.8)',
            },
          }
        },
      };
    },
    {defaultTheme},
  );
};


export const defaultGridStyle = styled('div')((props: { theme: Theme }) => ({
  '& .MuiDataGrid-root'             : {
    border: '0px solid transparent',
    // minHeight               : '100%',
    '& .MuiDataGrid-main': {
      border: '0px solid transparent',
      // height                            : '100%',
      '& .MuiDataGrid-overlay'          : {zIndex: 300},
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '12px',
      },
      '& .MuiDataGrid-columnHeaders'    : {
        backgroundColor                     : props.theme.palette.mode === 'light' ? '#F7F8FB' : 'rgba(255,255,255,0.2)',
        border                              : '1px solid ' + props.theme.skin.borderColor,
        boxShadow                           : props.theme.skin.boxShadow,
        borderRadius                        : '5px',
        marginBottom                        : '16px',
        '& .MuiDataGrid-iconButtonContainer': {
          position: 'absolute',
          right   : 0
        },
        '& .MuiDataGrid-columnHeader'       : {
          '&:focus'                       : {
            outline: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
      },
      '& .MuiDataGrid-virtualScroller'  : {
        // height                                 : '100%',
        '& .MuiDataGrid-virtualScrollerContent': {
          border: '0px solid red',
          // marginTop                                 : '8px',
          // height                                    : '100%',
          width                                     : '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .super-app-theme--row, .MuiDataGrid-row': {
              backgroundColor                : props.theme.palette.background.default,
              fontSize                       : '0.8rem',
              border                         : '1px solid transparent',
              maxWidth                       : 'calc(100% - 4px)',
              borderBottom                   : '1px solid ' + props.theme.skin.borderColor,
             
              zIndex                         : 0,
              marginBottom                   : '2px',
              '&.Mui-selected'               : {
                zIndex         : 10,
                backgroundColor: props.theme.palette.action.selected,
                borderRadius                   : '5px'
              },
              '&:hover'                      : {
                zIndex         : 10,
                backgroundColor: props.theme.palette.action.hover,
                border         : '1px solid #00B5E2',
                boxShadow      : props.theme.shadows[2],
              },
              '& .MuiDataGrid-cell'          : {
                border     : 'none',
                '&.minCell': {},
                '&:focus'  : {
                  outline: 'none',
                },
              },
              '& .titleCell, .titleCell > * ': {
                fontWeight: 'bolder!important',
                color     : 'black'
              },
            },
          },
        },
      },
    },
  },
  '& .super-app-theme--cellWithIcon': {
    display      : 'flex',
    flexDirection: 'row',
    alignItems   : 'center',
  },
  '& .super-app-theme--inactive'    : {
    borderRadius: '10px',
    // margin: "10px 0",
    '&:hover': {
      boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
    },
  },
  '& .super-app-theme--Rejected'    : {
    backgroundColor: '#FFFFFF',
    boxShadow      : '0px 5px 11px rgba(196, 211, 241, 0.6)',
    borderRadius   : '10px',
    margin         : '10px 0',
    '&:hover'      : {
      backgroundColor: '#FFFFFF',
      border         : '1px solid #00B5E2',
      boxShadow      : '0px 5px 11px rgba(196, 211, 241, 0.8)',
    },
  }
}));