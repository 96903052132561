/**
 created by Mydde
 */

import {Box, Button, Divider, IconButton} from "@mui/material";
import {styled} from "@mui/styles";
import {GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import {PageId} from "application/pages/pages.config";
import {EPermission} from "application/utils/granter.utils";
import {AxiosResponse} from "axios";
import {GridTable} from "components/organisms/GridTable/GridTable";
import {MainFrame} from "components/templates/MainFrame";
import {ColumnsMiddle, ColumnsWrap, Elem, GridItemFull, H5, StackerFullHeight} from "components/ui/AppElements";
import _ from "lodash";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {useFeatureFlag} from "stores/featureFlags.store";
import useLocalStorageState from "use-local-storage-state";
import {accessList} from "../../../application/access/access";
import {actions} from "../../../application/actions/actions";
import {objectApi} from "../../../application/entities/dataApi";
import {audiencesColumns} from "../../../application/entities/dataColumns/audiences.columns";
import {dataObject} from "../../../application/entities/dataObjects/DataObjectClass";
import {IAudiences} from "../../../application/entities/dataTypes/audiences";
import {pageUtils} from "../../../application/pages/pages.utils";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {dataColumnModelUtils, TDataColumnModel} from "../../../application/utils/dataColumnModel.utils";
import {ActionMenu} from "../../../components/controls/ActionMenu";
import {Pager} from "../../../components/controls/Pager";
import {Sorter} from "../../../components/controls/Sorter";
import {ActionGranter} from "../../../components/features/ActionGranter";
import {ApiProgress} from "../../../components/live/ApiProgress";
import {useAppToast} from "../../../components/live/AppToast";
import {useOpenDialogWindow} from "../../../components/live/DialogWindow";
import {AppDrawer} from "../../../components/ui/AppDrawer";
import {AppIcon} from "../../../components/ui/AppIcon";
import {AppTabs, TAppTabArgs} from "../../../components/ui/Tabs";
import {IOpenDialogAction} from "../../../redux/slices/dialog.slices";
import {BackendError} from "../../../types/backendError";
import {AudienceCard} from "../Card";
import {FloatingMenuHandle} from "../Card/FloatingMenuHandle";
import {ProviderStatus} from "../Card/ProviderStatus";
import {CopyToBrandPopup} from "../CopyToBrandPopup";
import {AudienceBuilderSearch, INaturalSearchResultSet} from "./AudienceBuilderSearch";
import {AudienceLibraryThumb} from "./AudienceLibraryThumb";

type AudiencesListProps = {
    accountId?: number;
};

export const AudiencesList = (props: AudiencesListProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    const defaultAudienceStatus = "All";

    // receive brandId in uri.params or props
    const uriParams = useParams<{ accountId: string }>() as { accountId: string };
    let accountId = props?.accountId ?? uriParams?.accountId ?? AUTHUser.accountId;
    const audiencesApi = new objectApi.audiences();
    const accountsApi = new objectApi.accounts();

    const account = useAccountTracker(Number(accountId));

    let [pageSizeState, setPageSizeState] = useLocalStorageState<number>("aud-pageSizeState", 50);
    let [recordSizeState, setRecordPageSizeState] = useLocalStorageState<number>("aud-recordSizeState", 50);
    let [pageNumber, setPageNumber] = useLocalStorageState<number>("aud-pageNumber", 0);
    let [sortDir, setSortDir] = React.useState<"asc" | "desc">("desc"); // useLocalStorageState<'asc' | 'desc'>('aud-sortDirDefault', 'desc');
    let [sortBy, setSortBy] = React.useState<"modificationTime" | "name" | string>("modificationTime");

    const [kw, setKw] = React.useState<string>("");

    // control the drawer
    const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false);
    // control the drawer mode
    const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>(undefined);
    // default for the first opening of drawer
    const defaultMode = "transparency";

    const [activeAudience, setActiveAudience] = React.useState<IAudiences>({} as any);

    const [tcLoading, setTcLoading] = React.useState<boolean>(false);

    let [displayMode, setDisplayMode] = useLocalStorageState<"list" | "grid">("aud-displayMode", "grid");

    const [searchResult, setSearchResults] = React.useState<INaturalSearchResultSet[]>([]);

    let [audiencesCount, setAudiencesCount] = useLocalStorageState<{
        archived: number;
        draft: number;
        activated: number;
        pendingApproval: number;
        approved: number;
    }>("aud-audiencesCount", {} as any);
    let [audienceStatus, setAudienceStatus] = useLocalStorageState<
        "Activated" | "Draft" | "Archived" | "All" | "Pending_approval" | "Approved" | string
    >("aud-audienceStatus", defaultAudienceStatus);
    let [tabActiveIndex, setTabActiveIndex] = React.useState<number | undefined>(0);

    const appToast = useAppToast();

    const {isLoading, error, data, refetch} = useQuery<any, BackendError>(
        ["audiences", pageSizeState, pageNumber, sortBy, sortDir, audienceStatus, kw],
        () => {
            return accountsApi
                .searchAudiences({
                    byId: +accountId,
                    searchValue: audienceStatus === "All" ? undefined : audienceStatus,
                    searchField: "status",
                    kwValue: kw.length ? kw : undefined,
                    page: {pageSize: pageSizeState, pageNumber: pageNumber},
                    sort: {sortBy: sortBy, sortDir: sortDir},
                })
                .then((res) => res);
        },
        {cacheTime: 0}
    );

    // @ts-ignore
    const audData = data?.data?.detailedResults ?? [];
    const flatData = audData.map((fl: any) => fl?.result?.content).flat();

    const columns = _.cloneDeep(audiencesColumns());

    let cols: TDataColumnModel = {
        id: {headerAlign: "center", align: "center", ...columns.id},
        creator: {headerAlign: "center", align: "left", ...columns.creator},
        name: {headerAlign: "center", align: "center", ...columns.name},
        createdDate: {headerName: "Creation Date", headerAlign: "center", align: "center", ...columns.createdDate},
        modifiedDate: {headerAlign: "center", align: "center", ...columns.modifiedDate},
        lastAudienceSize: {headerAlign: "center", align: "center", ...columns.lastAudienceSize},
        CPM: {headerAlign: "center", align: "center", ...columns.cpm},
        actions: columns.actions,
    };

    if (audienceStatus === "Pending_approval") {
        cols = {
            id: {headerAlign: "center", align: "center", ...columns.id},
            requester: columns.requester,
            requestApprovalTime: columns.requestApprovalTime,
            name: {headerAlign: "center", align: "center", ...columns.name},
            approverAsked: {headerAlign: "center", align: "center", ...columns.approverAsked},
            //price is not displayed cause info not there in search but only in searchfull
            actionsBtns: columns.actions,
        };
    }
    if (audienceStatus === "Approved") {
        cols = {
            id: {headerAlign: "center", align: "center", ...columns.id},
            requester: columns.requester,
            name: {headerAlign: "center", align: "center", ...columns.name},
            approver: {headerAlign: "center", align: "center", ...columns.approver},
            approvalTime: columns.approvalTime,
            actionsBtns: columns.actions,
        };
    }

    const dialogWindow = useOpenDialogWindow();

    const sortParams = {sortBy: sortBy ?? "modificationTime", sortDir: sortDir ?? "desc"};

    // move

    const createData = dataObject.getDataObject("audiences", {
        name: t("audience.untitledAudience"),
        account: `account/${accountId}`,
    });

    /** change the mode of th Drawer */
    function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
        if (mode === menuMode) {
            /*if (visibleDrawer) setMenuMode(undefined);
             setVisibleDrawer(!visibleDrawer);*/
        } else {
            setMenuMode(mode);
            setVisibleDrawer(true);
        }
    }

    function handleToggleDrawer(fact: any) {
        if (fact && !menuMode) setMenuMode(defaultMode);
        setVisibleDrawer(fact);
    }

    React.useEffect(() => {
        getAudienceCount();
    }, []);

    const total =
        (audiencesCount.pendingApproval ?? 0) +
        (audiencesCount.approved ?? 0) +
        (audiencesCount.archived ?? 0) +
        (audiencesCount.draft ?? 0) +
        (audiencesCount.activated ?? 0);
    //  CMVP-1389 Move the "All" tab to be the first tab on the left in the Audience Library view
    const tabs: TAppTabArgs[] = [
        {
            order: 1,
            id: 4,
            text: `${t("audience.statusAll")} (${total})`,
            value: `All`,
            dataLabel: "All",
            dataValue: total
        },
        {
            order: 2,
            id: 1,
            text: `${t("audience.statusDraft")} (${audiencesCount.draft ?? 0})`,
            value: `Draft`,
            dataLabel: "Draft",
            dataValue: audiencesCount.draft,
        },
        {
            order: 3,
            id: 5,
            text: `${t("audience.pendingApproval")} (${audiencesCount.pendingApproval})`,
            value: `Pending_approval`,
            dataLabel: "Pending_approval",
            dataValue: audiencesCount.pendingApproval,
        },
        {
            order: 4,
            id: 6,
            text: `${t("audience.approved")} (${audiencesCount.approved})`,
            value: `Approved`,
            dataLabel: "Approved",
            dataValue: audiencesCount.approved,
        },
        {
            order: 5,
            id: 2,
            text: `${t("audience.statusActivated")} (${audiencesCount.activated ?? 0})`,
            value: `Activated`,
            dataLabel: "Activated",
            dataValue: audiencesCount.activated ?? 0,
        },
        {
            order: 6,
            id: 3,
            text: `${t("audience.statusArchived")} (${audiencesCount.archived ?? 0})`,
            value: `Archived`,
            dataLabel: "Archived",
            dataValue: audiencesCount.archived ?? 0,
        },
    ];
    tabs.sort((a, b) => a?.order! - b?.order!);

    if (audiencesCount.pendingApproval === 0 && audiencesCount.approved === 0) {
        tabs.splice(2, 2);
    }

    React.useEffect(() => {
        const tab = tabs.find((t) => t.value === audienceStatus);
        if (tab) setTabActiveIndex(tabs.indexOf(tab));
    }, [tabActiveIndex]);

    React.useEffect(() => {
        if (data?.data?.detailedResults) updatePaginationState(data?.data?.detailedResults);
    }, [data?.data?.detailedResults]);

    const HeaderContent = (
        <ColumnsMiddle>
            <GridItemFull></GridItemFull>
            <ActionGranter accesses={accessList.audiences.builder}>
                <Button
                    onClick={() => {
                        navigate(pageUtils.getPagePathById(PageId.audienceBuilderSelection, {accountId: accountId}));
                    }}
                    variant="contained"
                >
                    Create New Audience
                </Button>

            </ActionGranter>
        </ColumnsMiddle>
    );

    const dialogArchive: IOpenDialogAction = {
        dialogId: "audienceArchive",
        buttonActions: {acceptButton: "Archive", cancelButton: true},
        title: t("audience.archiveAudience"),
        description: t("audience.archiveAudienceDescription"),
        icon: "Archive",
        onAccept: onArchiveAccept,
    };

    const dialogDelete: IOpenDialogAction = {
        dialogId: "audienceDelete",
        buttonActions: {acceptButton: "Delete", cancelButton: true},
        title: t("audience.deleteAudience"),
        description: t("audience.deleteAudienceDescription"),
        icon: "Trash",
        onAccept: onDeleteAccept,
    };

    const dialogDuplicate: IOpenDialogAction = {
        dialogId: "audienceDuplicate",
        buttonActions: {acceptButton: "Duplicate", cancelButton: true},
        title: t("audience.duplicateAudience"),
        description: t("audience.duplicateAudienceDescription"),
        icon: "Duplicate",
        onAccept: onDuplicateAccept,
    };

    const dialogCancel: IOpenDialogAction = {
        dialogId: "audienceCancel",
        buttonActions: {acceptButton: t("audience.cancelRequest"), cancelButton: true},
        title: t("audience.cancelRequest"),
        description: t("audience.cancelRequestDescription"),
        icon: "Close",
        onAccept: onCancelRequest,
    };

    const actionButtons = {...actions.audience};

    actionButtons.archive.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogArchive, dialogArgs: {id: audienceData.id, data: audienceData}});
    };
    actionButtons.copy.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogDuplicate, dialogArgs: {id: audienceData.id, data: audienceData}});
    };
    actionButtons.trash.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogDelete, dialogArgs: {id: audienceData.id, data: audienceData}});
    };
    actionButtons.review.action = (audienceData: IAudiences) => {
        if (audienceData.approverAsked.id === AUTHUser.userId) {
            navigate(pageUtils.getPagePathById(PageId.audienceApproval, {audienceId: audienceData.id}));
        } else {
            dialogWindow.open({
                dialogId: "confirmRequestReview",
                buttonActions: {acceptButton: "Review", cancelButton: true},
                title: "You are not the asked approver",
                description: "You are going to review this audience, but you are not the asked approver. Are your sure you want to review this request?",
                icon: "HandTool",
                onAccept: (args: any, orgs: any) => {
                    navigate(pageUtils.getPagePathById(PageId.audienceApproval, {audienceId: audienceData.id}));
                },
            });
        }
    };
    actionButtons.pay.action = (audienceData: IAudiences) => {
        navigate(pageUtils.getPagePathById(PageId.audienceActivation, {audienceId: audienceData.id}));
    };

    actionButtons.cancel.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogCancel, dialogArgs: {id: audienceData.id, data: audienceData}});
    };

    // for feature flag. When global, just edit the actions file and replace the pageid

    actionButtons.copyToBrand.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            dialogId: "copyToBrand",
            component: CopyToBrandPopup,
            componentProps: {
                audience: audienceData,
            },
        });
    };

    columns.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div>
                <ActionMenu actions={actionButtons} vars={{...cell.row, audienceId: cell.row.id}}/>
            </div>
        );
    };

    const getAudienceCount = () => {
        if (accountId) {
            accountsApi
                .GetAudienceStatusCount(+accountId)
                .then((res: AxiosResponse<any> | void) => {
                    if (res?.data) setAudiencesCount(res?.data);
                })
                .catch((error) => {
                });
        }
    };

    const saveAudience = async (id: number, data: any) => {
        audiencesApi
            .update(id, data)
            .then((res: any) => reloadData())
            .catch((e: BackendError) => showError(e));
    };

    const onSelectAudience = (audience: IAudiences) => {
        setTcLoading(true);
        if (!activeAudience || audience.id === activeAudience.id) {
            setVisibleDrawer(!visibleDrawer);
            if (visibleDrawer === true) {
                setTcLoading(false);
                setActiveAudience({} as any);
            }
        } else {
            setVisibleDrawer(true);
        }
        if (
            activeAudience &&
            (activeAudience.id !== audience.id || !activeAudience.dspCampaignInfo || audience.audienceStatus !== activeAudience.audienceStatus)
        ) {
            audiencesApi
                .openAndRefreshAudience(audience.id)
                .then((res) => {
                    if (res.data) {
                        setActiveAudience(res.data);
                        setTcLoading(false);
                    }
                })
                .catch((e) => {
                });
        }
    };

    const handleEditAudience = () => {
        if (activeAudience && activeAudience.id) navigate(pageUtils.getPagePathById("audience-builder-update", {id: activeAudience.id}));
    };

    const onTabClick = (args: TAppTabArgs) => {
        //close TC
        setDrawerMode("transparency");
        setVisibleDrawer(false);
        if (args.value) {
            setPageNumber(0);
            setAudienceStatus(args.value);
        }
    };

    async function onDeleteAccept(args: any, orgs: any) {
        audiencesApi
            .delete(orgs.id)
            .then(() => {
                setActiveAudience({} as IAudiences);
                setVisibleDrawer(false);
                reloadData();
            })
            .catch((e: BackendError) => showError(e));
    }

    async function onArchiveAccept(args: any, orgs: any) {
        audiencesApi
            .archiveAudience(orgs.id)
            .then(() => {
                reloadData();
                setVisibleDrawer(false);
            })
            .catch((e: BackendError) => showError(e));
    }

    function onDuplicateAccept(args: any, dta: { id: number; data: Partial<IAudiences> }) {
        audiencesApi
            .duplicateAudience(dta.data)
            .then((res) => {
                if (res && res.id) {
                    navigate(pageUtils.getPagePathById("audience-builder-update", {id: res.id}));
                }
            })
            .catch((e: BackendError) => showError(e));
    }

    function onCancelRequest(args: any, dta: { id: number; data: Partial<IAudiences> }) {
        audiencesApi
            .cancelRequestApproval(dta.id)
            .then((res) => {
                appToast.open({
                    toastId: "globalToast",
                    severity: "success",
                    message: t("audience.cancelRequestConfirmation"),
                });
                reloadData();
            })
            .catch((e: BackendError) => showError(e));
    }

    function reloadData() {
        refetch();
        getAudienceCount();
    }

    // update state vars for pager
    // parse result to calculate maxSize and totalElement's counts
    function updatePaginationState(audiencePage: any[]) {
        const totalElements = audiencePage
            .map((re) => {
                return re?.result?.totalElements;
            })
            .reduce((block, part) => block + part, 0); // add them

        setRecordPageSizeState(() => totalElements);
    }

    function showError(e: BackendError) {
        appToast.open({
            toastId: "globalToast",
            severity: "error",
            message: e.message ?? "An error occurred",
        });
    }

    function handlePageSizeChange(size: number) {
        setPageNumber(0);
        setPageSizeState(size);
    }

    function handlePageChange(size: number) {
        setPageNumber(size);
    }

    function onOpenAudience(id: any, status: string, isForMe: boolean = false) {
        if (["ARCHIVED", "ACTIVATED"].includes(status)) {
            navigate(pageUtils.getPagePathById("audience-view", {audienceId: id}));
            return;
        }
        if (!["PENDING_APPROVAL", "APPROVED"].includes(status)) {
            navigate(pageUtils.getPagePathById("audience-builder-update", {id}));
        } else if (status === "PENDING_APPROVAL" && AUTHUser.permissions.accountGrade === EPermission.COMPANYADMIN) {
            if (isForMe) {
                navigate(pageUtils.getPagePathById(PageId.audienceApproval, {audienceId: id}));
            } else {
                dialogWindow.open({
                    dialogId: "confirmRequestReview",
                    buttonActions: {acceptButton: "Review", cancelButton: true},
                    title: "You are not the asked approver",
                    description: "You are going to review this audience, but you are not the asked approver. Are your sure you want to review this request?",
                    icon: "HandTool",
                    onAccept: (args: any, orgs: any) => {
                        navigate(pageUtils.getPagePathById(PageId.audienceApproval, {audienceId: id}));
                    },
                });
            }
        } else if (status === "APPROVED") {
            navigate(pageUtils.getPagePathById(PageId.audienceActivation, {audienceId: id}));
        }
    }

    function onAudienceDataChange(args: Record<string, any>) {
        saveAudience(args.id, args);
    }

    let provStatus = visibleDrawer ? {right: "340px", top: "0.5rem"} : {right: 0, top: 0};

    const drawerTitle =
        menuMode === "channels"
            ? t("audience.cardTips.channelTipTitle")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTipTitle")
                : t("audience.cardTips.transparencyTipTitle");
    const drawerTip =
        menuMode === "channels"
            ? t("audience.cardTips.channelTip")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTip")
                : t("audience.cardTips.transparencyTip");
    return (
        <MainFrame headerButtons={HeaderContent} loading={isLoading} backendError={error} frameId={"audience-library"}>
            <StackerFullHeight style={{position: "relative"}}>
                <Elem padding={[0, 1.5]}>
                    <Box className={"flex-h flex-align-middle pad-2"} style={{paddingInline: "0"}}>
                        <AudienceBuilderSearch
                            setKw={setKw}
                            audienceStatus={audienceStatus ?? "Draft"}
                            sort={sortParams}
                            getResults={(options) => {
                                setSearchResults(options ?? []);
                            }}
                        />
                        <div>
                            <ColumnsMiddle>
                                <Box>
                                    <IconButton
                                        id={"cyLinkDisplayAudienceTable"}
                                        onClick={() => {
                                            setDisplayMode("list");
                                        }}
                                    >
                                        <AppIcon color={displayMode !== "list" ? "#ccc" : ""} icon={"List"}/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton
                                        id={"cyLinkDisplayAudienceThumb"}
                                        onClick={() => {
                                            setDisplayMode("grid");
                                        }}
                                    >
                                        <AppIcon color={displayMode !== "grid" ? "#ccc" : ""} icon={"Grid"}/>
                                    </IconButton>
                                </Box>
                            </ColumnsMiddle>
                        </div>
                    </Box>
                    <AppTabs activeTab={tabActiveIndex ?? 0} tabList={tabs} onClick={onTabClick}>
                        <Sorter sortFields={["name", "creationTime", "modificationTime"]} sortParams={sortParams}
                                setSortParams={{setSortDir, setSortBy}}/>
                    </AppTabs>
                </Elem>
                <GridItemFull margin={[0.5, 1]} style={{overflow: "auto", position: "relative"}}>
                    <Box sx={{position: "absolute", width: "100%", padding: "1px", zIndex: 10}}>
                        <ApiProgress entityName={"audiences"}/>
                    </Box>
                    {displayMode === "grid" && (
                        <Box sx={{width: "100%"}}>
                            <ColumnsWrap sx={{pl: 1}} justifyContent={"flex-start"}>
                                {audData.map((results: INaturalSearchResultSet) => {
                                    return (
                                        <>
                                            {audData?.length > 1 && (
                                                <Box
                                                    style={{backgroundColor: "rgba(255,255,255,0.3)"}}
                                                    sx={{
                                                        py: 1,
                                                        boxShadow: "#000",
                                                        width: "100%",
                                                        position: "sticky",
                                                        top: 0,
                                                        marginTop: 0,
                                                        zIndex: 20,
                                                        bgcolor: "primary.main",
                                                    }}
                                                >
                                                    <ColumnsMiddle>
                                                        <Box sx={{px: 2}}>
                                                            <AppIcon fontSize={"small"} icon={"ChevronDownIcon"}/>
                                                        </Box>
                                                        <Box>
                                                            <H5>{results.label}</H5>
                                                            {results.result.content.length} results
                                                        </Box>
                                                    </ColumnsMiddle>
                                                </Box>
                                            )}
                                            <GridContainer data-cy={"library-grid"} style={{paddingBottom: "1rem"}}>
                                                {(results.result.content ?? []).map((audience: IAudiences) => {
                                                    return (
                                                        <AudienceLibraryThumb
                                                            key={audience.id}
                                                            audience={audience}
                                                            selectedThumb={activeAudience?.id === audience.id}
                                                            onClick={() => {
                                                                onSelectAudience(audience);
                                                            }}
                                                            onDblClick={onOpenAudience}
                                                            onDataChange={onAudienceDataChange}
                                                            onDelete={onDeleteAccept}
                                                            actionMenu={actionButtons}
                                                        />
                                                    );
                                                })}
                                            </GridContainer>
                                        </>
                                    );
                                })}
                            </ColumnsWrap>
                        </Box>
                    )}
                    {displayMode === "list" && (
                        <div style={{height: "100%", paddingInline: "0.5rem"}}>
                            <GridTable
                                data-cy={"library-table"}
                                data={{
                                    columns: dataColumnModelUtils.renderModel(cols, "show") as any,
                                    rows: flatData,
                                }}
                                gridProps={{
                                    onRowClick: (param: GridRowParams<IAudiences>) => onSelectAudience(param.row),
                                    onRowDoubleClick: (param: GridRowParams<IAudiences>) => handleEditAudience(),
                                }}
                            />
                        </div>
                    )}
                </GridItemFull>
                <Elem padding={[0, 1.5]}>
                    <Divider/>
                </Elem>
                <FloatingMenuHandle hide={!visibleDrawer} position={provStatus}>
                    <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
                        <button style={{width: "24px"}} data-cy={"btnActionToggleAudienceDrawer"}
                                onClick={() => handleToggleDrawer(!visibleDrawer)}>
                            <AppIcon
                                themeColor={!visibleDrawer ? "primary" : "secondary"}
                                fontSize={"small"}
                                icon={"ChevronDownIcon"}
                                rotate={visibleDrawer ? -90 : 90}
                            />
                        </button>
                        <button data-cy={"btnActionOpenTransparencyCard"} onClick={() => setDrawerMode("transparency")}>
                            <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"}
                                     fontSize={"small"} icon={"FileSearch"}/>
                        </button>
                        {["DRAFT"].includes(activeAudience.audienceStatus) && (
                            <button style={{width: "24px"}} data-cy={"btnActionOpenChannels"}
                                    onClick={() => setDrawerMode("channels")}>
                                <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"}
                                         icon={"ChannelsNav"}/>
                            </button>
                        )}
                        {!["DRAFT"].includes(activeAudience.audienceStatus) && (
                            <button style={{width: "24px"}} data-cy={"btnActionOpenEnablement"}
                                    onClick={() => setDrawerMode("enablement")}>
                                <AppIcon themeColor={menuMode === "enablement" ? "primary" : "secondary"}
                                         fontSize={"small"} icon={"Channels"}/>
                            </button>
                        )}
                    </div>
                </FloatingMenuHandle>
                <AppDrawer
                    title={drawerTitle}
                    helperText={drawerTip}
                    helperTitle={drawerTitle}
                    open={visibleDrawer}
                    loading={tcLoading}
                    hideHandle={true}
                    actionButtons={actionButtons}
                    actionButtonsVars={{...activeAudience, audienceId: activeAudience?.id}} // add audienceId
                    onClose={() => {
                        handleToggleDrawer(false);
                    }}
                >
                    {menuMode === "channels" && <ProviderStatus audienceData={activeAudience} position={provStatus}/>}
                    {menuMode !== "channels" && <AudienceCard menuMode={menuMode} audience={activeAudience}/>}
                </AppDrawer>
                <Pager
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    activePage={pageNumber}
                    maxCount={recordSizeState}
                    pageSize={pageSizeState}
                />
            </StackerFullHeight>
        </MainFrame>
    );
};

const GridContainer = styled("div")(() => ({
    marginTop: "2rem",
    display: "grid",
    width: "100%",
    gap: "0.5rem",
    justifyContent: "left",
    gridTemplateColumns: "repeat(auto-fill, minmax(290px, auto))",
}));
