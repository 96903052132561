import {MenuItem, Select, TextField, TextFieldProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import * as React from 'react';
import {ICompanyUsers} from '../../../types';
import {personTitlesList} from '../../Accounts/zOld/mock';
import {DetailsBlockGrid, TextFieldBlock, InputFieldLabel} from '../../../components/templates/InfoBlock';
import { objectApi } from 'application/entities/dataApi';


interface IUserDetailsProps {
  dataFormik: any;
  userDetails: Partial<ICompanyUsers>;
  setUserDetails?: (arg0: Partial<ICompanyUsers>) => void;
}

export const UserForm: React.FC<IUserDetailsProps> = ({
                                                        dataFormik,
                                                        userDetails                                                
                                                      }) => {
  const {t}         = useTranslation();
  
  const companyUsersApi    = new objectApi.companyUsers;


  const checkIfEmailExists = async (e: any) => {
    dataFormik.handleBlur(e)
    if(!dataFormik.errors?.email){
      companyUsersApi.findCompanyUserByEmail(e.target.value).then((res)=>{
        if(res.data?._embedded?.companyUsers?.length){
          dataFormik.setFieldError('email', 'Email already exists')
        }
      })
    } 
  }


  return (
    <DetailsBlockGrid data-cy={'user-detail-identity'}>
      <div>
        <InputFieldLabel htmlFor="bootstrap-input">
          {t('account.userDetails.title')}
        </InputFieldLabel>
        <TextFieldBlock>
          <Select
            // className={classes.root}
            labelId="title"
            id="title"
            name="title"
            variant={'standard'}
            value={`${userDetails.title}`}
            onChange={dataFormik.handleChange}
            fullWidth={true}
            {...dataFormik.getFieldProps('title')}
          >
            {personTitlesList.map(title => <MenuItem value={title}>{title}</MenuItem>)}
          </Select>
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel required={true} htmlFor="bootstrap-input">
          {t('account.userDetails.firstName')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            value={dataFormik.values.firstName}
            onBlur={dataFormik.handleBlur}
            onChange={dataFormik.handleChange}
            error={dataFormik.touched.firstName && Boolean(dataFormik.errors.firstName)}
            helperText={dataFormik.touched.firstName && dataFormik.errors.firstName}
          />
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel required={true} htmlFor="bootstrap-input">
          {t('account.userDetails.surname')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            value={dataFormik.values.lastName}
            onBlur={dataFormik.handleBlur}
            onChange={dataFormik.handleChange}
            error={dataFormik.touched.lastName && Boolean(dataFormik.errors.lastName)}
            helperText={dataFormik.touched.lastName && dataFormik.errors.lastName}
          />
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel required={true} htmlFor="bootstrap-input">
          {t('account.userDetails.email')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="email"
            name="email"
            disabled={Boolean(userDetails?.id)}
            value={dataFormik.values.email}
            onBlur={checkIfEmailExists}
            onChange={dataFormik.handleChange}
            error={dataFormik.touched.email && Boolean(dataFormik.errors.email)}
            helperText={dataFormik.touched.email && dataFormik.errors.email}
          />
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel htmlFor="bootstrap-input">
          {t('account.userDetails.jobTitle')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="jobTitle"
            name="jobTitle"
            value={dataFormik.values.jobTitle}
            onBlur={dataFormik.handleBlur}
            onChange={dataFormik.handleChange}
            error={dataFormik.touched.jobTitle && Boolean(dataFormik.errors.jobTitle)}
            helperText={dataFormik.touched.jobTitle && dataFormik.errors.jobTitle}
          />
        </TextFieldBlock>
      </div>
      <div>
        <InputFieldLabel htmlFor="bootstrap-input">
          {t('account.userDetails.phone')}
        </InputFieldLabel>
        <TextFieldBlock>
          <TextField
            fullWidth
            id="phone"
            name="phone"
            value={dataFormik.values.phone}
            onBlur={dataFormik.handleBlur}
            onChange={dataFormik.handleChange}
            error={dataFormik.touched.phone && Boolean(dataFormik.errors.phone)}
            helperText={dataFormik.touched.phone && dataFormik.errors.phone}
          />
        </TextFieldBlock>
      </div>
    </DetailsBlockGrid>
  );
};

