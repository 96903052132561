import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import {useUiStore} from '../../redux/ui.store';
import {useFeatureFlag} from '../../stores/featureFlags.store';
import { AUTHUser } from 'application/utils/AuthUser';


export interface AccountRegisterProps {
  accountId: number;
}

/**
 * Change navigation mode depending on the user's type
 * used to dynamically change ui
 * use it in each page
 * @param {number} accountId
 * @returns {IAccounts}
 */
export const useAccountTracker = (accountId: number) => {
  //todo update next line with data from api
  const accountQuery           = useAccountQuery(accountId);
  const accountData: IAccounts = accountQuery?.data ?? {};
  const featureFlagStoreAction = useFeatureFlag().actions;
  // CMVP-2112 Feature flag is not reloaded when using "Go to Home" link
  featureFlagStoreAction.fetchFeatureFlags(accountId);
  const objReg = accountData.accountType === 'BRAND' ?
                 {accountMode: 'BRAND', currentCompany: undefined, currentBrand: accountData} :
                 {accountMode: 'COMPANY', currentCompany: accountData, currentBrand: undefined};
  useUiStore.setState(objReg);
  if(accountData.accountType === 'BRAND') {
  AUTHUser.setCurrentBrandId( accountData.id)
  }
  return accountData
};