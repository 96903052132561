import { Button, Divider, Skeleton, Typography } from "@mui/material";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { GlobalAvatar } from "components/ui/GlobalAvatar";
import moment from "moment";
import { useNavigate } from "react-router";

export const LastAccountsList = (props: { accounts: never[]; loading: boolean }) => {
  const { accounts, loading } = props;

  const AccountItem = (props: { account: any; noDivider?: boolean }) => {
    const { account, noDivider } = props;
    const navigate = useNavigate();

    const accountType = account.accountType === "BRAND" ? "Brand" : "Company";
    const clientType = account.dataClient === true ? "Data Client" : "Data Provider";

    const handleView = () => {
      navigate(pageUtils.getPagePathById(PageId.adminAccountView, { accountId: account.id }));
    };

    return (
      <div key={account.id}>
        <div
          style={{ display: "grid", gap: "5px", alignItems: "center", gridTemplateColumns: "31px 180px 35px", marginBottom: "0.5em", width: "100%" }}
        >
          <GlobalAvatar logoVal={account.companyName} size={"26px"} fontSize={"14px"} showText={false} />
          <Typography
            variant="body1"
            title={account.companyName}
            style={{ fontWeight: "600", fontSize: "14px", lineHeight: "unset", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
          >
            {account.companyName} <br />
            <span style={{ fontSize: "12px" }} className="color-tech-grey">
              Created on {moment(account.creationTime).format("D-MMM-YYYY")}
            </span>
            <br />
            <span style={{ fontSize: "12px" }} className="color-tech-grey">
              {clientType} - {accountType}
            </span>
          </Typography>
          <div style={{ textAlign: "right" }}>
            <Button variant="contained" size="small" color="primary" onClick={handleView}>
              View
            </Button>
          </div>
        </div>
        {!noDivider && <Divider style={{ marginBlock: "8px" }} />}
      </div>
    );
  };

  return (
    <div style={{ maxHeight: "300px", overflowY: "auto", width: "280px", maxWidth: "100%" }}>
      {loading === true ? (
        <Skeleton variant="rectangular" width={"100%"} height={"300px"} style={{ borderRadius: "7px" }} />
      ) : (
        accounts.map((account: any, idx: number) => {
          return AccountItem({ account: account, noDivider: idx === accounts.length - 1 });
        })
      )}
    </div>
  );
};
