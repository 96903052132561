import {IAudiences} from '../dataTypes/audiences';

export const audiences: Partial<IAudiences> = {
  id              : undefined,
  name            : '',
  description     : '',
  color           : '',
  audienceStatus  : 'DRAFT',
  active          : false,
  tags            : [],
  industries      : [],
  locations       : [],
  audienceFeatures: [],
  lastAudienceSize: 0
}