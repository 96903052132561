import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import {BackendError} from '../../../types/backendError';
import {ICountry} from '../../../application/entities/dataTypes/country';
import {useQueryProps} from './useAudiencesQuery';


export const useCountryQuery = (options?: useQueryProps) => {
  
  const countriesApi = new objectApi.countries();
  
  const {isLoading, error, data, refetch} = useQuery<ICountry[], BackendError>(['countries'], () => {
    return countriesApi.get().then(res => res?.data?._embedded?.countries);
    
  }, {
    cacheTime: 60000
  });
  
  return {isLoading, error, data, refetch};
};