import * as React from "react";
import styled from "styled-components/macro";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {ISubscriptionPlan} from "../../../types";
import {EditAccountSubscriptionPlan} from "./EditAccountSubscriptionPlan";
import {ISubscriptions} from "../../../application/entities/dataTypes/subscriptions";

import {TextField} from "@mui/material";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import {DetailBlock, InfoBlockTitle, InputFieldLabel, TextFieldBlock} from "../../../components/templates/InfoBlock";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {AppIcon} from "components/ui/AppIcon";

interface ISubscriptionInfoProps {
    subscription: Partial<ISubscriptions>;
    setSubscription: (arg0: Partial<ISubscriptions>) => void;
    subscriptionPlansList: ISubscriptionPlan[];
}

export const EditSubscription: React.FC<ISubscriptionInfoProps> = ({
                                                                       subscription,
                                                                       setSubscription,
                                                                       subscriptionPlansList
                                                                   }) => {
    const [startDate, setStartDate] = React.useState<Date>(subscription.startDate as Date);
    const [renewalDate, setRenewalDate] = React.useState<Date>(subscription.renewalDate as Date);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const {t} = useTranslation();

    React.useEffect(() => {
        if (subscription && subscription.startDate && subscription.renewalDate) {
            setStartDate(subscription.startDate as Date);
            setRenewalDate(subscription.renewalDate as Date);
        }
    }, [subscription]);

    const handleStartDateChange = (date: any) => {
        // date: TDateValue, keyboardInputValue?: string
        const newDate = moment(date as any).format("YYYY-MM-DD");
        setSubscription({...subscription, startDate: newDate});
        setStartDate(date as unknown as Date);
    };

    const handleRenewalDateChange = (date: any) => {
        // MaterialUiPickersDate
        const newDate = moment(date).format("YYYY-MM-DD");
        setSubscription({...subscription, renewalDate: newDate});
        setRenewalDate(date as unknown as Date);
    };

    return (
        <>
            <DetailBlock data-cy={"subscription-details"}>
                <div
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer"}}
                    onClick={() => setIsVisible(!isVisible)}
                >
                    <InfoBlockTitle style={{margin: "0"}}>Subscription Details</InfoBlockTitle>
                    <AppIcon icon="ChevronDownBlue" fontSize="medium" rotate={isVisible ? 180 : 0}></AppIcon>
                </div>
                {isVisible && (
                    <div style={{paddingTop: "1rem"}}>
                        <DateBlock>
                            <div>
                                <InputFieldLabel required={true} htmlFor="bootstrap-input">
                                    {t("account.accountSubscriptionLabels.startDate")}
                                </InputFieldLabel>
                                <TextFieldBlock>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disabled={!AUTHUser.isEvorraAdmin()}
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            OpenPickerButtonProps={{
                                                edge: "start",
                                                size: "small",
                                                color: "primary",
                                                sx: {}
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </TextFieldBlock>
                            </div>
                            <div>
                                <InputFieldLabel required={true} htmlFor="bootstrap-input">
                                    {t("account.accountSubscriptionLabels.renewalDate")}
                                </InputFieldLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TextFieldBlock>
                                        <DatePicker
                                            disabled={!AUTHUser.isEvorraAdmin()}
                                            value={renewalDate}
                                            onChange={handleRenewalDateChange}
                                            OpenPickerButtonProps={{
                                                edge: "start",
                                                size: "small",
                                                color: "primary",
                                                sx: {}
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </TextFieldBlock>
                                </LocalizationProvider>
                            </div>
                        </DateBlock>
                        <SubscriptionPlanBlock>
                            <InputFieldLabel required={true} htmlFor="bootstrap-input">
                                {t("account.accountSubscriptionLabels.subscriptionPlan")}
                            </InputFieldLabel>
                            <EditAccountSubscriptionPlan
                                subscription={subscription}
                                setSubscription={setSubscription}
                                subscriptionPlansList={subscriptionPlansList}
                            />
                        </SubscriptionPlanBlock>
                    </div>
                )}
            </DetailBlock>
        </>
    );
};

const DateBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  grid-row-gap: 25px;
`;

const SubscriptionPlanBlock = styled.div`
  margin-top: 20px;
`;
