import MenuIcon from "@mui/icons-material/Menu";
import {CircularProgress, Divider, Tooltip} from "@mui/material";
import {accessList} from "application/access/access";
import {useCreateAudience} from "components/hooks/useCreateAudience";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {AppIcon} from "components/ui/AppIcon";
import {useAccountConfParameters} from "pages/Agencies/useAccountConfParameters";
import React from "react";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {useFeatureFlag} from "stores/featureFlags.store";
import styledOld from "styled-components/macro";
import useLocalStorageState from "use-local-storage-state";
import {apiDataFactory} from "../../../application/entities/dataApi";
import {IAccounts} from "../../../application/entities/dataTypes/accounts";
import {PageId} from "../../../application/pages/pages.config";
import {pageUtils} from "../../../application/pages/pages.utils";
import {EUserAccountType, EUserRole, granter, IAccessOptions} from "../../../application/utils/granter.utils";
import {TIcon} from "../../../assets/icons";
import {useUiStore} from "../../../redux/ui.store";
import {useAccountQuery} from "../../hooks/data/useAccountQuery";
import {useLoggedUser} from "../../hooks/useLoggedInUser";
import {JiraCollector} from "../JiraCollector";

export const NavBarLeftAgencies = () => {
    const [expanded, setExpanded] = useLocalStorageState<boolean>("nav-expanded", true);

    // Handle Create New Audience.
    const [loadState, setloadState] = React.useState<PageId | undefined>(undefined);
    const activeFrame = useUiStore((st) => st.activeFrame);
    const accountMode = useUiStore((st) => st.accountMode);
    const {currentBrand, currentCompany} = useUiStore((st) => st);

    const {user, AUTHUser} = useLoggedUser();
    const windowDialog = useOpenDialogWindow();
    // account active
    const accountId = currentBrand?.id ?? currentCompany?.id ?? AUTHUser.accountId;
    const accountQy = useAccountQuery(accountId);
    const activeAccountData: IAccounts = accountQy?.data ?? ({} as IAccounts);
    const accountConfParameters = useAccountConfParameters(+accountId);

    const createAudience = useCreateAudience(accountId);

    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    const accountType = AUTHUser.accountType;

    const qyAudienceType = useQuery("qyAudienceType", () => {
        return apiDataFactory("audienceTypes")
            .get()
            .then((res) => res?.data?._embedded?.audienceTypes);
    });

    const history = useNavigate();
    let menuList: Record<string, any> = {};

    switch (accountMode) {
        case EUserAccountType.BRAND:
            menuList = {
                ragnarok: {
                    pageId: PageId.audienceBuilderSelection, // for _granter
                    icon: "AudienceBuilderIcon",
                    text: "Audience Builders",
                    items: [
                        PageId.audienceBuilderFormInterest,
                        PageId.audienceBuilderForm,
                        PageId.audienceBuilderFormTravel,
                        PageId.audienceBuilderFormAutomotive,
                        accountConfParameters.data?.enable_first_party_data && PageId.audienceBuilderFirstPartyData,
                        featureFlags?.cpg_builder && PageId.audienceBuilderCPG,

                    ],
                },
                ...(featureFlags?.segment_builder && {
                    "data-marketplace": {
                        pageId: PageId.audienceBuilderSegments,
                    }
                }),
                "audience-library": {
                    pageId: PageId.audienceLibrary,
                },
                permissions: {
                    pageId: PageId.vaultPermissions,
                },
                "data-vault-setup": {
                    pageId: PageId.vaultSetup,
                },
                ...(accountConfParameters?.data?.enable_first_party_data && {
                    "my-datas": {
                        pageId: PageId.myDatas,
                    },

                }),


                // "my-lists": Boolean(AUTHUser.getUserAccount()?.databaseName)
                //   ? {
                //       pageId: featureFlags?.new_checkout_parameters ? PageId.lists : PageId.myList,
                //     }
                //   : undefined, //
                "dsp-credentials": {
                    pageId: PageId.channelsSetup,
                },
            };
            break;
        default:
            menuList = {};
    }
    if (granter.grantAccesses([{role: EUserRole.DATA_PROVIDER}]).toUser(AUTHUser.granterUser)) {
        menuList = {
            permissions: {
                pageId: PageId.vaultPermissions,
            },
        };
    }

    //remove permissions and setup from menu if not data provider (permission is allowed for evadmin but it shouldn't appear in menu)
    if (!granter.grantAccesses([{role: EUserRole.DATA_PROVIDER}]).toUser(AUTHUser.granterUser)) {
        delete menuList.permissions;
        delete menuList["data-vault-setup"];
    }

    const handleExpandedState = () => {
        setExpanded(!expanded);
    };

    const goTo = (path: string) => {
        history(path);
    };

    const goToPage = (pageId: PageId) => {
        if (
            [
                PageId.audienceBuilderForm,
                PageId.audienceBuilderFormTravel,
                PageId.audienceBuilderFormInterest,
                PageId.audienceBuilderFormAutomotive,
                PageId.audienceBuilderFirstPartyData,
                PageId.audienceBuilderCPG,
                PageId.audienceBuilderSegments
            ].includes(pageId as PageId)
        ) {
            return handleCreateAudience(pageId);
        }
        history(pageUtils.getPagePathById(pageId, {accountId: accountId}));
    };

    function handleCreateAudience(pageId: PageId) {
        createAudience.createAudience(pageId);
    }

    //  extends IGranterProps - set Active to  ActiveTab === pageId
    const LeftMenuItem = (props: {
        id?: string;
        pageid?: string;
        icon?: TIcon;
        text?: string;
        onClick?: () => void;
        hasChild?: boolean;
        children?: any;
        background?: string;
        disabled?: boolean;
    }) => {
        const [open, setOpen] = React.useState<boolean>(true);
        // show active state
        const activeButton = useUiStore.getState().activeFrame === props?.pageid;
        // for data-cy
        const dataCy = !props?.hasChild ? "link_" + props?.pageid : "link_" + props?.text;

        // do not propagate action if MenuItem has children
        function handleClick() {
            if (!props?.hasChild && props?.onClick) props.onClick();
            if (props?.hasChild) {
                setOpen(!open);
            }
        }

        const backgroundStyle = {
            background: props.background ?? "inherit",
            marginLeft: "0",
            paddingInline: "10px",
            paddingBlock: "5px",
            width: "100%",
            boxShadow: "inset 0px 1px 3px 0px #3f495a, inset 0px -1px 3px 0px #3f495a",
        };

        return (
            <>
                <MenuItemBlock data-cy={dataCy} id={props.id} active={activeButton} key={props?.pageid}
                               onClick={props.disabled ? () => {
                               } : props.onClick}>
                    {props.disabled === true ? (
                        <>
                            <MenuItemIconBlock active={activeButton} data-pageid={props?.pageid}>
                                {props.icon && <DaIcon display={"inline"} fontSize={"small"} color={"#ffffff70"}
                                                       icon={props.icon}/>}
                            </MenuItemIconBlock>
                            <Tooltip title="This feature is temporary unavailable" arrow placement="right">
                                <MenuItemIconName title={props.text} active={activeButton} expanded={expanded}
                                                  style={{color: "#ffffff70"}}>
                                    {props.text}
                                </MenuItemIconName>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <MenuItemIconBlock active={activeButton} data-pageid={props?.pageid}>
                                {props.icon &&
                                    <DaIcon display={"inline"} fontSize={"small"} color={"white"} icon={props.icon}/>}
                            </MenuItemIconBlock>
                            <MenuItemIconName title={props.text} active={activeButton} expanded={expanded}>
                                {props.text}
                            </MenuItemIconName>
                            <div className={"pad-ii"}>
                                {!props?.hasChild && createAudience.loading && createAudience.loading === props?.pageid && (
                                    <div>
                                        <CircularProgress color="inherit" size={8}/>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </MenuItemBlock>
                {Boolean(props?.hasChild) && (
                    <InnerBlock key={"inner" + props?.pageid} expanded={open}
                                style={props.background ? backgroundStyle : {}}>
                        {props?.children}
                    </InnerBlock>
                )}
            </>
        );
    };

    const homeLinkC = pageUtils.getPagePathById(PageId.companyDashboard, {
        companyId: AUTHUser.getUserAccount().id,
    });
    const homeLinkB = pageUtils.getPagePathById(PageId.brandDashboard, {
        brandId: activeAccountData.id,
        companyId: AUTHUser.getUserAccount().id,
    });
    const homeLinkDataProvider = pageUtils.getPagePathById(PageId.dashboardDataProvider);

    const homeEvorraDashboard = pageUtils.getPagePathById(PageId.EvorraDashboard, {
        companyId: AUTHUser.getUserAccount().id,
    });

    function openJiraCollector() {
        windowDialog.open({
            dialogId: "jira-collector",
            title: "We value your feedback",
            icon: "FeedbackIcon",
            component: JiraCollector,
        });
    }

    const isInAdmin = useUiStore((st) => st.isAdminPage);
    return (
        <Container expanded={expanded} data-cy={"left-menu"}>
            <SolidBlock>
                <MenuTopSlide>
                    <MenuItemIconBlock onClick={handleExpandedState} data-cy={"toggle-left-menu"}>
                        <AppIcon pointer={true} display={"inline"} fontSize={"tiny"} icon={"ChevronDownIcon"}
                                 color={"white"} rotate={expanded ? 90 : 270}/>
                    </MenuItemIconBlock>
                </MenuTopSlide>
                <TopIconsBlock>
                    {AUTHUser.isEvorraAdmin() && (
                        <>
                            <LeftMenuItem
                                pageid={"admin-menu"}
                                onClick={() => {
                                    goTo(homeEvorraDashboard);
                                }}
                                icon={"logoAdmin"}
                                text={isInAdmin ? "Admin Menu" : "Admin Dashboard"}
                                hasChild={isInAdmin}
                                background="#4b566a"
                            >
                                {isInAdmin && (
                                    <>
                                        <LeftMenuItem
                                            pageid={"EvorraDashboard"}
                                            onClick={() => {
                                                goTo(homeEvorraDashboard);
                                            }}
                                            icon={"DashboardIcon"}
                                            text={"Dashboard"}
                                        />
                                        <LeftMenuItem
                                            pageid={PageId.globalCockpit}
                                            onClick={() => {
                                                history("data-cockpit");
                                            }}
                                            icon={"DataSources"}
                                            text={"Data Cockpit"}
                                        />
                                        <LeftMenuItem
                                            pageid={PageId.businessCockpit}
                                            onClick={() => {
                                                history("business-cockpit");
                                            }}
                                            icon={"PaymentBillingIcon"}
                                            text={"Business Cockpit"}
                                        />
                                        <LeftMenuItem
                                            pageid={PageId.audienceAdminList}
                                            onClick={() => {
                                                goToPage(PageId.audienceAdminList);
                                            }}
                                            icon={"Audience"}
                                            text={"Audiences List"}
                                        />
                                        <LeftMenuItem
                                            pageid={PageId.accountList}
                                            onClick={() => {
                                                goToPage(PageId.accountList);
                                            }}
                                            icon={"AccountEditIcon"}
                                            text={"Accounts List"}
                                        />
                                        <LeftMenuItem
                                            pageid={PageId.users}
                                            onClick={() => {
                                                goToPage(PageId.users);
                                            }}
                                            icon={"UserList"}
                                            text={"Users List"}
                                        />
                                    </>
                                )}
                            </LeftMenuItem>
                            {isInAdmin && <div style={{paddingBottom: "7px"}}></div>}
                            <StyledDivider light={true} variant={"middle"} flexItem/>
                        </>
                    )}
                    {accountType === "COMPANY" && (
                        <LeftMenuItem
                            pageid={PageId.companyDashboard}
                            onClick={() => {
                                goTo(homeLinkC);
                            }}
                            icon={"DashboardIcon"}
                            text={"Dashboard"}
                        />
                    )}
                    {accountMode === "BRAND" && (
                        <LeftMenuItem
                            pageid={PageId.brandDashboard}
                            onClick={() => {
                                goTo(homeLinkB);
                            }}
                            icon={"Brand"}
                            text={"Brand Home"}
                        />
                    )}

                    {accountType === null && granter.grantAccesses([{role: EUserRole.DATA_PROVIDER}]).toUser(AUTHUser.granterUser) && (
                        <LeftMenuItem
                            pageid={PageId.dashboardDataProvider}
                            onClick={() => {
                                goTo(homeLinkDataProvider);
                            }}
                            icon={"DashboardIcon"}
                            text={"Dashboard"}
                        />
                    )}
                    {Object.keys(menuList).map((pageIdx) => {
                        const innerPages = menuList[pageIdx]?.items ?? [];
                        const menuItem = menuList[pageIdx];

                        let accessAllows = true;
                        let icon = menuItem?.icon;
                        let navTitle = menuItem?.text;

                        if (menuItem?.pageId) {
                            const page = pageUtils.getPageById(menuItem?.pageId);
                            accessAllows = false;

                            icon = icon ?? page.icon;
                            navTitle = page.navTitle ?? page.title ?? navTitle;

                            if (page._accesses) {
                                accessAllows = granter
                                    .grantAccesses(page._accesses as IAccessOptions)
                                    .with(AUTHUser.granterUser)
                                    .toUser(AUTHUser.granterUser);
                            }
                            // CMVP-2097
                            if (
                                [
                                    PageId.audienceBuilderForm,
                                    PageId.audienceBuilderFormTravel,
                                    PageId.audienceBuilderFormInterest,
                                    PageId.audienceBuilderFirstPartyData,
                                    PageId.audienceBuilderCPG,
                                ].includes(menuItem.pageId) &&
                                activeAccountData?.accountType === "BRAND" &&
                                AUTHUser.accountType === "COMPANY"
                            ) {
                                // read role from agentBrandAccountAssociations
                                // const brandRole = AUTHUser?.user.agentBrandAccountAssociations.filter((x: ICompanyUsers) => x.account.id === activeAccountData?.id)?.[0]?.role;

                                accessAllows = granter.grantAccesses(accessList.audiences.builder).with(AUTHUser.granterUser).toUser(AUTHUser.granterUser);
                            }
                        }
                        // CMVP-2169
                        if (!menuItem?.pageId) return null;

                        if (accessAllows) {
                            return (
                                <LeftMenuItem
                                    key={menuItem.pageId}
                                    pageid={menuItem.pageId}
                                    onClick={() => {
                                        goToPage(menuItem?.pageId);
                                    }}
                                    icon={icon ?? "AudiencePlanIcon"}
                                    hasChild={Boolean(innerPages?.length)}
                                    text={menuItem.text ?? navTitle}
                                    disabled={["my-lists"].includes(menuItem?.pageId)}
                                >
                                    {innerPages
                                        ?.filter((x: any) => Boolean(x))
                                        .map((innerPageId: any) => {
                                            let innerAllow: boolean;
                                            const innerPage = pageUtils.getPageById(innerPageId);

                                            innerAllow = true;
                                            if (innerPage._accesses)
                                                innerAllow = granter
                                                    .grantAccesses(innerPage._accesses as IAccessOptions)
                                                    .with(AUTHUser.granterUser)
                                                    .toUser(AUTHUser.granterUser);
                                            // CMVP-2097
                                            if (
                                                [PageId.audienceBuilderForm, PageId.audienceBuilderFormTravel, PageId.audienceBuilderFormInterest].includes(innerPageId) &&
                                                activeAccountData?.accountType === "BRAND" &&
                                                AUTHUser.accountType === "COMPANY"
                                            ) {
                                                // read role from agentBrandAccountAssociations
                                                // const brandRole = user.agentBrandAccountAssociations.filter((x: ICompanyUsers) => x.account.id === activeAccountData?.id)?.[0]?.role;
                                                innerAllow = true;
                                            }
                                            if (!featureFlags?.automotive_builder) {
                                                if (innerPageId === PageId.audienceBuilderFormAutomotive) {
                                                    return;
                                                }
                                            }
                                            return (
                                                innerAllow && (
                                                    <LeftMenuItem
                                                        key={innerPage.id}
                                                        pageid={innerPage.id}
                                                        icon={innerPage.icon ?? "AudiencePlanIcon"}
                                                        text={innerPage.navTitle ?? innerPage.title}
                                                        onClick={() => {
                                                            goToPage(innerPage.id as PageId);
                                                        }}
                                                    />
                                                )
                                            );
                                        })}
                                </LeftMenuItem>
                            );
                        }
                        return <></>;
                    })}
                    <StyledDivider light={true} variant={"middle"} flexItem/>
                    <LeftMenuItem id={"get_help"} key={"ChatIcon"} icon={"YourfeedbackIcon"} text={"Your Feedback"}
                                  onClick={() => openJiraCollector()}/>
                </TopIconsBlock>
            </SolidBlock>
            <GradientBlock/>
        </Container>
    );
};

const StyledDivider = styledOld(Divider)({
    "border-top": "1px solid #fff",
    transform: "scaleY(0.5)",
});

interface IContainerProps {
    expanded?: boolean;
}

const Container = styledOld.div<IContainerProps>((props) => ({
    width: props.expanded ? "190px" : "40px",
    border: "none",
    boxShadow: "0 0 6px rgba(196, 211, 241, 0.85)",
    height: "100%",
    //display      : 'flex',
    flexDirection: "column",
    //alignItems   : 'center',
    transition: "all 0.3s ease-in-out",
}));

const SolidBlock = styledOld.div<IContainerProps>((props) => ({
    width: "100%",
    //background: '#4262FF',
    background: "#3B4559",
    height: "100%",
}));

const GradientBlock = styledOld.div<IContainerProps>((props) => ({
    width: "100%",
    // background: 'linear-gradient(180deg, #4262FF 10%, #37DEF9 100%)',
    background: "linear-gradient(180deg, #3B4559 0%, #5F6E8C 100%);",
    height: "100%",
}));

const TopIconsBlock = styledOld.div((props) => ({
    width: "100%",
    //height       : '100%',
    display: "flex",
    flexDirection: "column",
    //alignItems   : 'center',
}));

const Hamburger = styledOld(MenuIcon)<IMenuItemProps>((props) => ({
    width: "18px",
    height: "18px",
    fontSize: "14px",
    color: props.expanded ? "rgba(255,255,255,0.0)" : "rgba(255,255,255,0.9)",
    cursor: "pointer",
    boxShadow: "0 0 2px rgba(196, 211, 241, 0)",
    borderRadius: "40%",
    backgroundColor: "rgba(255, 255, 255, 0)",
    transition: "all 0.2s ease-in-out",
}));

interface IMenuItemProps {
    expanded?: boolean;
    active?: boolean;
}

const MenuItemIconBlock = styledOld("div")<IMenuItemProps & any>((props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0.5px solid transparent",
    backgroundColor: props.active ? "#00B5E2" : "",
    padding: "0.25rem",
    margin: "0.25rem",
    width: "calc(2rem - 0.5rem)",
    height: "calc(2rem - 0.5rem)",
    borderRadius: "4px",
    "&:hover": {
        border: "0.5px solid rgba(255,0,255,0.1)",
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        borderRadius: "4px",
        boxShadow: "0 0 1px rgba(196, 211, 241, 0.85)",
        color: props.expanded ? "rgba(255,255,255,0.7)" : "rgba(255,255,255,0.8)",
        transition: props.expanded ? "all 0.0s ease-in-out" : "all 0.2s ease-in-out",
    },
}));

const InnerBlock = styledOld.div<IMenuItemProps>((props) => ({
    display: props.expanded ? "block" : "none",
    height: props.expanded ? "inherit" : "0",
    width: "calc(100% - 0.5rem)",
    marginLeft: "0.5rem",
    // marginBottom: '0.25rem',
    //borderLeft     : '1px solid rgba(255, 255, 255, 0.3)',
    clipPath: "border-box inset(80px,0,80px,0)",
    overflow: "hidden",
    transition: "all 0.5s ease-in-out",
    [MenuItemBlock]: {
        "&:first-of-type,&:last-of-type": {
            [MenuItemIconBlock]: {
                "&:first-of-type": {
                    margin: "0 0.25rem",
                },
            },
        },
    },
}));
const MenuTopSlide = styledOld.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0.25rem
`;

const MenuItemIconName = styledOld.div<IMenuItemProps>((props) => ({
    // fontSize    : '11px',
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: props.active ? "#00B5E2" : "",
    fontWeight: props.active ? "bold" : "lighter",
    textOverflow: props.expanded ? "ellipsis" : "clip",
    paddingRight: props.expanded ? "0.5rem" : "0",
    width: props.expanded ? "150px" : "0",
    transition: props.expanded ? "all 0.0s ease-in-out" : "all 0.2s ease-in-out",
}));

const MenuItemBlock = styledOld.div<IMenuItemProps>((props) => ({
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: props.active ? "bold" : "lighter",
    color: "rgba(255,255,255,0.9)",
    transition: props.expanded ? "all 0.0s ease-in-out" : "all 0.2s ease-in-out",
    "&:hover": {
        color: "rgba(255,255,255,1)",
        backgroundColor: "rgba(255,255,255,0.025)",
        [MenuItemIconBlock]: {
            border: "0.5px solid rgba(255,0,255,0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            borderRadius: "4px",
            boxShadow: "0 0 1px rgba(196, 211, 241, 0.85)",
            color: props.expanded ? "rgba(255,255,255,0.7)" : "rgba(255,255,255,0.8)",
            transition: props.expanded ? "all 0.0s ease-in-out" : "all 0.2s ease-in-out",
        },
    },
}));

const DaIcon = styledOld(AppIcon)<IMenuItemProps>((props) => ({}));
