import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';


interface IAlertDialogProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  acceptFunction: () => void;
  objectToDelete: { title: string, object: string };
}

export const AlertDeleteDialog: React.FC<IAlertDialogProps> = ({
  open,
  setOpen,
  acceptFunction,
  objectToDelete,
}) => {


  const handleAccept = () => {
    setOpen(false);
    acceptFunction();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ margin: '20px' }}
          >
            Are you sure you want to delete {objectToDelete.object} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAccept} color="primary">
            Delete
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};