import React, { useEffect } from 'react';
import {Box, Slider, styled} from '@mui/material';
import {H5} from '../../../../components/ui/AppElements';
import {IFeaturesDto} from '../../../../application/entities/dataTypes/features';
import _ from 'lodash';
import { useAudienceBuilderStore } from 'pages/AudienceBuilder/Store/AudienceBuilderStore';

export interface IFeatureTypePercentageProps {
  feature: IFeaturesDto;
  featureCode: string;
}

export const InputPercentage = (props: IFeatureTypePercentageProps) => {
  const {featureCode} = props;

  const filtersValues  = useAudienceBuilderStore(st=> st.filtersValues.filter((el: any) => {
    return el.code === featureCode
  })[0]);

  const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode);
  
  const inputTimer        = React.useRef<any>(0);
  
  const audienceActions = useAudienceBuilderStore.getState().actions;
  
  //useState here cause we can slide the cursor so the state has to update live
  const [percentSelected, setPercentSelected] = React.useState<number>(Number(filtersValues?.selected[0] ?? 0));

  useEffect(() => {
    setPercentSelected(Number(filtersValues?.selected[0] ?? 0));
  }, [filtersValues.selected]);

  const handleChange = (value: number) => {
    setPercentSelected(value);
    if (inputTimer.current) clearTimeout(inputTimer.current);
    inputTimer.current = setTimeout(() => {
      audienceActions.insertFeatureValues(feature.feature as IFeaturesDto, value);
    }, 100);
  };

  
  return <>
    <Box sx={{textAlign: 'center', color: '#7587A3', width: '100%', mb: 0.5}}>
      <H5 data-cy="percentage-filter-selected">{percentSelected} <span style={{fontSize: '11px'}}>%</span></H5>
    </Box>
    <Box sx={{p: 1}} style={{width: '100%',  borderRadius: '12px',}}>
      <StyledSlider value={percentSelected}
                    onChange={(event: Event, value: number | number[], activeThumb: number) => {
                      // add tempo
                      if ((typeof value) === 'number') handleChange(value as number);
                    }}
                    min={0} max={100}/></Box>
  </>;
};
export const StyledSlider          = styled(Slider)(({theme}) => ({
  color  : '#b3b8bd',
  height : 3,
  padding: '4px 0px',
  
  '& .MuiSlider-thumb': {
    height      : 16,
    width       : 8,
    background  : 'linear-gradient(94.21deg, #00B5E2 0%, #10CAF9 100%)',
    boxShadow   : '0px 0px 6px rgba(196, 211, 241, 0.85)',
    borderRadius: '132px',
    '&:hover'   : {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    }
  },
  '& .MuiSlider-track': {
    height      : 6,
    borderRadius: '4px',
  },
  '& .MuiSlider-rail' : {
    color       : '#E4EAF0',
    opacity     : 1,
    height      : 6,
    borderRadius: '4px',
    maxWidth    : 'calc(100% -8px)',
  },
})); 