import { Box, Skeleton, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { profilesFormatter } from "_configuration/formaters";
import { moneyFormatter } from "application/utils/formatters.utils";
import { GridMiddle } from "components/ui/AppElements";
import { useAudienceBuilderStore } from "../Store/AudienceBuilderStore";

export const AudienceBuilderSum = (props: { previewMode?: boolean }) => {
  const audienceData = useAudienceBuilderStore((stre) => stre.audience);
  const currency = audienceData.displayCurrency;
  const audienceFreshData = useAudienceBuilderStore((stre) => stre.sizeAndPrice);
  const loadingSizeAndPrice = useAudienceBuilderStore((stre) => stre.loadingSizeAndPrice);

  const isFDPAudience = audienceData.audienceType.id === 5 ?? false;

  const freshData = props?.previewMode ? false : !["ARCHIVED", "ACTIVATED"].includes(audienceData.audienceStatus);

  const lastData = freshData ? audienceFreshData.lastAudienceSize : audienceData.lastAudienceSize;

  const minData = freshData ? audienceFreshData.cpmMinConverted : audienceData.cpmMinConverted;
  const maxData = freshData ? audienceFreshData.cpmMaxConverted : audienceData.cpmMaxConverted;

  const lastAudienceSize = lastData === -1 || lastData === undefined ? "< Minimum" : `${profilesFormatter.format(lastData)} profiles`;
  const cpmMin = minData ? `${moneyFormatter(minData ?? 0, { currency: currency }) ?? undefined}` : undefined;
  const cpmMax = maxData ? `${moneyFormatter(maxData ?? 0, { currency: currency }) ?? undefined}` : undefined;

  const cpm = minData === maxData ? cpmMin : `${cpmMin} - ${cpmMax}`;

  const fpdCpm =
    minData !== maxData
      ? `${moneyFormatter(minData ?? 0, { currency: currency })} - ${moneyFormatter(maxData ?? 0, { currency: currency })}`
      : moneyFormatter(minData ?? 0, { currency: currency });

  const data: Record<string, any> = {
    estimatedCpm: {
      value: isFDPAudience ? fpdCpm : `${cpm ?? "not set"}`,
      text: "Estimated CPM",
      info: "Estimated CPM infos",
      color: "#FCA657",
    },
    maxAudienceSize: {
      value: `${lastAudienceSize}`,
      text: isFDPAudience ? "Max. Profiles" : "Unique Profiles", // lastAudienceSize
      info: "Estimated CPM infos",
      color: "#4572F9",
    },
  };

  return (
    <Box>
      <Box
        className={"flex-h flex-align-middle " + (props?.previewMode === true ? "audience-builder-sum-preview" : "")}
        padding={[0, 1]}
        data-cy="cpm-profiles"
      >
        {Object.keys(data).map((dataKey: any) => {
          const fkData = data[dataKey];

          return (
            <StatusBox key={dataKey} sx={{ my: 0.5, mx: 1 }}>
              <Box
                sx={{ px: 1, mr: 1, textAlign: "right", borderRadius: "10px" }}
                className={"flex-h flex-align-middle gap-small"}
                style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
              >
                <Bubble color={fkData.color} />
                <Typography data-cy={`title-${dataKey}`}>{fkData.text}</Typography>
              </Box>
              <Box sx={{ px: 1, borderRadius: "10px", minWidth: "150px" }} style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                <Typography
                  data-cy={dataKey}
                  data-test-id={freshData && loadingSizeAndPrice ? "cpmAndProfilesLoading" : "cpmAndProfilesLoaded"}
                  variant={"h4"}
                >
                  {freshData && loadingSizeAndPrice ? (
                    <Skeleton width={72} animation="pulse" variant="rectangular" height={16} style={{ borderRadius: 8, marginBottom: 4 }} />
                  ) : (
                    fkData.value
                  )}
                </Typography>
              </Box>
            </StatusBox>
          );
        })}
      </Box>
      {(Boolean(loadingSizeAndPrice) || !freshData) && <div style={{ display: "none" }} data-cy={"loadedLastAudienceSize"} />}
    </Box>
  );
};

const StatusBox = styled(GridMiddle)((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.mode === "light" ? "#f8f9fb" : props.theme.palette.background.paper,
  borderRadius: "10px",
  flexWrap: "wrap",
}));

const Bubble = styled(Box)((props: { theme: Theme; color?: string }) => ({
  backgroundColor: props.color ?? "#4572F9",
  width: "3px", // #FCA657  // #4572F9 // opacity : 0.8
  height: "9px",
  opacity: "0.8",
}));
