import Box from "@mui/material/Box";
import {SearchItem} from "./SearchItem";
import {SegmentSearchBar} from "./SegmentSearchBar";
import {SegmentCountryFilter} from "./SegmentCountryFilter";
import {SegmentProviderFilter} from "./SegmentProviderFilter";
import React from "react";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {SegmentSearchTypeFilter} from "./SegmentSearchTypeFilter";
import {Button, Typography} from "@mui/material";

export const SegmentsSearch = () => {
    const searchDone = useSegmentBuilderStore(st => st.searchDone)
    const displaySegmentSelected = useSegmentBuilderStore(st => st.displaySegmentSelected)
    const selectedSegments = useSegmentBuilderStore(st => st.selectedSegments)


    return <>
        {!displaySegmentSelected ? (
                <Box sx={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: searchDone ? 'space-between' : 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: searchDone ? 'start' : 'center',

                    }}>
                        <>
                            <Box sx={{width: "400px"}}>
                                <SearchItem title={"Search in segment list"}>
                                    <SegmentSearchBar/>
                                </SearchItem>
                            </Box>

                            {searchDone && (
                                <SearchItem title={"Search Type"}>
                                    <SegmentSearchTypeFilter/>
                                </SearchItem>

                            )}

                            <SearchItem title={"Country"}>
                                <SegmentCountryFilter/>
                            </SearchItem>

                            <SearchItem title={"Provider"}>
                                <SegmentProviderFilter/>
                            </SearchItem>
                        </>


                    </Box>


                </Box>
            ) :
            (
                <Box>
                    <Typography variant="h4" fontSize="16px" sx={{textAlign: 'center'}}>
                        {selectedSegments.length} {selectedSegments.length > 1 ? 'Segments' : 'Segment'} selected
                    </Typography>
                    <Button variant="text" size="small" sx={{display: 'block', margin: 'auto'}}
                            onClick={() => useSegmentBuilderStore.setState({displaySegmentSelected: false})}>
                        Back to search
                    </Button>
                </Box>
            )}
    </>
}