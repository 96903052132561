import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";

export const fileIdsInfo: { label: string; key: keyof FirstPartyDataFile }[] = [
    {
        label: "Panorama Id",
        key: "numPanoids",
    },
    {
        label: "ID5",
        key: "numId5s",
    },
    {
        label: "Hashed Emails",
        key: "numHems",
    },
    {
        label: "TradeDesk Id",
        key: "numTtdids",
    },
    {
        label: "Pubmatic Cookie Id",
        key: "numPubmaticCookies",
    },
    {
        label: "Ramp Id",
        key: "numRampids",
    },
    {
        label: "UID2",
        key: "numUid20s",
    },
    {
        label: "Android Id",
        key: "numAndroidids",
    },
    {
        label: "iOS Id",
        key: "numIosids",
    },
];

export const idList = [
    {
        value: "ttd_id",
        label: "The TradeDesk Id",
    },
    {
        value: "panorama_id",
        label: "PanoramaId",
    },
    {
        value: "email",
        label: "Email",
    },
    {
        value: "hashed_email_sha256",
        label: "Hashed Email (SHA256)"
    },
    {
        value: "id5",
        label: "ID5",
    },
    {
        value: "uid2",
        label: "UID2",
    },
    {
        value: "rampid",
        label: "RampId",
    },
    {
        value: "pubmatic_cookie",
        label: "Pubmatic Cookie",
    },
    {
        value: "android_id",
        label: "Android Id",
    },
    {
        value: "ios_id",
        label: "iOS Id",
    },
    {
        value: "mad_id",
        label: "Mobile Advertiser Id",
    },

];

export const getSuccessFiles = (data: FirstPartyDataFile[]) => {
    return data.filter((fpd) => fpd.processingMessage === "success");
};
