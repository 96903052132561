import React, {FormEvent} from 'react';
import styled from 'styled-components/macro';
import {useParams} from 'react-router-dom';
import {Button, TextField} from '@mui/material';

import {dataColumnModelUtils, IInputEvent, TDataColumnModel,} from '../../../../application/utils/dataColumnModel.utils';
import {GridTable} from '../../../../components/organisms/GridTable';
import {Columns, ColumnsMiddle, Elem, GridItem, GridItemFull,} from '../../../../components/ui/AppElements';
import {rulesInputModel} from '../../../../application/entities/dataColumns/rules.columns';
import {IRuleState} from '../DataVaultPermissions';
import { useAdminPage } from 'components/hooks/useAdminPage';


export interface IDataVaultFormProps {
  featureRule: IRuleState;
  setFeatureRule: Function;
  mode: 'create' | 'update';
  onClose?: Function;
  onValidate?: Function;
  onDelete?: Function;
  disabled?: boolean;
}

export const DataVaultRuleForm = (props: IDataVaultFormProps) => {
  
  const {featureRule, setFeatureRule, mode, onValidate, onDelete} = props;
  const params                                                    = useParams<{ accountId: string }>() as { accountId: string };
  const accountId                                                 = params?.accountId;
  
  const [dataModel, setDataModel] = React.useState<TDataColumnModel>(rulesInputModel as TDataColumnModel);
  //
  const formRef                   = React.useRef<HTMLFormElement>(null);
  // beware of reference // debug
  const dataRuleModel             = {...rulesInputModel};
  React.useEffect(() => {
    if (accountId) setFeatureRule({...featureRule, account: `/account/${accountId}`});
  }, [accountId]);
  
  React.useEffect(() => {
    // BUG 'hasDomain' undefined
    let isWho: any = (featureRule.hasDomain) ? 'hasDomain' : 'hasIndustries';
    
    buildModel(keepCloning(dataRuleModel), isWho, featureRule);
  }, [featureRule]);
  
  // reference removing
  function keepCloning(objectpassed: any) {
    if (objectpassed === null || typeof objectpassed !== 'object') {
      return objectpassed;
    }
    let temporary_storage = objectpassed.constructor();
    for (var key in objectpassed) {
      temporary_storage[key] = keepCloning(objectpassed[key]);
    }
    return temporary_storage;
  }
  
  // build the model we are going to use
  function buildModel(
    model: TDataColumnModel,
    isWho: 'hasIndustries' | 'hasDomain',
    rule: IRuleState,
  ) {
    
    let tmpModel: TDataColumnModel = {...model};
    
    if (tmpModel.hasIndustries.input) {
      tmpModel.hasIndustries.code = "industry-radio"
      tmpModel.hasIndustries.input.onChange = (th: IInputEvent) => {
        registerHasTasTypeRule('hasIndustries');
      };
    }
    
    if (tmpModel.hasDomain.input) {
      tmpModel.hasDomain.code = "domain-radio"
      tmpModel.hasDomain.input.onChange = (th: IInputEvent) => {
        registerHasTasTypeRule('hasDomain');
      };
    }
    
    if (tmpModel.permissionType.input) {
      tmpModel.permissionType.code = "permission-select"
      tmpModel.permissionType.input.onChange = (th: IInputEvent) => {
        registerRule({[th.fieldName]: th.fieldValue});
      };
    }
    
    if (tmpModel.locations.input) tmpModel.locations.input.value = rule?.locations;
    
    if (tmpModel.locations.input) {
      tmpModel.locations.code = "locations-select"
      tmpModel.locations.input.onChange = (th: IInputEvent) => {
        registerRule({locations: th.fieldValue});
      };
    }
    
    tmpModel = switchWhoModel(tmpModel, isWho, rule);
    
    if (tmpModel.who.input) {
      tmpModel.who.input.onChange = (th: IInputEvent) => {
        let tru: any;
        // collect ids ?
        if (isWho === 'hasIndustries') {
          // collect ids // BUG here !!
          tru = th.fieldValue.map((t: any) => t.id);
          registerRule({
            industries: th.fieldValue,
            domain    : '',
          });
        } else if (isWho === 'hasDomain') {
          tru = th.fieldValue.value;
          registerRule({
            domain    : tru,
            industries: [],
          });
        }
      };
    }
    
    setDataModel((curr: TDataColumnModel) => {
      return {...curr, ...tmpModel};
    });
  }
  
  function registerHasTasTypeRule(column: 'hasIndustries' | 'hasDomain') {
    
    registerRule({
      hasIndustries: Boolean(column === 'hasIndustries'),
      hasDomain    : Boolean(column === 'hasDomain'),
    });
  }
  
  const switchWhoModel = (
    model: TDataColumnModel,
    who: 'hasIndustries' | 'hasDomain' | undefined,
    rule: IRuleState,
  ) => {
    const invr: Record<string, any>             = {
      hasIndustries: {
        dataType: 'treeValues',
        code:'industries-list',
        input   : {
          type          : 'treeValues',
          entityName    : 'industries',
          defaultField  : 'name',
          hierarchyField: 'name',
          value         : rule?.industries,
        },
      },
      hasDomain    : {
        dataType: 'freeTextValidate',
        input   : {
          type        : 'freeTextValidate',
          value       : rule?.domain,
          entityName  : 'industries',
          defaultField: 'formattedName',
        },
      },
    };
    let activeho: 'hasIndustries' | 'hasDomain' = rule.hasDomain ? 'hasDomain' : 'hasIndustries';
    
    return {
      ...model,
      who: Object.assign(model.who, invr[activeho]),
    };
  };
  
  const onCreate = () => {
    featureRule?.name.length > 0 && onValidate && onValidate(false);
  };
  
  // centralized usage of setFeatureRule()
  const registerRule = (fragment: Partial<IRuleState>) => {
    setFeatureRule((iru: IRuleState) => {
      return {
        ...iru,
        ...fragment,
      };
    });
  };
  
  const onNameChange = (event: any) => {
    event.stopPropagation();
    let value = event.target.value;
    setFeatureRule((current: IRuleState) => {
      return {
        ...current,
        name: value,
      };
    });
  };
  
  const ButtonZoneCreate = () => {
    return <Columns>
      <GridItemFull></GridItemFull>
      <GridItem padding={[0, 1]}>
        <Button
          data-cy={'create-btn'}
          onClick={onCreate}
          variant={'outlined'}
        >Create</Button>
      </GridItem>
      <GridItem>
        <Button
          data-cy={'create-and-activate-btn'}
          disabled={props.disabled}
          variant={'contained'}
          type={'submit'}
        >Create And Activate</Button>
      </GridItem>
    </Columns>;
  };
  
  const ButtonZoneUpdate = () => {
    return <Columns>
      {/*<GridItem><Button variant={'outlined'}>Duplicate</Button></GridItem>*/}
      <GridItemFull padding={[0, 1]}><Button
        data-cy={'delete-rule-btn'}
        onClick={() => {
          onDelete && onDelete();
        }}
        variant={'outlined'}
      >Delete</Button>
      </GridItemFull>
      <GridItem>
        <Button
          data-cy={'save-rule-btn'}
          type={'submit'}
          disabled={props.disabled}
          variant={'contained'}
        >Save</Button>
      </GridItem>
    </Columns>;
  };
  
  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    // featureRule?.name.length > 0 &&
    if (onValidate) onValidate(true);
    return false;
  };
  
  return (
    <form noValidate={true} onSubmit={onSubmit}>
      <ColumnsMiddle padding={[0.5, 0]}>
        <Elem width={4}>
          Name : <span style={{color: 'red'}}>*</span>
        </Elem>
        <Elem>
          <TextField
            data-cy={'rule-name'}
            value={featureRule?.name}
            placeholder={'Name'}
            onChange={onNameChange}
            type={'text'}
          />
        </Elem>
      </ColumnsMiddle>
      <GridContainer>
        <GridTable
          padding={0}
          data={{
            columns: dataColumnModelUtils.renderModel(dataModel, 'edit', formRef) as any,
            rows   : [featureRule],
          }}
        />
      </GridContainer>
      <Elem
        padding={[0, 1]}
      >
        {mode === 'update' ? <ButtonZoneUpdate/> : <ButtonZoneCreate/>}
      </Elem>
    </form>
  );
};

const GridContainer = styled.div({
  height: '120px',
});