import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { Container, ContentBlock } from "styles/styles";
import { MainFrame } from "components/templates/MainFrame";
import { ICompanyUsers } from "../../../types";
import { GridContent, GridTable } from "../../../components/organisms/Grid";
import { H4 } from "../../../components/ui/AppElements";
import { ButtonBlockForHeaderUsers } from "./ButtonBlockForHeaderUsers";
import { dataColumnModelUtils } from "../../../application/utils/dataColumnModel.utils";
import { companyUsersColumns } from "../../../application/entities/dataColumns/companyUsers.columns";
import _ from "lodash";
import useLocalStorageState from "use-local-storage-state";
import { AppDrawer } from "../../../components/ui/AppDrawer";
import { pageUtils } from "../../../application/pages/pages.utils";
import { UserCard } from "../Card";
import { objectApi } from "../../../application/entities/dataApi";
import { IActionButtonsList } from "../../../components/controls/ActionButtons";
import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import { BackendError } from "../../../types/backendError";
import { PageId } from "../../../application/pages/pages.config";
import { AUTHUser } from "../../../application/utils/AuthUser";
import { useAdminPage } from "components/hooks/useAdminPage";

export const Users: React.FC = () => {
  const history = useNavigate();
  const [exportReference, setExportReference] = useState<React.Ref<HTMLButtonElement>>(null);
  // CMVP-1910 Wrong redirection after delete user in data provider admin account
  const accountApi = new objectApi.accounts();
  const userApi = new objectApi.companyUsers();
  const { t } = useTranslation();
  useAdminPage();
  const [rowUsers, setRowUsers] = useLocalStorageState<ICompanyUsers[]>("cpu-rowUsers", []);
  const [pageNumber, setPageNumber] = useLocalStorageState("cpu-pageNumber", 0);
  const [pageSize, setPageSize] = useLocalStorageState("cpu-pageSize", 50);
  const [totalElements, setTotalElements] = useLocalStorageState<number>("cpu-totalElements", 0);
  const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>("cpu-sortModel", [{ field: "id", sort: "asc" }]);

  const [activeAccount, setActiveAccount] = useLocalStorageState<ICompanyUsers>("cpu-activeAccount", {} as ICompanyUsers);
  const [openDrawer, setOpenDrawer] = useLocalStorageState<boolean>("cpu-drawer", false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<BackendError>();

  const obj = sortModel[0] || { field: "id", sort: "asc" };
  const { field, sort } = obj;
  // one day
  // const userList      = useCompanyUsersQuery(AUTHUser.getUserAccount()?.id, {pageSize, pageNumber, sortBy: field, sortDir: sort ?? 'asc'});

  useEffect(() => {
    setLoading(true);

    const obj = sortModel[0] || { field: "id", sort: "asc" };
    const { field, sort } = obj;

    accountApi
      .getGlobalUsers({ page: { pageSize, pageNumber }, sort: { sortBy: field, sortDir: sort ?? "asc" } })
      .then((res) => {
        const { companyUsers } = res.data._embedded;
        companyUsers ? setRowUsers(companyUsers) : setRowUsers([]);
        const { totalElements } = res.data.page;
        setTotalElements(totalElements);
        setLoading(false);
      })
      .catch((err) => {
        setLoadingError(err);
        setLoading(false);
      });
  }, [pageNumber, sortModel, pageSize]);

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleSortModelChange = (params: GridSortModel) => {
    if (!_.isEqual(sortModel, params)) {
      setSortModel(params);
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPageNumber(0);
  };

  const handleRowClick = (param: GridRowParams<ICompanyUsers>) => {
    /*const id = param.id;
     history.push(`${appPages.users.home.path}/${id}`);*/
    if (param.row.id === activeAccount?.id) {
      setOpenDrawer(!openDrawer);
    } else {
      setOpenDrawer(true);
    }
    setActiveAccount(param.row);
  };

  const handleEditAccount = (param: GridRowParams<ICompanyUsers>) => {
    if (activeAccount?.id) history(pageUtils.getPagePathById("user-edit", { userId: activeAccount.id }));
  };

  const headerProperties = (
    <>
      <Title>{t("account.generalTitles.activeUsers")}</Title>
      <Box sx={{ pt: 1, display: "flex" }}>
        <H4>{totalElements}</H4>
        <IconGreen />
      </Box>
    </>
  );

  const headerButtons = <ButtonBlockForHeaderUsers exportReference={exportReference || null} />;

  // move to proper file
  const actionButtons: IActionButtonsList<IAccounts> = {
    companyUsers: {
      edit: {
        title: "Edit",
        variant: "outlined",
        pageId: PageId.userEdit,
      },
    },
  };

  return (
    <>
      <MainFrame
        frameId={PageId.users}
        loading={loading}
        backendError={loadingError}
        headerProps={{
          headerProperties,
          headerButtons,
        }}
      >
        <div style={{ height: "100%" }} className="pad-2">
          <GridTable
            loading={loading}
            data={{ columns: dataColumnModelUtils.renderModel(companyUsersColumns, "show") as any, rows: rowUsers }}
            pagerProps={{
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
              activePage: pageNumber,
              maxCount: totalElements,
              pageSize: pageSize,
            }}
            gridProps={{
              onRowClick: handleRowClick,
              onRowDoubleClick: handleEditAccount,
              // onRow
              sortingMode: "server",
              sortingOrder: ["desc", "asc"],
              sortModel: sortModel,
              onSortModelChange: handleSortModelChange,
              componentsProps: {
                toolbar: {
                  exportReference: exportReference,
                  setExportReference: setExportReference,
                },
              },
            }}
          />
        </div>
        <AppDrawer
          title={activeAccount?.firstName}
          icon={"User"}
          open={openDrawer}
          actionButtons={actionButtons.companyUsers}
          actionButtonsVars={{ userId: activeAccount.id }}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          <UserCard user={activeAccount} />
        </AppDrawer>
      </MainFrame>
    </>
  );
};

const Title = styled.div`
  color: #9daabf;
`;

const IconGreen = styled.div`
  width: 6px;
  height: 6px;
  background: #02c697;
  border-radius: 50%;
  margin: 0 5px;
`;
