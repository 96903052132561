import {SearchType, useSegmentBuilderStore} from "../SegmentBuilderStore";
import {Box, Collapse, MenuItem, Slider, Typography} from "@mui/material";
import Select from "@mui/material/Select";
import React, {useEffect} from "react";
import useDebounce from "../../../components/hooks/useDebounce";

export const SegmentSearchTypeFilter = () => {

    const searchType = useSegmentBuilderStore((state) => state.searchType)
    const distance = useSegmentBuilderStore((state) => state.distance)

    const [distanceSelected, setDistanceSelected] = React.useState<number>(distance * 100)

    const [displayDistance, setDisplayDistance] = React.useState<boolean>(false)

    const debouncedSearch = useDebounce(distanceSelected, 800);

    const handleChange = (searchType: string) => {
        useSegmentBuilderStore.setState({searchType: searchType as SearchType})
    }

    useEffect(() => {
        useSegmentBuilderStore.setState({distance: distanceSelected / 100})
    }, [debouncedSearch])

    const searchOptions = [
        {value: 'nearText', label: 'Semantic', description: 'Search by semantic'},
        {value: 'keyword', label: 'Keyword', description: 'Search by keyword'},
        {value: 'hybrid', label: 'Hybrid', description: "Mix semantic and keyword search"},
        // {value: 'strictKeywords', label: 'Strict Keyword', description: 'Search all the exact keywords'},
    ]


    const handleChangeDistance = (e: any) => {
        setDistanceSelected(e.target.value)
    }

    return <>
        <Box sx={{display: 'flex', gap: '0.5rem'}}>

            <Select value={searchType}
                    renderValue={(value) => {

                        return <>{searchOptions.filter(option => option.value === value)[0].label} </>
                    }}
                    onChange={(e) => handleChange(e.target.value)}
                    sx={{width: '150px', height: '40px'}}>
                {searchOptions.map(({value, label, description}) => {
                    return <MenuItem key={label}
                                     value={value}
                                     sx={{display: "block"}}>
                        <Typography sx={{fontWeight: 900}}>{label}</Typography>
                        <Typography sx={{fontStyle: "italic"}} color={"#7587A3"}> {description}</Typography>
                    </MenuItem>

                })}
            </Select>
            {/*searchType === 'nearText' && (
                <AppIcon icon={"SettingsIcon"} color={"#7587A3"} style={{cursor: 'pointer'}}
                         onClick={() => setDisplayDistance(!displayDistance)}/>
            )*/}
        </Box>
        <Collapse in={displayDistance && searchType === 'nearText'}>
            <Slider aria-label="creative" min={15} max={40} value={distanceSelected} onChange={handleChangeDistance}/>
        </Collapse>
    </>
}