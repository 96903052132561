import {Button, styled,} from '@mui/material';
import {PageId} from 'application/pages/pages.config';
import {FormBlock,} from 'components/templates/InfoBlock';
import {MainFrame} from 'components/templates/MainFrame';
import {useParams} from 'react-router-dom';
import React from 'react';
import {useAccountQuery} from '../../components/hooks/data/useAccountQuery';
import {ICompanyUsers} from '../../application/entities/dataTypes/companyUser';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import {Theme} from '@mui/material/styles';
import {AccountHeader} from '../Agencies/AccountHeader';
import {InviteUser} from '../Agencies/InviteUser';
import {UserThumbView} from '../Agencies/UserThumbView';
import {useAccountTracker} from '../Agencies/useAccountTracker';
import {BrandUserListTable} from '../Agencies/BrandUserListTable';
import {EPermission} from '../../application/utils/granter.utils';
import {ActionGranter} from '../../components/features/ActionGranter';

interface ListBrandAgentsProps {
    brandId?: number;
}

export const ListBrandUsers = (props: ListBrandAgentsProps) => {

    //
    // receive accountId and brandId in uri.params or props
    const uriParams = useParams<{ brandId: string; }>() as { brandId: string };
    //
    const brandId = props?.brandId ?? +uriParams?.brandId;
    //
    const accountQuery = useAccountQuery(brandId);
    const accountData: IAccounts = accountQuery?.data ?? {};
    const [inviteUser, setInviteUser] = React.useState<boolean>(false);
    const [activeUser, setActiveUser] = React.useState<ICompanyUsers>({} as ICompanyUsers);


    // account tracker and mode setter
    useAccountTracker(brandId);


    function handleRowClick(param: ICompanyUsers) {
        setActiveUser(param);
        setInviteUser(false);
    }

    return (
        <MainFrame
            frameId={PageId.brandAgentList}
            //checkPageAccess={true}
            // grantWith={{accountId: accountData.id}}
            loading={accountQuery?.isLoading}
            browserTitle={'Users - ' + accountData?.companyName}
            mainFrameHeaderContent={<AccountHeader
                accountId={brandId}
                frameId={PageId.brandAgentList}/>}>
            <Container className="flex-v" style={{minHeight: '100%'}}>
                <div className="flex-main flex-h gap-small">
                    <FormBlock className={'pad-ii-3 pad-b-2'} noBorder style={{width: '700px'}}
                               title={'User List'}
                               buttons={<ActionGranter accesses={[{grade: EPermission.COMPANYADMIN}]}><Button
                                   onClick={() => {
                                       setInviteUser(!inviteUser);
                                   }} variant={'outlined'} data-cy={'invite-user-button'}>Invite
                                   users</Button></ActionGranter>}>
                        <BrandUserListTable
                            brandId={brandId}
                            onRowClick={handleRowClick}/>
                    </FormBlock>
                    <div className={'flex-main'} style={{overflow: 'auto', height: '100%'}}>
                        <div style={{maxWidth: '460px'}}>
                            {Boolean(inviteUser) &&
                                <InviteUser
                                    accountId={brandId}
                                    onClose={() => setInviteUser(false)}
                                    onUserInvited={() => {
                                        accountQuery.refetch();
                                        setInviteUser(false);
                                    }}/>}
                            {Boolean(activeUser.id) && <UserThumbView
                                accountId={brandId}
                                userId={activeUser.id}
                                onClose={() => setActiveUser({} as ICompanyUsers)}
                            />}
                        </div>
                    </div>
                </div>
            </Container>
        </MainFrame>
    );
};


const Container = styled('div')((props: { theme: Theme }) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: '100%',
    height: '100%',
    width: '100%',
}));
;

