import { objectApi } from "application/entities/dataApi";
import { IUploadedList } from "application/entities/dataTypes/listFiles";
import { useQuery } from "react-query";

export const useExclusionLists = (accountId: number) => {
  const accountsApi = new objectApi.accounts();
  const { isLoading, error, data, refetch, remove } = useQuery<{ objects: IUploadedList[] }>(
    ["account", +accountId, "lists", "exclusionLists"],
    () => {
      return accountsApi.getUploadedExclusionLists(+accountId).then((res) => res?.data);
    },
    {
      enabled: Boolean(accountId),
      cacheTime: 30000,
    }
  );
  return { isLoading, error, data, refetch, remove };
};

export const useDomainLists = (accountId: number) => {
  const accountsApi = new objectApi.accounts();
  const { isLoading, error, data, refetch, remove } = useQuery<{ objects: IUploadedList[] }>(
    ["account", +accountId, "lists", "domainLists"],
    () => {
      return accountsApi.getUploadedDomainLists(+accountId).then((res) => res?.data);
    },
    {
      enabled: Boolean(accountId),
      cacheTime: 30000,
    }
  );
  return { isLoading, error, data, refetch, remove };
};
