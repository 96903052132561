import * as React from 'react';
import styledOld from 'styled-components/macro';
import {INote} from '../../../application/entities/dataTypes/note';
import {AppIcon} from '../../../components/ui/AppIcon';
import {GreyDot} from '../accounts.styled';
import {styled} from '@mui/styles';


interface INoteProps {
  item: Partial<INote>;
  index: number;
  handleDeleteNote?: (arg0: number | undefined) => void;
}
/** @deprecated duplicated file */
export const Note: React.FC<INoteProps> = ({
                                             item,
                                             index,
                                             handleDeleteNote,
                                           }) => {
  
  
  return (
    <NoteBlock>
      <NoteBox>
        <NoteHeader>
          {`Note #${index + 1} `}
          <GreyDot/>
          {`${item.creationTime ?? ''}`}
        </NoteHeader>
        <NoteText>
          {item.text}
        </NoteText>
      </NoteBox>
      {Boolean(handleDeleteNote) && <Box>
          <CloseBlock>
              <AppIcon
                  icon={'Close'}
                  onClick={() => { if (handleDeleteNote) handleDeleteNote(item.id);}}
                  color={'#00B5E2'}
                  fontSize={'small'}
              />
          </CloseBlock>
      </Box>}
    
    </NoteBlock>
  );
};

const NoteBlock = styled('div')(() => ({
  display       : 'flex',
  justifyContent: 'space-between',
  height        : '80px',
  overflow      : 'hidden',
}));

const NoteHeader = styledOld.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #7587A3;
  font-size: 12px;
`;

const NoteBox = styledOld.div`
  display: flex;
  flex-direction: column;;
`;

const NoteText = styledOld.div`
  font-size: 12px;
  color: #9DAABF;
  overflow: hidden;
`;

const Box = styledOld.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const CloseBlock = styledOld.div`
  cursor: pointer;
  border-radius: 50%;

  :hover {
    box-shadow: 0 4px 9px rgba(14, 199, 246, 0.3);
  }
`;
