import React from 'react';
import styled from 'styled-components/macro';
import {useParams} from 'react-router-dom';
import {Divider} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {objectApi} from '../../../../application/entities/dataApi';
import {Elem, H6} from '../../../../components/ui/AppElements';
import {useAppToast} from '../../../../components/live/AppToast';
import { DataVaultRuleForm } from '../DataVaultRuleForm';
import { IRuleState } from '../DataVaultPermissions';
import { useAdminPage } from 'components/hooks/useAdminPage';


interface IRuleFormCreateProps {
  visible?: boolean;
  dataReload?: Function;
  dataError?: Function;
  showHide?: Function;
}

export function RuleLineCreate(props: IRuleFormCreateProps) {
  
  const nullData: Partial<IRuleState>     = {
    id            : 0,
    name          : '',
    accountId     : undefined,
    isActivated   : false,
    domain        : '',
    permissionType: 'DENY',
    locations     : [],
    industries    : [],
    features      : [],
    industryIds   : [0],
    featureIds    : [0],
    hasIndustries : true,
    hasDomain     : false,
  };
  const {dataReload, dataError, showHide} = props;
  const params                            = useParams<{ accountId:string }>() as { accountId:string };
  const accountId                         = params?.accountId;
  
  const [featureRule, setFeatureRule] = React.useState<IRuleState>(nullData as IRuleState);
  const [processing, setProcessing]   = React.useState<boolean>(false);
  
  const {t}     = useTranslation();
  const appToast = useAppToast();
  const rulesApi = new objectApi.rules();
  
  if (accountId) {
    nullData.accountId = accountId as unknown as number;
  }
  
  const onValidate = async (isActivated: boolean) => {
    // set to true to block other validations
    setProcessing(true);
    
    try {
      const dataRules: IRuleState = {...featureRule};
  
  
      if (!dataRules.name?.length  ) {
        setProcessing(false);
        dataError && dataError({message: 'Please fill in a name'});
        return;
      }
      if (!dataRules.hasDomain && dataRules.industries.length < 1 && dataRules.locations.length < 1) {
        setProcessing(false);
        dataError && dataError({message: 'Choose Industry or Domain'});
        return;
      }
      if (dataRules.hasDomain && dataRules.domain.length < 1) {
        setProcessing(false);
        dataError && dataError({message: 'Choose Industry or input Domain'});
        return;
      }
      
      delete dataRules.hasDomain;
      delete dataRules.hasIndustries;
      
      if (isActivated) {
        dataRules.isActivated = true;
      }
      
      if (!dataRules?.industryIds) {
        dataRules.industryIds = [0];
      }
      
      if (!dataRules?.featureIds) {
        dataRules.featureIds = [0];
      }
      
      const rule = await rulesApi.create(dataRules);
      if (rule.id) {
        dataReload && dataReload();
        showHide && showHide(false);
        setProcessing(false);
        appToast.open({
          toastId : 'globalToast',
          message : t('dataVault.fetchedFine'),
          severity: 'success',
        });
      }
    } catch (error) {
      setProcessing(false);
      dataError && dataError(error);
    }
  };
  
  return <Elem padding={[1]}>
    <Container radius={20} padding={[2]}>
      <H6>Create New Rule</H6>
      <Elem padding={[0.25, 0]}>Please, fill in the form below and click "create to add a new
        rule"</Elem>
      <Divider/>
      <DataVaultRuleForm
        mode={'create'}
        featureRule={featureRule}
        setFeatureRule={setFeatureRule}
        onValidate={onValidate}
        disabled={processing}
      />
    </Container>
  </Elem>;
}

const Container = styled(Elem)({
  background  : '#ffffff',
  borderTop   : '1px solid #ededed',
  borderBottom: '1px solid #ccc',
  boxShadow   : '0px 0px 6px rgba(196, 211, 241, 0.85)',
});