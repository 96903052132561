import React, {FunctionComponent} from 'react';

interface ILinkToProps {
  children?: any;
  to?: any;
  title?: any;
  target?: any;
};


export const LinkTo: FunctionComponent<ILinkToProps> = (props: ILinkToProps) => {
  const {children, target='_blank'} = props;

  return (
    <a href={props.to || '#'} target={target} title={props.title || ''}>
      {children ?? 'no link'}
    </a>
  );
};


