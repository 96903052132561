import { loadStripe, SetupIntentResult, StripeCardNumberElement, StripeIbanElement } from "@stripe/stripe-js";

import { CardNumberElement, Elements, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { Suspense, useEffect, useState } from "react";

import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { PaymentIntentResult } from "@stripe/stripe-js/types/stripe-js/stripe";

import { PaymentMethodCreateParams } from "@stripe/stripe-js/types/api/payment-methods";
import { stripeConfig } from "_configuration/stripe.config";
import { stripeService } from "application/services/stripe.service";
import { AUTHUser } from "application/utils/AuthUser";
import { StyledCheckbox } from "components/form/CheckBox";
import { useTranslation } from "react-i18next";
import { BackendError } from "types/backendError";
import { useAudienceEnablementStore } from "../../store/AudienceEnablement.store";
import { AddCardForm } from "./AddCardForm";

const promise = loadStripe(stripeConfig.stripeKey);

/** stripe payment form for enablement */
export const StripeFormD = (props: ICheckoutProps) => {
  const { paymentMethod, onSuccess, vars, paymentReason } = props;
  const { paymentData } = vars;

  const billingAddress = useAudienceEnablementStore((st) => st.billingAddress);

  const { t } = useTranslation();
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [cardFormUntouched, setCardFormUntouched] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [authChecked, setAuthChecked] = React.useState<boolean>(false);
  const [billingAdress, setBillingAdress] = React.useState<PaymentMethodCreateParams.BillingDetails.Address>(
    {} as PaymentMethodCreateParams.BillingDetails.Address
  );

  const stripe = useStripe();
  const elements = useElements();

  const account = AUTHUser.getUserAccount();
  const stripeAccount = AUTHUser.getUserStripe();

  // very quick fix
  const intentId = paymentData.id;

  const billinData = {
    billing_details: {
      // customer details we can collect at any time
      name: account.companyName,
      email: stripeAccount?.stripeEmail ?? "notset@notset.com",
      address: billingAdress,
    },
    metadata: {
      objectType: vars.paymentType,
      objectId: intentId,
    },
  };

  // set billing address
  React.useEffect(() => {
    setBillingAdress({
      ...{
        city: account.city,
        country: account?.country,
        line1: account.streetAddress,
        line2: "",
        postal_code: account.postalCode,
        state: account.state,
      },
      ...billingAddress,
    });
    // none
  }, [billingAddress]);

  useEffect(() => {
    if (intentId) {
      setProcessing(true);
      stripeService
        .setSetupIntent(AUTHUser.currentBrandId ?? account.id, "card")
        .then((res) => {
          // client secret is set  here
          setClientSecret(res.clientSecret);
          setProcessing(false);
          setError(null);
        })
        .catch((err: BackendError) => {
          setError(err.error.message);
          setProcessing(false);
        });
    }
    return () => {
      setError(null);
    };
  }, []);

  const clickAuth = (event: any) => {
    setAuthChecked(!authChecked);
  };

  const handleCardChange = async (event: any) => {
    setCardFormUntouched(false);
    setError(event.error ? event.error.message : null);
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    if (!stripe) {
      return;
    }

    setProcessing(true);
    if (elements) {
      //createPaymentMethod();
      confirmPaymentOrSetup(clientSecret).then((res) => {
        switch (paymentReason) {
          case "register_card":
            if (res) handlePayload(res as SetupIntentResult);
            break;
          default:
            if (res) handlePayload(res as PaymentIntentResult);
            break;
        }
      });
    }
  };

  const confirmPaymentOrSetup = async (client_secret: string) => {
    let payload;

    switch (paymentMethod) {
      case "card":
        // can be stripeCreateAudienceInvoice | confirmCardPayment
        switch (paymentReason) {
          case "register_card":
            // get payment method back
            payload =
              stripe && elements
                ? await stripe
                    .confirmCardSetup(clientSecret, {
                      payment_method: {
                        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
                        ...billinData,
                      },
                    })
                    .catch((err) => {
                      console.log("error", err);
                    })
                : undefined;
            break;
          default:
            payload =
              stripe && elements
                ? await stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                      card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
                      ...billinData,
                    },
                  })
                : undefined;
        }

        break;
      case "iban":
        // direct pay by iban
        payload =
          stripe && elements
            ? await stripe.createPaymentMethod({
                type: "sepa_debit",
                sepa_debit: elements.getElement(IbanElement) as StripeIbanElement,
                billing_details: {
                  name: "Lebrun Meddy",
                  email: stripeAccount.stripeEmail,
                },
              })
            : undefined;
        break;
    }

    return payload;
  };

  // if payload:type is SetupIntentResult, a payment_method should be return in the payload
  // : write to store using stripeStoreActions.setSelectedCardMethod when props.paymentMethod = 'register_card
  // : and play props.onSuccess '
  const handlePayload = (payload: SetupIntentResult | PaymentIntentResult) => {
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      let setiResult = payload as SetupIntentResult;

      onSuccess && onSuccess();

      setError(null);
      setSucceeded(true);
    }
  };

  return (
    <>
      <Divider />
      <Box sx={{ my: 2, width: "400px" }}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <Box sx={{ width: "225px", margin: "auto" }}>
            {paymentMethod === "card" && <AddCardForm onChange={handleCardChange} />}
            {error && (
              <Box pb={2} sx={{ color: "#fc6050", textAlign: "center" }}>
                {error}
              </Box>
            )}
          </Box>

          <Box sx={{ px: 3, pb: 2 }}>
            <StyledCheckbox
              style={{ float: "left", marginRight: "10px", marginTop: "2px" }}
              onClick={clickAuth}
              checked={authChecked}
              size={"small"}
            />

            <Typography variant="body1" textAlign="justify" sx={{ cursor: "pointer" }} onClick={clickAuth}>
              {t("payment.authorizeEvorra")}
            </Typography>
          </Box>
          <Box sx={{ px: 3, pb: 2 }}>
            <Typography variant="body1" className="color-tech-grey" textAlign="justify">
              {t("payment.alertExplanation")}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button disabled={!authChecked || error !== null || cardFormUntouched} variant={"contained"} type={"submit"} id="submit">
              {processing && (
                <span>
                  <CircularProgress style={{ color: "white" }} size={"small"} variant={"indeterminate"} />
                  {t("payment.processing")} ...{" "}
                </span>
              )}
              {!processing && "Register card"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export interface ICheckoutProps<T = Record<string, any>> {
  paymentMethod: "card" | "iban" | "invoice";
  paymentReason: "pay_as_you_go" | "register_card";
  onSuccess?: Function;
  vars: {
    paymentType: string;
    paymentData: T;
  };
}

export const StripeForm = (props: ICheckoutProps) => {
  const { paymentMethod, paymentReason } = props;
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div>{t("payment.loading")}</div>}>
      <Elements stripe={promise}>
        <StripeFormD {...props} paymentMethod={paymentMethod} />
      </Elements>
    </Suspense>
  );
};
