import React from "react";
import {useNavigate} from "react-router-dom";
import {IAccounts} from "../../../application/entities/dataTypes/accounts";
import {useAppToast} from "../../../components/live/AppToast";
import {deleteAccount} from "./requests";
import {stripeService} from "../../../application/services/stripe.service";
import {AlertDeleteDialog} from "../../../components/live/AlertDialog";
import {ActionButtons} from "../../../components/controls/ActionButtons";
import {useTranslation} from "react-i18next";
import {objectApi} from "../../../application/entities/dataApi";
import {actions} from "application/actions/actions";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {pageUtils} from "../../../application/pages/pages.utils";
import {PageId} from "../../../application/pages/pages.config";
import {useAccountQuery} from "components/hooks/data/useAccountQuery";

export interface IButtonBlockEditAccountProps {
    account: IAccounts & Partial<IAccounts>;
    setAccount: (arg: IAccounts & Partial<IAccounts>) => void;
    viewMode?: boolean;
}

export const ButtonEditAccount: React.FC<IButtonBlockEditAccountProps> = ({account, setAccount, viewMode}) => {
    const history = useNavigate();
    const appToast = useAppToast();

    const [open, setOpen] = React.useState(false);
    const objectToDelete = {title: "Confirm", object: "Account"};

    const {t} = useTranslation();
    const accountsApi = new objectApi.accounts();
    const accountQuery = useAccountQuery(account.id);

    const handleChangeActivation = (args?: { accountId: number }) => {
        if (account && account.id) {
            const meth = account.activated ? accountsApi.suspendAccount : accountsApi.activateAccount;

            meth(account.id)
                .then((res) => {
                    if (res) setAccount({...account, activated: !account.activated});
                    // if AUTH.accountId === account.id
                    //invalidate cache
                    accountQuery.refetch();
                    appToast.open({
                        toastId: "manageAccount",
                        severity: "success",
                        message: t("message.account.updatedSuccess"),
                    });
                })
                .catch((err) => {
                    const message = err?.response?.data?.error?.message;
                    appToast.open({
                        toastId: "manageAccount",
                        severity: "error",
                        message: message ?? t("labels.error"),
                    });
                });
        }
    };

    const deleteAccountFun = (args?: { accountId: number }) => {
        if (account && account.id) {
            deleteAccount(account.id)
                .then((res) => {
                    appToast.open({
                        toastId: "manageAccount",
                        severity: "success",
                        message: t("message.account.deletedSuccess"),
                    });

                    setTimeout(() => history(pageUtils.getPagePathById(PageId.accountList)), 2000);
                })
                .catch((error) => {
                    const message = error?.response?.data?.error?.message;
                    appToast.open({
                        toastId: "manageAccount",
                        severity: "error",
                        message: message ?? t("labels.error"),
                    });
                });
        }
    };

    const [billingPath, setBillingPath] = React.useState("");

    React.useEffect(() => {
        if (account?.id) {
            stripeService
                .getStripePortal(`${account.id}`, window.location.href)
                .then((res) => {
                    setBillingPath(res);
                })
                .catch((error) => {
                    console.log({error});
                });
        }
    }, [account?.id]);

    const handleViewBillings = () => {
        window.open(billingPath, "stripeCustomerPage");
    };

    let actionsButtons = actions.account;
    actionsButtons.suspend.title = account.activated ? t("account.suspend") : t("account.activate");
    actionsButtons.billings.action = handleViewBillings;
    actionsButtons.billings.code = "view-billing";
    actionsButtons.suspend.action = handleChangeActivation;
    actionsButtons.billings.path = billingPath;

    if (!AUTHUser.isEvorraAdmin() && account.activated) {
        delete actionsButtons.dataVaultPermissions;
    }
    if (viewMode === true) {
        delete actionsButtons.create;
        actionsButtons.edit = {
            title: t("account.edit"),
            variant: "outlined",
            pageId: PageId.accountEdit,
        };
    }

    return (
        <>
            {account.id && (
                <>
                    <ActionButtons actions={actionsButtons}
                                   vars={{accountId: account.id, account, companyId: account.id}}/>
                    <AlertDeleteDialog open={open} setOpen={setOpen} acceptFunction={deleteAccountFun}
                                       objectToDelete={objectToDelete}/>
                </>
            )}
        </>
    );
};
