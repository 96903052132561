import React from 'react';
import {InputProps} from '@mui/material/Input/Input';
import {Input as MuiInput} from '@mui/material';
import styledOld from 'styled-components';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';

interface IInputProps {
  name: string;
  required?: boolean;
  label?: string;
  style?: any;
  inputElement?: any;
  helperText?: string;
}

export const Input              = React.forwardRef((props: IInputProps & InputProps, ref: any) => {
  
  const {type, helperText, label, required, inputElement, error} = props;
  
  const hasRef = ref ? {ref} : {};
  
  let inputOut: any;
  
  switch (type) {
    case 'element':
      inputOut = <InputWrapperStyled>{inputElement}</InputWrapperStyled>;
      break;
    default:
      inputOut = (
        <><MuiInput
          {...hasRef}
          required={required ?? false}
          style={{width: '100%', boxShadow: '0px 0px 6px rgba(196, 211, 241, 0.85)', borderRadius: '20px',}}
          {...props}
        />
        </>
      );
      break;
  }
  
  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      {inputOut}
      {error && <InputError>{helperText}</InputError>}
    </>
  );
});
export const InputWrapperStyled = styled('div')((props: { theme: Theme }) => ({
  margin      : '0.5rem 0 1rem 0',
  padding     : '0.5rem 1rem',
  boxSizing   : 'border-box',
  background  : props.theme.palette.background.default,
  color       : props.theme.palette.text.primary,
  borderRadius: '20px',
  overflow    : 'hidden',
  minWidth    : '4rem',
  boxShadow   : props.theme.skin.boxShadow,
  minHeight   : '30px'
}));

export const InputLabel = styledOld.div({
  fontWeight: 'bold',
  color     : '#9DAABF',
  paddingBottom   : '0.5rem'
});
const InputError        = styledOld.div({
  color  : '#FC6050',
  padding: '0.5rem'
});