import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import { objectApi } from '../../../application/entities/dataApi';
import { IVault, OperationStatus } from '../../../application/entities/dataTypes/vault';
import { ColorButton } from '../../../components/form/Buttons';
import { useAppToast } from '../../../components/live/AppToast';
import { useAdminPage } from 'components/hooks/useAdminPage';


interface ICreateVaultProps {
  disabled: boolean;
  setDisabled: (arg0: boolean) => void;
  accountId: number;
  vault?: IVault;
  setVault: (arg0: IVault) => void;
  createVaultDisabled: boolean;
  setCreateVaultDisabled: (arg0: boolean) => void;
  style?:any
}


export const CreateVault: React.FC<ICreateVaultProps> = ({
  disabled,
  setDisabled,
  accountId,
  vault,
  setVault,
  createVaultDisabled,
  setCreateVaultDisabled,
  style
}) => {

  const {t} = useTranslation();
  const accountsApi = new objectApi.accounts();
  const appToast = useAppToast();
  useAdminPage()

  const handleCreateVault = () => {
    accountsApi.createVault(accountId)
      .then(() => {
          if (vault) {
            setVault({ ...vault, lastVaultOperationStatus: OperationStatus.PENDING });
          }
        appToast.open({
          toastId: 'manageAccount',
          severity: 'info',
          message: t('dataVault.creationRequest'),
        });
        setCreateVaultDisabled(true);
        },
      )
      .catch(err => {
        const message = err?.response?.data?.error?.message;
        appToast.open({
          toastId: 'manageAccount',
          severity: 'error',
          message: message ?? t('labels.somethingWrong'),
        });
      });
  };

  return (
    <CreateVaultBox style={style}>
      <ColorButton
        onClick={() => handleCreateVault()}
        variant="contained"
        color="primary"
        disabled={createVaultDisabled}
      >
        {t('vaultSettingsPageLabels.createUpdateVault')}
      </ColorButton>
      <AwsIdentity>
        {t('vaultSettingsPageLabels.lastVaultOperationStatus')} 
        {vault ? ' '+ vault.lastVaultOperationStatus : ' Not Started'}
      </AwsIdentity>
      
    </CreateVaultBox>
  );
};

const CreateVaultBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
`;

const AwsIdentity = styled.div`
  padding: 10px;
  color:#525d70;
`;