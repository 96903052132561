
import React, { useEffect, useState } from 'react';
import { Chip, TextField } from '@mui/material';
import { FieldZone } from 'components/templates/InfoBlock';


interface IInputChipsProps {
    chips: string[];
    label?: string;
    placeholder?: string;
    validateUrl?: boolean;
    dataCy?: string;
    updateChips: (chips: string[]) => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const InputChips = (props: IInputChipsProps) => {
    
    const [chips, setChips] = useState<string[]>(props.chips);
    const [input, setInput] = useState<string>('');
    const [error, setError] = useState<string>('');
    const placeholder = props.placeholder ?? 'Enter an element and press enter';

    const handleKeyDown = (e: React.KeyboardEvent) => {
        
        if (e.key === 'Enter') {
            e.preventDefault();
            if(props.validateUrl){
                const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                if(!urlRegex.test(input)){
                    setError('Please enter a valid url')
                    return;
                }
            }
            if(chips.includes(input)){
                setInput('')
                setError('')
                return;
            } 
            setChips([...chips, input]);
            setInput('');
            setError('')
            props.updateChips([...chips, input])
        }
    };

    useEffect(() => {
        setChips(props.chips)
    }, [props.chips])

    const handleDelete = (chipToDelete: string) => () => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
        props.updateChips(chips.filter((chip) => chip !== chipToDelete))
    };

    return (
        <>
            <FieldZone title={props?.label}>
            <TextField
                fullWidth
                placeholder={placeholder}
                value={input}
                onChange={(e:any) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                data-cy={props.dataCy}
            />
            {error.length>0 && <div style={{color:'red'}}>{error}</div>}
            <div data-cy="chips_list" style={{marginTop:'10px', display:'flex',gap:'10px', flexWrap:'wrap', maxHeight:'200px', overflowY:'auto'}}>
            {chips?.map((chip) => (
                <Chip
                    data-cy="chip"
                    data-cy-value={chip}
                    key={chip}
                    label={chip}
                    onDelete={handleDelete(chip)}
                    size='small'
                    variant='outlined'
                    sx={{backgroundColor:'white', color:'#7587a3', fontSize:'11px'}}
                   
                />
            ))}
            </div>
            </FieldZone>
        </>
    );
};

export default InputChips;
