import styled from 'styled-components/macro';
import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


const defaultTheme = createTheme();

const ROW_HEIGHT = 60;
const ROW_MARGIN = 10;
// const containerHeight = (tableRows.length + 1) * (ROW_HEIGHT + ROW_MARGIN);

// const tableStyle = makeStyles(() =>
//   createStyles({
//     root: {
//       height: containerHeight,
//       "& .MuiDataGrid-renderingZone": {
//         maxHeight: `${containerHeight}px !important`,
//       },
//     },
//   })
// );
export const useStylesGrid = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        border: 0,
        minWidth: '350px',
        width: '350px',
        maxWidth: '100%',
        '& .MuiDataGrid-root': {
          border: '0px solid transparent',
          height: '100%',
          '& .MuiDataGrid-main': {
            border: '0px solid transparent',
            height: '100%',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '12px',
            },
            '& .MuiDataGrid-columnsContainer': {
              height: '40px!important',
              maxHeight: '40px!important',
              lineHeight: '40px!important',
              backgroundColor: '#F7F8FB',
              border: '1px solid #E4EAF0',
              boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
              borderRadius: '10px',
              '& .MuiDataGrid-columnHeader': {
                '&:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              },
            },
            '& .MuiDataGrid-window': {
              margin: '0px 0px 0px 0px',
              paddingTop: '0.5rem',
              border: '0px solid red',
              // paddingBottom: "40px",
              backgroundColor: 'none',
              '& .MuiDataGrid-dataContainer': {
                height: '100%',
                '& .MuiDataGrid-viewport': {
                  border: '0px solid red',
                  height: '100%',
                  // paddingBottom: '60px',

                  '& .MuiDataGrid-renderingZone': {
                    // maxHeight: `${containerHeight}px !important`,
                    // paddingBottom: '20px',
                    /* overflowX: "hidden",
                     overflowY: "auto",
                     height: "auto",
                     width: "100%", */
                    '& .super-app-theme--row, .MuiDataGrid-row': {
                      backgroundColor: '#FFFFFF',
                      fontSize: '0.8rem',
                      boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
                      //borderRadius: '10px',
                      height: '60px',
                      marginBottom: '10px',
                      width: "100%",
                      //border: '1px solid transparent',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #00B5E2',
                        boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
                      },
                      '& .MuiDataGrid-cell': {
                        border: 'none',
                        '&:focus': {
                          outline: 'none',
                        },
                      },
                    },
                    '& .metrics--row': {
                      backgroundColor: 'red',
                      fontSize: '0.8rem',
                      boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
                      borderRadius: '10px',
                      height: '60px',
                      marginBottom: '0',
                      width: '100%',
                      //border: '1px solid transparent',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #00B5E2',
                        boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
                      },
                      '& .MuiDataGrid-cell': {
                        border: 'none',
                        '&:focus': {
                          outline: 'none',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },

        '& .super-app-theme--cellWithIcon': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        '& .super-app-theme--active': {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
          borderRadius: '10px',
          paddingLeft: '10px',
          height: '60px',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #00B5E2',
            boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
          },
        },
        '& .super-app-theme--inactive': {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
          borderRadius: '10px',
          // margin: "10px 0",
          paddingLeft: '10px',
          height: '60px',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #00B5E2',
            boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
          },
        },
        '& .super-app-theme--Rejected': {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.6)',
          borderRadius: '10px',
          margin: '10px 0',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #00B5E2',
            boxShadow: '0px 5px 11px rgba(196, 211, 241, 0.8)',
          },
        },
        '& .super-app.active': {
          fontSize: '10px',
          color: '#02C697;',
          fontWeight: '600',
        },
        '& .super-app.inactive': {
          fontSize: '10px',
          color: '#FC6050',
          fontWeight: '600',
        },
        '& .super-app-theme--header': {
          width: '100%',
          minWidth: '100%',
          background: 'red',
        },
        '& .logo-block': {
          background: 'linear-gradient(180deg, #4262FF 19.27%, #37DEF9 72.92%)',
          padding: '1px',
          borderRadius: '50%',
          margin: '10px',
        },
        '& .logo': {
          background: '#FFFFFF',
          boxShadow: '0px 0px 3px rgba(222, 222, 222, 0.85)',
          color: '#3B4559',
          fontSize: '13px',
          borderTop: '1px transparent solid',
          width: '30px',
          height: '30px',
        },
      },
    };
  },
  { defaultTheme },
);


export const GridContent = styled.div`
  height: 100%;
  padding: 30px 30px 0 30px;
  max-width: 1920px;
  min-width: 1160px;
  width: 95%;
`;
