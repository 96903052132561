import React from 'react';
import styled from 'styled-components/macro';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {DetailBlock} from '../../../components/templates/InfoBlock';
import {InputLabel} from '../../../components/form/Input';


interface ICompanyAddressProps {
  account: Partial<IAccounts> ;
}

export const ViewAccountAddress: React.FC<ICompanyAddressProps> = ({
                                                                     account,
                                                                   }) => {
  const {t}         = useTranslation();
  return <>
    <CompanyAddressBlock>
      <div>
        <InputLabel>
          {t('account.accountAddressLabels.companyStreetAddress')}
        </InputLabel>
        <TextFieldBlock>
          <Typography>{account.streetAddress}</Typography>
        </TextFieldBlock>
      </div>
      <div>
        <InputLabel>
          {t('account.accountAddressLabels.postalCode')}
        </InputLabel>
        <TextFieldBlock>
          <Typography>{account.postalCode}</Typography>
        </TextFieldBlock>
      </div>
      <div>
        <InputLabel>
          {t('account.accountAddressLabels.state')}
        </InputLabel>
        <TextFieldBlock>
          <Typography>{account.state}</Typography>
        </TextFieldBlock>
      </div>
      <div>
        <InputLabel>
          {t('account.accountAddressLabels.country')}
        </InputLabel>
        <TextFieldBlock>
          <Typography>{account.country}</Typography>
        </TextFieldBlock>
      </div>
    </CompanyAddressBlock>
  </>;
};

const CompanyAddressBlock = styled(DetailBlock)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;

const TextFieldBlock = styled.div`

`;
