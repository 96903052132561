import {Box, Button, Checkbox, CircularProgress, Collapse, styled, TextField, Typography, useTheme} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {IDspChannelsSelections} from '../../application/entities/dataTypes/dspUserSelections';
import {useQuery} from 'react-query';
import {objectApi} from '../../application/entities/dataApi';
import React from 'react';
import {AppIcon} from '../../components/ui/AppIcon';
import {providerIconList} from '../../assets/providerIcons';
import {IDspParameters} from '../../application/entities/dataTypes/dspParameters';
import {useForm} from 'react-hook-form';
import {IDspEnablementOptions} from '../../application/entities/dataTypes/dspEnablementOptions';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';
import { useQueryClient } from 'react-query'
import { getDspAdditionalInfo } from 'application/utils/dsp.utils';

interface EditDspSelectionProps {
  accountId: number;
  dspChannelsSelectionsId: number;
  onClose?: () => void;
}


interface DspSelectionError {
  dspOptionsStatus: DspSelectionErrorStatus;
}

interface DspSelectionErrorStatus {
  [x: string]: 'ERROR' | 'OK'
}

export const DspAccountDspSelectionEdit = (props: EditDspSelectionProps) => {
  const {accountId, dspChannelsSelectionsId, onClose = () => {}} = props;
  
  const accountsApi = new objectApi.accounts();
  const theme       = useTheme();
  const {t}         = useTranslation();
  const queryClient = useQueryClient();

  const {register, handleSubmit, watch, formState: {errors, isValid, touchedFields, dirtyFields}} = useForm({mode: 'all'});
  
  
  
  const formValuesLive = watch()
  
  const myRef                                 = React.useRef<any>(null);
  const [parametersError, setParametersError] = React.useState<boolean>(false);
  //
  const [errorStatus, setErrorStatus]         = React.useState<DspSelectionErrorStatus>({});
  
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  
  const IconLoading = submitting ? <CircularProgress color={'primary'} size={16}/> : <></>;

  // fetch IT
  const dspAccountsSelectionQy = useQuery(['cscs' + dspChannelsSelectionsId], () => {return accountsApi.getDspChannelsSelectionsId(accountId, dspChannelsSelectionsId).then(res => res);});
  
  const channel: IDspChannelsSelections = dspAccountsSelectionQy?.data?.data ?? {} as IDspChannelsSelections;
  const selectedOptions: string[]       = channel?.dspEnablementOptions ? channel?.dspEnablementOptions.map((x: IDspEnablementOptions) => x.code) : [];
  
  /** Scrolls the component into view on open */
  React.useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, []);
  
  function onSubmit(data: any) {
    //
    const params                = {...data};
    //  build correct object
    params.dspEnablementOptions = Object.keys(data.dspEnablementOption ?? {}).filter((x: any) => data.dspEnablementOption[x]);
    params.dspChannelCode       = channel.dspChannel.code;
    
    setSubmitting(true);
    // check validity and submit
    accountsApi.checkDspChannelsSelection(accountId, {
      dspChannelCode : channel.dspChannel.code,
      parameterValues: data.parameterValues
    }).then((res: AxiosResponse<DspSelectionError | any>) => {
      // CMVP-2059
      setErrorStatus(res.data.dspOptionsStatus)
      
      /*console.log(err)
       return;*/
      if (Object.values(res.data.dspOptionsStatus).includes('ERROR')) {
        setParametersError(true);
        setSubmitting(false);
      } else {
        accountsApi.createUpdateDspChannelsSelections(accountId, params).then(res => {
          setParametersError(false);
          queryClient.invalidateQueries('dspChannelsSelections')
          dspAccountsSelectionQy.refetch();

          onClose();
          setSubmitting(false);
        }).catch(() => {
          setSubmitting(false);
        });
      }
    });
  }
  const hasDesc     = channel?.dspChannel?.dspParameters.filter((param: IDspParameters, idx: any) => Boolean(param.description)).length > 0 ? true : false;



  const isSspError = (code:string)=>{
    const dspEnablementOption = channel.dspChannel?.dspEnablementOptions?.find(dspChannelEnablementOption => dspChannelEnablementOption.code === code);
    const paramName = dspEnablementOption?.seatIdCode
   
    if(paramName && dspEnablementOption?.isSsp && formValuesLive?.parameterValues){
      const value = formValuesLive?.parameterValues[paramName];
      if(value?.length === 0 && formValuesLive?.dspEnablementOption[code]){
        return true
      }else{
        return false
      }
    } if(paramName && formValuesLive?.parameterValues){
      const value = formValuesLive?.parameterValues[paramName];
      if(value?.length === 0){
        return true
      }
    }

    return false
  }

  const convertSeatIdCode=(seatIdCode:string)=>{
    switch (seatIdCode) { 
      case 'partnerId': {
          return 'Partner ID'
      }
      case 'advertiserId': {
        return 'Advertiser ID'
      }
      default: {
        return seatIdCode
      }
    }
  }

  const idsErrors = ()=>{
    let errors: string[] = []
    channel.dspChannel?.dspEnablementOptions.forEach(dspChannelEnablementOption => {
      if(isSspError(dspChannelEnablementOption.code)){
        errors.push(convertSeatIdCode(dspChannelEnablementOption.seatIdCode!) + ' is required for ' + dspChannelEnablementOption.name)
      }
    })
    return errors
  }
  
  return (!channel?.dspChannel?.code || dspAccountsSelectionQy.isLoading) ? IconLoading : <EditDspSelectionContainer ref={myRef}>
    <Collapse in={!Boolean(dspAccountsSelectionQy.isLoading)} style={{transformOrigin: '0 0 0'}}>
      <form id={'form' + channel.dspChannel.code} name={'form' + channel.dspChannel.code} onSubmit={handleSubmit(onSubmit)}>
        <input type={'hidden'} {...register('isActive', {required: true})} value={channel.isActive as unknown as string}/>
        <div className={'pad-tb-3 border-b'}>
          <div className={'pad-l-3 flex-h flex-align-middle gap-small'}>
            <div className={'iconCircle'}><AppIcon display={'block'} fontSize={'small'} icon={providerIconList[channel.dspChannel.code]}/></div>
            <div><Typography sx={{fontWeight: 'bold'}}>{channel.dspChannel.name}</Typography></div>
            <div className={'flex-main'}/>
            <div className={'pad-r-3'}><AppIcon data-cy={'buttonClose'} color={'#7587A3'} onClick={onClose} fontSize={'small'} icon={'Close'}/></div>
          </div>
        </div>
        <div className={'flex-h gap-large pad-ii-2'} style={{minHeight: '80px'}}>
          <div className={'flex-v flex-main pad'}>
            <Box className={'flex-h flex-align-middle gap-small '} sx={{my: 1, width: '120px'}}>
              <Typography sx={{fontWeight: 'bold'}}>Seat Name</Typography>
            </Box>
            <TextField {...register('seatName', {required: true, minLength: 3})}
                       fullWidth={true}
                       defaultValue={channel.seatName}/>
          </div>
          <div className={'flex-main flex-v pad gap-small'}>
            {channel.dspChannel.dspParameters.map((param: IDspParameters, idx: number) => {
              const value = channel.parameterValues[param.code];
              const hasError = Boolean(errorStatus?.[param.code]==='ERROR');
              const errorText = `the ${param.parameterName} could not be validated, please check it and try again.`
              return <Box sx={{order: param.order}} key={idx} className={'flex-v '}>
                <Box sx={{my: 1, width: '120px'}}>
                  <Typography sx={{fontWeight: 'bold'}}>{param.parameterName} {Boolean(param.description) && '*'}</Typography>
                  {/*{Boolean(param.description) && <Typography>{t`dspChannelParameters.${param.description}`}</Typography>}*/}
                </Box>
                <Box>
                  <TextField {...register('parameterValues[' + param.code + ']', {required: false})}
                             onFocus={() => {setParametersError(false);}}
                             error={hasError}
                             defaultValue={value}
                             fullWidth={true}
                             helperText={hasError ? errorText :undefined}
                             name={'parameterValues[' + param.code + ']'}/>
                </Box>
              </Box>;
            })}
          </div>
          
          
          {Boolean(channel.dspChannel.dspEnablementOptions.filter((param: IDspEnablementOptions, idx: any) => !param.isSsp).length) && <div className={'flex-main pad'}>
              <Typography sx={{fontWeight: 'bold', my: 1}}>Enablement Options Available</Typography>
            {channel.dspChannel.dspEnablementOptions.filter((param: IDspEnablementOptions, idx: any) => !param.isSsp).map((param: IDspEnablementOptions, idx: any) => {
              if (channel.dspEnablementOptions.filter(dspOption => dspOption.code === param.code).length > 0) {
              }
              return <Box className={'flex-h flex-align-middle'}
                          data-cy-testid={param.code} key={idx}
                          sx={{py: 0.25}}>
                <div data-cy={'dspEnablementOptionsName'} className={'flex-main'}>
                {isSspError(param.code) && <span style={{color: 'red', marginRight: 7}}>X</span>}
                  {param.name}
                </div>
              </Box>;
            })}
          </div>}
          
          
          {Boolean(channel.dspChannel.dspEnablementOptions.filter((param: IDspEnablementOptions, idx: any) => param.isSsp).length) && <>
              <div className={'flex-main pad'}>
                  <Typography sx={{fontWeight: 'bold', my: 1}}>Enablement Methods</Typography>
                {channel.dspChannel.dspEnablementOptions.filter((param: IDspEnablementOptions, idx: any) => param.isSsp).map((param: IDspEnablementOptions, idx: any) => {

                  return <Box className={'flex-h flex-align-middle'}
                              data-cy-testid={param.code} key={idx}
                              sx={{py: 0.25, minWidth: '150px'}}>
                    <div data-cy={'dspSSPName'} className={'flex-main'}>
                      {isSspError(param.code) && <span style={{color: 'red', marginRight: 7}}>X</span>}
                      {getDspAdditionalInfo(param.code)?.name ?? param.name} <br/>
                      {getDspAdditionalInfo(param.code)?.restriction && <p className="color-tech-grey" style={{fontStyle:'italic'}}>{getDspAdditionalInfo(param.code)?.restriction}</p>}
                    </div>
                    <div><Checkbox defaultChecked={Boolean(selectedOptions.indexOf(param.code) !== -1)} size={'small'}
                                   {...register('dspEnablementOption[' + param.code + ']', {required: false})}
                                   name={'dspEnablementOption[' + param.code + ']'}/>
                    </div>
                  </Box>;
                })}
              </div>
          </>}
          <div style={{position: 'relative', width: '80px'}}>
            <div style={{position: 'absolute', bottom: '0', padding: '0.5rem 0'}}>
              <Button startIcon={IconLoading} sx={{maxWidth: '32px', px: 1}} disabled={!isValid || submitting } type={'submit'} variant={'contained'}>Save</Button>
            </div>
          </div>
        </div>
        {idsErrors().length >0 && <div style={{padding:'1rem'}}>
          {idsErrors().map((error, idx)=>{
            return <div key={idx} style={{color: 'red'}}>{error}</div>
          })}
          </div>}
       
        
        
        {Boolean(hasDesc) && <div className={'pad-ii-2 flex-h gap-small mt-1'}>
            <div><AppIcon fontSize={'small'} icon={'Info'}/></div>
            <div className={'flex-v '}>
              {channel.dspChannel.dspParameters.map((param: IDspParameters, idx: any) => {
                if (Boolean(param.description)) {
                  // @ts-ignore
                  const k: string = t(`dspChannelParameters.${param.description}`) as string;
                  return <Box sx={{order: param.order}} key={idx}>
                    <Box sx={{my: 0.5,}}>
                      <Typography sx={{fontWeight: 'bold'}}>* {param.parameterName}</Typography>
                      {Boolean(param.description) && <Typography>{k}</Typography>}
                    </Box>
                  </Box>;
                }
              })}</div>
        </div>}
        <div className={'flex-h flex-align-middle marg-t-2 gap-small  pad-b-4 pad-r-3'}/>
      </form>
    </Collapse>
  </EditDspSelectionContainer>;
};

const EditDspSelectionContainer = styled(Box)((props: { theme: Theme }) => ({
  background  : props.theme.palette.background.default,
  padding     : '0.25rem',
  borderRadius: '12px',
  border      : '1px solid ' + props.theme.palette.primary.main
}));
