import { GridRowParams, GridSortModel } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridTable } from "../../../components/organisms/Grid";
import { MainFrame } from "../../../components/templates/MainFrame";
import styled from "styled-components/macro";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import { H4 } from "components/ui/AppElements";
import { objectApi } from "../../../application/entities/dataApi";
import { dataColumnModelUtils } from "../../../application/utils/dataColumnModel.utils";
import { accountsColumns, dataClientColumns, dataProviderColumns } from "../../../application/entities/dataColumns/accounts.columns";
import useLocalStorageState from "use-local-storage-state";
import { AccountCard } from "../Card";
import { IAccounts } from "../../../application/entities/dataTypes/accounts";
import { AppDrawer } from "../../../components/ui/AppDrawer";
import { pageUtils } from "../../../application/pages/pages.utils";
import { ActionButtons } from "../../../components/controls/ActionButtons";
import { actions } from "../../../application/actions/actions";
import { useTranslation } from "react-i18next";
import { PageId } from "../../../application/pages/pages.config";
import { ChevronDownBlue } from "assets/icons";
import { AppIcon } from "components/ui/AppIcon";
import { AppTabs } from "components/ui/Tabs";
import { useAdminPage } from "components/hooks/useAdminPage";

const accountsApi = new objectApi.accounts();

export const AccountsList = <T,>(initData: T) => {
  interface IAccountQuery {
    accountType?: "COMPANY" | "BRAND" | "ALL";
    dataClient?: boolean | undefined;
    dataProvider?: boolean | undefined;
    kw?: string | undefined;
  }

  type TAccountListTab = {
    idx: number;
    id: number;
    text: string;
    value: string;
    field: string;
  };

  const history = useNavigate();
  useAdminPage();

  const [exportReference, setExportReference] = useState<React.Ref<HTMLButtonElement>>(null);
  const [countAccounts, setCountAccounts] = useLocalStorageState<number>("acc-countAccounts", 0);
  const [activeAccount, setActiveAccount] = useLocalStorageState<IAccounts>("acc-activeAccount", {} as IAccounts);
  const [openDrawer, setOpenDrawer] = useLocalStorageState<boolean>("acc-drawer", false);
  const { t } = useTranslation();
  const [rowCompanies, setRowCompanies] = useLocalStorageState<IAccounts[]>("acc-rowCompanies", []);
  const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>("acc-sortModel", [{ field: "id", sort: "asc" }]);
  const [pageConfig, setPageConfig] = useLocalStorageState<{
    pageSize: number;
    pageNumber: number;
  }>("acc-pageConfig", {
    pageSize: 50,
    pageNumber: 0,
  });

  const tabs: TAccountListTab[] = [
    { idx: 0, id: 1, text: `All`, value: `ALL`, field: "clientTypeFilter" },
    { idx: 1, id: 2, text: `Data client`, value: `DATACLIENT`, field: "clientTypeFilter" },
    { idx: 2, id: 3, text: `Data provider`, value: `DATAPROVIDER`, field: "clientTypeFilter" },
  ];
  const inputSearchField = React.useRef(null);
  const timerSearchField: any = React.useRef();

  const [loading, setLoading] = useState<boolean>(false);
  const [filterAccountQuery, setFilterAccountQuery] = useState<IAccountQuery>({});
  const [selectedTab, setSelectedTab] = useState<TAccountListTab>(tabs[0]);

  const fetchData = useCallback(() => {
    setLoading(true);
    accountsApi
      .search({
        page: pageConfig,
        query: filterAccountQuery,
        sort: {
          sortBy: sortModel[0].field,
          sortDir: sortModel[0].sort ?? "asc",
        },
      })
      .then((res) => {
        if (res.data.searchResult.content.length > 0) {
          const accounts = res.data.searchResult.content;
          const totalElements = res.data.searchResult.totalElements;
          setRowCompanies(accounts);
          setCountAccounts(totalElements);
        } else {
          setRowCompanies([]);
          setCountAccounts(0);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [pageConfig, sortModel, filterAccountQuery, setCountAccounts, setRowCompanies]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (pageNumber: number) => {
    setPageConfig((curr) => {
      return { ...curr, pageNumber };
    });
  };

  const handleSortModelChange = (params: GridSortModel) => {
    if (params[0].field !== sortModel[0].field || params[0].sort !== sortModel[0].sort) {
      setSortModel(params);
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageConfig((curr) => {
      return { ...curr, pageSize, pageNumber: 0 };
    });
  };

  const handleRowClick = (param: GridRowParams<IAccounts>) => {
    if (param.row.id === activeAccount?.id) {
      setOpenDrawer(!openDrawer);
    } else {
      setOpenDrawer(true);
    }
    setActiveAccount(param.row);
  };

  const handleEditAccount = (param: GridRowParams) => {
    if (activeAccount?.id) {
      history(
        pageUtils.getPagePathById("edit-accounts", {
          accountId: activeAccount.id,
        })
      );
    }
  };

  const updateFilterAccountQuery = (field: string, value: string) => {
    setPageConfig((curr) => {
      return { ...curr, pageNumber: 0 };
    });
    if (field === "accountTypeFilter") {
      setFilterAccountQuery((prevState: any) => {
        return {
          ...prevState,
          accountType: value === "ALL" ? undefined : value,
        };
      });
    }
    if (field === "clientTypeFilter") {
      setFilterAccountQuery((prevState: any) => {
        return {
          ...prevState,
          dataClient: value === "ALL" ? undefined : value === "DATACLIENT" ? true : undefined,
          dataProvider: value === "ALL" ? undefined : value === "DATAPROVIDER" ? true : undefined,
        };
      });
    }
    if (field === "kw") {
      setFilterAccountQuery((prevState: any) => {
        return {
          ...prevState,
          kw: value,
        };
      });
    }
  };

  const resetSearch = () => {
    setFilterAccountQuery((prevState: any) => {
      if (prevState?.kw) {
        delete prevState.kw;
        return prevState;
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;
    console.log("handleSearchChange", searchString);
    if (searchString.length === 0) {
      resetSearch();
    }
    if (searchString && searchString.trim().length !== 0) {
      if (timerSearchField.current) {
        window.clearTimeout(timerSearchField.current);
      }

      timerSearchField.current = window.setTimeout(() => {
        updateFilterAccountQuery("kw", searchString);
      }, 800);
    } else {
      resetSearch();
    }
  };

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
    updateFilterAccountQuery(tab.field, tab.value);
    if (tab.value !== "DATACLIENT") {
      setFilterAccountQuery((prevState: any) => {
        return {
          ...prevState,
          accountType: "ALL",
        };
      });
    }
    updateFilterAccountQuery("accountTypeFilter", "ALL");
  };

  const headerProperties = (
    <>
      <Title>{t("account.generalTitles.totalNumber")}</Title>
      <Box sx={{ pt: 1, display: "flex" }}>
        <H4 data-cy={"total-accounts"}>{countAccounts}</H4>
        <IconGreen />
      </Box>
    </>
  );

  actions.accountsList.download.action = () => {
    accountsApi.get().then((res) => {
      const allRow = res?.data?._embedded?.accounts;
      const columns = dataColumnModelUtils.renderModel(accountsColumns, "show") as any;
      if (allRow && allRow.length > 0 && columns && columns.length > 0) {
        const contentString =
          columns.map((col: any) => col.headerName).join(",") +
          "\r" +
          allRow.map((row: any) => columns.map((col: any) => row[col.field]).join(",")).join("\r");
        downloadInCSV(contentString, "accounts_to_csv");
      }
    });
  };

  function downloadInCSV(content: string, name: string) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", name + ".csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  // Go to home action
  if (activeAccount.accountType === "BRAND") {
    actions.accounts.goToHome.pageId = PageId.brandDashboard;
  }
  if (activeAccount.accountType === "COMPANY") {
    actions.accounts.goToHome.pageId = PageId.companyDashboard;
  }
  // @ts-ignore
  if (!["BRAND", "COMPANY"].includes(activeAccount.accountType)) {
    actions.accounts.goToHome.pageId = PageId.dashboardDataProvider;
  }

  const headerButtons = <ActionButtons actions={actions.accountsList} vars={1} />;

  // @ts-ignore
  return (
    <MainFrame
      frameId={"accounts"}
      headerProps={{
        headerProperties,
        headerButtons,
      }}
    >
      <div style={{ height: "calc(100% - 55px)" }} className="pad-2">
        <div style={{ opacity: loading ? "0.5" : "1", paddingTop: "10px", paddingBottom: "15px" }}>
          <AppTabs activeTab={selectedTab.idx} tabList={tabs} onClick={handleTabChange}>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "20px" }}>
              <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                <div style={{ color: "#7587A3", width: "250px" }}>Company / brand name or ID </div>
                <TextField
                  inputRef={inputSearchField}
                  autoFocus
                  fullWidth={true}
                  type={"search"}
                  onChange={handleSearchChange}
                  data-cy={"account-search"}
                  InputProps={{
                    startAdornment: (
                      <div style={{ marginLeft: "0.5rem" }}>
                        <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} icon={"SearchIcon"} />
                      </div>
                    ),
                  }}
                />
              </div>

              {selectedTab.value === "DATACLIENT" && (
                <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                  <div style={{ color: "#7587A3" }}>Account type</div>
                  <Select
                    data-cy={"select-accout-type"}
                    role="select"
                    sx={{ width: "120px", ".MuiSelect-select": { color: "#7587A3 !important" } }}
                    onChange={(event: any) => {
                      updateFilterAccountQuery("accountTypeFilter", event.target.value);
                    }}
                    value={filterAccountQuery?.accountType ?? "ALL"}
                    IconComponent={ChevronDownBlue}
                  >
                    <MenuItem key={"all"} role="option" value={"ALL"}>
                      All
                    </MenuItem>
                    <MenuItem key={"company"} value={"COMPANY"}>
                      Companies
                    </MenuItem>
                    <MenuItem key={"brand"} value={"BRAND"}>
                      Brands
                    </MenuItem>
                  </Select>
                </div>
              )}
            </div>
          </AppTabs>
        </div>
        <GridTable
          dataCy="account-list"
          loading={loading}
          data={{
            columns: dataColumnModelUtils.renderModel(
              selectedTab?.value === "DATACLIENT" ? dataClientColumns : selectedTab?.value === "DATAPROVIDER" ? dataProviderColumns : accountsColumns,
              "show"
            ) as any,
            rows: rowCompanies ?? [],
          }}
          pagerProps={{
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            activePage: pageConfig.pageNumber,
            maxCount: countAccounts,
            pageSize: pageConfig.pageSize,
          }}
          gridProps={{
            onRowDoubleClick: (param: GridRowParams<IAccounts>) => handleEditAccount(param),
            onRowClick: (param: GridRowParams<IAccounts>) => handleRowClick(param),
            sortingMode: "server",
            sortingOrder: ["desc", "asc"],
            sortModel: sortModel,
            onSortModelChange: handleSortModelChange,
            componentsProps: {
              toolbar: {
                exportReference: exportReference,
                setExportReference: setExportReference,
              },
            },
          }}
        />
      </div>

      <AppDrawer
        title={activeAccount?.companyName}
        icon={"CompanyListIcon"}
        open={openDrawer}
        actionButtons={actions.accounts}
        actionButtonsVars={{
          accountId: activeAccount?.id,
          companyId: activeAccount?.id,
          brandId: activeAccount?.id,
          accountType: activeAccount?.accountType,
        }}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <AccountCard account={activeAccount} />
      </AppDrawer>
    </MainFrame>
  );
};

const Title = styled.div`
  color: #9daabf;
`;

const IconGreen = styled.div`
  width: 6px;
  height: 6px;
  background: #02c697;
  border-radius: 50%;
  margin: 0 5px;
`;
