import { TextField, Theme } from "@mui/material";
import React, { useEffect } from "react";
import { ISubFeaturesDto } from "../../../../application/entities/dataTypes/subFeatures";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { EventNote, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/styles";
import _ from "lodash";
import moment from "moment";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { optSubFeatureDate } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { useShallow } from "zustand/react/shallow";
import { dataUtils } from "../../../../application/utils/dataState.utils";

interface SubFeatureTypeDateProps {
  subFeature: ISubFeaturesDto;
}

export const InputSubFeatureDate = (props: SubFeatureTypeDateProps) => {
  const { subFeature } = props;
  const audienceActions = useAudienceBuilderStore.getState().actions;

  // read the audienceFeature.subFeature.featureValues
  const allAudienceFeature = useAudienceBuilderStore(
    (st) => dataUtils.getObjectItemById(st.audience?.audienceFeatures ?? [], subFeature.id, "subFeatureId"),
    (old, ne) => _.isEqual(old, ne)
  );

  const TravelStartReturnAudienceFeatures = useAudienceBuilderStore(
    useShallow((st) => st.audience?.audienceFeatures?.filter((feat) => feat.feature.code === "TRAVELPERIOD") ?? [])
  );

  const actualValues: optSubFeatureDate = allAudienceFeature
    ?.map((item) => {
      try {
        return JSON.parse(item.featureValues?.[0]);
      } catch (e) {}
    })
    .flat()[0];

  const [minDate, setMinDate] = React.useState<string | null>(null);
  const [maxDate, setMaxDate] = React.useState<string | null>(null);

  useEffect(() => {
    //if in travel audience, set the date selectable limits
    let startDate = null;
    if (TravelStartReturnAudienceFeatures.filter((feat) => feat?.subFeature?.code === "TRAVELSTART")[0]?.featureValues[0]) {
      startDate =
        JSON.parse(TravelStartReturnAudienceFeatures.filter((feat) => feat?.subFeature?.code === "TRAVELSTART")[0]?.featureValues[0])?.value ?? null;
    }
    let returnDate = null;
    if (TravelStartReturnAudienceFeatures.filter((feat) => feat?.subFeature?.code === "TRAVELRETURN")[0]?.featureValues[0]) {
      returnDate =
        JSON.parse(TravelStartReturnAudienceFeatures.filter((feat) => feat?.subFeature?.code === "TRAVELRETURN")[0]?.featureValues[0])?.value ?? null;
    }
    if (subFeature?.code === "TRAVELSTART") {
      setMaxDate(returnDate);
    }
    if (subFeature?.code === "TRAVELRETURN") {
      setMinDate(startDate);
    }
  }, [TravelStartReturnAudienceFeatures]);

  let date = Boolean(actualValues) ? new Date(actualValues.value) : null;

  const handleDateChange = (date: any) => {
    // MaterialUiPickersDate
    const newDate = moment(new Date(date)).format("YYYY-MM-DD");

    const data = JSON.stringify({ text: subFeature.name, value: newDate, order: subFeature.order });

    audienceActions.insertSubFeatureValues(subFeature, data).then((res) => {});
  };

  return (
    <div style={{ flex: 1, order: subFeature.order }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ keyboardDate: "dd/mm/yyyy" }}>
        <TextFieldLabel>{subFeature.name}</TextFieldLabel>
        <div>
          <DatePicker
            components={{
              OpenPickerIcon: EventNote,
              SwitchViewIcon: ExpandMore,
            }}
            value={date}
            inputFormat={"dd/MM/yyyy"}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            showDaysOutsideCurrentMonth={true}
            OpenPickerButtonProps={{ edge: "start", size: "small", color: "primary" }}
            renderInput={(params: any) => {
              const par = {
                ...params,
                className: "dateTextField",
                inputProps: {
                  ...params.inputProps,
                  className: "smallPadding",
                  style: { padding: "0.5rem 0.5rem 0.5rem 0.5rem" },
                },
              };
              return <TextField className="dateTextField" data-cy={"date" + subFeature.code} {...par} />;
            }}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

const TextFieldLabel = styled("div")((props: { theme: Theme; active?: boolean }) => ({
  fontFamily: props.theme.typography.fontFamily,
  fontWeight: "bold",
  color: "#9DAABF",
  padding: "0rem",
  fontSize: 14,
  marginBottom: "8px",
}));
