import React from 'react';

export class AppHistory {
  get activeStep(): number {
    return this._activeStep;
  }
  
  private historyName: string             = '';
  private _activeStep: number             = 0;
  private _activeActionStep: number       = 0;
  private _historySteps: unknown[]        = [];
  private _historyActionSteps: Function[] = [];
  
  constructor(historyName: string, firstDataSet: unknown) {
    this.historyName = historyName;
    if (firstDataSet) this._historySteps.push({});
  }
  
  
  get historySteps(): unknown[] {
    return this._historySteps;
  }
  
  private _isListening: boolean = true;
  
  set isListening(value: boolean) {
    this._isListening = value;
  }
  
  initialize<T>(firstDataSet: T) {
    this._historySteps = [];
    this.pushStep(firstDataSet);
    return this;
  }
  
  static init<T>(historyName: string, firstDataSet: T) {
    return new this(historyName, firstDataSet);
  }
  /** @deprecated */
  pushActionStep(content: Function) {
    if (this._isListening) {
      this._historyActionSteps.push(content);
      this._activeActionStep = this._historyActionSteps.length;
    }
  }
  
  pushStep(content: any) {
    if (this._isListening) {
      this._historySteps.push(content);
      this._activeStep = this._historySteps.length;
    }
  }
  
  stepBackward() {
    this._activeStep = --this._activeStep;
    return this.returnStep(this._activeStep);
  }
  
  stepForward() {
    this._activeStep = ++this._activeStep;
    return this.returnStep(this._activeStep);
  }
  
  pickStep(index: number) {
    this._activeStep = index;
    return this.returnStep(index);
  }
  
  private returnStep(index: number) {
    return this._historySteps[index - 1];
  }
  
  private playActionStep(index: number) {
    if (this._historyActionSteps?.[index]) {
      if (typeof this._historyActionSteps?.[index] === 'function') {
        return this._historyActionSteps?.[index]();
      }
    }
  }
  
}

export const appHistoryInstance = new AppHistory('audienceData', {});

export const useAppHistory = (insideData: any) => {
  const [historyState, setHistoryState] = React.useState<unknown>();
  const [hasPrevious, setHasPrevious]   = React.useState<boolean>();
  const [hasNext, setHasNext]           = React.useState<boolean>();
  
  
  // non efficient
  React.useEffect(() => {
    setHasPrevious(appHistoryInstance.activeStep > 1);
    setHasNext(appHistoryInstance.activeStep < appHistoryInstance.historySteps?.length);
  }, [insideData, historyState]);
  
  return {
    historyDataState: historyState,
    hasPrevious     : hasPrevious,
    hasNext         : hasNext,
    initHistory     : (data: any) => {
      appHistoryInstance.initialize(data);
    },
    stepBackward    : () => {
      setHistoryState(appHistoryInstance.stepBackward());
    },
    stepForward     : () => {
      setHistoryState(appHistoryInstance.stepForward());
    },
    setListenOff    : () => {
      appHistoryInstance.isListening = false;
    },
    setListenOn     : () => {
      appHistoryInstance.isListening = true;
    },
  };
};