import { objectApi } from "application/entities/dataApi";
import { useQuery } from "react-query";
import { IAccountConfParameters } from "types";

export const useAccountConfParameters = (accountId: number) => {
  const accountsApi = new objectApi.accounts();
  const { isLoading, error, data, refetch, remove } = useQuery<IAccountConfParameters>(
    ["account", +accountId, "confParameters"],
    () => {
      return accountsApi.getAccountConfParameters(+accountId).then((res) => res?.data?.accountConfParameters);
    },
    {
      enabled: Boolean(accountId),
    }
  );
  return { isLoading, error, data, refetch, remove };
};
