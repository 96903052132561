import { objectApi } from "application/entities/dataApi";
import { IDspChannelsSelections } from "application/entities/dataTypes/dspUserSelections";
import { PageId } from "application/pages/pages.config";
import { useAllDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { MainFrame } from "components/templates/MainFrame";
import { useAccountTracker } from "pages/Agencies/useAccountTracker";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { ChannelsSetupWrap } from "../ChannelsSetupAdd/ChannelsSetupAdd";
import { useChannelsSetupStore } from "../ChannelsSetupStore";
import { ChannelConfiguration, IChannelConfig } from "../components/ChannelConfiguration";

export const ChannelsSetupEdit = () => {
  let { accountId, channelId } = useParams<{ accountId: string; channelId: string }>() as { accountId: number; channelId: number };
  useAccountTracker(accountId);

  const accountsApi = new objectApi.accounts();

  const parentAccountId = useQuery(
    ["parentAccountId", +accountId],
    () => {
      return accountsApi.byId(+accountId);
    },
    { enabled: Boolean(accountId) }
  );

  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<IDspChannelsSelections | undefined>(undefined);

  const channelConfig: IChannelConfig = {
    seatName: channel?.seatName ?? "",
    isActive: channel?.isActive ?? true,
    sendMethods: channel?.dspEnablementOptions?.map((option) => option.code) || [],
    parameterValues: channel?.parameterValues,
  };
  const channelsSetupStore = useChannelsSetupStore();
  const dspChannelsSelected = useAllDspChannels(+accountId);

  useEffect(() => {
    channelsSetupStore.setAccountId(+accountId);
    accountsApi.getDspChannelsSelectionsId(accountId, channelId).then((res) => {
      setChannel(res.data);
    });
  }, [accountId, channelId]);

  useEffect(() => {
    if (channel) {
      const currentChannel = dspChannelsSelected.data?.filter((dsp) => dsp.code === channel.dspChannel.code)[0];
      channelsSetupStore.setDspSelected(currentChannel);
      setLoading(false);
    }
  }, [channel]);

  useEffect(() => {
    channelsSetupStore.setAccountId(+accountId);
    return () => {
      channelsSetupStore.reset();
    };
  }, []);

  return (
    <MainFrame
      frameId={PageId.channelsSetupEdit}
      checkPageAccess={true}
      grantWith={{ accountId: parentAccountId?.data?.parentCompanyId }}
      loading={loading}
      breadCrumbProps={{
        params: { accountId: accountId.toString(), companyId: parentAccountId?.data?.parentCompanyId, brandId: accountId.toString() },
      }}
    >
      {!loading && (
        <div style={{ height: "100%", overflow: "auto" }}>
          <ChannelsSetupWrap sx={{ boxShadow: 3 }}>
            <ChannelConfiguration channelConfig={channelConfig} />
          </ChannelsSetupWrap>
        </div>
      )}
    </MainFrame>
  );
};
