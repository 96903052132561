import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";
import _ from "lodash";

declare module "@mui/material/styles" {
  interface Theme {
    skin: {
      borderColor: string;
      boxShadow: string;
      boxShadowHover: string;
      grey1: string;
    };
  }

  interface ThemeOptions {
    skin?: {
      borderColor?: string;
      boxShadow?: string;
      boxShadowHover?: string;
      grey1?: string;
    };
  }
}

const rawTheme = {
  palette: {
    mode: "light",
    action: {
      disabled: "#ededed",
    },
    primary: {
      main: "#00B5E2", // Evorra blue
    },
    secondary: {
      main: "#3B4559", // space grey
    },
    error: {
      main: "#FC6050",
    },
  },
};

const mainTheme = (rawTheme: Theme) => ({
  skin: {
    borderColor: rawTheme.palette.mode === "light" ? "rgb(237, 237, 237,0.9)" : "rgba(255,255,255,0.2)",
    boxShadow: rawTheme.palette.mode === "light" ? "0px 0px 6px rgba(196, 211, 241, 0.60)" : "0px 0px 6px rgb(22, 22, 22, 0.6)",
    boxShadowHover: rawTheme.palette.mode === "light" ? "0px 0px 8px rgba(196, 211, 241, 1)" : "0px 8px 12px rgb(22, 22, 22, 0.9)",
    grey1: rawTheme.palette.mode === "light" ? "rgb(229, 232, 238)" : "rgba(229, 232, 238,0.1)",
  },
  palette: {
    text: {
      primary: "#3B4559",
    },
    primary: {},
    background: {
      default: rawTheme.palette.mode === "light" ? "#fff" : "#313335",
      paper: rawTheme.palette.mode === "light" ? "#f8f9fb" : "#2b2b2b",
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", "OpenSans", sans-serif',
    color: "#3B4559",
    fontSize: 12,
    htmlFontSize: 12,
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 12,
    },
    button: {
      fontFamily: '"Nunito Sans", "OpenSans", sans-serif',
      fontSize: 12,
    },
  },
});

const componentTheme = (rawTheme: Theme) => ({
  palette: {
    techGrey: {
      main: "#3B4559",
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        virtualScrollerRenderZone: {
          maxWidth: "calc(100% - 5px)",
        },
        columnHeaderTitle: {
          color: "#7587A3",
          fontWeight: 500,
        },
        cell: {
          color: "#3B4559",
          outline: "0 !important",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "rgba(142, 142, 142, 0.1)",
          fontFamily: "Nunito Sans",
          fontWeight: "bold",

          ".PrivatePickersFadeTransitionGroup-root": {
            fontSize: "15px",
          },

          ".MuiSvgIcon-root": {
            color: "#00B5E2",
          },

          ".MuiTypography-root": {
            fontSize: "small",
            color: "#A1A1A1",
          },

          ".MuiPickersDay-root": {
            fontSize: "small",
            color: "#7593A3",
            borderRadius: "10px",
            "&:active": {
              backgroundColor: "#00B5E2!important",
              color: "white!important",
            },
          },

          ".Mui-selected": {
            backgroundColor: "#00B5E2!important",
            color: "white!important",
          },

          ".MuiPickersDay-today": {
            borderColor: "#7593A3!important",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          margin: "0",
          "& fieldset": {
            border: 0,
          },
        },
        popper: {
          border: "0.5px solid #DBE0EB",
          boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
          borderRadius: "12px",
          overflow: "hidden",
          minWidth: "300px",
        },
        listbox: {
          margin: 0,
          padding: 0,
          zIndex: 1,
          listStyle: "none",
          overflow: "auto",
          borderRadius: "12px",
          '& li[data-focus="true"]': {
            backgroundColor: "#EFF4F8",
            cursor: "pointer",
          },
          "& li:active": {
            color: rawTheme.palette.primary.main,
            background: "none",
          },
          "& li[aria-selected='true']": {
            color: rawTheme.palette.primary.main,
            background: "none !important",
          },
        },
        groupLabel: {
          fontSize: 12,
          lineHeight: "inherit",
          padding: "0.5rem 0",
        },
        option: {
          fontSize: 12,
          borderRadius: "6px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          display: "block",
          maxWidth: "100%",
          backgroundColor: rawTheme.palette.background.default,
          "&:hover": {
            color: "inherit",
            backgroundColor: rawTheme.palette.background.default,
          },
          "&:active": {
            color: rawTheme.palette.primary.main,
            backgroundColor: rawTheme.palette.background.default,
          },

          "&.Mui-focused": {
            color: rawTheme.palette.primary.main,
            backgroundColor: rawTheme.palette.background.default + "!important",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "30px",
          height: "30px",
          boxShadow: "0px 0px 3px rgba(222, 222, 222, 0.85)",
          color: "#FFFFFF",
          fontSize: "13px",
          borderTop: "1px transparent solid",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
          padding: "3px 10px",
          width: "fit-content",
          minWidth: "5rem",
          height: "30px",
          textTransform: "none",
          borderRadius: "20px",
        },
        contained: {
          color: "white",
          //background  : 'linear-gradient(94.21deg, #00b5e2 0%, #10caf9 100%)',
          boxShadow: "0px 4px 9px rgba(14, 199, 246, 0.3)",
          borderRadius: "20px",
          padding: "0 16px",
          "&:active": {
            backgroundColor: "rgba(0, 181, 226, 0.5)",
            boxShadow: "none",
            transform: "scale(0.98)",
          },
          "&:hover": {
            boxShadow: "0px 4px 9px rgba(14, 199, 246, 0.3)!important",
          },
          "&:focus": {
            outline: "none",
            boxShadow: "none",
          },
          "&:disabled": {
            color: "#3B4559",
            background: "#F0F2F7",
            boxShadow: "none",
          },
        },
        outlined: {
          // color: '#00B5E2!important',
          //background  : 'white',
          boxShadow: "none",
          borderRadius: "20px",
          //border      : '1px solid #00B5E2',
          "&:error": {
            color: "red",
          },
          "&:active": {
            backgroundColor: "rgba(0, 181, 226, 0.5)",
            boxShadow: "none",
            transform: "scale(0.98)",
            border: "0.5px solid #00B5E2",
            color: "white",
          },
          "&:hover": {
            boxShadow: "0px 4px 9px rgba(14, 199, 246, 0.3)!important",
          },
          "&:focus": {
            outline: "none",
            boxShadow: "none",
          },
          "&:disabled": {
            border: "0.5px solid ccc",
            opacity: "0.8",
            color: "rgba(25, 116, 140,0.6)!important",
          },
        },
        sizeSmall: {
          height: "20px",
          padding: "3px 10px",
          minWidth: "auto",
          width: "auto",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: 12,
          htmlFontSize: 12,
          // borderRadius : '20px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root, &.MuiIconButton-root": {
            padding: "0!important",
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        input: {
          "& .Mui-error": {
            border: "1px solid red",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: "0!important",
        },
        input: {
          borderRadius: "20px",
          padding: "6px 10px!important",
          // minHeight           : '30px',
          backgroundColor: rawTheme.palette.background.default,
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 30px white inset !important",
          },
          "& .Mui-error": {
            border: "1px solid red",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // border: '1px solid #DBE0EB',
          borderRadius: "20px",
          backgroundColor: rawTheme.palette.background.default,
          "& fieldset": {
            borderRadius: "20px",
            boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          padding: 0,
          "& MuiPaper-rounded": {
            borderRadius: "12px",
          },
          "& .MuiDialog-paperWidthSm": {
            maxWidth: "700px",
          },
          "& .MuiDialogContent-root": {
            padding: 0,
            margin: 0,
            "&:first-of-type": {
              padding: 0,
            },
          },
        },
        paper: {
          padding: 0,
          maxWidth: "750px",
          border: "1px solid #00B5E2",
          borderRadius: "12px",
        },
        container: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // margin: '0.25rem 0',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {},
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          padding: [rawTheme.spacing(2), rawTheme.spacing(0.5)],
          lineHeight: "inherit!important",
          backgroundColor: "transparent",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          padding: rawTheme.spacing(0),
        },
        "& a": {
          color: "red",
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: rawTheme.spacing(4),
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          //marginRight: '0.5rem'
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          fontSize: 12,
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
          "& .MenuItemTitle": {
            margin: "0.5rem 1rem",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
          htmlFontSize: "0.75rem",
          borderRadius: "8px",
          margin: "0 0.5rem",
          padding: "0.25rem 1rem",
          minHeight: "16px",
          "&.Mui-selected": {
            background: "none",
            color: rawTheme.palette.primary.main,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          //border:'1px solid red'
          maxHeight: "100%",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
          minWidth: "230px",
          //boxShadow   : rawTheme.skin.boxShadow,
          maxHeight: "90%",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {},
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
          border: "1px solid #DBE0EB",
          backgroundColor: rawTheme.palette.background.default,
          // minWidth                               : '10px',
          "& .MuiSelect-select.MuiSelect-select ": {
            marginRight: "-7px",
            // width         : '100%',
            boxShadow: "none",
            fontWeight: "normal",
            fontSize: "12px",
            // lineHeight    : '20px',
            color: "#3B4559",
            background: "transparent",
            justifyContent: "center",
            "&:focus": {
              //border         : '0.5px solid transparent',
              backgroundColor: "transparent",
            },
          },
          "& .MuiPaper-root": {
            background: "blue",
          },
        },
        standard: {
          borderRadius: "20px",
          boxShadow: "0px 0px 3px rgba(196, 211, 241, 0.85)",
        },
        "&:hover fieldset": {
          borderRadius: "20px!important",
          boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
          borderColor: "yellow",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 35,
          height: 19,
          padding: 0,
          position: "relative",
        },
        switchBase: {
          padding: 2,
          color: "#ededed",
          "&.Mui-checked": {
            transform: "translateX(14px)",
            color: "white",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: rawTheme.palette.primary.main,
              borderColor: "#DBE0EB",
            },
          },
          "&.Mui-disabled": {
            "& + .MuiSwitch-track": {
              opacity: "0.12",
            },
          },
        },
        thumb: {
          margin: "0 1px",
          width: 15,
          height: 15,
          boxShadow: "none",
          color: "white",
          border: "1px solid #DBE0EB",
        },
        track: {
          border: `1px solid #DBE0EB`,
          borderRadius: 20,
          opacity: 1,
          backgroundColor: "#EAEDF3",
          width: "35px",
        },
        checked: {},
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        group: {
          marginLeft: 12,
          marginBottom: "8px",
          marginTop: "8px",
          paddingLeft: "16px",
          borderLeft: `1px solid #ededed`,
        },
        content: {
          overflow: "hidden",
          flexDirection: "row-reverse",
          padding: "0",
        },
        label: {
          padding: "8px 0",
          overflow: "hidden",
          width: "100%",
        },
      },
    },
    MuiTreeView: {
      styleOverrides: {
        root: {
          "& > .MuiTreeItem-root": {
            "& > .MuiTreeItem-content": {
              borderBottom: `1px solid #ededed`,
            },
            "& .MuiTreeItem-group": {
              "& .MuiTreeItem-content:first-of-type": {},
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 12,
          htmlFontSize: 12,
          fontFamily: '"Nunito Sans", "OpenSans", sans-serif',
        },
        body1: {
          fontSize: 12,
          htmlFontSize: 12,
        },
        body2: {
          fontSize: 12,
          htmlFontSize: 12,
        },
        h1: {
          fontSize: "28px",
          fontWeight: "bold",
          lineHeight: "38px",
          letterSpacing: "-0.2px",
        },
        h2: {
          fontSize: "24px",
          fontWeight: "bold",
          lineHeight: "33px",
          letterSpacing: "-0.2px",
        },
        h3: {
          fontSize: "22px",
          fontWeight: "bold",
          lineHeight: "24px",
          letterSpacing: "-0.2px",
        },
        h4: {
          fontSize: "20px",
          fontWeight: "bold",
          lineHeight: "24px",
          letterSpacing: "-0.2px",
        },
        h5: {
          fontSize: "18px",
          fontWeight: "bold",
          lineHeight: "25px",
          letterSpacing: "-0.2px",
        },
        h6: {
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "22px",
          letterSpacing: "-0.2px",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          boxShadow: "none ",
          border: "1px solid #DBE0EB",
          backgroundColor: rawTheme.palette.background.default,

          "& .MuiButtonGroup-grouped:not(:last-of-type):hover": {
            boxShadow: "none !important",
            border: "0px !important",
            transform: "scale(100%)",
          },
          "& .MuiButtonGroup-grouped": {
            marginRight: "-1px",
            border: "none",
            "&:hover": {
              boxShadow: "none !important",
              border: "0px !important",
              transform: "scale(100%)",
            },
            "&:not(:last-child)": {
              borderRight: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
            },
            "&:last-child": {
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            },
          },
        },
      },
    },
  },
});

const createAppTheme = (themePart: Partial<ThemeOptions>) => {
  let theme = createTheme(_.merge(rawTheme, themePart));
  // @ts-ignore
  theme = createTheme(theme, mainTheme(theme));
  theme = createTheme(theme, componentTheme(theme));

  return theme;
};

export { createAppTheme };
