import {GridMiddle} from 'components/ui/AppElements';
import {Box, Button, CircularProgress, Link, Typography} from '@mui/material';
import {IconSet} from '../../assets/icons';
import {accessList} from '../../application/access/access';
import {ActionGranter} from '../../components/features/ActionGranter';
import {pageUtils} from '../../application/pages/pages.utils';
import {useNavigate} from 'react-router-dom';
import {AUTHUser} from '../../application/utils/AuthUser';
import React from 'react';
import {objectApi} from '../../application/entities/dataApi';
import {useTranslation} from 'react-i18next';
import {AppIcon} from '../../components/ui/AppIcon';
import {StatusDot, StatusItem} from '../Accounts/zOld/shared';
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import {PageId} from '../../application/pages/pages.config';
import moment from 'moment';
import { SGut, SCard, SCardCenter } from './styledElements';

export const ProviderDashboard = () => {
  
  const history      = useNavigate();
  const audiencesApi = new objectApi.audiences();
  const accountsApi  = new objectApi.accounts();
  
  const [reportingDate, setReportingDate] = React.useState<string | undefined>();
  const [reportingDateNice, setReportingDateNice] = React.useState<string | undefined>();
  
  const [buildDataReportLoading, setBuildDataReportLoading] = React.useState<boolean>(false);
  const [buildLogsLoading, setBuildLogsLoading]             = React.useState<boolean>(false);
  
  const {t} = useTranslation();
  
  const IconLoadingDataReport = buildDataReportLoading ? <CircularProgress size={16}/> : <></>;
  const IconLoadingLogs       = buildLogsLoading ? <CircularProgress size={16}/> : <></>;
  
  const infoText = AUTHUser.getUserAccount()?.activated ? t('dashboard.contactToPauseMonetize') : t('dashboard.dataNotMonetize') ;
  
  React.useEffect(() => {
    accountsApi.providerDataReportDate(AUTHUser.accountId).then((res) => {
      if (res.data?.reportDate) {
        setReportingDate(moment(res.data.reportDate).format('D MMMM YYYY') as string);
        setReportingDateNice(moment(res.data.reportDate).format('DDMMYYYY') as string);
      }
      
    }).catch((e) => {});
  }, []);
  
  function goToPage(pageId: PageId | string) {
    history(pageUtils.getPagePathById(pageId, {accountId: AUTHUser.accountId}));
  }
  
  function buildReport() {
    setBuildLogsLoading(true);
    accountsApi.buildReport(AUTHUser.accountId).then((res) => {
      setBuildLogsLoading(false);
      const reportUrl = window.URL.createObjectURL(new Blob([res?.data]));
      const link      = document.createElement('a');
      const dateToday = moment().format('DDMMYYYY') as string
      link.href       = reportUrl;
      link.setAttribute('data-cy', 'buildReport');
      link.setAttribute('download', `Evorra_Monetization_Log_${dateToday}.csv`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(reportUrl);
      document.body.removeChild(link);
    });
  }
  
  function buildDataReport() {
    // <CircularProgress size={16}/>
    setBuildDataReportLoading(true);
    accountsApi.providerDataReport(AUTHUser.accountId).then((res) => {
      setBuildDataReportLoading(false);
      const reportUrl = window.URL.createObjectURL(new Blob([res?.data]));
      const link      = document.createElement('a');
      link.href       = reportUrl;
      link.setAttribute('data-cy', 'buildReport');
      link.setAttribute('download', `report.csv`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(reportUrl);
      document.body.removeChild(link);
    });
  }
  
  return <div data-cy="dashboard-providerDashboard" style={{margin: '1rem auto'}}>
    <div style={{display: 'flex', gap: '16px', margin: '0 1rem', marginBottom: '16px' }}>
      <SGut style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <SCard style={{display: 'flex', padding: 0, margin: '0 auto', width: '100%', height: '241px', position: 'relative'}}>
          <div style={{flex: 1, paddingLeft: '1rem'}}>
            <Typography sx={{mt: 8, mb: 2}} variant={'h4'}> {t('dashboard.titles.createDataPerm') }</Typography>
            <Typography sx={{width: '300px', mb: 2}}>
              {t('dashboard.titles.controlData') } <br/>{t('dashboard.titles.manageWhoUseData') }
            </Typography>
            <ActionGranter accesses={accessList.dataVault.permissions}>
              <Button variant={'contained'} onClick={() => {goToPage(PageId.vaultPermissions);}}>{t`audience.getStarted`}</Button>
            </ActionGranter>
          </div>
          <div style={{flex: 1}}>
            <div style={{position: 'absolute', bottom: 0, right: 0}}>
              <IconSet.HeaderClient style={{display: 'block'}}/>
            </div>
            <div style={{position: 'absolute', bottom: 0, right: '132px'}}>
              <img style={{display: 'block'}} height={'240px'} alt={'Welcome screen'} src={'headerClient.png'}/>
            </div>
          </div>
        </SCard>
        <SCard style={{flex: 1, position: 'relative', display: 'flex', gap: '8px', alignItems: 'center'}}>
          <Typography variant={'h4'}>{ }</Typography> <AppTip text={infoText}/>
          <Box sx={{pt: 1}}>
            <StatusItem active={AUTHUser.getUserAccount()?.activated}>
              <StatusDot/> {(AUTHUser.getUserAccount()?.activated ? t('dashboard.status.active') : t('dashboard.status.inactive'))}
            </StatusItem>
          </Box>
        </SCard>
      </SGut>
    </div>
    <div style={{display: 'flex', gap: '24px', margin: '0 1rem', marginBottom: '16px' }}>
      <SGut style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{p: 3,pb:1}} variant={'h4'}>{t('dashboard.titles.reports')}</Typography>
        <div style={{display: 'flex', gap: '24px'}}>
          <SCardCenter style={{flex: 1, position: 'relative'}}>
            <AppIcon display={'inline'} icon={'PaymentBillingIcon'} chip={true}/>
            <GridMiddle sx={{gap: '8px'}}>
              <Typography sx={{fontWeight: 'bold'}}>{t('dashboard.titles.monetizationLogs')}</Typography>
              <AppTip title={t('dashboard.titles.monetizationLogs')} text={t('dashboard.monetizationEventCsv')}/>
            </GridMiddle>
            <div style={{flex: 1}}></div>
            <Button  startIcon={IconLoadingLogs} onClick={buildReport} variant={'outlined'}>{t('labels.download')}</Button>
          </SCardCenter>
          <SCardCenter style={{flex: 1, position: 'relative'}}>
            <AppIcon display={'inline'} icon={'DataCollection'} chip={true}/>
            <GridMiddle sx={{gap: '8px'}}>
              <Typography sx={{fontWeight: 'bold'}}>{t('dashboard.titles.dataCollectionReport')}</Typography>
              <AppTip title={t('dashboard.titles.dataCollectionReport')} text={'text to set'}/>
            </GridMiddle>
            <Typography sx={{color: '#E4EAF0'}}>{reportingDate ?? t('dashboard.status.notAvailable') }</Typography>
            <div style={{flex: 1}}></div>
            <Button disabled={(reportingDate===undefined)} startIcon={IconLoadingDataReport} onClick={buildDataReport} variant={'outlined'}>{t('labels.download')}</Button>
          </SCardCenter>
        </div>
      </SGut>
      <SGut style={{paddingLeft: '0', flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{py: 3,pb:1}} variant={'h4'}>{t('dashboard.titles.implementationResources')}</Typography>
        <SCard className={'flex-v flex-align-middle'} style={{height:'100%'}}>
          <GridMiddle gap={'1rem'}>
            <Box sx={{textAlign: 'center'}}><AppIcon icon={'Pdf'} chip={true}/></Box>
            <Link>{t('dashboard.dataProviderImplGuide')}</Link>
          </GridMiddle><br/>
          <GridMiddle gap={'1rem'}>
            <Box sx={{textAlign: 'center'}}><AppIcon icon={'DownloadCollection'} chip={true}/></Box>
            <Link target={'_blank'} href={'https://' + AUTHUser.getUserAccount()?.databaseName + '.evorra.net/doc/index.html'}>{t('dashboard.titles.dataCollectionApiInfo')}</Link>
          </GridMiddle>
        
        </SCard>
      </SGut>
    </div>
  </div>;
};
