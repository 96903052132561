import {Typography, useTheme} from '@mui/material';
import {IOpenToastAction, TToastId} from 'redux/slices/toast.slices';
import {RootState} from 'redux/store/rootReducer';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Collapse from '@mui/material/Collapse';
import {closeToast, openToast} from '../../../redux/slices/toast.slices';
import {TIcon} from 'assets/icons';
import {AppIcon} from 'components/ui/AppIcon';
import {Columns, ColumnsCenter, GridItem, GridItemFull} from '../../ui/AppElements';
import styled from 'styled-components';

interface IAppToastProps {
  toastId: string;
  severity?: string;
  message?: string | null;
  messageText?: string | null;
  autoHide?: boolean;
  autoHideDelay?: number;
  fromComponent?: string;
  rounded?: boolean;
  fullWidth?: boolean;
  centered?: boolean;
  position?: 'absolute' | 'relative';
}

// ready to take other integrated icons
// TODO move elsewhere
export const iconMapping: Record<string, TIcon> = {
  error  : 'ErrorIcon',
  success: 'SuccessIcon',
  info   : 'InfoIcon',
  warning: 'WarningIcon',
};

export const AppToast = (props: IAppToastProps) => {
  
  let toastTimer: any = React.useRef();
  const appToast      = useAppToast();
  const toastSlice    = useSelector(
    (state: RootState) => state.toastSlice[props.toastId] || {}
  );
  
  const {rounded, autoHide, centered, fullWidth, position} = props;
  
  const defaultConf = {
    noRounded: false,
    centered : true,
    autoHide : true,
    fullWidth: false,
    position : 'absolute'
  };
  // overrides default conf
  const toastConf   = Object.assign(defaultConf, props);
  
  useEffect(() => {
    autoHideToast();
    return () => {
      // clean timer on unmount
      if (toastTimer.current) {
        window.clearTimeout(toastTimer.current);
      }
    };
  }, [toastSlice.isOpen]);
  
  // function to hide toaasy after delay, if autoHide === true
  const autoHideToast = () => {
    if (!defaultConf.autoHide) {
      return;
    }
    
    if (toastTimer.current) {
      window.clearTimeout(toastTimer.current);
    }
    
    if (toastSlice.isOpen) {
      toastTimer.current = setTimeout(() => {
        appToast.close(props.toastId);
      }, props.autoHideDelay ?? 10000);
    }
  };
  
  const handleNotifyClose = () => {
    appToast.close(props.toastId);
    window.clearTimeout(toastTimer.current);
  };
  
  // build container for : fullWidth, center, position
  const NotifierContainer = (props: { children: any }) => {
    return toastConf.centered ?
           <div style={{position: toastConf.position, width: '100%'}}>
             <ColumnsCenter style={{width: fullWidth ? '100%' : 'auto'}}>{props.children}</ColumnsCenter>
           </div> :
           <div style={{position: toastConf.position}}>{props.children}</div>;
  };
  
  const Notifier = (args: any) => {
    const {palette}                                = useTheme();
    const {severity, isOpen, message, messageText} = toastSlice;
    
    
    return (
      <Columns
        wrap={'nowrap'}
        radius={rounded ? 10 : 0}
        style={{
          zIndex         : 3000,
          backgroundColor: palette?.[severity]?.main,
          display        : 'inline-flex',
          margin         : '0px auto',
          position       : 'relative',
          width          : fullWidth ? '100%' : 'auto'
        }}>
        <GridItem style={{padding: '1rem', color: 'white'}}>
          <AppIcon icon={iconMapping[severity]} color={'white'}/>
        </GridItem>
        <GridItem>
          <Sep/>
        </GridItem>
        <GridItemFull style={{padding: '1rem', color: 'white'}}>
        <Typography data-cy={severity?'appToast-' + severity:'appToast'} variant={'h6'}>{Boolean(message) && message}</Typography>
          <Columns>
            <GridItemFull>
              {Boolean(messageText) && messageText}
            </GridItemFull>
            <GridItem>
            </GridItem>
          </Columns>
        </GridItemFull>
        <GridItem style={{padding: '1rem', alignSelf: 'flex-start'}}  data-cy={'appToast-close'}>
          <AppIcon icon={'Close'} onClick={handleNotifyClose} color={'white'}/>
        </GridItem>
      </Columns>
    );
  };
  
  return (
    <Collapse in={toastSlice.isOpen}>
      <NotifierContainer><Notifier {...props} /></NotifierContainer>
    </Collapse>
  );
};

const Sep = styled.div({
  marginTop: '12px',
  height   : '32px',
  border   : '1px solid rgba(255,255,255,0.4)'
});

export const useAppToast = () => {
  const dispatch = useDispatch();
  
  return {
    open : (args: IOpenToastAction) => {
      dispatch(
        openToast({
          toastId    : args?.toastId ?? 'globalToast',
          severity   : args.severity,
          message    : args.message,
          messageText: args?.messageText
        })
      );
    },
    success : (args: Partial<IOpenToastAction>) => {
      dispatch(
        openToast({
          toastId    : args?.toastId ?? 'globalToast',
          severity   : 'success',
          message    : args.message ?? '',
          messageText: args?.messageText
        })
      );
    },
    error : (args: Partial<IOpenToastAction>) => {
      dispatch(
        openToast({
          toastId    : args?.toastId ?? 'globalToast',
          severity   : 'error',
          message    : args.message ?? '',
          messageText: args?.messageText
        })
      );
    },
    close: (toastId: TToastId) => {
      dispatch(closeToast(toastId));
    },
  };
};

/*
 Examples :
 <AppToast autoHide={false} toastId={'red'} message={'red 1'}/>
 <AppToast autoHideDelay={10000} toastId={'red2'} message={'red 2'}/>
 <AppToast autoHideDelay={15000} toastId={'red3'} message={'red 2'} noRounded={true} centered={true} fullWidth={true}/>
 */
