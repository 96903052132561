import { Card, Skeleton, Typography } from "@mui/material"
import { TIcon } from "assets/icons"
import { AppIcon } from "components/ui/AppIcon"
import { styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { profilesFormatter } from "_configuration/formaters";
import { pageUtils } from "application/pages/pages.utils";
import { useAuthState } from "redux/slices/auth.slices";
import { useNavigate } from "react-router";


type AdminDashboardCounterProps = {
    title: string
    count: number
    icon: TIcon
    loading: boolean,
    pageId?: string
}

export const AdminDashboardCounter = (props: AdminDashboardCounterProps) => {
    const {title, count, icon, loading, pageId} = props
    const authState = useAuthState();
    const navigate = useNavigate();

    const goToPage = ()=>{
        navigate(pageUtils.getPagePathById(pageId!, {
            accountId: authState?.user?.account?.id,
          }))
    }
     
    return <>
    {loading ? <Skeleton variant="rectangular" width={'100%'} height={'75px'} style={{borderRadius:'12px'}} />:
        <CounterWrap style={{backgroundColor:'#fff', padding:'1rem'}}>
            <div className={'flex-h flex-align-middle gap-small'} style={{justifyContent:'space-between'}}>
                
                <AppIcon icon={icon} fontSize="medium" color="#7587A3" chip={true}/>
                <div style={{textAlign:'right'}}>
                <Typography variant={'h5'} color="#7587A3" style={{textTransform:'uppercase', fontSize:'16px'}}>
                    {title}
                </Typography>
                <Typography variant={'h4'} color="#3B4559">
                    {profilesFormatter.format(count)}
                    {
                        pageId && 
                        <span onClick={goToPage} style={{textTransform:'none', fontSize:'12px',fontWeight:'600', paddingLeft:'10px', color:'#00B5E2', cursor:'pointer'}}>
                            View
                        </span>
                    }
                    </Typography>
                </div>
               
            </div>
        </CounterWrap>
    }
    </>
}

const CounterWrap = styled(Card)((props: { theme: Theme }) => ({
    
    boxShadow: props.theme.skin.boxShadow,
    backgroundColor: props.theme.palette.background.default,
    borderRadius: "12px",
    border: "1px solid transparent",
    width:'300px',
    maxWidth:'100%',
    
  }));
