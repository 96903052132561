import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import {TIcon} from '../../assets/icons';


export type TDialogId = string;
export type TOpenDialogAction = TDialogId;
export type TCloseDialogAction = TDialogId;

export type TComponentProps = Record<string, any>;

interface IDialogButtonActions {
  cancelButton?: Boolean | string;
  dismissButton?: Boolean | string;
  acceptButton?: Boolean | string;
  acceptValue?: Boolean | any;
}

export interface IDialogSlice {
  dialogId: TDialogId;
  title: string | undefined;
  description: string;
  open: boolean;
  component?: any;
  componentProps: TComponentProps;
  content?: any;
  onClose?: (value?: any) => void;
  onAccept?: (value?: any, acceptValue?: any) => void;
  dialogArgs?: Record<string, any>;
  dialogProps: Omit<DialogProps, 'open'>;
  buttonActions?: IDialogButtonActions;
  icon?: TIcon | ((value: any)=> TIcon)
}

// ??
export interface IOpenDialogAction extends Partial<IDialogSlice>{
  dialogId: TDialogId;
  title?: string;
  description?: string;
  componentProps?: TComponentProps;
  component?: any;
  content?: any;
  onClose?: (value?: any) => void;
  onAccept?: (value?: any, acceptValue?: any) => void;
  dialogArgs?: Record<string, any>;
  dialogProps?: Omit<DialogProps, 'open'>;
  buttonActions?: IDialogButtonActions;
}

export interface ISetDialogAttributes { // Partial here
  dialogId?: TDialogId;
  title?: string;
  description?: string;
  componentProps?: TComponentProps;
  component?: any;
  content?: any;
  onClose?: (value?: any) => void;
  onAccept?: (value?: any, acceptValue?: any) => void;
  dialogArgs?: Record<string, any>;
  dialogProps?: Omit<DialogProps, 'open'>;
  buttonActions?: IDialogButtonActions;
}

const initialState: IDialogSlice = {
  dialogId: '',
  title: undefined,
  description: '',
  open: false,
  component: undefined,
  componentProps: {},
  content: undefined,
  onClose: undefined,
  onAccept: undefined,
  dialogArgs: undefined,
  dialogProps: {},
  buttonActions: undefined,
};


const dialogSlice = createSlice({
  name: 'dialogSlice',
  initialState,
  reducers: {
    openDialog(state, action: PayloadAction<IOpenDialogAction>) {

      state.open = true;
      state.dialogId = action.payload.dialogId;
      state.title = action.payload.title ?? state.title;
      state.description = action.payload.description ?? state.description;
      state.componentProps = action.payload.componentProps ?? state.componentProps;
      state.component = action.payload.component ?? undefined;
      state.content = action.payload.content ?? undefined;
      state.onClose = action.payload.onClose;
      state.onAccept = action.payload.onAccept;
      state.buttonActions = action.payload.buttonActions;
      state.dialogArgs = action.payload.dialogArgs;
      state.icon = action.payload.icon;

      if (action.payload.dialogProps) {
        // @ts-ignore
        state.dialogProps = action.payload.dialogProps;
      }

      // return {...action.payload,open: true}
    },
    open(state, action: PayloadAction<any>) {

      state.open = true;
      state.dialogId = action.payload.dialogId;

      // return {...action.payload,open: true}
    },
    closeDialog(state, action: PayloadAction<TCloseDialogAction>) {
      state.open = false;
      state.dialogId = '';
      state.description = '';
      state.title = '';
    },
    setDialogAttributes(state, action: PayloadAction<ISetDialogAttributes>) {
      return { ...action.payload, ...state };
    },
    setComponentProps(state, action: PayloadAction<TComponentProps>) {
      state.componentProps = action.payload;
    },
  },
});

const { openDialog, closeDialog, setDialogAttributes, open } = dialogSlice.actions;

function DialogActions() {
  const dispatch = useDispatch();

  return {
    dialog: (dialogId: string) => {
      return {
        open: async () => {
          return dispatch(open({ dialogId }));
        },
        close: async (args: any) => {
          return dispatch(closeDialog(dialogId));
        },
        setAttributes: async (args: ISetDialogAttributes) => {
          args.dialogId = dialogId;
          return dispatch(setDialogAttributes(args));
        },
      };
    },
    openDialog: async (args: IOpenDialogAction) => {
      return dispatch(openDialog(args));
    },
    closeDialog: async (args: TCloseDialogAction) => {
      return dispatch(closeDialog(args));
    },
    setDialogAttributes: async (args: IDialogSlice) => {
      return dispatch(setDialogAttributes(args));
    },
  };
}

function DialogState() {
  return useSelector((state: RootState) => state.dialogSlice || {});
}

export const useDialogActions = DialogActions;
export const useDialogState = DialogState;

export { openDialog, closeDialog, setDialogAttributes };

export default dialogSlice.reducer;
