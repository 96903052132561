import { Skeleton } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { useEffect, useState } from "react";
import { PropertiesList } from "../AdminAccountView";

export const AdminAccountFeatureFlag = (props: {accountId: number}) => {
    const {accountId} = props;
    const accountsApi         = new objectApi.accounts();
    const [loading, setLoading] = useState<boolean>(true);
    const [featureFlags, setFeatureFlags] = useState<any[]>([]);

    useEffect(() => {
        fetchData(accountId)
    }, [accountId]);

    const fetchData = (accountId: number) => {
        accountsApi.getMyFeatureFlags(accountId).then((res) => {
            setFeatureFlags(res.data.featureFlags);
            setLoading(false);
        })
    }

    return <>
    {loading ? <Skeleton variant="rectangular" width={'100%'} height={'200px'} style={{borderRadius:'7px'}}/> : <>
        <PropertiesList>
            {Object.entries(featureFlags).sort().map(([key, value]) => {
                return  <li key={key}>
                <span>{key}</span> {value ? 'Yes': 'No'}
            </li>
            })}
        </PropertiesList>
        </>
}
        
    </>

}