import { Box, Button, Skeleton, Slide, Stack, Typography } from "@mui/material";
import { GridMiddle } from "../AppElements";
import { AppIcon } from "../AppIcon";
import { TIcon } from "../../../assets/icons";
import React from "react";
import { styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { ActionButtons, IActionButtons } from "../../controls/ActionButtons";
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";

interface IAppDrawerProps {
  children?: any;
  open: boolean;
  title?: string;
  description?: string;
  actionButtons?: IActionButtons;
  actionButtonsVars?: Record<string, any>;
  hideHandle?: boolean;
  /** @deprecated permits only one button to be inserted */
  handleClick?: any;
  icon?: TIcon;
  helperText?: string;
  helperTitle?: string;
  loading?:boolean;
  onClose?: () => void;
}

export const AppDrawer = (props: IAppDrawerProps) => {
  const {
    children,
    open,
    title,
    handleClick,
    hideHandle,
    icon,
    description,
    loading,
    onClose,
    actionButtons,
    actionButtonsVars,
  } = props;

  const handleClickArrow = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <Slide timeout={150} direction={"left"} in={open}>
        <AppDrawerContainer data-cy={"AppDrawer"} id={"cyDrawer"} open={open}>
          {!hideHandle && open && (
            <AppDrawerContainerCloser onClick={handleClickArrow}>
              <AppIcon
                icon={"ChevronDownIcon"}
                color={"white"}
                fontSize={"tiny"}
                rotate={open ? 270 : 90}
              />
            </AppDrawerContainerCloser>
          )}
            {loading ? <div style={{backgroundColor:'#fff', height:'100%'}}>
          
              <AppDrawerInnerTitle>
                <Box sx={{ p: 1 }}>
                  <Typography variant={"h5"} noWrap={true}>Transparency card is loading...</Typography>
                </Box>
              </AppDrawerInnerTitle>
                 
                <div style={{padding:'10px'}}>
                <Skeleton variant="rectangular" width={'100%'} height={30} style={{borderRadius:'10px'}}/>
                <br/>
                <Skeleton variant="rectangular" width={'100%'} height={40} style={{borderRadius:'10px'}}/>
                <br/>
                <Skeleton variant="rectangular" width={'100%'} height={10} style={{borderRadius:'10px'}}/>
                <br/>
                <Skeleton variant="rectangular" width={'100%'} height={160} style={{borderRadius:'10px'}}/>
                <br/>
                <Skeleton variant="rectangular" width={'100%'} height={10} style={{borderRadius:'10px'}}/>
                <br/>
                <Skeleton variant="rectangular" width={'100%'} height={120}  style={{borderRadius:'10px'}}/>
                </div>              
              </div>:
          <AppDrawerInner>
            <AppDrawerInnerTop>
              {Boolean(title) && (
                <Box sx={{ bgcolor: "background.default" }}>
                  <AppDrawerInnerTitle
                    style={{ minWidth: "auto", height: "auto" }}>
                    {!hideHandle && (
                      <Closer onClick={handleClickArrow} open={open}>
                        <AppIcon
                          icon={"ChevronDownIcon"}
                          color={"white"}
                          fontSize={"tiny"}
                          rotate={open ? 270 : 90}
                        />
                      </Closer>
                    )}
                    {icon !== undefined && (
                      <Box sx={{ p: 1 }}>
                        <AppIcon color={"white"} icon={icon} />
                      </Box>
                    )}
                    <Box sx={{ p: 1, flex: "1 1 auto" }}>
                      {/*<Typography variant={'h4'} noWrap={true}>{title} <AppIcon fontSize={'small'} icon={'InfoIcon'} display={'inline'}/></Typography>*/}
                      <GridMiddle>
                        <Typography variant={"h5"} noWrap={true}>
                          {title}{" "}
                        </Typography>
                        {Boolean(props?.helperText) && (
                          <div style={{ paddingLeft: "0.5rem" }}>
                            <AppTip
                              title={props?.helperTitle as string}
                              color={"white"}
                              text={props?.helperText as string}
                            />
                          </div>
                        )}
                      </GridMiddle>

                      {description && (
                        <Box sx={{ pt: 1 }}>
                          <Typography variant={"inherit"}>
                            {description}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AppDrawerInnerTitle>
                </Box>
              )}
              {actionButtons && (
                <Box sx={{ px: 3, py: 1 }}>
                  <ActionButtons
                    buttonSize={"small"}
                    actions={actionButtons}
                    vars={actionButtonsVars}
                  />
                </Box>
              )}
            </AppDrawerInnerTop>

            <Box sx={{ flex: 1, overflow: "auto" }}>
              {React.Children.toArray(children).map(
                (child: any, index: any) => {
                  return React.cloneElement(child, { ...props });
                }
              )}
            </Box>
          </AppDrawerInner>
          }
        </AppDrawerContainer>
      </Slide>
    </>
  );
};

const Closer = styled(Box)((props: { theme: Theme } & { open: boolean }) => ({
  //background            : props.theme.palette.primary.main,
  background: "rgba(255,255,255,0.1)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: "0.5rem",
  cursor: "pointer",
}));

const AppDrawerContainerCloser = styled(Box)((props: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",
  // border           : '1px solid red',
  cursor: "pointer",
  position: "absolute",
  top: "16px",
  left: "-21px",
  boxShadow: props.theme.skin.boxShadow,
  // padding               : '8px 8px',
  width: "21px",
  height: "26px",
  borderBottomLeftRadius: "6px",
  //borderTopLeftRadius   : '6px',
  background: props.theme.palette.primary.main,
  zIndex: 1,
  textAlign: "right",
  verticalAlign: "middle",
  flexDirection: "row-reverse",
}));

const AppDrawerContainer = styled(Stack)(
  (props: { theme: Theme } & { open: boolean }) => ({
    height: "calc(100% - 32px)",
    maxHeight: "calc(100% - 32px)",
    width: props.open ? "351px" : "auto",
    top: 0,
    margin: "16px auto",
    position: "absolute",
    right: 0,
    zIndex: 1000, //props.theme.zIndex.drawer,
    // borderTopLeftRadius   : '6px',
    borderBottomLeftRadius: "6px",
    transition: "all 200ms ease-in-out",
    overflow: "hidden",
    boxShadow: props.theme.skin.boxShadow,
  })
);

const AppDrawerInnerTitle = styled(GridMiddle)((props: { theme: Theme }) => ({
  //paddingLeft    : '1rem',
  display: "flex",
  color: "white",
  backgroundColor: "#8691A2",
  height: "34px!important",
}));

const AppDrawerInner = styled(Stack)((props: { theme: Theme }) => ({
  overflow: "hidden",
  backgroundColor: props.theme.palette.background.default,
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 10,
  // borderTopLeftRadius   : '8px',
  borderBottomLeftRadius: "8px",
  boxShadow: props.theme.skin.boxShadow,
}));
const AppDrawerInnerTop = styled(Box)((props: { theme: Theme }) => ({
  borderBottom: "1px solid " + props.theme.skin.borderColor,
}));

const Title = (props: IAppDrawerProps) => {
  // const context = useDonutContext();

  return <Box></Box>;
};

AppDrawer.Title = Title;
