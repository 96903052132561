import {ICompanyUsers} from '../dataTypes/companyUser';

export const companyUsers: Partial<ICompanyUsers> = {
  id       : undefined,
  title    : '',
  firstName: '',
  lastName : '',
  email    : '',
  role     : undefined,
  jobTitle : '',
  accountId: undefined,
}