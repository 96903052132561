import { Button, Icon, Theme, styled } from "@mui/material";
import { TIcon } from "assets/icons";
import { AppIcon } from "../AppIcon";
import { useState } from "react";


interface IAdminCardProps {
    
    children?: React.ReactNode;
    title: string,
    counterTitle?: string | number,
    icon?: TIcon,
    toggle?: boolean,
    action?:{
        text?:string
        icon?:TIcon
        onClick:()=>void
    }
}


export const AdminCard = (props: IAdminCardProps) => {
    const {children, title, counterTitle, action, icon, toggle } = props;


    const [isOpen, setIsOpen] = useState<boolean>(toggle ? false: true);

    return <Card>
        <CardHeader isOpen={isOpen}>
            {icon && <AppIcon icon={icon} fontSize="middle" color="#7587A3"/> }
            {toggle ? 
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                    <CardTitle onClick={()=>setIsOpen(!isOpen)} style={{cursor:'pointer'}}>
                        {title} <span> {counterTitle}</span>
                    </CardTitle>
                    <AppIcon icon="ChevronDownBlue" fontSize="medium" color="#7587A3" rotate={isOpen ? 180 : 0} onClick={()=>setIsOpen(!isOpen)}/>
                </div>
                :
                (action  && (action?.text || action?.icon)) ?
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                        <CardTitle>
                            {title} <span> {counterTitle}</span>
                        </CardTitle>
                        {action?.text && 
                            <Button variant='outlined' size='small' color='primary' onClick={action.onClick}>{action.text}</Button>
                        }
                    </div>
                :
                <CardTitle>
                    {title} <span> {counterTitle}</span>
                </CardTitle>
                

            }
        </CardHeader>
        {isOpen &&
            <CardContent style={{maxHeight: '450px'}}>
                {children}
            </CardContent>
        }
    </Card>
}


const Card  = styled('div')((props: { theme: Theme }) => ({
    padding: '16px',
    maxWidth    : '100%',
    overflowY:'auto',
    boxShadow      : props.theme.skin.boxShadow,
    backgroundColor: props.theme.palette.background.default,
    borderRadius   : '12px',
    border         : '1px solid ' + props.theme.skin.borderColor,
    
  }));

  const CardHeader = styled('div')((props: {isOpen: boolean}) => ({
    paddingBottom:props.isOpen ? '0.75rem': '0',
    marginBottom: props.isOpen ? '1rem' : '0',
    borderBottom: props.isOpen ?'1px solid #eee': 0,
    display:'flex',
    flexDirection:'row',
    gap:'8px',
    alignItems:'center',
 }));

  const CardTitle = styled('h2')({
    fontSize:'18px',
    fontWeight:'600',
    color:'#7587A3',
    '& span':{
        color:'#7587A3',
        fontSize:'14px',
        paddingLeft:'3px',
    }
 });

 const CardContent = styled('div')({
    maxHeight:'100%',
    overflowY:'auto',
 });


