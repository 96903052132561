import {GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';
import {TDataColumnModel} from '../../utils/dataColumnModel.utils';
import {IAccounts} from '../dataTypes/accounts';
import {ICompanyUsers} from '../dataTypes/companyUser';



export const accountsColumns: TDataColumnModel = {
  companyName  : {
    field      : 'companyName',
    headerName : 'Brand Name',
    minWidth      : 130,
    flex:1,
    align      : 'left',
    headerAlign: 'left',
    type       : 'logo',
    sortable   : true,
  },
  industry    :
    {
      field      : 'industry',
      headerName : 'Industry',
      align      : 'left',
      headerAlign: 'left',
      minWidth      : 130,
      flex:1,
      sortable   : true,
      type       : 'string',
      valueGetter: (params: GridValueGetterParams<IAccounts>) => {
        
        return params.row?.industries?.[0]?.name ?? ''
      }
    },
  /*users    :
    {
      field      : 'activated',
      headerName : 'Users',
      align      : 'center',
      headerAlign: 'center',
      minWidth      : 130,
      flex:1,
      sortable   : true,
      type       : 'string',
      valueGetter: (params: GridValueGetterParams<IAccounts>) => {
        
        return params.row?.companyUsers?.length ?? 0
      }
    },*/
};