import styledOld from 'styled-components/macro';


export const AccountTitle = styledOld.div`
  color: #9DAABF;
`;

export interface StatusItemProps {
  active?: boolean;
}

export const StatusDot  = styledOld.div`
  width: 8px;
  height: 8px;
  margin: 7px;
  border-radius: 50%;
  border: white solid 1px;
`;
export const StatusItem     = styledOld.div<StatusItemProps>(props => ({
  height      : '20px',
  fontWeight  : 600,
  display     : 'flex',
  alignItems  : 'center',
  width       : 'fit-content',
  padding     : '0 8px 0 0',
  borderRadius: '26px',
  margin      : '0 0 5px 0',
  fontSize    : '10px',
  background  : props.active ? 'rgba(2, 198, 151, 0.1)' : 'rgba(252, 96, 80, 0.1)',
  color       : props.active ? 'rgba(2, 198, 151, 1)' : 'rgba(252, 96, 80, 1)',
  [StatusDot] : {
    background: props.active ? 'rgba(2, 198, 151, 1)' : 'rgba(252, 96, 80, 1)',
    boxShadow : props.active ? '0 0 3px rgba(2, 198, 151, 0.5)' : '0 0 3px rgba(252, 96, 80, 0.5)',
    
  }
}));
