import {Box, styled} from '@mui/material';
import {TagList} from '../../../../components/organisms/TagList';

import {removeFragmentItemById} from '../../../../application/utils/dataState.utils';
import {TFeatureGroupCode} from '../../../../application/entities/dataTypes/featureGroups';
import { useAudienceBuilderStore } from 'pages/AudienceBuilder/Store/AudienceBuilderStore';
import { featIcnConf } from 'pages/AudienceBuilder/configuration';
import { AudienceBuilderThumbNew, IAudienceBuilderThumbNewProps } from './Cards/ViewAudienceMainCard';
import { IElementsData } from 'pages/AudienceBuilder/types/audienceBuilderTypes';

export interface IThumbNodeProps {
  id: TFeatureGroupCode | any;
  data: IElementsData;
  viewMode?: boolean;
  warning?:string
}

/** default audience card */
export const AudienceCardMain = (props: IThumbNodeProps) => {
  
  const audienceData    = useAudienceBuilderStore(st => st.audience);
  const audienceTags    = useAudienceBuilderStore(st => st.audience.tags);
  const audienceActions = useAudienceBuilderStore(st => st.actions);
  
  
  const {id, data,viewMode, warning} = props;
  
  let showNode = true;
  
  const dtProps: IAudienceBuilderThumbNewProps = {
    title     : audienceData.name,
    subTitle  : `ID${audienceData.id}`,
    showPicker: viewMode ? false : true,
    entityData : audienceData,
    icon       : featIcnConf?.['BASICS']?.icon,
    ...data?.builderThumbProps,
  };
  
  dtProps.gutterColor  = audienceData.color;
  dtProps.onDataChange = (args) => {
    audienceActions.updateAudience(args);
  };
  
  
  const removeTag = (id: number, index?: number) => {
    const newTags = removeFragmentItemById(audienceTags, id);
    audienceActions.updateAudienceTags(newTags);
  };
  
  
  return <>{Boolean(showNode) && <NodeContainer className={'nowheel'} data-cy={'audience-card'} data-cy-id={audienceData.id} viewMode={viewMode}>
      <AudienceBuilderThumbNew
        {...dtProps}>
          {warning && 
            <p style={{textAlign:'left', paddingLeft:'9px', fontWeight:'900', color:'var(--color-scheme-error-alpha-low)'}}>
              {warning}
            </p>
          }
          <Box sx={{p: 1, fontSize:'9px'}}>
              <TagList dense={true} fullSize={true} onRemoveTag={removeTag} tags={audienceTags} viewMode={viewMode}/>
          </Box>
      </AudienceBuilderThumbNew>
  </NodeContainer>}</>;
};

const NodeContainer = styled(Box)((props:{viewMode?: boolean}) => ({
  fontSize : '11px',
  overflow : 'visible',
  position : 'relative',
  minHeight: '40px',
  maxWidth : '100%'
}));
