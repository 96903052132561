import {QueryClient} from 'react-query';
import apiRequest from 'application/entities/api/interceptors';
import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import {devApi} from '../../../_configuration/api.config';
import {TEntityName} from '../dataTypes';


export type TCrudOp = 'get' | 'put' | 'post' | 'patch' | 'delete';

export interface ISortParameters {
  sortBy: string;
  sortDir: 'asc' | 'desc';
}

export interface IPageParameters {
  pageSize?: number;
  pageNumber: number;
}

export type ISearchValueParameter = string | number | undefined | Record<string, any>;
export type ISearchByParameter = string;
export type IStaleCode = 'FRESH' | 'ONE_HOUR' | 'ONE_DAY' | 'INFINITE';

export enum API_RETURN_FORMAT {
  ARRAY = 'ARRAY',
  MAP   = 'MAP'
}

export enum API_PART_TYPE {
  RAW      = 'RAW',
  EMBEDDED = 'EMBEDDED'
}

export interface IApiReturn {
  part?: keyof typeof API_PART_TYPE,
  format?: API_RETURN_FORMAT; //Partial<Record<keyof typeof API_RETURN_FORMAT, string>>,
}

export interface ISearchParameters {
  fieldName: ISearchByParameter;
  value?: ISearchValueParameter;
}

export type ICrudArgs = Record<string, any> | any[] | any

export interface IApiMetaData {
  queryId: string,
  entity: string;
  sentData: any;
  statusCode?: string;
  operation: TCrudOp;
  progress: 'RUNNING' | 'STOPPED'
  cancelToken?: CancelToken | undefined;
}

export interface IBuildApiMetaData {
  metadata: IApiMetaData;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const staleCacheOptions: Record<IStaleCode, number> = {
  'FRESH'   : 0,
  'ONE_HOUR': 60000,
  'ONE_DAY' : 655565,
  'INFINITE': Infinity,
};

export class apiBuilder {
  private entityName: TEntityName;
  private subEntityName!: TEntityName;
  private naturalSearchParameter!: string | number;
  private naturalKw: string | number    = 'kw';
  private searchParameter!: ISearchParameters;
  private searchInParameter!: ISearchParameters;
  private searchMode: boolean           = false;
  private searchModeFull: boolean           = false;
  private sortParameters!: ISortParameters;
  private pageParameters!: IPageParameters;
  private parameters: string[]          = [];
  private id!: number;
  private subEntityId!: number;
  private staleCode!: IStaleCode;
  private cacheKey: Record<string, any> = {};
  private showProjection: boolean;
  private projection: string | undefined;
  private cancelToken: CancelToken | undefined;
  /** don't use automated projection */
  
  constructor(
    entityName: TEntityName,
    subEntityName?: TEntityName | undefined,
    options?: IApiOptions 
  ) {
    this.entityName = entityName;
    if (subEntityName) {
      this.subEntityName = subEntityName;
    } 
    this.projection = options?.projection
    this.showProjection = (options?.showProjection !== undefined) ? options?.showProjection : true;
    //
    this.setCacheKey();
  }
  
  byId(id: number | undefined) {
    if (id !== undefined) this.id = id;
    return this;
  }
  
  subId(id: number) {
    this.subEntityId = id;
    return this;
  }
  
  find(
    searchByParameter: ISearchByParameter,
    searchParameter?: ISearchValueParameter,
  ) {
    if (searchParameter) {
      this.searchParameter = {
        value    : searchParameter,
        fieldName: searchByParameter,
      };
    } else {
      this.searchParameter = {
        fieldName: searchByParameter,
      };
    }
    return this;
  }
  
  naturalFind(searchValue: string | number, kw = 'kw') {
    this.showProjection         = false;
    this.naturalKw              = kw;
    this.naturalSearchParameter = searchValue;
    return this;
  }
  
  addParameter(name: string, value: string | any | undefined) {
    if (value !== undefined) this.parameters.push(`${name}=${value}`);
    return this;
  }
  
  addParameters(value:Record<string,any> ) {
    Object.keys(value).forEach((val)=>{
      if (value[val] !== undefined) this.parameters.push(`${val}=${value[val]}`);
    })
    
    
    return this;
  }
  
  search() {
    this.searchMode = true;
    return this;
  }
  searchFull() {
    this.searchModeFull = true;
    return this;
  }
  
  sort(sortParameters?: ISortParameters) {
    if (!sortParameters) return this;
    this.sortParameters = sortParameters;
    return this;
  }
  
  page(pageParameters?: IPageParameters) {
    if (!pageParameters) return this;
    this.pageParameters = pageParameters;
    return this;
  }

  withCancelToken(token:CancelToken | undefined){
    this.cancelToken = token;
    return this
  }
  
  // asUriFragment.
  encodeSubEntities(args?: ICrudArgs) {
    return args
    .map((value: string | number) => `${devApi}/${this.subEntityName}/${value}`)
    .join('\n');
  }
  
  async post(args?: ICrudArgs, options?: AxiosRequestConfig) {
    if (options?.asUriFragment) {
      args    = this.encodeSubEntities(args);
      options = {...options, ...{headers: {'Content-type': 'text/uri-list'}}};
    }
    
    return apiRequest.post(
      this.hydrateEntity(),
      args,
      {...options, ...this.addMetadata('post', args), cancelToken: this.cancelToken},
    );
  }
  
  async patch<T = any>(args: ICrudArgs, options?: AxiosRequestConfig) {
    if (options?.asUriFragment) {
      args    = this.encodeSubEntities(args);
      options = {...options, ...{headers: {'Content-type': 'text/uri-list'}}};
    }
    
    return apiRequest.patch<T>(
      this.hydrateEntity() + '?projection=' + (this.subEntityName ?? this.entityName),
      args,
      {...options, ...this.addMetadata('patch', args)},
    );
  }
  
  async put(args: ICrudArgs, options?: AxiosRequestConfig) {
    if (options?.asUriFragment) {
      args    = this.encodeSubEntities(args);
      options = {...options, ...{headers: {'Content-type': 'text/uri-list'}}};
    }
    
    return apiRequest.put(
      this.hydrateEntity(),
      args,
      {...options, ...this.addMetadata('put', args)},
    );
  }
  
  async delete(options?: {}) {
    return apiRequest.delete(
      this.hydrateEntity(),
      {...options, ...this.addMetadata('delete', this.parameters)},
    );
  }
  
  async get<T>(args?: IApiReturn) {
    return this.fetch<T>(args);
  }
  
  /**
   * alias for get()
   * @param args IApiReturn
   */
  async fetch<T>(args?: IApiReturn) {
    
    let uri: string = '';
    if (this.searchParameter || this.searchInParameter) {
      
      if (this.searchParameter) {
        const upperKey = this.searchParameter.fieldName.charAt(0)
                             .toUpperCase() + this.searchParameter.fieldName.slice(1);
        let searchValue: unknown;
        // legacy
        if (this.searchParameter.value) {
          if (typeof (this.searchParameter.value) === 'string') {
            searchValue = encodeURIComponent(this.searchParameter.value as string);
            this.parameters.push(`${this.searchParameter.fieldName}=${searchValue}`);
          }
          
          if (typeof (this.searchParameter.value === 'object') && !Array.isArray(this.searchParameter.value)) {
            searchValue = Object.keys(this.searchParameter.value)
            // @ts-ignore
                                .map((key: string) => `${key}=${encodeURIComponent(this.searchParameter.value[key])}`)
                                .join('&');
            
            this.parameters.push(`${searchValue}`);
          }
          uri = `${this.entityName}/search/findBy${upperKey}`;
          
        } else {
          if (this.searchParameter.fieldName) {
            uri = `${this.entityName}/search/${this.searchParameter.fieldName}`;
          }
        }
      }
    } else if (this.naturalSearchParameter) {
      uri = `${this.hydrateEntity()}/search`;
      this.parameters.push(`${this.naturalKw}=${this.naturalSearchParameter}`);
    } else if (this.searchMode) {
      uri = `${this.hydrateEntity()}/search`;
    } else if (this.searchModeFull) {
      uri = `${this.hydrateEntity()}/searchFull`;
    }else {
      uri = this.hydrateEntity();
    }
    
    this.buildPaginationParameters();
    this.buildSortParameters();
    this.addProjection();
    
    if (this.parameters.length) {
      uri += '?' + this.parameters.join('&');
    }
    
    return apiRequest.get<T, AxiosResponse>(uri, this.addMetadata('get', this.parameters)).then(res => {
      if (args?.format === API_RETURN_FORMAT.MAP) {
        res.data._embedded[this.entityName] = arrayToObject(
          res.data._embedded[this.entityName],
          'id',
        );
      }
      if (args?.part === API_PART_TYPE.EMBEDDED) {
        return res?.data?._embedded[this.projection ?? this.entityName ?? this.subEntityName];
      }
      return res;
    });
  }
  
  private setCacheKey() {
    if (this.entityName) {
      this.cacheKey.entityName = this.entityName;
    }
    if (this.id) {
      this.cacheKey.id = this.id;
    }
    if (this.subEntityName) {
      this.cacheKey.subEntityName = this.subEntityName;
    }
    if (this.searchParameter) {
      this.cacheKey.searchParameter = this.searchParameter;
    }
    if (this.searchInParameter) {
      this.cacheKey.searchInParameter = this.searchInParameter;
    }
    if (this.sortParameters) {
      this.cacheKey.sortParameters = this.sortParameters;
    }
  }
  
  private hydrateEntity() {
    let uri: any[] = []; // this.entityName
    
    this.entityName && uri.push(this.entityName);
    (this.id !== undefined) && uri.push(this.id);
    (this.subEntityName !== undefined) && uri.push(this.subEntityName);
    (this.subEntityId !== undefined) && uri.push(this.subEntityId);
    
    return uri.filter(x => x)
              .join('/');
  }
  
  private buildPaginationParameters() {
    this.pageParameters?.pageSize && this.parameters.push(`size=${this.pageParameters.pageSize}`);
    this.pageParameters?.pageNumber !== undefined && this.parameters.push(`page=${this.pageParameters.pageNumber}`);
  }
  
  private buildSortParameters() {
    this.sortParameters?.sortBy && this.parameters.push(
      `sort=${this.sortParameters.sortBy},${this.sortParameters.sortDir || 'asc'}`
    );
  }
  
  private addProjection() {
    //if (!this.searchParameter) { // && !this.id
    if (this.showProjection) {
      this.parameters.push('projection=' + (this.projection ?? this.subEntityName ?? this.entityName));
    }
    //}
  }
  
  private addMetadata(operation: TCrudOp, data?: any): IBuildApiMetaData {
    // IApiMetaData
    return {
      metadata: {
        queryId  : Math.random()
                       .toString(36)
                       .substr(2, 9),
        entity   : this.subEntityName ?? this.entityName,
        operation: operation,
        sentData : data,
        progress : 'RUNNING',
      },
    };
  }
}


export const arrayToObject = (dataArray: any[], key: string | number) => {
  const initialValue = {};
  return dataArray.reduce((obj, item: any) => {
    
    const newKey = (key === '_id') ? item?._id?.$oid : item[key];
    return {
      ...obj,
      [newKey]: item,
    };
  }, initialValue);
};

export const arrayToMap = <T>(dataArray: [], key: string | number): Map<number | string, T> => {
  return new Map(arrayToMap(dataArray, key));
};

export interface IApiOptions {
  showProjection?: boolean;
  projection?: string;
  stale?: IStaleCode;
  cache?: IStaleCode;
}

export class evorraApi {
  
  static entity(entityName: TEntityName | TEntityName[] | string, options?: IApiOptions) {
    const doEntityName    = Array.isArray(entityName) ? entityName[0] : entityName;
    const doSubEntityName = Array.isArray(entityName) ? entityName[1] : undefined;
    
    return new apiBuilder(doEntityName, doSubEntityName, options);
  }
  
  
  static route(route: string , options?: IApiOptions) {
    
    return new apiBuilder(route,undefined, options);
  }
  
  /** migrate to agencies */
  static getRoute(route: string, vars: Record<string, any> = {}, options?: IApiOptions) {
    
    const addDots = Object.keys(vars).reduce((acc: Record<string, string>, key: string) => {
      acc[`{${key}}`] = `${vars[key as string]}`;
      return acc;
    }, {});
    
    const RE = new RegExp(Object.keys(addDots).join('|'), 'gi');
    
    let red =  route.replace(RE, function (matched: string) {
      return addDots[matched as string];
    });
    
    return evorraApi.route(red,options)
  }
}

const apiService = evorraApi; // new evorraApi();

export {apiService};

// examples
/*const ex = (() => {
 
 apiService
 .entity('companyUsers')
 .fetch();
 
 apiService
 .entity('companyUsers')
 .sort({sortBy: 'name', sortDir: 'asc'})
 .fetch();
 
 apiService
 .entity('companyUsers', {stale: 'ONE_DAY'})
 .sort({sortBy: 'name', sortDir: 'asc'})
 .fetch();
 
 apiService
 .entity('companyUsers')
 .page({pageNumber: 0, pageSize: 50})
 .fetch();
 
 apiService
 .entity('companyUsers')
 .find('email', 'my@email.com')
 .fetch()
 .then();
 
 // fetch fragments
 apiService
 .entity(['companyUsers', 'account'])
 .byId(26)
 .fetch();
 });*/
