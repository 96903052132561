import {useQuery} from 'react-query';
import {objectApi} from '../../../application/entities/dataApi';
import {BackendError} from '../../../types/backendError';
import {useQueryProps} from './useAudiencesQuery';


export const useCompanyUsersQuery = (accountId: number, options: useQueryProps = {} as useQueryProps, hookOpt: Record<string, any> = {}) => {

    const {params = {}, pageSize = 50, pageNumber = 0, sortBy = 'firstName', sortDir = 'desc'} = options;

    const accountsApi = new objectApi.accounts();

    return useQuery<any, BackendError>(['account', accountId, 'companyUsers'], () => {
        return accountsApi.getCompanyUsers(accountId, {params, page: {pageSize, pageNumber}, sort: {sortBy, sortDir}})
            .then(res => res);

    }, {
        enabled: Boolean(accountId),
        ...hookOpt,
        keepPreviousData: false,
        cacheTime: 10000
    });

};