import { IAccounts } from "./accounts";

export enum OperationStatus {
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export interface IVault {
  id: number;
  lastVaultOperationStatus: OperationStatus;
  account: IAccounts;
  environment: string;
  awsAccountId: number;
  awsRegion: string;
  name: string;
  enableTracker: boolean;
  enableUpload: boolean;
  dataPremiumPercent: number;
  s3Url: number;
  awsAccountUrl: string;
  pixelTag: string;
  lastVaultOperationLabel: string;
  lastDataProcessingStatus: OperationStatus;
  lastDataProcessingLabel: string;
  lastDataProcessingJobId: string;
  state: string;
}
