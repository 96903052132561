import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { EnablementSteps, useAudienceEnablementStore } from "../store/AudienceEnablement.store";
import { TStep } from "../types";
import { StepConnector, stepConnectorClasses, styled } from "@mui/material";
import { useShallow } from "zustand/react/shallow";

export const EnablementStepper = () => {
  const currentStep = useAudienceEnablementStore(useShallow((state) => state.currentStep));
  const stepsActivation = useAudienceEnablementStore((state) => state.stepsActivation);
  const stepBackDisabled = useAudienceEnablementStore((state) => state.stepBackDisabled);
  const paymentInProgress = useAudienceEnablementStore((state) => state.paymentInProgress);

  const stepsDisplayed = stepsActivation.filter((step) => step.isVisible === true);
  const stepActiveIndex = stepsDisplayed.findIndex((step) => step.name === currentStep);
  const currentStepIndex = stepsDisplayed.findIndex((stepDisplay) => stepDisplay.name === currentStep);

  const handleStepChange = (step: TStep) => {
    const newStepIndex = stepsDisplayed.findIndex((stepDisplay) => stepDisplay.name === step);
    if (!stepBackDisabled && newStepIndex <= currentStepIndex && !paymentInProgress) {
      useAudienceEnablementStore.setState({ currentStep: step });
    }
  };

  return (
    <Box sx={{ maxWidth: "1200px", marginInline: "auto" }}>
      <Stepper activeStep={stepActiveIndex} alternativeLabel connector={<CustomConnector />}>
        {stepsDisplayed.map((step) => {
          const isFinished = stepActiveIndex > stepsDisplayed.findIndex((stepDisplay) => stepDisplay.name === step.name);
          const isActive = stepActiveIndex === stepsDisplayed.findIndex((stepDisplay) => stepDisplay.name === step.name);
          const dataCyStatus = isFinished ? "finished" : isActive ? "active" : "disabled";
          return (
            <Step
              key={step.name}
              sx={{ cursor: isFinished && !stepBackDisabled && !paymentInProgress ? "pointer" : "default" }}
              onClick={() => handleStepChange(step.name)}
            >
              <StepLabel data-cy={`step-${EnablementSteps[step.name]}`} data-cy-status={dataCyStatus} StepIconComponent={QontoStepIcon}>
                {EnablementSteps[step.name]}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

const QontoStepIcon = styled(StepLabel)(({ theme }) => ({
  [`.MuiStepIcon-text`]: {
    fill: "#fff",
  },
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, #eaeaf0 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));
