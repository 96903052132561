import { Box, Button, Checkbox, CircularProgress, Divider, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { objectApi } from "application/entities/dataApi";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { useEffect, useState } from "react";
import { IAccounts } from "types";

type CopyToBrandProps = {
  audience: IAudiences;
};

const accountsApi = new objectApi.accounts();
const audiencesApi = new objectApi.audiences();

export const CopyToBrandPopup = (props: CopyToBrandProps) => {
  const { audience } = props;
  const [brandList, setBrandList] = useState<{ subAccount: IAccounts }[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Array<{ name: string; id: number }>>([]);
  const [hasExclusionList, setHasExclusionList] = useState<boolean>(false);

  const [copyProgress, setCopyProgress] = useState<number>(0);

  const [status, setStatus] = useState<"loading" | "waiting" | "process" | "finished" | "error">("loading");

  useEffect(() => {
    accountsApi.byId(audience.account.id).then((currentAccount: IAccounts) => {
      accountsApi.byId(currentAccount.parentCompanyId).then((parentAccount: IAccounts) => {
        const brandList = parentAccount.brandAccountAssociations
          .sort((a, b) => (a.subAccount.companyName > b.subAccount.companyName ? 1 : b.subAccount.companyName > a.subAccount.companyName ? -1 : 0))
          .filter((brand) => brand.subAccount.id !== audience.account.id);
        setBrandList(brandList);
        setStatus("waiting");
      });
    });
    audiencesApi.byId(audience.id).then((res: IAudiences) => {
      const exclusionList = res.audienceFeatures.find((feature) => feature.featureTypeCode === "templateUrlList");
      setHasExclusionList(!!exclusionList);
    });
  }, [audience]);

  const handleCopy = async () => {
    setStatus("process");
    setCopyProgress(0);
    if (selectedBrands.length && audience.id) {
      for (const brand of selectedBrands) {
        await audiencesApi.duplicateAudienceToAccount({ audienceId: audience.id, accountDestinationId: brand.id }).catch((e) => {
          console.log(`error while copy audience to ${brand.name} (${brand.id})`);
        });
      }
      setStatus("finished");
      // audiencesApi
      //   .duplicateAudienceToAccount({
      //     audienceId: audience.id,
      //     accountDestinationId: selectedBrand,
      //   })
      //   .then((res) => {
      //     setStatus("finished");
      //   })
      //   .catch((err: any) => {
      //     console.log(err);
      //     setStatus("error");
      //   });
    }
  };

  const handleSelectBrand = (e: any) => {
    const selectedValues = e.target.value;
    const newItem = brandList
      .filter((brand) => selectedValues.includes(brand.subAccount.id))
      .map((res) => {
        return {
          id: res.subAccount.id,
          name: res.subAccount.companyName,
        };
      })[0];

    if (selectedBrands.find((selectedBrand) => selectedBrand.id === newItem.id)) {
      setSelectedBrands((current) => current.filter((selectedBrand) => selectedBrand.id !== newItem.id));
    } else {
      setSelectedBrands((current) => [...current, newItem]);
    }
  };

  if (status === "error") {
    return (
      <>
        <Box sx={{ p: 4 }}>
          <Typography variant="h2" sx={{ textAlign: "center", color: "error.main" }}>
            The copy failed, please retry later
          </Typography>
        </Box>
      </>
    );
  }
  if (status === "process")
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Copy in progress, please wait
        </Typography>
        {selectedBrands.map((brand, idx) => {
          return (
            <Box key={brand.id}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBlock: 1 }}>
                <Typography>{brand.name}</Typography>
                {copyProgress > idx + 1 ? <>Done!</> : <CircularProgress size={15} />}
              </Box>
              <Divider />
            </Box>
          );
        })}
        <Typography sx={{ mt: 2, textAlign: "center" }}>
          {" "}
          Progression: {copyProgress} / {selectedBrands.length}{" "}
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ p: 4 }}>
      {status === "finished" ? (
        <>
          <Typography variant="h2" sx={{ textAlign: "center", paddingBlock: 4 }}>
            Your audience has been copied
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Copy "{audience.name}" to Another Brand
          </Typography>
          {hasExclusionList && (
            <Typography sx={{ mb: 2, color: "error.main", textAlign: "center" }}>
              Important: You audience contains an exclusion List. The copy won't includes it.
            </Typography>
          )}

          <Typography sx={{ mb: 1 }}>Select the brands destination to copy your audience </Typography>
          {status === "loading" ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Select
                id="brand-selection"
                value={selectedBrands}
                renderValue={(selected) => selected.length + " Brands Selected"}
                multiple
                fullWidth
                onChange={handleSelectBrand}
              >
                {brandList?.map((brand) => {
                  return (
                    <MenuItem key={brand.subAccount.id} value={brand.subAccount.id}>
                      <Checkbox
                        sx={{ mr: 1 }}
                        size="small"
                        checked={selectedBrands.find((selectedBrand) => selectedBrand.id === brand.subAccount.id) ? true : false}
                      />

                      <ListItemText primary={brand.subAccount.companyName} />
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          )}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Button variant="contained" disabled={!selectedBrands.length || status !== "waiting"} onClick={handleCopy}>
              {!selectedBrands.length ? "Copy" : `Copy to ${selectedBrands.length} ${selectedBrands.length > 1 ? " brands" : " brand"}`}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
