import {QueryClient} from 'react-query';

export const queryClient = new QueryClient({defaultOptions: {queries: {keepPreviousData: true, staleTime: 3600000, cacheTime: 7200000}}});
// string[] means one off
// string[][] means all of
// ['...','...',[[...],[...]]
export const queryClientRefetcher = (keys: string[] | string[][]) => {
  return queryClient.refetchQueries({
    predicate: (query) => {
      
      // console.log(query.queryKey,keys.some(x => typeof x === 'string' ? query.queryKey.includes(x) : x.every(y => query.queryKey.includes(y))))
      return keys.some(x => typeof x === 'string' ? query.queryKey.includes(x) : x.every(y => query.queryKey.includes(y)));
    }
  });
};