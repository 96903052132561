/**
 created by Mydde
 */

import * as React from "react";
import { ColumnsMiddle, GridItemFull } from "components/ui/AppElements";
import { pageUtils } from "../../../application/pages/pages.utils";
import { Breadcrumbs, Link, Skeleton } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { IAppPage } from "../../../application/pages/pages.utils.types.tmp";
import { useAuthState } from "../../../redux/slices/auth.slices";
import { styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { granter, IAccessOptions } from "../../../application/utils/granter.utils";
import { AUTHUser } from "../../../application/utils/AuthUser";
import { useUiStore } from "../../../redux/ui.store";
import { useLoggedUser } from "../../hooks/useLoggedInUser";

export interface IBreadCrumbProps {
  frameId: string;
  crumbTitle?: string;
  idCompany?: string;
  idBrand?: string;
  noParent?: boolean;
  params?: Record<string, string>;
}

export const BreadCrumb = (props: IBreadCrumbProps) => {
  // Get current Brand
  const currentBrand = useUiStore((st) => st.currentBrand);
  const currentCompany = useUiStore((st) => st.currentCompany);
  const currentAccountMode = useUiStore((st) => st.accountMode);

  const authState = useAuthState();
  // const
  const loggedInUser = useLoggedUser();
  let urlParams = useParams<Record<string, string>>();
  if (props.params) {
    urlParams = props.params;
  }
  const { frameId, crumbTitle, noParent } = props;
  const page = pageUtils.getPageById(frameId);

  const getParentality = (frameId: string): IAppPage[] => {
    const arrCollect: any[] = [];

    setParentality(frameId);

    return arrCollect.reverse();

    function setParentality(frameId: string): void {
      const pageParent = pageUtils.getPageParentFromId(frameId);

      if (pageParent && Object.keys(pageParent).length) {
        arrCollect.push(pageParent);
        setParentality(pageParent?.id ?? "");
      }
    }
  };

  const arrParent = getParentality(frameId) ?? [];

  const youAreConnected = () => {
    if (currentAccountMode === "BRAND" && currentBrand?.companyName) {
      return (
        "You are in " + currentBrand?.parentAccountAssociations[0]?.parentAccount?.companyName + " company working on " + currentBrand.companyName
      );
    }
    if (currentAccountMode === "COMPANY" && currentCompany?.companyName) {
      return "You are in " + currentCompany.companyName + " company";
    }
    if (currentAccountMode === undefined && authState?.user?.account?.companyName) {
      return "You are in " + authState?.user?.account?.companyName + " company";
    }
    return null;
  };

  return (
    <BreadContainer padding={[0.5, 1, 0.5, 1.5]}>
      <GridItemFull>
        <Breadcrumbs aria-label="breadcrumb">
          {/*<Link
           component={RouterLink} to="/"
           >
           Dashboard
           </Link>*/}
          {!noParent &&
            arrParent.map((indexPage: IAppPage, index: number) => {
              let accessAllows = false;
              const page = indexPage.id ? pageUtils.getPageById(indexPage.id) : ({} as IAppPage);
              const path = indexPage.id ? pageUtils.getPagePathById(indexPage.id, urlParams) : "";

              if (page?._accesses)
                accessAllows = granter
                  .grantAccesses(page._accesses as IAccessOptions)
                  .with(AUTHUser.granterUser)
                  .toUser(AUTHUser.granterUser);
              // Manage user when i am on company
              if (!currentBrand?.companyName) {
                // arrParent.splice(1, 1);
              }
              if (currentBrand?.companyName === authState?.user?.account?.companyName) {
                // arrParent.splice(0, 1);
              }

              return (
                accessAllows && (
                  <Link key={`${indexPage.path}`} component={RouterLink} to={path}>
                    {indexPage.title === "Dashboard"
                      ? authState?.user?.account?.companyName
                      : indexPage.title === "Brand Home" && currentBrand?.companyName
                      ? currentBrand?.companyName
                      : indexPage.title}
                  </Link>
                )
              );
            })}
          <div> {crumbTitle ?? (page.title === "Brand Home" || page.title === "DashboardBrand" ? currentBrand?.companyName : page.title)}</div>
        </Breadcrumbs>
      </GridItemFull>

      {Boolean(youAreConnected()) ? (
        <UserRoleInfo data-cy={"user-role-info"}>{youAreConnected()}</UserRoleInfo>
      ) : (
        <Skeleton variant="text" width={200} />
      )}
    </BreadContainer>
  );
};

const BreadContainer = styled(ColumnsMiddle)((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  borderBottom: "1px solid " + props.theme.skin.borderColor,
}));

const UserRoleInfo = styled("div")({
  color: "#878D99",
  textAlign: "right",
});
