import {PageId} from "application/pages/pages.config";
import {MainFrame} from "components/templates/MainFrame";
import {objectApi} from "../../application/entities/dataApi";
import {Link, useNavigate, useParams} from "react-router-dom";
import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {AccountHeader} from "../Agencies/AccountHeader";
import React from "react";
import {dataColumnModelUtils} from "../../application/utils/dataColumnModel.utils";
import {GridRowParams} from "@mui/x-data-grid";
import {GridTable} from "../../components/organisms/GridTable";
import {accountsColumns} from "../../application/entities/dataColumns/companyBrands.columns";
import {pageUtils} from "../../application/pages/pages.utils";
import {Button, Divider, styled, Theme, Typography} from "@mui/material";
import {FormBlock, ShadowBox} from "../../components/templates/InfoBlock";
import {ActionGranter} from "../../components/features/ActionGranter";
import {accessList} from "../../application/access/access";
import {useTranslation} from "react-i18next";
import {GridMiddleCenter} from "../../components/ui/AppElements";
import {AppIcon} from "../../components/ui/AppIcon";
import {useAccountTracker} from "../Agencies/useAccountTracker";
import {useUserQuery} from "../../components/hooks/data/useUserQuery";
import {AUTHUser} from "../../application/utils/AuthUser";
import {ICompanyUsers} from "../../application/entities/dataTypes/companyUser";
import {useQuery} from "react-query";
import {EPermission} from "../../application/utils/granter.utils";
import {CompanyRoles} from "../Dashboard/widgets/CompanyRoles";

interface ListCompanyBrandProps {
    companyId?: number;
}

export const DashBoardCompany = (props: ListCompanyBrandProps) => {
    const {t} = useTranslation();
    const accountApi = new objectApi.accounts();
    const audienceApi = new objectApi.audiences();
    // receive companyId and brandId in uri.params or props
    const uriParams = useParams<{ companyId: string }>() as { companyId: string };
    const companyId = props?.companyId ?? +uriParams?.companyId;
    const navigate = useNavigate();
    const [mode, setMode] = React.useState<"userAssoc" | "brandData">("userAssoc");

    const userQuery = useUserQuery({userId: AUTHUser.userId});
    const userData: ICompanyUsers = userQuery?.data ?? {};
    const userAssoc = !userData.agentBrandAccountAssociations ? [] : userData.agentBrandAccountAssociations.map((ass) => ass.account);
    // remove this line
    const brandQy = useQuery(["account", companyId, "brandAccountAssociations"], () =>
        accountApi.getBrandAccountAssociations(companyId).then((res) => res)
    );
    // update this one, brandDataSet =  useBrandAccountAssociation
    const brandDataSet = brandQy.data?.data?.brandAccountAssociations ?? [];
    const brandData = brandDataSet.map((x: any) => x.subAccount);

    const [audiencesToApprove, setAudiencesToApprove] = React.useState<any>([]);
    const [audiencesApproved, setAudiencesApproved] = React.useState<any>([]);

    // account tracker and mode setter
    useAccountTracker(companyId);

    function handleRowClick(param: IAccounts) {
        navigate(pageUtils.getPagePathById(PageId.brandDashboard, {companyId, brandId: param.id}));
    }

    function createUser() {
        navigate(pageUtils.getPagePathById(PageId.companyUserList, {companyId}));
    }

    // userAssoc vs brandData
    const gridData = AUTHUser.hasRole(EPermission.COMPANYADMIN) ? brandData : userAssoc;

    //adding data-cy company name
    gridData.map((row: any) => (row.code = `brand-${row.companyName}`));

    const texts = {
        brandData: {
            title: "All Brands",
            btn: "See your brands",
        },
        userAssoc: {
            title: "Your Brands",
            btn: "See all brands",
        },
    };

    React.useEffect(() => {
        if (userData?.id) {
            audienceApi.getAudiencesToApproveByUser(userData?.id).then((res) => {
                setAudiencesToApprove(res.data?._embedded?.audiences);
            });
            audienceApi.getAudiencesApprovedByUser(userData?.id).then((res) => {
                setAudiencesApproved(res.data?._embedded?.audiences);
            });
        }
    }, [userData]);

    return (
        <MainFrame
            frameId={PageId.companyDashboard}
            checkPageAccess={true}
            loading={userQuery.isLoading}
            grantWith={{accountId: userData.account?.id}}
            mainFrameHeaderContent={<AccountHeader accountId={companyId} frameId={PageId.companyDashboard}/>}
        >
            <Container className={"pad-4"} style={{minHeight: "100%"}}>
                <div className={"flex-h gap-medium"} style={{maxWidth: "1980px"}}>
                    <div className={"flex-main"}>
                        <ShadowBox>
                            <FormBlock
                                className={"pad-ii-3 pad-b-2"}
                                noBorder
                                style={{height: "400px"}}
                                title={texts[mode].title}
                                icon={"Brand"}
                                buttons={
                                    <ActionGranter accesses={[{grade: EPermission.COMPANYADMIN}]}>
                                        <Button
                                            data-cy="dashboard-add-brand-btn"
                                            onClick={() => {
                                                navigate(pageUtils.getPagePathById(PageId.brandCreate, {companyId}));
                                            }}
                                            variant={"outlined"}
                                        >
                                            Add New Brand
                                        </Button>
                                    </ActionGranter>
                                }
                            >
                                <GridTable
                                    dataCy="brand-list"
                                    styleClass={"naked"}
                                    noRowsText={
                                        AUTHUser.permissions.accountGrade === EPermission.COMPANYADMIN
                                            ? "Create your first brand account"
                                            : "You have no brands assigned to you"
                                    }
                                    data={{
                                        columns: dataColumnModelUtils.renderModel(accountsColumns, "show") as any,
                                        rows: gridData
                                    }}
                                    gridProps={{onRowClick: (param: GridRowParams<IAccounts>) => handleRowClick(param.row)}}
                                />
                            </FormBlock>
                        </ShadowBox>
                    </div>

                    <div style={{maxWidth: "297px"}}>
                        {AUTHUser.isAccountSuperAdmin() && (
                            <ShadowBox className={"pad-2"}
                                       style={{
                                           flex: 1,
                                           display: "flex",
                                           flexDirection: "column",
                                           gap: "8px",
                                           marginBottom: "16px"
                                       }}
                                       data-cy={"dashboard-your-team"}>
                                <GridMiddleCenter sx={{gap: 1}}>
                <span style={{position: "relative"}}>
                  <AppIcon color={"#3B4559"} display={"inline"} icon={"Demographics"} chip={true}/>
                </span>
                                    <SCardTitle style={{flex: 1}}>Super Admin</SCardTitle>
                                </GridMiddleCenter>
                                <Divider/>
                                <Typography>
                                    You are connected as a Super Administrator. You have special permissions on Brands.
                                </Typography>
                                <Button variant="contained"
                                        onClick={() => navigate(pageUtils.getPagePathById(PageId.brandsSettingsSuperAdmin, {companyId}))}>
                                    Brand Settings
                                </Button>

                            </ShadowBox>
                        )}
                        {audiencesToApprove?.length > 0 && (
                            <ShadowBox
                                className={"pad-2"}
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    marginBottom: "20px"
                                }}
                                data-cy={"dashboard-audiences-to-approve"}
                                data-cy-count={audiencesToApprove.length}
                            >
                                <GridMiddleCenter sx={{gap: 1}}>
                  <span style={{position: "relative"}}>
                    <AppIcon color={"#3B4559"} display={"inline"} icon={"Approval"} chip={true}/>
                  </span>
                                    <SCardTitle style={{flex: 1}}>
                                        {audiencesToApprove?.length} {audiencesToApprove?.length > 1 ? "Audiences" : "Audience"} to
                                        be approved
                                    </SCardTitle>
                                </GridMiddleCenter>
                                <Divider/>
                                <ul style={{maxHeight: "300px", overflowY: "auto"}}>
                                    {audiencesToApprove.map((audience: any) => {
                                        return (
                                            <li
                                                data-cy="audience_to_approve"
                                                data-cy-id={audience.id}
                                                style={{borderBottom: "1px solid #ededede6", paddingBlock: "0.25rem"}}
                                                key={audience.id}
                                            >
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to={pageUtils.getPagePathById(PageId.audienceApproval, {audienceId: audience.id})}
                                                    key={audience.id}
                                                >
                                                    <Typography
                                                        style={{fontWeight: "bold"}}>{audience.name}</Typography>
                                                    <Typography style={{color: "#7587A3", textDecoration: "none"}}>
                                                        {" "}
                                                        by {audience.requester.firstName + " " + audience.requester.lastName} on {audience.account?.companyName}{" "}
                                                    </Typography>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ShadowBox>
                        )}
                        {audiencesApproved?.length > 0 && (
                            <ShadowBox
                                className={"pad-2"}
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    marginBottom: "20px"
                                }}
                                data-cy={"dashboard-audiences-to-finalise"}
                                data-cy-count={audiencesApproved.length}
                            >
                                <GridMiddleCenter sx={{gap: 1}}>
                  <span style={{position: "relative"}}>
                    <AppIcon color={"#3B4559"} display={"inline"} icon={"Approval"} chip={true}/>
                  </span>
                                    <SCardTitle style={{flex: 1}}>
                                        {audiencesApproved?.length} {audiencesApproved?.length > 1 ? "Audiences" : "Audience"} to
                                        finalise
                                    </SCardTitle>
                                </GridMiddleCenter>
                                <Divider/>
                                <ul style={{maxHeight: "300px", overflowY: "auto"}}>
                                    {audiencesApproved.map((audience: any) => {
                                        return (
                                            <li
                                                data-cy="audience_to_finalise"
                                                data-cy-id={audience.id}
                                                style={{borderBottom: "1px solid #ededede6", paddingBlock: "0.25rem"}}
                                                key={audience.id}
                                            >
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to={pageUtils.getPagePathById(PageId.audienceActivation, {audienceId: audience.id})}
                                                    key={audience.id}
                                                >
                                                    <Typography
                                                        style={{fontWeight: "bold"}}>{audience.name}</Typography>
                                                    <Typography style={{
                                                        color: "#7587A3",
                                                        textDecoration: "none"
                                                    }}>{audience.account?.companyName} </Typography>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ShadowBox>
                        )}
                        <ShadowBox className={"pad-2"}
                                   style={{flex: 1, display: "flex", flexDirection: "column", gap: "8px"}}
                                   data-cy={"dashboard-your-team"}>
                            <GridMiddleCenter sx={{gap: 1}}>
                <span style={{position: "relative"}}>
                  <AppIcon color={"#3B4559"} display={"inline"} icon={"Demographics"} chip={true}/>
                </span>
                                <SCardTitle style={{flex: 1}}>{t("dashboard.titles.yourTeam")}</SCardTitle>
                            </GridMiddleCenter>
                            <Divider/>
                            <div style={{flex: 1, overflow: "auto", paddingBottom: "0.5rem"}}>
                                {/*<AccountUsers/>*/} {/*role={'ADMINISTRATOR'}*/}
                                <CompanyRoles accountId={companyId}/>
                            </div>
                            {/*<Divider/>*/}
                            <ActionGranter mode={"deny"} accesses={accessList.companyUsers.create}>
                                <Typography>{t("audience.contactAdminMoreMembers")}</Typography>
                            </ActionGranter>
                            <ActionGranter accesses={accessList.companyUsers.create}>
                                <div>
                                    <Button data-cy="dashboard-users-management-btn" onClick={createUser}
                                            variant={"outlined"}>
                                        User Management{/*{t('dashboard.titles.inviteMore')}*/}
                                    </Button>
                                </div>
                            </ActionGranter>
                        </ShadowBox>
                    </div>
                </div>
            </Container>
        </MainFrame>
    );
};

const Container = styled("div")((props: { theme: Theme }) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
}));

const SCardTitle = styled("div")((props: { theme: Theme }) => ({
    fontWeight: "bold",
    color: "#7587A3",
    fontSize: "18px",
}));
