import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { rootReducer, RootState } from './rootReducer';


const logger = createLogger();
export const history = createBrowserHistory();
const sagaMiddleWare = createSagaMiddleware();

// @ts-ignore
const configureAppStore = (preloadedState?: RootState) => configureStore({
  reducer: rootReducer(history),
  preloadedState,
  middleware: [
    sagaMiddleWare,
    // logger,
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
});

const appStore = configureAppStore();


export { appStore as store } ;