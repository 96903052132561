import {AbstractBaseApi} from './AbstractBaseApi';
import {apiService} from '../api/apiService';
import {AxiosResponse} from 'axios';
import {ISubscriptionPlans} from '../dataTypes/subscriptionPlans';
import {TEntities} from '../dataTypes';


export class SubscriptionPlans extends AbstractBaseApi<ISubscriptionPlans> {
  constructor() {
    super('subscriptionPlans');
  }

   /** @deprecated should be in subscriptions according to REST api **/
  getSubscriptionPlan(id: number) {
    return apiService.entity(['subscriptions', 'subscriptionPlan'])
                     .byId(id)
                     .get()
                     .then((res: AxiosResponse<TEntities['subscriptionPlans']>) => {
                       return res?.data;
                     });
  }
}