import {createTheme} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';


const defaultTheme = createTheme();


export const useStylesSelectRules = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        border: 0,
        marginRight: '-10px',
        height: '30px',
        width: '220px',
        '& .MuiSelect-select.MuiSelect-select ': {
          marginRight: '-7px',
          width: '100%',
          height: '100%',
          boxShadow: 'none',
        },
        '& .MuiSelect-select': {
          '&:focus': {
            border: '0.5px solid transparent',
          },
        },
      },
      menuPaper: {
        maxHeight: 300,
      },
    };
  },
  { defaultTheme },
);


export const useStylesSelectNumber = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        border: 0,
        // marginRight: '-10px',
        minWidth: '10px',
        '& .MuiSelect-select.MuiSelect-select ': {
          marginRight: '-7px',
          width: '100%',
          boxShadow: 'none',
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#3B4559',
          background: 'transparent',
          justifyContent: 'center',
          '&:focus'      : {
            border         : '0.5px solid transparent',
            backgroundColor: 'transparent',
          },
        },
        '& .MuiPaper-root': {
          background: 'blue',
        },
      },
    };
  },
  {defaultTheme},
);