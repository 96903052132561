import {useAuthActions} from 'redux/slices/auth.slices';
import {AppStepper} from 'components/ui/AppStepper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import AuthStrength from '../../../live/AuthStrength';
import {AuthHeader, AuthSignZone, AuthTStepBox, HtmlForm} from '../authentication.styled';
import {H5, HeaderZoneIconBar, HeaderZoneMessage, HeaderZoneTitle,} from '../../../ui/AppElements';
import {Button, Fade, TextField} from '@mui/material';
import {AppIcon} from '../../../ui/AppIcon';
import {InputLabel} from '../../../form/Input';
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import {PageId, appPages} from '../../../../application/pages/pages.config';
import { authStore } from 'stores/auth.store';
import { pageUtils } from 'application/pages/pages.utils';


interface Props {}

interface ISignupVerifyState {
  email: string;

  [key: string]: any;
}

export const RetrievePassword = (props: Props) => {
  const authStoreActions = authStore(st => st.actions);

  const authActions      = useAuthActions();
  const {t}              = useTranslation();
  let navigate            = useNavigate();

  const [signStep, setSignStep]       = React.useState<number>(0);
  const [passStr, setPassStr]         = React.useState<number>(0);
  const [disableForm, setDisableForm] = React.useState<boolean>(true);

  const [passwordForce, setPasswordForce] = React.useState<number>(0);
  // const [regexOk, setPasswordRegex] = React.useState<true>(false);

  const [retrievePasswordState, setRetrievePasswordState] =
          React.useState<ISignupVerifyState>({
            email   : '',
            code    : '',
            password: '',
          });


  const titles = [
    t('auth.retrievePassword.title.step1'),
    t('auth.retrievePassword.title.step2'),
    t('auth.retrievePassword.title.step3'),
  ];

  const messageText = [
    t('auth.retrievePassword.message.step1'),
    t('auth.retrievePassword.message.step2'),
    t('auth.retrievePassword.message.step3'),
  ];

  const btnTitles = [
    t('auth.retrievePassword.submitText.step1'),
    t('auth.retrievePassword.submitText.step2'),
    t('auth.retrievePassword.submitText.step3'),
  ];

  // disabled form should be a different dataset
  const handleFormChange = (event: any) => {
    const inputName  = event.target.name;
    const inputValue = event.target.value;

    setRetrievePasswordState(function (state) {
      return {
        ...state,
        [inputName]: inputValue,
      };
    });

    // enabled form ifemail not empty
    if (signStep === 0) {
      if (retrievePasswordState.email) {
        // check email validity, enable / disabled form
        if (validateEmail(retrievePasswordState.email)) {
          setDisableForm(false);
        }
      }
    }

    if (signStep === 1) { //&& inputName === "password"

      let force = passwordForce;

      setPassStr(force);
      if (force >= 60) {
        setDisableForm(false);
      } else {
        setDisableForm(true);
      }
    }
  };

  const validateEmail = (email: string) => {
    const res =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  };

  const getPassWordForce = (force: number) => {
    setPasswordForce(force);
  };

  const verifyEntry = (
    originField: string,
    destinationField: string,
    currentValue: string
  ) => {
    if (retrievePasswordState[originField] !== currentValue) {
      setDisableForm(false);
    } else {
      setDisableForm(true);
    }
  };

  const onStepClick = (step: number) => {
    // cannot go forward
    if (step > signStep) return;
    setSignStep(step);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (signStep === 0) {
      authStoreActions.doRetrievePassword({email: retrievePasswordState.email})
                      .then((res: any) => {
                        if (res?.data?.flowType === 'FORGOT_PASSWORD') {
                          setSignStep(1);
                          setDisableForm(true);
                        } else if (res?.data?.flowType === 'USER_ACTIVATION') {
                          setDisableForm(true);
                          setSignStep(0);
                          // navigate(appPages.auth.user_invitation.path.replace(':email', retrievePasswordState?.email));
                          document.location.href=appPages.auth.user_invitation.path.replace(':email', retrievePasswordState?.email) // CMVP-1440
                        }
                      })
                      .catch((er) => {
                        // setSignStep(0);
                      });
    }

    if (signStep === 1) {
      // pass strength is supposed to be confirmed here
      authStoreActions
      .doRedefinePassword({
        code    : retrievePasswordState.code,
        email   : retrievePasswordState.email,
        password: retrievePasswordState.password,
      })
      .then((data: any) => {
        // is response ok ?
        if (data?.meta?.requestStatus !== 'rejected') {
          setSignStep(2);
        }
      })
      .catch((error: any) => {
        setSignStep(0);
      });
    }

    if (signStep === 2) {
      navigate('/');
      return;
    }

    return false;
  };

  const regexCrit = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#.,;`^~])(?=.{8,})/; 
  const regexOk           = Boolean(regexCrit.test(retrievePasswordState?.password));
  const validEmail   = validateEmail(retrievePasswordState.email);
  const samePass     = retrievePasswordState.password === retrievePasswordState?.password_2;
  const showDupError = retrievePasswordState.password?.length && retrievePasswordState.password_2?.length && retrievePasswordState.password !== retrievePasswordState.password_2;

  React.useEffect(() => {
    // clear errors showing on enter
    // deprecated redux
    authActions.resetError();
    // zustand
    authStore.setState({showError: false});
  }, []);

  React.useEffect(() => {
    setDisableForm(true);
    if (signStep === 0) {
      if (validEmail) setDisableForm(false);
    }
    if (signStep === 1) {
      if (validEmail && regexOk && samePass) setDisableForm(false);
    }
    if (signStep === 2) {
      setDisableForm(false);
    }
  }, [signStep, retrievePasswordState, validEmail, regexOk, samePass]);

  const passCrit = 'Minimum length: \n' +
                   '8 characters\n' +
                   'Must contain: \n' +
                   '1 upper and 1 lowercase letter\n' +
                   'One special character (ie. @ & # % etc)\n' +
                   'One number\n';

  //

  return (
    <Fade in={true} timeout={1000} unmountOnExit>
    <AuthSignZone>
      <AuthHeader>
        <HeaderZoneIconBar>
          <AppIcon display={'inline'} icon={'ResetPasswordIcon'} fontSize={'medium'}/>
        </HeaderZoneIconBar>
        <HeaderZoneTitle>
          <H5 display={'inline'}>{titles[signStep]}</H5>
        </HeaderZoneTitle>
        <HeaderZoneMessage>
          <p>{messageText[signStep]}</p>
          {(signStep !== 2) && <p>If you experience troubles contact your administrator</p>}
        </HeaderZoneMessage>
      </AuthHeader>
      <HtmlForm>
        <form onSubmit={handleSubmit} onChange={handleFormChange} autoComplete={'off'}>
          {signStep === 0 && <><InputLabel>Your email</InputLabel>
              <TextField
                  type={'email'}
                  name={'email'}
                  required={true}
                  placeholder={'email@example.com'}
                  value={retrievePasswordState['email']}
                  autoComplete={'off'}
                  role={'presentation'}
                // onChange={(event) => {setRetrievePasswordState((st)=>({...st,'email':event.target.value}))}}
                  style={{width: 224}}
                  inputProps={{'data-cy': 'input_email'}}
              /></>}
          {signStep === 1 && <>
              <InputLabel>Verification code</InputLabel>
              <TextField
                  type={'text'}
                  name={'code'}
                  required={true}
                  placeholder={'Verification code'}
                  value={retrievePasswordState['code']}
                  autoComplete={'off'}
                  onChange={() => {}}
                  style={{width: 224}}
                  inputProps={{'data-cy': 'input_code'}}
              />
              <InputLabel>
                  <span className={'flex-h flex-inline flex-align-middle'} style={{gap: '0.5rem'}}>
                      <div>New password </div>
                      <AppTip title={'Password Criteria'} text={passCrit}/>
                  </span>
              </InputLabel>
              <TextField
                  type={'password'}
                  name={'password'}
                  required={true}
                  placeholder={'New password'}
                  value={retrievePasswordState['password']}
                  autoComplete={'new-password'}
                  onChange={() => {}}
                  style={{width: 224}}
                  inputProps={{'data-cy': 'input_password'}}
                  error = {Boolean(!regexOk) && retrievePasswordState?.password?.length > 0}
                  helperText={Boolean(!regexOk) && retrievePasswordState?.password?.length > 0 ? 'Not matching criteria' : undefined}
              />
               {Boolean(regexOk) && <>
              <AuthStrength
                  password={retrievePasswordState['password']}
                  getForceCallback={getPassWordForce}
              />
              </>}
              <InputLabel>{'Re-enter new Password'}</InputLabel>
              <TextField
                  type={'password'}
                  name={'password_2'}
                  required={true}
                  placeholder={''}
                  defaultValue={
                    retrievePasswordState['password_2']
                  }
                  autoComplete={'new-password'}
                  onChange={(event) => {
                    verifyEntry(
                      'password',
                      'password_2',
                      event.target.value
                    );
                  }}
                  style={{width: 224}}
                  error={showDupError}
                  helperText={showDupError ? t('auth.noPasswordMatch') : undefined}
                  inputProps={{'data-cy': 'input_check_password'}}

              />
          </>}
          <br/>
          <br/>
          {/* button has to be disabled on !password.match & password.strength */}
          <Button data-cy={'submit_' + signStep} variant="contained" disabled={disableForm} type={'submit'}>
            {btnTitles[signStep]}
          </Button>
          <br/>
          <br/>
          <AuthTStepBox>
            <AppStepper onStepClick={onStepClick} steps={3} activeStep={signStep}/>
          </AuthTStepBox>
        </form>
      <Link to={pageUtils.getPagePathById(PageId.signin)} data-cy={'signin-link'}>
            {t('auth.signIn.backToSignin')}
          </Link>
      </HtmlForm>
    </AuthSignZone>
    </Fade>
  );
};
