import * as React from 'react';
import {TIcon} from '../../../assets/icons';
import {Elem} from '../AppElements';
import styled from 'styled-components';
import {AppIcon} from '../AppIcon';
import {Box} from '@mui/material';

export interface IAppTagProps {
  id?: number,
  name: string,
  index?: number,
  icon?: TIcon,
  helperText?: string
  handleRemove?: Function
  onClick?: Function
  dense?: boolean
  fullSize?: boolean
  viewMode?: boolean
};

export const AppTag = (props: IAppTagProps) => {
  
  const {id=0, index, name, handleRemove, dense,onClick, viewMode, fullSize} = props;
  
  const padding           = dense ? 0 : 0.5;
  const handleClickRemove = (id: number, index?: number) => {
    handleRemove && handleRemove(id, index);
  };
  
  return (
    <Tag style={{maxWidth: fullSize ? '100%':'auto'}} onClick={()=>{if(onClick) onClick()}} data-cy={'AppTag'}>
      {(handleRemove && !viewMode) && <TagCloser>
          <AppIcon color={'red'} onClick={() => {
            handleClickRemove(id, index);
          }} fontSize={'small'} display={'block'} icon={'Close'}/>
      </TagCloser>}
      <Box sx={{py: padding, px: 1}}>{name}</Box>
    </Tag>
  );
};

const TagCloser = styled(Box)({
  cursor   : 'pointer',
  position : 'absolute',
  right    : '0.25rem',
  top      : '50%',
  marginTop:'-8px',
  '&:hover': {
    backgroundColor: '#E4EAF0',
  }
});

const Tag = styled(Elem)({
  backgroundColor: '#E4EAF0',
  display        : 'inline-flex!important',
  borderRadius   : '80px',
  marginLeft     : 0,
  position       : 'relative',
  textOverflow   : 'ellipsis',
  overflow       : 'hidden',
  whiteSpace     : 'nowrap',
  [TagCloser]    : {
    display: 'none',
  },
  '&:hover'      : {
    [TagCloser]: {
      display        : 'block',
      backgroundColor: '#E4EAF0',
    }
  }
});
