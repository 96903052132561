import {Box, Collapse, Divider, Paper, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {objectApi} from "application/entities/dataApi";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {IActionButtons} from "components/controls/ActionButtons";
import {ActionMenu} from "components/controls/ActionMenu";
import {useFirstPartyData} from "components/hooks/useFirstPartyData";
import {useAppToast} from "components/live/AppToast";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {AppIcon} from "components/ui/AppIcon";
import moment from "moment";
import {useState} from "react";
import {RenameListForm} from "./renameListForm";

export const FirstPartyItem = (props: { file: FirstPartyDataFile; accountId: number }) => {
    const accountsApi = new objectApi.accounts();

    const {file, accountId} = props;
    const appToast = useAppToast();

    const [displayInfo, setDisplayInfo] = useState<boolean>(false);
    const [displayProcessingMessage, setDisplayProcessingMessage] = useState<boolean>(false);
    const {refetch} = useFirstPartyData(+accountId);

    const dialogWindow = useOpenDialogWindow();
    const infos: { label: string; key: keyof FirstPartyDataFile }[] = [
        {
            label: "Panorama Id",
            key: "numPanoids",
        },
        {
            label: "ID5",
            key: "numId5s",
        },
        {
            label: "Hashed Emails",
            key: "numHems",
        },
        {
            label: "TradeDesk Id",
            key: "numTtdids",
        },
        {
            label: "Pubmatic Cookie Id",
            key: "numPubmaticCookies",
        },
        {
            label: "Ramp Id",
            key: "numRampids",
        },
        {
            label: "UID2",
            key: "numUid20s",
        },
        {
            label: "Android Id",
            key: "numAndroidids",
        },
        {
            label: "iOS Id",
            key: "numIosids",
        },
    ];

    const handleDelete = (fpdCode: string) => {
        dialogWindow.open({
            dialogId: "ListDelete",
            buttonActions: {acceptButton: true, cancelButton: true},
            title: "Delete this First Party Data?",
            description: "This action is irreversible, all the data will be removed from our server",
            icon: "Trash",
            onAccept: () => {
                accountsApi
                    .deleteFirstPartyData(accountId, fpdCode)
                    .then((res) => {
                        appToast.open({
                            toastId: "globalToast",
                            severity: "success",
                            message: "Your First Party Data has been deleted",
                        });
                        refetch();
                    })
                    .catch((e) => {
                        console.error(e);
                        appToast.open({
                            toastId: "globalToast",
                            severity: "error",
                            message: "Error while deleting Your First Party Data, please try later",
                        });
                    });
            },
        });
    };

    const handleRename = () => {
        dialogWindow.open({
            dialogId: "RenameListModal",
            component: RenameListForm,
            title: `Rename file "${file.name}"`,
            icon: "Upload",
            componentProps: {
                code: file.code,
                currentName: file.name,
                accountId: accountId,
                currentDescription: file.description,
            },
        });
    };

    const actionButtons: IActionButtons = {
        rename: {
            title: "Edit",
            icon: "Edit",
            variant: "contained",
            action: () => handleRename(),
        },
        delete: {
            title: "Delete",
            icon: "Trash",
            variant: "contained",
            action: () => handleDelete(file.code),
        },
    };

    const fileName = file.name.replaceAll('_', ' ')

    return (
        <Paper elevation={4}
               sx={{
                   backgroundColor: "white",
                   borderRadius: "7px",
                   padding: "2rem",
                   lineHeight: "1.1",
                   height: "100%",
                   width: "100%"
               }}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box display="flex" alignItems="center" gap={1.5} sx={{width: "100%"}}>
                    <AppIcon icon="File"/>
                    <Box sx={{width: "100%", flex: 1}}>
                        <Typography
                            title={fileName}
                            sx={{
                                fontSize: "16px",
                                lineHeight: "1.2",
                                fontWeight: "bold",
                                lineBreak: fileName.split(' ').length > 1 ? "loose" : "anywhere",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "calc(100% - 30px)"
                            }}>{fileName}</Typography>
                        <Typography className="color-tech-grey" sx={{fontWeight: "900"}}>
                            Uploaded on {moment(file.creationTime).format("D MMM YYYY")}
                        </Typography>
                    </Box>
                </Box>
                <ActionMenu actions={actionButtons} vars={{code: file.code}}/>
            </Box>
            <Divider sx={{mt: 1, mb: 1}}/>

            <Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    marginBlock: "12px"
                }}>
                    <Typography className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}}>
                        Status:
                    </Typography>
                    {file.uploadStatus === "SUCCESS" && (
                        <Typography className="color-tech-grey" sx={{fontSize: "14px"}}>
                            Success
                        </Typography>
                    )}
                    {file.uploadStatus === "PENDING" && (
                        <Typography className="color-tech-grey" sx={{fontSize: "14px"}}>
                            Pending
                        </Typography>
                    )}
                    {file.uploadStatus === "ERROR" &&
                        <Typography sx={{fontSize: "14px", color: "#d96565", fontWeight: "900"}}>Error</Typography>}

                    {file.processingMessage && (
                        <AppIcon
                            color={"#7587A3"}
                            fontSize={"small"}
                            icon={"InfoIcon"}
                            style={{cursor: "pointer"}}
                            onClick={() => setDisplayProcessingMessage(!displayProcessingMessage)}
                        />
                    )}
                </Box>

                <Collapse orientation="vertical" in={displayProcessingMessage}>
                    <Box mb="12px"
                         sx={{border: "1px solid #ececec", borderRadius: "7px", padding: "8px", background: "#fcfcfc"}}>
                        <Typography className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}}>
                            Processing Message
                        </Typography>
                        <Typography className="color-tech-grey">{file.processingMessage}</Typography>
                    </Box>
                </Collapse>

                <Box mb="12px"
                     sx={{border: "1px solid #ececec", borderRadius: "7px", padding: "8px", background: "#fcfcfc"}}>
                    <Typography className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}}>
                        Description
                    </Typography>
                    <Typography className="color-tech-grey">{file.description || "No description"}</Typography>
                </Box>

                <Typography className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}} mb={1}>
                    Uploaded
                    Profiles: {file.uploadStatus === "PENDING" ? 'Pending' : profilesFormatter.format(Number(file.numInputLines))}
                </Typography>

                <Box sx={{display: "flex", gap: "8px", alignItems: "center", cursor: "pointer"}}
                     onClick={() => setDisplayInfo(!displayInfo)}>
                    <Typography className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}}>
                        Unique
                        Profiles: {file.uploadStatus === "PENDING" ? 'Pending' : profilesFormatter.format(Number(file.numRawProfiles))}
                    </Typography>
                    {file.uploadStatus !== "PENDING" &&
                        <div style={{transform: displayInfo ? "rotate(180deg)" : "rotate(0deg)"}}>
                            <AppIcon icon="ChevronDownIcon" color="#7587A3" fontSize="small"/>
                        </div>}
                </Box>

                <Collapse orientation="vertical" in={displayInfo}>
                    {infos.map((info, idx) => {
                        if (!file[info.key]) return null;
                        return (
                            <Box key={info.key} display="flex" alignItems="center" justifyContent="space-between"
                                 gap={1} py={1} borderBottom={"1px solid #eee"}>
                                <Typography className="color-tech-grey" fontWeight="bold">
                                    {info.label}
                                </Typography>
                                <Typography
                                    className="color-tech-grey">{profilesFormatter.format(Number(file[info.key]) || 0)}</Typography>
                            </Box>
                        );
                    })}
                </Collapse>

                <Typography mt={1} className="color-tech-grey" sx={{fontWeight: "900", fontSize: "14px"}}>
                    Enhanced
                    Profiles: {file.uploadStatus === "PENDING" ? 'Pending' : profilesFormatter.format(Number(file.numEnrichedProfiles))}
                </Typography>
            </Box>
        </Paper>
    );
};
