/**
 created by Mydde
 */

import * as React from 'react';
import {Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import { AppIcon } from 'components/ui/AppIcon';

interface AppTipProps {
  text: string;
  title?: string;
  disabled?: boolean;
  children?: any;  color?: string;
};

export const AppTip = (props: AppTipProps) => {
  
  const arText = props.text.split('\n').map((str:string,idx:number) => <p key={'k'+idx}>{str}</p>) ;
  
  
  return props.disabled? props.children : <HtmlTooltip placement="right" title={<React.Fragment>
    <InnerToolTip>
      {Boolean(props.title) && <InnerToolTipTitle>{props.title}</InnerToolTipTitle>}
      <div>{arText}</div></InnerToolTip></React.Fragment>}>
    <div style={{maxWidth:'100%', display:'inline-flex'}}>{props.children ?? <AppIcon color={props.color ?? '#7587A3'} fontSize={'small'} icon={'InfoIcon'}/>}</div>
  </HtmlTooltip>;
};


const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    padding        : '0',
    maxWidth       : '300px',
    borderRadius   : '12px'
  },
}));

const InnerToolTipTitle = styled(Typography)((props: { theme: Theme }) => ({
  fontSize: '12px',
  fontWeight:'bold',
  color: 'black'
}));
const InnerToolTip      = styled('div')((props: { theme: Theme }) => ({
  padding        : '16px',
  color          : '#7587A3',
  boxShadow      : props.theme.skin.boxShadow,
  backgroundColor: props.theme.palette.background.default,
  borderRadius   : '12px',
  border         : '1px solid ' + props.theme.skin.borderColor,
  fontSize       : '10px'
}));
