import React from 'react';
import { Divider, Typography } from '@mui/material';
import { AppIcon } from 'components/ui/AppIcon';
import { TIcon } from 'assets/icons';

export const DashboardCardContent = (props: { title: string; icon: TIcon; children?: any; color?:string }) => {
  const { title, icon, children, color } = props;
  return <div>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0.5rem' }}>
      <AppIcon color={color ? color: '#7587A3'} display={'inline'} icon={icon} />
      <Typography variant="h5" style={{ color: '#7587A3' }}>
        {title}</Typography>
    </div>
    <Divider style={{ marginBottom: '1rem' }} />
    {children}
  </div>;
};
