import {AxiosResponse} from 'axios';
import {apiService} from '../api/apiService';
import {TEntities} from '../dataTypes';
import {AbstractBaseApi} from './AbstractBaseApi';
import {INote} from '../dataTypes/note';


export class Notes extends AbstractBaseApi<INote> {
  
  constructor() {
    super('notes');
  }
  
  
  create(data: Partial<INote>) {
    return super.create(data);
  }
  
  getAccountNotes(id: number) {

    return apiService.entity(['accounts', 'notes'], {showProjection: false})
                     .byId(id)
                     .sort({sortDir: 'desc', sortBy: 'creationTime'})
                     .get()
                     .then((res: AxiosResponse<TEntities['notes']>) => {
                       return res?.data;
                     });
  }
}