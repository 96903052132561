import { Divider, Typography, Button } from "@mui/material";
import { accessList } from "application/access/access";
import { PageId } from "application/pages/pages.config";
import { providerIconList } from "assets/providerIcons";
import { ActionGranter } from "components/features/ActionGranter";
import { GridMiddleCenter, GridMiddle } from "components/ui/AppElements";
import { AppIcon } from "components/ui/AppIcon";
import { SCard, SCardTitle } from "../styledElements";
import { useTranslation } from "react-i18next";
import { IDspChannels } from "application/entities/dataTypes/dspChannels";
import { useAllDspChannels, useSelectedDspChannels } from "components/hooks/data/useDspChannelsQuery";
import { getUnselectedDsps } from "application/utils/dsp.utils";
import { useFeatureFlag } from "stores/featureFlags.store";
import { AUTHUser } from "application/utils/AuthUser";
import { EPermission } from "application/utils/granter.utils";
import { useNavigate } from "react-router";

interface IChannelsRecommendationsProps {
  accountId: number;
  goToPage: (pageId: PageId | string) => void;
}

export const ChannelsRecommendations = (props: IChannelsRecommendationsProps) => {
  const { t } = useTranslation();
  const { accountId, goToPage } = props;

  const dspSelected = useSelectedDspChannels(accountId);
  const allDsps = useAllDspChannels(accountId);
  const missingChannels = getUnselectedDsps(allDsps.data, dspSelected.data);
  const navigate = useNavigate();

  const isBrandAdmin =
    AUTHUser.hasRole(EPermission.COMPANYADMIN || AUTHUser.hasRole(EPermission.EVADMIN)) ||
    AUTHUser.permissions?.brandGrades[0]?.brandPermission === EPermission.BRANDADMIN
      ? true
      : false;

  if (missingChannels.length === 0 || dspSelected.isLoading || allDsps.isLoading) return <></>;
  return (
    <SCard style={{ display: "flex", flexDirection: "column", gap: "8px" }} data-cy={"dashboard-channel-recommendations"}>
      <GridMiddleCenter sx={{ gap: 1 }}>
        <div style={{ position: "relative" }}>
          <AppIcon color={"#3B4559"} display={"inline"} fontSize={"middle"} icon={"ChannelIcon"} chip={true} />
        </div>
        <SCardTitle style={{ flex: 1 }}>{t("audience.channelRecommendation")}</SCardTitle>
      </GridMiddleCenter>
      <Divider />
      <div style={{ display: "grid", gap: "15px", gridTemplateColumns: "1fr 1fr", padding: "16px 10px" }}>
        {missingChannels.map((dsp: IDspChannels) => {
          return (
            <GridMiddle sx={{ gap: "8px" }} title={dsp.name} key={dsp.code} data-cy="channel">
              <AppIcon icon={providerIconList?.[dsp.code] ?? "Settings"} /> <Typography>{dsp.name}</Typography>
            </GridMiddle>
          );
        })}
      </div>
      <Divider />
      <ActionGranter mode={"deny"} accesses={accessList.activation.setup}>
        <Typography>{t("audience.contactAdminChannelEnable")}</Typography>
      </ActionGranter>
      <ActionGranter accesses={accessList.activation.setup}>
        <div>
          <Button
            style={{ fontWeight: "bold" }}
            data-cy="connect-channels"
            onClick={() => {
              goToPage(PageId.channelsSetup);
            }}
            variant={"outlined"}
          >
            {t("audience.connectChannels")}
          </Button>
        </div>
      </ActionGranter>
    </SCard>
  );
};
