import {useTranslation} from 'react-i18next';
import {buttonLabels} from 'components/form/Buttons/buttonLabels';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import {IAccounts} from '../../../application/entities/dataTypes/accounts';
import {userValidationSchema} from '../../../application/validation';
import {ColorButton, GhostButton} from '../../../components/form/Buttons';
import {useAppToast} from '../../../components/live/AppToast';
import {ICompanyUsers} from '../../../types';
import {getSubscriptionPlans} from '../../Accounts/zOld/requests';
import {CompanyDetails} from '../Details/CompanyDetails';
import {UserRoleType} from '../Details/UserRoleType';
import {createUser} from '../Details/requests';
import {pageUtils} from '../../../application/pages/pages.utils';
import {ISubscriptions} from '../../../application/entities/dataTypes/subscriptions';
import {FooterBlock, FrameContainer, FrameContent, InfoBlockTitle, InfoContainer} from '../../../components/templates/InfoBlock';
import {objectApi} from '../../../application/entities/dataApi';
import {MainFrame} from '../../../components/templates/MainFrame';
import {GlobalAvatar} from '../../../components/ui/GlobalAvatar';
import {Box} from '@mui/material';
import {UserForm} from '../Form/UserForm';
import { PageId } from 'application/pages/pages.config';

interface ICreateAccountProps {
}

export const CreateUser: React.FC<ICreateAccountProps> = () => {
  
  const history     = useNavigate();
  const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
  const appToast    = useAppToast();
  const {t}         = useTranslation();
  
  const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);
  const [account, setAccount]                             = useState<Partial<IAccounts>>({
    activated             : false,
    clientTypesDescription: '',
    companyName           : '',
    // companySize: '',
    companyUrlList: [],
    contractFiles : '',
    country       : '',
    // creationTime: '',
    databaseName: '',
    dataClient  : false,
    dataProvider: false,
    industry    : 'industry',
    industryIds : [],
    // modificationTime: '',
    notes         : undefined,
    postalCode    : '',
    size          : '',
    state         : '',
    streetAddress : '',
    tag           : '',
    vertical      : '',
    subscriptionId: 6,
  });
  
  const [userDetails, setUserDetails] = useState<Partial<ICompanyUsers>>({
    title    : '',
    firstName: '',
    lastName : '',
    email    : '',
    role     : undefined,
    jobTitle : '',
    accountId: account.id,
  });
  
  const [subscription, setSubscription] = useState<Partial<ISubscriptions>>({
    id       : undefined,
    activated: undefined,
  });
  
  const accountsApi = new objectApi.accounts();
  
  const getPageInfo = () => {
    return {
      title       : account.companyName,
      companyName : account.companyName,
      description1: `Company ID#: ${account.id}`,
      description2: account.companyName && account.country && `${account.companyName}, ${account.country}`,
      activated   : account.activated,
    
    };
  };
 
  
  useEffect(() => {
    accountId && accountsApi.byId(+accountId)
                            .then(
                              (res) => {
                                res && setSubscription({
                                  ...res.subscription,
                                  subscriptionPlanId: res.subscriptionPlan.id,
                                  createUser        : true,
                                });
                                res && setAccount(res);
                              },
                            )
                            .catch((err) => {
                              console.log(err);
                            });
  }, [accountId]);
  
  useEffect(() => {
    getSubscriptionPlans()
    .then(res => {
      const {subscriptionPlans} = res.data._embedded;
      subscriptionPlans && setSubscriptionPlansList(subscriptionPlans);
    });
  }, []);
  
  
  const handleCreateUser = (values: Partial<ICompanyUsers>) => {
    if (values && values.email && values.email.length > 6 && values.firstName && values.firstName.length > 0) {
      createUser({...values, account: `account/${account.id}`})
      .then(
        (userRes) => {
          const userData = userRes.data;
          setUserDetails(userData);
          appToast.open({
            toastId : 'manageAccount',
            severity: 'success',
            message : t('message.account.addingSuccess'),
          });
          setTimeout(() => {
            appToast.close('manageAccount');
          }, 5000);
          // user-view
          setTimeout(() => history(pageUtils.getPagePathById(PageId.accountView, {accountId: account.id})), 1000);
        },
      )
      .catch((err) => {
        const message = err?.response?.data?.error?.message;
        appToast.open({
          toastId : 'manageAccount',
          severity: 'error',
          message : message ?? t('labels.error'),
        });
        setTimeout(() => {
          appToast.close('manageAccount');
        }, 5000);
      });
    }
  };
  
  
  const userDetailsFormik = useFormik({
    initialValues   : userDetails,
    validationSchema: userValidationSchema,
    validateOnChange: true,
    validateOnBlur  : true,
    onSubmit        : values => {
      const castValues = userValidationSchema.cast(values);
      userDetails.role && handleCreateUser({
        ...castValues,
        role: userDetails.role,
      });
    },
  });
  
  const handleCancel = () => {
    history(-1);
  };
  
  
  return (
    <MainFrame
      frameId={'user-create'}
      headerProps={{
        frameInfo: getPageInfo(),

      }}
    >
      <FrameContainer>
        <form onSubmit={userDetailsFormik.handleSubmit}>
          <FrameContent>
            <DetailsBox>
              <InfoContainer>
                <InfoBlockTitle>
                  {t('account.userDetails.userDetails')}
                </InfoBlockTitle>
                <UserForm
                  dataFormik={userDetailsFormik}
                  userDetails={userDetails}
                />
                {/*<EditAccountNotes
                 account={account}
                 setAccount={setAccount}
                 />*/}
              </InfoContainer>
              <InfoContainer>
                <InfoBlockTitle>
                  {t('account.accountDetailsLabels.accountsIamApartOf')}
                </InfoBlockTitle>
                <CompanyDetails
                  account={account}
                />
                <UserRoleType
                  userDetailsFormik={userDetailsFormik}
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
                {/*<ClientType
                 account={account}
                 setAccount={setAccount}
                 />*/}
                {/*<ActionGranter accesses={accessList.accounts.subscriptions}>
                 <InfoBlockTitle>
                 {labels.accountSubscriptionLabels.subscriptionDetails}
                 </InfoBlockTitle>
                 <SubscriptionInfo
                 subscription={subscription}
                 setSubscription={setSubscription}
                 subscriptionPlansList={subscriptionPlansList}
                 />
                 </ActionGranter>*/}
              </InfoContainer>
            </DetailsBox>
            <FooterBlock>
              <ColorButton
                variant="contained"
                color="primary"
                type="submit"
                data-cy={'create-user-submit'}
              >
                {buttonLabels.create}
              </ColorButton>
              <GhostButton
                onClick={() => handleCancel()}
                variant="contained"
                color="primary"
              >
                {buttonLabels.cancel}
              </GhostButton>
            </FooterBlock>
          </FrameContent>
        </form>
      </FrameContainer>
    </MainFrame>
  )
    ;
};

const DetailsBox = styled.div`
  display: flex;
  flex-direction: row;
`;
