import { CircularProgress, Divider, Typography } from "@mui/material";
import { actions } from "application/actions/actions";
import { objectApi } from "application/entities/dataApi";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { pageUtils } from "application/pages/pages.utils";
import { AudienceViewAttributes } from "components/audiences/audienceViewAttributes/AudienceViewAttributes";
import { ActionButtons } from "components/controls/ActionButtons";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { AudienceBuilderSum } from "pages/AudienceBuilder/components/AudienceBuilderSum";
import { CopyToBrandPopup } from "pages/Audiences/CopyToBrandPopup";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IOpenDialogAction } from "redux/slices/dialog.slices";

interface AudiencePreviewProps {
  audienceId: number;
  hideButtons?: boolean;
  onDelete: (arg: any, org: any) => void;
}

export const AudiencePreviewPopup = (props: AudiencePreviewProps) => {
  const audienceId = props.audienceId;
  const hideButtons = props.hideButtons ?? false;
  const audience = useAudienceBuilderStore((st) => st.audience);
  const audienceBuilderActions = useAudienceBuilderStore((st) => st.actions);
  const isLoading = useAudienceBuilderStore((st) => st.loading);
  const audiencesApi = new objectApi.audiences();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const windowDialog = useOpenDialogWindow();

  useEffect(() => {
    audienceBuilderActions.init(audienceId, true);
  }, [audienceId]);

  useEffect(() => {
    return () => {
      audienceBuilderActions.reset();
    };
  }, []);

  const dialogArchive: IOpenDialogAction = {
    dialogId: "audienceArchive",
    buttonActions: { acceptButton: "Archive", cancelButton: true },
    title: t("audience.archiveAudience"),
    description: t("audience.archiveAudienceDescription"),
    icon: "Archive",
    onAccept: onArchiveAccept,
  };

  const dialogDelete: IOpenDialogAction = {
    dialogId: "audienceDelete",
    buttonActions: { acceptButton: "Delete", cancelButton: true },
    title: t("audience.deleteAudience"),
    description: t("audience.deleteAudienceDescription"),
    icon: "Trash",
    onAccept: onDeleteAccept,
  };

  const dialogDuplicate: IOpenDialogAction = {
    dialogId: "audienceDuplicate",
    buttonActions: { acceptButton: "Duplicate", cancelButton: true },
    title: t("audience.duplicateAudience"),
    description: t("audience.duplicateAudienceDescription"),
    icon: "Duplicate",
    onAccept: onDuplicateAccept,
  };

  async function onDeleteAccept(args: any, orgs: any) {
    props.onDelete(true, orgs);
    windowDialog.close("AudiencePreviewPopup");
  }

  async function onArchiveAccept(args: any, orgs: any) {
    audiencesApi.archiveAudience(orgs.id).then(() => {
      windowDialog.close("AudiencePreviewPopup");
    });
  }

  function onDuplicateAccept(args: any, dta: { id: number; data: Partial<IAudiences> }) {
    audiencesApi.duplicateAudience(dta.data).then((res) => {
      if (res && res.id) {
        windowDialog.close("AudiencePreviewPopup").then(() => {
          navigate(pageUtils.getPagePathById("audience-builder-update", { id: res.id }));
        });
      }
    });
  }

  const actionButtons = { ...actions.audience };
  actionButtons.archive.action = (audienceData: IAudiences) => {
    windowDialog.open({ ...dialogArchive, dialogArgs: { id: audienceData.id, data: audienceData } });
  };
  actionButtons.copy.action = (audienceData: IAudiences) => {
    windowDialog.open({ ...dialogDuplicate, dialogArgs: { id: audienceData.id, data: audienceData } });
  };
  actionButtons.trash.action = (audienceData: IAudiences) => {
    windowDialog.open({ ...dialogDelete, dialogArgs: { id: audienceData.id, data: audienceData } });
  };
  actionButtons.copyToBrand.action = (audienceData: IAudiences) => {
    windowDialog.open({
      dialogId: "copyToBrand",
      component: CopyToBrandPopup,
      componentProps: {
        audience: audienceData,
      },
    });
  };
  delete actionButtons.edit.pageId;
  actionButtons.edit.action = (audienceData: IAudiences) => {
    windowDialog.close("AudiencePreviewPopup").then(() => {
      navigate(pageUtils.getPagePathById("audience-builder-update", { id: audienceId }));
    });
  };

  if (audience?.audienceType?.code === "FIRST_PARTY") {
    delete actionButtons.copy;
    delete actionButtons.copyToBrand;
  }

  return (
    <div style={previewAudienceStyle}>
      {isLoading ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <CircularProgress />
          <p>Loading, please wait...</p>
        </div>
      ) : (
        <>
          <div
            style={{
              paddingRight: "30px",
              display: "flex",
              gap: "1rem",
              marginBottom: "0.5rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Audience preview</Typography>
            {!hideButtons && (
              <div>
                <ActionButtons buttonSize={"small"} actions={actionButtons} vars={{ ...audience, audienceId: audienceId }} />
              </div>
            )}
          </div>
          <Divider style={{ marginBottom: "1.5rem" }} />
          <AudienceViewAttributes previewMode={true} />
          <Divider style={{ marginTop: "1rem" }} />
          <AudienceBuilderSum previewMode={true} />
        </>
      )}
    </div>
  );
};

const previewAudienceStyle = {
  padding: "20px",
  paddingBottom: "10px",
};
