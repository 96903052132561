import { ButtonGroup, Button, Box, Typography, Chip, styled } from "@mui/material";
import { DspCheckoutParameterType } from "application/entities/dataTypes/dspEnablementOptions";
import { AUTHUser } from "application/utils/AuthUser";
import { EPermission } from "application/utils/granter.utils";
import { StyledCheckbox } from "components/form/CheckBox";
import { useDomainLists } from "components/hooks/data/useListsQuery";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { UploadList } from "pages/Lists/UploadList";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOpenDialogAction } from "redux/slices/dialog.slices";

type OptionDomainsProps = {
  accountId: number;
  onChange: (code: "exclude_domains" | "include_domains", value: string) => void;
  domainsGroupCheckoutParameters: DspCheckoutParameterType[];
};

export const OptionDomains = (props: OptionDomainsProps) => {
  const { accountId, onChange, domainsGroupCheckoutParameters } = props;
  const dialogWindow = useOpenDialogWindow();

  const getFileName = (key: string) => key?.split("/").pop()?.replaceAll("_", " ");

  const selected = domainsGroupCheckoutParameters.find((item) => item.selected);
  const selectedChipLabel = () => {
    if (!selected) return (<></>) as ReactNode;
    const type = selected.code === "exclude_domains" ? `Blacklist` : `Whitelist`;
    return (
      <>
        <Typography variant="body2" pr={1} component="span" color={selected.code === "include_domains" ? "#35cf35" : "error"}>
          {type}:
        </Typography>
        <Typography variant="body2" component="span" color={"GrayText"}>
          {getFileName(selected.selected as string)}
        </Typography>
      </>
    ) as ReactNode;
  };
  const uploadDomainListDialog: IOpenDialogAction = {
    dialogId: "domainListsModal",
    component: DomainSelectionWindow,
    title: "Domain list selection",
    icon: "ExclInclu",
    componentProps: {
      accountId: accountId,
      currentSelection: selected,
      handleChange: (type: string, selected: string) => {
        onChange(type === "include" ? "include_domains" : "exclude_domains", selected);
      },
    },
  };

  const handleOpenDomainLists = () => {
    dialogWindow.open(uploadDomainListDialog);
  };

  return (
    <>
      {!selected && (
        <Button disableRipple onClick={handleOpenDomainLists} variant="outlined">
          Select a domain list
        </Button>
      )}
      {selected && (
        <StyledChip
          size="medium"
          color={selected.code === "include_domains" ? "success" : "error"}
          label={selectedChipLabel()}
          onClick={handleOpenDomainLists}
        />
      )}
    </>
  );
};

type DomainSelectionWindowProps = {
  accountId: number;
  currentSelection: DspCheckoutParameterType;
  handleChange: (type: string, selected: string | false) => void;
};
const DomainSelectionWindow = (props: DomainSelectionWindowProps) => {
  const { accountId, currentSelection, handleChange } = props;
  const { data: domainData, isLoading } = useDomainLists(+accountId);
  const dialogWindow = useOpenDialogWindow();
  const { t } = useTranslation();

  const [type, setType] = useState<"include" | "exclude" | null>(null);
  const [selected, setSelected] = useState<string | null>(null);

  const handleChangeType = (type: "include" | "exclude") => {
    setType(type);
    setSelected(null);
  };

  useEffect(() => {
    if (currentSelection) {
      setSelected(currentSelection.selected as string);
      setType(currentSelection.code === "exclude_domains" ? "exclude" : "include");
    }
  }, [currentSelection]);

  const uploadDomainListDialog: IOpenDialogAction = {
    dialogId: "UploadListModal",
    component: UploadList,
    title: t("uploadLists.uploadDomainList"),
    icon: "Upload",
    description: t("uploadLists.uploadDomainListDescription"),
    componentProps: {
      accountId: accountId,
      listType: "domain",
    },
  };
  const handleOpenDomainLists = () => {
    dialogWindow.open(uploadDomainListDialog);
  };

  const isBrandAdmin =
    AUTHUser.hasRole(EPermission.COMPANYADMIN || AUTHUser.hasRole(EPermission.EVADMIN)) ||
    AUTHUser.permissions?.brandGrades[0]?.brandPermission === EPermission.BRANDADMIN
      ? true
      : false;

  const handleSave = () => {
    if (type && selected) {
      handleChange(type, selected);
      dialogWindow.close("domainListsModal");
    }
  };

  const handleReset = () => {
    if (type && selected) {
      handleChange(type, false);
      dialogWindow.close("domainListsModal");
    }
  };
  return (
    <Box p={2}>
      <Box textAlign="center" mb={2}>
        <Typography variant="body1" mb={2}>
          Select the Domain List you want to associate to this Deal ID.
        </Typography>
        <ButtonGroup size="medium" variant="contained" aria-label="outlined primary button group" disableElevation>
          <Button disableRipple onClick={() => handleChangeType("include")} variant={type === "include" ? "contained" : "outlined"}>
            Whitelist
          </Button>
          <Button disableRipple onClick={() => handleChangeType("exclude")} variant={type === "exclude" ? "contained" : "outlined"}>
            Blacklist
          </Button>
        </ButtonGroup>
      </Box>
      {domainData?.objects?.length && type && (
        <Box>
          <Typography variant="body1" mb={2}>
            Select one of this list is to be associated to the Deal ID as a {type === "include" ? "Whitelist" : "Blacklist"}
          </Typography>
          {domainData.objects.map((file) => {
            let realName = file?.key?.split("/").pop()?.replaceAll("_", " ");
            return (
              <Box pl={1} sx={{ display: "flex", alignItems: "center", gap: "5px", mb: 1 }}>
                <StyledCheckbox checked={selected === file.key} onClick={() => setSelected(file.key)} />
                {realName}
              </Box>
            );
          })}
        </Box>
      )}

      {isBrandAdmin && type && (
        <Box pl={1} mt={1}>
          <Typography onClick={handleOpenDomainLists} color="primary" sx={{ cursor: "pointer" }}>
            Upload a domain list
          </Typography>
        </Box>
      )}

      <Box textAlign="center" mt={4} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="text" color="error" disabled={!selected} onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" disabled={!selected} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

const StyledChip = styled(Chip)(() => ({
  fontSize: "11px",
  padding: "2px",
  background: "#fff",
  boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
  "&:hover": {
    background: "#fff",
  },
}));
