import { Box, Button, Typography } from "@mui/material";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAudienceEnablementStore } from "../store/AudienceEnablement.store";
import { SummaryTable } from "./SummaryStep/SummaryTable";

export const FinalStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const audienceData = useAudienceEnablementStore((state) => state.audienceData);

  useAudienceEnablementStore.setState({ stepBackDisabled: true });

  return (
    <>
      <Typography pt={2} pb={1} variant="h4" textAlign="center">
        Congratulations!
      </Typography>
      <Typography pb={4} variant="body1" className="color-tech-grey" textAlign="center">
        Your audiences will appear in the following Enablement Channels shortly
      </Typography>
      {audienceData.audienceStatus !== "APPROVED" && <SummaryTable />}

      <Typography pt={2} pb={4} variant="body1" textAlign="center">
        {t`activation.willReceive`} {t`activation.reminder`}
      </Typography>

      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={() => {
            navigate(pageUtils.getPagePathById(PageId.audienceLibrary, { accountId: audienceData?.account?.id }));
          }}
          variant="outlined"
        >
          {t("audience.activation.backToAudienceLib")}
        </Button>

        <Button
          onClick={() => {
            navigate(pageUtils.getPagePathById(PageId.audienceBuilderSelection, { accountId: audienceData?.account?.id }));
          }}
          variant="contained"
        >
          {t(`activation.buildNewAudience`)}
        </Button>
      </Box>
    </>
  );
};
