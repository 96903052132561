import {PageId} from 'application/pages/pages.config';
import {MainFrame} from 'components/templates/MainFrame';

export const ListCompany = () => {
  return <MainFrame frameId={PageId.companyList}>
  </MainFrame>;
};




