import { objectApi } from "application/entities/dataApi";
import { useQuery, useQueryClient } from "react-query";
import { Button, CircularProgress, Divider, TextField, Theme, Typography, styled } from "@mui/material";
import { IAudienceActivationRecap } from "application/entities/dataTypes/audienceActivationRecap";
import { PropertiesList } from "pages/Accounts/admin/AdminAccountView";
import { profilesFormatter } from "_configuration/formaters";
import moment from "moment";
import { useState } from "react";
import { TextFieldLabel } from "pages/AudienceBuilder/components/filters/InputLinkedId";
import { AppSwitch } from "components/form/AppSwitch";
import { useAudiencesAlwaysOn } from "components/hooks/data/useAudiencesQuery";

const useAudienceAlwaysOn = (audienceId: number) => {
    const audienceApi = new objectApi.audiences();
    return useQuery(['audience-alway-in', audienceId], ()=>{
        return audienceApi.byId(audienceId).then(res => res);
    }, {cacheTime: 1000})
}

const audienceApi = new objectApi.audiences();

export const AlwaysOnDialog = (props: {audienceId: number}) => {
    const audienceId = Number(props.audienceId); 
    const {isLoading, error, data, refetch} = useAudienceAlwaysOn(audienceId)

    const dsps = data?.audienceActivationRecap?.filter((recap: IAudienceActivationRecap) => recap?.canBeAlwaysOn === true);
    
    return <Content>
        {isLoading ? <div style={{padding:'20px', textAlign:'center'}}>
            <CircularProgress />
            <p>Loading, please wait...</p>
            </div> :
            <div>
                <Typography variant="h2" style={{ textAlign: "center", marginBottom:'1rem' }}>{data.name}</Typography>
                {dsps?.map((recap: IAudienceActivationRecap) => {
                return (
                  <div key={recap.dspCode} style={{ marginBottom: "1rem" }}>
                    <Typography variant="h5" style={{ textAlign: "left" }}>
                      {recap.dspName}
                    </Typography>
                    <Divider style={{ marginBottom: "1rem" }} />
                    <PropertiesList>
                      <li>
                        <span>Always On:</span> <span style={{color: recap?.alwaysOnActivated ? 'green':'#7587A3'}}>{recap?.alwaysOnActivated ?"Active": "Not Active"}</span>
                      </li>
                      <li>
                        <span>Always On Refresh interval (in days):</span> {recap?.alwaysOnRefreshIntervalInDays ?? "not set"}
                      </li>
                      <li>
                        <span>Start Date:</span>{" "}
                        {recap?.activationTime ? moment(new Date(recap?.activationTime)).format("D-MMM-YYYY h:mm A") : "not set"}{" "}
                      </li>
                      <li>
                        <span>End Date:</span>{" "}
                        {recap?.expirationTime ? moment(new Date(recap?.expirationTime)).format("D-MMM-YYYY h:mm A") : "not set"}
                      </li>
                      <li>
                        <span>Size:</span> {profilesFormatter.format(recap.profilesNumber)}
                      </li>
                    </PropertiesList>
                    <AlwaysOnForm audienceId={audienceId} dsp={recap}/>
                    
                  </div>
                );
              })}
            </div>
    }
    </Content>
}


const AlwaysOnForm = (props: {audienceId: number, dsp: IAudienceActivationRecap}) => {
    const {audienceId, dsp} = props;
 
  
    const queryClient = useQueryClient();
    
    const allAlwaysOnList = useAudiencesAlwaysOn()
    const [alwaysOn, setAlwaysOn] = useState(dsp?.alwaysOnActivated ?? false);
    const [refreshInterval, setRefreshInterval] = useState(dsp?.alwaysOnRefreshIntervalInDays ?? null);
    const [saveButton, setSaveButton] = useState<{element:any, color:"inherit" | "error" | "primary" | "info" | "secondary" | "success" | "warning" | undefined}>({element: <span>Save</span>, color: 'primary'});
    const { refetch} = useAudienceAlwaysOn(audienceId)

    const handleSave =()=>{
      setSaveButton({element:<CircularProgress color="info"  size={24}/>, color:'info'})
        audienceApi.updateAlwaysOn({
            audienceId: audienceId,
            dspCode: dsp.dspCode,
            enableAlwaysOn: alwaysOn,
            refreshIntervalInDays: alwaysOn ? refreshInterval: null
        }).then(res=>{
            refetch()
            
            allAlwaysOnList.refetch()
            queryClient.refetchQueries('admin-audiences')
            setSaveButton({element: <span>Saved!</span>, color: 'success'})
            setTimeout(() => {
              setSaveButton({element: <span>Save</span>, color: 'primary'})
            }, 2000);
        })

    }
    
    return <div style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "2rem",
        marginTop: "1rem",
      }}>
        <AppSwitch label="Always On" componentsProps={{typography: {style: {color:'red'}}}} labelPlacement="start" checked={alwaysOn} onChange={(e)=>setAlwaysOn(old=> !old)}/>
        {alwaysOn && <>
        <TextFieldLabel>Refresh interval in days:</TextFieldLabel>
        <TextField
        id={dsp.dspCode}
        type="number"
        name={dsp.dspCode}
        sx={{ width: "100px" }}
        value={refreshInterval}
        style={{width:'50px'}}
        onChange={(e)=>setRefreshInterval(e.target.value ? Number(e.target.value): null)}
        inputProps={{ "data-cy": "alwaysOnRefreshIntervalInDays-" + dsp.dspCode }}
        />
        
        </>}
        <Button variant="contained" disabled={(alwaysOn && refreshInterval === null) } color={saveButton.color} onClick={handleSave}>
          {saveButton.element}
        </Button>
    </div>
}

const Content = styled("div")((props: { theme: Theme }) => ({
    padding: "16px",
    overflowY: "auto",
    margin: "2rem auto",
    width: "600px",
  }));