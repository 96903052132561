import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide, useTheme,} from '@mui/material';
import {TransitionProps} from '@mui/material/transitions';

import {useDialogActions, useDialogState,} from '../../../redux/slices/dialog.slices';

import {useAuthState} from 'redux/slices/auth.slices';
import {AppIcon} from 'components/ui/AppIcon';
import {TIcon} from '../../../assets/icons';
import {styled} from '@mui/styles';

interface IDialogWindowProps {
  open?: boolean;
  dialogId?: string;
  className?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogWindow = (props: IDialogWindowProps) => {
  
  const authState     = useAuthState();
  const dialogState   = useDialogState();
  const dialogActions = useDialogActions();
  const theme         = useTheme();
  const {className} = props
  //const newDialog = useDialog()
  
  const {
          dialogId,
          title,
          dialogProps,
          description,
          componentProps,
          component,
          content,
          buttonActions,
          onClose,
          onAccept,
          dialogArgs,
        } =
          dialogState;
  
  const Component = component;
  
  const handleClose = () => {
    dialogActions.closeDialog(dialogId);
  };
  
  const handleCancel = () => {
    onClose && onClose(false);
    dialogActions.closeDialog(dialogId);
  };
  
  const handleAccept = () => {
    onAccept && onAccept(true, dialogArgs);
    dialogActions.closeDialog(dialogId);
  };
  
  const propsDiag = Object.assign({
    open               : dialogState.open,
    TransitionComponent: Transition,
    keepMounted        : false,
    onClose            : handleClose,
    className          : className ?? '',
   
  }, dialogProps);
  
  React.useEffect(() => {
    if (authState.loggedIn) {
      dialogActions.closeDialog(dialogId);
    }
  }, [authState.loggedIn]);
  
  return (
    <Dialog data-cy={'dialog'}
      {...propsDiag} 
    ><Closer  onClick={handleClose}><AppIcon fontSize={'small'}  icon={'CloseLight'} /></Closer>
      {Boolean(dialogState.icon) &&
       <Box sx={{pt: 4}} style={{backgroundColor: theme.palette.background.paper, display: 'flex', justifyContent: 'center'}}><AppIcon display={'inline'} fontSize={'default'}
                                                                                                                                       chip={true}
                                                                                                                                       icon={dialogState.icon as TIcon}/></Box>}
      {Boolean(dialogState.title) && (
        <DialogTitle style={{textAlign: 'center', margin: '0', padding: '0.5rem 1rem',marginBottom:'0.5rem', fontSize:'18px'}} id="alert-dialog-slide-title">
          {dialogState.title}
        </DialogTitle>
      )}
      {description &&
       <DialogContentText sx={{px: 4,maxWidth: '400px', textAlign: 'center',display: 'flex', justifyContent: 'center'}} id="alert-dialog-description">
             {description}
       </DialogContentText>}
      {dialogState.open && <DialogContent>
        {component && (<Component {...componentProps} /> ?? '')}
        {dialogState.open && content && content}
      </DialogContent>}
      {buttonActions && <Divider sx={{m: 1, p: 1}}/>}
      {buttonActions && <DialogActions>
        {buttonActions.cancelButton && <Button onClick={handleCancel} color="primary" data-cy={'cancel-button'}>
          {(typeof buttonActions.cancelButton === 'string') ? buttonActions.cancelButton : 'Cancel'}
        </Button>}
        {buttonActions.dismissButton &&
         <Button variant={'outlined'} onClick={handleClose} color="primary">
           {(typeof buttonActions.dismissButton === 'string')
            ? buttonActions.dismissButton
            : 'Dismiss'}
         </Button>}
        {buttonActions.acceptButton &&
         <Button type="submit" variant={'contained'} onClick={handleAccept} color="primary" data-cy={'accept-button'}>
           {(typeof buttonActions.acceptButton === 'string') ? buttonActions.acceptButton : 'Ok'}
         </Button>}
      </DialogActions>}
    </Dialog>
  );
};


export const useOpenDialogWindow = () => {
  const dialogActions = useDialogActions();
  
  return {
    open : dialogActions.openDialog,
    close: dialogActions.closeDialog,
  };
};


const Closer = styled('div')(() => ({
  position           : 'absolute',
  right              : '0.25rem',
  marginTop          : '0.8rem',
  width              : '2rem',
  cursor:'pointer'
}));

