import Box from "@mui/material/Box";
import {PageId} from "../../application/pages/pages.config";
import {MainFrame} from "../../components/templates/MainFrame";
import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {useParams} from "react-router";
import {useSegmentBuilderStore} from "./SegmentBuilderStore";
import {SegmentsResults} from "./SegmentsResults";
import {AccountTracker} from "../../components/controls/AccountTracker";
import {SegmentsSideBar} from "./sideBar/SegmentsSideBar";
import {SegmentsSearch} from "./search/SegmentsSearch";
import {useQueryClient} from "react-query";

export const SegmentBuilder = () => {
    let {id} = useParams<{ id: string }>() as { id: string };
    const queryClient = useQueryClient()

    const searchDone = useSegmentBuilderStore(st => st.searchDone)
    const isLoading = useSegmentBuilderStore(st => st.isLoading)
    const accountId = useSegmentBuilderStore(st => st.accountId)


    const init = useSegmentBuilderStore(st => st.init)


    useEffect(() => {
        if (id === undefined) return
        console.log('call init with id', id, Number(id))
        queryClient.removeQueries('externalSegments')
        useSegmentBuilderStore.setState({isLoading: true})
        init(Number(id))

    }, [id])

    return <>
        {accountId && <AccountTracker accountId={accountId}/>}
        <MainFrame
            frameId={PageId.segmentBuilder}
            scrollable={true}
            loading={isLoading}
        >
            {!isLoading && (
                <Box sx={{height: "100%", overflowY: "auto", flex: 1, padding: "2rem", display: 'flex', gap: '1rem'}}
                     data-cy="segment-builder">
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '1rem'
                    }}>
                        {!searchDone && <>
                            <Typography variant="h4" fontSize="22px" color="#7587A3" align={'center'} mb={2}>
                                Search segments
                            </Typography>
                            <Typography variant="body1" color="#7587A3" align={'center'} mb={4}>
                                Search for segments in the list below and select the ones you want to enable
                            </Typography>
                        </>
                        }

                        <SegmentsSearch/>

                        <SegmentsResults/>

                    </Box>
                    <SegmentsSideBar/>

                </Box>

            )}
        </MainFrame>
    </>
}